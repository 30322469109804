<template>
  <span :class="['badge', badgeColor]">{{word}}</span>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    require: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    word() {
      return this.require? '必須' : '任意'
    },
    badgeColor(){
      return this.require? 'badge-danger' : 'badge-secondary'
    }
  }
}
</script>