<template>
</template>

<script>
export default {
	props: ["accountType"],
  data() {
		return {
		}
  },
  methods: {
	  unlock: function(){
		  this.$loading.load(this.$auth.api.get('auth/unlock', {
			  params: {
					unlock_token: this.$route.params.unlock_token
			  }
		  }).then(res => {
				this.$router.push({
					path: '/' + this.accountType + "/login",
					query: {
						unlocked: '1'
					}
				})
		  }).catch(err => {
				this.$router.push({
					path: '/' + this.accountType + "/login",
				})
			}));
		}
  },
  computed: {
  },
  created: function () {
		this.unlock();
  },
  mounted: function() {
  },
  watch: {
  }
}
</script>