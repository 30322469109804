<template>
  <div id="app">
    <main>
      <section class="main-title">
        <h1>大手企業プッシュ登録</h1>
      </section>
      <form class="needs-validation" @submit.prevent="confirmEpCompany" novalidate>
        <!-- 企業情報 -->
        <section class="chapter white-block">
          <h2 class="chapter-label">企業情報</h2>
          <div class="form-wrap border-top">
            <div class="form-group">
              <label class="required-addon" for="company-name">企業名<span class="badge badge-danger">必須</span></label>
              <b-form-input v-model="ep_company.company_name" :state="checkValid('company_name')" type="text" placeholder="例) シックスブレイン株式会社" class="form-control"></b-form-input>
              <p v-if="!!errors['company_name']" class="invalid-feedback d-block">{{ errors['company_name'][0]}}</p>
            </div>

            <fieldset>
              <legend class="required-addon">本社所在地<span class="badge badge-secondary">任意</span></legend>
              <div class="form-row">
                <div class="form-group col-3">
                  <b-select v-model="ep_company.prefecture_master_id" :state="checkValid('prefecture_master_id')" class="custom-select">
                    <option value="">選択してください</option>
                    <option v-for="(prefecture_i18n, prefecture) in prefectures" :key="prefecture" :value="prefecture">
                      {{ prefecture_i18n }}
                    </option>
                  </b-select>
                  <p v-if="!!errors['prefecture_master_id']" class="invalid-feedback d-block">{{ errors['prefecture_master_id'][0] }}</p>
                </div>
                <div class="form-group col">
                  <b-form-input v-model="ep_company.address" :state="checkValid('address')" class="form-control" type="text" id="company-address2" placeholder="例) 千代田区丸の内3-2-3 丸の内二重橋ビルディング"></b-form-input>
                  <p v-if="!!errors['address']" class="invalid-feedback d-block">{{ errors['address'][0] }}</p>
                </div>
              </div>
            </fieldset>

            <div class="form-group">
              <label class="required-addon" for="company-url">企業HP<span class="badge badge-secondary">任意</span></label>
              <b-form-input v-model="ep_company.company_hp" :state="checkValid('company_hp')" type="text" placeholder="例) https://sixbrain.ai" class="form-control"></b-form-input>
              <p v-if="!!errors['company_hp']" class="invalid-feedback d-block">{{ errors['company_hp'][0] }}</p>
            </div>

            <fieldset class="form-group">
              <legend class="required-addon mb-0">業界</legend>
                <div class="required-addon"><span class="badge badge-danger">必須</span>
                  <div class="col d-flex">
                    <b-select class="custom-select col-4" id="industry" v-model="ep_company.industry_master_id" :state="checkValid('industry_master_id')">
                      <option selected disabled value="">選択してください</option>
                      <option v-for="(industry_i18n, industry) in uniqIndustries" :key="industry" :value="industry">
                        {{ industry_i18n }}
                      </option>
                    </b-select>
                    <p v-if="!!errors['industry_master_id']" class="invalid-feedback m-2">{{ errors['industry_master_id'][0] }}</p>
                  </div>
                </div>

              <div class="required-addon mt-2"><span class="badge badge-secondary">任意</span>
                <div class="col d-flex">
                  <b-select class="custom-select col-4" id="sub_industry" v-model="ep_company.sub_industry_master_id" :state="checkValid('sub_industry_master_id')">
                    <option value="">選択してください</option>
                    <option v-for="(industry_i18n, industry) in uniqSubIndustries" :key="industry" :value="industry">
                      {{ industry_i18n }}
                    </option>
                  </b-select>
                  <p v-if="!!errors['sub_industry_master_id']" class="invalid-feedback m-2">{{ errors['sub_industry_master_id'][0] }}</p>
                </div>
              </div>
            </fieldset>

            <div class="form-group">
              <label class="required-addon" for="business-overview">私たちについて<span class="badge badge-secondary">任意</span></label>
              <b-form-textarea v-model="ep_company.business_summary" :state="checkValid('business_summary')" type="text" class="form-control" placeholder="例) デロイト トーマツ ベンチャーサポート株式会社は、「挑戦する人とともに未来をひらく」をミッションに、ベンチャー企業・大手 企業・官公庁／地方自治体等が協働し、数多くのイノベーションを生み出す世界を目指し活動しています。"></b-form-textarea>
              <p v-if="!!errors['business_summary']" class="invalid-feedback d-block">{{ errors['business_summary'][0] }}</p>
            </div>

            <fieldset>
              <legend class="required-addon">有限責任監査法人トーマツ及びDeloitteのメンバーファームからの監査<span class="badge badge-secondary">任意</span></legend>
              <div class="form-group custom-control custom-checkbox vertical-checkbox">
                <input v-model="ep_company.is_audit" true-value='audit' false-value='unaudit' class="custom-control-input" type="checkbox" id="auditor">
                <label class="required-addon custom-control-label" for="auditor">受けている</label>
                <p v-if="!!errors['is_audit']" class="invalid-feedback d-block">{{ errors['is_audit'][0] }}</p>
              </div>
            </fieldset>

            <fieldset>
              <legend class="required-addon">有料会員機能の利用<span class="badge badge-secondary">任意</span></legend>
              <div class="form-group custom-control custom-checkbox vertical-checkbox">
                <input v-model="ep_company.membership_type" true-value='paid_member' false-value='free_member' class="custom-control-input" type="checkbox" id="membership">
                <label class="required-addon custom-control-label" for="membership">利用できる</label>
                <p v-if="!!errors['membership_type']" class="invalid-feedback d-block">{{ errors['membership_type'][0] }}</p>
              </div>
            </fieldset>

          </div>
        </section>

        <!-- 管理者情報-->
        <section class="chapter white-block">
          <h2 class="chapter-label">管理者情報</h2>
          <div class="form-wrap d-flex border-top">
            <div class="left-col drop_area" @dragover.prevent="onArea" @drop.prevent="dropFile" @dragleave.prevent="offArea" @dragend.prevent="offArea">
              <!-- 既に保存されている画像 -->
              <img v-if="ep_company.ep_admin_user_attributes.image_attributes.filename"
                class="thumbnail-frame img180 mx-auto mb-3"
                :src="ep_company.ep_admin_user_attributes.presigned_url"
                :alt="ep_company.ep_admin_user_attributes.image_attributes.filename"
              />
              <!-- 新しく画像が選択された -->
              <img v-else-if="!!(ep_company.ep_admin_user_attributes.uploaded_image)" class="thumbnail-frame img180 mx-auto mb-3"
                :src="ep_company.ep_admin_user_attributes.uploaded_image"
                :alt="ep_company.ep_admin_user_attributes.new_image_name"
              />
              <img v-else
                class="thumbnail-frame img180 mx-auto mb-3"
                src="~images/ep_user_default.png"
                alt="株式会社シックスブレイン logo"
              />
              <p v-if="!!errors['ep_admin_user.image']" class="invalid-feedback d-block">{{ errors['ep_admin_user.image'][0] }}</p>
              <p v-if="!!errors['ep_admin_user.image.filename']" class="invalid-feedback d-block">{{ errors['ep_admin_user.image.filename'][0]}}</p>
              <p v-if="!!errors['ep_admin_user.image_size']" class="invalid-feedback d-block">{{ errors['ep_admin_user.image_size'][0]}}</p>
              <div class="btn-group row" role="group" aria-label="画像アップロード">
                <button @click="removeUser" class="btn btn-cancel icon-addon-left col-4" type="button" data-toggle="modal" data-target="#delete"><i class="fas fa-times"></i>削除</button>
                <label class="btn btn-file icon-addon-left col-8" for="department-admin-user-file-upload"><i class="fas fa-arrow-up"></i>アップロード
                  <input type="file" id="department-admin-user-file-upload" :accept="accept_extensions" @change="onUserFileChange" ref="userImageUploader">
                </label>
              </div>
            </div>
            <div class="right-col">
              <fieldset>
                <div class="form-row">
                  <div class="form-group col-6">
                    <label class="required-addon" for="administrator-name1">姓<span class="badge badge-danger">必須</span></label>
                    <b-form-input v-model="ep_company.ep_admin_user_attributes.last_name" :state="checkValid('ep_admin_user.last_name')" type="text" class="form-control" placeholder="例) デロイト"></b-form-input>
                    <p v-if="!!errors['ep_admin_user.last_name']" class="invalid-feedback d-block">{{ errors['ep_admin_user.last_name'][0] }}</p>
                  </div>
                  <div class="form-group col-6">
                    <label class="required-addon" for="administrator-name2">名<span class="badge badge-danger">必須</span></label>
                    <b-form-input v-model="ep_company.ep_admin_user_attributes.first_name" :state="checkValid('ep_admin_user.first_name')" type="text" class="form-control" placeholder="例) 太郎"></b-form-input>
                    <p v-if="!!errors['ep_admin_user.first_name']" class="invalid-feedback d-block">{{ errors['ep_admin_user.first_name'][0] }}</p>
                  </div>
                </div>
              </fieldset>

              <fieldset>
                <div class="form-row">
                  <div class="form-group col-6">
                    <label class="required-addon" for="administrator-name1-kana">セイ<span class="badge badge-secondary">任意</span></label>
                    <b-form-input v-model="ep_company.ep_admin_user_attributes.kana_last_name" :state="checkValid('ep_admin_user.kana_last_name')" type="text" class="form-control" id="administrator-name1-kana" placeholder="例) デロイト"></b-form-input>
                    <p v-if="!!errors['ep_admin_user.kana_last_name']" class="invalid-feedback d-block">{{ errors['ep_admin_user.kana_last_name'][0] }}</p>
                  </div>
                  <div class="form-group col-6">
                    <label class="required-addon" for="administrator-name2-kana">メイ<span class="badge badge-secondary">任意</span></label>
                    <b-form-input v-model="ep_company.ep_admin_user_attributes.kana_first_name" :state="checkValid('ep_admin_user.kana_first_name')" type="text" class="form-control" id="administrator-name2-kana" placeholder="例) タロウ"></b-form-input>
                    <p v-if="!!errors['ep_admin_user.kana_first_name']" class="invalid-feedback d-block">{{ errors['ep_admin_user.kana_first_name'][0] }}</p>
                  </div>
                </div>
              </fieldset>

              <div class="form-group">
                <label class="required-addon" for="administrator-email">メールアドレス（ログインID）<span class="badge badge-danger">必須</span></label>
                <b-form-input v-model="ep_company.ep_admin_user_attributes.email" type="email" :state="checkValid('ep_admin_user.email')" class="form-control" placeholder="例) t-deloitte@sixbrain.co.jp"></b-form-input>
                <p v-if="!!errors['ep_admin_user.email']" class="invalid-feedback d-block">{{ errors['ep_admin_user.email'][0] }}</p>
              </div>

              <div class="form-group">
                <label class="required-addon" for="administrator-title">役職名<span class="badge badge-secondary">任意</span></label>
                <b-form-input v-model="ep_company.ep_admin_user_attributes.position" :state="checkValid('ep_admin_user.position')" class="form-control" type="text" id="administrator-title" placeholder="例) 課長"></b-form-input>
                <p v-if="!!errors['ep_admin_user.position']" class="invalid-feedback d-block">{{ errors['ep_admin_user.position'][0] }}</p>
              </div>

              <div class="form-group">
                <label class="required-addon" for="administrator-department">部署名<span class="badge badge-secondary">任意</span></label>
                <b-form-input v-model="ep_company.ep_admin_user_attributes.department" :state="checkValid('ep_admin_user.department')" class="form-control" type="text" id="administrator-department" placeholder="例) 経営戦略部"></b-form-input>
                <p v-if="!!errors['ep_admin_user.department']" class="invalid-feedback d-block">{{ errors['ep_admin_user.department'][0] }}</p>
              </div>

              <div class="form-group">
                <label class="required-addon" for="administrator-profile">プロフィール<span class="badge badge-secondary">任意</span></label>
                <b-form-textarea v-model="ep_company.ep_admin_user_attributes.profile" :state="checkValid('ep_admin_user.profile')" type="text" class="form-control"></b-form-textarea>
                <p v-if="!!errors['ep_admin_user.profile']" class="invalid-feedback d-block">{{ errors['ep_admin_user.profile'][0] }}</p>
              </div>
            </div>
          </div>
        </section>
        <!-- 管理者情報-->

        <div class="btn-container d-flex justify-content-center">
          <button class="button-square btn-cancel" type="button" @click="backToDetail()">もどる</button>
          <button type="submit" class="button-square btn-send">確認する</button>
        </div>
      </form>
    </main>
  </div>
</template>

<script>
  import Form from '../../../common/mixins/form'
  // アップロード画像チェック
  import UploadFileCheck  from "../../../common/mixins/upload_file_check"
  export default {
    mixins: [Form, UploadFileCheck],
    metaInfo: {
      title: '大手企業 プッシュ登録',
    },
    data: function () {
      return {
        days_max: '',
        errors: '',
        was_valid: false,
        previous_route: null,
        uploaded_image: this.prop_admin_user_uploaded_image,
        img_name: this.prop_admin_user_img_name,
        department_admin_user_uploaded_image: this.prop_department_admin_user_uploaded_image,
        department_admin_user_img_name: this.prop_department_admin_user_img_name
      }
    },
    props: {
      ep_company: Object,
      is_audits: Object,
      membership_types: Object,
      industries: Object,
      accept_extensions: Array | String,
      prefectures: Object,
      prop_admin_user_uploaded_image: String,
      prop_admin_user_img_name: String,
      prop_department_admin_user_uploaded_image: String,
      prop_department_admin_user_img_name: String
    },
    methods: {
      confirmEpCompany: function(){
        this.$loading.load(this.$auth.api.post('admin/ep_companies/new_confirm', {
          ep_company: this.ep_company,
          ep_admin_user_attributes: this.ep_company.ep_admin_user_attributes
        })
        .then(response => {
          this.$store.commit('ep_company/setEpCompany', this.ep_company);
          this.$router.push({name: 'AdminEpCompanyConfirm'});
        })
        .catch(error => {
          if(error.response.status == 422) {
            if (error.response.data && error.response.data.errors) {
              this.errors = error.response.data.errors;
              // 確認ボタン押下の確認、falseの時はis-invalidが付与されない
              this.was_valid = true
            }
            // エラー時ページトップにスクロール
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
          }else if(error.response.status == 500) {
            this.$errorHandlers.initial(error);
          }else{
            this.$errorHandlers.ajax(error);
          }
        }));
      },
      // 管理者ユーザー画像変更
      onUserFileChange(e) {
        const files = e.target.files || e.dataTransfer.files;

        // ファイルチェック(/common/mixins/upload_file_check.jsを参照)
        let image_file_error = this.checkUploadImage(files[0]);
        if (image_file_error != ''){
          // エラーの場合はトースト表示
          this.$bvToast.toast(image_file_error, { variant: 'danger', title: 'エラー'});
          return;
        }

        this.ep_company.ep_admin_user_attributes.image_attributes.filename = ''
        this.createUserImage(files[0]);
        this.ep_company.ep_admin_user_attributes.new_image_name = files[0].name;
        this.ep_company.ep_admin_user_attributes.image_size = files[0].size;
      },
      // ファイル読込
      createUserImage(file) {
        const reader = new FileReader();
        reader.onload = e => {
          this.ep_company.ep_admin_user_attributes.uploaded_image = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      // 管理者ユーザー画像削除
      removeUser() {
        this.ep_company.ep_admin_user_attributes.uploaded_image = false;
        this.ep_company.ep_admin_user_attributes.image_attributes.filename = '';
        this.ep_company.ep_admin_user_attributes.uploaded_image = '';
        this.ep_company.ep_admin_user_attributes.new_image_name = '';
        this.ep_company.ep_admin_user_attributes.image_size = '';
        // 一度アップしたものを取り消した後再度同じものをアップすると@changeが発火しないため、refでDOMを直接引っ張ってvalueを消す
        this.$refs.userImageUploader.value = '';
      },
      // 画像ファイルのドラッグアンドドロップ処理
      dropFile(e) {
          this.onUserFileChange(e)
          this.offArea()
      },
      onArea  () { this.inArea = true  },
      offArea () { this.inArea = false },
      backToDetail () {
        this.$store.commit('su_company_edit/initSuCompany');
        if (!!this.previous_route && this.previous_route.name === 'AdminEpCompanyIndex') {
          this.$router.push({path: this.previous_route.fullPath});
        } else {
          this.$router.push({name: 'AdminEpCompanyIndex'});
        }
      },
    },
    computed: {
      uniqIndustries: function () {
        let entries = Object.entries(this.industries);
        entries = entries.filter(([id]) => id !==  this.ep_company.sub_industry_master_id);
        return Object.fromEntries(entries);
      },
      uniqSubIndustries: function () {
        let entries = Object.entries(this.industries);
        entries = entries.filter(([id]) => id !==  this.ep_company.industry_master_id);
        return Object.fromEntries(entries);
      }
    },
    // コンポーネントを描画するルートが確立する前に呼ばれる
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.previous_route = from;
      });
    },
  }
</script>
