<template>
  <div>
    <router-view
      :provisional_su_latest_info="provisional_su_latest_info"
      :asset_masters="asset_masters"
      :industry_asset_masters="industry_asset_masters"
      :accept_extensions="accept_extensions"
      :form.sync="form"
      :main_image.sync="main_image"
      :sub1_image.sync="sub1_image"
      :sub2_image.sync="sub2_image"
      :pitch_file_accept_extensions="pitch_file_accept_extensions"
      :validation_errors="validation_errors"
      :onTemporarily="onTemporarily"
      :onConfirm="onConfirm"
      :onSave="onSave"
      :onFix="onFix"
      :onBack="onBack"
      :is_form_changed="is_form_changed"
    >
    </router-view>
    <CompletedModal
      ref="completed_modal"
      :onModalClose="moveIndexAfterSave"
      :action_text="'登録'"
      :additional_text="'<br>DTVSが内容について確認を行い、<br>大企業へ公開されます。<br>審査完了までお待ちください。'"
      :isNoFade="isNoFade"
    />
  </div>
</template>

<script>
  import CompletedModal from '../../../common/pages/CompletedModal.vue';
  export default {
    metaInfo: {
      title: '最新情報作成',
    },

    components: {
      CompletedModal,
    },

    data() {
      return {
        provisional_su_latest_info: {},
        // 承認済みアセット一覧
        asset_masters: [],
        // 業界に紐づく承認済みアセット一覧
        industry_asset_masters: [],
        // 許容拡張子
        accept_extensions: [],
        // 許容するピッチファイルの拡張子
        pitch_file_accept_extensions: [],

        was_valid: false,
        validation_errors: {},
        previous_route: null,
        is_form_changed: false,

        form: {
          provisional_su_latest_info: {
            su_company_id: '',
            title:  '',
            detail: '',
            registed_type: '',
            exam_status: '',
            // メイン画像
            main_image_title: '',
            // サブ1画像
            sub1_image_title: '',
            // サブ2画像
            sub2_image_title: '',
            // メイン画像
            main_image_attributes: {
              // 画像新規アップロード情報
              // filename: '',
              // size: '',
              // upload_image: '',
            },
            // サブ画像1
            sub1_image_attributes: {
              // 画像新規アップロード情報
              // filename: '',
              // size: '',
              // upload_image: '',
            },
            // サブ画像2
            sub2_image_attributes: {
              // 画像新規アップロード情報
              // filename: '',
              // size: '',
              // upload_image: '',
            },
            // ピッチ資料
            uploaded_pitch_file: '',
            new_pitch_file_name: '',
            pitch_file_attributes: {
              filename: ''
            },
            // 選択アセット
            provisional_su_latest_info_asset_masters_attributes: [
              // {
              //   asset_master_id: '',
              // }
            ],
            // 削除アセット
            destroy_provisional_su_latest_info_asset_masters_attributes: [
              // {
              //   id: '',
              //   asset_master_id: '',
              // }
            ],
            // 登録予定のアセット
            asset_masters_attributes: [
              // {
              //   id: ''
              //   name: ''
              //   // 編集処理用
              //   is_edit: false
              // }
            ],
            // SU企業
            su_company_attributes: {
              id: '',
              // 事業概要
              business_summary: '',
              updated_at: '',
            },
          }
        },
        // メイン画像(表示用)
        main_image: {
          src: '',
          alt: '',
        },
        // サブ1画像(表示用)
        sub1_image: {
          src: '',
          alt: '',
        },
        // サブ2画像(表示用)
        sub2_image: {
          src: '',
          alt: '',
        },
      }
    },

    props: { isNoFade: Boolean },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.init();
    },

    // route.push時に実行
    beforeRouteUpdate(to, from, next) {
      next();
    },

    // コンポーネントを描画するルートが確立する前に呼ばれる
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.previous_route = from;
      });
    },

    // メソッド定義
    methods: {
      // 初期化
      init() {
        this.getProvisionalSuLatestInfo();
      },

      getProvisionalSuLatestInfo() {
        this.$loading.load(
          this.$auth.api.get(
            'su/provisional_su_latest_infos/form_init'
          ).then(res => {
            // SU最新情報
            this.provisional_su_latest_info = res.data.provisional_su_latest_info;
            // 承認済みアセットマスタ一覧
            this.asset_masters = res.data.approval_asset_masters;
            // SU企業のメイン業界に紐づくアセットマスタ一覧
            this.industry_asset_masters = res.data.industry_asset_masters;
            // 許容拡張子
            this.accept_extensions = res.data.accept_extensions;
            // 許容するピッチファイルの拡張子
            this.pitch_file_accept_extensions = res.data.pitch_file_accept_extensions;

            // SU企業ID
            this.form.provisional_su_latest_info.su_company_id = this.provisional_su_latest_info.su_company.id;

            // SU企業 私たちについて
            this.form.provisional_su_latest_info.su_company_attributes.id = this.provisional_su_latest_info.su_company.id;
            this.form.provisional_su_latest_info.su_company_attributes.business_summary = this.provisional_su_latest_info.su_company.business_summary;
            // SU企業 更新日時
            this.form.provisional_su_latest_info.su_company_attributes.updated_at = this.provisional_su_latest_info.su_company.updated_at;

            // 入力項目の監視を開始（非同期処理でフォーム変更と監視開始のタイミングがずれるためここで実施する）
            this.$watch('form', function(){ this.is_form_changed = true }, { deep: true });
            this.$watch('main_image', function(){ this.is_form_changed = true }, { deep: true });
            this.$watch('sub1_image', function(){ this.is_form_changed = true }, { deep: true });
            this.$watch('sub2_image', function(){ this.is_form_changed = true }, { deep: true });

          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        )
      },

      onTemporarily() {
        // 下書きボタン経由のためtrueをonConfirmに渡す
        this.onConfirm(true);
      },

      // 確認ボタン押下
      onConfirm(is_draft = false) {
        // 一時保存
        if(is_draft) {
          // 登録種別 新規登録
          this.form.provisional_su_latest_info.registed_type = 'registration'
          // 審査ステータス 下書き
          this.form.provisional_su_latest_info.exam_status = 'draft'
        }
        else {
          // 登録種別 新規登録
          this.form.provisional_su_latest_info.registed_type = 'registration'
          // 審査ステータス 未承認
          this.form.provisional_su_latest_info.exam_status = 'undecided'
        }

        this.$loading.load(
          this.$auth.api.post(
            'su/provisional_su_latest_infos/confirm', this.form
          ).then(res => {
            // 確認画面を表示
            this.$router.push({name: 'SuProvisionalSuLatestInfoNewConfirm'});
          }).catch(err => {
            // バリデーションエラー
            if (err.response.status == 422) {
              this.validation_errors = err.response.data.errors;
            }
            else if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else {
              this.$errorHandlers.ajax(err);
            }
          })
        );
      },

      // 保存ボタンo押下時処理
      onSave() {
        this.$loading.load(
          this.$auth.api.post(
            'su/provisional_su_latest_infos', this.form
          ).then(res => {
            if (this.form.provisional_su_latest_info.exam_status != 'draft'){
              // 下書き保存時以外は更新完了モーダルを表示
              this.$refs.completed_modal.open();
            }
            else{
              this.moveIndexAfterSave()
            }
          }).catch(err => {
            // バリデーションエラー
            if (err.response.status == 422) {
              this.validation_errors = err.response.data.errors;
            }
            else if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else {
              this.$errorHandlers.ajax(err);
            }
          })
        );
      },

      // 保存後の画面遷移処理
      moveIndexAfterSave(){
        if (!!this.previous_route && this.previous_route.name === 'SuProvisionalSuLatestInfoIndex') {
          this.$router.push({path: this.previous_route.fullPath, query: {toast_type: 'created'}});
        }
        else {
          this.$router.push({name: 'SuProvisionalSuLatestInfoIndex', query: {toast_type: 'created'}});
        }
      },

      // 修正ボタン押下時処理
      onFix() {
        this.$router.push({name: 'SuProvisionalSuLatestInfoNew'});
      },

      // 戻るボタン押下時処理
      onBack() {
        if (!!this.previous_route && this.previous_route.name === 'SuProvisionalSuLatestInfoIndex') {
          this.$router.push({path: this.previous_route.fullPath});
        }
        else if (!!this.previous_route && this.previous_route.name === 'SuProvisionalSuLatestInfoShow') {
          this.$router.push({path: this.previous_route.fullPath});
        }
        else {
          this.$router.push({name: 'SuProvisionalSuLatestInfoIndex'});
        }
      },

    },
  }

</script>