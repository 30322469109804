<template>
  <router-view :user="user" :image_attributes="image_attributes" :newly="true" :currentUserId="currentUserId" ></router-view>
</template>

<script>
export default {
  metaInfo: {
    title: 'ユーザー作成',
  },
  name: 'UserNew',

  props: { currentUserId: Number },

  data(){
    return {
      user: {
        account_type: null
      },
      image_attributes: {id: "", filename: ""}
    }
  }
}
</script>
