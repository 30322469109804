<template>
  <fieldset class="form-group">
    <div class="row">
      <div class="col-3 col-form-label pt-0">
        <legend class="mb-0">利用規定</legend>
        <a class="dl-link text-black-50" :href="terms_of_service" download="six brain利用規定.pdf">ダウンロード<i
          class="far fa-file-pdf"></i></a>
      </div>
      <div class="col-9">
        <div class="custom-control custom-checkbox">
          <input class="custom-control-input" type="checkbox" id="request_check1" :disabled="!term_enabled" v-model="term_accepted">
          <label class="custom-control-label" for="request_check1">「利用規定」を読み、すべて同意します。</label>
        </div>
        <textarea id="term_textarea" v-on:scroll="onScrollTerm" readonly class="form-control text-black-50">
six brain 利用規約
前文
本利用規約（以下「本規約」といいます。）は、本ウェブサイト（第 2 条において定義されます。） においてデロイト トーマツ ベンチャーサポート株式会社（以下「当社」といいます。）が登録会員（以下「登録会員」といいます。）に対して提供する本サービス（第 2 条において定義されます。）の内容、 本サービスの提供条件その他本サービスの提供及び利用に関する当社及び登録会員間の権利義務関係を定めるものです。本サービスをご利用いただく場合には、事前に本規約の全文をお読みいただいた上で、本規約に同意していただく必要があります。
第１条（本規約の目的及び適用範囲）
1. 本規約は、本サービスの内容、本サービスの提供条件その他本サービスの提供及び利用に関する当社及び登録会員間の権利義務関係を定めることを目的とするものです。本サービスの提供及び利用に関する当社と登録会員の間のいかなる関係についても、本規約が適用されるものとします。
2. 本規約の内容と、その他の本ウェブサイト又は本サービスに関する説明資料等における記載の間に齟齬がある場合には、本規約の規定が優先して適用されるものとします。
3. 本規約のいずれかの規定又はその一部が、無効又は執行不能と判断された場合であっても、他の規定又は当該規定の他の部分は、引き続き完全にその効力を有するものとします。
第２条（定義）
本規約において、以下の用語は、それぞれ以下に定める意味を有するものとします。
（1）「本ウェブサイト」とは、当社が管理運営するウェブサイトである「six brain」（https://www.sixbrain.ai/）をいいます。
（2）「本サービス」とは、当社が本ウェブサイトにおいて提供する、協業案件（M&A を含み、以下同様とします。）に関する情報を登録、公開、検索するためのプラットフォーム、協業案件に関する進捗状況管理システム、その他の付随サービスの総体としての協業先（M&A を含む。）のコミュニケーションサービスをいいます。本サービスの具体的な内容、利用方法等につきましては、別途当社が定めるユーザーガイド（（スタートアップ企業用：https://www.sixbrain.ai/pages/faq_startup または大手企業用：https://www.sixbrain.ai/pages/faq_enterprise のうち、各登録会員に適用のあるものをご覧ください）をいい、以下「ユーザーガイド」という。)によります。
（3）「知的財産権」とは、特許権、実用新案権、意匠権、著作権、商標権その他の一切の知的財産権をいいます。
第３条（会員登録及び登録情報）
1. 本サービスの利用を希望する者は、当社に対し、当社が要求する所定の会員情報を、six brain アカウント登録申請（スタートアップ企業用：https://manage.sixbrain.ai/su/request_fl ow1_edit または大手企業用：https://manage.sixbrain.ai/ep/request_info）への入力、その他の当社が定める方法にて当社に提供するとともに、所定のウェブページにおいて本規約の内容に関して同意をした上で、本サービスに関する会員登録を申請するものとします。なお、当該会員登録申請をしたことをもって、本サービスの利用を希望する者は、本規約に同意したものとみなします。
2. 前項の登録申請者について、当社は、当社が定める基準に従い、会員登録の可否を判断し、その結果を登録申請者に通知します。
3. 当社によって会員登録を認められた登録申請者は、前項の通知に記載されている所定のウェブページにおいて初回のログインをすることをもって、当社及び登録会員間において本規約に基づく契約関係が有効に成立するものとします。
4. 登録された会員情報は、常に完全かつ最新のものでなければならないものとします。
5.登録会員は、登録済みの会員情報に変更等があった場合には、直ちに完全かつ最新の会員情報を six brain 運営事務局への電子メールでの連絡その他の当社が定める方法にて当社に通知するものとします。当該変更等については、当社の承認を得なければならず、当社の承認が得られるまで当該変更等はその効力を生じないものとします。
第４条（アカウント ID 及びパスワードの管理）
1. 当社は、登録会員ごとに、1 個の管理者アカウント ID を発行します。登録会員は、前条第 3 項に規定する初回に行うログインに先立ち、自らの管理者アカウント ID を管理する者（以下「管理者」といいます。）を指定し、当社の承認を得なければならないものとします。また、登録会員は、管理者の氏名その他の管理者アカウント ID に関する会員情報の変更については、あらかじめ当該変更後の情報を当社に通知した上で当社の承認を得なければならず、当社の承認が得られるまで当該変更はその効力を生じないものとします。
2. 当社は、登録会員に関して、当該登録会員の管理者からの申請があった場合、登録会員の会員種別および各プランに従い、当該登録会員の各部署および担当者にそれぞれ部署アカウント ID および担当者アカウント ID の登録を認めるものとします（以下、管理者アカウント ID 並びに部署アカウント ID 及び担当者アカウント ID を総称して「アカウント ID」といいます。）。管理者は、本サービスを利用する登録会員に所属する各部署及び担当者ごとに 1 個の部署アカウント ID 及び担当者アカウント ID の申請および登録をしなければならず、登録会員は、1 個の部署アカウント ID 及び担当者アカウント ID を複数の部署および担当者に使用させてはならないものとします。なお、本項に基づく部署アカウント ID 及び担当者アカウント ID の登録は、管理者が、部署アカウント ID であれば部署管理者の、担当者アカウント ID であれば当該担当者それぞれの氏名、連絡先等の当社が定める情報を会員情報の一部として、本ウェブサイトの登録情報管理ページ上で登録する他、当社が定める方法にて当社に通知する方法にて行うものとします。
3. 登録会員のアカウント ID を使用して行われた一切の行為は当該登録会員に帰属し、当該行為については、当社は一切責任を負わず、当該登録会員が全責任を負うものとします。
4. 登録会員は、本サービスに関するアカウント ID 及びパスワードについて、自らの責任においてその秘密を保持しなければならず、第三者に利用させてはならないものとします。
5. 本サービスに関するアカウント ID 又はパスワードの盗用、漏えい、第三者による不正アクセスその他の不正使用等によって当社又は他の登録会員に生じた損害、損失、費用及び負担（以下「損害等」といいます。）については、当社は一切責任を負わず、登録会員が全責任を負うものとします。登録会員は、当社又は他の登録会員に生じた全ての損害等を補償し、当社に一切責任を負わせないものとします。
6. 本サービスに関するアカウント ID 又はパスワードが盗用され又は不正使用されていることが判明した場合には、登録会員は、直ちにその旨を当社に通知するとともに、その後の対応については当社の指示に従うものとします。
第５条（本サービスの利用）
1. 登録会員は、本サービスを利用するに当たり、本規約及びユーザーガイドを遵守しなければならないものとします。
2. 登録会員は、本サービスの操作を第三者に委託する場合、本規定に基づく登録会員の義務と同等の義務を当該第三者に負わせるものとします。但し、それにより登録会員の義務及び責任を免れることはできません。
第６条（案件情報の登録）
1. 登録会員は、自ら協業案件の当事者として、当社が別途定める協業案件に関する情報（登録会員自身の会員情報を含みます。以下「案件情報」といいます。）を本ウェブサイト上又は当社が別途定める方法により登録し、当社が定める基準に従い、当社の承認を得たうえで、公開することができるものとします。この場合、登録会員は、案件情報を自らの責任と判断において登録及び公開するものとし、案件情報の登録及び公開について全責任を負うものとします。なお、登録会員は、第 11 条第 16 号において禁止される取引スキームを内容とする案件情報を登録及び公開することはできないものとします。
2. 本サービスを通じて案件情報を登録及び公開した協業案件に関するコミュニケーションを行うためには、別途当社が許可した場合を除き、当該協業案件にかかる両当事者がいずれも会員登録を行っていることが必要になります。
3. 登録会員が登録及び公開した案件情報が本規約若しくは当社が定める基準に違反する若しくは事実に反すると当社が判断した場合、その他案件情報を登録若しくは公開をすることが適切ではないと当社が判断した場合には、当社は、案件情報の公開を中止および登録を削除することができるものとします。この場合、当社は、当社の独自の判断により、登録会員に対して案件情報の修正を求めた上で、当該修正された内容に従い案件情報の公開を再開することができるものとします。
4. 案件情報は、常に完全かつ最新のものでなければならないものとします。登録会員は、案件情報に変更等があった場合には、直ちに本ウェブサイトの登録案件情報管理ページ上において又はその他当社に通知することにより、案件情報を修正するものとします。
第７条（他の登録会員への対応義務）
1. 登録会員は、他の登録会員から、自らが案件情報を登録した協業案件に関する連絡を受けた場合、自ら、当該他の登録会員に対し、本ウェブサイト上のメッセージ機能を利用して、又はその他当社が定める方法により、回答等を行わなければならないものとします。
2. 登録会員は、当社が本ウェブサイト上のメッセージ機能を用いた登録会員間の連絡や回答等の内容を知り得ることを予め承諾するものとします。
3.当社は、登録会員が他の登録会員から協業案件に関する連絡を受けたにもかかわらず、当該他の登録会員に対して回答等をしない等、本サービスにおける登録会員間のコミュニケーションについて一切責任を負わないものとします。
第８条（当社への通知回答義務）
1. 登録会員は、本サービスによるコミュニケーションをした両当事者の間で協業に係る条件に関する拘束力ある契約（以下「最終契約」といいます。）が締結された場合、当該協業が実行された場合、当該協業に関する最終契約を締結できないことが確定した場合その他当社が定める場合に、直ちに所定の方法により当社に通知するものとします。
2. 登録会員は、本サービスの利用状況又は協業案件の進捗状況に関して当社から問い合わせを受けた場合、これに誠実に回答しなければならないものとします。
第９条（他の登録会員に関する登録情報の目的外使用の禁止）
1. 登録会員は、本サービスを通じて取得した他の登録会員に関する会員情報及び案件情報、並びに、当社が別途登録会員に提供した、登録会員以外の企業（以下「特定企業」という。）に関する企業情報及び案件情報を、自らが本ウェブサイトに案件情報を登録した協業案件の相手方候補の検索並びに当該協業案件の検討、協議、交渉及び実行のためにのみ使用するものとし、その他の目的（商業目的であるか否かを問いません。）に一切使用しないものとします。
2. 登録会員は、他の登録会員に関する会員情報及び案件情報（一般に公開されている情報を除きます。）並びに特定企業に関する企業情報及び案件情報を機密情報として厳重かつ適正に取り扱うものとし、第三者に開示又は漏洩しないものとします。
3. 登録会員は、他の登録会員による会員情報及び案件情報並びに特定企業に関する企業情報及び案件情報の使用及び管理に関して、当社に対して訴訟提起その他のいかなるクレームもすることができないものとします。登録会員は、自らによる会員情報及び案件情報の使用及び管理に関し、他の登録会員その他の第三者から当社に対して訴訟提起その他のクレームがなされた場合、かかる訴訟その他のクレームについて、当社が一切責任を負わず、当社が被った損害等の一切を補償することに同意し、また、当社の要請があった場合は、自らの責任及び費用負担によりこれを解決するものとします。
第１０条（料金）
1. サービス利用料
登録会員は、会員登録後、会員種別および各プランに従い、別途定めるサービス利用料を当社に支払うものとします。
2. 登録会員は、前項及び別途当社が定める料金を、当社が別途発行する請求書を受領した日の属する月の翌月末日までに、当社が別途指定する銀行口座に送金する方法により支払うものとします。当該送金にかかる手数料は登録会員が負担するものとします。
3. 登録会員が本条に基づく料金の支払を遅滞した場合には、その時点での日本における法定利率による遅延損害金を当社に支払うものとします。
4. 第 1 条第 2 項並びに本条第 1 項の規定にかかわらず、当社が、特定の登録会員又は期間における本ウェブサイト及び本サービスの利用に係る料金として、特別価格を提示した場合には、当該特別価格が適用されるものとします。
5. 本条に基づき当社に支払われた本ウェブサイト及び本サービスの利用に係る料金は、いかなる場合においても当社から登録会員に返金することはしないものとします。また、本ウェブサイト及び本サービスの利用並びに協業案件に係る交渉、進捗管理、実行その他一切の関連作業に係る通信費その他の経費については、登録会員がその責任において負担するものとし、当社はその責任を負いません。
第１１条（禁止事項）
登録会員は、本サービスを利用するに当たって、以下の各号に該当する行為をしてはならないものとします。
（1）犯罪行為その他法令（日本国の法令に限らず、諸外国の法令を含みます。以下同様です。）等に違反する行為
（2）当社、他の登録会員その他の第三者に対する詐欺又は脅迫行為
（3）当社、他の登録会員その他の第三者の営業を妨害し又は信用を毀損する行為
（4）当社、他の登録会員その他の第三者の知的財産権、名誉権、プライバシー権その他の権利又は利益を侵害する行為
（5）以下に該当する表現を含む内容のメッセージを本ウェブサイト上に登録若しくは投稿し又は当社、他の登録会員その他の第三者に送信等する行為
  ア 暴力的又は威嚇的な表現
  イ わいせつ又はひわいな表現
  ウ 特定の人種、民族、宗教、性的指向等に対する差別を誘引又は助長する表現
  エ 上記のほか、他の登録会員その他の第三者に不快感を与える表現
（6）チェーンメール、スパムメールその他の迷惑メールを当社、他の登録会員その他の第三者に送信等する行為
（7）コンピューター・ウィルスその他の有害なコンピューター・プログラムを拡散する行為
（8）当社、他の登録会員その他の第三者になりすます行為
（9）当社、他の登録会員その他の第三者の商号、ロゴその他の表示を用いて、これらの者との関係を不当に誤認させる可能性がある情報を登録する行為
（10）他の登録会員のアカウント ID 又はパスワードを盗用又は不正使用する行為
（11）本ウェブサイトのネットワーク又はシステム等に過度な負荷をかける行為
（12）本ウェブサイトのネットワーク又はシステム等に不正にアクセスする行為
（13）広告、宣伝、勧誘、営業行為その他本サービスの趣旨に反する行為
（14）本ウェブサイトおよびユーザーガイドを含む当社の指示又は明示された意図に反して、協業の当事者その他の法人又は個人を識別又は特定するに足りる情報を登録すること
（15）会員情報、案件情報若しくは協業の進捗状況に関して、虚偽、不正確若しくは誤解を招く内容の情報を登録若しくは送信すること又は適時に必要な変更・更新等を怠ること
（16）上場会社などの有価証券報告書提出会社が発行する株式その他の有価証券の売買・発行（第三者割当増資による株式の発行を含みます。）や、有価証券を対価とする資産や事業の譲渡等、金融商品取引法その他の法令により規制される若しくはそのおそれがあると当社が判断する、又は、これらの規制の観点から本ウェブサイト若しくは本サービスの適切な管理運営及び提供を妨げる若しくはそのおそれがあると当社が判断する取引スキームを内容とする案件情報を登録し又は当該案件情報に係る協業を実行すること
（17）不当に本サービスの利用に係る料金の支払義務を免れようとする行為
（18）上記のほか、本ウェブサイト又は本サービスの適切な管理運営及び提供を妨げる又はそのおそれがあると当社が判断する行為
第１２条（本サービスの一時停止等）
1. 当社は、以下の各号のいずれかに該当する場合には、本サービスの全部又は一部の提供を一時停止又は中断することができるものとします。この場合、当社は、事前の通知が可能である場合には事前に、不可能である場合には本サービスの全部又は一部の提供を一時停止又は中断した後合理的期間内に、その旨を公表し、又は、登録会員に通知するものとします。
  （1）本ウェブサイトに係るサーバー、ネットワークその他のコンピューター・システム又は通信回線、設備等の点検、保守、修理又は変更作業を行う場合
  （2）本ウェブサイトに係るサーバー、ネットワークその他のコンピューター・システム又は通信回線、設備等の使用が火災、天災地変、戦争、暴動、内乱、感染症、ストライキ、争議行為、法令の制定・改廃等の不可抗力により停止又は中断した場合
  （3）行政当局又は裁判所等から本サービスの全部又は一部の提供を一時停止又は中断するよう指導、勧告、命令等を受けた場合
  （4）火災、天災地変、戦争、暴動、内乱、感染症、ストライキ、争議行為、法令の制定・改廃、その他の不可抗力または当社の責に帰さない事由により本サービスの全部または一部の提供が困難となる場合。
  （5）上記のほか、当社が合理的理由により本サービスの全部又は一部の一時停止又は中断が必要であると判断した場合
2. 前項の場合に加えて、当社は、当社の合理的判断により、いつでも本サービスの内容を変更し又は提供を終了することができるものとします。当社は、本サービスの提供を終了する場合には、事前にその旨を公表し、かつ、登録会員に通知するものとします。当社が本サービスの提供を終了する場合には、当該終了の時点において当社及び登録会員間の本規約に基づく契約関係は終了するものとします。
3. 登録会員は、前二項に基づく措置がなされた場合でも、本ウェブサイト又は本サービスに関して当社、他の登録会員その他の第三者に負う債務及び義務を一切免れることはできないものとします。
4. 当社は、本条に基づき当社が行った措置により登録会員に損害等が生じた場合でも、当該損害等について一切責任を負わないものとします。
第１３条（知的財産権及び情報の帰属）
1. 本ウェブサイト及び本サービスに関する知的財産権は全て当社又は当社にライセンスを許諾しているライセンサーに帰属するものとし、本ウェブサイト及び本サービスに対する会員登録並びに本サービスの提供は、何ら当該知的財産権の登録会員に対するライセンスを意味するものではないものとします。
2. 登録会員は、本ウェブサイトにおいて自らが登録、入力及び送信等した情報について、自らが登録、入力及び送信等するための適法な権利を有していること、当該情報が真実かつ正確であること及び当該情報の登録、入力及び送信等が第三者のいかなる権利又は利益も侵害していないことを、当社に対して表明保証するものとします。
3. 登録会員が本ウェブサイトにおいて登録、入力及び送信等した情報並びにこれらの情報に由来する派生情報に関する権利は、引き続き当該登録会員に帰属するものとします。但し、当社は、当該情報を本ウェブサイト及び本サービスの管理運営、提供および改善等のために必要な限度において、自由に無償で使用することができ、登録会員は、当社に対して、当該情報の使用を許諾するものとします。
第１４条（当社による会員登録の抹消等）
1. 当社は、登録会員が以下の各号のいずれかに該当する場合には、何ら事前に通知又は催告をすることなく、当該登録会員に対する本サービスの提供を停止し、会員登録を抹消することができるものとします。以下の第 1 号ないし第 3 号のいずれかに該当することが判明した場合には、登録会員は、直ちに当社に対して通知するものとします。
  （1）本規約の規定に違反した場合（表明保証した事項が真実でなく又は不正確であった場合を含みます。）
  （2）登録情報に虚偽又は不正確な事実があることが判明した場合
  （3）支払停止若しくは支払不能となり又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する倒産手続の開始の申立てがあった場合
  （4）当社が登録会員に対し、「独立性の方針」への違反によって、有限責任監査法人トーマツの所属するネットワーク（日本 公認会計士協会倫理規則の定義による。）内のネットワーク・ファーム（日本公認会計士協会倫理規則の定義による。）の独立性を毀損する結果をもたらす状況を認識した旨通知した場合。なお、「独立性の方針」とは、有限責任監査法人トーマツの所属するネットワーク内のネットワーク・ファームが監査又は保証業務を実施するにあたり求められる、独立性に関わる公認会計士法等の法令及び日本公認会計士協会倫理規則等並びに各国の相当する法令、規則等をいう。
  （5）上記のほか、当社が会員登録の継続を適切でないと合理的に判断した場合
2. 前項に基づく会員登録の抹消がなされた場合、当該抹消の時点において当社及び登録会員間の本規約に基づく契約関係は終了するものとします。この場合、登録会員は、本ウェブサイト及び本サービスの利用に関して当社に負う債務及び義務の一切について当然に期限の利益を失い、直ちに当該債務及び義務を履行しなければならないものとします。
3. 登録会員は、第１項に基づく会員登録の抹消がなされた場合でも、本ウェブサイト又は本サービスに関して当社、他の登録会員その他の第三者に負う債務及び義務を一切免れることはできないものとします。
4. 当社は、本条に基づき当社が行った会員登録の抹消により登録会員に損害等が生じた場合でも、当該損害等について一切責任を負わないものとします。
第１５条（登録会員による会員登録の抹消等）
1. 登録会員は、当社が定める方法で当社に通知することにより、いつでも本サービスの利用を停止し、会員登録を抹消することができます。
2. 前項に基づき会員登録を抹消した場合、当該抹消の時点において当社及び登録会員間の本規約に基づく契約関係は終了するものとします。この場合、登録会員は、本ウェブサイト及び本サービスの利用に関して当社に負う債務及び義務の一切について当然に期限の利益を失い、直ちに当該債務及び義務を履行しなければならないものとします。
3. 登録会員は、第１項に基づき会員登録を抹消した場合でも、本ウェブサイト又は本サービスに関して当社、他の登録会員その他の第三者に負う債務及び義務を一切免れることはできないものとします。
4. 登録会員は、第１項に基づき会員登録を抹消した場合でも、再度会員登録を受けた上で本ウェブサイト及び本サービスを利用することができます。
第１６条（保証の否認及び免責）
1. 当社は、本ウェブサイト及び本サービスは「現状有姿」で提供され、登録会員の特定の目的に適合すること、登録会員が期待する機能、商品的価値、正確性若しくは有用性を有すること、登録会員による本ウェブサイト及び本サービスの利用が登録会員に適用される法令等に適合すること、本サービスの利用に不具合が生じないこと、又は本ウェブサイト及び本サービスにおいて当社又は他の登録会員が提供する情報が真実かつ正確であることのいずれについても何ら保証するものではありません。
2. 当社は、システムやサーバーのダウン、当社による本サービスの提供の一時停止、中断、終了、利用不能又は変更、登録情報の削除又は消失、会員登録の抹消、本サービスの利用によるデータの喪失又は機器の故障若しくは損傷その他理由の如何を問わず、また、債務不履行又は不法行為その他請求の原因の如何を問わず、本サービスに関連して登録会員が被った損害等について一切の責任を負いません。
3. 当社は、本サービスの一部として、登録会員のニーズに相応する候補会社の紹介、協業条件に関する助言や交渉等、登録会員の協業案件を実現するために必要な行為を行う義務を負担するものではなく、また、本サービスの内容には、かかる行為を提供することは含まれないものとします。登録会員は、自らの責任と判断で本サービスを利用し、協業案件を行うものとし、当社は、本サービスに関連して登録会員と他の登録会員又は第三者との間において生じた取引、契約、連絡、交渉、協議、紛争等について一切の責任を負わないものとします。
第１７条（秘密保持義務）
1. 登録会員は、本サービスの利用に関連して取得した当社、他の登録会員その他の第三者の機密情報について、当該機密情報の帰属主体の事前の書面による承諾がある場合を除き、秘密に取り扱うものとし、第三者に開示又は漏えいしないものとします。また、登録会員は、本サービスの利用に関連して取得した当社の機密情報（本ウェブサイト及び本サービスに関する情報・仕組み・ノウハウ・プログラムソース等を含みますがこれらに限られません。）について、自ら又は第三者のために利用してはならないものとします。
2 当社は、登録会員が登録した会員情報及び案件情報その他本ウェブサイト及び本サービスの管理運営及び提供等に関連して取得した登録会員の機密情報について、秘密に取り扱うものとし、当社が、情報の開示について別途登録会員に承諾を得た場合を除き、登録会員及び本サービスの管理運営に必要な範囲でかつ本規約において当社が負っている秘密保持義務と同程度の義務を負わせている第三者を除く第三者に開示又は漏えいしないものとします。
3 前項の定めにかかわらず、当社は、登録会員の承諾があった場合、提案書、ウェブサイトその他公表される資料等において、本サービスの宣伝、広告等のため、登録会員が本サービスを利用していた事実および本サービス利用に関する登録会員担当者のコメント等を第三者に開示することができる。また、当該開示にあたり、登録会員の名称および標章を使用ならびに登録会員の役員や従業員等の氏名（所属部署、役職名を含む）、肖像を複製、加工、修正その他の方法によって使用することができるものとします。
第１８条（個人情報の取扱い）
当社は、登録会員又はその管理者、担当者その他の関係者に関する個人情報を、当社のプライバシーポリシー（ https://www2.deloitte.com/jp/ja/legal/privacy.html）に従って取り扱うものとします。
第１９条（確認事項）
1. 登録会員は、当社に対し、以下の要件について充足し、遵守することを表明し、確約するものとします。
(1)登録会員は、自らに適用のある一切の法令等（贈収賄に関する法令等を含むが、これらに限られない）に違反していないこと。
(2)登録会員は、自らの事業を 行うにあたり必要となる行政機関等の許認可、登録、承諾、同意、届出等の手続きをすべて適法に履践済みであること。
(3)登録会員は、自らが、暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者（以下、これらを「暴力団員等」という。）に該当せず、および次の各号のいずれにも該当しないこと、また将来にわたっても該当しないことを確約すること。
  (ア)暴力団員等が経営を支配していると認められる関係を有すること
  (イ)暴力団員等が経営に実質的に関与していると認められる関係を有すること
  (ウ)自己、自社もしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること
  (エ)暴力団員等に対して資金等を提供し、または便宜を供与するなどの関与をしていると認められる関係を有すること
  (オ)役員または経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること
  (カ)登録会員自らまたは第三者を利用して、次の事項に該当する行為を行わないこと。
    ①　暴力的な要求行為
    ②　法的な責任を超えた不当な要求行為
    ③　取引に関して、脅迫的な言動をし、または暴力を用いる行為
    ④　風説を流布し、偽計を用いまたは威力を用いて第三者の信用を毀損し、または第三者の業務を妨害する行為
    ⑤　その他前各号に準ずる行為
2. 当社は、登録会員が、前項の表明および確約に違反した場合には、直ちに会員登録を抹消することができるものとします。この場合において、当社は、登録会員に対して損害の賠償はいたしません。また、当該抹消され登録会員は、本条の違反により、当社に損害を生じさせた場合、当社が被った全ての損害を賠償するものとします。なお、登録会員は、当社の名誉の維持および回復のために必要な事項の実施につき、自らの費用負担により当社に協力するものとする。
3. 登録会員が、前項の事項について充足できない、または遵守できないことを認識した場合は、可能な限り速やかに当社へ通知するものとします。
第２０条（通知）
1. 登録会員が当社に対して本サービス又は本規約に関する通知を行う場合には、登録会員は、当社が定める宛先に当社が定める方法にて通知しなければならないものとします。
2. 本規約の変更に関する通知その他当社が登録会員に対して本サービス又は本規約に関する通知を行う場合には、当社は、管理者の登録メールアドレスに対する電子メールの送信、本ウェブサイト上での管理者アカウントに対するメッセージの送信その他当社が適当と認める方法で通知するものとします。なお、登録会員が登録情報の更新を怠っていたため、かかる通知が到達しなかった場合、当該通知は登録情報を適正に更新していれば到達していたであろう時点において到達したものとみなし、当社はかかる不到達に関して一切責任を負わないものとします。
第２１条（本規約上の地位の譲渡）
1. 登録会員は、当社が事前に書面によって承諾しない限り、本規約上の地位又は本規約に基づく権利義務を、第三者に対して、譲渡、移転、担保設定その他の処分をすることはできないものとします。
2. 当社は、本サービス及び本ウェブサイトに係る事業を他社に譲渡する場合には、本規約上の地位又は本規約に基づく権利義務を、当該事業の譲受人に譲渡することができるものとします。
第２２条(完全合意)
本規約は、本サービスの提供条件その他本サービスの提供及び利用に関する当社及び登録会員間の権利義務関係のすべてを示すものであり、本規約において特に明示されていない提案、取り決め、および合意については両者を法的に拘束しません。
第２３条（準拠法及び管轄裁判所）本規約は、日本法を準拠法とし、日本法に従って解釈されるものとします。本規約に起因し又は関連して生じる一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
第２４条（本規約の変更）当社は、いつでも本規約を変更することができるものとします。当社は、本規約を変更した場合には、直ちに登録会員の管理者にその旨を通知します。登録会員が本規約の変更後も本サービスの利用を継続するためには、その管理者が当該変更内容に同意する必要があります。なお、本規約変更の通知後、登録会員が本サービスを継続して利用した場合、当該登録会員は当該変更内容に同意したものとみまします。
第２５条（存続条項）
第 1 条、第 4 条第 3 項及び第 5 項、第 5 条第 2 項、第 6 条第 1 項、第 9 条、第10 条、第 12 条第 3 項及び第 4 項、第 13 条、第 14 条第 2 項ないし第 4 項、第15 条第 2 項ないし第 4 項、第 16 条ないし第 18 条、第 19 条第 2 項、第 20 条ないし第 22 条並びに本条の規定は、当社及び登録会員間の本規約に基づく契約関係が終了した後も引き続き有効にその効力を有するものとします。但し、第17 条第 1 項および第 2 項については、当社及び登録会員間の本規約に基づく契約関係が終了した後 3 年間に限り、引き続き有効にその効力を有するものとします。
                                                                                                                                                                                                        以上
制定：2020 年 6 月 1 日
改定：2022 年 2 月 1 日
        </textarea>
      </div>
    </div>
  </fieldset>
</template>

<script>
  export default {
    props: {

    },
    data() {
      return{
        send_enabled: false,
        term_enabled: false,
        term_accepted: false,
        society_accepted: false,
        terms_of_service: '',
      }
    },
    methods: {
      getFiles() {
        this.$loading.load(
          this.$auth.api.get('registration_auths/new')
          .then(res => {
            this.terms_of_service = res.data.terms_of_service;
          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        );
      },
      onScrollTerm() {
        // 最下部までスクロールしたらチェックボックスを有効にする
        // ウィンドウサイズによって誤差が出る場合があるため、アクティブ判定領域を100pxほど手前にする
        let element = document.getElementById("term_textarea");
        if (element.scrollHeight - element.clientHeight - 100 <= element.scrollTop) {
          this.term_enabled = true;
        }
      },
    },
    watch: {
      term_accepted(new_value, old_value) {
        this.$emit('checkValid', this.term_accepted)
      },
      policy_accepted(new_value, old_value) {
        this.$emit('checkValid', this.term_accepted)
      },
      society_accepted(new_value, old_value) {
        this.$emit('checkValid', this.term_accepted)
      },
      'form.email'(new_value, old_value) {
        this.$emit('checkValid', this.term_accepted)
      },
    },
    created() {
      this.getFiles();
    },
  }
</script>

<style scoped>

</style>