<template>
  <main>
    <Header :accountType="accountType" />
    <MiniHeader :accountType="accountType" />
    <div class="request-flow-image">
      <ul>
        <li class="flow-title"><img src="~/images/request_flow_title.svg" alt="Step"></li>
        <li class="flow-01"><img src="~/images/request_flow1_on.svg" alt="Step1 メールアドレス入力"></li>
        <li class="flow-02"><img src="~/images/request_flow2.svg" alt="Step2 メール送信"></li>
        <li class="flow-03"><img src="~/images/request_flow3.svg" alt="Step3 申請情報入力"></li>
        <li class="flow-04"><img src="~/images/request_flow4.svg" alt="Step4 申請情報確認"></li>
        <li class="flow-05"><img src="~/images/request_flow5.svg" alt="Complete 申請完了"></li>
      </ul>
    </div>
    <section class="request-inner mt-6">
      <form class="row-form">
        <div class="form-group row">
          <label class="col-3 col-form-label" for="request_mail">メールアドレス<br class="breakpoint-tab">（ログインID）</label>
          <div class="col-9">
            <input
              v-model="form.email"
              class="form-control"
              type="text"
              id="request_mail"
              placeholder="例) t-deloitte@sixbrain.co.jp"
              value=""
              @keydown.self.prevent.enter
              wovn-ignore
            >
            <small class="form-text text-secondary">ご入力いただいたメールアドレスに登録申請フォームのURLをお送りします。</small>
          </div>
        </div>
        <UsePolicy @checkValid="checkValid" />

        <div class="btn-container d-flex justify-content-center mt-5">
          <button class="button-square btn-send" :class="send_enabled ? '' : 'disabled'" type="button"
                  @click="onClickSend">送信する
          </button>
        </div>
      </form>
    </section>
  </main>
</template>

<script>
  import Header from "./Header";
  import MiniHeader from "./MiniHeader";
  import UsePolicy from "./UsePolicy";

  export default {
    metaInfo: {
      title: 'six brainアカウント登録申請'
    },
    name: "New",
    components: {
      MiniHeader,
      Header,
      UsePolicy,
    },
    props: {
      accountType: String
    },
    data() {
      return {
        send_enabled: false,
        term_accepted: false,
        society_accepted: false,
        term_enabled: false,
        form: {
          email: '',
          account_type: this.accountType,
        },
        terms_of_service: '',
        privacy_policy: '',
        antisocial: '',
      }
    },
    methods: {
      init() {
      },
      getFiles() {
        this.$loading.load(
          this.$auth.api.get('registration_auths/new')
          .then(res => {
            this.terms_of_service = res.data.terms_of_service;
          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        );
      },
      onScrollTerm() {
        // 最下部までスクロールしたらチェックボックスを有効にする
        let element = document.getElementById("term_textarea");
        if (element.scrollHeight - element.clientHeight <= element.scrollTop) {
          this.term_enabled = true;
        }
      },
      onClickSend() {
        this.$loading.load(
          this.$auth.api.post('registration_auths', this.form)
            .then(res => {
              this.$router.push({path: './request_flow2_send/' + res.data.id});
            })
            .catch(err => {
              if (err.response.status == 422) {
                this.$bvToast.toast(err.response.data.errors['email'][0] || window.defaultMessages.error, {
                  title: window.defaultMessages.errorTitle,
                  variant: 'danger'
                })
              } else if (err.response.status == 500) {
                // メール重複エラー
                if(err.response.data.error.indexOf('メールアドレス') !== -1){
                  this.$bvToast.toast(err.response.data.error, {
                    variant: 'danger',
                    title: 'エラー'
                  });
                }else{
                  this.$errorHandlers.initial(err);
                }
              } else {
                this.$errorHandlers.ajax(err);
              }
            })
        );
      },
      checkValid(value) {
        this.term_accepted = value
        this.send_enabled = this.term_accepted && !!this.form.email;
      },
    },
    watch: {
      'form.email'(new_value, old_value) {
        this.send_enabled = this.term_accepted && !!this.form.email;
      },
    },
    created() {
      this.init();
      this.getFiles();
    },
  }
</script>

<style scoped>

</style>
