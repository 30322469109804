<template>
  <main>
    <section class="main-title">
      <h1>ユーザー{{mode}}</h1>
    </section>
    <form class="needs-validation" novalidate="">
      <section class="chapter white-block">
        <div class="form-wrap d-flex">
          <profile-image ref="profile_image" :filename="image_attributes.filename || ''"
            :presigned_url="user.presigned_url || ''" :errors="errors" :accepted_extensions="accepted_extensions"></profile-image>
          <div class="right-col">
            <fieldset>
              <div class="form-row">
                <div class="form-group col-6">
                  <label class="required-addon" for="administrator-name1">姓<badge :require="true"></badge></label>
                  <b-form-input v-model="user.last_name" :state="checkValid('last_name')" class="form-control" type="text"
                    id="administrator-name1" placeholder="例) デロイト" required></b-form-input>
                  <p v-if="!!errors['last_name']" class="invalid-feedback d-block">{{ errors['last_name'][0] }}</p>
                </div>
                <div class="form-group col-6">
                  <label class="required-addon" for="administrator-name2">名<badge :require="true"></badge></label>
                  <b-form-input v-model="user.first_name" :state="checkValid('first_name')" class="form-control"
                    type="text" id="administrator-name2" placeholder="例) 太郎" required></b-form-input>
                  <p v-if="!!errors['first_name']" class="invalid-feedback d-block">{{ errors['first_name'][0] }}</p>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <div class="form-row">
                <div class="form-group col-6">
                  <label class="required-addon" for="administrator-name1-kana">セイ<badge :require="false"></badge></label>
                  <b-form-input v-model="user.kana_last_name" :state="checkValid('kana_last_name')" class="form-control"
                    type="text" id="administrator-name1-kana" placeholder="例) デロイト" required></b-form-input>
                  <p v-if="!!errors['kana_last_name']" class="invalid-feedback d-block">{{ errors['kana_last_name'][0] }}
                  </p>
                </div>
                <div class="form-group col-6">
                  <label class="required-addon" for="administrator-name2-kana">メイ<badge :require="false"></badge></label>
                  <b-form-input v-model="user.kana_first_name" :state="checkValid('kana_first_name')" class="form-control"
                    type="text" id="administrator-name2-kana" placeholder="例) タロウ" required></b-form-input>
                  <p v-if="!!errors['kana_first_name']" class="invalid-feedback d-block">
                    {{ errors['kana_first_name'][0] }}</p>
                </div>
              </div>
            </fieldset>
            <div class="form-group">
              <template v-if="currentUserId === user.id">
                <label class="required-addon" for="administrator-email">メールアドレス（ログインID）</label>
                <label class="required-addon" for="administrator-email">{{user.email}}</label>
              </template>
              <template v-else>
                <label class="required-addon" for="administrator-email">メールアドレス（ログインID）<badge :require="true"></badge>
                  </label>
                <b-form-input v-model="user.email" :state="checkValid('email')" class="form-control" type="email"
                  id="administrator-email" placeholder="例) t-deloitte@sixbrain.co.jp" required></b-form-input>
                <p v-if="!!errors['email']" class="invalid-feedback d-block">{{ errors['email'][0] }}</p>
              </template>
            </div>
            <div class="form-group">
              <label class="required-addon" for="administrator-title">役職名<badge :require="false"></badge></label>
              <b-form-input v-model="user.position" :state="checkValid('position')" class="form-control" type="text"
                id="administrator-title" placeholder="例) コンサルタント" required></b-form-input>
              <p v-if="!!errors['position']" class="invalid-feedback d-block">{{ errors['position'][0] }}</p>
            </div>
            <div class="form-group">
              <label class="required-addon" for="administrator-department">部署名<badge :require="false"></badge></label>
              <b-form-input v-model="user.department" :state="checkValid('department')" class="form-control" type="text"
                id="administrator-department" placeholder="例) アドバイザリーサービス事業部" required></b-form-input>
              <p v-if="!!errors['department']" class="invalid-feedback d-block">{{ errors['department'][0] }}</p>
            </div>
            <fieldset>
              <legend class="required-addon">アカウント種別<badge :require="true"></badge>
              </legend>
              <div class="form-row">
                <div class="form-group col-6">
                  <b-form-select class="custom-select" v-model="user.account_type" :state="checkValid('account_type')" :options="account_types"></b-form-select>
                  <p v-if="!!errors['account_type']" class="invalid-feedback d-block">{{ errors['account_type'][0] }}</p>
                </div>
              </div>
            </fieldset>
            <div class="form-group">
              <label class="required-addon" for="administrator-password">パスワード
                <badge :require="newly"></badge>
              </label>
              <div class="input-group icon-addon-right">
                <b-form-input v-model="user.password" :state="checkValid('password')" class="form-control" type="password"
                  id="administrator-password" aria-describedby="password-help" required autocomplete="no"></b-form-input>
                <p v-if="!!errors['password']" class="invalid-feedback d-block">{{ errors['password'][0] }}</p>
              </div><small class="form-text text-secondary"
                id="password-help">・半角英数字・記号、10文字以上<br>・英小文字、英大文字、数字、記号の４つの文字種のうち、３つ以上の文字種を含む</small>
            </div>
            <div class="form-group">
              <label class="required-addon" for="administrator-password2">パスワード（確認用）
                <badge :require="newly"></badge>
              </label>
              <div class="input-group icon-addon-right">
                <b-form-input v-model="user.password_confirmation" :state="checkValid('password_confirmation')"
                  class="form-control" type="password" id="administrator-password2" aria-describedby="password-help"
                  required autocomplete="no"></b-form-input>
                <p v-if="!!errors['password_confirmation']" class="invalid-feedback d-block">
                  {{ errors['password_confirmation'][0] }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="btn-container d-flex justify-content-center">
        <button class="button-square btn-send" type="button" @click="confirmUser">確認する</button>
      </div>
    </form>
  </main>
</template>

<script>
import Form from '../../../common/mixins/form'
import Badge from './Badge'
import ProfileImage from './ProfileImage'
export default {
  name: 'Form',
  mixins: [Form],
  components: {
    Badge,
    ProfileImage
  },
  data(){
    return {
      account_types: [
        {value: null, text: '選択してください'}
      ],
      errors: '',
      accepted_extensions: []
    }
  },
  computed: {
    mode() {
      if (this.$route.name.includes('Edit')){
        return '編集';
      } else {
        return '作成';
      }
    }
  },
  props: {
    user: {
      type: Object,
      default(){
        return {
          id: "",
          email: "",
          last_name: "",
          first_name: "",
          kana_last_name: "",
          kana_first_name: "",
          account_type: "",
          position: "",
          department: "",
          password: "",
          password_confirmation: "",
          uploaded_image: "",
          new_image_name: "",
          image_size: "",
          updated_at: ""
        }
      }
    },
    image_attributes: {id: "", filename: ""},
    newly: false,
    currentUserId: Number,
  },
  methods: {
    initForm () {
      let params = {};
      if(!this.newly){
        params = {id: this.$route.params.id};
      }
      this.$loading.load(this.$auth.api.get('admin/users/init_form.json', {params: params})
        .then(response => {
          this.account_types = this.account_types.concat(response.data.account_types);
          this.accepted_extensions = response.data.accepted_extensions.join();
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
    },
    confirmUser () {
      let image = this.$refs.profile_image.getData();
      let user = Object.assign(this.user, image);
      this.$auth.api.post('admin/users/confirm', {
        user: this.user
      })
      .then(response => {
        this.$store.commit('user/setUser', {
          user: user,
        });
        if (this.$route.path.includes('edit')){
          this.$router.push({ name: 'AdminUserEditConfirm' });
        } else {
          this.$router.push({ name: 'AdminUserNewConfirm' });
        }
      })
      .catch(error => {
        if(error.response.status == 422) {
          if(error.response.data && error.response.data.errors) {
            this.errors = error.response.data.errors;
          }
          // エラー時ページトップにスクロール
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        } else if(error.response.status == 500) {
          this.$errorHandlers.initial(error);
        } else {
          this.$errorHandlers.ajax(error);
        }
      });
    }
  },
  mounted(){
    this.initForm();
  }
}
</script>
