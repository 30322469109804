<template>
  <div>
    <section class="chapter white-block faq">
      <h2 class="chapter-label">{{ title }}</h2>

      <message-room
        :room-id="messageRoomId"
        :account-type="accountType"
        :collaboration-main-staff="collaborationMainStaff"
        :function-type="functionType"
        :creatable="creatable"
        :login="login"
        :current-user-id="currentUserId"
        :isNoFade="isNoFade"
      />
    </section>
  </div>
</template>

<script>
import MessageRoom from './room'

export default {
  components: {
    MessageRoom
  },
  props: {
    messageRoomId: {
      type: [Number, String],
      required: true,
    },
    accountType: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: 'メッセージ'
    },
    collaborationMainStaff: {
      type: Object,
      default: null
    },
    functionType: {
      type: String,
      default: ""
    },
    creatable:{
      type: Boolean,
      default: true
    },
    login:{
      type: Object,
      default: () => ({})
    },
    currentUserId: {
      type: [String, Number],
      required: true,
    },
    isNoFade: {
      type: Boolean,
      default: true,
    }
  },
}
</script>
