<template>
  <div class="main-area">
    <main>
      <section class="main-title">
        <h1>最新情報詳細</h1>
      </section>

      <!-- 最新情報詳細-->
      <section class="article-container mt-3">
        <article class="white-block reading-block">
          <div class="reading-visual">
            <template v-if="provisional_su_latest_info.presigned_pitch_file_url">
              <VuePdf
                :src="pitch_file_data"
                :page="current_page_num"
                @num-pages="total_page_num = $event"/>
              <div class="reading-visual-nav">
                <div class="reading-visual-nav-item">
                  <button class="reading-visual-nav-btn" @click="jumpPage(1)">
                    <i class="fas fa-angle-double-left"></i>
                  </button>
                </div>
                <div class="reading-visual-nav-item">
                  <button class="reading-visual-nav-btn" @click="jumpPage(current_page_num - 1)">
                    <i class="fas fa-angle-left"></i>
                  </button>
                </div>
                <div class="reading-visual-nav-item">{{ current_page_num }}<span> / {{ total_page_num }}</span></div>
                <div class="reading-visual-nav-item">
                  <button class="reading-visual-nav-btn" @click="jumpPage(current_page_num + 1)">
                    <i class="fas fa-angle-right"></i>
                  </button>
                </div>
                <div class="reading-visual-nav-item">
                  <button class="reading-visual-nav-btn" @click="jumpPage(total_page_num)">
                    <i class="fas fa-angle-double-right"></i>
                  </button>
                </div>
              </div>
            </template>
            <template v-else>
              <!-- メインビジュアル画像 -->
              <template v-if="!isObjectEmpty(provisional_su_latest_info.main_image)">
                <a class="img-box" :href="provisional_su_latest_info.main_image.presigned_url"
                  data-lightbox="latest_info_image" :data-title="provisional_su_latest_info.main_image_title">
                  <img
                    :src="provisional_su_latest_info.main_image.presigned_url"
                    :alt="provisional_su_latest_info.main_image.filename">
                </a>
                <div class="reading-visual-caption">{{ provisional_su_latest_info.main_image_title }}</div>
              </template>
              <template v-else>
                <a class="img-box" href="#">
                  <img
                    src="~images/image_default.jpg"
                    alt="デフォルト画像"
                  />
                </a>
              </template>
            </template>
          </div>
          <!-- 投稿日時 -->
          <div class="reading-bar">
            <small class="reading-date text-secondary">{{ provisional_su_latest_info.created_at | format.datetime }}</small>
          </div>
          <!-- タイトル -->
          <h2 class="reading-title">{{ provisional_su_latest_info.title }}</h2>
          <!-- 最新情報企業 -->
          <dl class="reading-body border-bottom">
            <dt>
              <img v-if="provisional_su_latest_info.is_latest_info_manager_image"
                :src="provisional_su_latest_info.presigned_latest_info_manager_image_url"
                :alt="provisional_su_latest_info.presigned_latest_info_manager_image_filename"
                class="thumbnail-frame img40"
              />
              <img v-else
                src="~images/su_user_default.png"
                alt="画像が選択されていません"
                class="thumbnail-frame img40"
              />
            </dt>
            <dd>
              <p v-if="provisional_su_latest_info.is_deleted"><span class="badge badge-sm badge-danger">削除</span></p>
              <p class="company-name">
                {{ !!provisional_su_latest_info.su_company ? provisional_su_latest_info.su_company.company_name : '' }}
              </p>
            </dd>
          </dl>
          <dl class="reading-item">
            <dt>企業情報（私たちについて）</dt>
            <dd class="u-pre-wrap" v-if="!!provisional_su_latest_info.su_company">{{ provisional_su_latest_info.su_company.business_summary }}</dd>
          </dl>
          <dl class="reading-item">
            <dt>最近何やってる？</dt>
            <dd class="u-pre-wrap">{{ provisional_su_latest_info.detail }}</dd>
          </dl>
          <dl class="reading-item" v-if="provisional_su_latest_info.sub1_image_id || provisional_su_latest_info.sub2_image_id">
            <dt>イメージ</dt>
            <dd class="d-flex">
              <!-- 画像（サブビジュアル1） -->
              <figure v-if="provisional_su_latest_info.sub1_image_id">
                <a class="img-box" :href="provisional_su_latest_info.sub1_image.presigned_url"
                  data-lightbox="latest_info_image" :data-title="provisional_su_latest_info.sub1_image_title">
                  <img
                    :src="provisional_su_latest_info.sub1_image.presigned_url"
                    :alt="provisional_su_latest_info.sub1_image.filename"
                  >
                </a>
                <figcaption class="reading-visual-caption">{{ provisional_su_latest_info.sub1_image_title }}</figcaption>
              </figure>
              <!-- 画像（サブビジュアル2） -->
              <figure class="mr-3" v-if="provisional_su_latest_info.sub2_image_id">
                <a class="img-box" :href="provisional_su_latest_info.sub2_image.presigned_url"
                  data-lightbox="latest_info_image" :data-title="provisional_su_latest_info.sub2_image_title">
                  <img
                    :src="provisional_su_latest_info.sub2_image.presigned_url"
                    :alt="provisional_su_latest_info.sub2_image.filename"
                  >
                </a>
                <figcaption class="reading-visual-caption">{{ provisional_su_latest_info.sub2_image_title }}</figcaption>
              </figure>
            </dd>
          </dl>
          <dl class="reading-item">
            <dt>パートナー企業様に求めるアセット</dt>
            <dd>
              <template v-if="provisional_su_latest_info.is_show_more_assets">
                <div v-for="(asset, index) in provisional_su_latest_info.asset_masters" :key="asset.asset_master_id" class="task-labels" v-if="index <= 9">
                  <p class="task-labels-parent">
                    {{ asset.name }}
                  </p>
                </div>
                <div class="btn-container text-right">
                  <button class="js-view-trigger btn-text d-show" type="button" @click="onClickShowMoreAssets()">
                    もっと見る
                  </button>
                </div>
              </template>
              <template v-else>
                <div v-for="asset in provisional_su_latest_info.asset_masters" :key="asset.asset_master_id" class="task-labels">
                  <p class="task-labels-parent">
                    {{ asset.name }}
                  </p>
                </div>
              </template>
            </dd>
          </dl>
          <dl class="reading-item" v-if="provisional_su_latest_info.presigned_pitch_file_url">
            <dt>会社についてもっと詳しく知りたい</dt>
            <dd>
              <a class="btn btn-big btn-green rounded-pill" :href="provisional_su_latest_info.presigned_pitch_file_url" :download="provisional_su_latest_info.presigned_pitch_file_filename">資料ダウンロード<i class="fas fa-file-download pl-1"></i></a>
            </dd>
          </dl>
        </article>
      </section>
      <!-- / 最新情報詳細-->

      <!-- 審査詳細-->
      <section class="chapter white-block" v-if="isDispExamInfo()">
        <h2 class="chapter-label mb-3">審査詳細</h2>
        <div class="overview-list overview-list-dt15 my-3 p-3">
          <dl class="form-confirm-group">
            <dt>登録種別</dt>
            <dd>{{ provisional_su_latest_info.registed_type_i18n }}</dd>
          </dl>

          <dl class="form-confirm-group">
            <dt>作成日</dt>
            <dd>{{ provisional_su_latest_info.created_at | format.datetime }}</dd>
          </dl>

          <dl class="form-confirm-group">
            <dt>審査ステータス</dt>
            <dd>{{ provisional_su_latest_info.exam_status_i18n }}</dd>
          </dl>

          <dl class="form-confirm-group"  v-if="provisional_su_latest_info.is_modify">
            <dt>要修正理由</dt>
            <dd>{{ provisional_su_latest_info.no_reason }}</dd>
          </dl>

          <dl class="form-confirm-group">
            <dt>レビュー完了日</dt>
            <dd>{{ provisional_su_latest_info.examination_at | format.datetime }}</dd>
          </dl>
        </div>
      </section>
      <!-- / 審査詳細-->

      <!-- ボタンエリア-->
      <div class="btn-container adjust-lotof-btn d-flex justify-content-center">
        <a class="btn btn-big btn-white rounded-pill"  @click.prevent.stop="onClickBack">戻る</a>
        <button
          class="btn btn-big btn-delete rounded-pill"
          :class="provisional_su_latest_info.is_deleted ? 'disable' : ''"
          :disabled="provisional_su_latest_info.is_deleted"
          @click.prevent.stop="onDelete"
          style="margin-left: 10px;"
        >
          削除する
        </button>
        <button
          class="btn btn-big btn-green rounded-pill"
          :class="provisional_su_latest_info.is_deleted ? 'disable' : ''"
          :disabled="provisional_su_latest_info.is_deleted"
          @click.prevent.stop="onEdit"
        >
          更新する
        </button>
      </div>
      <template
        v-if="provisional_su_latest_info.is_edit &&
              !provisional_su_latest_info.is_deleted &&
              !!provisional_su_latest_info.su_latest_info.id &&
              (provisional_su_latest_info.is_undecided || provisional_su_latest_info.is_modify || provisional_su_latest_info.is_reregister)"
      >
        <button class="button-square btn-diff" @click="onDiffView()">承認中の最新情報と見比べる<img src="~images/icon_diff_window.svg"></button>
      </template>
      <!-- ボタンエリア-->

      <ConfirmModal
        ref="delete_confirm_modal"
        :onClickSend="onDeleteComfirmSend"
        :body_text="'この最新情報を削除します。<br>よろしいですか？'"
        :send_button_text="'削除する'"
        :isNoFade="isNoFade"
      />

      <ConfirmModal
        ref="exam_confirm_modal"
        :onClickSend="onSaveComfirmSend"
        :body_text="'審査内容を登録します。<br>よろしいですか？'"
        :send_button_text="'登録する'"
        :isNoFade="isNoFade"
      />

    </main>

    <div class="fixed-footer-block" v-show="isDispExamForm()">
      <div class="inner">
        <div class="chapter-label my-1">最新情報の承認を行う</div>

        <form class="form-wrap py-1">
          <div class="properties-wrap">
            <!-- 審査ステータス -->
            <div class="form-group properties-item">
              <label class="properties-item-label">審査ステータス</label>
              <div class="properties-item-value">
                <b-select v-model="form.provisional_su_latest_info.exam_status" :state="checkValid('exam_status')" class="custom-select">
                  <option v-for="(exam_statuses_i18n, exam_status_key) in exam_statuses" :key="exam_status_key" :value="exam_status_key" v-if="exam_status_key != 'draft'">
                    {{exam_statuses_i18n}}
                  </option>
                </b-select>
              </div>
              <div v-for="error_message in validation_errors.exam_status" class="invalid-feedback" style="display: block" :key="error_message">
                {{ error_message }}
              </div>
            </div>

            <div class="form-group properties-item flex-grow-1" v-show="isDispNoReason()">
            </div>
            <!-- 要修正理由 -->
            <div class="form-group properties-item flex-grow-1" v-show="isDispNoReason()">
              <label class="properties-item-label" for="rejected-comment">要修正理由</label>
              <div class="properties-item-value">
                <b-form-textarea
                  v-model="form.provisional_su_latest_info.no_reason"
                  :state="checkValid('no_reason')"
                  type="text"
                  placeholder=""
                  class="form-control">
                </b-form-textarea>
                <small class="form-text text-right">500文字以内</small>
                <div v-if="!!validation_errors['no_reason']" class="invalid-feedback" style="display: block">
                  {{ validation_errors['no_reason'][0] }}
                </div>
              </div>
            </div>
          </div>
          <!-- 登録ボタン -->
          <div class="btn-container d-flex justify-content-end">
            <button
              class="button-square btn-send btn-sm"
              :class="form.provisional_su_latest_info.exam_status == 'undecided' ? 'disabled' : ''"
              type="button" data-toggle="modal" data-target="#register" @click="onSave()">登録する</button>
          </div>
        </form>
      </div>
    </div>

    <FooterComponent />
  </div>
</template>

<script>
  // Footer
  import FooterComponent from '../../../common/layouts/footer';

  import Form from '../../../common/mixins/form'
  // 確認モーダルのコンポーネント読み込み
  import ConfirmModal from '../../../common/pages/ConfirmModal.vue';
  // PDFパッチ資料
  import VuePdf from 'vue-pdf';

  export default {
    mixins: [Form],
    metaInfo: {
      title: '最新情報詳細',
    },

    components: {
      ConfirmModal,
      FooterComponent,
      VuePdf,
    },

    data() {
      return {
        provisional_su_latest_info: {},
        su_latest_info_updated_at: '',
        previous_route: null,
        validation_errors: {},
        // 審査ステータス一覧
        exam_statuses: {},
        pitch_file_data: '',
        current_page_num: 1,
        total_page_num: 0,
        cmaps_path: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.5.141/cmaps/',
        form: {
          provisional_su_latest_info: {
            id: '',
            exam_status: '',
            no_reason: '',
            updated_at: '',
          },
        },
      }
    },

    props: { isNoFade: Boolean },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.init();
    },

    // コンポーネントを描画するルートが確立する前に呼ばれる
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.previous_route = from;
      });
    },

    // メソッド定義
    methods: {
      // 初期化
      init() {
        this.getProvisionalSuLatestInfo();
      },

      jumpPage(toPage) {
        if (toPage < 1 || toPage > this.total_page_num) {
          return
        }
        this.current_page_num = toPage
      },

      getProvisionalSuLatestInfo() {
        this.$loading.load(
          this.$auth.api.get(
            `admin/provisional_su_latest_infos/${this.$route.params.id}`
          ).then(res => {
            this.provisional_su_latest_info = res.data.provisional_su_latest_info;
            this.exam_statuses = res.data.exam_statuses;

            if (res.data.pitch_file_data) {
              this.pitch_file_data = VuePdf.createLoadingTask({
                data: atob(res.data.pitch_file_data),
                cMapUrl: this.cmaps_path,
                cMapPacked: true,
              });
            }

            // ID
            this.form.provisional_su_latest_info.id            = this.provisional_su_latest_info.id;
            // 審査ステータス
            this.form.provisional_su_latest_info.exam_status   = this.provisional_su_latest_info.exam_status;
            // 要修正理由
            this.form.provisional_su_latest_info.no_reason     = this.provisional_su_latest_info.no_reason;
            // 更新日時
            this.form.provisional_su_latest_info.updated_at    = this.provisional_su_latest_info.updated_at;

          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        );
      },

      // 戻るボタン押下時処理
      onClickBack() {
        if (!!this.previous_route && this.previous_route.name === 'AdminProvisionalSuLatestInfoIndex') {
          this.$router.push({path: this.previous_route.fullPath});
        } else {
          this.$router.push({name: 'AdminProvisionalSuLatestInfoIndex'});
        }
      },

      // 削除ボタン押下時
      onDelete() {
        if (!!this.provisional_su_latest_info.su_latest_info) {
          this.su_latest_info_updated_at = this.provisional_su_latest_info.su_latest_info.updated_at;
        }
        this.$refs.delete_confirm_modal.open();
      },

      // 削除確認モーダル内の削除するボタン押下時
      onDeleteComfirmSend() {
        if (!!this.provisional_su_latest_info.su_latest_info) {
          this.su_latest_info_updated_at = this.provisional_su_latest_info.su_latest_info.updated_at;
        }
        this.$loading.load(
          this.$auth.api.delete(
            'admin/provisional_su_latest_infos/' + this.provisional_su_latest_info.id, {
              params: {
                provisional_su_latest_info_updated_at: this.provisional_su_latest_info.updated_at,
                su_latest_info_updated_at: this.su_latest_info_updated_at
              }
            }
          ).then(res => {
            this.$refs.delete_confirm_modal.close();
            // 一覧画面へ戻る
            if (!!this.previous_route && this.previous_route.name === 'AdminProvisionalSuLatestInfoIndex') {
              this.$router.push({path: this.previous_route.fullPath, query: {toast_type: 'deleted'}});
            } else {
              this.$router.push({name: 'AdminProvisionalSuLatestInfoIndex', query: {toast_type: 'deleted'}});
            }
          }).catch(err => {
            this.$refs.delete_confirm_modal.close();

            if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else {
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },

      // 更新するボタン押下時
      onEdit() {
        // 編集画面に遷移
        this.$router.push({ name: 'AdminProvisionalSuLatestInfoEdit', params: { id: this.provisional_su_latest_info.id } });
      },

      // 登録ボタン押下
      onSave() {
        this.$refs.exam_confirm_modal.open();
      },

      // 確認モーダル登録ボタン押下
      onSaveComfirmSend() {
        this.$loading.load(
          this.$auth.api.post(
            `admin/provisional_su_latest_infos/${this.$route.params.id}/exam_update`, this.form
          ).then(res => {
            this.$refs.exam_confirm_modal.close();

            this.$bvToast.toast("登録しました", {
              variant: 'success',
              title: '完了'
            });
            this.$router.go({name: 'AdminProvisionalSuLatestInfoShow'});
          }).catch(err => {
            this.$refs.exam_confirm_modal.close();

            // バリデーションエラー
            if (err.response.status == 422) {
              this.validation_errors = err.response.data.errors;
            }
            else if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else {
              this.$errorHandlers.ajax(err);
            }
          })
        );
      },

      // 差分比較window表示
      onDiffView() {
        let resolvedRoute = this.$router.resolve({
          name: 'AdminProvisionalSuLatestInfoDiff',
          params: {id: this.provisional_su_latest_info.id}
        });
        window.open(resolvedRoute.href, 'preview','top=100,left=100,width=1000,height=700');
      },

      isObjectEmpty(object) {
        if(!object) {
          return true;
        }
        return !(Object.keys(object).length)
      },

      // 要修正理由 表示非表示判定
      isDispNoReason() {
        let result = false;

        // 編集時
        if(!!this.provisional_su_latest_info.id) {
          // 審査ステータスで「要修正」が選択されている
          if(this.form.provisional_su_latest_info.exam_status == 'modify') {
            result = true;
          }
          // 要修正理由が空ではない
          if(!!this.provisional_su_latest_info.no_reason) {
            result = true;
          }
        }

        return result;
      },

      // 審査詳細 表示非表示判定
      isDispExamInfo() {
        let result = false;

        // 審査ステータスが承認・再修正
        if(this.provisional_su_latest_info.exam_status == 'accepted' || this.provisional_su_latest_info.exam_status == 'modify') {
          result = true;
        }
        // 論理削除されている
        if(!!this.provisional_su_latest_info.deleted_at) {
          result = true;
        }

        return result;
      },

      // 審査登録 表示非表示判定
      isDispExamForm() {
        let result = false;

        // 論理削除されていない
        if(!this.provisional_su_latest_info.deleted_at) {
          // 審査ステータスが未承認・再登録
          if(this.provisional_su_latest_info.exam_status == 'undecided' || this.provisional_su_latest_info.exam_status == 'reregister') {
            result = true;
          }
        }

        return result;
      },

      // もっと見るボタン押下時
      onClickShowMoreAssets(){
        this.provisional_su_latest_info.is_show_more_assets = false;
      },

    }

  }
</script>