const initialState = () => {
  return {
    from_page: {
      full_path: '',
    }
  }
}

export default {
  namespaced: true,
  state: initialState(),

  mutations: {
    setFromPage(state, data) {
      state.from_page.full_path = data.full_path;
    },

    clear(state) {
      // state.from_page.full_path = '';
      Object.assign(state, {from_page:{full_path: ''}});
    },
    reset(state){
      Object.assign(state, initialState())
    },
  },

  getters: {
    fromPage: state => {
      return state.from_page;
    }
  }
}