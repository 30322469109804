<template>
  <div class="main-area">
    <main>
      <!-- 最新情報 -->
      <section class="article-container mt-3" v-if="ep_recommend.su_latest_info">
        <article class="white-block reading-block">
          <div class="reading-visual">
            <template v-if="ep_recommend.presigned_pitch_file_url">
              <VuePdf
                :src="pitch_file_data"
                :page="current_page_num"
                @num-pages="total_page_num = $event"/>
              <div class="reading-visual-nav">
                <div class="reading-visual-nav-item">
                  <button class="reading-visual-nav-btn" @click="jumpPage(1)">
                    <i class="fas fa-angle-double-left"></i>
                  </button>
                </div>
                <div class="reading-visual-nav-item">
                  <button class="reading-visual-nav-btn" @click="jumpPage(current_page_num - 1)">
                    <i class="fas fa-angle-left"></i>
                  </button>
                </div>
                <div class="reading-visual-nav-item">{{ current_page_num }}<span> / {{ total_page_num }}</span></div>
                <div class="reading-visual-nav-item">
                  <button class="reading-visual-nav-btn" @click="jumpPage(current_page_num + 1)">
                    <i class="fas fa-angle-right"></i>
                  </button>
                </div>
                <div class="reading-visual-nav-item">
                  <button class="reading-visual-nav-btn" @click="jumpPage(total_page_num)">
                    <i class="fas fa-angle-double-right"></i>
                  </button>
                </div>
              </div>
            </template>
            <template v-else>
              <!-- メインビジュアル画像 -->
              <template v-if="ep_recommend.presigned_latest_info_main_image_url">
                <a class="img-box" :href="ep_recommend.presigned_latest_info_main_image_url"
                  data-lightbox="latest_info_image" :data-title="ep_recommend.su_latest_info.main_image_title">
                  <img
                    :src="ep_recommend.presigned_latest_info_main_image_url"
                    :alt="ep_recommend.presigned_latest_info_main_image_filename">
                </a>
                <div class="reading-visual-caption">{{ ep_recommend.su_latest_info.main_image_title }}</div>
              </template>
              <template v-else>
                <a class="img-box" href="#">
                  <img
                    src="~images/image_default.jpg"
                    alt="デフォルト画像"
                  />
                </a>
              </template>
            </template>
          </div>
          <!-- リコメンド送付日時 -->
          <div class="reading-bar">
            <small class="reading-date text-secondary">{{ ep_recommend.recommend_send_at | format.datetime }}</small>
          </div>
          <!-- タイトル -->
          <h2 class="reading-title">{{ ep_recommend.su_latest_info.title }}</h2>
          <!-- 最新情報企業 -->
          <dl class="reading-body border-bottom">
            <dt>
              <img v-if="ep_recommend.is_latest_info_manager_image"
                :src="ep_recommend.presigned_latest_info_manager_image_url"
                :alt="ep_recommend.presigned_latest_info_manager_image_filename"
                class="thumbnail-frame img40"
              />
              <img v-else
                src="~images/su_user_default.png"
                alt="画像が選択されていません"
                class="thumbnail-frame img40"
              />
            </dt>
            <dd>
              <p class="company-name">{{ ep_recommend.su_company.company_name }}</p>
            </dd>
          </dl>
          <dl class="reading-item">
            <dt>企業情報（私たちについて）</dt>
            <dd class="u-pre-wrap" v-text="ep_recommend.su_company.business_summary"></dd>
          </dl>
          <dl class="reading-item">
            <dt>最近何やってる？</dt>
            <dd class="u-pre-wrap" v-text="ep_recommend.su_latest_info.detail"></dd>
          </dl>
          <dl class="reading-item" v-if="ep_recommend.su_latest_info.sub1_image_id || ep_recommend.su_latest_info.sub2_image_id">
            <dt>イメージ</dt>
            <dd class="d-flex">
              <!-- 画像（サブビジュアル1） -->
              <figure v-if="ep_recommend.su_latest_info.sub1_image_id">
                <a class="img-box" :href="ep_recommend.presigned_latest_info_sub1_image_url"
                  data-lightbox="latest_info_image" :data-title="ep_recommend.su_latest_info.sub1_image_title">
                  <img
                    :src="ep_recommend.presigned_latest_info_sub1_image_url"
                    :alt="ep_recommend.presigned_latest_info_sub1_image_filename"
                  >
                </a>
                <figcaption class="reading-visual-caption">{{ ep_recommend.su_latest_info.sub1_image_title }}</figcaption>
              </figure>
              <!-- 画像（サブビジュアル2） -->
              <figure class="mr-3" v-if="ep_recommend.su_latest_info.sub2_image_id">
                <a class="img-box" :href="ep_recommend.presigned_latest_info_sub2_image_url"
                  data-lightbox="latest_info_image" :data-title="ep_recommend.su_latest_info.sub2_image_title">
                  <img
                    :src="ep_recommend.presigned_latest_info_sub2_image_url"
                    :alt="ep_recommend.presigned_latest_info_sub2_image_filename"
                  >
                </a>
                <figcaption class="reading-visual-caption">{{ ep_recommend.su_latest_info.sub2_image_title }}</figcaption>
              </figure>
            </dd>
          </dl>
          <dl class="reading-item">
            <dt>パートナー企業様に求めるアセット</dt>
            <dd>
              <template v-if="ep_recommend.is_show_more_assets">
                <div v-for="asset in ep_recommend.asset_masters.slice(0, 10)" :key="asset.asset_master_id" class="task-labels">
                  <p class="task-labels-parent">
                    {{ asset.name }}
                  </p>
                </div>
                <div class="btn-container text-right">
                  <button class="js-view-trigger btn-text d-show" type="button" @click="onClickShowMoreAssets()">
                    もっと見る
                  </button>
                </div>
              </template>
              <template v-else>
                <div v-for="asset in ep_recommend.asset_masters" :key="asset.asset_master_id" class="task-labels">
                  <p class="task-labels-parent">
                    {{ asset.name }}
                  </p>
                </div>
              </template>
            </dd>
          </dl>
          <dl class="reading-item" v-if="ep_recommend.pitch_file_id">
            <dt>会社についてもっと詳しく知りたい</dt>
            <dd>
              <a class="btn btn-big btn-green rounded-pill" :href="ep_recommend.presigned_pitch_file_url" :download="ep_recommend.presigned_pitch_file_filename">資料ダウンロード<i class="fas fa-file-download pl-1"></i></a>
            </dd>
          </dl>
        </article>
        <aside class="chapter white-block mb-4">
          <h3 class="chapter-label mb-3">担当者情報</h3>
          <div class="d-flex p-3">
            <img v-if="ep_recommend.is_latest_info_manager_image"
              :src="ep_recommend.presigned_latest_info_manager_image_url"
              :alt="ep_recommend.presigned_latest_info_manager_image_filename"
              class="thumbnail-frame img80"
            />
            <img v-else
              src="~images/su_user_default.png"
              alt="画像が選択されていません"
              class="thumbnail-frame img80"
            />
            <div class="ml-3">
              <p class="h5">{{ ep_recommend.latest_info_manager_name }}</p>
              <p class="text-box mt-1 u-pre-wrap" v-text="ep_recommend.latest_info_manager_profile"></p>
            </div>
          </div>
        </aside>
      </section>
      <!-- /最新情報 -->

      <!-- その他のリコメンド情報 -->
      <section v-if="!!other_ep_recommends" class="chapter white-block">
        <h2 class="chapter-label mb-3">その他のリコメンド情報</h2>
        <div class="m-3 p-3">
          <SlickCarousel v-bind="slickOptions" v-if="Object.keys(other_ep_recommends).length" class="other-item-list">
            <li v-for="other_ep_recommend in other_ep_recommends" :key="other_ep_recommend.id">
              <a @click.prevent.stop="onClickOtherRecommend(other_ep_recommend)" href="#" class="card">
                <p class="title">{{ other_ep_recommend.title }}</p>
                <div class="img-box">
                  <img
                    v-if="other_ep_recommend.presigned_other_latest_info_main_image_url"
                    :src="other_ep_recommend.presigned_other_latest_info_main_image_url"
                    :alt="other_ep_recommend.presigned_other_latest_info_main_image_filename"
                  />
                  <img
                    v-else
                    src="~images/image_default.jpg"
                    alt="デフォルト画像"
                  />
                </div>
              </a>
            </li>
          </SlickCarousel>
        </div>
      </section>
      <!-- /その他のリコメンド情報 -->

      <!-- チャレンジ情報 -->
      <section class="chapter white-block" v-if="!!ep_recommend.ep_challenge">
        <h2 class="chapter-label mb-3">チャレンジ情報</h2>
        <div class="overview-list overview-list-dt15 my-3 p-3">
          <dl class="form-confirm-group">
            <dt>チャレンジタイトル</dt>
            <dd>{{ ep_recommend.ep_challenge.title }}</dd>
          </dl>

          <dl class="form-confirm-group">
            <dt>担当者</dt>
            <dd>{{ ep_recommend.ep_challenge_manager_department }} {{ ep_recommend.ep_challenge_manager_name }}</dd>
          </dl>
        </div>
      </section>
      <!-- /チャレンジ情報 -->

      <!-- リコメンド判断 -->
      <section class="chapter white-block" v-if="!!ep_recommend.ep_action_status && ep_recommend.ep_action_status != 'none'">
        <h2 class="chapter-label mb-3">リコメンド判断</h2>
        <div class="overview-list overview-list-dt15 my-3 p-3">
          <dl class="form-confirm-group">
            <dt>判断日</dt>
            <dd>{{ ep_recommend.ep_checked_at | format.datetime }}</dd>
          </dl>

          <dl class="form-confirm-group">
            <dt>ステータス</dt>
            <dd>{{ ep_recommend.recommend_answer_status_i18n }}</dd>
          </dl>

          <!-- 運営の判断があれば表示 -->
          <template v-if="!!ep_recommend.ep_offer_suggestions_welcome.review_at">
            <dl class="form-confirm-group">
              <dt>レビュー日時</dt>
              <dd>{{ ep_recommend.ep_offer_suggestions_welcome.review_at | format.datetime }}</dd>
            </dl>
            <dl class="form-confirm-group" v-if="!!ep_recommend.ep_offer_suggestions_welcome.no_reason">
              <dt>レビュー内容</dt>
              <dd>{{ ep_recommend.ep_offer_suggestions_welcome.no_reason }}</dd>
            </dl>
          </template>

          <!-- 対応ステータスにより表示を変える -->
          <template v-if="ep_recommend.ep_action_status == 'see_off'">
            <dl class="form-confirm-group">
              <dt>見送り理由</dt>
              <dd>{{ ep_recommend.ep_no_reason }}</dd>
            </dl>
          </template>
          <template v-else-if="ep_recommend.is_offered">
            <dl class="form-confirm-group">
              <dt>オファータイトル</dt>
              <dd>{{ ep_recommend.ep_offer_suggestions_welcome.ep_offer_title }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>オファー本文</dt>
              <dd>{{ ep_recommend.ep_offer_suggestions_welcome.ep_offer_detail }}</dd>
            </dl>
          </template>
          <template v-else-if="ep_recommend.is_suggestions_welcomed">
            <dl class="form-confirm-group">
              <dt>提案歓迎コメント</dt>
              <dd>{{ ep_recommend.ep_offer_suggestions_welcome.ep_welcome_detail }}</dd>
            </dl>
          </template>
        </div>
      </section>
      <!-- /リコメンド判断 -->

      <!-- ボタン -->
      <div class="btn-container adjust-lotof-btn d-flex justify-content-center">
        <a class="btn btn-big btn-white rounded-pill" href="#" @click="onClickBack()">戻る</a>
        <a class="btn btn-big btn-outline-green rounded-pill" href="#" @click="onClickIndex()">チャレンジ一覧</a>
      </div>
      <!-- /ボタン -->

      <!-- リコメンド対応確認モーダル -->
      <ConfirmModal
          ref="choice_confirm_modal"
          :onClickSend="onChoiceSend"
          :body_text="confirm_body_text"
          :send_button_text="confirm_send_text"
          :isNoFade="isNoFade"
      />

      <!-- リコメンド対応って？ -->
      <b-modal
        v-model="recommend_send_help"
        id="recommend_send"
        aria-label="recommend-help_Label"
        title="リコメンド対応って？"
        centered
        hide-header
        hide-footer
        size="sm"
        :no-fade="isNoFade"
      >
        <div class="modal-body">
          <h3 class="chapter-label mb-3"><i class="fas fa-info-circle mr-1"></i>リコメンド対応って？</h3>
          <div class="mx-auto">
            <div class="img-box"><img src="~images/recommend_reaction.svg" alt=""></div>
          </div>
          <div class="my-3">
            <p class="text-box mt-1">気になった案件に対して「オファー」や「提案歓迎」を出すことで、企業からの反応を受け取る可能性が高くなります。気軽にオファーや提案歓迎をしてみましょう！</p>
          </div>
          <div class="text-center">
            <button type="button" @click="onRecommendHelpHide()"><i class="fas fa-times"></i></button>
          </div>
        </div>
      </b-modal>
    </main>

    <!-- リコメンド対応選択エリア -->
    <div class="fixed-footer-block" v-if="!!ep_recommend.ep_action_status && (ep_recommend.ep_action_status == 'none' || ep_recommend.ep_offer_suggestions_welcome.recommend_answer_status == 'offer_returned')">
      <!-- リコメンド対応を選択（デフォルト）-->
      <div class="inner" v-if="form.recommend_choice_type == -1">
        <div class="btn-container d-flex justify-content-center align-items-center">
          <div class="chapter-label">リコメンド対応を選択<a href="" @click.prevent.stop="onRecommendHelp()"><i class="fas fa-info-circle ml-1"></i></a>
          </div>
            <a class="btn btn-medium btn-green rounded-pill" href="#" @click.prevent.stop="onChoice(choiced_offer)">オファー送付</a>
            <template v-if="!!ep_recommend.ep_challenge">
              <a class="btn btn-medium btn-green rounded-pill" href="#" @click.prevent.stop="onChoice(choiced_welcome)">提案歓迎</a>
            </template>
            <a class="btn btn-medium btn-outline-green rounded-pill" href="#" @click.prevent.stop="onChoice(choiced_seeoff)">見送る</a>
        </div>
      </div>
      <!-- / リコメンド対応を選択（デフォルト）-->

      <!-- オファー・提案歓迎を行う（オファー送付）-->
      <div class="inner" v-else-if="form.recommend_choice_type == choiced_offer">
        <form class="form-wrap py-1">
          <!-- オファーを出す -->
          <div class="chapter-label">オファーを出す</div>
          <div class="form-group row">
            <label class="col-2 col-form-label" for="offer-title">
              オファータイトル
              <span class="badge badge-danger ml-2">必須</span>
            </label>
            <div class="col-10 incremental-wrap">
              <input class="form-control" type="text" id="offer-title" v-model="form.offer_title">
              <small class="form-text text-right">100文字以内</small>
              <div v-if="!!validation_errors.ep_offer_title">
                <p  v-for="error_message in validation_errors.ep_offer_title" :key="error_message" class="invalid-feedback d-block">
                  {{ error_message }}
                </p>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-2 col-form-label" for="welcome-comment">
              オファー本文
              <span class="badge badge-danger ml-2">必須</span>
            </label>
            <div class="col-10 incremental-wrap">
              <textarea class="form-control" type="text" id="welcome-comment" v-model="form.offer_detail"></textarea>
              <small class="form-text text-right">500文字以内</small>
              <div v-if="!!validation_errors.ep_offer_detail">
                <p  v-for="error_message in validation_errors.ep_offer_detail" :key="error_message" class="invalid-feedback d-block">
                  {{ error_message }}
                </p>
              </div>
            </div>
          </div>
          <div class="btn-container d-flex justify-content-end">
            <a class="button-square btn-sm btn-cancel mr-2" href="#" @click="onChoiceCancel()">戻る</a>
            <button class="button-square btn-sm btn-send" type="button" @click="onChoiceConfirm()">オファー送付</button>
          </div>
        </form>
      </div>
      <!-- / オファー・提案歓迎を行う（オファー送付）-->

      <!-- オファー・提案歓迎を行う（提案歓迎）-->
      <div class="inner" v-else-if="form.recommend_choice_type == choiced_welcome">
        <form>
          <!-- 提案歓迎の意思表示を送る -->
          <div class="row">
            <div class="left-col">
              <div class="chapter-label">提案歓迎の意思表示を送る<a href="" @click.prevent.stop="onRecommendHelp()"><i class="fas fa-info-circle ml-1"></i></a></div>
              <label for="welcome-comment">提案歓迎コメント<span class="badge badge-light ml-1">任意</span></label>
            </div>
            <div class="right-col col-9">
              <textarea class="form-control" type="text" id="welcome-comment" v-model="form.welcome_detail"></textarea>
              <small class="form-text text-right">500文字以内</small>
              <div v-if="!!validation_errors.ep_welcome_detail">
                <p  v-for="error_message in validation_errors.ep_welcome_detail" :key="error_message" class="invalid-feedback d-block">
                  {{ error_message }}
                </p>
              </div>
            </div>
          </div>
          <div class="btn-container d-flex justify-content-end">
            <a class="button-square btn-sm btn-cancel mr-2" href="#" @click="onChoiceCancel()">戻る</a>
            <button class="button-square btn-sm btn-send" type="button" @click="onChoiceConfirm()">提案歓迎</button>
          </div>
        </form>
      </div>
      <!-- / オファー・提案歓迎を行う（提案歓迎）-->

      <!-- オファー・提案歓迎を行う（見送る）-->
      <div class="inner" v-else>
        <form>
          <!-- 見送る -->
          <div class="row">
            <div class="left-col">
              <div class="chapter-label">見送る<a href="" @click.prevent.stop="onRecommendHelp()"><i class="fas fa-info-circle ml-1"></i></a></div>
              <label for="pass-comment">見送りコメント<span class="badge badge-light ml-1">任意</span></label>
            </div>
            <div class="right-col col-10">
              <textarea class="form-control" type="text" id="pass-comment" v-model="form.seeoff_detail"></textarea>
              <small class="form-text text-right">500文字以内</small>
              <div v-if="!!validation_errors.ep_no_reason">
                <p  v-for="error_message in validation_errors.ep_no_reason" :key="error_message" class="invalid-feedback d-block">
                  {{ error_message }}
                </p>
              </div>
            </div>
          </div>
          <div class="btn-container d-flex justify-content-end">
            <a class="button-square btn-sm btn-cancel mr-2" href="#" @click="onChoiceCancel()">戻る</a>
            <button class="button-square btn-sm btn-delete" type="button" @click="onChoiceConfirm()">見送る</button>
          </div>
        </form>
      </div>
      <!-- / オファー・提案歓迎を行う（見送る）-->
    </div>
    <!-- /リコメンド対応選択エリア -->

    <FooterComponent />
  </div>
</template>
<script>
  // Footer
  import FooterComponent from '../../../common/layouts/footer';

  // 確認モーダルのコンポーネント読み込み
  import ConfirmModal from '../../../common/pages/ConfirmModal.vue';

  // 他のリコメンドのコンポーネント読み込み
  import SlickCarousel from "../../../common/SlickCarouselMixin"

  // PDFパッチ資料
  import VuePdf from 'vue-pdf';

  export default {

    mixins: [SlickCarousel],

    metaInfo: {
      title: 'リコメンド詳細',
    },

    components: {
      ConfirmModal,
      FooterComponent,
      VuePdf,
    },

    props: { isNoFade: Boolean },

    data() {
      return {
        ep_recommend_id: this.$route.params.id,
        ep_recommend: [],
        other_ep_recommends: [],
        confirm_body_text: "",
        confirm_send_text: "",
        offer_returned: false,
        recommend_send_help: false,
        validation_errors: {},
        pitch_file_data: '',
        current_page_num: 1,
        total_page_num: 0,
        cmaps_path: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.5.141/cmaps/',
        previous_route: null,
        form: {
          recommend_choice_type: -1,
          offer_title: "",
          offer_detail: "",
          welcome_detail: "",
          seeoff_detail: "",
          ep_recommend_updated_at: "",
          ep_challenge_updated_at: "",
          provisional_ep_challenge_updated_at: "",
          ep_offer_suggestions_welcome_updated_at: "",
        },
      }
    },

    // コンポーネントを描画するルートが確立する前に呼ばれる
    beforeRouteEnter(to, from, next) {
      next(vm => {
        // 直前のルートを格納する
        vm.previous_route = from;
      });
    },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.init();
    },

    // 計算プロパティ
    computed: {
      // 定数を定義
      choiced_offer: function() { return 11 }, // オファーレビュー待ち
      choiced_welcome: function() { return 23 },// 提案歓迎
      choiced_seeoff: function() { return 0 }, // 見送り
    },

    // ウォッチャ定義
    watch: {
    },

    // メソッド定義
    methods: {
      init() {
        this.$loading.load(
          this.$auth.api.get(
            'ep/recommends/' + this.ep_recommend_id, {
              params: {}
            }
          ).then(res => {
            // 取得したデータを格納
            this.ep_recommend = res.data.ep_recommend;
            this.other_ep_recommends = res.data.other_ep_recommends;
            this.form.ep_recommend_updated_at = res.data.ep_recommend.updated_at;
            if (!!res.data.ep_recommend.ep_challenge) {
              this.form.ep_challenge_updated_at = res.data.ep_recommend.ep_challenge.updated_at;
              this.form.provisional_ep_challenge_updated_at = res.data.ep_recommend.provisional_ep_challenge_updated_at;
            }
            if(!!res.data.ep_recommend.ep_offer_suggestions_welcome){ // オファー・提案歓迎のテーブルが存在
              this.form.ep_offer_suggestions_welcome_updated_at = res.data.ep_recommend.ep_offer_suggestions_welcome.updated_at;
              // オファー差し戻し状態ならば、オファー入力エリアを表示させる
              if(res.data.ep_recommend.ep_offer_suggestions_welcome.recommend_answer_status == 'offer_returned'){
                this.offer_returned = true;
                this.onChoice(this.choiced_offer);
                this.form.offer_title = res.data.ep_recommend.ep_offer_suggestions_welcome.ep_offer_title;
                this.form.offer_detail = res.data.ep_recommend.ep_offer_suggestions_welcome.ep_offer_detail;
              }
            }
            if (res.data.pitch_file_data) {
              this.pitch_file_data = VuePdf.createLoadingTask({
                data: atob(res.data.pitch_file_data),
                cMapUrl: this.cmaps_path,
                cMapPacked: true,
              });
            }
          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        )
      },

      jumpPage(toPage) {
        if (toPage < 1 || toPage > this.total_page_num) {
          return
        }
        this.current_page_num = toPage
      },

      // もっと見るボタン押下時
      onClickShowMoreAssets(){
        this.ep_recommend.is_show_more_assets = false;
      },

      // リコメンド対応とはモーダル
      onRecommendHelp() {
        this.recommend_send_help = true;
      },

      // リコメンド対応とはモーダル閉じる
      onRecommendHelpHide() {
        this.recommend_send_help = false;
      },

      // 他リコメンド押下時
      onClickOtherRecommend(other_ep_recommend) {
        this.ep_recommend_id = other_ep_recommend.id
        let resolvedRoute = this.$router.resolve({
          name: 'EpRecommendShow',
          params: {id: other_ep_recommend.id}
        });
        window.open(resolvedRoute.href)
      },

      // 戻るボタン押下時処理
      onClickBack() {
        if (!!this.previous_route && (this.previous_route.name === 'EpRecommendShow' || this.previous_route.name === 'EpRecommendIndex')) {
          this.$router.push({path: this.previous_route.fullPath});
        } else {
          this.$router.push({name: 'EpRecommendIndex'});
        }
      },

      // チャレンジ一覧ボタン押下時処理
      onClickIndex() {
        this.$router.push({ name: 'EpProvisionalEpChallengeIndex' });
      },

      // リコメンド対応選択時
      onChoice(type) {
        this.form.recommend_choice_type = type;
      },

      // リコメンド対応確認モーダル
      onChoiceConfirm() {
        this.$loading.load(
          this.$auth.api.post(
            'ep/recommends/' + this.ep_recommend_id + '/update_confirm', this.form
          ).then(res => {
            this.validation_errors = {};
            // リコメンド選択肢によりモーダル内文章を切り替え
            switch(this.form.recommend_choice_type){
              case this.choiced_offer:
                this.confirm_body_text = "DTVSの確認後、スタートアップにオファー情報を届けます。<br>よろしいですか？";
                this.confirm_send_text = "オファー送付";
                break;
              case this.choiced_welcome:
                this.confirm_body_text = "スタートアップに提案歓迎オファー情報を届けます。<br>よろしいですか？";
                this.confirm_send_text = "提案歓迎送付";
                break;
              case this.choiced_seeoff:
                this.confirm_body_text = "リコメンドを見送ります。<br>よろしいですか？";
                this.confirm_send_text = "見送り登録";
                break;
            }
            this.$refs.choice_confirm_modal.open();
          }).catch(err => {
            if(err.response.status == 422) {
              if (err.response.data && err.response.data.errors) {
                this.validation_errors = err.response.data.errors;
              }
            }else if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }else{
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },

      // リコメンド選択キャンセル時
      onChoiceCancel() {
        this.form.recommend_choice_type = -1;
      },

      // リコメンド選択確定時
      onChoiceSend() {
        this.$loading.load(
          this.$auth.api.patch(
            'ep/recommends/' + this.ep_recommend_id, this.form
          ).then(res => {
            this.$refs.choice_confirm_modal.close();
            this.$router.push({name: 'EpRecommendIndex',  query: {toast_type: 'created'}});
          }).catch(err => {
            if(err.response.status == 422) {
              if (err.response.data && err.response.data.errors) {
                this.validation_errors = err.response.data.errors;
              }
              this.$refs.choice_confirm_modal.close();
            }else if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }else{
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },

    }

  }
</script>
<style>
.lb-nav a.lb-prev, .lb-nav a.lb-next {
  width: 50%;
}
</style>
