

export default{
  data(){
    return {
      cookie_like_modal_hide: 'like_modal_hide_flg',
    }
  },
  methods: {
    getCookieLikeModalHide(){
      return (this.$cookies.get(this.cookie_like_modal_hide) === 'true');
    },
    setCookieLikeModalHide(obj){
      this.$cookies.set(this.cookie_like_modal_hide, obj, '1y');
    },
  },
}