<template>
  <main>
    <div class="overview-text-area text-center mt-5">
      <h2>2段階認証の設定が完了しました。</h2>
      <p class="mt-4">次回ログインからはパスワードに加えて認証コードの入力を求められます。</p>
    </div>

    <section class="chapter white-block">
      <h2 class="chapter-label">バックアップコード</h2>
      <p class="sub-label text-black-50">認証端末の紛失や故障等により、アプリから認証コードを取得できない場合、認証コードの代わりにバックアップコードを使用してログインできます。1度使用したバックアップコードは使用できなくなります。バックアップコードは安全な場所に保管してください。</p>
      <div class="border-top pt-4 px-3 mb-5">
        <dl class="form-confirm-group" v-for="backup_code in otp_backup_codes" :key="backup_code">
          <dd>{{backup_code}}</dd>
        </dl>
      </div>
    </section>

      <div class="btn-container d-flex justify-content-center mt-5">
        <button class="button-square btn-send" type="button" @click="backAccountDetail">アカウント情報へ</button>
      </div>
  </main>
</template>

<script>
  export default {
    metaInfo: {
      title: '2段階設定登録完了'
    },
    name: "Complete",
    components: {
    },

    data() {
      return {
        user_id: 0,
        account_type: '',
        otp_backup_codes: [],
      }
    },

    // コンポーネントを描画するルートが確立する前に呼ばれる
    beforeRouteEnter(to, from, next) {
      // 意図しない画面遷移によるバックアップコードの更新を防ぐため、
      // 2段階認証設定確認画面以外から遷移してきた場合は2段階認証設定画面へ移動する
      if (!!from.name && from.name.match(/TwoFactorAuthConfirm/)){
        next();
      }
      else{
        next({path: to.path.split('/').slice(0, -1).join('/') + '/edit'});
      }
    },

    created() {
      this.onInit();
    },
    methods: {
      onInit() {
        // アカウント情報へ戻るボタンの遷移先をセットする
        this.$loading.load(
          this.$auth.api.get(`two_factor_auth_setting/complete`)
          .then(res => {
            this.user_id = res.data.user_id;
            this.account_type = res.data.account_type;
            this.otp_backup_codes = res.data.otp_backup_codes;
          })
          .catch(err => {
            this.$errorHandlers.initial(err);
          })
        );
      },

      // アカウント詳細に戻る
      backAccountDetail() {
        // アカウント種別に応じて遷移先を変更する
        // ブラウザバック防止のためreplaceで遷移
        switch (this.account_type) {
          case 'dtvs_admin':
          case 'dtvs_cm':
            this.$router.replace({name: 'AdminUserShow', params: {id: this.user_id}});
            break;
          case 'ep_admin':
            this.$router.replace({name: 'EpEpCompanyShow'});
            break;
          case 'ep_person_in_charge':
            this.$router.replace({name: 'EpPersonInChargeShow', params: {id: this.user_id}});
            break;
          case 'su_user':
            this.$router.replace({name: 'SuSuCompanyDetail'});
            break;
          case 'su_person_in_charge':
            this.$router.replace({name: 'SuPersonInChargeShow', params: {id: this.user_id}});
            break;
          default:
            break;
        }
      }
    }
  }
</script>
