// 第一引数valueに丸める文字列、第二引数lengthに文字数
export default function truncate( value, length ) {
  var ommision = "...";
  if (!!value) {
    if (value.length <= length) {
      return value;
    }
    return value.substring(0, length) + ommision;
  }
}
