import Format from './format'
import nl2br from './nl2br'
import htmlEscape from './htmlEscape'
import Truncate from './truncate'
import statisticNumber from './statisticNumber'
import linkify from './linkify'

export default {
	format: Format,
	nl2br: nl2br,
	htmlEscape: htmlEscape,
	truncate: Truncate,
	statisticNumber: statisticNumber,
	linkify: linkify,
}
