<template>
  <div>
    <main>
      <section class="main-title">
        <h1>オファー詳細</h1>
      </section>

      <!-- オファー詳細 -->
      <section class="chapter white-block offer-deta" v-if="offer.su_latest_info">
        <h2 class="chapter-label mb-3">オファー情報</h2>
        <div class="active-trigger js-switch-trigger" v-if="edit_mode == true && edit_view_flg == false">
          <button class="btn btn-sm btn-icon mx-1" type="button" data-toggle="tooltip" data-original-title="オファー詳細編集" @click="onClickEdit()">
            <i class="fas fa-pen"></i>
          </button>
        </div>

        <!-- オファー詳細(編集不可能モード) -->
        <div class="overview-list overview-list-dt15 my-3 p-3" v-if="edit_view_flg == false">
          <dl class="form-confirm-group">
            <dt>スタートアップ</dt>
            <dd>{{ offer.su_company.company_name }}</dd>
          </dl>
          <dl class="form-confirm-group">
            <dt>ステータス</dt>
            <dd>{{ offer.offer_detail_status }}</dd>
          </dl>
          <dl class="form-confirm-group" v-if="offer.is_offered && offer.recommend_answer_status != 'offer_awaiting_review'">
            <dt>審査完了日</dt>
            <dd>{{ offer.review_at | format.datetime }}</dd>
          </dl>
          <dl class="form-confirm-group" v-if="offer.is_offered && offer.recommend_answer_status == 'offer_returned'">
            <dt>差し戻し理由</dt>
            <dd>{{ offer.no_reason }}</dd>
          </dl>
          <template v-if="offer.is_offered">
            <dl class="form-confirm-group">
              <dt>オファータイトル</dt>
              <dd>{{ offer.ep_offer_title }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>オファー本文</dt>
              <dd>{{ offer.ep_offer_detail }}</dd>
            </dl>
          </template>
          <dl class="form-confirm-group" v-else>
            <dt>提案歓迎内容</dt>
            <dd>{{ offer.ep_welcome_detail }}</dd>
          </dl>
        </div>

        <!-- オファー詳細(編集可能モード) -->
        <form class="form-wrap" :class="{'offer-edit-wrap': !edit_view_flg}" v-if="offer.is_offered">
          <div class="row">
            <label class="col-2 col-form-label">スタートアップ</label>
            <p class="col-10 col-form-text">{{ offer.su_company.company_name }}</p>
          </div>
          <div class="row">
            <label class="col-2 col-form-label">ステータス</label>
            <p class="col-10 col-form-text">{{ offer.offer_detail_status }}</p>
          </div>
          <div class="row" v-if="offer.recommend_answer_status != 'offer_awaiting_review'">
            <label class="col-2 col-form-label">審査完了日</label>
            <p class="col-10 col-form-text">{{ offer.review_at | format.datetime }}</p>
          </div>
          <div class="row" v-if="offer.recommend_answer_status == 'offer_returned'">
            <label class="col-2 col-form-label">差し戻し理由</label>
            <p class="col-10 col-form-text text-prewrap">{{ offer.no_reason }}</p>
          </div>
          <div class="form-group row mt-4">
            <label class="col-2 col-form-label" for="offer-title">オファータイトル</label>
            <div class="col-10">
              <b-form-input
                  class="form-control"
                  type="text"
                  id="offer-title"
                  :state="was_valid ? !validation_errors.ep_offer_title : null"
                  v-model="form.offer_title"
                />
              <small class="form-text text-right text-secondary">100文字以内</small>
              <div v-if="!!validation_errors.ep_offer_title">
                <p  v-for="error_message in validation_errors.ep_offer_title" :key="error_message" class="invalid-feedback d-block">
                  {{ error_message }}
                </p>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-2 col-form-label" for="offer-outline">オファー本文</label>
            <div class="col-10 incremental-wrap">
              <b-form-textarea
                  class="form-control auto-resize"
                  id="offer-outlinel"
                  style="overflow: hidden; overflow-wrap: break-word;"
                  :state="was_valid ? !validation_errors.ep_offer_detail : null"
                  v-model="form.offer_detail"
                >
                </b-form-textarea>
              <small class="form-text text-right text-secondary">500文字以内</small>
              <div v-if="!!validation_errors.ep_offer_detail">
                <p  v-for="error_message in validation_errors.ep_offer_detail" :key="error_message" class="invalid-feedback d-block">
                  {{ error_message }}
                </p>
              </div>
            </div>
          </div>
          <div class="btn-container d-flex justify-content-end">
            <button class="button-square btn-cancel" type="button" @click="onClickEditClose()">閉じる</button>
            <button class="button-square btn-send" type="button" @click="onClickConfirm()">登録する</button>
          </div>
        </form>
      </section>
      <!-- /オファー詳細 -->

      <!-- スタートアップ企業情報 -->
      <section class="chapter white-block" v-if="offer.su_latest_info">
        <h2 class="chapter-label mb-3">スタートアップ企業情報</h2>
        <a class="accordion-trigger" data-toggle="collapse" href="#" @click.prevent.stop="" v-b-toggle.su-company-toggle><i class="fas"></i></a>
        <b-collapse id="su-company-toggle" role="tabpanel">
          <div class="border-top p-3 my-4">
            <div class="deta-header">
              <h3 class="company-name">{{ offer.su_company.company_name }}</h3>
              <dl>
                <dt><i class="fas fa-desktop" title="URL"></i></dt>
                <dd><a class="btn-text" :href="offer.su_company.company_hp" target="_blank" rel="noopener">{{ offer.su_company.company_hp }}</a></dd>
              </dl>
            </div>
            <div class="overview-list overview-list-dt15">
              <dl class="form-confirm-group">
                <dt>業界</dt>
                <dd>{{ offer.su_company.industry_name }}</dd>
              </dl>
              <dl class="form-confirm-group">
                <dt>所在地</dt>
                <dd><template v-if="!!offer.su_company.prefecture">{{ offer.su_company.prefecture }}{{ offer.su_company.address }}</template></dd>
              </dl>
              <dl class="form-confirm-group">
                <dt>私たちについて</dt>
                <dd>{{ offer.su_company.business_summary }}</dd>
              </dl>
              <dl class="form-confirm-group">
                <dt>ステージ</dt>
                <dd>{{ offer.su_company.stage_i18n }}</dd>
              </dl>
            </div>
          </div>
        </b-collapse>
      </section>
      <!-- /スタートアップ企業情報 -->

      <!-- 最新情報 -->
      <section class="chapter accordion-block white-block" v-if="offer.su_latest_info">
        <h2 class="chapter-label mb-3">最新情報</h2>
        <a class="accordion-trigger" data-toggle="collapse" href="#" @click.prevent.stop="" v-b-toggle.su-latest-info-toggle><i class="fas"></i></a>
        <b-collapse class="article-container" id="su-latest-info-toggle">
          <article class="reading-block pb-0">
            <!-- メインビジュアル画像 -->
            <div class="reading-visual">
              <template v-if="offer.su_latest_info.main_image_url">
                <a class="img-box" :href="offer.su_latest_info.main_image_url"
                  data-lightbox="latest_info_image" :data-title="offer.su_latest_info.main_image_title">
                  <img
                    :src="offer.su_latest_info.main_image_url"
                    :alt="offer.su_latest_info.main_image_filename">
                </a>
                <div class="reading-visual-caption">{{ offer.su_latest_info.main_image_title }}</div>
              </template>
              <template v-else>
                <a class="img-box" href="#">
                  <img
                    src="~images/image_default.jpg"
                    alt="デフォルト画像"
                  />
                </a>
              </template>
            </div>
            <!-- 投稿日時 -->
            <div class="reading-bar">
              <small class="reading-date text-secondary">{{ offer.su_latest_info.created_at | format.datetime }}</small>
            </div>
            <!-- タイトル -->
            <h2 class="reading-title">{{ offer.su_latest_info.title }}</h2>
            <!-- 最新情報企業 -->
            <dl class="reading-body border-bottom">
              <dt>
                <img v-if="offer.su_latest_info.is_manager_image"
                    :src="offer.su_latest_info.manager_image_url"
                    :alt="offer.su_latest_info.manager_image_filename"
                    class="thumbnail-frame img40"
                />
                <img v-else
                    src="~images/su_user_default.png"
                    alt="画像が選択されていません"
                    class="thumbnail-frame img40"
                />
              </dt>
              <dd>
                <p class="company-name">{{ offer.su_company.company_name }}</p>
              </dd>
            </dl>
            <!-- 内容 -->
            <dl class="reading-item">
              <dt>最近何やってる？</dt>
              <dd class="u-pre-wrap" v-text="offer.su_latest_info.detail"></dd>
            </dl>
            <dl class="reading-item" v-if="offer.su_latest_info.sub1_image_url || offer.su_latest_info.sub2_image_url">
              <dt>イメージ</dt>
              <dd class="d-flex">
                <!-- 画像（サブビジュアル1） -->
                <figure v-if="offer.su_latest_info.sub1_image_url">
                  <a class="img-box" :href="offer.su_latest_info.sub1_image_url"
                    data-lightbox="latest_info_image" :data-title="offer.su_latest_info.sub1_image_title">
                    <img
                      :src="offer.su_latest_info.sub1_image_url"
                      :alt="offer.su_latest_info.sub1_image_filename"
                    >
                  </a>
                  <figcaption class="reading-visual-caption">{{ offer.su_latest_info.sub1_image_title }}</figcaption>
                </figure>
                <!-- 画像（サブビジュアル2） -->
                <figure class="mr-3" v-if="offer.su_latest_info.sub2_image_url">
                  <a class="img-box" :href="offer.su_latest_info.sub2_image_url"
                      data-lightbox="latest_info_image" :data-title="offer.su_latest_info.sub2_image_title">
                    <img
                      :src="offer.su_latest_info.sub2_image_url"
                      :alt="offer.su_latest_info.sub2_image_filename"
                    >
                  </a>
                  <figcaption class="reading-visual-caption">{{ offer.su_latest_info.sub2_image_title }}</figcaption>
                </figure>
              </dd>
            </dl>

            <dl class="reading-item">
              <dt>パートナー企業様に求めるアセット</dt>
              <dd>
                <template v-if="offer.su_latest_info.is_show_more_assets">
                  <div v-for="asset in offer.su_latest_info.asset_masters.slice(0, 10)" :key="asset.asset_master_id" class="task-labels">
                    <p class="task-labels-parent">
                      {{ asset.name }}
                    </p>
                  </div>
                  <div class="btn-container text-right">
                    <button class="js-view-trigger btn-text d-show" type="button" @click="onClickShowMoreAssetsSuLatestInfo()">
                      もっと見る
                    </button>
                  </div>
                </template>
                <template v-else>
                  <div v-for="asset in offer.su_latest_info.asset_masters" :key="asset.asset_master_id" class="task-labels">
                    <p class="task-labels-parent">
                      {{ asset.name }}
                    </p>
                  </div>
                </template>
              </dd>
            </dl>

            <dl class="reading-item" v-if="offer.su_latest_info.pitch_file_id">
              <dt>会社についてもっと詳しく知りたい</dt>
              <dd>
                <a class="btn btn-big btn-green rounded-pill" :href="offer.su_latest_info.pitch_file_url" :download="offer.su_latest_info.pitch_file_filename">資料ダウンロード<i class="fas fa-file-download pl-1"></i></a>
              </dd>
            </dl>
          </article>
        </b-collapse>
      </section>
      <!-- /最新情報 -->

      <!-- チャレンジ情報 -->
      <section class="chapter accordion-block white-block" v-if="offer.ep_challenge">
        <h2 class="chapter-label mb-3">チャレンジ情報</h2>
        <a class="accordion-trigger" data-toggle="collapse" href="#" @click.prevent.stop="" v-b-toggle.ep-challenge-toggle><i class="fas"></i></a>
        <b-collapse class="article-container" id="ep-challenge-toggle">
          <article class="reading-block pb-0">
            <!-- メインビジュアル画像 -->
            <div class="reading-visual">
              <template v-if="offer.ep_challenge.main_image_url">
                <a class="img-box" :href="offer.ep_challenge.main_image_url"
                  data-lightbox="latest_info_image" :data-title="offer.ep_challenge.main_image_title">
                  <img
                    :src="offer.ep_challenge.main_image_url"
                    :alt="offer.ep_challenge.main_image_filename">
                </a>
                <div class="reading-visual-caption">{{ offer.ep_challenge.main_image_title }}</div>
              </template>
              <template v-else>
                <a class="img-box" href="#">
                  <img
                    src="~images/image_default.jpg"
                    alt="デフォルト画像"
                  />
                </a>
              </template>
            </div>
            <!-- 投稿日時 -->
            <div class="reading-bar">
              <small class="reading-date text-secondary">{{ offer.ep_challenge.created_at | format.datetime }}</small>
            </div>
            <!-- タイトル -->
            <h2 class="reading-title">{{ offer.ep_challenge.title }}</h2>

            <!-- チャレンジ企業 -->
            <dl class="reading-body border-bottom">
              <dt>
                <img v-if="offer.ep_challenge.is_manager_image"
                    :src="offer.ep_challenge.manager_image_url"
                    :alt="offer.ep_challenge.manager_image_filename"
                    class="thumbnail-frame img40"
                />
                <img v-else
                    src="~images/ep_user_default.png"
                    alt="画像が選択されていません"
                    class="thumbnail-frame img40"
                />
              </dt>
              <dd>
                <p class="company-name">{{ offer.ep_company.company_name }}</p>
              </dd>
            </dl>

            <dl class="reading-item">
              <dt>私たちのチャレンジ</dt>
              <dd class="u-pre-wrap ql-snow">
                <div class="ql-editor" v-html="offer.ep_challenge.detail"></div>
              </dd>
            </dl>

            <dl class="reading-item" v-if="offer.ep_challenge.sub1_image_url || offer.ep_challenge.sub2_image_url">
              <dt>イメージ</dt>
              <dd class="d-flex">
                <!-- 画像（サブビジュアル1） -->
                <figure v-if="offer.ep_challenge.sub1_image_url">
                  <a
                    class="img-box"
                    :href="offer.ep_challenge.sub1_image_url"
                    data-lightbox="latest_info_image"
                    :data-title="offer.ep_challenge.sub1_image_title"
                  >
                    <img
                      :src="offer.ep_challenge.sub1_image_url"
                      :alt="offer.ep_challenge.sub1_image_filename"
                    >
                  </a>
                  <figcaption class="reading-visual-caption">{{ offer.ep_challenge.sub1_image_title }}</figcaption>
                </figure>
                <!-- 画像（サブビジュアル2） -->
                <figure class="mr-3" v-if="offer.ep_challenge.sub2_image_url">
                  <a
                    class="img-box"
                    :href="offer.ep_challenge.sub2_image_url"
                    data-lightbox="latest_info_image"
                    :data-title="offer.ep_challenge.sub2_image_title"
                  >
                    <img
                      :src="offer.ep_challenge.sub2_image_url"
                      :alt="offer.ep_challenge.sub2_image_filename"
                    >
                  </a>
                  <figcaption class="reading-visual-caption">{{ offer.ep_challenge.sub2_image_title }}</figcaption>
                </figure>
              </dd>
            </dl>

            <!-- チャレンジのアセット -->
            <dl class="reading-item">
              <dt>パートナー企業様にご提供できる価値</dt>
              <dd>
                <template v-if="offer.ep_challenge.is_show_more_assets">
                  <div v-for="asset in offer.ep_challenge.asset_masters.slice(0, 10)" :key="asset.asset_master_id" class="task-labels">
                    <p class="task-labels-parent">
                      {{ asset.name }}
                    </p>
                  </div>
                  <div class="btn-container text-right">
                    <button class="js-view-trigger btn-text d-show" type="button" @click="onClickShowMoreAssetsEpChallenge()">
                      もっと見る
                    </button>
                  </div>
                </template>
                <template v-else>
                  <div v-for="asset in offer.ep_challenge.asset_masters" :key="asset.asset_master_id" class="task-labels">
                    <p class="task-labels-parent">
                      {{ asset.name }}
                    </p>
                  </div>
                </template>
              </dd>
            </dl>

            <!-- チャレンジのステージ -->
            <dl class="reading-item">
              <dt>このチャレンジのステータス</dt>
              <dd>
                <div class="status-list">

                  <!-- アイデア段階 -->
                  <div class="status-item">
                    <div v-if="offer.ep_challenge.is_idea_stage">
                      <p class="status-label active">アイデア段階</p>
                      <div class="img-box"><img src="~images/status_img1_on.svg" alt=""></div>
                    </div>
                    <div v-else>
                      <p class="status-label">アイデア段階</p>
                      <div class="img-box"><img src="~images/status_img1.svg" alt=""></div>
                    </div>
                  </div>

                  <!-- PoC段階 -->
                  <div class="status-item">
                    <div v-if="offer.ep_challenge.is_poc_stage">
                      <p class="status-label active">PoC段階</p>
                      <div class="img-box"><img src="~images/status_img2_on.svg" alt=""></div>
                    </div>
                    <div v-else>
                      <p class="status-label">PoC段階</p>
                      <div class="img-box"><img src="~images/status_img2.svg" alt=""></div>
                    </div>
                  </div>

                  <!-- 事業化段階 -->
                  <div class="status-item">
                    <div v-if="offer.ep_challenge.is_commercial_stage">
                      <p class="status-label active">事業化段階</p>
                      <div class="img-box"><img src="~images/status_img3_on.svg" alt=""></div>
                    </div>
                    <div v-else>
                      <p class="status-label">事業化段階</p>
                      <div class="img-box"><img src="~images/status_img3.svg" alt=""></div>
                    </div>
                  </div>

                  <!-- その他 -->
                  <div class="status-item">
                    <div v-if="offer.ep_challenge.is_other_stage">
                      <p class="status-label active">その他</p>
                      <div class="img-box"><img src="~images/status_img4_on.svg" alt=""></div>
                    </div>
                    <div v-else>
                      <p class="status-label">その他</p>
                      <div class="img-box"><img src="~images/status_img4.svg" alt=""></div>
                    </div>
                  </div>

                </div>
              </dd>
            </dl>

            <dl class="reading-item">
              <dt>私たちについて</dt>
              <dd class="u-pre-wrap" v-text="offer.ep_company.business_summary"></dd>
            </dl>

            <dl class="reading-item mb-0">
              <dt>担当者情報</dt>
              <dd class="thread d-flex py-4">
                <div class="icon-col">
                  <!-- チャレンジ担当者画像画像 -->
                  <img v-if="offer.ep_challenge.is_manager_image"
                    :src="offer.ep_challenge.manager_image_url"
                    :alt="offer.ep_challenge.manager_image_filename"
                    class="thumbnail-frame img80"
                  />
                  <img v-else
                    src="~images/ep_user_default.png"
                    alt="画像が選択されていません"
                    class="thumbnail-frame img80"
                  />
                </div>
                <div class="data-col py-1">
                  <p class="h5">{{ offer.ep_challenge.manager_name }}</p>
                  <p class="text-box mt-1 u-pre-wrap" v-text="offer.ep_challenge.manager_profile"></p>
                </div>
              </dd>
            </dl>
          </article>
        </b-collapse>
      </section>
      <!-- /チャレンジ情報 -->

      <!-- ボタン -->
      <div class="btn-container adjust-lotof-btn d-flex justify-content-center">
        <a class="btn btn-big btn-white rounded-pill" href="#" @click="onClickBack()">戻る</a>
        <a class="btn btn-big btn-delete rounded-pill" href="#" v-if="offer.recommend_answer_status == 'offer_awaiting_review' || offer.recommend_answer_status == 'offer_returned'" @click="onClickDelete()">削除する</a>
      </div>
      <!-- /ボタン -->

      <!-- 編集確認モーダル -->
      <ConfirmModal
          ref="edit_confirm_modal"
          :onClickSend="onEditSend"
          :body_text="'編集内容を確定します。<br>よろしいですか？'"
          :send_button_text="'登録する'"
          :isNoFade="isNoFade"
      />

      <!-- 編集確認モーダル -->
      <ConfirmModal
          ref="delete_confirm_modal"
          :onClickSend="onDeleteSend"
          :body_text="'オファー内容を削除します。<br>よろしいですか？'"
          :send_button_text="'削除する'"
          :isNoFade="isNoFade"
      />
    </main>
  </div>
</template>
<script>
  // 確認モーダルのコンポーネント読み込み
  import ConfirmModal from '../../../common/pages/ConfirmModal.vue';

  export default {

    metaInfo: {
      title: 'オファー詳細',
    },

    components: { ConfirmModal },

    props: { isNoFade: Boolean },

    data() {
      return {
        ep_offer_suggestions_welcome_id: this.$route.params.id,
        offer: [],
        edit_mode: false,
        edit_view_flg: false,
        offer_returned: false,
        // バリデーションエラーが発生したか
        was_valid: false,
        validation_errors: {},
        previous_route: null,
        form: {
          offer_title: "",
          offer_detail: "",
          ep_recommend_updated_at: "",
          ep_offer_suggestions_welcome_updated_at: "",
        },
      }
    },

    // コンポーネントを描画するルートが確立する前に呼ばれる
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.previous_route = from;
      });
    },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.init();
    },

    // 計算プロパティ
    computed: {
      // 定数を定義
      choiced_offer: function() { return 11 }, // オファーレビュー待ち
      choiced_welcome: function() { return 23 },// 提案歓迎
      choiced_seeoff: function() { return 0 }, // 見送り
    },

    //フィルタ定義
    filters: {
      numberWithDelimiter (value) {
        if (!value) {
          return '0'
        }
        return value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,')
      }
    },

    // メソッド定義
    methods: {
      init() {
        this.$loading.load(
          this.$auth.api.get(
            'ep/offers/' + this.ep_offer_suggestions_welcome_id, {
              params: {}
            }
          ).then(res => {
            // 取得したデータを格納
            this.offer = res.data.offer;
            this.form.ep_recommend_updated_at = res.data.offer.ep_recommend_updated_at;
            this.form.ep_offer_suggestions_welcome_updated_at = res.data.offer.updated_at;
            this.form.offer_title = res.data.offer.ep_offer_title;
            this.form.offer_detail = res.data.offer.ep_offer_detail;

            // 編集可能なデータ(レビュー待ち・差し戻し中ならば)
            this.edit_mode = (this.offer.recommend_answer_status == 'offer_awaiting_review' || this.offer.recommend_answer_status == 'offer_returned');
            this.edit_view_flg = false;
          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        )
      },

      // もっと見るボタン押下時(最新情報)
      onClickShowMoreAssetsSuLatestInfo(){
        this.offer.su_latest_info.is_show_more_assets = false;
      },

      // もっと見るボタン押下時(チャレンジ)
      onClickShowMoreAssetsEpChallenge(){
        this.offer.ep_challenge.is_show_more_assets = false;
      },

      // 編集ボタン押下時処理
      onClickEdit() {
        this.edit_view_flg = true;
      },

      // 編集:閉じるボタン押下時処理
      onClickEditClose() {
        this.edit_view_flg = false;
      },

      // 戻るボタン押下時処理
      onClickBack() {
        if (!!this.previous_route && (this.previous_route.name === 'EpOfferShow' || this.previous_route.name === 'EpOfferIndex')) {
          this.$router.push({path: this.previous_route.fullPath});
        } else {
          this.$router.push({name: 'EpOfferIndex'});
        }
      },

      // 削除ボタン押下時
      onClickDelete() {
        this.$refs.delete_confirm_modal.open();
      },

      // 削除確定時
      onDeleteSend() {
        this.$loading.load(
          this.$auth.api.delete(
            'ep/offers/' + this.ep_offer_suggestions_welcome_id, { params: this.form }
          ).then(res => {
            this.$refs.delete_confirm_modal.close();
            this.$router.push({name: 'EpOfferIndex', query: { toast_type: 'deleted' } });
          }).catch(err => {
            if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else{
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },

      // 編集対応確認モーダル
      onClickConfirm() {
        this.$loading.load(
          this.$auth.api.post(
            'ep/offers/' + this.ep_offer_suggestions_welcome_id + '/update_confirm', this.form
          ).then(res => {
            this.was_valid = false;
            this.validation_errors = {};
            this.$refs.edit_confirm_modal.open();
          }).catch(err => {
            if(err.response.status == 422) {
              if (err.response.data && err.response.data.errors) {
                this.was_valid = true;
                this.validation_errors = err.response.data.errors;
              }
            }
            else if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else{
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },

      // 編集確定時
      onEditSend() {
        this.$loading.load(
          this.$auth.api.patch(
            'ep/offers/' + this.ep_offer_suggestions_welcome_id, this.form
          ).then(res => {
            this.$refs.edit_confirm_modal.close();
            this.init(); // 再読み込み
          }).catch(err => {
            if(err.response.status == 422) {
              if (err.response.data && err.response.data.errors) {
                this.was_valid = true;
                this.validation_errors = err.response.data.errors;
              }
              this.$refs.edit_confirm_modal.close();
            }
            else if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else{
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },

    }

  }
</script>
<style>
.lb-nav a.lb-prev, .lb-nav a.lb-next {
  width: 50%;
}
</style>
