const initialState = () => {
  return {
    user: {
      id: '',
      email: '',
      last_name: '',
      first_name: '',
      kana_last_name: '',
      kana_first_name: '',
      position: '',
      department: '',
      profile: '',
      account_type: '',
      su_company_id: '',
      password: '',
      password_confirmation: '',
      uploaded_image: '',
      new_image_name: '',
      updated_at: '',
      image_attributes: {
        filename: '',
      },
    },
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    reset(state){
      Object.assign(state, initialState())
    },
    setUser: function(state, data){
      state.user = data.user
    },
    initUser: function(state){
      state.user.id = ''
      state.user.email = ''
      state.user.last_name = ''
      state.user.first_name = ''
      state.user.kana_last_name = ''
      state.user.kana_first_name = ''
      state.user.position = ''
      state.user.department = ''
      state.user.profile = ''
      state.user.account_type = ''
      state.user.su_company_id = ''
      state.user.password = ''
      state.user.password_confirmation = ''
      state.user.uploaded_image = ''
      state.user.new_image_name = ''
      state.user.image_attributes = { filename: '' }
    },
  },
  getters: {
    User: state => {
      return state.user
    },
    uploadedUserImage: state => {
      return state.user_uploaded_image
    },
    imgUserName: state => {
      return state.user_img_name
    },
    password: state => {
      return state.password
    },
    passwordConfirm: state => {
      return state.password_confirmation
    },
  }
}
