export default {
  methods: {

    // 端末のチェック(スマホ、タブレットの場合はfalse、PCの場合はtrue)
    deviceIsPc(){
      var ua = [
        'iPod',
        'iPad',
        'iPhone',
        'Android',
        'Mobile',
        'Tablet',
        'Opera Mini'
      ]
      for (var i = 0; i < ua.length; i++) {
        if (navigator.userAgent.indexOf(ua[i]) > 0) {
          return false;
        }
      }
      return true;
    },
  }
}
