import 'moment/locale/ja'
import autosize from 'autosize'
export default{
  data(){
    return {
      errors: {},
      was_valid: true,
    }
  },
  methods: {
    checkValid (value) {
      if(!!this.errors[value]){
        return false;
      }else if(!this.errors[value] && this.was_valid){
        return true;
      }else{
        ; //422エラーがなく、画面が初期表示である場合はなにもしない
      }
    },
    // カレンダー
    // input_id : datepickerを設定するinputのID
    // selectedCallback : カレンダーを選択した時のコールバックメソッド
    // widget_pos_vertical : カレンダーの表示位置(デフォルト : auto)
    setDatePicker(input_id, selectedCallback, widget_pos_vertical = 'auto') {
      $(input_id).datetimepicker({
        dayViewHeaderFormat: 'YYYY年 M月',
        tooltips: {
          close: '閉じる',
          selectMonth: '月を選択',
          prevMonth: '前月',
          nextMonth: '次月',
          selectYear: '年を選択',
          prevYear: '前年',
          nextYear: '次年',
          selectTime: '時間を選択',
          selectDate: '日付を選択',
          prevDecade: '前期間',
          nextDecade: '次期間',
          selectDecade: '期間を選択',
          prevCentury: '前世紀',
          nextCentury: '次世紀'
        },
        widgetPositioning: {
          horizontal: 'auto',
          vertical: widget_pos_vertical
        },
        format: 'YYYY/MM/DD',
        locale: 'ja'
      });
      $(input_id).blur(function() {
        $(input_id).datetimepicker('hide');
      });
      $(input_id).on('change.datetimepicker', function (e) {
        selectedCallback($(input_id).val());
      });
    },
    setTimePicker(input_id, selectedCallback) {
      $(input_id).datetimepicker(
        {
          icons:
          {
            up: 'fas fa-chevron-up',
            down: 'fas fa-chevron-down'
          },
          tooltips:
          {
            close: '閉じる',
            pickHour: '時間を取得',
            incrementHour: '時間を増加',
            decrementHour: '時間を減少',
            pickMinute: '分を取得',
            incrementMinute: '分を増加',
            decrementMinute: '分を減少',
            pickSecond: '秒を取得',
            incrementSecond: '秒を増加',
            decrementSecond: '秒を減少',
            togglePeriod: '午前/午後切替',
            selectTime: '時間を選択'
          },
          format: 'HH:mm',
          locale: 'ja'
        }
      );
      $(input_id).blur(function() {
        $(input_id).datetimepicker('hide');
      });
      $(input_id).on('change.datetimepicker', function (e) {
        selectedCallback($(input_id).val());
      });
    },
    // 登録処理後などの一覧画面遷移（検索結果保持用）
    transitionProcessed (toast_type, name) {
      let from_page = this.$store.getters['back_to_index_modal/fromPage'];
      if(from_page.full_path && from_page.name === name) {
        this.$router.push({ path: from_page.full_path, query: { toast_type: toast_type } })
      }else{
        this.$router.push({ name: name, query: { toast_type: toast_type } })
      }
    },
    // テキストエリア オートサイズの設定
    setTextAreaAutoSize(class_name = '.auto-resize') {
      autosize($(class_name));
    },
  }
}