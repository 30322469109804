<template>
<div class="p-3">
  <section class="main-title d-flex">
    <h1>承認中の最新情報情報（比較）</h1>
    <ul class="diff-guide ml-auto">
      <li class="diff-style">
        <div class="diff-added"></div>
        <label>変更箇所</label>
      </li>
    </ul>
  </section>

  <section class="chapter white-block">
    <h2 class="chapter-label mb-3">最新情報詳細</h2>
    <div class="overview-list my-3 p-3">
      <dl class="form-confirm-group">
        <dt>企業名</dt>
        <dd>{{ !!provisional_su_latest_info.su_company ? provisional_su_latest_info.su_company.company_name : '' }}</dd>
      </dl>

      <dl class="form-confirm-group">
        <dt>
          <span :class="isDiff(provisional_su_latest_info.title, su_latest_info.title) ? 'diff-added' : ''">タイトル</span>
        </dt>
        <dd id="title_area">
          <template v-for="status_text in diff(su_latest_info.title, provisional_su_latest_info.title)">
            <template v-if="isDiffDeleteText(status_text)"><span class="diff-added"><s>{{status_text[1]}}</s></span></template>
            <template v-else-if="isDiffText(status_text)"><span class="diff-added">{{status_text[1]}}</span></template>
            <template v-else>{{status_text[1]}}</template>
          </template>
        </dd>
      </dl>

      <dl class="form-confirm-group">
        <dt>
          <span :class="isDiff(provisional_su_latest_info.detail, su_latest_info.detail) ? 'diff-added' : ''">本文</span>
        </dt>
        <dd>
          <template v-for="status_text in diff(su_latest_info.detail, provisional_su_latest_info.detail)">
            <template v-if="isDiffDeleteText(status_text)"><span class="diff-added"><s>{{status_text[1]}}</s></span></template>
            <template v-else-if="isDiffText(status_text)"><span class="diff-added">{{status_text[1]}}</span></template>
            <template v-else>{{status_text[1]}}</template>
          </template>
        </dd>
      </dl>

      <dl class="form-confirm-group">
        <dt>
          <span :class="su_latest_info.is_diff_asset ? 'diff-added' : ''">アセット</span>
        </dt>
        </dd>
        <dd class="task-labels-wrap">
          <div v-for="asset_master in su_latest_info.asset_masters" class="task-labels">
            <p class="task-labels-parent">{{ asset_master.name }}</p>
          </div>
        </dd>
      </dl>

      <dl class="form-confirm-group">
        <dt>
          <span :class="isDiff(provisional_su_latest_info.main_image_id, su_latest_info.main_image_id) ? 'diff-added' : ''">メインビジュアル</span>
        </dt>
        <dd>
          <!-- 保存されている画像 -->
          <img v-if="!isObjectEmpty(su_latest_info.main_image)"
            class="img320"
            :src="su_latest_info.main_image.presigned_url"
            :alt="su_latest_info.main_image.filename"
          />
          <!-- デフォルト画像 -->
          <img v-else
            class="img320"
            src="~images/image_default.jpg"
            alt=""
          />
        </dd>
      </dl>

      <dl class="form-confirm-group">
        <dt>
          <span :class="isDiff(provisional_su_latest_info.main_image_title, su_latest_info.main_image_title) ? 'diff-added' : ''">メインビジュアル<br>タイトル</span>
        </dt>
        <dd>
          <template v-for="status_text in diff(su_latest_info.main_image_title, provisional_su_latest_info.main_image_title)">
            <template v-if="isDiffDeleteText(status_text)"><span class="diff-added"><s>{{status_text[1]}}</s></span></template>
            <template v-else-if="isDiffText(status_text)"><span class="diff-added">{{status_text[1]}}</span></template>
            <template v-else>{{status_text[1]}}</template>
          </template>
        </dd>
      </dl>

      <dl class="form-confirm-group">
        <dt>
          <span :class="isDiff(provisional_su_latest_info.sub1_image_id, su_latest_info.sub1_image_id) ? 'diff-added' : ''">イメージ 1</span>
        </dt>
        <dd>
          <!-- 保存されている画像 -->
          <img v-if="!isObjectEmpty(su_latest_info.sub1_image)"
            class="img320"
            :src="su_latest_info.sub1_image.presigned_url"
            :alt="su_latest_info.sub1_image.filename"
          />
          <!-- デフォルト画像 -->
          <img v-else
            class="img320"
            src="~images/image_default.jpg"
            alt=""
          />
        </dd>
      </dl>

      <dl class="form-confirm-group">
        <dt>
          <span :class="isDiff(provisional_su_latest_info.sub1_image_title, su_latest_info.sub1_image_title) ? 'diff-added' : ''">イメージ 1 タイトル</span>
        </dt>
        <dd>
          <template v-for="status_text in diff(su_latest_info.sub1_image_title, provisional_su_latest_info.sub1_image_title)">
            <template v-if="isDiffDeleteText(status_text)"><span class="diff-added"><s>{{status_text[1]}}</s></span></template>
            <template v-else-if="isDiffText(status_text)"><span class="diff-added">{{status_text[1]}}</span></template>
            <template v-else>{{status_text[1]}}</template>
          </template>
        </dd>
      </dl>

      <dl class="form-confirm-group">
        <dt>
          <span :class="isDiff(provisional_su_latest_info.sub2_image_id, su_latest_info.sub2_image_id) ? 'diff-added' : ''">イメージ 2</span>
        </dt>
        <dd>
          <!-- 保存されている画像 -->
          <img v-if="!isObjectEmpty(su_latest_info.sub2_image)"
            class="img320"
            :src="su_latest_info.sub2_image.presigned_url"
            :alt="su_latest_info.sub2_image.filename"
          />
          <!-- デフォルト画像 -->
          <img v-else
            class="img320"
            src="~images/image_default.jpg"
            alt=""
          />
        </dd>
      </dl>

      <dl class="form-confirm-group">
        <dt>
          <span :class="isDiff(provisional_su_latest_info.sub2_image_title, su_latest_info.sub2_image_title) ? 'diff-added' : ''">イメージ 2 タイトル</span>
        </dt>
        <dd>
          <template v-for="status_text in diff(su_latest_info.sub2_image_title, provisional_su_latest_info.sub2_image_title)">
            <template v-if="isDiffDeleteText(status_text)"><span class="diff-added"><s>{{status_text[1]}}</s></span></template>
            <template v-else-if="isDiffText(status_text)"><span class="diff-added">{{status_text[1]}}</span></template>
            <template v-else>{{status_text[1]}}</template>
          </template>
        </dd>
      </dl>

      <dl class="form-confirm-group">
        <dt>企業情報<br>（私たちについて）</dt>
        <dd v-if="!!provisional_su_latest_info.su_company">{{ provisional_su_latest_info.su_company.business_summary }}</dd>
      </dl>
    </div>
  </section>

</div>
</template>

<script>
  // 差分表示ライブラリ
  import DiffMatchPatch from 'diff-match-patch'


  export default {
    metaInfo: {
      title: '承認中の最新情報情報',
    },

    components: {
      DiffMatchPatch,
    },

    data() {
      return {
        provisional_su_latest_info: {},
        su_latest_info: {},
      }
    },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.init();
    },

    mounted: function() {
      // ヘッダー非表示
      $('.top-header').hide();
      // フッター非表示
      $('.footer').hide();
    },

    // メソッド定義
    methods: {
      // 初期化
      init() {
        this.getProvisionalSuLatestInfo();
      },

      getProvisionalSuLatestInfo() {
        this.$auth.api.get(
          `admin/provisional_su_latest_infos/${this.$route.params.id}/diff`
        ).then(res => {
          this.provisional_su_latest_info = res.data.provisional_su_latest_info;
          this.su_latest_info             = res.data.provisional_su_latest_info.su_latest_info;
        }).catch(err => {
          this.$errorHandlers.initial(err);
        })
      },

      // 閉じるボタン押下時処理
      onClose() {
        window.close()
      },

      // 差分取得
      diff(before_text, after_text) {
        if(before_text == null || after_text == null) {
          return [];
        }

        let result = null;
        let dmp = new DiffMatchPatch;

        result = dmp.diff_main(before_text, after_text);
        dmp.diff_cleanupSemantic(result);

        return result;
      },

      // 差分があるか
      isDiff(before_text, after_text) {
        return before_text != after_text;
      },

      // 差分があるか
      isDiffText(status_text_list) {
        let result = false;

        // 差分がある(追加されている)
        if(status_text_list[0] == 1) {
          result = true;
        }
        // 差分無し
        else if(status_text_list[0] == 0) {
          result = false;
        }

        return result;
      },

      // 削除されたテキストか
      isDiffDeleteText(status_text_list) {
        let result = false;

        // 削除されている
        if(status_text_list[0] == -1) {
          result = true;
        }

        return result;
      },

      isObjectEmpty(object) {
        if(!object) {
          return true;
        }
        return !(Object.keys(object).length)
      },
    }

  }
</script>