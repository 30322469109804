export default {
  methods: {

    // アップロードされた画像のファイルチェック処理
    // エラー時はエラーメッセージを返す
    checkUploadImage(file) {
      const fileExtLimit = new Array('jpeg', 'jpg', 'png');
      const fileSizeLimit = 2;

      // 拡張子チェック
      let file_ext = file.name.split('.').pop().toLowerCase();
      if(!fileExtLimit.includes(file_ext)) {
        return '画像はjpg, JPG, png, PNG, jpeg, JPEG のみ使用できます。';
      }

      // サイズチェック
      else if (file.size > fileSizeLimit * 1024 * 1024){
        return `画像は${fileSizeLimit}MBまで使用できます。`;
      }
      return '';
    },

    // アップロードされたピッチ資料のファイルチェック処理
    // エラー時はエラーメッセージを返す
    checkUploadPitch(file) {
      const fileExtLimit = new Array('pdf');
      const fileSizeLimit = 5;

      // 拡張子チェック
      let file_ext = file.name.split('.').pop().toLowerCase();
      if(!fileExtLimit.includes(file_ext)) {
        return 'ピッチ資料はpdf のみ使用できます。';
      }

      // サイズチェック
      else if (file.size > fileSizeLimit * 1024 * 1024){
        return `ピッチ資料サイズは${fileSizeLimit}MBまで使用できます。`;
      }
      return '';
    },

    // アップロードされたピッチ資料のファイルチェック処理
    // エラー時はエラーメッセージを返す
    checkUploadCsv(file) {
      const fileExtLimit = new Array('csv');
      const fileSizeLimit = 5;

      // 拡張子チェック
      let file_ext = file.name.split('.').pop().toLowerCase();
      if(!fileExtLimit.includes(file_ext)) {
        return '調達実績はcsv のみ取込できます。';
      }

      // サイズチェック
      else if (file.size > fileSizeLimit * 1024 * 1024){
        return `ファイルサイズは${fileSizeLimit}MBまで使用できます。`;
      }
      return '';
    },

  }
}
