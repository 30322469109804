import Vue from 'vue';
import router from '../router';
import store from '../store';
import App from '../app';
import Auth from './plugins/auth';
import "core-js/stable";
import "regenerator-runtime/runtime";

/* jQuery */
import jQuery from 'jquery'
global.jquery = jQuery
global.jQuery = jQuery
global.$ = jQuery
window.$ = window.jQuery = require('jquery')

/* moment js */
import moment from 'moment';
Vue.prototype.moment = moment;
window.moment = moment;

/* datetimepicker */
require('tempusdominus-bootstrap-4');
import 'tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min.css';
/* lightbox2 */
require("lightbox2");
import "lightbox2/dist/css/lightbox.min.css";

/* bootstrap-vue */
import BootstrapVue from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue, {
  BToast: {
    toaster: "b-toaster-top-center",
    autoHideDelay: 5000,
  },
})

/* vue-select */
import vSelect from 'vue-select'
Vue.component("v-select", vSelect);

import '../stylesheets/application'

Vue.use(Auth);

import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
})

import VueFragment from 'vue-fragment'
Vue.use(VueFragment.Plugin);

// レイアウト
import DefaultLayout from '../components/common/layouts/default.vue'
Vue.component('default-layout', DefaultLayout);

import AdminMainLayout from '../components/admin/layouts/Main.vue'
Vue.component('admin-main-layout', AdminMainLayout);
import AdminMainFixedBottomLayout from '../components/admin/layouts/MainFixedBottom.vue'
Vue.component('admin-main-fixed-bottom-layout', AdminMainFixedBottomLayout);

import EpMainLayout from '../components/ep/layouts/Main.vue'
Vue.component('ep-main-layout', EpMainLayout);
import EpMainFixedBottomLayout from '../components/ep/layouts/MainFixedBottom.vue'
Vue.component('ep-main-fixed-bottom-layout', EpMainFixedBottomLayout);
import EpSingleLayout from '../components/ep/layouts/single.vue'
Vue.component('ep-single-layout', EpSingleLayout);

import SuMainLayout from '../components/su/layouts/Main.vue'
Vue.component('su-main-layout', SuMainLayout);
import SuMainFixedBottomLayout from '../components/su/layouts/MainFixedBottom.vue'
Vue.component('su-main-fixed-bottom-layout', SuMainFixedBottomLayout);
import SuSingleLayout from '../components/su/layouts/single.vue'
Vue.component('su-single-layout', SuSingleLayout);

import PasswordToggle from '../components/common/passwordToggle.vue'
Vue.component('password-toggle', PasswordToggle);

import ErrorHandlers from './plugins/errorHandlers'
Vue.use(ErrorHandlers);

import Loading from './plugins/loading'
Vue.use(Loading);

import VueCookies from 'vue-cookies'
Vue.use(VueCookies);

// フィルター
import Filters from '../filters'
import Dotize from 'dotize'
const filters = Dotize.convert(Filters)
for(const key in filters){
  const name = key.replace(/\.default$/, '');
  Vue.filter(name, filters[key]);
}

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#application',
    store: store,
    router,
    render: (h) => h(App),
  });
});