<template>
	<main>
		<div class="overview-text-area text-center mt-7">
			<h2>six brainをご利用いただきありがとうございます</h2>
			<p class="mt-4">six brainからログアウトしました。</p>
		</div>
	</main>
</template>

<script>
export default {
  data () {
    return {
      
    }
  },
  methods: {
    logout () {
      this.$loading.load(this.$auth.logout()
        .then(res => {
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
    }
  },
  computed: {
  },
  created: function () {
    
  },
  mounted: function() {
  	this.logout();
  },
  watch: {
  }
}
</script>

<style scoped>
</style>