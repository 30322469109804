<template>
  <main>
    <section class="main-title">
      <h1>過去の調達実績詳細</h1>
    </section>

    <!-- 過去の調達実績詳細-->
    <section class="chapter white-block">
      <div class="overview-wrap pt-3">
        <div class="right-col">
          <div class="overview-list my-4">
            <dl class="form-group form-confirm-group">
              <dt>調達日付</dt>
              <dd>{{ su_old_achievement.supply_date | format.date }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>金額</dt>
              <dd>{{ su_old_achievement.amount | format.trimFractionDigits }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>単位</dt>
              <dd>{{ su_old_achievement.unit }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>出資者</dt>
              <dd v-if="!su_old_achievement.invest_persons"></dd>
              <dd v-else>
                <p v-for="invest_person in su_old_achievement.invest_persons" :key="invest_person">{{ invest_person }}</p>
              </dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>投資家の数-金融系VC</dt>
              <dd>{{ su_old_achievement.investor_finance_vc }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt style="word-break:keep-all;">投資家の数-CVC・事業会社</dt>
              <dd>{{ su_old_achievement.investor_cvc_company }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>投資家の数-独立系VC</dt>
              <dd>{{ su_old_achievement.investor_independent }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>投資家の数-政府系</dt>
              <dd>{{ su_old_achievement.investor_government }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>投資家の数-海外VC</dt>
              <dd>{{ su_old_achievement.investor_international_vc }}</dd>
            </dl>
          </div>
        </div>
      </div>
      <!-- 過去の調達実績詳細-->
      <div class="btn-container d-flex justify-content-center">
        <button class="btn btn-medium btn-outline-green rounded-pill" type="button" @click="$router.push({ path: './edit' })">編集する</button>
      </div>
    </section>

    <div class="btn-container d-flex justify-content-center pt-2">
      <button class="btn btn-big btn-white rounded-pill" type="button" @click="backToPage()">もどる</button>

      <button class="btn btn-big btn-delete rounded-pill" type="button" data-toggle="modal" data-target="#delete" @click="$refs.delete_confirm_modal.open()">削除する</button>
    </div>

    <ConfirmModal
      ref="delete_confirm_modal"
      :onClickSend="onDeleteConfirmSend"
      :body_text="'この調達実績を削除します。<br>よろしいですか？'"
      :send_button_text="'削除する'"
      :isNoFade="isNoFade"
    />
  </main>
</template>

<script>
// 確認モーダルのコンポーネント読み込み
import ConfirmModal from "../../../common/pages/ConfirmModal.vue";

export default {
  metaInfo: {
    title: "過去の調達実績詳細",
  },

  components: {
    ConfirmModal,
  },

  props: {
    currentUserId: Number,
    isNoFade: Boolean,
  },

  data: function() {
    return {
      id: this.$route.params.id,
      su_old_achievement: {},
      registration_requests_id: "",
    };
  },
  created: function() {
    this.getSuOldAchievement();
  },

  methods: {
    getSuOldAchievement: function() {
      this.$loading.load(
        this.$auth.api
          .get(`admin/su_old_achievements/${this.id}`)
          .then((res) => {
            this.su_old_achievement = res.data.su_old_achievement;
            this.registration_requests_id = res.data.registration_requests_id;
          })
          .catch((err) => {
            this.$errorHandlers.initial(err);
          })
      );
    },
    // 戻るボタン押下
    backToPage() {
      this.$router.push({ name: "AdminSuCompanyDetail", params: { id: this.registration_requests_id } });
    },
    // 削除確認モーダル内削除するボタン押下時
    onDeleteConfirmSend() {
      this.onSuOldAchievementDelete();
    },
    // 削除処理
    onSuOldAchievementDelete() {
      this.$loading.load(
        this.$auth.api
          .delete("admin/su_old_achievements/" + this.su_old_achievement.id)
          .then((res) => {
            this.$refs.delete_confirm_modal.close();
            this.$router.push({ name: "AdminSuCompanyDetail", params: { id: this.registration_requests_id }, query: { toast_type: "deleted" } });
          })
          .catch((err) => {
            this.$refs.delete_confirm_modal.close();
            if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            } else {
              this.$errorHandlers.ajax(err);
            }
          })
      );
    },
  },
};
</script>
