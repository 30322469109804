<template>
  <div class="all-wrapper">
    <HeaderComponent
      :companyname="companyname"
      :message="message"
      :icon="icon"
      :notification-count="notification_count"
     />

    <div class="drawer">
      <input class="drawer-toggle" id="drawer-checkbox" type="checkbox" v-model="isOpen">
      <label class="drawer-btn" for="drawer-checkbox"><span></span><span></span><span></span></label>
    </div>

    <template v-if="this.$auth.accountType=='dtvs_admin'">
      <div class="side-area">
        <SidemenuComponent :currentUserId="current_user_id" />
      </div>
    </template>

    <template v-if="this.$auth.accountType=='dtvs_cm'">
      <div class="side-area">
        <SidemenuCmComponent :currentUserId="current_user_id" />
      </div>
    </template>

    <div class="main-scroll-container">
      <!-- main-area-->
      <div class="main-area">
        <router-view :currentUserId="current_user_id" :isNoFade="is_no_fade" :accountType="this.$auth.accountType"/>
        <FooterComponent />
      </div>
    </div>

  </div>

</template>

<script>
import HeaderComponent from '../../common/layouts/header';
import FooterComponent from '../../common/layouts/footer';
import SidemenuComponent from './Sidemenu.vue';
import SidemenuCmComponent from './SidemenuCm.vue';
// 端末判別コンポーネント
import DeviceCheck from '../../common/mixins/device_check.js';

export default {
  components: {
    HeaderComponent,
    SidemenuComponent,
    SidemenuCmComponent,
    FooterComponent
  },
  mixins: [DeviceCheck],

  metaInfo() {
    return {
      bodyAttrs: {
        class: this.isOpen ? ['drawer-open'] : []
      }
    }
  },
  data() {
    return {
      companyname: null,
      message: null,
      icon: null,
      current_user_id: null,
      notification_count: null,
      is_no_fade: false,
      isOpen: false,
    }
  },
  mounted () {
    this.getCompanyInfo();
    this.browserIECheck();
  },
  methods: {
    getCompanyInfo () {
      this.$auth.api.get('user_sessions')
      .then(res => {
        this.companyname = res.data.companyname;
        this.message = res.data.message;
        this.icon = res.data.icon;
        this.current_user_id = res.data.current_user_id;
        this.notification_count = res.data.notification_count;
      }).catch(err => {
        this.$errorHandlers.initial(err);
        if(err.response.status === 401) {
          this.$router.push({ name: 'AdminLogin', query: {error_status: 401}});
        }
      })
    },
    browserIECheck(){
      var ua = navigator.userAgent;
      if(ua.indexOf('Trident') !== -1) {
        this.is_no_fade = true;
      }
    },
  },
  watch: {
    '$route': function (to, from) {
      // 前画面情報を取得
      this.$store.commit('back_to_index_modal/setFromPage', {
        full_path: from.fullPath,
        name: from.name,
        path: from.path
      })
      this.isOpen = false;
    }
  }
}
</script>
