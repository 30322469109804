<template>
  <div>
    <router-view
      :user.sync="user"
      :su_admin_email.sync="su_admin_email"
      :accepted_extensions="accepted_extensions"
      :currentUserId="currentUserId"
      :prop_user_uploaded_image.sync="prop_user_uploaded_image"
      :prop_user_img_name.sync="prop_user_img_name"
      :check_account_type.sync="check_account_type">
    </router-view>
  </div>
</template>

<script>
  export default {
    data: function () {
      return {
        user: {},
        su_admin_email: '',
        accepted_extensions: [],
        prop_user_uploaded_image: '',
        prop_user_img_name: '',
        is_company_id_illegal: false,
        is_actor_illegal: false,
        is_su_actor_illegal: false,
        // 編集対象のユーザーの設定されているアカウント種別（DBに登録されている値）
        check_account_type: ''
      }
    },

    props: {
      currentUserId: Number,
    },

    beforeMount() {
      if(this.$route.name !== "AdminSuPersonInChargeEditConfirm"){
        this.fetchSuPersonInCharge();
        this.$store.commit('su_person_in_charge/initUser');
      }
    },
    methods: {
      fetchSuPersonInCharge: function() {
        this.$loading.load(this.$auth.api.get(`admin/su_person_in_charges/${this.$route.params.id}/edit`)
        .then(res => {
          this.user = res.data.user
          this.is_company_id_illegal = res.data.is_company_id_illegal
          this.is_actor_illegal = res.data.is_actor_illegal;
          this.is_su_actor_illegal = res.data.is_su_actor_illegal;
          this.checkIllegalId();
          this.check_account_type = res.data.user.account_type
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
      },
      checkIllegalId(){
        if (this.is_actor_illegal) {
          this.$router.push({path: '/admin/' , query: { toast_type: 'invalid_transitioned' } });
        } else if (this.is_ep_actor_illegal){
          this.$router.push({name: 'AdminSuCompanyDetail', params: {id: this.user.su_company_id}, query: {toast_type: 'invalid_transitioned'}});
        }
      },
      getUser () {
        this.user = this.$store.getters['su_person_in_charge/User']
      },
      setEnums () {
        this.$loading.load(this.$auth.api.get(`admin/su_person_in_charges/${this.$route.params.id}/init_edit.json`)
          .then(res => {
            this.user.su_company_id = res.data.su_company_id;
            this.accepted_extensions = res.data.accepted_extensions;
          })
          .catch(err => {
            this.$errorHandlers.initial(err);
          }));
      },
    },
    computed: {
      getUserUploadedImage () {
        this.prop_user_uploaded_image = this.$store.getters['su_person_in_charge/uploadedUserImage']
      },
      getUserImgName () {
        this.prop_user_img_name = this.$store.getters['su_person_in_charge/imgUserName']
      }
    },
    created: function(){
      this.setEnums();
      this.getUser();
      this.getUserUploadedImage;
      this.getUserImgName;
    },
  }
</script>
