<template>
  <main>
    <section class="main-title d-flex">
      <h1>リコメンド一覧</h1>
    </section>

    <!-- 検索-->
    <index-condition-section
      @search-button-click="onSearchFromChild"
      :condition-ep-companies="condition_ep_companies"
      :condition-ep-challenges="condition_ep_challenges"
      :condition-su-companies="condition_su_companies"
      :recommend-statuses="recommend_statuses"
      :recommend-types="recommend_types"
      :searched-su-company-id="searched_su_company_id"
      :searched-su-company-name="searched_su_company_name"
      :searched-recommend-statuses="searched_recommend_statuses"
      :searched-recommend-at-from="searched_recommend_at_from"
      :searched-recommend-at-to="searched_recommend_at_to"
      :searched-recommend-types="searched_recommend_types"
      :searched-interested-at-from="searched_interested_at_from"
      :searched-interested-at-to="searched_interested_at_to"
      :has-record="hasRecord"
      :loaded = "loaded"
    />
    <!-- /検索-->

    <!-- リスト-->
    <section v-show="hasRecord" class="chapter white-block">
      <div class="recommend">
        <div class="table-responsive mb-0 table-fixed-head">
          <table class="table table-striped table-hover">
            <thead class="thead-light">
              <tr>
                <th style="width: 2%"></th>
                <th style="width: 10%">企業名</th>
                <th style="width: 15%">タイトル</th>
                <th style="width: 15%">アセット</th>
                <th style="width: 10%">作成日</th>
                <th style="width: 13%">
                  <a @click="recommendSort()" class="sort-link js-sort_trigger">
                    最終リコメンド日<i :class="recommend_sort === 'down' ? 'fas fa-caret-down' : 'fas fa-caret-up'"></i>
                  </a>
                </th>
                <th style="width: 12%">公開日</th>
                <th style="width: 8%">リコメンド数</th>
                <th style="width: 15%"></th>
              </tr>
            </thead>
          </table>

          <!-- チャレンジ毎のアコーディオン生成 -->
          <table class="table table-bordered accordion-wrap recommend-list"
                  v-for="(ep_challenge_header, index) in ep_challenge_headers" :key="ep_challenge_header.ep_challenge_id">
            <!-- チャレンジ毎のアコーディオン生成：ヘッダ部分 -->
            <thead class="accordion-header" :class="{active: ep_challenge_header.accordion_opened}">
              <tr class="recommend-list-header table-success">
                <!-- アコーディオン開閉 -->
                <th style="width: 2%" @click="onAccordionState(ep_challenge_header.ep_company_id, ep_challenge_header.ep_challenge_id, index)"></th>
                <!-- 企業名・部署名 -->
                <th style="width: 10%">
                  {{ep_challenge_header.ep_company_name}}<br>
                  {{ep_challenge_header.ep_company_department}}
                </th>
                <!-- チャレンジタイトル（省略あり） -->
                <th style="width: 15%">
                  <a
                      :id="'ep_challenge-detail_' + ep_challenge_header.ep_challenge_id"
                      class="popover-trigger text-ellipsis"
                      tabindex="0"
                      data-content=""
                      data-original-title=""
                      title=""
                      href="#"
                      @click.prevent.stop="onEpChallengeTitle(ep_challenge_header.provisional_ep_challenge_id)">
                      {{ep_challenge_header.ep_challenge_title | omittedText(25)}}
                  </a>
                  <b-popover
                      :target="'ep_challenge-detail_' + ep_challenge_header.ep_challenge_id"
                      triggers="hover"
                      placement="auto"
                    >
                    <div
                      style="margin: -1rem -1.5em; padding: 1rem 1.5em; position: relative; z-index: 1;"
                      @click="$root.$emit('bv::hide::popover')"
                      >
                      <span :inner-html.prop="ep_challenge_header.ep_challenge_title | htmlEscape | nl2br" ></span>
                    </div>
                  </b-popover>
                </th>
                <!-- アセット(省略あり) -->
                <th style="width: 15%">
                  <span :id="'asset_' + ep_challenge_header.ep_challenge_id">
                    {{ ep_challenge_header.asset_name | omittedText(25) }}
                  </span>
                  <b-popover
                      :target="'asset_' + ep_challenge_header.ep_challenge_id"
                      triggers="hover"
                      placement="auto"
                    >
                    <div
                      style="margin: -1rem -1.5em; padding: 1rem 1.5em; position: relative; z-index: 1;"
                      @click="$root.$emit('bv::hide::popover')"
                      >
                      <span :inner-html.prop="ep_challenge_header.asset_name | htmlEscape | nl2br" ></span>
                    </div>
                  </b-popover>
                </th>
                <!-- 作成日 -->
                <th style="width: 10%">
                  <span class="text-nowrap">{{ ep_challenge_header.ep_challenge_created_at | format.date }}</span>
                  {{ ep_challenge_header.ep_challenge_created_at | format.time }}
                </th>
                <!-- リコメンド日 -->
                <th style="width: 13%">{{ ep_challenge_header.recommend_send_at | format.date }}</th>
                <!-- 公開日 -->
                <th style="width: 12%">{{ ep_challenge_header.release_at | format.date }}</th>
                <!-- リコメンド件数 -->
                <th style="width: 8%">{{ep_challenge_header.recommend_count}}件</th>
                <!-- ボタンエリア -->
                <th style="width: 15%">
                  <!-- アコーディオンを開いている時で、別のチャレンジのリコメンド選択中でなければ表示 -->
                  <template v-if="ep_challenge_header.accordion_opened && (check_recommend_challenge_index == null || check_recommend_challenge_index == index)">
                    <button class="button-square btn-sm btn-send icon-addon-left m-1" type="button" @click="onClickAddModal(index)" v-if="!!ep_challenge_header.ep_challenge_id">
                      <i class="fas fa-plus"></i>新規追加
                    </button>
                    <button class="button-square btn-sm btn-send icon-addon-left m-1" type="button" @click="onChangeAdminHideState(index)">
                      <i class="fas" :class="ep_challenge_header.admin_hide_data_show_flg ? 'fa-eye-slash' : 'fa-eye'"></i>
                      {{ ep_challenge_header.admin_hide_data_show_flg ? "非表示" : "再表示" }}
                    </button>
                  </template>
                </th>
              </tr>
            </thead>

            <!-- チャレンジ毎のアコーディオン生成：データ部分 -->
            <tbody class="accordion-body" :class="{
                  hide: !ep_challenge_header.accordion_opened,
                  disabled: (check_recommend_challenge_index != null && check_recommend_challenge_index != index)
            }">
              <tr class="table-warning">
                <td class="p-0" colspan="2"><img class="m-auto" src="~images/icon_right.svg"></td>
                <td>興味あり企業</td>
                <td>興味あり押下日</td>
                <td colspan="2">最新情報</td>
                <td>リコメンド日</td>
                <td colspan="2">ステータス</td>
              </tr>

              <!-- リコメンド行 -->
              <template v-for="(recommend, recommend_index) in ep_challenge_header.recommends">
                <tr :class="{'table-info': (check_recommend_challenge_index == index && check_recommend_su_latest_info_ids.includes(recommend.su_latest_info_id))}"
                    :key="recommend.su_latest_info_id"
                    v-if="showRecord(ep_challenge_header, ep_challenge_header.recommends[recommend_index])">

                  <!-- リコメンド表示・非表示ボタン -->
                  <td class="text-center" colspan="2">
                    <button class="btn btn-sm btn-icon mx-1" type="button" @click="onAdminHideConfirm(index, recommend_index)" v-if="recommend.ep_recommend_id">
                      <i class="fas" :class="recommend.admin_hide_flg ? 'fa-eye' : 'fa-eye-slash'"></i>
                    </button>
                  </td>
                  <!-- 興味あり企業名(省略表示) -->
                  <td>
                    <template v-if="recommend.manual_addition_flg == enum_manual_addition_flgs.su_interested">
                      <!-- 興味あり企業ならただ単にテキスト表示 -->
                      <span :id="'row_su_company_' + recommend.su_latest_info_id">
                        {{ recommend.su_company_name | omittedText(25) }}
                      </span>
                      <b-popover
                        :target="'row_su_company_' + recommend.su_latest_info_id"
                        triggers="hover"
                        placement="auto"
                      >
                        <div
                          style="margin: -1rem -1.5em; padding: 1rem 1.5em; position: relative; z-index: 1;"
                          @click="$root.$emit('bv::hide::popover')"
                        >
                          <span :inner-html.prop="recommend.su_company_name | htmlEscape | nl2br" ></span>
                        </div>
                      </b-popover>
                    </template>
                    <ul class="d-flex justify-content-between align-items-center" v-else>
                      <!-- マニュアル企業ならラベルも表示 -->
                      <li class="mr-1">
                        <span class="badge badge-sm badge-info">マニュアル追加</span>
                        <p :id="'row_su_company_' + recommend.su_latest_info_id">
                          {{ recommend.su_company_name | omittedText(25) }}
                        </p>
                        <b-popover
                          :target="'row_su_company_' + recommend.su_latest_info_id"
                          triggers="hover"
                          placement="auto"
                        >
                          <div
                            style="margin: -1rem -1.5em; padding: 1rem 1.5em; position: relative; z-index: 1;"
                            @click="$root.$emit('bv::hide::popover')"
                          >
                            <span :inner-html.prop="recommend.su_company_name | htmlEscape | nl2br" ></span>
                          </div>
                        </b-popover>
                      </li>
                      <!-- 削除ボタン：リコメンド確定前なら表示 -->
                      <li v-if="recommend.admin_recommend_status != enum_admin_recommend_statuses.pass && recommend.admin_recommend_status != enum_admin_recommend_statuses.recommended">
                        <button class="btn btn-sm btn-icon mx-1" type="button" data-toggle="tooltip" data-original-title="削除" @click="manualRecommendDelete(index, recommend_index)">
                          <i class="fas fa-trash"></i>
                        </button>
                      </li>
                    </ul>
                  </td>
                  <!-- 興味あり押下日 -->
                  <td>{{ recommend.clicked_at | format.datetime }}</td>
                  <!-- 最新情報タイトル(省略あり) -->
                  <td colspan="2">
                    <a
                      :id="'su_latest_info-detail_' + recommend.su_latest_info_id"
                      class="popover-trigger text-ellipsis"
                      tabindex="0"
                      data-content=""
                      data-original-title=""
                      title=""
                      href="#"
                      @click.prevent.stop="onSuLatestInfoTitle(recommend.provisional_su_latest_info_id)">
                      {{ recommend.su_latest_info_title | omittedText(25) }}
                    </a>
                    <b-popover
                      :target="'su_latest_info-detail_' + recommend.su_latest_info_id"
                      triggers="hover"
                      placement="auto"
                    >
                      <div
                        style="margin: -1rem -1.5em; padding: 1rem 1.5em; position: relative; z-index: 1;"
                        @click="$root.$emit('bv::hide::popover')"
                      >
                        <span :inner-html.prop="recommend.su_latest_info_title | htmlEscape | nl2br" ></span>
                      </div>
                    </b-popover>
                  </td>
                  <!-- リコメンド日 -->
                  <td>{{ recommend.recommend_send_at | format.date }}</td>
                  <!-- リコメンド・見送りボタン -->
                  <td colspan="2">
                    <button class="button-square btn-sm btn-send" type="button"
                              v-if="recommend.admin_recommend_status == enum_admin_recommend_statuses.pass" @click="onAdminSeeOff(index, recommend_index, false)">
                      見送り済
                    </button>
                    <button class="button-square btn-sm btn-send disabled" type="button"
                              v-else-if="recommend.admin_recommend_status == enum_admin_recommend_statuses.recommended">
                      リコメンド済
                    </button>
                    <button class="button-square btn-sm btn-send" type="button"
                              @click="onRecommend(index, recommend.su_latest_info_id)"
                              v-else-if="check_recommend_challenge_index == index && check_recommend_su_latest_info_ids.includes(recommend.su_latest_info_id)">
                      リコメンド選択済
                    </button>
                    <div class="btn-group" v-else>
                      <button class="button-square btn-sm btn-send" type="button" @click="onRecommend(index, recommend.su_latest_info_id)">リコメンド</button>
                      <button class="button-square btn-sm btn-send"
                              :class="{disabled: (check_recommend_challenge_index == index && check_recommend_su_latest_info_ids.includes(recommend.su_latest_info_id))}"
                              type="button"
                              @click="onAdminSeeOff(index, recommend_index, true)">
                        見送り
                      </button>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- /リスト-->

    <!-- リコメンド確認ボタン -->
    <div class="fixed-btn-container fixed-btn-container-right" :style="{'display': (check_recommend_su_latest_info_ids.length > 0) ? 'block' : 'none' }">
      <button class="button-square btn-bulksend" type="button" @click="recommendConfirmModalOpen()">リコメンドを<br>送付する</button>
      <span class="badge badge-pill badge-danger">{{ check_recommend_su_latest_info_ids.length }}</span>
    </div>
    <!-- /リコメンド確認ボタン -->

    <!-- リコメンド非表示・再表示確認モーダル -->
    <admin-hide-modal
      ref="admin_hide_confirm_modal"
      :body_text="admin_hide_modal_detail_text"
      :send_button_text="admin_hide_modal_apply_text"
      :onClickSend="onClickSetAdminHideModal"
      :onClickCancel="onClickCancelAdminHideModal"
      :isNoFade="isNoFade"
    />
    <!-- /リコメンド非表示・再表示確認モーダル -->

    <!-- SU最新情報検索モーダル -->
    <search-su-latest-info-modal
      ref="search_su_latest_info_modal"
      :onModalClose="onSearchModalClose"
      :onClickSuccess="onSearchModalSuccess"
      :onClickSuLatestInfoTitle="onSuLatestInfoTitle"
      :industries="condition_industries"
      :isNoFade="isNoFade"
    />
    <!-- /SU最新情報検索モーダル -->

    <!-- リコメンド確認モーダル -->
    <b-modal
      v-model="recommend_confirm_show"
      id="recommend_send"
      aria-label="recommend-confirm_Label"
      title="リコメンド送付"
      centered
      hide-header
      size="lg"
      scrollable
      :no-fade="isNoFade"
    >
      <div class="have-selected-deta">
        <div class="selected-company">
          {{ check_recommend_challenge_index != null ? ep_challenge_headers[check_recommend_challenge_index].ep_company_name : "" }}
        </div>
        <div class="selected-title">
          {{ check_recommend_challenge_index != null ? ep_challenge_headers[check_recommend_challenge_index].ep_challenge_title : "" }}
        </div>
      </div>
      <section class="chapter white-block mt-5">
        <h2 class="chapter-label mb-3">以下の内容をリコメンドします。よろしいですか？</h2>
          <div class="recommend-labels-container border-top pt-4">
            <div class="recommend-labels assets-labels" v-for="su_latest_info_id in check_recommend_su_latest_info_ids" :key="su_latest_info_id">
              <p class="text-wrap">
                <span class="recommend-campany">
                  {{ ep_challenge_headers[check_recommend_challenge_index].recommends[getRecommendIndex(check_recommend_challenge_index, su_latest_info_id)].su_company_name | omittedText(50) }}
                </span>
                <br>
                {{ ep_challenge_headers[check_recommend_challenge_index].recommends[getRecommendIndex(check_recommend_challenge_index, su_latest_info_id)].su_latest_info_title | omittedText(50) }}
              </p>
              <button class="btn-action ml-1" type="button" data-toggle="tooltip" title="" data-original-title="削除" @click="onRecommend(check_recommend_challenge_index, su_latest_info_id)"><i class="fas fa-trash"></i></button>
            </div>
          </div>
        </section>

      <template v-slot:modal-footer>
        <button class="btn btn-cancel" type="button" @click="recommendConfirmModalClose()">閉じる</button>
        <button class="btn btn-send"   type="button" @click="recommendSend()">リコメンドする</button>
      </template>
    </b-modal>
    <!-- /リコメンド確認モーダル -->
  </main>
</template>

<script>
  // 検索エリアのコンポーネント読み込み
  import IndexConditionSection from "./IndexConditionSection";
  // 最新情報検索モーダルのコンポーネント読み込み
  import SearchSuLatestInfoModal from './SearchSuLatestInfoModal.vue';
  // 確認モーダルのコンポーネント読み込み
  import ConfirmModal from '../../../common/pages/ConfirmModal.vue';

  export default {
    metaInfo: {
      title: 'リコメンド一覧',
    },

    components: {
      "index-condition-section": IndexConditionSection,
      "search-su-latest-info-modal": SearchSuLatestInfoModal,
      "admin-hide-modal": ConfirmModal,
    },

    data() {
      return {
        // EPチャレンジヘッダ
        ep_challenge_headers: [],

        // ソート
        recommend_sort: 'down',

        // リコメンドステータス（検索用）
        recommend_statuses: [],

        // リコメンドタイプ（検索用）
        recommend_types: [],

        // EP企業（インクリメンタルサーチ用）
        condition_ep_companies: [],

        // チャレンジ（インクリメンタルサーチ用）
        condition_ep_challenges: [],

        // SU企業（インクリメンタルサーチ用）
        condition_su_companies: [],

        // 業界（インクリメンタルサーチ用）
        condition_industries: [],

        // 条件判定用：マニュアル追加
        enum_manual_addition_flgs: {},

        // 条件判定用：運営非表示
        enum_admin_hide_flgs: {},

        // 条件判定用：運営リコメンド状態
        enum_admin_recommend_statuses: {},

        // アコーディオン内部表示用：SU企業ID
        searched_su_company_id: this.$route.query.su_company_id || '',

        // アコーディオン内部表示用：SU企業名
        searched_su_company_name: this.$route.query.su_company_name || '',

        // アコーディオン内部表示用：初期設定ステータス
        searched_recommend_statuses: this.$route.query.recommend_statuses || ["admin_wait", "pass", "recommended"],

        // アコーディオン内部表示用：初期設定リコメンド送付日From
        searched_recommend_at_from: this.$route.query.recommend_at_from || '',

        // アコーディオン内部表示用：初期設定リコメンド送付日To
        searched_recommend_at_to: this.$route.query.recommend_at_to || '',

        // アコーディオン内部表示用：初期設定リコメンド種類
        searched_recommend_types: this.$route.query.recommend_types || ["interested", "manual"],

        // アコーディオン内部表示用：初期設定興味あり押下日From
        searched_interested_at_from: this.$route.query.interested_at_from || '',

        // アコーディオン内部表示用：初期設定興味あり押下日To
        searched_interested_at_to: this.$route.query.interested_at_to || '',

        // 非表示・再表示対象チャレンジIndex
        click_admin_hide_challenge_index: null,

        // 非表示・再表示対象チャレンジリコメンドIndex
        click_admin_hide_recommend_index: null,

        // 非表示・再表示確認モーダル文章
        admin_hide_modal_detail_text: null,

        // 非表示・再表示確認モーダルボタン
        admin_hide_modal_apply_text: null,

        // リコメンド追加対象チャレンジIndex
        add_recommend_challenge_index: null,

        // リコメンド設定対象チャレンジIndex
        check_recommend_challenge_index: null,

        // リコメンド設定対象チャレンジId
        check_recommend_su_latest_info_ids: [],

        // リコメンド送付確認モーダル開閉
        recommend_confirm_show: false,

        // 読み込み完了フラグ
        loaded: false,
      }
    },

    props: { isNoFade: Boolean },

    filters: {

      // 文字列を省略して表示
      omittedText(text, len) {
        if (!text) return '';
        return text.length > len ? text.slice(0, len) + "…" : text;
      },

    },

    computed: {
      /**
       * 検索結果が存在するか
       */
      hasRecord: function(){
        return Object.keys(this.ep_challenge_headers).length > 0
      },

      /**
       * 該当行を表示するか否か
       */
      showRecord: function(){
        return function(header, record) {
          return header.admin_hide_data_show_flg || record.admin_hide_flg == this.enum_admin_hide_flgs.admin_show;
        }
      },
    },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.getEpChallengeHeaders(this.$route.query);
    },

    // route.push時に実行
    beforeRouteUpdate (to, from, next) {
      this.getEpChallengeHeaders(to.query);
      next();
    },

    // メソッド定義
    methods: {

      // ヘッダ取得処理
      getEpChallengeHeaders(query) {
        this.searched_su_company_id        = query.su_company_id || '';
        this.searched_su_company_name      = query.su_company_name || '';
        this.searched_recommend_statuses   = query.recommend_statuses || ["admin_wait", "pass", "recommended"];
        if(!Array.isArray(this.searched_recommend_statuses)){
          this.searched_recommend_statuses = [this.searched_recommend_statuses];
        }
        this.searched_recommend_at_from    = query.recommend_at_from || '';
        this.searched_recommend_at_to      = query.recommend_at_to || '';
        this.searched_recommend_types      = query.recommend_types || ["interested", "manual"];
        if(!Array.isArray(this.searched_recommend_types)){
          this.searched_recommend_types = [this.searched_recommend_types];
        }
        this.searched_interested_at_from   = query.interested_at_from || '';
        this.searched_interested_at_to     = query.interested_at_to || '';
        this.$loading.load(
          this.$auth.api.post(
            'admin/recommends', query
          ).then(res => {
            this.ep_challenge_headers          = res.data.ep_challenge_headers;
            this.recommend_statuses            = res.data.recommend_statuses;
            this.recommend_types               = res.data.recommend_types;
            this.condition_ep_companies        = res.data.condition_ep_companies;
            this.condition_ep_challenges       = res.data.condition_ep_challenges;
            this.condition_su_companies        = res.data.condition_su_companies;
            this.condition_industries          = res.data.industries;
            this.enum_manual_addition_flgs     = res.data.enum_manual_addition_flgs;
            this.enum_admin_hide_flgs          = res.data.enum_admin_hide_flgs;
            this.enum_admin_recommend_statuses = res.data.enum_admin_recommend_statuses;
            this.loaded = true;
          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        )
      },

      /**
       * 検索ボタンが押下された時の処理.
       * (子Componentから$emitされる)
       */
      onSearchFromChild: function(form = {}){
        // 画面更新
        this.reload(form);
      },

      // ソート
      recommendSort () {
        if(this.recommend_sort === 'down') {
          this.recommend_sort = 'up'
          this.ep_challenge_headers.sort(function(first, second) {
          let f_at = new Date(first.recommend_send_at);
          let s_at = new Date(second.recommend_send_at);
          if(f_at > s_at) {
            return 1;
          }else{
            return -1;
          }})
        }else{
          this.recommend_sort = 'down'
          this.ep_challenge_headers.sort(function(first, second) {
          let f_at = new Date(first.recommend_send_at);
          let s_at = new Date(second.recommend_send_at);
          if(f_at < s_at) {
            return 1;
          }else{
            return -1;
          }})
        }
      },

      /**
       * アコーディオン開閉処理.
       */
      onAccordionState(ep_company_id, ep_challenge_id, index) {
        // 現在のアコーディオン状態にて処理分岐
        if(this.ep_challenge_headers[index].accordion_opened){
          // 開いている状態ならば、閉じる
          this.ep_challenge_headers[index].accordion_opened = !this.ep_challenge_headers[index].accordion_opened;
        } else {
          // リコメンド情報取得処理
          this.loaded = false;
          this.$loading.load(
            this.$auth.api.post(
              'admin/recommends/get_recommend', {
                ep_company_id: ep_company_id,
                ep_challenge_id: ep_challenge_id,
                searched_su_company_id: this.searched_su_company_id,
                searched_su_company_name: this.searched_su_company_name,
                searched_recommend_statuses: this.searched_recommend_statuses,
                searched_recommend_at_from: this.searched_recommend_at_from,
                searched_recommend_at_to: this.searched_recommend_at_to,
                searched_recommend_types: this.searched_recommend_types,
                searched_interested_at_from: this.searched_interested_at_from,
                searched_interested_at_to: this.searched_interested_at_to,
              }
            ).then(res => {
              // 返却結果を表示するように格納
              this.ep_challenge_headers[index].recommends = res.data.ep_recommends;
              // アコーディオンを開く
              this.ep_challenge_headers[index].accordion_opened = !this.ep_challenge_headers[index].accordion_opened;
              this.loaded = true;
            }).catch(err => {
              // トースト表示
              this.$bvToast.toast("指定されたチャレンジ情報の取得に失敗しました。", {
                variant: 'danger',
                title: 'エラー'
              });
            })
          )
        }
      },

      /**
       * 運営非表示データ表示非表示切り替え.
       */
      onChangeAdminHideState: function(index) {
        this.ep_challenge_headers[index].admin_hide_data_show_flg = !this.ep_challenge_headers[index].admin_hide_data_show_flg;
      },

      /**
       * 新規追加（最新情報追加）モーダル表示.
       */
      onClickAddModal: function(index){
        // 対象のIndexを保持
        this.add_recommend_challenge_index = index;

        // モーダルをオープン
        this.$refs.search_su_latest_info_modal.open(
          this.ep_challenge_headers[index].ep_challenge_id,
          this.ep_challenge_headers[index].ep_company_name,
          this.ep_challenge_headers[index].ep_challenge_title
        );
      },

      /**
       * 非表示・表示確認モーダル表示.
       */
      onAdminHideConfirm: function(index, recommend_index) {
        // クリックされたリコメンドの位置を保持
        this.click_admin_hide_challenge_index = index;
        this.click_admin_hide_recommend_index = recommend_index;

        // モーダル表示のテキストの調整
        if(this.ep_challenge_headers[index].recommends[recommend_index].admin_hide_flg == this.enum_admin_hide_flgs.admin_show){
          this.admin_hide_modal_detail_text = "指定されたリコメンドを非表示にします。<br>よろしいですか？";
          this.admin_hide_modal_apply_text  = "非表示にする";
        }else{
          this.admin_hide_modal_detail_text = "指定されたリコメンドの非表示非表示設定を解除します。<br>よろしいですか？";
          this.admin_hide_modal_apply_text  = "解除する";
        }

        // モーダルをオープン
        this.$refs.admin_hide_confirm_modal.open();
      },

      /**
       * 非表示・表示確認モーダル: 処理実行
       */
      onClickSetAdminHideModal: function(){
        // モーダルを閉じる
        this.$refs.admin_hide_confirm_modal.close();
        this.loaded = false;
        this.$loading.load(
          this.$auth.api.post(
            'admin/recommends/change_hide_status', {
              ep_recommend_id:     this.ep_challenge_headers[this.click_admin_hide_challenge_index].recommends[this.click_admin_hide_recommend_index].ep_recommend_id,
              ep_company_id:       this.ep_challenge_headers[this.click_admin_hide_challenge_index].ep_company_id,
              ep_challenge_id:     this.ep_challenge_headers[this.click_admin_hide_challenge_index].ep_challenge_id,
              su_latest_info_id:   this.ep_challenge_headers[this.click_admin_hide_challenge_index].recommends[this.click_admin_hide_recommend_index].su_latest_info_id,
              type:                (this.ep_challenge_headers[this.click_admin_hide_challenge_index].recommends[this.click_admin_hide_recommend_index].admin_hide_flg == this.enum_admin_hide_flgs.admin_show),
              updated_at:          this.ep_challenge_headers[this.click_admin_hide_challenge_index].recommends[this.click_admin_hide_recommend_index].ep_recommend_updated_at,
            }
          ).then(res => {
            // 返却結果を表示するように格納
            this.ep_challenge_headers[this.click_admin_hide_challenge_index].recommends[this.click_admin_hide_recommend_index].admin_hide_flg          = res.data.admin_hide_flg;
            this.ep_challenge_headers[this.click_admin_hide_challenge_index].recommends[this.click_admin_hide_recommend_index].ep_recommend_updated_at = res.data.ep_recommend_updated_at;

            // 保持していたデータをクリア
            this.click_admin_hide_challenge_index = null;
            this.click_admin_hide_recommend_index = null;
            this.loaded = true;

            // トースト表示
            this.$bvToast.toast(res.data.admin_hide_flg ? "非表示にしました。" : "再表示にしました。", {
              variant: 'success',
              title: '完了'
            });
          }).catch(err => {
            if(err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }else{
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },

      /**
       * 非表示・表示確認モーダル: キャンセル
       */
      onClickCancelAdminHideModal: function(){
        // 保持していたデータをクリア
        this.click_admin_hide_challenge_index = null;
        this.click_admin_hide_recommend_index = null;
        // モーダルを閉じる
        this.$refs.admin_hide_confirm_modal.close();
      },

      /**
       * マニュアル追加データ削除処理
       */
      manualRecommendDelete: function(index, recommend_index) {
        // リコメンド選択状態ならば、選択状態を解除
        if(this.check_recommend_challenge_index == index && this.check_recommend_su_latest_info_ids.includes(this.ep_challenge_headers[index].recommends[recommend_index].su_latest_info_id)){
          this.onRecommend(index, this.ep_challenge_headers[index].recommends[recommend_index].su_latest_info_id);
        }

        // リストから削除
        this.ep_challenge_headers[index].recommends.splice(recommend_index, 1);
        this.check_recommend_su_latest_info_ids.splice(); // オブジェクトの変更を検知させる
      },

      /**
       * リコメンド見送り・見送り取り消し処理
       */
      onAdminSeeOff: function(index, recommend_index, see_off_type) {
        this.loaded = false;
        this.$loading.load(
          this.$auth.api.post(
            'admin/recommends/admin_see_off', {
              ep_recommend_id:     this.ep_challenge_headers[index].recommends[recommend_index].ep_recommend_id,
              ep_company_id:       this.ep_challenge_headers[index].ep_company_id,
              ep_challenge_id:     this.ep_challenge_headers[index].ep_challenge_id,
              su_latest_info_id:   this.ep_challenge_headers[index].recommends[recommend_index].su_latest_info_id,
              type:                see_off_type,
              manual_addition_flg: this.ep_challenge_headers[index].recommends[recommend_index].manual_addition_flg,
              admin_hide_flg:      this.ep_challenge_headers[index].recommends[recommend_index].admin_hide_flg,
              updated_at:          this.ep_challenge_headers[index].recommends[recommend_index].ep_recommend_updated_at,
            }
          ).then(res => {
            // 返却結果を表示するように格納
            this.ep_challenge_headers[index].recommends[recommend_index].ep_recommend_id         = res.data.ep_recommend_id;
            this.ep_challenge_headers[index].recommends[recommend_index].admin_recommend_status  = res.data.admin_recommend_status;
            this.ep_challenge_headers[index].recommends[recommend_index].ep_recommend_updated_at = res.data.ep_recommend_updated_at;
            this.ep_challenge_headers[index].recommends[recommend_index].admin_hide_flg          = res.data.admin_hide_flg;
            this.ep_challenge_headers[index].recommends[recommend_index].manual_addition_flg     = res.data.manual_addition_flg;
            this.loaded = true;
          }).catch(err => {
            if(err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }else{
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },

      /**
       * リコメンド選択・解除処理
       */
      onRecommend: function(index, su_latest_info_id) {
        // 対象のリコメンド情報を配列に保持
        if(this.check_recommend_challenge_index == null){
          this.check_recommend_challenge_index = index;
        }

        // リコメンドの追加 OR 除外
        if(this.check_recommend_su_latest_info_ids.includes(su_latest_info_id)){
          // 既に存在する　＝　除外
          this.check_recommend_su_latest_info_ids = this.check_recommend_su_latest_info_ids.filter(item => item != su_latest_info_id);
        }else{
          // 存在しない　＝　追加
          this.check_recommend_su_latest_info_ids.push(su_latest_info_id);
        }

        // 対象のリコメンドが存在しなくなったらリコメンド選択状態を解除
        if(this.check_recommend_su_latest_info_ids.length == 0){
          this.check_recommend_challenge_index = null;
          this.recommend_confirm_show = false;
        }
      },

      /**
       * リコメンド対象を送信し、処理を確定
       */
      recommendSend: function(){
        // マニュアル追加フラグ対象のIDをピックアップ
        let manual_ids = [];
        let id_with_updated_ats = [];
        this.check_recommend_su_latest_info_ids.forEach(x =>{
          // マニュアル追加フラグがマニュアル追加ならばマニュアルIDとして追加
          if(this.ep_challenge_headers[this.check_recommend_challenge_index].recommends[this.getRecommendIndex(this.check_recommend_challenge_index, x)].manual_addition_flg == this.enum_manual_addition_flgs.manual_add){
            manual_ids.push(x);
          }

          // IDとSU最新情報の更新時刻を_にて連結した文字列を作成
          id_with_updated_ats.push(x + '_' + this.ep_challenge_headers[this.check_recommend_challenge_index].recommends[this.getRecommendIndex(this.check_recommend_challenge_index, x)].su_latest_info_updated_at);
        });

        this.loaded = false;
        this.$loading.load(
          this.$auth.api.post(
            'admin/recommends/admin_recommendation', {
              ep_challenge_id: this.ep_challenge_headers[this.check_recommend_challenge_index].ep_challenge_id,
              ep_company_id: this.ep_challenge_headers[this.check_recommend_challenge_index].ep_company_id,
              ep_challenge_updated_at: this.ep_challenge_headers[this.check_recommend_challenge_index].updated_at,
              su_latest_info_ids: id_with_updated_ats,
              manual_ids: manual_ids,
            }
          ).then(res => {
            // 返却結果を反映
            res.data.ep_recommends.forEach(obj =>{
              // 対象のデータに情報をセット
              let info_index = this.getRecommendIndex(this.check_recommend_challenge_index, obj.su_latest_info_id);
              this.ep_challenge_headers[this.check_recommend_challenge_index].recommends[info_index].ep_recommend_id         = obj.ep_recommend_id;
              this.ep_challenge_headers[this.check_recommend_challenge_index].recommends[info_index].admin_recommend_status  = obj.admin_recommend_status;
              this.ep_challenge_headers[this.check_recommend_challenge_index].recommends[info_index].ep_recommend_updated_at = obj.ep_recommend_updated_at;
            });

            // リコメンドの選択状態を解除する
            this.check_recommend_challenge_index = null;
            this.check_recommend_su_latest_info_ids = [];

            // モーダルを閉じる
            this.recommendConfirmModalClose();
            this.loaded = true;

            // トースト表示
            this.$bvToast.toast("リコメンドを設定しました", {
              variant: 'success',
              title: '完了'
            });
          }).catch(err => {
            if(err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }else{
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },

      /**
       * Epチャレンジタイトル押下時
       */
      onEpChallengeTitle: function(id){
        // 新しいタブでEpチャレンジの詳細画面に遷移
        let routeData = this.$router.resolve({name: 'AdminProvisionalEpChallengeShow', params: { id: id } });
        window.open(routeData.href, '_new');
      },

      /**
       * Su最新情報タイトル押下時
       */
      onSuLatestInfoTitle: function(id){
        // 新しいタブでSu最新情報の詳細画面に遷移
        let routeData = this.$router.resolve({name: 'AdminProvisionalSuLatestInfoShow', params: { id: id } });
        window.open(routeData.href, '_new');
      },

      /**
       * 最新情報検索モーダル：Close時
       */
      onSearchModalClose: function(){
        this.add_recommend_challenge_index = null;
      },

      /**
       * 最新情報検索モーダル：リコメンド追加時
       */
      onSearchModalSuccess: function(add_recommends){
        // 送られて来た最新情報をリストに追加
        add_recommends.forEach(obj => {
          // そもそも配列に存在しないかチェック
          let recommend_index = this.ep_challenge_headers[this.add_recommend_challenge_index].recommends.findIndex(function(elem){ return elem.su_latest_info_id == obj.su_latest_info_id });
          if(recommend_index != -1){
            // そもそもリストに存在する
            if(this.ep_challenge_headers[this.add_recommend_challenge_index].recommends[recommend_index].admin_recommend_status === null &&
                this.getCheckedRecommendIndex(obj.su_latest_info_id) == -1){
              // 未選択状態ならば、選択状態とする
              this.onRecommend(this.add_recommend_challenge_index, obj.su_latest_info_id);
            }
          }else{
            // 対象に追加する
            this.ep_challenge_headers[this.add_recommend_challenge_index].recommends.unshift(obj);
            this.onRecommend(this.add_recommend_challenge_index, obj.su_latest_info_id);
          }
        });
      },

      /**
       * リコメンド送付モーダルの表示
       */
      recommendConfirmModalOpen: function(){
        this.recommend_confirm_show = true;
      },

      /**
       * リコメンド送付モーダルを閉じる
       */
      recommendConfirmModalClose: function(){
        this.recommend_confirm_show = false;
      },

      /**
       * SU最新情報IDの配列の位置特定
       */
      getRecommendIndex: function(header_index, su_latest_info_id){
        return this.ep_challenge_headers[header_index].recommends.findIndex(function(elem){ return elem.su_latest_info_id == su_latest_info_id });
      },

      /**
       * チェックされたSU最新情報IDの配列の位置特定
       */
      getCheckedRecommendIndex: function(su_latest_info_id){
        return this.check_recommend_su_latest_info_ids.indexOf(su_latest_info_id);
      },

      /**
       * 画面再読み込み.
       * @param query
       */
      reload: function(query = []) {
        this.$router.push({name: 'AdminRecommendIndex', query: query}).catch(err => {});
      },
    },
  }

</script>
