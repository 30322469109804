<template>
  <div>
    <b-modal
      v-model="is_show"
      title=""
      centered
      size="sm"
      hide-header
      :no-fade="isNoFade"
    >
      <p class="mt-3 text-center">
        <span v-html="body_text"></span>
      </p>
      <template v-slot:modal-footer>
        <button class="btn btn-cancel" type="button" @click="onCancel" >{{ cancel_button_text }}</button>
        <button class="btn btn-send"   type="button" @click="onSend" >{{ send_button_text }}</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  export default {
    props: {
      // モーダルが開いた時のコールバックメソッド
      onModalOpen: {
        type: Function,
      },
      // モーダルが閉じた時のコールバックメソッド
      onModalClose: {
        type: Function,
      },
      // OKボタン押下時のコールバックメソッド
      onClickSend: {
        type: Function,
      },
      // キャンセルボタン押下時のコールバックメソッド
      onClickCancel: {
        type: Function,
      },
       // OKボタンの文字列
      send_button_text: {
        type: String,
        default: 'OK'
      },
      // キャンセルボタンの文字列
      cancel_button_text: {
        type: String,
        default: 'キャンセル'
      },
      // 内容の文字列
      body_text: {
        type: String,
        default: 'よろしいですか？'
      },
      isNoFade: {
        type: Boolean,
      },
    },
    data() {
      return {
        is_show: false,
      }
    },
    watch: {
      is_show(new_is_show, old_is_show) {
        if(new_is_show) {
          if(typeof this.onModalOpen == 'function') {
            this.onModalOpen();
          }
        } else {
          if(typeof this.onModalClose == 'function') {
            this.onModalClose();
          }
        }
      }
    },
    methods: {
      // モーダルオープン
      open() {
        this.is_show = true;
      },
      // モーダルクローズ
      close() {
        this.is_show = false;
      },
      // OKボタン押下時
      onSend() {
        if(typeof this.onClickSend == 'function') {
          // OKボタン押下時のコールバックメソッドが存在すれば、呼ぶ
          // ※ モーダルは閉じられないので、コールバックメソッド内で閉じる必要がある
          this.onClickSend();
        } else {
          // OKボタン押下時のコールバックメソッドが存在しなければ、モーダルを閉じる
          this.close();
        }
      },
      // キャンセルボタン押下時
      onCancel() {
        if(typeof this.onClickCancel == 'function') {
          // キャンセルボタン押下時のコールバックメソッドが存在すれば、呼ぶ
          // ※ モーダルは閉じられないので、コールバックメソッド内で閉じる必要がある
          this.onClickCancel();
        } else {
          // キャンセルボタン押下時のコールバックメソッドが存在しなければ、モーダルを閉じる
          this.close();
        }
      }
    }
  }
</script>
