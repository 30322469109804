<template>
  <div>
    <section class="chapter white-block step-data">
      <div class="step-table">
        <div class="btn-container">
          <div>
            <router-link
              :to="{ name: 'EpScenariosShowStep2' }"
              class="btn btn-white btn-sm rounded-pill"
            >
              戻る
            </router-link>
          </div>
          <EditButtons
            :inEdit="inEdit"
            @editClick="$emit('update:inEdit', true)"
            @onSave="$emit('updateJsonScenario')"
          />
        </div>
        <div
          v-if="errors.length"
          class="mx-4 mb-4"
        >
          <p
            v-for="(error, i) in errors"
            :key="`scenario_errors_${i}`"
            class="invalid-feedback d-block"
          >
            {{ error }}
          </p>
        </div>
        <div class="mr-n3 -my-2 overflow-x-auto">
          <div class="pr-3 inline-block min-w-full py-2 align-middle">
            <table class="min-w-full divide-y divide-gray-300">
              <YearsThead
                :columns="yearColumns(scenario.years_custom, 'current')"
                :displayCurrencyUnit="displayCurrencyUnit(scenario)"
              />
              <tbody class="divide-y divide-gray-200 bg-white">
                <TbodyRow
                  :inEdit="inEdit"
                  :columns="yearColumns(scenario.years_custom, 'current')"
                  :fieldValues="scenario.accounts_receivable"
                  fieldName="売上債権"
                  tooltipContent="事業計画上予定されている売掛金等の金額を入力してください。売掛金が増加する分、FCFが減少します。"
                  @fieldValuesReplace="setField(scenario, 'accounts_receivable', $event)"
                />
                <TbodyRow
                  :inEdit="inEdit"
                  :columns="yearColumns(scenario.years_custom, 'current')"
                  :fieldValues="scenario.inventory"
                  fieldName="棚卸資産"
                  tooltipContent="事業計画上予定されている商品等の金額を入力してください。棚卸資産が増加する分、FCFが減少します。"
                  @fieldValuesReplace="setField(scenario, 'inventory', $event)"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="step-graph border-bottom">
        <BarChart
          :options="workingCapitalsBarOptions(scenario)"
          :chartData="workingCapitalsBarChartData(scenario, 'assets')"
        />
      </div>
      <div class="step-table">
        <div class="mr-n3 -my-2 overflow-x-auto">
          <div class="pr-3 inline-block min-w-full py-2 align-middle">
            <table class="min-w-full divide-y divide-gray-300">
              <YearsThead
                :columns="yearColumns(scenario.years_custom, 'current')"
                :displayCurrencyUnit="displayCurrencyUnit(scenario)"
              />
              <tbody>
                <TbodyRow
                  :inEdit="inEdit"
                  :columns="yearColumns(scenario.years_custom, 'current')"
                  :fieldValues="scenario.accounts_payable"
                  fieldName="仕入債務"
                  tooltipContent="事業計画上予定されている買掛金等の金額を入力してください。買掛金が増加する分、FCFも増加します。"
                  @fieldValuesReplace="setField(scenario, 'accounts_payable', $event)"
                />
                <TbodyRow
                  :inEdit="inEdit"
                  :columns="yearColumns(scenario.years_custom, 'current')"
                  :fieldValues="scenario.advance_payment"
                  fieldName="前受金"
                  @fieldValuesReplace="setField(scenario, 'advance_payment', $event)"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="step-graph">
        <BarChart
          :options="workingCapitalsBarOptions(scenario)"
          :chartData="workingCapitalsBarChartData(scenario, 'liabilities')"
        />
      </div>
    </section>
  </div>
</template>

<script>
  import YearsThead from './parts/YearsThead';
  import TbodyRow from './parts/TbodyRow';
  import EditButtons from './parts/EditButtons';
  import BarChart from './parts/BarChart';
  import ScenariosShowMixins from './scenarios_show_mixins';

  export default {
    mixins: [ScenariosShowMixins, ],
    components: {
      YearsThead,
      TbodyRow,
      EditButtons,
      BarChart,
    },
    props: {
      scenario: {
        type: Object,
        required: true,
      },
      errors: {
        type: Array,
        required: true,
      },
      inEdit: {
        type: Boolean,
        required: true,
      },
    },
  };
</script>
<style src="./tailwind.css" scoped />
