<template>
  <div>
    <b-modal
      v-model="is_show"
      title=""
      centered
      size="sm"
      hide-header
      :no-fade="isNoFade"
    >
      <p class="mt-3 text-center">
        <span v-html="body_text"></span>
      </p>
      <template v-slot:modal-footer>
        <button class="btn btn-cancel" type="button" @click="onCancel()" >{{ cancel_button_text }}</button>
        <button class="btn btn-send"   type="button" @click="onSend()" >{{ send_button_text }}</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data () {
    return {
      is_show: false,
      from_page: {}
    }
  },
  props:{
    // モーダルが開いた時のコールバックメソッド
    onModalOpen: {
      type: Function
    },
    onModalClose: {
      type: Function
    },
    onClickSend: {
      type: Function
    },
    onClickCansel: {
      type: Function
    },
    // OKボタンの文字列
    send_button_text: {
      type: String,
      default: '一覧にもどる'
    },
    // キャンセルボタンの文字列
    cancel_button_text: {
      type: String,
      default: 'キャンセル'
    },
    // 内容の文字列
    body_text: {
      type: String,
      default: '一覧画面に戻ります。<br>よろしいですか？'
    },
    // 一覧画面の名称
    index_page_name: {
      type: String,
    },
    isNoFade: {
      type: Boolean,
    },
  },
  watch: {
    is_show(new_is_show, old_is_show) {
      if(new_is_show) {
        if(typeof this.onModalOpen == 'function') {
          this.onModalOpen();
        }
      } else {
        if(typeof this.onModalClose == 'function') {
          this.onModalClose();
        }
      }
    }
  },

  created() {
    this.fetchFromPage();
  },

  methods:{
    // 前画面情報を取得
    fetchFromPage () {
      this.from_page = this.$store.getters['back_to_index_modal/fromPage'];
    },
    // モーダルオープン
    open() {
      this.is_show = true;
    },
    // モーダルクローズ
    close() {
      this.is_show = false;
    },
    // 一覧へ戻るボタン押下時
    onSend () {
      if(typeof this.onClickSend == 'function') {
        // OKボタン押下時のコールバックメソッドが存在すれば、呼ぶ
        // ※ モーダルは閉じられないので、コールバックメソッド内で閉じる必要がある
        this.onClickSend();
      }else if(this.index_page_name === 'HistoryBack'){
        // history back
        this.$router.go(-1);
      } else {
        // 前画面情報があり、一覧画面名が正しければクエリを保持した一覧画面に遷移
        if(!!this.from_page && this.from_page.name === this.index_page_name){
          this.$router.push(this.from_page.full_path);
        }else{
          // 前画面情報がなければクエリなしで遷移
          this.$router.push({name: this.index_page_name});
        };
        // OKボタン押下時のコールバックメソッドが存在しなければ、モーダルを閉じる
        this.close();
      };
    },

    onCancel () {
      if(typeof this.onClickCancel == 'function') {
        // キャンセルボタン押下時のコールバックメソッドが存在すれば、呼ぶ
        // ※ モーダルは閉じられないので、コールバックメソッド内で閉じる必要がある
        this.onClickCancel();
      } else {
        // キャンセルボタン押下時のコールバックメソッドが存在しなければ、モーダルを閉じる
        this.close();
      }
    }
  }
}
</script>
