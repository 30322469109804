<template>
  <div>
    <b-modal
      v-model="is_show"
      id="industry-setting"
      aria-labelledby="industry-setting_Label"
      title=""
      centered
      scrollable
      size="lg"
      :no-fade="isNoFade"
    >
      <template v-slot:modal-header>
        <h3 class="modal-title mx-auto">{{ header_name }}</h3>
      </template>
      <form>
        <!-- 業界-->
        <section class="chapter white-block">
          <h2 class="chapter-label mb-3 d-flex">業界
            <p class="sub-label ml-4">関連付ける業界を全てクリックして下さい。</p>
          </h2>
          <div class="industry-setting-list">
            <div class="btn-group-toggle" data-toggle="buttons">
              <label
                v-for="industry in industries"
                class="btn button-square btn-check btn-checkbox btn-sm"
                :class="selected_industry_ids.indexOf(industry.id) >= 0 ? 'active' : ''"
              >
                {{ industry.name }}<i class="fas fa-check-circle"></i>
                <input
                  v-model="selected_industry_ids"
                  type="checkbox"
                  class="form-check-input"
                  :id="'industry_' + industry.id"
                  :value="industry.id"
                >
              </label>
            </div>
          </div>
        </section>
        <!-- / 業界-->
      </form>
      <template v-slot:modal-footer>
        <button class="btn btn-cancel" type="button" @click="onCancel" >{{ cancel_button_text }}</button>
        <button
          class="btn btn-send"
          type="button"
          @click="onSend"
        >
          {{ send_button_text }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  export default {
    props: {
      // モーダルが開いた時のコールバックメソッド
      onModalOpen: {
        type: Function,
      },
      // モーダルが閉じた時のコールバックメソッド
      onModalClose: {
        type: Function,
      },
      // OKボタン押下時のコールバックメソッド
      onClickSend: {
        type: Function,
      },
      // キャンセルボタン押下時のコールバックメソッド
      onClickCancel: {
        type: Function,
      },
       // OKボタンの文字列
      send_button_text: {
        type: String,
        default: '適用する'
      },
      // キャンセルボタンの文字列
      cancel_button_text: {
        type: String,
        default: 'キャンセルする'
      },
      // ヘッダー表示名
      header_name: {
        type: String,
        default: ''
      },
      // 業界マスタの配列
      industries: {
        type: Array,
        required: true,
        default: () => ({})
      },
      // 選択する業界IDの配列
      select_industry_ids: {
        type: Array,
        required: true,
        default: () => ({})
      },
      isNoFade: {
        type: Boolean,
      },
    },
    data() {
      return {
        is_show: false,
        selected_industry_ids: []
      }
    },
    watch: {
      is_show(new_is_show, old_is_show) {
        if(new_is_show) {
          if(typeof this.onModalOpen == 'function') {
            this.onModalOpen();
          }
        } else {
          if(typeof this.onModalClose == 'function') {
            this.onModalClose();
          }
        }
      }
    },
    methods: {
      // 初期化
      init() {
        this.selected_industry_ids = JSON.parse(JSON.stringify(this.select_industry_ids));
      },
      // モーダルオープン
      open() {
        this.init();
        this.is_show = true;
      },
      // モーダルクローズ
      close() {
        this.is_show = false;
      },
      // OKボタン押下時
      onSend() {
        if(typeof this.onClickSend == 'function') {
          // OKボタン押下時のコールバックメソッドが存在すれば、呼ぶ
          // ※ モーダルは閉じられないので、コールバックメソッド内で閉じる必要がある
          this.onClickSend(this.selected_industry_ids);
        } else {
          // OKボタン押下時のコールバックメソッドが存在しなければ、モーダルを閉じる
          this.close();
        }
      },
      // キャンセルボタン押下時
      onCancel() {
        if(typeof this.onClickCancel == 'function') {
          // キャンセルボタン押下時のコールバックメソッドが存在すれば、呼ぶ
          // ※ モーダルは閉じられないので、コールバックメソッド内で閉じる必要がある
          this.onClickCancel();
        } else {
          // キャンセルボタン押下時のコールバックメソッドが存在しなければ、モーダルを閉じる
          this.close();
        }
      }
    }
  }
</script>
