<template>
  <div>
    <b-modal
      v-model="is_show"
      title=""
      centered
      size="sm"
      hide-header
      :no-fade="isNoFade"
    >
      <p class="mt-3 text-center">
        <span>{{ action_text }}が完了しました。</span><span v-html="additional_text"></span>
      </p>
      <template v-slot:modal-footer>
        <button class="btn btn-send" type="button" @click="close" >OK</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  export default {
    props: {
      // モーダルが開いた時のコールバックメソッド
      onModalOpen: {
        type: Function,
      },
      // モーダルが閉じた時のコールバックメソッド
      onModalClose: {
        type: Function,
      },
       // 完了した操作の文字列
      action_text: {
        type: String,
        default: '登録'
      },
      // 追加表示する文字列
      additional_text: {
        type: String,
        default: ''
      },
      isNoFade: {
        type: Boolean,
      },
    },
    data() {
      return {
        is_show: false,
      }
    },
    watch: {
      is_show(new_is_show, old_is_show) {
        if(new_is_show) {
          if(typeof this.onModalOpen == 'function') {
            this.onModalOpen();
          }
        } else {
          if(typeof this.onModalClose == 'function') {
            this.onModalClose();
          }
        }
      }
    },
    methods: {
      // モーダルオープン
      open() {
        this.is_show = true;
      },
      // モーダルクローズ
      close() {
        this.is_show = false;
      },
    }
  }
</script>
