import Index from './common/pages/index'
import NotFound from './common/pages/not_found'
import Error from './common/pages/error'

import AdminRoutes from './admin/routes'
import SPRoutes from './su/routes'
import EPRoutes from './ep/routes'

export default [
  { path: '/', redirect: to => { window.location.href = 'https://www.sixbrain.ai/'; }, meta: { isPublic: true }},
  { path: '/error', component: Error, meta: { isPublic: true }},
].concat(AdminRoutes, SPRoutes, EPRoutes, [
  { path: '*', component: NotFound, meta: { isPublic: true }}
]);
