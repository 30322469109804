<template>
  <header class="mini-header">
    <div class="user-profile">
      <p class="login-name">{{ icon_text }}</p>
      <img v-if="accountType === 'ep'" class="user-icon" src="~images/ep_user_default.png" :alt="icon_text" />
      <img v-else class="user-icon" src="~images/su_user_default.png" :alt="icon_text" />
    </div>
    <p class="page-title">six brainアカウント登録申請</p>
  </header>
</template>

<script>
  export default {
    props: {
      accountType: String
    },
    data() {
      return {
        icon_text: this.accountType === 'ep' ? '大手企業様用' : 'スタートアップ企業様用',
      }
    },
  }
</script>

<style scoped>

</style>