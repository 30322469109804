<template>
  <div>
    <section class="chapter white-block step-data">
      <div class="step-graph border-bottom">
        <BarChart
          :options="presentValuesBarOptions(scenario)"
          :chartData="presentValuesBarChartData(scenario)"
        />
      </div>
      <div class="step-table">
        <div class="btn-container">
          <div />
          <EditButtons
            :inEdit="inEdit"
            @editClick="$emit('update:inEdit', true)"
            @onSave="$emit('updateJsonScenario')"
          />
        </div>
        <div
          v-if="errors.length"
          class="mx-4 mb-4"
        >
          <p
            v-for="(error, i) in errors"
            :key="`scenario_errors_${i}`"
            class="invalid-feedback d-block"
          >
            {{ error }}
          </p>
        </div>
        <div class="mr-n3 -my-2 overflow-x-auto">
          <div class="pr-3 inline-block min-w-full py-2 align-middle">
            <table class="min-w-full divide-y divide-gray-300">
              <YearsThead
                :columns="yearColumns(scenario.years_custom)"
                :displayCurrencyUnit="displayCurrencyUnit(scenario)"
              />
              <tbody class="divide-y divide-gray-200 bg-white">
                <TbodyRow
                  :columns="yearColumns(scenario.years_custom)"
                  :fieldValues="calcOperatingProfitAfterTaxes(scenario)"
                  fieldName="税引後営業利益"
                />
                <TbodyRow
                  :inEdit="inEdit"
                  :columns="yearColumns(scenario.years_custom)"
                  :fieldValues="scenario.capital_investment"
                  fieldName="設備投資"
                  tooltipContent="各年度で支出された設備投資の金額を負の値で入力してください。"
                  @fieldValuesReplace="setField(scenario, 'capital_investment', $event)"
                />
                <TbodyRow
                  :columns="yearColumns(scenario.years_custom)"
                  :fieldValues="workingCapitals.assets"
                  fieldName="運転資本の増減（資産項目）"
                  tooltipContent="クリックすると詳細な値を入力することができます。"
                  :onLink="goWorkingCapital"
                />
                <TbodyRow
                  :columns="yearColumns(scenario.years_custom)"
                  :fieldValues="workingCapitals.liabilities"
                  fieldName="運転資本の増減（負債項目）"
                  tooltipContent="クリックすると詳細な値を入力することができます。"
                  :onLink="goWorkingCapital"
                />
                <TbodyRow
                  :inEdit="inEdit"
                  :columns="yearColumns(scenario.years_custom)"
                  :fieldValues="scenario.depreciation"
                  fieldName="減価償却費"
                  @fieldValuesReplace="setField(scenario, 'depreciation', $event)"
                />
                <TbodyRow
                  :inEdit="inEdit"
                  :columns="yearColumns(scenario.years_custom)"
                  :fieldValues="scenario.other_non_cash_profit_and_loss_items"
                  fieldName="その他非資金損益項目"
                  @fieldValuesReplace="setField(scenario, 'other_non_cash_profit_and_loss_items', $event)"
                />
                <TbodyRow
                  :columns="yearColumns(scenario.years_custom)"
                  :fieldValues="calcFreeCashFlows(scenario)"
                  fieldName="Free Cash Flow"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <div class="btn-container d-flex justify-content-center">
      <router-link
        :to="{ name: 'EpScenariosShowStep1' }"
        class="btn btn-big rounded-pill btn-prev"
      >
        前のステップに戻る
      </router-link>
      <router-link
        :to="{ name: 'EpScenariosShowStep3' }"
        class="btn btn-big rounded-pill btn-next"
      >
        次のステップに進む
      </router-link>
    </div>
  </div>
</template>

<script>
  import YearsThead from './parts/YearsThead';
  import TbodyRow from './parts/TbodyRow';
  import EditButtons from './parts/EditButtons';
  import BarChart from './parts/BarChart';
  import ScenariosShowMixins from './scenarios_show_mixins';

  export default {
    mixins: [ScenariosShowMixins, ],
    components: {
      YearsThead,
      TbodyRow,
      EditButtons,
      BarChart,
    },
    props: {
      scenario: {
        type: Object,
        required: true,
      },
      errors: {
        type: Array,
        required: true,
      },
      inEdit: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      workingCapitals() {
        return this.calcChangeInWorkingCapitals(this.scenario);
      },
    },
    methods: {
      goWorkingCapital() {
        this.$router.push({ name: 'EpScenariosShowWorkingCapital', });
      },
    },
  };
</script>
<style src="./tailwind.css" scoped />
