import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);

import Routes from './components/routes'

const router = new VueRouter({
  mode: 'history',
  routes: Routes,
  relative: true,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

// GoogleAnalytics
import VueGtag from 'vue-gtag'

const host = window.location.host
switch (host) {
  case 'manage.sixbrain.ai':
  case 'dev.sixbrain.ai': 
  case 'stg.sixbrain.ai':
    Vue.use(VueGtag, {
      config: {
        id: 'UA-155022883-1',
      }
    }, router);
    break;
}

const loginCheck = (to, from, next) => {
  const loggedIn = to.matched.some(record => record.meta.isPublic) || router.app.$auth.isLoggedIn
  const check = loggedIn && to.matched.some(record => record.meta.loginCheck && !record.meta.loginCheck(to, from, next, router.app.$auth))
  return loggedIn && !check;
}

router.beforeEach((to, from, next) => {
  if (!loginCheck(to, from, next)) {
    const first = to.path.split('/')[1]
    if(first == 'admin' || first == 'su' || first == 'ep'){
      next({ path: '/' + first + '/login'});
    }else{
      next({ path: '/'});
    }
  } else {
    next();
  }
});


export default router;
