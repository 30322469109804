<template>
  <main>
    <section class="main-title">
      <h1>担当者詳細</h1>
    </section>

    <!-- 担当者詳細-->
    <section class="chapter white-block">
      <div class="overview-wrap pt-3">
        <div class="left-col">
          <img v-if="!!user.image_attributes && user.image_attributes.filename"
            class="thumbnail-frame img180"
            :src="user.presigned_url"
            :alt="user.image_attributes.filename"
          >
          <img v-else
            class="thumbnail-frame img180"
            src="~images/ep_user_default.png"
            alt="画像が選択されていません"
          />
          <p class="login-time mt-3">{{user.last_login}}</p>
        </div>
        <div class="right-col">
          <div class="overview-list my-4">
            <dl class="form-confirm-group">
              <dt>氏名</dt>
              <dd wovn-ignore>{{fullName(user)}}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>氏名（フリガナ）</dt>
              <dd wovn-ignore>{{fullKanaName(user)}}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>メールアドレス<br>（ログインID）</dt>
              <dd wovn-ignore>{{user.email}}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>役職名</dt>
              <dd>{{user.position}}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>部署名</dt>
              <dd>{{user.department}}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>プロフィール</dt>
              <dd>{{user.profile}}</dd>
            </dl>
          </div>
        </div>
      </div>
      <!-- 担当者詳細-->
      <div class="btn-container d-flex justify-content-center">
        <button class="btn btn-medium btn-outline-green rounded-pill" type="button" @click="$router.push({path: './edit'})">編集する</button>
      </div>
    </section>

    <!-- 2段階認証設定(子アカウントのみ設定可、) -->
    <TwoFactorAuth
      editUrl="EpTwoFactorAuthEdit"
      :user_id="user_id"
      :isSetting="current_api_v1_user_account_type == 'ep_person_in_charge'"
      :isInActivate="current_api_v1_user_account_type == 'ep_admin'"
      :otpRequiredForLogin="otp_required_for_login"
      @reloadFunc="getEpPersonInCharge"
    />
    <!-- 2段階認証設定 -->

    <div class="btn-container d-flex justify-content-center pt-2">
      <button class="btn btn-big btn-white rounded-pill" type="button" @click="backToPage()">もどる</button>

      <template>
        <template v-if="current_api_v1_user_account_type == 'ep_admin'">
          <span class="mr-3" id="ep-department-delete">
            <button
              class="btn btn-big btn-delete rounded-pill"
              type="button"
              data-toggle="modal"
              data-target="#delete"
              @click="$refs.delete_confirm_modal.open()"
              >削除する</button>
          </span>
        </template>
      </template>
    </div>

    <ConfirmModal
      ref="delete_confirm_modal"
      :onClickSend="onDeleteConfirmSend"
      :body_text="'この担当者を削除します。<br>よろしいですか？'"
      :send_button_text="'削除する'"
      :isNoFade="isNoFade"
    />

  </main>
</template>

<script>
  // 確認モーダルのコンポーネント読み込み
  import ConfirmModal from '../../../common/pages/ConfirmModal.vue';
  // 2段階認証設定のコンポーネント読み込み
  import TwoFactorAuth from '../../../common/pages/two_factor_auth/Show.vue';

  export default {

    metaInfo: {
      title: '担当者詳細'
    },

    components: {
      ConfirmModal,
      TwoFactorAuth,
    },

    props: {
      currentUserId: Number,
      isNoFade: Boolean,
    },

    data: function () {
      return {
        user_id: Number(this.$route.params.id),
        user: {},
        display_order: 'posted_time_desc',
        from_page: {},
        is_company_id_illegal: false,
        is_department_id_illegal: false,
        is_current_user_id_illegal: false,
        current_api_v1_user_account_type: '',
        otp_required_for_login: '',
      }
    },
    created: function(){
      this.getEpPersonInCharge();
      this.from_page = this.$store.getters['back_to_index_modal/fromPage'];
    },

    watch: {
      display_order(){
        this.getEpPersonInCharge();
      },
      '$route'(){
        this.user_id = Number(this.$route.params.id);
        this.getEpPersonInCharge();
        this.from_page = this.$store.getters['back_to_index_modal/fromPage'];
      },
    },
    methods: {
      getEpPersonInCharge: function() {
        this.$loading.load(this.$auth.api.get(`ep/ep_person_in_charges/${this.user_id}`, {
          params: {
            display_order: this.display_order
          }
        })
        .then(res => {
          this.user = res.data.user;
          this.otp_required_for_login = res.data.user.otp_required_for_login;
          this.current_api_v1_user_account_type = res.data.current_api_v1_user_account_type;
          this.is_company_id_illegal = res.data.is_company_id_illegal
          this.is_department_id_illegal = res.data.is_department_id_illegal
          this.is_current_user_id_illegal = res.data.is_current_user_id_illegal
          this.checkIllegalId();
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
      },
      checkIllegalId(){
        if (this.is_company_id_illegal){
          this.$router.push({name: 'EpEpCompanyShow', query: {toast_type: 'invalid_transitioned'}});
        } else if (this.is_department_id_illegal) {
          this.$router.push({path: '/ep/', query: {toast_type: 'invalid_transitioned'}});
        } else if (this.is_current_user_id_illegal) {
          this.$router.push({path: '/ep/', query: {toast_type: 'invalid_transitioned'}});
        }
      },
      fullName: function (user) {
        return `${user.last_name} ${user.first_name}`
      },
      fullKanaName: function (user) {
        return user.kana_last_name == '' || user.kana_last_name == null ? '' : `${user.kana_last_name} ${user.kana_first_name}`
      },
      // 戻るボタン押下
      backToPage(){
        this.$router.push({name: 'EpEpCompanyShow'})
      },
      deleteBackToPage(){
        this.$router.push({name: 'EpEpCompanyShow', query: { toast_type: 'deleted' } });
      },
      // 削除確認モーダル内削除するボタン押下時
      onDeleteConfirmSend() {
        this.onEpPersonInChargeDelete();
      },
      // 削除処理
      onEpPersonInChargeDelete() {
        this.$loading.load(
          this.$auth.api.delete(
            `ep/ep_person_in_charges/${this.user.id}`,
            {params:{updated_at: this.user.updated_at}}).then(res => {
            this.$refs.delete_confirm_modal.close();
            this.deleteBackToPage();
          }).catch(err => {
            this.$refs.delete_confirm_modal.close();
            if(err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }else{
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },
    },
  }
</script>
