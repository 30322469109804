<template>
  <div>
    <main>
      <section class="main-title">
        <h1>過去の調達実績編集<span class="small"> - 確認</span></h1>
      </section>

      <form @submit.prevent="updateSuOldAchievement">
        <!-- 過去の調達実績-->
        <section class="chapter white-block">
          <div class="form-wrap d-flex">
            <div class="right-col">
              <dl class="form-group form-confirm-group">
                <dt>調達日付</dt>
                <dd>{{ su_old_achievement.supply_date }}</dd>
              </dl>
              <dl class="form-group form-confirm-group">
                <dt>金額</dt>
                <dd>{{ su_old_achievement.amount | format.trimFractionDigits }}</dd>
              </dl>
              <dl class="form-group form-confirm-group">
                <dt>単位</dt>
                <dd>{{ su_old_achievement.unit }}</dd>
              </dl>
              <dl class="form-group form-confirm-group">
                <dt>出資者</dt>
                <dd v-if="!su_old_achievement.invest_persons"></dd>
                <dd v-else><p v-for="invest_person in su_old_achievement.invest_persons" :key="invest_person">{{invest_person}}</p></dd>
              </dl>
              <dl class="form-group form-confirm-group">
                <dt>投資家の数-金融系VC</dt>
                <dd>{{su_old_achievement.investor_finance_vc}}</dd>
              </dl>
              <dl class="form-group form-confirm-group">
                <dt>投資家の数-CVC・事業会社</dt>
                <dd>{{su_old_achievement.investor_cvc_company}}</dd>
              </dl>
              <dl class="form-group form-confirm-group">
                <dt>投資家の数-独立系VC</dt>
                <dd>{{su_old_achievement.investor_independent}}</dd>
              </dl>
              <dl class="form-group form-confirm-group">
                <dt>投資家の数-政府系</dt>
                <dd>{{su_old_achievement.investor_government}}</dd>
              </dl>
              <dl class="form-group form-confirm-group">
                <dt>投資家の数-海外VC</dt>
                <dd>{{su_old_achievement.investor_international_vc}}</dd>
              </dl>
            </div>
          </div>
        </section>
        <!-- 過去の調達実績-->
        <div class="btn-container d-flex justify-content-center">
          <button class="button-square btn-cancel" type="button" @click="onClickBackPage()">修正する</button>
          <button class="button-square btn-send" type="submit">更新する</button>
        </div>
      </form>
    </main>
  </div>
</template>

<script>
  export default {
    metaInfo: {
      title: '過去の調達実績編集',
    },
    props: {
      su_old_achievement: Object,
    },
    methods: {
      updateSuOldAchievement: function(){
        this.$loading.load(this.$auth.api.patch(`admin/su_old_achievements/${this.su_old_achievement.id}`, {
          su_old_achievement: this.su_old_achievement
        })
        .then(res => {
          this.$store.commit('su_old_achievement/initSuOldAchievement');
          this.$router.push({path: `/admin/su_old_achievements/${this.$route.params.id}/detail` , query: {toast_type: 'updated'}});
        })
        .catch(error => {
          if(error.response.status == 500) {
            this.$errorHandlers.initial(error);
          }else{
            this.$errorHandlers.ajax(error);
          }
        }));
      },
      onClickBackPage: function () {
        this.$router.push({name: 'AdminSuOldAchievementEditForm'});
      },
    },
  }
</script>
