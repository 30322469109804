<template>
  <div id="app">
    <main>
      <section class="main-title">
        <h1>{{title()}}</h1>
      </section>

      <form class="needs-validation" autocomplete="off" @submit.prevent>

        <!-- チャレンジ情報-->
        <section class="chapter white-block">

          <div class="form-wrap">
            <!-- 企業 -->
            <div class="form-group">
              <label class="required-addon">企業名<span class="badge badge-danger">必須</span></label>
              <template v-if="!provisional_ep_challenge.id">
                <v-select
                  v-model="form.provisional_ep_challenge.ep_company_id"
                  :options="ep_companies"
                  label="company_name"
                  :reduce="ep_company => ep_company.id"
                  :state="checkValid('ep_company_id')"
                  :clearable="false"
                  :placeholder="'選択してください'"
                  @option:selected="onChangeCompany()">
                  <template #no-options="{ search, searching, loading }">
                    対象の企業が見つかりませんでした
                  </template>
                </v-select>
              </template>
              <template v-else>
                <div class="chosen-container chosen-container-single chosen-disabled" title="" id="company_name_chosen" style="width: 1538px;">
                  <a class="chosen-single chosen-default">
                    <span>{{form.provisional_ep_challenge.ep_company_name}}</span>
                    <div><b></b></div>
                  </a>
                </div>
              </template>
              <div v-for="error_message in validation_errors.ep_company_id" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- タイトル -->
            <div class="form-group">
              <label class="required-addon" for="title">タイトル<span class="badge badge-danger">必須</span></label>
              <b-form-input
                v-model="form.provisional_ep_challenge.title"
                :state="checkValid('title')"
                type="text"
                placeholder="例) 訪日外国人向けに地域の観光資源を活用した新規事業を立ちあげたい"
                class="form-control">
              </b-form-input>
              <small class="form-text text-right text-secondary">200文字以内</small>
              <div v-for="error_message in validation_errors.title" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- 本文 -->
            <div class="form-group">
              <label class="required-addon" for="comment">本文
                <span class="badge badge-danger">必須</span>
              </label>
              <detail-editor
                v-model="form.provisional_ep_challenge.detail"
                placeholder="例) 高齢化に伴う人口減少による都市の利便性低下を改善するために、県内の特定の地域を対象としたコンパクトシティの開発を、我々のイニシアティブで進めたいと思います。住民だけでなく、建設業者・ディベロッパー・MaaS事業者（交通事業者）・スーパー・医療・介護事業者などが都市に集積することを目標とします。"
              ></detail-editor>
              <div v-for="error_message in validation_errors.detail" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- アセット -->
            <div class="form-group pb-2">
              <h3 class="chapter-label mb-3 required-addon">アセット<span class="badge badge-danger">必須</span></h3>
              <div class="card">
                <!-- 関連アセット-->
                <div class="card-header py-3">
                  <label>関連アセットを選択<small class="text-secondary ml-lg-4">協業先に提供できるアセットを選択してください</small></label>
                  <div class="btn-group-toggle my-2 overflow-auto h-max400" data-toggle="button">
                    <label
                      v-for="asset_master in asset_masters"
                      v-show="asset_master.is_ep_main_industry || isInitAssetMaster(asset_master.id) || isSelectedAssetMaster(asset_master.id)"
                      class="btn button-square btn-check btn-checkbox btn-sm"
                      :class="isSelectedAssetMaster(asset_master.id) ? 'active' : ''"
                    >
                      {{ asset_master.name }}<i class="fas fa-check-circle"></i>
                      <input
                        type="checkbox"
                        class="form-check-input"
                        v-model="provisional_ep_challenge_asset_master_params"
                        :id="'asset_master_' + asset_master.id"
                        :value="{'asset_master_id': asset_master.id}"
                        @click.prevent.stop="onClickAssetMaster(asset_master.id, asset_master.relation_id)"
                      >
                    </label>
                  </div>

                  <div class="btn-container">
                    <button
                      class="button-square btn-sm btn-send"
                      type="button"
                      @click="$refs.other_assets_select_modal.open()"
                    >
                      {{ 'アセットを探す' }}
                      <i class="fas fa-clone pl-2"></i>
                    </button>
                  </div>

                  <div v-for="error_message in validation_errors.provisional_ep_challenge_asset_masters" class="invalid-feedback" style="display: block">
                    {{ error_message }}
                  </div>
                </div>
                <!-- / 関連アセット-->

                <!-- 検索・新規作成-->
                <div class="card-body">
                  <label>新規作成<small class="text-secondary ml-lg-4">該当するアセットがない場合は新規作成する</small></label>
                  <div class="new-assets">
                    <div class="incremental-wrap">
                      <input v-model="asset_name" class="form-control" type="text" placeholder="" value="" >
                    </div>
                    <button @click="onNewAsset" class="button-square btn-send" :class="{'disabled': !asset_name}" type="button" data-toggle="modal" data-target="#market-setting">作成</button>
                  </div>
                </div>
                <!-- /検索・新規作成-->

                <!-- 登録予定のアセット-->
                <div class="card-body">
                  <label>登録予定のアセット</label>
                  <div>
                    <template v-for="(asset_master, index) in form.provisional_ep_challenge.asset_masters_attributes">
                      <div class="assets-labels mt-0" v-if="!asset_master._destroy || asset_master._destroy != 1">
                        <!-- 表示 -->
                        <div class="d-flex" :class="!asset_master.is_edit ? 'd-show' : 'd-none'">
                          <p class="task-labels-parent pl-0">{{ asset_master.name }}</p>
                          <button @click="onEditAssetMaster(asset_master)" class="btn-action mx-2 js-switch-trigger" type="button">
                            <i class="fas fa-pen" v-b-tooltip.hover="'編集'"></i>
                          </button>
                          <button @click="onDeleteAssetMaster(asset_master)" class="btn-action" type="button">
                            <i class="fas fa-trash" v-b-tooltip.hover="'削除'"></i>
                          </button>
                        </div>
                        <!-- 編集 -->
                        <div class="assets-edit-wrap" :class="asset_master.is_edit ? 'd-show' : 'd-none'">
                          <div class="incremental-wrap">
                            <input v-model="edit_form_asset_master.name" class="form-control" type="text">
                          </div>
                          <div class="btn-container d-flex justify-content-end mt-2">
                            <button @click="onCancelAsset(asset_master)" class="button-square btn-cancel btn-sm" type="button">キャンセル</button>
                            <button @click="onSaveAsset(asset_master)" :class="{'disabled': edit_form_asset_master.name == asset_master.name}" class="button-square btn-send btn-sm" type="button">保存</button>
                          </div>
                        </div>
                        <!-- エラーメッセージ -->
                        <div
                          v-for="error_message in assetMasterErrorMessagesIndexIs(index).error_messages"
                          class="invalid-feedback"
                          style="display:block"
                        >
                          {{ error_message }}
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <!-- / 登録予定のアセット-->
              </div>
            </div>

            <!-- ステージ -->
            <div class="form-row">
              <div class="form-group col-4">
                <label class="required-addon" for="stage">ステージ<span class="badge badge-danger">必須</span></label>
                <b-select v-model="form.provisional_ep_challenge.challenge_stage" :state="checkValid('challenge_stage')" class="custom-select">
                  <option value="">選択してください</option>
                  <option v-for="(challenge_stages_i18n, challenge_stage) in challenge_stages" :key="challenge_stage" :value="challenge_stage">
                    {{ challenge_stages_i18n }}
                  </option>
                </b-select>
                <div v-for="error_message in validation_errors.challenge_stage" class="invalid-feedback" style="display: block">
                  {{ error_message }}
                </div>
              </div>
            </div>

            <!-- 業種 -->
            <fieldset>
              <legend class="required-addon">業種（協業を想定するパートナーの業種）<span class="badge badge-secondary">任意</span></legend>
              <div class="btn-group-toggle" data-toggle="buttons">
                <label
                  v-for="industry_master in industry_masters"
                  class="btn button-square btn-check btn-checkbox btn-sm"
                  :class="isSelectedIndustryMaster(industry_master.id) ? 'active' : ''"
                >
                  {{ industry_master.name }}<i class="fas fa-check-circle"></i>
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="provisional_ep_challenge_industry_master_params"
                    :id="'industry_master_' + industry_master.id"
                    :value="{'industry_master_id': industry_master.id}"
                    @click.prevent.stop="onClickIndustryMaster(industry_master.id)"
                  >
                </label>
              </div>

              <div v-for="error_message in validation_errors.provisional_ep_challenge_industry_masters" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </fieldset>

            <!-- メイン画像 アップロード -->
            <div class="form-group">
              <label class="required-addon">メインビジュアル
                <span class="badge badge-secondary">任意</span>
                <i class="fas fa-info-circle icon-gray mx-1" id="main-image-tooltip"></i>
                <b-tooltip target="main-image-tooltip" triggers="hover">
                  推奨サイズ：横1280px、縦780px
                </b-tooltip>
              </label>
              <!-- 画像 -->
              <div class="drop_area" @dragleave.prevent @dragover.prevent @dragend.prevent @drop.prevent="dropFile($event, form.provisional_ep_challenge.main_image_attributes, main_image)">
                <!-- 新規画像 か 保存されている画像 -->
                <img v-if="!!main_image.src"
                  class="img320"
                  :src="main_image.src"
                  :alt="main_image.alt"
                />
                <!-- デフォルト画像 -->
                <img v-else
                  class="img320"
                  src="~images/image_default.jpg"
                  alt=""
                />
              </div>
              <!-- ボタン -->
              <div class="d-flex mt-2">
                <div class="btn-group" role="group" aria-label="画像アップロード">
                  <button
                    v-if="!!main_image.src"
                    @click="removeImageFile(form.provisional_ep_challenge.main_image_attributes, main_image, $refs.main_image_upload)"
                    class="btn btn-cancel icon-addon-left px-3"
                    type="button"
                    data-toggle="modal"
                    data-target="#delete"
                  >
                    <i class="fas fa-times"></i>
                    削除
                  </button>
                  <label class="btn btn-file icon-addon-left px-3" for="main-image-upload">
                    <i class="fas fa-arrow-up"></i>アップロード
                    <input
                      type="file"
                      id="main-image-upload"
                      ref="main_image_upload"
                      :accept="accept_extensions"
                      @change="onImageFileChange($event, form.provisional_ep_challenge.main_image_attributes, main_image)"
                    >
                  </label>
                </div>
                <small class="form-text text-right text-secondary ml-2">画像形式：jpg、png（2MBまで）</small>
              </div>
              <!-- エラーメッセージ -->
              <template v-if="!!validation_errors.main_image">
                <div v-for="error_message in validation_errors.main_image" class="invalid-feedback" style="display: block">
                  {{ error_message }}
                </div>
              </template>
              <template v-else-if="!!validation_errors.main_image_name">
                <div v-for="error_message in validation_errors.main_image_name" class="invalid-feedback" style="display: block">
                  {{ error_message }}
                </div>
              </template>
              <div v-for="error_message in validation_errors.main_image_size" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- メイン画像 タイトル -->
            <div class="form-group">
              <label class="required-addon" for="mainvisual-title">メインビジュアル タイトル<span class="badge badge-secondary">任意</span></label>
              <b-form-input
                v-model="form.provisional_ep_challenge.main_image_title"
                :state="checkValid('main_image_title')"
                type="text"
                placeholder="例) 地方の女性をターゲット"
                class="form-control">
              </b-form-input>
              <div v-for="error_message in validation_errors.main_image_title" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- サブ1画像 アップロード -->
            <div class="form-group">
              <label class="required-addon">イメージ1
                <span class="badge badge-secondary">任意</span>
                <i class="fas fa-info-circle icon-gray mx-1" id="sub1-image-tooltip"></i>
                <b-tooltip target="sub1-image-tooltip" triggers="hover">
                  推奨サイズ：横1280px、縦780px
                </b-tooltip>
              </label>
              <!-- 画像 -->
              <div class="drop_area" @dragleave.prevent @dragover.prevent @dragend.prevent @drop.prevent="dropFile($event, form.provisional_ep_challenge.sub1_image_attributes, sub1_image)">
                <template>
                  <!-- 新規画像 か 保存されている画像 -->
                  <img v-if="!!sub1_image.src"
                    class="img320"
                    :src="sub1_image.src"
                    :alt="sub1_image.alt"
                  />
                  <!-- デフォルト画像 -->
                  <img v-else
                    class="img320"
                    src="~images/image_default.jpg"
                    alt=""
                  />
                </template>
              </div>
              <!-- ボタン -->
              <div class="d-flex mt-2">
                <div class="btn-group" role="group" aria-label="画像アップロード">
                  <button
                    v-if="!!sub1_image.src"
                    @click="removeImageFile(form.provisional_ep_challenge.sub1_image_attributes, sub1_image, $refs.sub1_image_upload)"
                    class="btn btn-cancel icon-addon-left px-3"
                    type="button"
                    data-toggle="modal"
                    data-target="#delete"
                  >
                    <i class="fas fa-times"></i>
                    削除
                  </button>
                  <label class="btn btn-file icon-addon-left px-3" for="sub1-image-upload">
                    <i class="fas fa-arrow-up"></i>アップロード
                    <input
                      type="file"
                      id="sub1-image-upload"
                      ref="sub1_image_upload"
                      :accept="accept_extensions"
                      @change="onImageFileChange($event, form.provisional_ep_challenge.sub1_image_attributes, sub1_image)"
                    >
                  </label>
                </div>
                <small class="form-text text-right text-secondary ml-2">画像形式：jpg、png（2MBまで）</small>
              </div>
              <!-- エラーメッセージ -->
              <span v-if="!!validation_errors.sub1_image">
                <div v-for="error_message in validation_errors.sub1_image" class="invalid-feedback" style="display: block">
                  {{ error_message }}
                </div>
              </span>
              <span v-else-if="!!validation_errors.sub1_image_name">
                <div v-for="error_message in validation_errors.sub1_image_name" class="invalid-feedback" style="display: block">
                  {{ error_message }}
                </div>
              </span>
              <div v-for="error_message in validation_errors.sub1_image_size" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- サブ1画像 タイトル -->
            <div class="form-group">
              <label class="required-addon" for="subimage1-title">イメージ1 タイトル<span class="badge badge-secondary">任意</span></label>
              <b-form-input
                v-model="form.provisional_ep_challenge.sub1_image_title"
                :state="checkValid('sub1_image_title')"
                type="text"
                placeholder=""
                class="form-control">
              </b-form-input>
              <div v-for="error_message in validation_errors.sub1_image_title" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- サブ2画像 アップロード -->
            <div class="form-group">
              <label class="required-addon">イメージ2
                <span class="badge badge-secondary">任意</span>
                <i class="fas fa-info-circle icon-gray mx-1" id="sub2-image-tooltip"></i>
                <b-tooltip target="sub2-image-tooltip" triggers="hover">
                  推奨サイズ：横1280px、縦780px
                </b-tooltip>
              </label>
              <!-- 画像 -->
              <div class="drop_area" @dragleave.prevent @dragover.prevent @dragend.prevent @drop.prevent="dropFile($event, form.provisional_ep_challenge.sub2_image_attributes, sub2_image)">
                <template>
                  <!-- 新規画像 か 保存されている画像 -->
                  <img v-if="!!sub2_image.src"
                    class="img320"
                    :src="sub2_image.src"
                    :alt="sub2_image.alt"
                  />
                  <!-- デフォルト画像 -->
                  <img v-else
                    class="img320"
                    src="~images/image_default.jpg"
                    alt=""
                  />
                </template>
              </div>
              <!-- ボタン -->
              <div class="d-flex mt-2">
                <div class="btn-group" role="group" aria-label="画像アップロード">
                  <button
                    v-if="!!sub2_image.src"
                    @click="removeImageFile(form.provisional_ep_challenge.sub2_image_attributes, sub2_image, $refs.sub2_image_upload)"
                    class="btn btn-cancel icon-addon-left px-3"
                    type="button"
                    data-toggle="modal"
                    data-target="#delete"
                  >
                    <i class="fas fa-times"></i>
                    削除
                  </button>
                  <label class="btn btn-file icon-addon-left px-3" for="sub2-image-upload">
                    <i class="fas fa-arrow-up"></i>アップロード
                    <input
                      type="file"
                      id="sub2-image-upload"
                      ref="sub2_image_upload"
                      :accept="accept_extensions"
                      @change="onImageFileChange($event, form.provisional_ep_challenge.sub2_image_attributes, sub2_image)"
                    >
                  </label>
                </div>
                <small class="form-text text-right text-secondary ml-2">画像形式：jpg、png（2MBまで）</small>
              </div>
              <!-- エラーメッセージ -->
              <span v-if="!!validation_errors.sub2_image">
                <div v-for="error_message in validation_errors.sub2_image" class="invalid-feedback" style="display: block">
                  {{ error_message }}
                </div>
              </span>
              <span v-else-if="!!validation_errors.sub2_image_name">
                <div v-for="error_message in validation_errors.sub2_image_name" class="invalid-feedback" style="display: block">
                  {{ error_message }}
                </div>
              </span>
              <div v-for="error_message in validation_errors.sub2_image_size" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- サブ2画像 タイトル -->
            <div class="form-group">
              <label class="required-addon" for="subimage2-title">イメージ2 タイトル<span class="badge badge-secondary">任意</span></label>
              <b-form-input
                v-model="form.provisional_ep_challenge.sub2_image_title"
                :state="checkValid('sub2_image_title')"
                type="text"
                placeholder=""
                class="form-control">
              </b-form-input>
              <div v-for="error_message in validation_errors.sub2_image_title" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>
          </div>
        </section>
        <!-- / チャレンジ情報-->

        <!-- 企業情報-->
        <section class="chapter white-block">
          <h2 class="chapter-label">企業情報（私たちについて）</h2>
          <div class="form-wrap border-top">
            <div class="form-group">
              <label class="required-addon" for="company-data">会社説明<span class="badge badge-danger">必須</span></label>
              <b-form-textarea
                v-model="form.provisional_ep_challenge.ep_company_attributes.business_summary"
                :state="checkValid('ep_company.business_summary')"
                type="text"
                placeholder=""
                class="form-control auto-resize">
              </b-form-textarea>
              <small class="form-text text-right text-secondary">500文字以内</small>
              <div v-if="!!validation_errors['ep_company.business_summary']" class="invalid-feedback" style="display: block">
                {{ validation_errors['ep_company.business_summary'][0] }}
              </div>
            </div>
          </div>
        </section>
        <!-- 企業情報-->

        <!-- 審査詳細 -->
        <section class="chapter white-block">
          <h2 class="chapter-label mb-3">審査詳細</h2>
          <div class="form-wrap border-top">
            <div class="form-row">
              <div class="form-group col-4">
                <label>ステータス</label>
                <b-select v-model="form.provisional_ep_challenge.exam_status" :state="checkValid('exam_status')" class="custom-select">
                  <option v-for="(exam_statuses_i18n, exam_status_key) in exam_statuses" :key="exam_status_key" :value="exam_status_key" v-if="exam_status_key != 'draft'">
                    {{exam_statuses_i18n}}
                  </option>
                </b-select>
                <div v-for="error_message in validation_errors.exam_status" class="invalid-feedback" style="display: block">
                  {{ error_message }}
                </div>
              </div>
            </div>

            <div v-show="isDispPublicArea()">
              <div class="form-group">
                <label>公開ステータス</label>
                <p class="pl-4">{{ form.provisional_ep_challenge.public_status }}</p>
              </div>

              <fieldset class="form-group">
                <label>公開期間</label>
                <div class="ml-4 mb-3">
                  <label class="pt-2"><span class="badge badge-danger">必須</span>&nbsp;開始日</label>
                  <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                    <b-form-input v-model="form.provisional_ep_challenge.ep_challenge_attributes.release_date" :state="checkValid('ep_challenge.release_date')" class="form-control" id="start-date" type="text" data-toggle="datetimepicker" data-target="#start-date" autocomplete="off" required></b-form-input><i class="far fa-calendar-alt"></i>
                    <p v-if="!!validation_errors['ep_challenge.release_date']" class="invalid-feedback d-block">
                      {{ validation_errors['ep_challenge.release_date'][0] }}
                    </p>
                  </div>
                  <label class="pt-2">時間</label>
                  <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                    <b-form-input v-model="form.provisional_ep_challenge.ep_challenge_attributes.release_time" :state="checkValid('ep_challenge.release_time')" class="form-control" id="start-time" type="text" data-toggle="datetimepicker" data-target="#start-time" autocomplete="off" required></b-form-input><i class="far fa-clock"></i>
                    <p v-if="!!validation_errors['ep_challenge.release_time']" class="invalid-feedback d-block">
                      {{ validation_errors['ep_challenge.release_time'][0] }}
                    </p>
                  </div>
                  <p v-if="!!validation_errors['ep_challenge.release_at']" class="invalid-feedback d-block">
                    {{ validation_errors['ep_challenge.release_at'][0] }}
                  </p>
                </div>

                <div class="ml-4">
                  <label class="pt-2"><span class="badge badge-secondary">任意</span>&nbsp;終了日</label>
                  <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                    <b-form-input v-model="form.provisional_ep_challenge.ep_challenge_attributes.close_date" :state="checkValid('ep_challenge.close_date')" class="form-control" id="end-date" type="text" data-toggle="datetimepicker" data-target="#end-date" autocomplete="off"></b-form-input><i class="far fa-calendar-alt"></i>
                    <p v-if="!!validation_errors['ep_challenge.close_date']" class="invalid-feedback d-block">
                      {{ validation_errors['ep_challenge.close_date'][0] }}
                    </p>
                  </div>
                  <label class="pt-2">時間</label>
                  <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                    <b-form-input v-model="form.provisional_ep_challenge.ep_challenge_attributes.close_time" :state="checkValid('ep_challenge.close_time')" class="form-control" id="end-time" type="text" data-toggle="datetimepicker" data-target="#end-time" autocomplete="off"></b-form-input><i class="far fa-clock"></i>
                    <p v-if="!!validation_errors['ep_challenge.close_time']" class="invalid-feedback d-block">
                      {{ validation_errors['ep_challenge.close_time'][0] }}
                    </p>
                  </div>
                  <p v-if="!!validation_errors['ep_challenge.close_at']" class="invalid-feedback d-block">
                    {{ validation_errors['ep_challenge.close_at'][0] }}
                  </p>
                </div>
              </fieldset>
            </div>

            <div v-show="isDispNoReason()">
              <div class="form-group">
                 <label>要修正理由</label>
                 <b-form-textarea
                   v-model="form.provisional_ep_challenge.no_reason"
                   :state="checkValid('no_reason')"
                   type="text"
                   placeholder=""
                   class="form-control auto-resize">
                 </b-form-textarea>
                 <div v-if="!!validation_errors['no_reason']" class="invalid-feedback" style="display: block">
                   {{ validation_errors['no_reason'][0] }}
                 </div>
               </div>
             </div>
          </div>
        </section>
        <!-- / 審査詳細-->

        <!-- おすすめチャレンジ枠 -->
        <section class="chapter white-block">
          <h2 class="chapter-label mb-3">おすすめチャレンジ枠</h2>
          <div class="form-wrap border-top">
            <div class="form-row">
              <div class="form-group col-4">
                <label>設定</label>
                <b-select v-model="form.provisional_ep_challenge.is_sponser" :state="checkValid('is_sponser')" class="custom-select">
                  <!-- v-forディレクティブを使用してオプションを動的に生成 -->
                  <option v-for="option in sponserOptions" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </b-select>
                <div v-for="error_message in validation_errors.is_sponser" :key="error_message" class="invalid-feedback" style="display: block">
                  {{ error_message }}
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- / おすすめチャレンジ枠-->

        <div class="btn-container d-flex justify-content-center">
          <button @click="onClickBack()" class="button-square btn-cancel" type="button">もどる</button>
          <button class="button-square btn-save" @click="onClickTemporarily" type="button" v-if="!provisional_ep_challenge.id || provisional_ep_challenge.is_draft">一時保存する</button>
          <button class="button-square btn-send" @click="onClickConfirm" type="button">確認する</button>
        </div>
      </form>

      <ConfirmModal
        ref="back_confirm_modal"
        :onClickSend="onBack"
        :body_text="'入力内容を破棄して戻ります。<br>よろしいですか？'"
        :send_button_text="'戻る'"
        :isNoFade="isNoFade"
      />

      <ConfirmModal
        ref="reset_confirm_modal"
        :onClickSend="changeCompany"
        :onClickCancel="cancelChangeCompany"
        :body_text="'企業を変更したため、入力内容を一度クリアします。<br>よろしいですか？'"
        :send_button_text="'実行する'"
        :isNoFade="isNoFade"
      />

      <!-- アセット検索モーダル-->
      <AssetsSelectModal
        ref="other_assets_select_modal"
        :onClickSend="onSelectedOtherAssets"
        :isNoFade="isNoFade"
        :asset_masters="asset_masters"
      />

    </main>
  </div>
</template>

<script>
  import Form from '../../../common/mixins/form';
  // 確認モーダルのコンポーネント読み込み
  import ConfirmModal from '../../../common/pages/ConfirmModal.vue';
  // アップロード画像チェック
  import UploadFileCheck  from "../../../common/mixins/upload_file_check";
  // アセット検索モーダルのコンポーネント読み込み
  import AssetsSelectModal     from '../../../common/pages/assetsSelectModal';
  // WYSIWYGエディタ
  import DetailEditor from '../../../common/pages/DetailEditor.vue'

  export default {
    mixins: [Form, UploadFileCheck],

    props: {
      // 登録編集対象の仮登録EPチャレンジ
      provisional_ep_challenge: Object,
      // 大手企業一覧
      ep_companies: {
        type: Array,
        default: () => ([])
      },
      // アセットマスタ
      asset_masters: {
        type: Array,
        default: () => ([])
      },
      // 審査ステータス一覧
      exam_statuses: {
        type: Object,
        default: () => ({})
      },
      // 業界マスタ
      industry_masters: {
        type: Array,
        default: () => ([])
      },
      // EPチャレンジ ステージ一覧
      challenge_stages: {
        type: Object,
        default: () => ({})
      },
      // 許容拡張子
      accept_extensions: {
        type: Array,
        default: () => ([])
      },
      // フォームの内容
      form: {
        type: Object,
        default: () => ({})
      },
      // 確認ボタン押下時のコールバックメソッド
      onConfirm: {
        type: Function,
      },
      // 一時保存ボタン押下時のコールバックメソッド
      onTemporarily: {
        type: Function,
      },
      // 戻る確認モーダル内の戻るボタン押下時のコールバックメソッド
      onBack: {
        type: Function,
      },
      // バリデーションエラー
      validation_errors: {
        type: Object,
        default: () => ({})
      },
      // メイン画像(表示用)
      main_image: {
        type: Object,
        default: () => ({})
      },
      // サブ1画像(表示用)
      sub1_image: {
        type: Object,
        default: () => ({})
      },
      // サブ2画像(表示用)
      sub2_image: {
        type: Object,
        default: () => ({})
      },
      // 入力フォームが変更されたか
      is_form_changed: {
        type: Boolean,
        default: false
      },
      isNoFade: {
        type: Boolean,
      }
    },

    components: {
      ConfirmModal,
      AssetsSelectModal,
      DetailEditor,
    },

    data() {
      return {
        // アセット名の入力値
        asset_name: '',
        // アセットの検索候補
        pick_assets: [],
        // EP企業候補
        pick_ep_companies: [],
        // 大手企業の業界に紐づく承認済みアセットのIDs
        ep_industry_asset_master_ids: [],
        // 全ての業界
        industries: [],
        // アセット編集用
        edit_form_asset_master: {
          id: null,
          name: '',
        },
        sponserOptions: [
          { value: 0, text: '設定しない' },
          { value: 1, text: '設定する' }
        ],
        // 前企業ID
        old_company_id: '',
        // 戻る確認モーダル内テキスト
        back_confirm_text: '',
        // 戻るページ名
        back_page_name: '',
        asset_master_error_messages: [],
      }
    },

    watch: {
      validation_errors(new_validation_errors, old_validation_errors) {
        // 値をpostする形にするため編集をコピー
        let post_form = JSON.parse(JSON.stringify(this.form));

        // 登録するアセットと削除するアセットのパラメータを結合
        let provisional_ep_challenge_asset_masters_attributes = post_form.provisional_ep_challenge.provisional_ep_challenge_asset_masters_attributes
        let destroy_provisional_ep_challenge_asset_masters_attributes = post_form.provisional_ep_challenge.destroy_provisional_ep_challenge_asset_masters_attributes
        let concat_provisional_ep_challenge_asset_masters_attributes = provisional_ep_challenge_asset_masters_attributes.concat(destroy_provisional_ep_challenge_asset_masters_attributes);
        // 選択されたアセットの件数を取得
        // 新規で選択されたアセットは除外
        let provisional_ep_challenge_asset_masters_length = concat_provisional_ep_challenge_asset_masters_attributes.filter(
            concat_provisional_ep_challenge_asset_masters_attribute => !!concat_provisional_ep_challenge_asset_masters_attribute.id
        ).length;

        this.asset_master_error_messages = [];

        this.form.provisional_ep_challenge.asset_masters_attributes.forEach((asset_masters_attributes, index) => {
          // 新規登録
          if(!this.provisional_ep_challenge.id) {
            var error_messages = new_validation_errors[`asset_masters[${index}].name`]
            this.asset_master_error_messages.push({
              error_message_index: index,
              error_messages: error_messages
            });
          }
          // 編集
          else {
            var error_messages = new_validation_errors[`asset_masters[${provisional_ep_challenge_asset_masters_length + index}].name`]
            this.asset_master_error_messages.push({
              error_message_index: index,
              error_messages: error_messages
            });
          }
        });

        // エラー時ページトップにスクロール
        if (Object.keys(new_validation_errors).length > 0) {
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        };
      },
      'form.provisional_ep_challenge.ep_company_id'(new_company_id, old_company_id) {
        this.old_company_id = old_company_id;
        if(this.old_company_id == ''){
          this.old_company_id = new_company_id;
        }
      },
    },

    mounted: function() {
      // 初期選択IDがパラメータで渡された場合、EP企業を初期選択
      if(!!this.$route.params.init_select_id){
        this.changeCompany(this.$route.params.init_select_id)
      }

      const self = this;
      // 公開期間_開始日 のカレンダー設定
      this.setDatePicker(
        '#start-date',
        function(selected_date) {
          self.form.provisional_ep_challenge.ep_challenge_attributes.release_date = selected_date;
          self.updatePublicStatus();
        },
        'top'
      );
      // 公開期間_終了日 のカレンダー設定
      this.setDatePicker(
        '#end-date',
        function(selected_date) {
          self.form.provisional_ep_challenge.ep_challenge_attributes.close_date = selected_date;
          self.updatePublicStatus();
        },
        'top'
      );
      // 公開期間_開始時間 のカレンダー設定
      this.setTimePicker(
        '#start-time',
        function(selected_time) {
          self.form.provisional_ep_challenge.ep_challenge_attributes.release_time = selected_time;
          self.updatePublicStatus();
        },
        'top'
      );
      // 公開期間_終了時間 のカレンダー設定
      this.setTimePicker(
        '#end-time',
        function(selected_time) {
          self.form.provisional_ep_challenge.ep_challenge_attributes.close_time = selected_time;
          self.updatePublicStatus();
        },
        'top'
      );
    },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
    },

    // データ更新時の再描画後に実行
    updated(){
      // テキストエリア 改行時高さ自動調整
      this.setTextAreaAutoSize();
    },

    // メソッド定義
    methods: {
      // 初期化
      init() {
      },

      // 戻るボタン押下時処理
      onClickBack(){
        // フォームを変更されている場合は確認モーダルを表示
        this.is_form_changed ? this.$refs.back_confirm_modal.open() : this.onBack();
      },

      // 一時保存ボタン押下時処理
      onClickTemporarily() {
        if( this.asset_name != '' || this.edit_form_asset_master.name != '' ) {
          this.$bvToast.toast('アセットが編集中です。編集を完了してください。', {
            variant: 'danger',
            title: 'エラー'
          });
        } else {
          this.onTemporarily();
        }
      },

      // 確認ボタン押下時処理
      onClickConfirm() {
        // 編集中のアセットが存在する場合、エラーメッセージを表示
        if( this.asset_name != '' || this.edit_form_asset_master.name != '' ) {
          this.$bvToast.toast('アセットが編集中です。編集を完了してください。', {
            variant: 'danger',
            title: 'エラー'
          });
        } else {
          this.onConfirm();
        }
      },

      // 画像選択時処理
      onImageFileChange(event, image_attributes, disp_image) {
        let reader = new FileReader();
        let files = event.target.files || event.dataTransfer.files;

        // ファイルチェック(/common/mixins/upload_file_check.jsを参照)
        let image_file_error = this.checkUploadImage(files[0]);
        if (image_file_error != ''){
          // エラーの場合はトースト表示
          this.$bvToast.toast(image_file_error, { variant: 'danger', title: 'エラー'});
          return;
        }

        reader.onload = e => {
          image_attributes.upload_image = e.target.result;
          disp_image.src = e.target.result;
        };
        reader.readAsDataURL(files[0]);

        image_attributes.filename = files[0].name;
        image_attributes.size     = files[0].size;

        disp_image.alt = image_attributes.filename;
        // _destroy要素を削除
        delete image_attributes._destroy
      },

      // 画像ドロップ時処理
      dropFile(event, image_attributes, disp_image) {
        this.onImageFileChange(event, image_attributes, disp_image);
      },

      // 画像削除ボタン押下時処理
      removeImageFile(image_attributes, disp_image, upload_btn_refs) {
        image_attributes.filename = '';
        image_attributes.size     = '';
        image_attributes.upload_image = '';
        image_attributes._destroy = 1;
        disp_image.src = '';
        disp_image.alt = '';
        // 同じファイルの再アップロードができるようにアップロードボタンのvalueをクリアする
        upload_btn_refs.value = '';
      },

      // 対象のアセットマスタが選択されているか
      isSelectedAssetMaster(asset_master_id){
        if (Object.keys(this.form.provisional_ep_challenge.provisional_ep_challenge_asset_masters_attributes).length == 0) {
          return false;
        }

        return !!this.form.provisional_ep_challenge.provisional_ep_challenge_asset_masters_attributes.filter(
            provisional_ep_challenge_asset_masters => provisional_ep_challenge_asset_masters.asset_master_id == asset_master_id
        ).length;
      },

      // 対象のアセットマスタがep_industry_asset_master_idsに含まれているか
      isInitAssetMaster(asset_master_id){
        return this.ep_industry_asset_master_ids.includes(asset_master_id)
      },

      // アセットの選択肢を選択時処理
      onClickAssetMaster(asset_master_id) {
        // 選択済み一覧から対象を検索
        let selected_asset_master =
          this.form.provisional_ep_challenge.provisional_ep_challenge_asset_masters_attributes.find(v => v.asset_master_id == asset_master_id);

        // 削除一覧から対象を検索
        let delete_asset_master =
          this.form.provisional_ep_challenge.destroy_provisional_ep_challenge_asset_masters_attributes.find(v => v.asset_master_id == asset_master_id);

        // 削除一覧に存在する
        if (!!delete_asset_master) {
          // 削除一覧から対象を削除
          this.$delete(
            this.form.provisional_ep_challenge.destroy_provisional_ep_challenge_asset_masters_attributes,
            this.form.provisional_ep_challenge.destroy_provisional_ep_challenge_asset_masters_attributes.findIndex(v => v.asset_master_id == asset_master_id)
          );

          // 選択済み一覧に対象を追加
          this.form.provisional_ep_challenge.provisional_ep_challenge_asset_masters_attributes.push({
            'id': delete_asset_master.id,
            'asset_master_id': asset_master_id,
          });

          return;
        }

        // 選択済み一覧に存在する
        if (!!selected_asset_master) {
          // 選択済み一覧から削除
          this.$delete(
            this.form.provisional_ep_challenge.provisional_ep_challenge_asset_masters_attributes,
            this.form.provisional_ep_challenge.provisional_ep_challenge_asset_masters_attributes.findIndex(v => v.asset_master_id == asset_master_id)
          );

          // 中間テーブルのIDを持っているか
          if(!!selected_asset_master.id) {
            // 削除一覧に対象を追加
            this.form.provisional_ep_challenge.destroy_provisional_ep_challenge_asset_masters_attributes.push({
              'id': selected_asset_master.id,
              'asset_master_id': selected_asset_master.asset_master_id,
              '_destroy': 1,
            });
          }

          return;
        }

        // 中間テーブルに紐づかない、未選択状態のものを選択した場合
        // 選択済み一覧に対象を追加
        this.form.provisional_ep_challenge.provisional_ep_challenge_asset_masters_attributes.push({
          'asset_master_id': asset_master_id,
        });
      },

      // アセット検索モーダルの追加するボタン押下時処理
      onSelectedOtherAssets(selected_asset_masters_attributes) {
        // 既に選択されていたアセットマスタと結合
        this.form.provisional_ep_challenge.provisional_ep_challenge_asset_masters_attributes =
            this.form.provisional_ep_challenge.provisional_ep_challenge_asset_masters_attributes.concat(selected_asset_masters_attributes)

        // 重複排除
        this.form.provisional_ep_challenge.provisional_ep_challenge_asset_masters_attributes = Array.from(
            new Map(this.form.provisional_ep_challenge.provisional_ep_challenge_asset_masters_attributes.map((asset) => [asset.asset_master_id, asset])).values()
        );

        // 登録しようとしているアセットが削除一覧に存在する場合、削除一覧からそのアセットを削除
        this.delete_assets_from_destroy_list(selected_asset_masters_attributes);

        this.$refs.other_assets_select_modal.close();
      },

      // アセット 作成ボタン押下時処理
      onNewAsset() {
        // 入力値が空文字の場合、スルー
        if(this.asset_name.name == '') {
          return;
        }

        // 既に仮登録アセットに存在する場合、スルー
        if(this.form.provisional_ep_challenge.asset_masters_attributes.filter(asset_master => asset_master.name == this.asset_name).length != 0) {
          this.$bvToast.toast('すでに登録されているアセットのため登録できません。', {
            variant: 'danger',
            title: 'エラー'
          });

          return;
        }

        this.$loading.load(
          this.$auth.api.get(
            'admin/provisional_ep_challenges/search_asset_name', {params: {
              name: this.asset_name
            }}
          ).then(res => {
            if(res.data.length < 1) {
              let asset_master = {
                'id': null,
                'name': this.asset_name,
                'is_edit': false,
              };
              // 登録予定のアセットに追加
              this.form.provisional_ep_challenge.asset_masters_attributes.push(asset_master);
              // アセットの入力欄を空にする
              this.asset_name = '';
              // バリデーションエラーの配列を初期化
              this.asset_master_error_messages = [];
            }
            else {
              this.$bvToast.toast('すでに登録されているアセットのため登録できません。', {
                variant: 'danger',
                title: 'エラー'
              });

              result = false;
            }
          }).catch(err => {
            if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else {
              this.$errorHandlers.ajax(err);
            }
          })
        );
      },

      // アセット編集 編集ボタン押下時処理
      onEditAssetMaster(asset_master) {
        this.edit_form_asset_master.id   = asset_master.id;
        this.edit_form_asset_master.name = asset_master.name;

        // 一旦の編集中のアセットの編集エリアを閉じる
        this.form.provisional_ep_challenge.asset_masters_attributes.forEach((asset_masters_attributes, index) => {
          asset_masters_attributes.is_edit = false;
        });

        asset_master.is_edit = true;
      },

      // アセット編集 削除ボタン押下時処理
      onDeleteAssetMaster(asset_master) {
        if(!asset_master.id) {
          // フォーム上から削除する
          this.$delete(
            this.form.provisional_ep_challenge.asset_masters_attributes,
            this.form.provisional_ep_challenge.asset_masters_attributes.findIndex(v => v.name == asset_master.name)
          );
          // バリデーションエラーの配列を初期化
          this.asset_master_error_messages = [];
        }
        else {
          // 論理削除の対象にする
          this.$set(asset_master, '_destroy', 1);
        }
      },

      // アセット編集 保存ボタン押下時処理
      onSaveAsset(asset_master) {
        // 既に仮登録アセットに存在する場合、スルー
        if(this.form.provisional_ep_challenge.asset_masters_attributes.filter(asset_masters_attributes => asset_masters_attributes.name == this.edit_form_asset_master.name).length != 0) {
          this.$bvToast.toast('すでに登録されているアセットのため登録できません。', {
            variant: 'danger',
            title: 'エラー'
          });

          return;
        }

        // 入力値が空文字の場合、スルー
        if(this.edit_form_asset_master.name == '') {
          return;
        }

        this.$loading.load(
          this.$auth.api.get(
            'admin/provisional_ep_challenges/search_asset_name', {params: {
              name: this.edit_form_asset_master.name
            }}
          ).then(res => {
            if(res.data.length < 1) {
              asset_master.id   = this.edit_form_asset_master.id;
              asset_master.name = this.edit_form_asset_master.name;
              this.edit_form_asset_master.id   = null;
              this.edit_form_asset_master.name = '';
              asset_master.is_edit = false;
            }
            else {
              this.$bvToast.toast('すでに登録されているアセットのため登録できません。', {
                variant: 'danger',
                title: 'エラー'
              });

              result = false;
            }
          }).catch(err => {
            if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else {
              this.$errorHandlers.ajax(err);
            }
          })
        );
      },

      // アセット編集 キャンセルボタン押下時処理
      onCancelAsset(asset_master) {
        this.edit_form_asset_master.id   = null;
        this.edit_form_asset_master.name = '';
        asset_master.is_edit = false;
      },

      // 対象アセットが削除一覧に存在する場合、削除一覧からそのアセットを削除
      delete_assets_from_destroy_list(asset_masters_attributes){
        let tmp_array = [];
        for (const destroy_asset_master_attributes of this.form.provisional_ep_challenge.destroy_provisional_ep_challenge_asset_masters_attributes) {
          let flg = true;
          for (const asset_master_attributes of asset_masters_attributes){
            if (destroy_asset_master_attributes.asset_master_id == asset_master_attributes.asset_master_id){
              flg = false;
              break;
            }
          }
          if (flg){tmp_array.push(destroy_asset_master_attributes)}
        }
        this.form.provisional_ep_challenge.destroy_provisional_ep_challenge_asset_masters_attributes = tmp_array;
      },

      // 企業選択時
      onChangeCompany() {
        // 変更前の企業IDが空だった場合、確認モーダルを表示しない
        if(this.old_company_id == '') {
          this.changeCompany();
        }
        // 確認モーダルを表示
        else {
          this.$refs.reset_confirm_modal.open();
        }
      },

      // 企業変更処理
      // 企業IDが選択されている場合、大手企業情報取得、入力内容リセット
      // 選択されていない場合、入力内容リセット
      changeCompany(init_select_id) {
        // 初期選択IDが渡されてない場合はformの企業IDを使用
        let ep_company_id = init_select_id || this.form.provisional_ep_challenge.ep_company_id;

        if(ep_company_id != '') {
          this.$loading.load(
            this.$auth.api.post(
              'admin/provisional_ep_challenges/ep_company', {
                id: ep_company_id
              }
            ).then(res => {
              let ep_company = res.data.ep_company;
              this.form.provisional_ep_challenge.ep_company_name = ep_company.company_name;
              this.provisional_ep_challenge.ep_company = ep_company;
              this.ep_industry_asset_master_ids = ep_company.ep_industry_asset_master_ids;

              // 入力内容をリセット
              this.inputReset(ep_company);
            }).catch(err => {
              if (err.response.status == 500) {
                this.$errorHandlers.initial(err);
              }
              else {
                this.$errorHandlers.ajax(err);
              }
            })
          );
        }
        else {
          // 入力内容をリセット
          this.inputReset();
          this.form.provisional_ep_challenge
        }

        this.$refs.reset_confirm_modal.close();
      },

      // 入力内容をリセット
      inputReset(ep_company = null) {
        // 仮登録EPチャレンジ
        this.form.provisional_ep_challenge.title  = '';
        this.form.provisional_ep_challenge.detail = '';
        this.form.provisional_ep_challenge.challenge_stage = '';
        this.form.provisional_ep_challenge.is_sponser = 0;

        if(ep_company != null) {
          this.form.provisional_ep_challenge.ep_company_attributes.id = ep_company.id;
          // 大手企業 私たちについて
          this.form.provisional_ep_challenge.ep_company_attributes.business_summary = ep_company.business_summary;
          // 大手企業 更新日時
          this.form.provisional_ep_challenge.ep_company_attributes.updated_at = ep_company.updated_at;
        }
        else {
          this.form.provisional_ep_challenge.ep_company_attributes = {};
        }

        // 登録予定のアセット
        this.form.provisional_ep_challenge.asset_masters_attributes = [];
        // 削除アセット
        this.form.provisional_ep_challenge.destroy_provisional_ep_challenge_asset_masters_attributes = [];
        // 業種
        this.form.provisional_ep_challenge.provisional_ep_challenge_industry_masters_attributes = [];
        // 削除業界
        this.form.provisional_ep_challenge.destroy_provisional_ep_challenge_industry_masters_attributes = [];

        // メイン画像
        this.form.provisional_ep_challenge.main_image_title = '';
        // サブ1画像
        this.form.provisional_ep_challenge.sub1_image_title = '';
        // サブ2画像
        this.form.provisional_ep_challenge.sub2_image_title = '';

        // メイン画像
        this.form.provisional_ep_challenge.main_image_attributes = {};
        // サブ1画像
        this.form.provisional_ep_challenge.sub1_image_attributes = {};
        // サブ2画像
        this.form.provisional_ep_challenge.sub2_image_attributes = {};

        // メイン画像 初期化
        this.main_image.src = '';
        this.main_image.alt = '';

        // サブ1画像 初期化
        this.sub1_image.src = '';
        this.sub1_image.alt = '';

        // サブ2画像 初期化
        this.sub2_image.src = '';
        this.sub2_image.alt = '';

        // 審査ステータス
        this.form.provisional_ep_challenge.ep_challenge_attributes.exam_status = 'undecided';

        // 公開期間 開始日
        this.form.provisional_ep_challenge.ep_challenge_attributes.release_date = '';
        // 公開期間 開始時間
        this.form.provisional_ep_challenge.ep_challenge_attributes.close_date = '';
        // 公開期間 終了日
        this.form.provisional_ep_challenge.ep_challenge_attributes.release_time = '';
        // 公開期間 終了時間
        this.form.provisional_ep_challenge.ep_challenge_attributes.close_time = '';

        this.clear_validation_errors();
      },

      // 企業変更をキャンセル
      cancelChangeCompany() {
        // 選択前の値に戻す
        this.form.provisional_ep_challenge.ep_company_id = this.old_company_id;
        this.$refs.reset_confirm_modal.close();
      },

      // 対象の業界マスタが選択されているか
      isSelectedIndustryMaster(industry_master_id) {
        if (Object.keys(this.form.provisional_ep_challenge.provisional_ep_challenge_industry_masters_attributes).length == 0) {
          return false;
        }

        return !!this.form.provisional_ep_challenge.provisional_ep_challenge_industry_masters_attributes.filter(
            provisional_ep_challenge_industry_masters => provisional_ep_challenge_industry_masters.industry_master_id == industry_master_id
        ).length;
      },

      // 業界の選択肢を選択時処理
      onClickIndustryMaster(industry_master_id) {
        // 選択済み一覧から対象を検索
        let selected_industry_master =
          this.form.provisional_ep_challenge.provisional_ep_challenge_industry_masters_attributes.find(v => v.industry_master_id == industry_master_id);

        // 削除一覧から対象を検索
        let delete_industry_master =
          this.form.provisional_ep_challenge.destroy_provisional_ep_challenge_industry_masters_attributes.find(v => v.industry_master_id == industry_master_id);

        // 削除一覧に存在する
        if (!!delete_industry_master) {
          // 削除一覧から対象を削除
          this.$delete(
            this.form.provisional_ep_challenge.destroy_provisional_ep_challenge_industry_masters_attributes,
            this.form.provisional_ep_challenge.destroy_provisional_ep_challenge_industry_masters_attributes.findIndex(v => v.industry_master_id == industry_master_id)
          );

          // 選択済み一覧に対象を追加
          this.form.provisional_ep_challenge.provisional_ep_challenge_industry_masters_attributes.push({
            'id': delete_industry_master.id,
            'industry_master_id': industry_master_id,
          });

          return;
        }

        // 選択済み一覧に存在する
        if (!!selected_industry_master) {
          // 選択済み一覧から削除
          this.$delete(
            this.form.provisional_ep_challenge.provisional_ep_challenge_industry_masters_attributes,
            this.form.provisional_ep_challenge.provisional_ep_challenge_industry_masters_attributes.findIndex(v => v.industry_master_id == industry_master_id)
          );

          // 中間テーブルのIDを持っているか
          if(!!selected_industry_master.id) {
            // 削除一覧に対象を追加
            this.form.provisional_ep_challenge.destroy_provisional_ep_challenge_industry_masters_attributes.push({
              'id': selected_industry_master.id,
              'industry_master_id': selected_industry_master.industry_master_id,
              '_destroy': 1,
            });
          }

          return;
        }

        // 中間テーブルに紐づかない、未選択状態のものを選択した場合
        // 選択済み一覧に対象を追加
        this.form.provisional_ep_challenge.provisional_ep_challenge_industry_masters_attributes.push({
          'industry_master_id': industry_master_id,
        });
      },

      // チェックボックス用のパラメータ アセット
      provisional_ep_challenge_asset_master_params() {
        return this.form.provisional_ep_challenge.provisional_ep_challenge_asset_masters_attributes.map(
          provisional_ep_challenge_asset_masters => ({
            'asset_master_id': provisional_ep_challenge_asset_masters.asset_master_id
          })
        );
      },

      // チェックボックス用のパラメータ 業界
      provisional_ep_challenge_industry_master_params() {
        return this.form.provisional_ep_challenge.provisional_ep_challenge_industry_masters_attributes.map(
          provisional_ep_challenge_industry_masters => ({
            'industry_master_id': provisional_ep_challenge_industry_masters.industry_master_id
          })
        );
      },

      // 公開ステータスを更新
      updatePublicStatus() {
        this.form.provisional_ep_challenge.public_status = this.publicStatusText();
      },

      // 公開期間を比較して条件に合った公開ステータスのテキストを返却
      publicStatusText() {
        // 現在秒(ミリ秒)
        let current_time = new Date().getTime();

        // 日付の正規表現
        let date_pattern = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;
        // 時間の正規表現
        let time_pattern = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;

        let public_status_text = '';

        let release_date = this.form.provisional_ep_challenge.ep_challenge_attributes.release_date || '';
        let close_date   = this.form.provisional_ep_challenge.ep_challenge_attributes.close_date   || '';
        let release_time = this.form.provisional_ep_challenge.ep_challenge_attributes.release_time || '';
        let close_time   = this.form.provisional_ep_challenge.ep_challenge_attributes.close_time   || '';

        // 日付のフォーマットチェック
        release_date = release_date.match(date_pattern) ? release_date : null;
        close_date   = close_date.match(date_pattern)   ? close_date   : null;

        // 時間のフォーマットチェック
        release_time = release_time.match(time_pattern) ? release_time : null;
        close_time   = close_time.match(time_pattern)   ? close_time   : null;

        let release_at = null;
        let close_at = null;

        // 日付と時間を結合して開始日時作成
        // どちらか一方がnullの場合、開始日時はnull
        if(release_date != null && release_time != null) {
          let release_at_text = `${release_date} ${release_time}`;
          release_at = Date.parse(release_at_text);
        }

        // 日付と時間を結合して終了日時作成
        // どちらか一方がnullの場合、終了日時はnull
        if(close_date != null && close_time != null) {
          let close_at_text = `${close_date} ${close_time}`;
          close_at = Date.parse(close_at_text);
        }

        // 公開開始日時がnil または 現在日時が公開開始日時より未来
        if(release_at == null || release_at > current_time) {
          public_status_text = '公開待ち';
        }
        // 公開開始日時が現在日時より過去 かつ 公開終了日時がnil または 公開終了日時が現在日時より未来
        else if(release_at <= current_time && close_at == null || close_at >= current_time) {
          public_status_text = '公開';
        }
        else {
          public_status_text = '期限切れ';
        }

        return public_status_text;
      },

      title() {
        let title = '';
        if(!!this.form.provisional_ep_challenge.id) {
          title = 'チャレンジ編集';
        }
        else {
          title = 'チャレンジ作成';
        }

        return title;
      },

      // アセットのエラーメッセージの配列からIndexで検索
      assetMasterErrorMessagesIndexIs(i) {
        var error_message = this.asset_master_error_messages.find((error_messages) => error_messages.error_message_index === i);
        return !!error_message ? error_message : { error_messages: [] };
      },

      // 公開ステータス、公開期間 表示非表示判定
      isDispPublicArea() {
        let result = false;

        // EPチャレンジが紐づいている
        if(!!this.provisional_ep_challenge.ep_challenge && !!this.provisional_ep_challenge.ep_challenge.id) {
          result = true;
        }
        else {
          // 審査ステータスで「承認」が選択されている
          if(this.form.provisional_ep_challenge.exam_status == 'accepted') {
            result = true;
          }
        }

        return result;
      },

      // 要修正理由 表示非表示判定
      isDispNoReason() {
        let result = false;

        // 編集時
        if(!!this.provisional_ep_challenge.id) {
          // 審査ステータスで「要修正」が選択されている
          if(this.form.provisional_ep_challenge.exam_status == 'modify') {
            result = true;
          }
          // 要修正理由が空ではない
          if(!!this.provisional_ep_challenge.no_reason) {
            result = true;
          }
        }

        return result;
      },

      // バリデーションエラーをクリア
      clear_validation_errors() {
        this.$emit('update:validation_errors', {});
        this.was_valid = true;
      },
    },
  }
</script>
