<template>
  <div>
    <section id="pic_list" class="chapter white-block mt-5">
      <h2 class="chapter-label">担当者一覧</h2>

      <template>
        <div class="btn-container edit-btn-position" v-if="current_api_v1_user.account_type == 'su_user'">
          <button @click="$router.push({ name: 'SuPersonInChargeNewForm' })" class="btn btn-green rounded-pill" type="button">新規作成</button>
        </div>
      </template>
      <div class="table-responsive">
        <table class="table table-striped table-hover" v-if="sorted_su_users">
          <thead class="thead-light">
            <tr>
              <th class="w-5">氏名</th>
              <th class="w-10"></th>
              <th class="w-10">部署名</th>
              <th class="w-15">メールアドレス</th>
              <th class="w-5"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="sorted_su_user in sorted_su_users" :key="sorted_su_user.id">
              <td>
                <img v-if="!!sorted_su_user.image_attributes && sorted_su_user.image_attributes.filename" class="thumbnail-frame img60"
                  :src="sorted_su_user.presigned_url"
                  :alt="sorted_su_user.image_attributes.filename"
                />
                <img v-else
                  class="thumbnail-frame img60"
                  src="~images/su_user_default.png"
                  alt="画像が選択されていません"
                />
              </td>
              <td>{{fullName(sorted_su_user)}}</td>
              <td><template v-if="!!sorted_su_user.department">{{sorted_su_user.department}}</template></td>
              <td>{{sorted_su_user.email}}</td>
              <td class="text-right" v-if="current_api_v1_user.account_type == 'su_person_in_charge'">
                <button class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'詳細'" @click="$router.push({name: 'SuPersonInChargeShow', params: {id: sorted_su_user.id}})" v-if="current_api_v1_user.id == sorted_su_user.id"><i class="fas fa-ellipsis-h"></i></button>
                <template>
                  <button class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'編集'" @click="$router.push({name: 'SuPersonInChargeEditForm', params: {id: sorted_su_user.id}})" v-if="current_api_v1_user.id == sorted_su_user.id"><i class="fas fa-pen"></i></button>
                </template>
              </td>
              <td class="text-right" v-else>
                <button class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'詳細'" @click="$router.push({name: 'SuPersonInChargeShow', params: {id: sorted_su_user.id}})"><i class="fas fa-ellipsis-h"></i></button>
                <template>
                  <button class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'編集'" @click="$router.push({name: 'SuPersonInChargeEditForm', params: {id: sorted_su_user.id}})"><i class="fas fa-pen"></i></button>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <!--担当者一覧-->
  </div>

</template>

<script>

  export default {

    props: {
      baseApiUrl: {
        type: String
      },
      su_company_id: {
        type: [Number,String]
      },
    },

    // propsを多重に経由してるせいかcreatedのタイミングではsu_company_idがundifindになのでwatchで起動
    watch: {
      su_company_id(new_su_company_id,old_su_company_id){
        if (!!new_su_company_id) {
          this.getUsers();
        }
      },
    },

    data(){
      return {
        sorted_su_users: [],
        current_api_v1_user: {},
      }
    },
    methods: {
      fullName: function (user) {
        return `${user.last_name} ${user.first_name}`
      },
      getUsers(){
        this.$loading.load(this.$auth.api.get(`${this.baseApiUrl}/su_companies/edit`, {
        })
        .then(res => {
          this.sorted_su_users = res.data.sorted_su_users
          this.current_api_v1_user = res.data.current_api_v1_user
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
      },
    },
    created: function(){
      if (!!this.su_company_id) {
        this.getUsers();
      }
    },
    beforUpdate: function(){
      this.getUsers();
    },
  }

</script>
