<template>
  <div id="app">
    <main>
      <section class="main-title">
        <h1>{{title()}}</h1>
      </section>

      <form class="needs-validation" autocomplete="off" @submit.prevent>

        <!-- 最新情報情報-->
        <section class="chapter white-block">

          <div class="form-wrap">
            <!-- 企業 -->
            <div class="form-group">
              <label class="required-addon">企業名<span class="badge badge-danger">必須</span></label>
              <template v-if="!provisional_su_latest_info.id">
                <v-select
                  v-model="form.provisional_su_latest_info.su_company_id"
                  :options="su_companies"
                  label="company_name"
                  :reduce="su_company => su_company.id"
                  :state="checkValid('su_company_id')"
                  :clearable="false"
                  :placeholder="'選択してください'"
                  @option:selected="onChangeCompany()">
                  <template #no-options="{ search, searching, loading }">
                    対象の企業が見つかりませんでした
                  </template>
                </v-select>
              </template>
              <template v-else>
                <div class="chosen-container chosen-container-single chosen-disabled" title="" id="company_name_chosen" style="width: 1538px;">
                  <a class="chosen-single chosen-default">
                    <span>{{form.provisional_su_latest_info.su_company_name}}</span>
                    <div><b></b></div>
                  </a>
                </div>
              </template>
              <div v-for="error_message in validation_errors.su_company_id" :key="error_message" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- タイトル -->
            <div class="form-group">
              <label class="required-addon" for="title">タイトル<span class="badge badge-danger">必須</span></label>
              <b-form-input
                v-model="form.provisional_su_latest_info.title"
                :state="checkValid('title')"
                type="text"
                placeholder="例) 訪日外国人向けに地域の観光資源を活用した新規事業を立ちあげたい"
                class="form-control">
              </b-form-input>
              <small class="form-text text-right text-secondary">200文字以内</small>
              <div v-for="error_message in validation_errors.title" :key="error_message" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- 本文 -->
            <div class="form-group">
              <label class="required-addon" for="comment">本文
                <span class="badge badge-danger">必須</span>
              </label>
              <b-form-textarea
                v-model="form.provisional_su_latest_info.detail"
                :state="checkValid('detail')"
                type="text"
                placeholder="例) 高齢化に伴う人口減少による都市の利便性低下を改善するために、県内の特定の地域を対象としたコンパクトシティの開発を、我々のイニシアティブで進めたいと思います。住民だけでなく、建設業者・ディベロッパー・MaaS事業者（交通事業者）・スーパー・医療・介護事業者などが都市に集積することを目標とします。"
                class="form-control auto-resize h-min235">
              </b-form-textarea>
              <div v-for="error_message in validation_errors.detail" :key="error_message" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- アセット -->
            <div class="form-group pb-2">
              <h3 class="chapter-label mb-3 required-addon">アセット<span class="badge badge-danger">必須</span></h3>
              <div class="card">
                <!-- 関連アセット-->
                <div class="card-header py-3">
                  <label>関連アセットを選択<small class="text-secondary ml-lg-4">協業先から提供してもらいたいアセットを選択してください</small></label>
                  <div class="btn-group-toggle my-2 overflow-auto h-max400" data-toggle="button">
                    <label
                      v-for="asset_master in asset_masters"
                      v-show="asset_master.is_su_main_industry || isInitAssetMaster(asset_master.id) || isSelectedAssetMaster(asset_master.id)"
                      :key="asset_master.id"
                      class="btn button-square btn-check btn-checkbox btn-sm"
                      :class="isSelectedAssetMaster(asset_master.id) ? 'active' : ''"
                    >
                      {{ asset_master.name }}<i class="fas fa-check-circle"></i>
                      <input
                        type="checkbox"
                        class="form-check-input"
                        v-model="provisional_su_latest_info_asset_master_params"
                        :id="'asset_master_' + asset_master.id"
                        :value="{'asset_master_id': asset_master.id}"
                        @click.prevent.stop="onClickAssetMaster(asset_master.id, asset_master.relation_id)"
                      >
                    </label>
                  </div>

                  <div class="btn-container">
                    <button
                      class="button-square btn-sm btn-send"
                      type="button"
                      @click="$refs.other_assets_select_modal.open()"
                    >
                      {{ 'アセットを探す' }}
                      <i class="fas fa-clone pl-2"></i>
                    </button>
                  </div>

                  <div v-for="error_message in validation_errors.provisional_su_latest_info_asset_masters" :key="error_message" class="invalid-feedback" style="display: block">
                    {{ error_message }}
                  </div>
                </div>
                <!-- / 関連アセット-->

                <!-- 検索・新規作成-->
                <div class="card-body">
                  <label>新規作成<small class="text-secondary ml-lg-4">該当するアセットがない場合は新規作成する</small></label>
                  <div class="new-assets">
                    <div class="incremental-wrap">
                      <input v-model="asset_name" class="form-control" type="text" placeholder="" value="" >
                    </div>
                    <button @click="onNewAsset" class="button-square btn-send" :class="{'disabled': !asset_name}" type="button" data-toggle="modal" data-target="#market-setting">作成</button>
                  </div>
                </div>
                <!-- /検索・新規作成-->

                <!-- 登録予定のアセット-->
                <div class="card-body">
                  <label>登録予定のアセット</label>
                  <div>
                    <template v-for="(asset_master, index) in form.provisional_su_latest_info.asset_masters_attributes">
                      <div class="assets-labels mt-0" v-if="!asset_master._destroy || asset_master._destroy != 1" :key="asset_master.id">
                        <!-- 表示 -->
                        <div class="d-flex" :class="!asset_master.is_edit ? 'd-show' : 'd-none'">
                          <p class="task-labels-parent pl-0">{{ asset_master.name }}</p>
                          <button @click="onEditAssetMaster(asset_master)" class="btn-action mx-2 js-switch-trigger" type="button">
                            <i class="fas fa-pen" v-b-tooltip.hover="'編集'"></i>
                          </button>
                          <button @click="onDeleteAssetMaster(asset_master)" class="btn-action" type="button">
                            <i class="fas fa-trash" v-b-tooltip.hover="'削除'"></i>
                          </button>
                        </div>
                        <!-- 編集 -->
                        <div class="assets-edit-wrap" :class="asset_master.is_edit ? 'd-show' : 'd-none'">
                          <div class="incremental-wrap">
                            <input v-model="edit_form_asset_master.name" class="form-control" type="text">
                          </div>
                          <div class="btn-container d-flex justify-content-end mt-2">
                            <button @click="onCancelAsset(asset_master)" class="button-square btn-cancel btn-sm" type="button">キャンセル</button>
                            <button @click="onSaveAsset(asset_master)" :class="{'disabled': edit_form_asset_master.name == asset_master.name}" class="button-square btn-send btn-sm" type="button">保存</button>
                          </div>
                        </div>
                        <!-- エラーメッセージ -->
                        <div
                          v-for="error_message in assetMasterErrorMessagesIndexIs(index).error_messages"
                          :key="error_message"
                          class="invalid-feedback"
                          style="display:block"
                        >
                          {{ error_message }}
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <!-- / 登録予定のアセット-->
              </div>
            </div>

            <!-- メイン画像 アップロード -->
            <div class="form-group">
              <label class="required-addon">メインビジュアル
                <span class="badge badge-secondary">任意</span>
                <i class="fas fa-info-circle icon-gray mx-1" id="main-image-tooltip"></i>
                <b-tooltip target="main-image-tooltip" triggers="hover">
                  推奨サイズ：横1280px、縦780px
                </b-tooltip>
              </label>
              <!-- 画像 -->
              <div class="drop_area" @dragleave.prevent @dragover.prevent @dragend.prevent @drop.prevent="dropFile($event, form.provisional_su_latest_info.main_image_attributes, main_image)">
                <!-- 新規画像 か 保存されている画像 -->
                <img v-if="!!main_image.src"
                  class="img320"
                  :src="main_image.src"
                  :alt="main_image.alt"
                />
                <!-- デフォルト画像 -->
                <img v-else
                  class="img320"
                  src="~images/image_default.jpg"
                  alt=""
                />
              </div>
              <!-- ボタン -->
              <div class="d-flex mt-2">
                <div class="btn-group" role="group" aria-label="画像アップロード">
                  <button
                      v-if="!!main_image.src"
                      @click="removeImageFile(form.provisional_su_latest_info.main_image_attributes, main_image, $refs.main_image_upload)"
                      class="btn btn-cancel icon-addon-left px-3"
                      type="button"
                      data-toggle="modal"
                      data-target="#delete"
                    >
                    <i class="fas fa-times"></i>
                    削除
                  </button>
                  <label class="btn btn-file icon-addon-left px-3" for="main-image-upload">
                    <i class="fas fa-arrow-up"></i>アップロード
                    <input
                      type="file"
                      id="main-image-upload"
                      ref="main_image_upload"
                      :accept="accept_extensions"
                      @change="onImageFileChange($event, form.provisional_su_latest_info.main_image_attributes, main_image)"
                    >
                  </label>
                </div>
                <small class="form-text text-right text-secondary ml-2">画像形式：jpg、png（2MBまで）</small>
              </div>
              <!-- エラーメッセージ -->
              <template v-if="!!validation_errors.main_image">
                <div v-for="error_message in validation_errors.main_image" :key="error_message" class="invalid-feedback" style="display: block">
                  {{ error_message }}
                </div>
              </template>
              <template v-else-if="!!validation_errors.main_image_name">
                <div v-for="error_message in validation_errors.main_image_name" :key="error_message" class="invalid-feedback" style="display: block">
                  {{ error_message }}
                </div>
              </template>
              <div v-for="error_message in validation_errors.main_image_size" :key="error_message" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- メイン画像 タイトル -->
            <div class="form-group">
              <label class="required-addon" for="mainvisual-title">メインビジュアル タイトル<span class="badge badge-secondary">任意</span></label>
              <b-form-input
                v-model="form.provisional_su_latest_info.main_image_title"
                :state="checkValid('main_image_title')"
                type="text"
                placeholder="例) 地方の女性をターゲット"
                class="form-control">
              </b-form-input>
              <div v-for="error_message in validation_errors.main_image_title" :key="error_message" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- サブ1画像 アップロード -->
            <div class="form-group">
              <label class="required-addon">イメージ1
                <span class="badge badge-secondary">任意</span>
                <i class="fas fa-info-circle icon-gray mx-1" id="sub1-image-tooltip"></i>
                <b-tooltip target="sub1-image-tooltip" triggers="hover">
                  推奨サイズ：横1280px、縦780px
                </b-tooltip>
              </label>
              <!-- 画像 -->
              <div class="drop_area" @dragleave.prevent @dragover.prevent @dragend.prevent @drop.prevent="dropFile($event, form.provisional_su_latest_info.sub1_image_attributes, sub1_image)">
                <template>
                  <!-- 新規画像 か 保存されている画像 -->
                  <img v-if="!!sub1_image.src"
                    class="img320"
                    :src="sub1_image.src"
                    :alt="sub1_image.alt"
                  />
                  <!-- デフォルト画像 -->
                  <img v-else
                    class="img320"
                    src="~images/image_default.jpg"
                    alt=""
                  />
                </template>
              </div>
              <!-- ボタン -->
              <div class="d-flex mt-2">
                <div class="btn-group" role="group" aria-label="画像アップロード">
                  <button
                      v-if="!!sub1_image.src"
                      @click="removeImageFile(form.provisional_su_latest_info.sub1_image_attributes, sub1_image, $refs.sub1_image_upload)"
                      class="btn btn-cancel icon-addon-left px-3"
                      type="button"
                      data-toggle="modal"
                      data-target="#delete"
                    >
                    <i class="fas fa-times"></i>
                    削除
                  </button>
                  <label class="btn btn-file icon-addon-left px-3" for="sub1-image-upload">
                    <i class="fas fa-arrow-up"></i>アップロード
                    <input
                      type="file"
                      id="sub1-image-upload"
                      ref="sub1_image_upload"
                      :accept="accept_extensions"
                      @change="onImageFileChange($event, form.provisional_su_latest_info.sub1_image_attributes, sub1_image)"
                    >
                  </label>
                </div>
                <small class="form-text text-right text-secondary ml-2">画像形式：jpg、png（2MBまで）</small>
              </div>
              <!-- エラーメッセージ -->
              <span v-if="!!validation_errors.sub1_image">
                <div v-for="error_message in validation_errors.sub1_image" :key="error_message" class="invalid-feedback" style="display: block">
                  {{ error_message }}
                </div>
              </span>
              <span v-else-if="!!validation_errors.sub1_image_name">
                <div v-for="error_message in validation_errors.sub1_image_name" :key="error_message" class="invalid-feedback" style="display: block">
                  {{ error_message }}
                </div>
              </span>
              <div v-for="error_message in validation_errors.sub1_image_size" :key="error_message" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- サブ1画像 タイトル -->
            <div class="form-group">
              <label class="required-addon" for="subimage1-title">イメージ1 タイトル<span class="badge badge-secondary">任意</span></label>
              <b-form-input
                v-model="form.provisional_su_latest_info.sub1_image_title"
                :state="checkValid('sub1_image_title')"
                type="text"
                placeholder=""
                class="form-control">
              </b-form-input>
              <div v-for="error_message in validation_errors.sub1_image_title" :key="error_message" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- サブ2画像 アップロード -->
            <div class="form-group">
              <label class="required-addon">イメージ2
                <span class="badge badge-secondary">任意</span>
                <i class="fas fa-info-circle icon-gray mx-1" id="sub2-image-tooltip"></i>
                <b-tooltip target="sub2-image-tooltip" triggers="hover">
                  推奨サイズ：横1280px、縦780px
                </b-tooltip>
              </label>
              <!-- 画像 -->
              <div class="drop_area" @dragleave.prevent @dragover.prevent @dragend.prevent @drop.prevent="dropFile($event, form.provisional_su_latest_info.sub2_image_attributes, sub2_image)">
                <template>
                  <!-- 新規画像 か 保存されている画像 -->
                  <img v-if="!!sub2_image.src"
                    class="img320"
                    :src="sub2_image.src"
                    :alt="sub2_image.alt"
                  />
                  <!-- デフォルト画像 -->
                  <img v-else
                    class="img320"
                    src="~images/image_default.jpg"
                    alt=""
                  />
                </template>
              </div>
              <!-- ボタン -->
              <div class="d-flex mt-2">
                <div class="btn-group" role="group" aria-label="画像アップロード">
                  <button
                      v-if="!!sub2_image.src"
                      @click="removeImageFile(form.provisional_su_latest_info.sub2_image_attributes, sub2_image, $refs.sub2_image_upload)"
                      class="btn btn-cancel icon-addon-left px-3"
                      type="button"
                      data-toggle="modal"
                      data-target="#delete"
                    >
                    <i class="fas fa-times"></i>
                    削除
                  </button>
                  <label class="btn btn-file icon-addon-left px-3" for="sub2-image-upload">
                    <i class="fas fa-arrow-up"></i>アップロード
                    <input
                      type="file"
                      id="sub2-image-upload"
                      ref="sub2_image_upload"
                      :accept="accept_extensions"
                      @change="onImageFileChange($event, form.provisional_su_latest_info.sub2_image_attributes, sub2_image)"
                    >
                  </label>
                </div>
                <small class="form-text text-right text-secondary ml-2">画像形式：jpg、png（2MBまで）</small>
              </div>
              <!-- エラーメッセージ -->
              <span v-if="!!validation_errors.sub2_image">
                <div v-for="error_message in validation_errors.sub2_image" :key="error_message" class="invalid-feedback" style="display: block">
                  {{ error_message }}
                </div>
              </span>
              <span v-else-if="!!validation_errors.sub2_image_name">
                <div v-for="error_message in validation_errors.sub2_image_name" :key="error_message" class="invalid-feedback" style="display: block">
                  {{ error_message }}
                </div>
              </span>
              <div v-for="error_message in validation_errors.sub2_image_size" :key="error_message" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- サブ2画像 タイトル -->
            <div class="form-group">
              <label class="required-addon" for="subimage2-title">イメージ2 タイトル<span class="badge badge-secondary">任意</span></label>
              <b-form-input
                v-model="form.provisional_su_latest_info.sub2_image_title"
                :state="checkValid('sub2_image_title')"
                type="text"
                placeholder=""
                class="form-control">
              </b-form-input>
              <div v-for="error_message in validation_errors.sub2_image_title" :key="error_message" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- ピッチ資料 -->
            <div class="drop_area" @dragover.prevent="onArea" @drop.prevent="dropPitchFile" @dragleave.prevent="offArea" @dragend.prevent="offArea">
              <label class="required-addon">ピッチ資料<span class="badge badge-secondary">任意</span></label>
              <div v-if="form.provisional_su_latest_info.pitch_file_attributes.filename">{{ form.provisional_su_latest_info.pitch_file_attributes.filename }}</div>
              <div v-else-if="form.provisional_su_latest_info.uploaded_pitch_file">{{ form.provisional_su_latest_info.new_pitch_file_name }}</div>
              <div v-else >なし</div>
              <p v-if="!!errors['new_pitch_file_name']" class="invalid-feedback d-block">{{ errors['new_pitch_file_name'][0]}}</p>
              <p v-if="!!errors['pitch_file_size']" class="invalid-feedback d-block">{{ errors['pitch_file_size'][0]}}</p>
              <div class="btn-group row" role="group" aria-label="ピッチ資料アップロード">
                <button @click="removePitchFile" class="btn btn-cancel icon-addon-left col-4" type="button" data-toggle="modal" data-target="#delete"><i class="fas fa-times"></i>削除</button>
                <label class="btn btn-file icon-addon-left col-8" for="pitch_file-upload"><i class="fas fa-arrow-up"></i>アップロード
                  <input type="file" id="pitch_file-upload" :accept="pitch_file_accept_extensions" @change="onPitchFileChange" ref="pitchFileUploader">
                </label>
              </div>
            </div>
          </div>
        </section>
        <!-- / 最新情報情報-->

        <!-- 企業情報-->
        <section class="chapter white-block">
          <h2 class="chapter-label">企業情報（私たちについて）</h2>
          <div class="form-wrap border-top">
            <div class="form-group">
              <label class="required-addon" for="company-data">会社説明<span class="badge badge-danger">必須</span></label>
              <b-form-textarea
                v-model="form.provisional_su_latest_info.su_company_attributes.business_summary"
                :state="checkValid('su_company.business_summary')"
                type="text"
                placeholder=""
                class="form-control auto-resize">
              </b-form-textarea>
              <small class="form-text text-right text-secondary">500文字以内</small>
              <div v-if="!!validation_errors['su_company.business_summary']" class="invalid-feedback" style="display: block">
                {{ validation_errors['su_company.business_summary'][0] }}
              </div>
            </div>
          </div>
        </section>
        <!-- 企業情報-->

        <!-- 審査詳細 -->
        <section class="chapter white-block">
          <h2 class="chapter-label mb-3">審査詳細</h2>
          <div class="form-wrap border-top">
            <div class="form-row">
              <div class="form-group col-4">
                <label>ステータス</label>
                <b-select v-model="form.provisional_su_latest_info.exam_status" :state="checkValid('exam_status')" class="custom-select">
                  <option v-for="(exam_statuses_i18n, exam_status_key) in exam_statuses" :key="exam_status_key" :value="exam_status_key" v-if="exam_status_key != 'draft'">
                    {{exam_statuses_i18n}}
                  </option>
                </b-select>
                <div v-for="error_message in validation_errors.exam_status" :key="error_message" class="invalid-feedback" style="display: block">
                  {{ error_message }}
                </div>
              </div>
            </div>

            <div v-show="isDispNoReason()">
              <div class="form-group">
                <label>要修正理由</label>
                <b-form-textarea
                  v-model="form.provisional_su_latest_info.no_reason"
                  :state="checkValid('no_reason')"
                  type="text"
                  placeholder=""
                  class="form-control auto-resize">
                </b-form-textarea>
                <div v-if="!!validation_errors['no_reason']" class="invalid-feedback" style="display: block">
                  {{ validation_errors['no_reason'][0] }}
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- / 審査詳細-->

        <div class="btn-container d-flex justify-content-center">
          <button @click="onClickBack()" class="button-square btn-cancel" type="button">もどる</button>
          <button class="button-square btn-save" @click="onClickTemporarily" type="button" v-if="!provisional_su_latest_info.id || provisional_su_latest_info.is_draft">一時保存する</button>
          <button class="button-square btn-send" @click="onClickConfirm" type="button">確認する</button>
        </div>
      </form>

      <ConfirmModal
        ref="back_confirm_modal"
        :onClickSend="onBack"
        :body_text="'入力内容を破棄して戻ります。<br>よろしいですか？'"
        :send_button_text="'戻る'"
        :isNoFade="isNoFade"
      />

      <ConfirmModal
        ref="reset_confirm_modal"
        :onClickSend="changeCompany"
        :onClickCancel="cancelChangeCompany"
        :body_text="'企業を変更したため、入力内容を一度クリアします。<br>よろしいですか？'"
        :send_button_text="'実行する'"
        :isNoFade="isNoFade"
      />

      <!-- アセット検索モーダル-->
      <AssetsSelectModal
        ref="other_assets_select_modal"
        :onClickSend="onSelectedOtherAssets"
        :isNoFade="isNoFade"
        :asset_masters="asset_masters"
      />

    </main>
  </div>
</template>

<script>
  import Form from '../../../common/mixins/form'
  // 確認モーダルのコンポーネント読み込み
  import ConfirmModal from '../../../common/pages/ConfirmModal.vue';
  // アップロード画像チェック
  import UploadFileCheck  from "../../../common/mixins/upload_file_check"
  // アセット検索モーダルのコンポーネント読み込み
  import AssetsSelectModal     from '../../../common/pages/assetsSelectModal';

  export default {
    mixins: [Form, UploadFileCheck],

    props: {
      // 登録編集対象の仮登録SU最新情報
      provisional_su_latest_info: Object,
      // スタートアップ企業一覧
      su_companies: {
        type: Array,
        default: () => ([])
      },
      // アセットマスタ
      asset_masters: {
        type: Array,
        default: () => ([])
      },
      // 審査ステータス一覧
      exam_statuses: {
        type: Object,
        default: () => ({})
      },
      // 許容拡張子
      accept_extensions: {
        type: Array,
        default: () => ([])
      },
      // 許容するピッチファイルの拡張子
      pitch_file_accept_extensions: {
        type: Array,
        default: () => ([])
      },
      // フォームの内容
      form: {
        type: Object,
        default: () => ({})
      },
      // 確認ボタン押下時のコールバックメソッド
      onConfirm: {
        type: Function,
      },
      // 一時保存ボタン押下時のコールバックメソッド
      onTemporarily: {
        type: Function,
      },
      // 戻る確認モーダル内の戻るボタン押下時のコールバックメソッド
      onBack: {
        type: Function,
      },
      // バリデーションエラー
      validation_errors: {
        type: Object,
        default: () => ({})
      },
      // メイン画像(表示用)
      main_image: {
        type: Object,
        default: () => ({})
      },
      // サブ1画像(表示用)
      sub1_image: {
        type: Object,
        default: () => ({})
      },
      // サブ2画像(表示用)
      sub2_image: {
        type: Object,
        default: () => ({})
      },
      // 入力フォームが変更されたか
      is_form_changed: {
        type: Boolean,
        default: false
      },
      isNoFade: {
        type: Boolean,
      }
    },

    components: {
      ConfirmModal,
      AssetsSelectModal,
    },

    data() {
      return {
        // アセット名の入力値
        asset_name: '',
        // アセットの検索候補
        pick_assets: [],
        // SU企業検索候補
        pick_su_companies: [],
        // SU企業の業界に紐づく承認済みアセットのIDs
        industry_asset_master_ids: [],
        // アセット編集用
        edit_form_asset_master: {
          id: null,
          name: '',
        },
        // 前企業ID
        old_company_id: '',
        asset_master_error_messages: [],
      }
    },

    watch: {
      validation_errors(new_validation_errors, old_validation_errors) {
        // 値をpostする形にするため編集をコピー
        let post_form = JSON.parse(JSON.stringify(this.form));

        // 登録するアセットと削除するアセットのパラメータを結合
        let provisional_su_latest_info_asset_masters_attributes = post_form.provisional_su_latest_info.provisional_su_latest_info_asset_masters_attributes
        let destroy_provisional_su_latest_info_asset_masters_attributes = post_form.provisional_su_latest_info.destroy_provisional_su_latest_info_asset_masters_attributes
        let concat_provisional_su_latest_info_asset_masters_attributes = provisional_su_latest_info_asset_masters_attributes.concat(destroy_provisional_su_latest_info_asset_masters_attributes);
        // 選択されたアセットの件数を取得
        // 新規で選択されたアセットは除外
        let provisional_su_latest_info_asset_masters_length = concat_provisional_su_latest_info_asset_masters_attributes.filter(
            concat_provisional_su_latest_info_asset_masters_attribute => !!concat_provisional_su_latest_info_asset_masters_attribute.id
        ).length;

        this.asset_master_error_messages = [];

        this.form.provisional_su_latest_info.asset_masters_attributes.forEach((asset_masters_attributes, index) => {
          // 新規登録
          if(!this.provisional_su_latest_info.id) {
            var error_messages = new_validation_errors[`asset_masters[${index}].name`]
            this.asset_master_error_messages.push({
              error_message_index: index,
              error_messages: error_messages
            });
          }
          // 編集
          else {
            var error_messages = new_validation_errors[`asset_masters[${provisional_su_latest_info_asset_masters_length + index}].name`]
            this.asset_master_error_messages.push({
              error_message_index: index,
              error_messages: error_messages
            });
          }
        });

        // エラー時ページトップにスクロール
        if (Object.keys(new_validation_errors).length > 0) {
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        };
      },
      'form.provisional_su_latest_info.su_company_id'(new_company_id, old_company_id) {
        this.old_company_id = old_company_id;
        if(this.old_company_id == ''){
          this.old_company_id = new_company_id;
        }
      },
    },

    // インスタンス生成後、リアクティブデータ初期化後
    created(){
    },

    // DOM処理完了後
    mounted(){
      // 初期選択IDがパラメータで渡された場合、企業名を初期選択
      if(!!this.$route.params.init_select_id){
        this.changeCompany(this.$route.params.init_select_id)
      }
    },

    // データ更新時の再描画後に実行
    updated(){
      // テキストエリア 改行時高さ自動調整
      this.setTextAreaAutoSize();
    },
    // メソッド定義
    methods: {
      // 初期化
      init() {
      },

      // 一時保存ボタン押下時処理
      onClickTemporarily() {
        if( this.asset_name != '' || this.edit_form_asset_master.name != '' ) {
          this.$bvToast.toast('アセットが編集中です。編集を完了してください。', {
            variant: 'danger',
            title: 'エラー'
          });
        } else {
          this.onTemporarily();
        }
      },

      // 戻るボタン押下時処理
      onClickBack(){
        // フォームを変更されている場合は確認モーダルを表示
        this.is_form_changed ? this.$refs.back_confirm_modal.open() : this.onBack();
      },

      // 確認ボタン押下時処理
      onClickConfirm() {
        // 編集中のアセットが存在する場合、エラーメッセージを表示
        if( this.asset_name != '' || this.edit_form_asset_master.name != '' ) {
          this.$bvToast.toast('アセットが編集中です。編集を完了してください。', {
            variant: 'danger',
            title: 'エラー'
          });
        } else {
          this.onConfirm();
        }
      },

      // 画像選択時処理
      onImageFileChange(event, image_attributes, disp_image) {
        let reader = new FileReader();
        let files = event.target.files || event.dataTransfer.files;

        // ファイルチェック(/common/mixins/upload_file_check.jsを参照)
        let image_file_error = this.checkUploadImage(files[0]);
        if (image_file_error != ''){
          // エラーの場合はトースト表示
          this.$bvToast.toast(image_file_error, { variant: 'danger', title: 'エラー'});
          return;
        }

        reader.onload = e => {
          image_attributes.upload_image = e.target.result;
          disp_image.src = e.target.result;
        };
        reader.readAsDataURL(files[0]);

        image_attributes.filename = files[0].name;
        image_attributes.size     = files[0].size;

        disp_image.alt = image_attributes.filename;
        // _destroy要素を削除
        delete image_attributes._destroy
      },

      // 画像ドロップ時処理
      dropFile(event, image_attributes, disp_image) {
        this.onImageFileChange(event, image_attributes, disp_image);
      },

      // 画像削除ボタン押下時処理
      removeImageFile(image_attributes, disp_image, upload_btn_refs) {
        image_attributes.filename = '';
        image_attributes.size     = '';
        image_attributes.upload_image = '';
        image_attributes._destroy = 1;
        disp_image.src = '';
        disp_image.alt = '';
        // 同じファイルの再アップロードができるようにアップロードボタンのvalueをクリアする
        upload_btn_refs.value = '';
      },

      // 企業ピッチ資料
      onPitchFileChange(e) {
        const files = e.target.files || e.dataTransfer.files;

        // ファイルチェック(/common/mixins/upload_file_check.jsを参照)
        let pitch_file_error = this.checkUploadPitch(files[0]);
        if (pitch_file_error != ''){
          // エラーの場合はトースト表示
          this.$bvToast.toast(pitch_file_error, { variant: 'danger', title: 'エラー'});
          return;
        }

        this.form.provisional_su_latest_info.pitch_file_attributes.filename = ''
        this.createPitchFile(files[0]);
        this.form.provisional_su_latest_info.new_pitch_file_name = files[0].name;
        this.form.provisional_su_latest_info.pitch_file_size = files[0].size;
      },
      createPitchFile(file) {
        const reader = new FileReader();
        reader.onload = e => {
          this.form.provisional_su_latest_info.uploaded_pitch_file = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removePitchFile() {
        this.form.provisional_su_latest_info.pitch_file_attributes.filename = '';
        this.form.provisional_su_latest_info.uploaded_pitch_file = '';
        this.form.provisional_su_latest_info.new_pitch_file_name = '';
        this.form.provisional_su_latest_info.pitch_file_size = '';
        // 一度アップしたものを取り消した後再度同じものをアップすると@changeが発火しないため、refでDOMを直接引っ張ってvalueを消す
        this.$refs.pitchFileUploader.value = '';
      },
      dropPitchFile(e) {
        this.onPitchFileChange(e)
        this.offArea()
      },

      // 対象のアセットマスタが選択されているか
      isSelectedAssetMaster(asset_master_id){
        if (Object.keys(this.form.provisional_su_latest_info.provisional_su_latest_info_asset_masters_attributes).length == 0) {
          return false;
        }

        return !!this.form.provisional_su_latest_info.provisional_su_latest_info_asset_masters_attributes.filter(
            provisional_su_latest_info_asset_masters => provisional_su_latest_info_asset_masters.asset_master_id == asset_master_id
        ).length;
      },

      // 対象のアセットマスタがindustry_asset_master_idsに含まれているか
      isInitAssetMaster(asset_master_id){
        return this.industry_asset_master_ids.includes(asset_master_id)
      },

      // アセットの選択肢を選択時処理
      onClickAssetMaster(asset_master_id) {
        // 選択済み一覧から対象を検索
        let selected_asset_master =
          this.form.provisional_su_latest_info.provisional_su_latest_info_asset_masters_attributes.find(v => v.asset_master_id == asset_master_id);

        // 削除一覧から対象を検索
        let delete_asset_master =
          this.form.provisional_su_latest_info.destroy_provisional_su_latest_info_asset_masters_attributes.find(v => v.asset_master_id == asset_master_id);

        // 削除一覧に存在する
        if (!!delete_asset_master) {
          // 削除一覧から対象を削除
          this.$delete(
            this.form.provisional_su_latest_info.destroy_provisional_su_latest_info_asset_masters_attributes,
            this.form.provisional_su_latest_info.destroy_provisional_su_latest_info_asset_masters_attributes.findIndex(v => v.asset_master_id == asset_master_id)
          );

          // 選択済み一覧に対象を追加
          this.form.provisional_su_latest_info.provisional_su_latest_info_asset_masters_attributes.push({
            'id': delete_asset_master.id,
            'asset_master_id': asset_master_id,
          });

          return;
        }

        // 選択済み一覧に存在する
        if (!!selected_asset_master) {
          // 選択済み一覧から削除
          this.$delete(
            this.form.provisional_su_latest_info.provisional_su_latest_info_asset_masters_attributes,
            this.form.provisional_su_latest_info.provisional_su_latest_info_asset_masters_attributes.findIndex(v => v.asset_master_id == asset_master_id)
          );

          // 中間テーブルのIDを持っているか
          if(!!selected_asset_master.id) {
            // 削除一覧に対象を追加
            this.form.provisional_su_latest_info.destroy_provisional_su_latest_info_asset_masters_attributes.push({
              'id': selected_asset_master.id,
              'asset_master_id': selected_asset_master.asset_master_id,
              '_destroy': 1,
            });
          }

          return;
        }

        // 中間テーブルに紐づかない、未選択状態のものを選択した場合
        // 選択済み一覧に対象を追加
        this.form.provisional_su_latest_info.provisional_su_latest_info_asset_masters_attributes.push({
          'asset_master_id': asset_master_id,
        });
      },

      // アセット検索モーダルの追加するボタン押下時処理
      onSelectedOtherAssets(selected_asset_masters_attributes) {
        // 既に選択されていたアセットマスタと結合
        this.form.provisional_su_latest_info.provisional_su_latest_info_asset_masters_attributes =
          this.form.provisional_su_latest_info.provisional_su_latest_info_asset_masters_attributes.concat(selected_asset_masters_attributes)

        // 重複排除
        this.form.provisional_su_latest_info.provisional_su_latest_info_asset_masters_attributes = Array.from(
          new Map(this.form.provisional_su_latest_info.provisional_su_latest_info_asset_masters_attributes.map((asset) => [asset.asset_master_id, asset])).values()
        );

        // 登録しようとしているアセットが削除一覧に存在する場合、削除一覧からそのアセットを削除
        this.delete_assets_from_destroy_list(selected_asset_masters_attributes);

        this.$refs.other_assets_select_modal.close();
      },

      // アセット 作成ボタン押下時処理
      onNewAsset() {
        // 入力値が空文字の場合、スルー
        if(this.asset_name.name == '') {
          return;
        }

        // 既に仮登録アセットに存在する場合、スルー
        if(this.form.provisional_su_latest_info.asset_masters_attributes.filter(asset_master => asset_master.name == this.asset_name).length != 0) {
          this.$bvToast.toast('すでに登録されているアセットのため登録できません。', {
            variant: 'danger',
            title: 'エラー'
          });

          return;
        }

        this.$loading.load(
          this.$auth.api.get(
            'admin/provisional_su_latest_infos/search_asset_name', {params: {
              name: this.asset_name
            }}
          ).then(res => {
            if(res.data.length < 1) {
              let asset_master = {
                'id': null,
                'name': this.asset_name,
                'is_edit': false,
              };
              // 登録予定のアセットに追加
              this.form.provisional_su_latest_info.asset_masters_attributes.push(asset_master);
              // アセットの入力欄を空にする
              this.asset_name = '';
              // バリデーションエラーの配列を初期化
              this.asset_master_error_messages = [];
            }
            else {
              this.$bvToast.toast('すでに登録されているアセットのため登録できません。', {
                variant: 'danger',
                title: 'エラー'
              });

              result = false;
            }
          }).catch(err => {
            if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else {
              this.$errorHandlers.ajax(err);
            }
          })
        );
      },

      // アセット編集 編集ボタン押下時処理
      onEditAssetMaster(asset_master) {
        this.edit_form_asset_master.id   = asset_master.id;
        this.edit_form_asset_master.name = asset_master.name;

        // 一旦の編集中のアセットの編集エリアを閉じる
        this.form.provisional_su_latest_info.asset_masters_attributes.forEach((asset_masters_attributes, index) => {
          asset_masters_attributes.is_edit = false;
        });

        asset_master.is_edit = true;
      },

      // アセット編集 削除ボタン押下時処理
      onDeleteAssetMaster(asset_master) {
        if(!asset_master.id) {
          // フォーム上から削除する
          this.$delete(
            this.form.provisional_su_latest_info.asset_masters_attributes,
            this.form.provisional_su_latest_info.asset_masters_attributes.findIndex(v => v.name == asset_master.name)
          );
          // バリデーションエラーの配列を初期化
          this.asset_master_error_messages = [];
        }
        else {
          // 論理削除の対象にする
          this.$set(asset_master, '_destroy', 1);
        }
      },

      // アセット編集 保存ボタン押下時処理
      onSaveAsset(asset_master) {
        // 既に仮登録アセットに存在する場合、スルー
        if(this.form.provisional_su_latest_info.asset_masters_attributes.filter(asset_masters_attributes => asset_masters_attributes.name == this.edit_form_asset_master.name).length != 0) {
          this.$bvToast.toast('すでに登録されているアセットのため登録できません。', {
            variant: 'danger',
            title: 'エラー'
          });

          return;
        }

        // 入力値が空文字の場合、スルー
        if(this.edit_form_asset_master.name == '') {
          return;
        }

        this.$loading.load(
          this.$auth.api.get(
            'admin/provisional_su_latest_infos/search_asset_name', {params: {
              name: this.edit_form_asset_master.name
            }}
          ).then(res => {
            if(res.data.length < 1) {
              asset_master.id   = this.edit_form_asset_master.id;
              asset_master.name = this.edit_form_asset_master.name;
              this.edit_form_asset_master.id   = null;
              this.edit_form_asset_master.name = '';
              asset_master.is_edit = false;
            }
            else {
              this.$bvToast.toast('すでに登録されているアセットのため登録できません。', {
                variant: 'danger',
                title: 'エラー'
              });

              result = false;
            }
          }).catch(err => {
            if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else {
              this.$errorHandlers.ajax(err);
            }
          })
        );
      },

      // アセット編集 キャンセルボタン押下時処理
      onCancelAsset(asset_master) {
        this.edit_form_asset_master.id   = null;
        this.edit_form_asset_master.name = '';
        asset_master.is_edit = false;
      },

      // 対象アセットが削除一覧に存在する場合、削除一覧からそのアセットを削除
      delete_assets_from_destroy_list(asset_masters_attributes){
        let tmp_array = [];
        for (const destroy_asset_master_attributes of this.form.provisional_su_latest_info.destroy_provisional_su_latest_info_asset_masters_attributes) {
          let flg = true;
          for (const asset_master_attributes of asset_masters_attributes){
            if (destroy_asset_master_attributes.asset_master_id == asset_master_attributes.asset_master_id){
              flg = false;
              break;
            }
          }
          if (flg){tmp_array.push(destroy_asset_master_attributes)}
        }
        this.form.provisional_su_latest_info.destroy_provisional_su_latest_info_asset_masters_attributes = tmp_array;
      },

      // 企業選択時
      onChangeCompany() {
        // 変更前の企業IDが空だった場合、確認モーダルを表示しない
        if(this.old_company_id == '') {
          this.changeCompany();
        }
        // 確認モーダルを表示
        else {
          this.$refs.reset_confirm_modal.open();
        }
      },

      // 企業変更処理
      // 企業IDが選択されている場合、大手企業情報取得、入力内容リセット
      // 選択されていない場合、入力内容リセット
      changeCompany(init_select_id) {
        let su_company_id = init_select_id || this.form.provisional_su_latest_info.su_company_id;

        if(su_company_id != '') {
          this.$loading.load(
            this.$auth.api.post(
              'admin/provisional_su_latest_infos/su_company', {
                id: su_company_id
              }
            ).then(res => {
              let su_company = res.data.su_company;
              this.form.provisional_su_latest_info.su_company_name = su_company.company_name;
              this.provisional_su_latest_info.su_company = su_company;
              this.industry_asset_master_ids = su_company.industry_asset_master_ids;

              // 入力内容をリセット
              this.inputReset(su_company);
            }).catch(err => {
              if (err.response.status == 500) {
                this.$errorHandlers.initial(err);
              }
              else {
                this.$errorHandlers.ajax(err);
              }
            })
          );
        }
        else {
          // 入力内容をリセット
          this.inputReset();
          this.form.provisional_su_latest_info
        }

        this.$refs.reset_confirm_modal.close();
      },

      // 入力内容をリセット
      inputReset(su_company = null) {
        // 仮登録SU最新情報
        this.form.provisional_su_latest_info.title  = '';
        this.form.provisional_su_latest_info.detail = '';

        if(su_company != null) {
          this.form.provisional_su_latest_info.su_company_attributes.id = su_company.id;
          // SU企業 私たちについて
          this.form.provisional_su_latest_info.su_company_attributes.business_summary = su_company.business_summary;
          // SU企業 更新日時
          this.form.provisional_su_latest_info.su_company_attributes.updated_at = su_company.updated_at;

        }
        else {
          this.form.provisional_su_latest_info.su_company_attributes = {};
        }

        // 登録予定のアセット
        this.form.provisional_su_latest_info.asset_masters_attributes = [];
        // 削除アセット
        this.form.provisional_su_latest_info.destroy_provisional_su_latest_info_asset_masters_attributes = [];

        // メイン画像
        this.form.provisional_su_latest_info.main_image_title = '';
        // サブ1画像
        this.form.provisional_su_latest_info.sub1_image_title = '';
        // サブ2画像
        this.form.provisional_su_latest_info.sub2_image_title = '';

        // メイン画像
        this.form.provisional_su_latest_info.main_image_attributes = {};
        // サブ1画像
        this.form.provisional_su_latest_info.sub1_image_attributes = {};
        // サブ2画像
        this.form.provisional_su_latest_info.sub2_image_attributes = {};

        // メイン画像 初期化
        this.main_image.src = '';
        this.main_image.alt = '';
        this.$refs.main_image_upload.value = '';

        // サブ1画像 初期化
        this.sub1_image.src = '';
        this.sub1_image.alt = '';
        this.$refs.sub1_image_upload.value = '';
        // サブ2画像 初期化
        this.sub2_image.src = '';
        this.sub2_image.alt = '';
        this.$refs.sub2_image_upload.value = '';

        this.clear_validation_errors();
      },

      // 企業変更をキャンセル
      cancelChangeCompany() {
        // 選択前の値に戻す
        this.form.provisional_su_latest_info.su_company_id = this.old_company_id;
        this.$refs.reset_confirm_modal.close();
      },

      // チェックボックス用のパラメータ アセット
      provisional_su_latest_info_asset_master_params() {
        return this.form.provisional_su_latest_info.provisional_su_latest_info_asset_masters_attributes.map(
          provisional_su_latest_info_asset_masters => ({
            'asset_master_id': provisional_su_latest_info_asset_masters.asset_master_id
          })
        );
      },

      title() {
        let title = '';
        if(!!this.form.provisional_su_latest_info.id) {
          title = '最新情報編集';
        }
        else {
          title = '最新情報作成';
        }

        return title;
      },

      // アセットのエラーメッセージの配列からIndexで検索
      assetMasterErrorMessagesIndexIs(i) {
        var error_message = this.asset_master_error_messages.find((error_messages) => error_messages.error_message_index === i);
        return !!error_message ? error_message : { error_messages: [] };
      },

      // 要修正理由 表示非表示判定
      isDispNoReason() {
        let result = false;

        // 編集時
        if(!!this.provisional_su_latest_info.id) {
          // 審査ステータスで「要修正」が選択されている
          if(this.form.provisional_su_latest_info.exam_status == 'modify') {
            result = true;
          }
          // 要修正理由が空ではない
          if(!!this.provisional_su_latest_info.no_reason) {
            result = true;
          }
        }

        return result;
      },

      // バリデーションエラーをクリア
      clear_validation_errors() {
        this.$emit('update:validation_errors', {});
        this.was_valid = true;
      },
    },
  }
</script>
