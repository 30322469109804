<template>
  <div class="main-area">
    <main>
      <section class="main-title">
        <h1>スタートアップ最新情報詳細</h1>
      </section>

      <!-- 最新情報詳細-->
      <section class="article-container mt-3">
        <article class="white-block reading-block">
          <div class="reading-visual">
            <template v-if="su_latest_info.presigned_pitch_file_url">
              <VuePdf
                :src="pitch_file_data"
                :page="current_page_num"
                @num-pages="total_page_num = $event"/>
              <div class="reading-visual-nav">
                <div class="reading-visual-nav-item">
                  <button class="reading-visual-nav-btn" @click="jumpPage(1)">
                    <i class="fas fa-angle-double-left"></i>
                  </button>
                </div>
                <div class="reading-visual-nav-item">
                  <button class="reading-visual-nav-btn" @click="jumpPage(current_page_num - 1)">
                    <i class="fas fa-angle-left"></i>
                  </button>
                </div>
                <div class="reading-visual-nav-item">{{ current_page_num }}<span> / {{ total_page_num }}</span></div>
                <div class="reading-visual-nav-item">
                  <button class="reading-visual-nav-btn" @click="jumpPage(current_page_num + 1)">
                    <i class="fas fa-angle-right"></i>
                  </button>
                </div>
                <div class="reading-visual-nav-item">
                  <button class="reading-visual-nav-btn" @click="jumpPage(total_page_num)">
                    <i class="fas fa-angle-double-right"></i>
                  </button>
                </div>
              </div>
            </template>
            <template v-else>
              <!-- メインビジュアル画像 -->
              <template v-if="!isObjectEmpty(su_latest_info.main_image)">
                <a class="img-box" :href="su_latest_info.main_image.presigned_url"
                  data-lightbox="latest_info_image" :data-title="su_latest_info.main_image_title">
                  <img
                    :src="su_latest_info.main_image.presigned_url"
                    :alt="su_latest_info.main_image.filename">
                </a>
                <div class="reading-visual-caption">{{ su_latest_info.main_image_title }}</div>
              </template>
              <template v-else>
                <a class="img-box" href="#">
                  <img
                    src="~images/image_default.jpg"
                    alt="デフォルト画像"
                  />
                </a>
              </template>
            </template>
          </div>
          <!-- 投稿日時 -->
          <div class="reading-bar">
            <small class="reading-date text-secondary">{{ su_latest_info.created_at | format.datetime }}</small>
          </div>
          <!-- タイトル -->
          <h2 class="reading-title">{{ su_latest_info.title }}</h2>
          <dl class="reading-item">
            <dt>企業情報（私たちについて）</dt>
            <dd class="u-pre-wrap" v-if="!!su_latest_info.su_company">{{ su_latest_info.su_company.business_summary }}</dd>
          </dl>
          <dl class="reading-item">
            <dt>最近何やってる？</dt>
            <dd class="u-pre-wrap">{{ su_latest_info.detail }}</dd>
          </dl>
          <dl class="reading-item" v-if="su_latest_info.sub1_image_id || su_latest_info.sub2_image_id">
            <dt>イメージ</dt>
            <dd class="d-flex">
              <!-- 画像（サブビジュアル1） -->
              <figure v-if="su_latest_info.sub1_image_id">
                <a class="img-box" :href="su_latest_info.sub1_image.presigned_url"
                  data-lightbox="latest_info_image" :data-title="su_latest_info.sub1_image_title">
                  <img
                    :src="su_latest_info.sub1_image.presigned_url"
                    :alt="su_latest_info.sub1_image.filename"
                  >
                </a>
                <figcaption class="reading-visual-caption">{{ su_latest_info.sub1_image_title }}</figcaption>
              </figure>
              <!-- 画像（サブビジュアル2） -->
              <figure class="mr-3" v-if="su_latest_info.sub2_image_id">
                <a class="img-box" :href="su_latest_info.sub2_image.presigned_url"
                  data-lightbox="latest_info_image" :data-title="su_latest_info.sub2_image_title">
                  <img
                    :src="su_latest_info.sub2_image.presigned_url"
                    :alt="su_latest_info.sub2_image.filename"
                  >
                </a>
                <figcaption class="reading-visual-caption">{{ su_latest_info.sub2_image_title }}</figcaption>
              </figure>
            </dd>
          </dl>
          <dl class="reading-item">
            <dt>パートナー企業様に求めるアセット</dt>
            <dd>
              <template v-if="su_latest_info.is_show_more_assets">
                <div v-for="(asset, index) in su_latest_info.asset_masters" :key="asset.asset_master_id" class="task-labels" v-if="index <= 9">
                  <p class="task-labels-parent">
                    {{ asset.name }}
                  </p>
                </div>
                <div class="btn-container text-right">
                  <button class="js-view-trigger btn-text d-show" type="button" @click="onClickShowMoreAssets()">
                    もっと見る
                  </button>
                </div>
              </template>
              <template v-else>
                <div v-for="asset in su_latest_info.asset_masters" :key="asset.asset_master_id" class="task-labels">
                  <p class="task-labels-parent">
                    {{ asset.name }}
                  </p>
                </div>
              </template>
            </dd>
          </dl>
          <dl class="reading-item" v-if="su_latest_info.presigned_pitch_file_url">
            <dt>会社についてもっと詳しく知りたい</dt>
            <dd>
              <a class="btn btn-big btn-green rounded-pill" :href="su_latest_info.presigned_pitch_file_url" :download="su_latest_info.presigned_pitch_file_filename">資料ダウンロード<i class="fas fa-file-download pl-1"></i></a>
            </dd>
          </dl>
          <dl class="reading-item" v-if="!!su_latest_info.su_company && !!su_latest_info.su_company.current_market_capital">
            <dt>推定時価総額</dt>
            <dd>
              <p>{{ su_latest_info.su_company.current_market_capital | format.trimFractionDigits }} 百万円</p>
            </dd>
          </dl>
          <dl class="reading-item" v-if="!!su_latest_info.su_company && !!su_latest_info.su_company.last_finance_date">
            <dt>直近ファイナンス日付</dt>
            <dd>
              <p>{{ su_latest_info.su_company.last_finance_date | format.date.ja }}</p>
            </dd>
          </dl>
          <dl class="reading-item" v-if="!!su_latest_info.su_company && !!su_latest_info.su_company.founding_date">
            <dt>創業日</dt>
            <dd>
              <p>{{ su_latest_info.su_company.founding_date | format.date.ja }}</p>
            </dd>
          </dl>

        </article>
      </section>
      <!-- / 最新情報詳細-->

      <!-- 過去の調達実績-->
      <section class="chapter white-block">
        <h2 class="chapter-label mb-3">過去の調達実績</h2>
        <div v-if="su_old_achievements.length">
          <div class="overview-list overview-list-dt15 my-3 p-3">
            <dl class="reading-item">
              <dd v-for="su_old_achievement in su_old_achievements" :key="su_old_achievement.id">
                <div class="mr-3">
                  <a href="#" style="display: inline-flex; align-items: center;" @click.prevent.stop="onClickSuOldAchievement(su_old_achievement.id)">
                    <img style="width: 16px; height: 16px; flex-shrink: 0; backface-visibility: hidden; margin-right: 5px;" src="~/images/icon_page_empty.svg" />
                    {{ su_old_achievement.supply_date | format.date.ja }}&nbsp;&nbsp;{{ su_old_achievement.amount | format.trimFractionDigits }}&nbsp;{{ su_old_achievement.unit }}
                  </a>
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </section>
      <!-- / 過去の調達実績-->

      <!-- 担当者情報 -->
      <aside class="chapter white-block mb-4">
        <h3 class="chapter-label mb-3">担当者情報</h3>
        <div class="d-flex p-3">
          <img v-if="su_latest_info.is_manager_image"
            :src="su_latest_info.presigned_manager_image_url"
            :alt="su_latest_info.presigned_manager_image_filename"
            class="thumbnail-frame img80"
          />
          <img v-else
            src="~images/su_user_default.png"
            alt="画像が選択されていません"
            class="thumbnail-frame img80"
          />
          <div class="ml-3">
            <p class="h5">{{ su_latest_info.manager_name }}</p>
            <p class="text-box mt-1 u-pre-wrap" v-text="su_latest_info.manager_profile"></p>
          </div>
        </div>
      </aside>
      <!-- / 担当者情報 -->

      <!-- ボタンエリア-->
      <div class="btn-container adjust-lotof-btn d-flex justify-content-center">
        <a class="btn btn-big btn-white rounded-pill"  @click.prevent.stop="onClickBack">戻る</a>
      </div>
      <!-- ボタンエリア-->

      <SuOldAchievementModal
        ref="show_old_achievement"
        :old_achievement=show_su_old_achievement
        :isNoFade="isNoFade"
      />

      <!-- リコメンド対応確認モーダル -->
      <ConfirmModal
          ref="choice_confirm_modal"
          :onClickSend="onChoiceSend"
          :body_text="confirm_body_text"
          :send_button_text="confirm_send_text"
          :isNoFade="isNoFade"
      />

      <!-- リコメンド対応って？ -->
      <b-modal
        v-model="recommend_send_help"
        id="recommend_send"
        aria-label="recommend-help_Label"
        title="リコメンド対応って？"
        centered
        hide-header
        hide-footer
        size="sm"
        :no-fade="isNoFade"
      >
        <div class="modal-body">
          <h3 class="chapter-label mb-3"><i class="fas fa-info-circle mr-1"></i>オファー送付とは？</h3>
          <div class="mx-auto">
            <div class="img-box"><img src="~images/recommend_reaction.svg" alt=""></div>
          </div>
          <div class="my-3">
            <p class="text-box mt-1">気になった案件に対して「オファー」を出すことで、企業からの反応を受け取る可能性が高くなります。気軽にオファーをしてみましょう！</p>
          </div>
          <div class="text-center">
            <button type="button" @click="onRecommendHelpHide()"><i class="fas fa-times"></i></button>
          </div>
        </div>
      </b-modal>

    </main>

    <!-- リコメンド対応選択エリア -->
    <div class="fixed-footer-block" v-if="!is_offered">
      <!-- リコメンド対応を選択（デフォルト）-->
      <div class="inner" v-if="recommend_choice_type == -1">
        <div class="btn-container d-flex justify-content-center align-items-center">
          <div class="chapter-label">オファーとは<a href="" @click.prevent.stop="onRecommendHelp()"><i class="fas fa-info-circle ml-1"></i></a>
          </div>
          <a class="btn btn-medium btn-green rounded-pill" href="#" @click.prevent.stop="onChoice(choiced_offer)">オファー送付</a>
        </div>
      </div>
      <!-- / リコメンド対応を選択（デフォルト）-->

      <!-- オファーを行う（オファー送付）-->
      <div class="inner" v-else-if="recommend_choice_type == choiced_offer">
        <form class="form-wrap py-1">
          <!-- オファーを出す -->
          <div class="chapter-label">オファーを出す</div>
          <div class="form-group row">
            <label class="col-2 col-form-label" for="offer-title">
              オファータイトル
              <span class="badge badge-danger ml-2">必須</span>
            </label>
            <div class="col-10 incremental-wrap">
              <input class="form-control" type="text" id="offer-title" v-model="form.offer_title">
              <div class="d-flex justify-content-between">
                <div>
                  <template v-if="!!validation_errors.ep_offer_title">
                    <p  v-for="error_message in validation_errors.ep_offer_title" :key="error_message" class="invalid-feedback d-block">
                      {{ error_message }}
                    </p>
                  </template>
                </div>
                <small class="form-text">100文字以内</small>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-2 col-form-label" for="welcome-comment">
              オファー本文
              <span class="badge badge-danger ml-2">必須</span>
            </label>
            <div class="col-10 incremental-wrap">
              <textarea class="form-control" type="text" id="welcome-comment" v-model="form.offer_detail"></textarea>
              <div class="d-flex justify-content-between">
                <div>
                  <template v-if="!!validation_errors.ep_offer_detail">
                    <p  v-for="error_message in validation_errors.ep_offer_detail" :key="error_message" class="invalid-feedback d-block">
                      {{ error_message }}
                    </p>
                  </template>
                </div>
                <small class="form-text">500文字以内</small>
              </div>
            </div>
          </div>
          <div class="btn-container d-flex justify-content-end">
            <a class="button-square btn-sm btn-cancel mr-2" @click.prevent.stop="onChoiceCancel()">戻る</a>
            <button class="button-square btn-sm btn-send" type="button" @click="onChoiceConfirm()">オファー送付</button>
          </div>
        </form>
      </div>
      <!-- / オファー・提案歓迎を行う（オファー送付）-->

    </div>
    <!-- /リコメンド対応選択エリア -->
    <FooterComponent />
  </div>
</template>

<script>
  // 確認モーダルのコンポーネント読み込み
  import SuOldAchievementModal from './SuOldAchievementModal.vue';
  // 確認モーダルのコンポーネント読み込み
  import ConfirmModal from '../../../common/pages/ConfirmModal.vue';
  // Footer
  import FooterComponent from '../../../common/layouts/footer';

  // PDFパッチ資料
  import VuePdf from 'vue-pdf';

  export default {

    components: {
      SuOldAchievementModal,
      ConfirmModal,
      FooterComponent,
      VuePdf,
    },

    metaInfo: {
      title: 'スタートアップ情報詳細',
    },

    data() {
      return {
        su_latest_info: {},
        su_old_achievements: [],
        show_su_old_achievement: {},
        is_offered: true,
        previous_route: null,
        pitch_file_data: '',
        current_page_num: 1,
        total_page_num: 0,
        cmaps_path: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.5.141/cmaps/',
        recommend_send_help: false,
        confirm_body_text: "",
        confirm_send_text: "",
        validation_errors: {},
        recommend_choice_type: -1,
        form: {
          offer_title: "",
          offer_detail: "",
          ep_recommend_updated_at: "",
          su_latest_info_updated_at: "",
        },
      }
    },

    props: { isNoFade: Boolean },

    // 計算プロパティ
    computed: {
      // 定数を定義
      choiced_offer: function() { return 11 }, // オファーレビュー待ち
    },


    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.init();
    },

    // コンポーネントを描画するルートが確立する前に呼ばれる
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.previous_route = from;
      });
    },

    // メソッド定義
    methods: {
      // 初期化
      init() {
        this.getSuLatestInfo();
      },

      jumpPage(toPage) {
        if (toPage < 1 || toPage > this.total_page_num) {
          return
        }
        this.current_page_num = toPage
      },

      getSuLatestInfo() {
        this.$loading.load(
          this.$auth.api.get(
            `ep/su_latest_infos/${this.$route.params.id}`
          ).then(res => {
            this.su_latest_info = res.data.su_latest_info;
            this.su_old_achievements = res.data.su_old_achievements;
            this.is_offered = res.data.is_offered;
            this.form.ep_recommend_updated_at = res.data.ep_recommend_updated_at;
            this.form.su_latest_info_updated_at = res.data.su_latest_info.updated_at;
            if (res.data.pitch_file_data) {
              this.pitch_file_data = VuePdf.createLoadingTask({
                data: atob(res.data.pitch_file_data),
                cMapUrl: this.cmaps_path,
                cMapPacked: true,
              });
            }
          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        );
      },

      // 過去の調達実績リンク押下時
      onClickSuOldAchievement(id) {
        this.show_su_old_achievement = this.su_old_achievements.find(v => v.id === id)
        this.$refs.show_old_achievement.open();
      },

      // 戻るボタン押下時処理
      onClickBack() {
        if (!!this.previous_route && (this.previous_route.name === 'EpSuLatestInfoIndex' || this.previous_route.name === 'EpRecommendIndex')) {
          this.$router.push({path: this.previous_route.fullPath});
        } else {
          this.$router.push({name: 'EpSuLatestInfoIndex'});
        }
      },

      isObjectEmpty(object) {
        if(!object) {
          return true;
        }
        return !(Object.keys(object).length)
      },

      // もっと見るボタン押下時
      onClickShowMoreAssets(){
        this.su_latest_info.is_show_more_assets = false;
      },

      // リコメンド対応とはモーダル
      onRecommendHelp() {
        this.recommend_send_help = true;
      },

      // リコメンド対応とはモーダル閉じる
      onRecommendHelpHide() {
        this.recommend_send_help = false;
      },

      // リコメンド対応選択時
      onChoice(type) {
        this.recommend_choice_type = type;
      },

      // リコメンド選択キャンセル時
      onChoiceCancel() {
        this.recommend_choice_type = -1;
      },

      // リコメンド対応確認モーダル
      onChoiceConfirm() {
        this.$loading.load(
          this.$auth.api.post(
            `ep/su_latest_infos/${this.$route.params.id}/update_confirm` , this.form
          ).then(res => {
            this.validation_errors = {};
            // リコメンド選択肢によりモーダル内文章を切り替え
            this.confirm_body_text = "DTVSの確認後、スタートアップにオファー情報を届けます。<br>よろしいですか？";
            this.confirm_send_text = "オファー送付";
            this.$refs.choice_confirm_modal.open();
          }).catch(err => {
            if(err.response.status == 422) {
              if (err.response.data && err.response.data.errors) {
                this.validation_errors = err.response.data.errors;
              }
            }else if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }else{
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },

      // リコメンド選択確定時
      onChoiceSend() {
        this.$loading.load(
          this.$auth.api.patch(
            `ep/su_latest_infos/${this.$route.params.id}`, this.form
          ).then(res => {
            this.$refs.choice_confirm_modal.close();
            this.$router.push({name: 'EpSuLatestInfoIndex',  query: {toast_type: 'created'}});
          }).catch(err => {
            if(err.response.status == 422) {
              if (err.response.data && err.response.data.errors) {
                this.validation_errors = err.response.data.errors;
              }
              this.$refs.choice_confirm_modal.close();
            }else if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }else{
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },

    }

  }
</script>