<template>
  <div>
    <router-view
      :user.sync="user"
      :ep_admin_email.sync="ep_admin_email"
      :accepted_extensions="accepted_extensions"
      :currentUserId="currentUserId"
      :prop_user_uploaded_image.sync="prop_user_uploaded_image"
      :prop_user_img_name.sync="prop_user_img_name">
    </router-view>
  </div>
</template>

<script>
  export default {
    data: function () {
      return {
        user: {},
        ep_admin_email: '',
        accepted_extensions: [],
        prop_user_uploaded_image: '',
        prop_user_img_name: '',
        is_company_id_illegal: false,
        is_department_id_illegal: false,
        is_current_user_id_illegal: false
      }
    },

    props: {
      currentUserId: Number,
    },

    beforeMount() {
      if(this.$route.name !== "EpPersonInChargeEditConfirm"){
        this.fetchEpPersonInCharge();
        this.$store.commit('ep_person_in_charge/initUser');
      }
    },
    methods: {
      fetchEpPersonInCharge: function() {
        this.$loading.load(this.$auth.api.get(`ep/ep_person_in_charges/${this.$route.params.id}/edit`)
        .then(res => {
          this.user = res.data.user
          this.ep_admin_email = res.data.ep_admin_email
          this.is_company_id_illegal = res.data.is_company_id_illegal
          this.is_current_user_id_illegal = res.data.is_current_user_id_illegal
          this.checkIllegalId();
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
      },
      checkIllegalId(){
        if (this.is_company_id_illegal){
          this.$router.push({name: 'EpEpCompanyShow', query: {toast_type: 'invalid_transitioned'}});
        } else if (this.is_current_user_id_illegal) {
          this.$router.push({path: '/ep/', query: {toast_type: 'invalid_transitioned'}});
        }
      },
      getUser () {
        this.user = this.$store.getters['ep_person_in_charge/User']
      },
      setEnums () {
        this.$loading.load(this.$auth.api.get(`ep/ep_person_in_charges/${this.$route.params.id}/init_edit.json`)
          .then(res => {
            this.user.ep_company_id = res.data.ep_company_id;
            this.accepted_extensions = res.data.accepted_extensions;
          })
          .catch(err => {
            this.$errorHandlers.initial(err);
          }));
      },
    },
    computed: {
      getUserUploadedImage () {
        this.prop_user_uploaded_image = this.$store.getters['ep_person_in_charge/uploadedUserImage']
      },
      getUserImgName () {
        this.prop_user_img_name = this.$store.getters['ep_person_in_charge/imgUserName']
      }
    },
    created: function(){
      this.setEnums();
      this.getUser();
      this.getUserUploadedImage;
      this.getUserImgName;
    },
  }
</script>
