<template>
  <div>
    <router-view :su_old_achievement.sync="su_old_achievement"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      su_old_achievement: {
        id: '',
        su_company_id: '',
        supply_date: '',
        amount: '',
        unit: '',
        invest_persons: [],
        investor_finance_vc: '',
        investor_cvc_company: '',
        investor_independent: '',
        investor_government: '',
        investor_international_vc: '',
        updated_at: '',
      },
    };
  },

  created: function () {
    if (this.$route.name === "AdminSuOldAchievementNewConfirm" || this.$route.name === "AdminSuOldAchievementNewForm") {
      this.su_old_achievement = this.$store.getters["su_old_achievement/SuOldAchievement"];
    } else {
      this.$store.commit("su_old_achievement/initSuOldAchievement");
      this.su_old_achievement = this.$store.getters["su_old_achievement/SuOldAchievement"];
    }
  },
};
</script>
