<template>
  <div>
    <section class="chapter white-block step-data">
      <div class="step-graph border-bottom">
        <BarChart
          :options="presentValuesBarOptions(scenario)"
          :chartData="presentValuesBarChartData(scenario)"
        />
      </div>
      <div class="step-table">
        <div class="mr-n3 -my-2 overflow-x-auto">
          <div class="pr-3 inline-block min-w-full py-2 align-middle">
            <table class="min-w-full divide-y divide-gray-300">
              <YearsThead
                :columns="yearColumns(scenario.years_custom)"
                :displayCurrencyUnit="displayCurrencyUnit(scenario)"
              />
              <tbody class="divide-y divide-gray-200 bg-white">
                <TbodyRow
                  :columns="yearColumns(scenario.years_custom)"
                  :fieldValues="calcSubtotals(scenario)"
                  fieldName="小計"
                />
                <tr class="divide-x divide-gray-200">
                  <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900">
                    割引率
                    <div
                      class="fas fa-info-circle icon-gray ml-1"
                      v-b-tooltip.hover="'各年度で発生する予定のFCFを、期待利回り率で割引計算することで、「現在いくらで投資をしたら来期以降予定されたFCFを得られるか」を計算します。'"
                    />
                  </td>
                  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                    {{ `${digitSeparator(scenario.expected_return)}%` }}
                  </td>
                </tr>
                <TbodyRow
                  :columns="yearColumns(scenario.years_custom)"
                  :fieldValues="calcPresentValueFactors(scenario)"
                  fieldName="現価係数"
                  tooltipContent="現価係数は一定期間、一定の利率で運用すると目標とする金額に到達するためには現在いくらあればよいかを求めることができる係数です。そのため、各年度で発生する予定のFCFを、現価係数で乗じることで現在の価格に割り戻すことができます。"
                />
                <TbodyRow
                  :columns="yearColumns(scenario.years_custom)"
                  :fieldValues="calcPresentValues(scenario)"
                  fieldName="Present Value"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <div class="btn-container d-flex justify-content-center">
      <router-link
        :to="{ name: 'EpScenariosShowStep3' }"
        class="btn btn-big rounded-pill btn-prev"
      >
        前のステップに戻る
      </router-link>
      <router-link
        :to="{ name: 'EpScenariosShowStep5' }"
        class="btn btn-big rounded-pill btn-next"
      >
        次のステップに進む
      </router-link>
    </div>
  </div>
</template>

<script>
  import YearsThead from './parts/YearsThead';
  import TbodyRow from './parts/TbodyRow';
  import BarChart from './parts/BarChart';
  import ScenariosShowMixins from './scenarios_show_mixins';

  export default {
    mixins: [ScenariosShowMixins, ],
    components: {
      YearsThead,
      TbodyRow,
      BarChart,
    },
    props: {
      scenario: {
        type: Object,
        required: true,
      },
    },
  };
</script>
<style src="./tailwind.css" scoped />
