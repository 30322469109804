<template>
  <div>
    <b-modal
      v-model="is_show"
      title=""
      centered
      size="lg"
      hide-header
      hide-footer
      scrollable
      :no-fade="isNoFade"
    >
      <section class="chapter white-block">
        <h2 class="chapter-label">過去の調達実績詳細</h2>
        <div class="overview-list overview-list-dt15 my-3 p-3">
          <dl class="form-confirm-group">
            <dt>調達日付</dt>
            <dd>{{old_achievement.supply_date | format.date.ja}}</dd>
          </dl>
          <dl class="form-confirm-group">
            <dt>金額</dt>
            <dd>{{old_achievement.amount | format.trimFractionDigits}}</dd>
          </dl>
          <dl class="form-confirm-group">
            <dt>単位</dt>
            <dd>{{old_achievement.unit}}</dd>
          </dl>
          <dl class="form-confirm-group">
            <dt>出資者</dt>
            <dd v-if="!old_achievement.invest_persons"></dd>
            <dd v-else>
              <p v-for="invest_person in old_achievement.invest_persons" :key="invest_person">{{invest_person}}</p>
            </dd>
          </dl>
          <dl class="form-confirm-group" v-if="old_achievement.investor_cvc_company > 0">
            <dt>投資家の数-CVC・事業会社</dt>
            <dd>{{ old_achievement.investor_cvc_company }}</dd>
          </dl>
          <dl class="form-confirm-group" v-if="old_achievement.investor_government > 0">
            <dt>投資家の数-政府系</dt>
            <dd>{{ old_achievement.investor_government }}</dd>
          </dl>
          <dl class="form-confirm-group" v-if="old_achievement.investor_international_vc > 0">
            <dt>投資家の数-海外VC</dt>
            <dd>{{ old_achievement.investor_international_vc }}</dd>
          </dl>
          <dl class="form-confirm-group" v-if="old_achievement.investor_independent > 0">
            <dt>投資家の数-独立系VC</dt>
            <dd>{{ old_achievement.investor_independent }}</dd>
          </dl>
          <dl class="form-confirm-group" v-if="old_achievement.investor_finance_vc > 0">
            <dt>投資家の数-金融系</dt>
            <dd>{{ old_achievement.investor_finance_vc }}</dd>
          </dl>
        </div>

        <div class="btn-container adjust-lotof-btn d-flex justify-content-center">
          <a class="btn btn-big btn-white rounded-pill"  @click.prevent.stop="close()">閉じる</a>
        </div>
      </section>
    </b-modal>
  </div>
</template>

<script>
  export default {
    props: {
      // 過去の調達実績
      old_achievement: {
        type: Object
      },
      isNoFade: {
        type: Boolean,
      },
    },

    data() {
      return {
        is_show: false,
      }
    },

    watch: {
      is_show(new_is_show, old_is_show) {
        if(new_is_show) {
          if(typeof this.onModalOpen == 'function') {
            this.onModalOpen();
          }
        } else {
          if(typeof this.onModalClose == 'function') {
            this.onModalClose();
          }
        }
      }
    },

    filters: {

      // 文字列を省略して表示
      omittedText(text, len) {
        return text.length > len ? text.slice(0, len) + "…" : text;
      },

    },

    methods: {
      // モーダルオープン
      open() {
        this.is_show = true;
      },

      // モーダルクローズ
      close() {
        this.is_show = false;
      },
    },
  }
</script>
