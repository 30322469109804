const initialState = () => {
  return {
    su_company: {
      company_name: '',
      company_hp: '',
      representative: '',
      prefecture_master_id: '',
      address: '',
      stage: '',
      industry_master_id: '',
      sub_industry_master_id: '',
      sub2_industry_master_id: '',
      business_summary: '',
      is_audit: '',
      is_publish_info: '',
      user_attributes: {
        first_name: '',
        last_name: '',
        kana_first_name: '',
        kana_last_name: '',
        email: '',
        position: '',
        department: '',
        profile: '',
        account_type: 'su_user',
        position: '',
        password: '',
        password_confirmation: '',
        uploaded_image: '',
        new_image_name: '',
        image_attributes: {
          filename: ''
        }
      }
    }
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    reset(state){
      Object.assign(state, initialState())
    },
    setSuCompany: function(state, data){
      state.su_company = data
    },
    initSuCompany (state) {
      state.su_company.company_name = ''
      state.su_company.company_hp = ''
      state.su_company.representative = ''
      state.su_company.prefecture_master_id = ''
      state.su_company.address = ''
      state.su_company.stage = ''
      state.su_company.industry_master_id = ''
      state.su_company.sub_industry_master_id = ''
      state.su_company.sub2_industry_master_id = ''
      state.su_company.business_summary = ''
      state.su_company.is_audit = 'unaudit'
      state.su_company.is_publish_info = 'publish'

      state.su_company.user_attributes.first_name = ''
      state.su_company.user_attributes.last_name = ''
      state.su_company.user_attributes.kana_first_name = ''
      state.su_company.user_attributes.kana_last_name = ''
      state.su_company.user_attributes.email = ''
      state.su_company.user_attributes.position = ''
      state.su_company.user_attributes.department = ''
      state.su_company.user_attributes.profile = ''
      state.su_company.user_attributes.account_type = 'su_user'
      state.su_company.user_attributes.position = ''
      state.su_company.user_attributes.password = ''
      state.su_company.user_attributes.uploaded_image = ''
      state.su_company.user_attributes.new_image_name = ''
      state.su_company.user_attributes.image_attributes = { filename: '' }
    },
    initPassword: function(state){
      state.su_company.user_attributes.password = null;
      state.su_company.user_attributes.password_confirmation = null;
    }
  },
  getters: {
    suCompany: state => {
      return state.su_company
    }
  }
}