

export default{
  data(){
    return {
      intervals: []
    }
  },
  methods: {
    setInterval(func, interval){
      const id = window.setInterval(() => {
        if (document.visibilityState === 'visible') {
          func()
        }
      }, interval);

      this.intervals.push(id);

      return id;
    },
    clearInterval(id){
      window.clearInterval(id);
      this.intervals = this.intervals.filter(i => i !== id);
    },
    clearAllIntervals(){
      this.intervals.forEach(window.clearInterval);
      this.intervals = [];
    }
  },
  beforeDestroy(){
    this.clearAllIntervals();
  }
}