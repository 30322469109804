import Login from '../common/pages/login'
import Logout from './pages/logout'
import Verify from '../common/pages/verify'
import PasswordReset from '../common/pages/password_reset'
import PasswordResetSent from '../common/pages/password_reset/sent'
import PasswordResetNewPassword from '../common/pages/password_reset/new_password'
import Unlock from '../common/pages/unlock'

import EpCompanyShow from './pages/ep_companies/Show.vue'
import EpCompanyEdit from './pages/ep_companies/Edit.vue'
import EpCompanyEditForm from './pages/ep_companies/EditForm.vue'
import EpCompanyEditConfirm from './pages/ep_companies/EditConfirm.vue'

import EpPersonInChargeNew from './pages/ep_person_in_charges/New.vue'
import EpPersonInChargeNewForm from './pages/ep_person_in_charges/Form.vue'
import EpPersonInChargeNewConfirm from './pages/ep_person_in_charges/Confirm.vue'
import EpPersonInChargeEdit from './pages/ep_person_in_charges/Edit.vue'
import EpPersonInChargeEditForm from './pages/ep_person_in_charges/EditForm.vue'
import EpPersonInChargeEditConfirm from './pages/ep_person_in_charges/EditConfirm.vue'
import EpPersonInChargeShow from './pages/ep_person_in_charges/Show.vue'

import RegistrationAuthInfo from '../common/pages/registration_auths/Info.vue'
import RegistrationAuthNew from '../common/pages/registration_auths/New.vue'
import RegistrationAuthShow from '../common/pages/registration_auths/Show.vue'

import RegistrationRequestNew from '../common/pages/registration_requests/New.vue'
import RegistrationRequestForm from '../common/pages/registration_requests/Form.vue'
import RegistrationRequestConfirm from '../common/pages/registration_requests/Confirm.vue'
import RegistrationRequestComplete from '../common/pages/registration_requests/Complete.vue'

import TwoFactorAuthEdit from '../common/pages/two_factor_auth/Edit.vue'
import TwoFactorAuthConfirm from '../common/pages/two_factor_auth/Confirm.vue'
import TwoFactorAuthComplete from '../common/pages/two_factor_auth/Complete.vue'

import MessageRoom from '../common/pages/message_room'

import EpRecommendIndex from './pages/recommends/Index'
import EpRecommendShow from './pages/recommends/Show'

import EpOfferIndex from './pages/offers/Index'
import EpOfferShow from './pages/offers/Show'

import ProvisionalEpChallengeIndex   from './pages/provisional_ep_challenges/Index'
import ProvisionalEpChallengeNew     from './pages/provisional_ep_challenges/New'
import ProvisionalEpChallengeEdit    from './pages/provisional_ep_challenges/Edit'
import ProvisionalEpChallengeForm    from './pages/provisional_ep_challenges/Form'
import ProvisionalEpChallengeConfirm from './pages/provisional_ep_challenges/Confirm'
import ProvisionalEpChallengeShow    from './pages/provisional_ep_challenges/Show'

import SuLatestInfoIndex from './pages/su_latest_infos/Index'
import SuLatestInfoShow from './pages/su_latest_infos/Show'

import PortfolioIndex from './pages/portfolios/Index'
import PortfolioNew from './pages/portfolios/New'
import PortfolioShow from './pages/portfolios/Show'


import ScenarioNew from './pages/scenarios/New'
import ScenarioEdit from './pages/scenarios/Edit'
import ScenarioForm from './pages/scenarios/Form'

import ScenariosShow from './pages/scenarios/show/Show'
import ScenatiosStep1 from './pages/scenarios/show/Step1'
import ScenatiosStep2 from './pages/scenarios/show/Step2'
import ScenatiosStep3 from './pages/scenarios/show/Step3'
import ScenatiosStep4 from './pages/scenarios/show/Step4'
import ScenatiosStep5 from './pages/scenarios/show/Step5'
import ScenatiosStep6 from './pages/scenarios/show/Step6'
import ScenatiosWorkingCapital from './pages/scenarios/show/WorkingCapital'
import ScenatiosPerComparison from './pages/scenarios/show/PerComparison'

const prefix = '/ep/';

// epにアクセス制限
const loginCheck = (to, from, next, auth) => {
  return auth.accountType == "ep_admin" || auth.accountType == "ep_person_in_charge"
}

// 管理者ユーザーかチェック
const EpAdminCheck = (to, from, next, auth) => {
  return auth.accountType == "ep_admin";
}

const single = "ep-single";
const layout='ep-main';
const layout_fixed_bottom = 'ep-main-fixed-bottom'

export default [
  { path: prefix + 'login', component: Login, name: 'EpLogin', meta: { isPublic: true }, props: {accountType: "ep"} },
  { path: prefix + 'logout', component: Logout, meta: { isPublic: true, layout: single}},
  { path: prefix + 'password/reset', component: PasswordReset, meta: { isPublic: true }, props: {accountType: "ep"} },
  { path: prefix + 'password/reset/sent', component: PasswordResetSent, meta: { isPublic: true }, props: {accountType: "ep"} },
  { path: prefix + 'password/reset/new_password/:reset_password_token', component: PasswordResetNewPassword, meta: { isPublic: true }, props: {accountType: "ep"} },
  { path: prefix + 'verify', component: Verify, meta: { isPublic: true }},
  { path: prefix + 'password/unlock/:unlock_token', component: Unlock, meta: { isPublic: true }, props: {accountType: 'ep'}},

  {
    path: prefix + "ep_companies/detail",
    component: EpCompanyShow,
    name: 'EpEpCompanyShow',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'ep_companies',
    component: EpCompanyEdit,
    children: [
      {
        path: "edit",
        component: EpCompanyEditForm,
        name: 'EpEpCompanyEditForm',
        meta: { loginCheck: EpAdminCheck, layout: layout }
      },
      {
        path: "confirm",
        component: EpCompanyEditConfirm,
        name: 'EpEpCompanyEditConfirm',
        meta: { loginCheck: EpAdminCheck, layout: layout }
      }
  ]},
  {
    path: prefix + 'ep_person_in_charges',
    component: EpPersonInChargeNew,
    children: [
      {
        path: "new",
        component: EpPersonInChargeNewForm,
        name: 'EpPersonInChargeNewForm',
        meta: { loginCheck: EpAdminCheck, layout: layout }
      },
      {
        path: "confirm",
        component: EpPersonInChargeNewConfirm,
        name: 'EpPersonInChargeNewConfirm',
        meta: { loginCheck: EpAdminCheck, layout: layout }
      },
    ]
  },
  {
    path: prefix + 'ep_person_in_charges/:id',
    component: EpPersonInChargeEdit,
    children: [
      {
        path: "edit",
        component: EpPersonInChargeEditForm,
        name: 'EpPersonInChargeEditForm',
        meta: { loginCheck: loginCheck, layout: layout }
      },
      {
        path: "confirm",
        component: EpPersonInChargeEditConfirm,
        name: 'EpPersonInChargeEditConfirm',
        meta: { loginCheck: loginCheck, layout: layout }
      }
    ]
  },
  {
    path: prefix + 'ep_person_in_charges/:id/detail',
    component: EpPersonInChargeShow,
    name: 'EpPersonInChargeShow',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'message_room/:room_id',
    component: MessageRoom,
    name: 'EpMessageRoom',
    meta: {loginCheck: loginCheck, layout: layout},
    props: {accountType: 'ep'}
  },
  {
    path: prefix + 'request_info',
    component: RegistrationAuthInfo,
    name: 'EpRegistrationAuthInfo',
    meta: { isPublic: true },
    props: { accountType: "ep" }
  },
  {
    path: prefix + 'request_flow1_edit',
    component: RegistrationAuthNew,
    name: 'EpRegistrationAuthNew',
    meta: { isPublic: true },
    props: { accountType: "ep" }
  },
  {
    path: prefix + 'request_flow2_send/:id',
    component: RegistrationAuthShow,
    name: 'EpRegistrationAuthShow',
    meta: { isPublic: true },
    props: { accountType: "ep" }
  },
  {
    path: prefix + 'request_flow',
    component: RegistrationRequestNew,
    meta: { isPublic: true },
    children: [
      {
        path: "new",
        component: RegistrationRequestForm,
        name: 'EpRegistrationRequestForm',
        props: { accountType: "ep" },
      },
      {
        path: "confirm",
        component: RegistrationRequestConfirm,
        name: 'EpRegistrationRequestConfirm',
        props: { accountType: "ep" },
      },
      {
        path: "complete",
        component: RegistrationRequestComplete,
        name: 'EpRegistrationRequestComplete',
        props: { accountType: "ep" },
      }
    ]
  },

  {
    path: prefix + "two_factor_auth/edit",
    component: TwoFactorAuthEdit,
    name: 'EpTwoFactorAuthEdit',
    meta: { loginCheck: loginCheck, layout: layout },
    props: { accountType: "ep" },
  },
  {
    path: prefix + "two_factor_auth/confirm",
    component: TwoFactorAuthConfirm,
    name: 'EpTwoFactorAuthConfirm',
    meta: { loginCheck: loginCheck, layout: layout },
    props: { accountType: "ep" },
  },
  {
    path: prefix + "two_factor_auth/complete",
    component: TwoFactorAuthComplete,
    name: 'EpTwoFactorAuthComplete',
    meta: { loginCheck: loginCheck, layout: layout },
    props: { accountType: "ep" },
  },

  {
    path: prefix + 'recommends/',
    component: EpRecommendIndex,
    name: 'EpRecommendIndex',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'recommends/:id',
    component: EpRecommendShow,
    name: 'EpRecommendShow',
    meta: { loginCheck: loginCheck, layout: layout_fixed_bottom }
  },
  {
    path: prefix + 'offers/',
    component: EpOfferIndex,
    name: 'EpOfferIndex',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'offers/:id',
    component: EpOfferShow,
    name: 'EpOfferShow',
    meta: { loginCheck: loginCheck, layout: layout }
  },

  {
    path: prefix + 'provisional_ep_challenges/index',
    component: ProvisionalEpChallengeIndex,
    name: 'EpProvisionalEpChallengeIndex',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'provisional_ep_challenges',
    component: ProvisionalEpChallengeNew,
    children: [
      {
        path: 'new',
        component: ProvisionalEpChallengeForm,
        name: 'EpProvisionalEpChallengeNew',
        meta: { loginCheck: loginCheck, layout: layout }
      },
      {
        path: 'confirm',
        component: ProvisionalEpChallengeConfirm,
        name: 'EpProvisionalEpChallengeNewConfirm',
        meta: { loginCheck: loginCheck, layout: layout }
      }
    ]
  },
  {
    path: prefix + 'provisional_ep_challenges/:id',
    component: ProvisionalEpChallengeEdit,
    children: [
      {
        path: 'edit',
        component: ProvisionalEpChallengeForm,
        name: 'EpProvisionalEpChallengeEdit',
        meta: { loginCheck: loginCheck, layout: layout }
      },
      {
        path: 'confirm',
        component: ProvisionalEpChallengeConfirm,
        name: 'EpProvisionalEpChallengeEditConfirm',
        meta: { loginCheck: loginCheck, layout: layout }
      }
    ]
  },
  {
    path: prefix + 'provisional_ep_challenges/:id/show',
    component: ProvisionalEpChallengeShow,
    name: 'EpProvisionalEpChallengeShow',
    meta: {loginCheck: loginCheck, layout: layout},
  },
  {
    path: prefix + 'su_latest_infos/index',
    component: SuLatestInfoIndex,
    name: 'EpSuLatestInfoIndex',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'latest_infos/:id/show',
    component: SuLatestInfoShow,
    name: 'EpSuLatestInfoShow',
    meta: { loginCheck: loginCheck, layout: layout_fixed_bottom }
  },
  {
    path: prefix + 'portfolios/index',
    component: PortfolioIndex,
    name: 'EpPortfolioIndex',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'portfolios/new',
    component: PortfolioNew,
    name: 'EpPortfolioNew',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'portfolios/:id/show',
    component: PortfolioShow,
    name: 'EpPortfolioShow',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'portfolios/:id/scenarios',
    component: ScenarioNew,
    children: [
      {
        path: 'new',
        component: ScenarioForm,
        name: 'EpScenarioNew',
        meta: { loginCheck: loginCheck, layout: layout }
      }
    ]
  },
  {
    path: prefix + 'scenarios/:id',
    component: ScenarioEdit,
    children: [
      {
        path: 'edit',
        component: ScenarioForm,
        name: 'EpScenarioEdit',
        meta: { loginCheck: loginCheck, layout: layout }
      }
    ]
  },
  {
    path: prefix + 'scenarios/:id',
    component: ScenariosShow,
    children: [
      {
        path: 'step_1',
        component: ScenatiosStep1,
        name: 'EpScenariosShowStep1',
        meta: { loginCheck: loginCheck, layout: layout },
      },
      {
        path: 'step_2',
        component: ScenatiosStep2,
        name: 'EpScenariosShowStep2',
        meta: { loginCheck: loginCheck, layout: layout },
      },
      {
        path: 'step_3',
        component: ScenatiosStep3,
        name: 'EpScenariosShowStep3',
        meta: { loginCheck: loginCheck, layout: layout },
      },
      {
        path: 'step_4',
        component: ScenatiosStep4,
        name: 'EpScenariosShowStep4',
        meta: { loginCheck: loginCheck, layout: layout },
      },
      {
        path: 'step_5',
        component: ScenatiosStep5,
        name: 'EpScenariosShowStep5',
        meta: { loginCheck: loginCheck, layout: layout },
      },
      {
        path: 'step_6',
        component: ScenatiosStep6,
        name: 'EpScenariosShowStep6',
        meta: { loginCheck: loginCheck, layout: layout },
      },
      {
        path: 'working_capital',
        component: ScenatiosWorkingCapital,
        name: 'EpScenariosShowWorkingCapital',
        meta: { loginCheck: loginCheck, layout: layout },
      },
      {
        path: 'per_comparison',
        component: ScenatiosPerComparison,
        name: 'EpScenariosShowPerComparison',
        meta: { loginCheck: loginCheck, layout: layout },
      },
    ],
  },
]
