<script>
  import { Bar, mixins } from 'vue-chartjs';
  import chartjsPluginDatalabels from 'chartjs-plugin-datalabels';

  Chart.Tooltip.positioners.custom = (elements) => {
    const view = elements[0]._view;
    return {
      x: view.x,
      y: view.y < 50 ? 50 : view.y,
    };
  };

  export default {
    extends: Bar,
    mixins: [mixins.reactiveProp ],
    props: {
      options: {
        type: Object,
        required: true,
      },
      chartData: {
        type: Object,
        required: true,
      },
    },
    mounted() {
      // customの場合tooltipの初期位置を設定
      if (this.options['tooltips'] && this.options.tooltips['position'] === 'custom') {
        if (!this.options.tooltips['xAlign']) this.options.tooltips['xAlign'] = 'center';
        if (!this.options.tooltips['yAlign']) this.options.tooltips['yAlign'] = 'bottom';
      }
      this.addPlugin(chartjsPluginDatalabels);
      this.renderChart(this.chartData, this.options);
    },
  };
</script>
