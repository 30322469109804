export default{
  computed: {
    /**
     * 初期表示時に検索条件セクションをオープンするか判定.
     */
    InitialSearchSectionOpening: function () {
      if(Object.keys(this.$route.query).length !== 0){
        return true;
      }
    }
  }
}