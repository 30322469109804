<template>
  <footer class="footer" v-bind:class="{ 'request-footer': isRequestFooter }">
    <div class="footer-inner">
      <ul class="d-flex">
        <li class="flex-grow-1">© 2022. 詳細は<a class="px-1" href="https://www2.deloitte.com/jp/ja/footerlinks1/legal/legal-for-other-sites.html" target="_blank" rel="noopener">利用規定</a>をご覧ください。</li>
        <li class="mr-3"><a href="https://www2.deloitte.com/jp/ja/footerlinks1/cookies/cookies-for-other-sites.html" target="_blank" rel="noopener">クッキーに関する通知<i class="far fa-window-restore ml-1"></i></a></li>
        <li><a href="https://www2.deloitte.com/jp/ja/footerlinks1/privacy/privacy-for-other-sites.html" target="_blank" rel="noopener">プライバシーポリシー<i class="far fa-window-restore ml-1"></i></a></li>
      </ul>
      <p class="copyright">Deloitte（デロイト）とは、デロイト トウシュ トーマツ リミテッド（“DTTL”）ならびにそのグローバルネットワーク組織を構成するメンバーファームおよびそれらの関係法人のひとつまたは複数を指します。DTTL（または“Deloitte Global”）および各メンバーファームならびにそれらの関係法人はそれぞれ法的に独立した別個の組織体です。DTTLはクライアントへのサービス提供を行いません。詳細は「<a href="https://www2.deloitte.com/jp/ja/pages/about-deloitte/articles/about-deloitte.html" target="_blank" rel="noopener">デロイト トーマツ グループおよびデロイトについて</a>」をご覧ください。</p>
    </div>
  </footer>
</template>

<script>
  export default {
    computed: {
      isRequestFooter() {
        return this.$route.name === 'SuRegistrationRequestComplete' ||
          this.$route.name === 'EpRegistrationRequestComplete';
      }
    }
  }
</script>