<template>
	<main>
        <div class="one-col-box white-block">
          <div class="header">
            <h1 class="logo"><img v-bind:src="require('../../../../images/logo.svg')" alt="six brain"></h1>
            <h2 class="mb-4">ログインできませんか？</h2>
          </div>
          <div class="body">
            <div class="alert alert-danger" role="alert" v-if="error">メールアドレス・姓・名に一致するユーザーが見つかりませんでした。</div>
            <p class="lead">ご登録のメールアドレス、お名前を入力してください。<br>パスワード再設定リンクを送ります。</p>
            <form @submit.prevent="post">
              <div class="form-group">
                <div class="input-group icon-addon-right">
                  <input class="form-control" type="text" name="email" v-model="email" placeholder="メールアドレス" required wovn-ignore><i class="far fa-envelope"></i>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-6">
                  <label class="required-addon lead p-0" for="name1">姓<span class="badge badge-danger">必須</span></label>
                  <input class="form-control" type="text" id="name1" placeholder="例) デロイト" required v-model="lastName" wovn-ignore>
                  <div class="invalid-feedback">姓を入力してください</div>
                </div>
                <div class="form-group col-6">
                  <label class="required-addon lead p-0" for="name2">名<span class="badge badge-danger">必須</span></label>
                  <input class="form-control" type="text" id="name2" placeholder="例) 太郎" required v-model="firstName" wovn-ignore>
                  <div class="invalid-feedback">名を入力してください</div>
                </div>
              </div>
              <div class="btn-container py-3">
                <button class="button-square btn-send btn-block" type="submit">パスワード再設定リンクを送信</button>
              </div>
            </form>
          </div>
          <div class="footer">
            <p class="text-center mt-3"><router-link to="../login">ログイン画面に戻る</router-link></p>
          </div>
        </div>
        <p class="text-center"><a class="text-secondary" href="mailto:sixbrain-support@tohmatsu.co.jp">サポートに問い合わせる</a></p>
    </main>
</template>

<script>
export default {
	metaInfo: {
		title: 'パスワード再設定',
		bodyAttrs: {
			class: ['password_setting']
		}
	},
	data() {
    return {
      email: '',
      lastName: '',
      firstName: '',
      error: false,
    }
  },
  methods: {
    post: function(){
      this.error = false;
      this.$loading.load(this.$auth.api.post('auth/password', {
        email: this.email,
        first_name: this.firstName,
        last_name: this.lastName,
        account_type: this.$attrs.accountType,
        redirect_url: 'http://localhost/reset'
      }).then(res => {
        if(res.data.success){
          const p = this.$route.path.split('/');
          this.$router.push({
            path: '/' + p[1] + '/password/reset/sent',
            query: {
              email: this.email,
              first_name: this.firstName,
              last_name: this.lastName,
            }
          });
        }
      }).catch(err => {
        console.log(err);
        if(err.response.status == 404){
          // ユーザー not found
          this.error = true;
        }else if(err.response.status == 500){
          this.$errorHandlers.initial(err);
        }else{
          this.$errorHandlers.ajax(err);
        }
      }))
    }
  },
  computed: {
  },
  created: function () {
  },
  mounted: function() {
  },
  watch: {
  }
}
</script>

<style scoped>
</style>