<template>
	<i class="fas fa-eye" @click="toggle($event)"></i>
</template>

<script>
export default {
	data() {
		return {
		}
	},
	props: ['target'],
	methods: {
		toggle($ev){
			$ev.target.classList.toggle('fa-eye');
			$ev.target.classList.toggle('fa-eye-slash');

			const input = (this.target) ? document.querySelector(this.target) : this.$el.parentNode.querySelector('input');
			if(input.type == 'password'){
				input.type = 'text';
			}else{
				input.type = 'password';
			}
		}
	},
	computed: {
	},
	created: function () {
	},
	mounted: function() {
	},
	watch: {
	}
}
</script>