import Login from '../common/pages/login'
import Logout from '../common/pages/logout'
import Verify from '../common/pages/verify'
import Dashboard from './pages/dashboard'
import PasswordReset from '../common/pages/password_reset'
import PasswordResetSent from '../common/pages/password_reset/sent'
import PasswordResetNewPassword from '../common/pages/password_reset/new_password'
import Unlock from '../common/pages/unlock'

import TwoFactorAuthEdit from '../common/pages/two_factor_auth/Edit.vue'
import TwoFactorAuthConfirm from '../common/pages/two_factor_auth/Confirm.vue'
import TwoFactorAuthComplete from '../common/pages/two_factor_auth/Complete.vue'

import EpCompanyIndex from './pages/ep_companies/Index.vue'

import EpCompanyNew from './pages/ep_companies/New.vue'
import EpCompanyForm from './pages/ep_companies/Form.vue'
import EpCompanyConfirm from './pages/ep_companies/Confirm.vue'
import EpCompanyComplete from './pages/ep_companies/Complete.vue'
import EpCompanyShow from './pages/ep_companies/Show.vue'
import EpCompanyEdit from './pages/ep_companies/Edit.vue'
import EpCompanyEditForm from './pages/ep_companies/EditForm.vue'
import EpCompanyEditConfirm from './pages/ep_companies/EditConfirm.vue'

import SuCompanyNew from './pages/su_companies/New.vue'
import SuCompanyForm from './pages/su_companies/Form.vue'
import SuCompanyConfirm from './pages/su_companies/Confirm.vue'
import SuCompanyComplete from './pages/su_companies/Complete.vue'
import SuEdit from './pages/su_companies/SuEdit'
import SuEditForm from './pages/su_companies/SuEditForm.vue'
import SuEditConfirm from './pages/su_companies/SuEditConfirm.vue'
import SuCompanyDetail from './pages/su_companies/SuCompanyDetail.vue'
import SuIndex from './pages/su_companies/Index.vue'

import MessageIndex from './pages/message_rooms/Index.vue'
import MessageShow from './pages/message_rooms/Show.vue'

import AssetMasterIndex from './pages/asset_masters/Index.vue'
import AssetMasterNew   from './pages/asset_masters/New'
import AssetMasterEdit  from './pages/asset_masters/Edit'

import UserIndex from './pages/users/Index.vue'
import UserNew from './pages/users/New.vue'
import UserEdit from './pages/users/Edit.vue'
import UserConfirm from './pages/users/Confirm.vue'
import UserShow from './pages/users/Show.vue'
import UserForm from './pages/users/Form.vue'

import EpPersonInChargeNew from './pages/ep_person_in_charges/New.vue'
import EpPersonInChargeNewForm from './pages/ep_person_in_charges/Form.vue'
import EpPersonInChargeNewConfirm from './pages/ep_person_in_charges/Confirm.vue'
import EpPersonInChargeEdit from './pages/ep_person_in_charges/Edit.vue'
import EpPersonInChargeEditForm from './pages/ep_person_in_charges/EditForm.vue'
import EpPersonInChargeEditConfirm from './pages/ep_person_in_charges/EditConfirm.vue'
import EpPersonInChargeShow from './pages/ep_person_in_charges/Show.vue'

import SuPersonInChargeNew from './pages/su_person_in_charges/New.vue'
import SuPersonInChargeNewForm from './pages/su_person_in_charges/Form.vue'
import SuPersonInChargeNewConfirm from './pages/su_person_in_charges/Confirm.vue'
import SuPersonInChargeEdit from './pages/su_person_in_charges/Edit.vue'
import SuPersonInChargeEditForm from './pages/su_person_in_charges/EditForm.vue'
import SuPersonInChargeEditConfirm from './pages/su_person_in_charges/EditConfirm.vue'
import SuPersonInChargeShow from './pages/su_person_in_charges/Show.vue'

import MessageRoom from '../common/pages/message_room'

import RecommendIndex from './pages/recommends/Index.vue'

import AfterRecommendIndex from "./pages/after_recommends/Index.vue";
import AfterRecommendShow from "./pages/after_recommends/Show.vue";

import ProvisionalEpChallengeIndex   from './pages/provisional_ep_challenges/Index'
import ProvisionalEpChallengeNew     from './pages/provisional_ep_challenges/New'
import ProvisionalEpChallengeEdit    from './pages/provisional_ep_challenges/Edit'
import ProvisionalEpChallengeForm    from './pages/provisional_ep_challenges/Form'
import ProvisionalEpChallengeConfirm from './pages/provisional_ep_challenges/Confirm'
import ProvisionalEpChallengeShow    from './pages/provisional_ep_challenges/Show'
import ProvisionalEpChallengeDiff    from './pages/provisional_ep_challenges/Diff'

import ProvisionalSuLatestInfoIndex   from './pages/provisional_su_latest_infos/Index'
import ProvisionalSuLatestInfoNew     from './pages/provisional_su_latest_infos/New'
import ProvisionalSuLatestInfoEdit    from './pages/provisional_su_latest_infos/Edit'
import ProvisionalSuLatestInfoForm    from './pages/provisional_su_latest_infos/Form'
import ProvisionalSuLatestInfoConfirm from './pages/provisional_su_latest_infos/Confirm'
import ProvisionalSuLatestInfoShow    from './pages/provisional_su_latest_infos/Show'
import ProvisionalSuLatestInfoDiff    from './pages/provisional_su_latest_infos/Diff'

import SuOldAchievementNew from './pages/su_old_achievements/New.vue'
import SuOldAchievementNewForm from './pages/su_old_achievements/Form.vue'
import SuOldAchievementNewConfirm from './pages/su_old_achievements/Confirm.vue'
import SuOldAchievementEdit from './pages/su_old_achievements/Edit.vue'
import SuOldAchievementEditForm from './pages/su_old_achievements/EditForm.vue'
import SuOldAchievementEditConfirm from './pages/su_old_achievements/EditConfirm.vue'
import SuOldAchievementShow from './pages/su_old_achievements/Show.vue'

import AchievementCsvImport from './pages/achievement_csv_import/Index.vue'

const prefix = '/admin/';

// 管理者にアクセス制限
const loginCheck = (to, from, next, auth) => {
  return auth.accountType == "dtvs_admin" || auth.accountType == "dtvs_cm";
}

// 管理者ユーザーかチェック
const dtvsAdminCheck = (to, from, next, auth) => {
  return auth.accountType == "dtvs_admin";
}

const layout = 'admin-main';
const layout_fixed_bottom = 'admin-main-fixed-bottom'


export default [
  { path: prefix + 'login', component: Login, name: 'AdminLogin', meta: { isPublic: true }, props: {accountType: "admin"} },
  { path: prefix + 'logout', component: Logout, meta: { isPublic: true }},
  { path: prefix + 'password/reset', component: PasswordReset, meta: { isPublic: true }, props: {accountType: "admin"} },
  { path: prefix + 'password/reset/sent', component: PasswordResetSent, meta: { isPublic: true }, props: {accountType: "admin"} },
  { path: prefix + 'password/reset/new_password/:reset_password_token', component: PasswordResetNewPassword, meta: { isPublic: true }, props: {accountType: "admin"} },
  { path: prefix + 'verify', component: Verify, meta: { isPublic: true }},
  { path: prefix + 'password/unlock/:unlock_token', component: Unlock, meta: { isPublic: true }, props: {accountType: 'admin'}},
  { path: prefix + '', component: Dashboard, meta: { loginCheck: loginCheck, layout: layout }},

  // 2段階認証設定
  {
    path: prefix + "two_factor_auth/edit",
    component: TwoFactorAuthEdit,
    name: 'AdminTwoFactorAuthEdit',
    meta: { loginCheck: loginCheck, layout: layout },
  },
  {
    path: prefix + "two_factor_auth/confirm",
    component: TwoFactorAuthConfirm,
    name: 'AdminTwoFactorAuthConfirm',
    meta: { loginCheck: loginCheck, layout: layout },
  },
  {
    path: prefix + "two_factor_auth/complete",
    component: TwoFactorAuthComplete,
    name: 'AdminTwoFactorAuthComplete',
    meta: { loginCheck: loginCheck, layout: layout },
  },

  { path: prefix + 'su_companies/index', component: SuIndex, name: 'AdminSuIndex', meta: {loginCheck: loginCheck, layout: layout} },
  { path: prefix + 'su_companies', component: SuCompanyNew,
    children: [
      { path: 'new', component: SuCompanyForm, name: 'AdminSuCompanyForm', meta: {loginCheck: loginCheck, layout: layout} },
      { path: 'confirm', component: SuCompanyConfirm, name: 'AdminSuCompanyConfirm', meta: {loginCheck: loginCheck, layout: layout} },
      { path: "complete", component: SuCompanyComplete, name: 'AdminSuCompanyComplete', meta: { loginCheck: loginCheck, layout: layout } }
    ]
  },
  { path: prefix + 'su_companies/:id', component: SuEdit,
    children: [
      { path: 'edit', component: SuEditForm, name: 'AdminSuEditForm', meta: {loginCheck: loginCheck, layout: layout} },
      { path: 'confirm', component: SuEditConfirm, name: 'AdminSuEditConfirm', meta: {loginCheck: loginCheck, layout: layout} },
      { path: 'show', component: SuCompanyDetail, name: 'AdminSuCompanyDetail', meta: {loginCheck: loginCheck, layout: layout} }
    ]
  },

  { path: prefix + 'message_rooms/index', component: MessageIndex, name: 'AdminMessageIndex', meta: {loginCheck: loginCheck, layout: layout} },
  { path: prefix + 'message_rooms/:room_id', component: MessageShow, name: 'AdminMessageShow', meta: {loginCheck: loginCheck, layout: layout} },

  {
    path: prefix + 'ep_companies/index',
    component: EpCompanyIndex,
    name: 'AdminEpCompanyIndex',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'ep_companies',
    component: EpCompanyNew,
    name: 'AdminEpCompanyNew',
    children: [
      {
        path: "new",
        component: EpCompanyForm,
        name: 'AdminEpCompanyForm',
        meta: { loginCheck: dtvsAdminCheck, layout: layout }
      },
      {
        path: "confirm",
        component: EpCompanyConfirm,
        name: 'AdminEpCompanyConfirm',
        meta: { loginCheck: dtvsAdminCheck, layout: layout }
      },
      {
        path: "complete",
        component: EpCompanyComplete,
        name: 'AdminEpCompanyComplete',
        meta: { loginCheck: dtvsAdminCheck, layout: layout }
      }
  ]},
  {
    path: prefix + 'ep_companies/:id/show',
    component: EpCompanyShow,
    name: 'AdminEpCompanyShow',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'ep_companies/:id',
    component: EpCompanyEdit,
    children: [
      {
        path: "edit",
        component: EpCompanyEditForm,
        name: 'AdminEpCompanyEditForm',
        meta: { loginCheck: dtvsAdminCheck, layout: layout }
      },
      {
        path: "edit_confirm",
        component: EpCompanyEditConfirm,
        name: 'AdminEpCompanyEditConfirm',
        meta: { loginCheck: dtvsAdminCheck, layout: layout }
      }
  ]},
  {
    path: prefix + 'asset_masters/',
    component: AssetMasterIndex,
    name: 'AdminAssetMasterIndex',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'asset_masters/new',
    component: AssetMasterNew,
    name: 'AdminAssetMasterNew',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'asset_masters/:asset_master_id/edit',
    component: AssetMasterEdit,
    name: 'AdminAssetMasterEdit',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'ep_person_in_charges',
    component: EpPersonInChargeNew,
    children: [
      {
        path: "new",
        component: EpPersonInChargeNewForm,
        name: 'AdminEpPersonInChargeNewForm',
        meta: { loginCheck: dtvsAdminCheck, layout: layout }
      },
      {
        path: "confirm",
        component: EpPersonInChargeNewConfirm,
        name: 'AdminEpPersonInChargeNewConfirm',
        meta: { loginCheck: dtvsAdminCheck, layout: layout }
      },
    ]
  },
  {
    path: prefix + 'ep_person_in_charges/:id',
    component: EpPersonInChargeEdit,
    children: [
      {
        path: "edit",
        component: EpPersonInChargeEditForm,
        name: 'AdminEpPersonInChargeEditForm',
        meta: { loginCheck: dtvsAdminCheck, layout: layout }
      },
      {
        path: "confirm",
        component: EpPersonInChargeEditConfirm,
        name: 'AdminEpPersonInChargeEditConfirm',
        meta: { loginCheck: loginCheck, layout: layout }
      }
    ]
  },
  {
    path: prefix + 'ep_person_in_charges/:id/detail',
    component: EpPersonInChargeShow,
    name: 'AdminEpPersonInChargeShow',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'su_person_in_charges',
    component: SuPersonInChargeNew,
    children: [
      {
        path: "new",
        component: SuPersonInChargeNewForm,
        name: 'AdminSuPersonInChargeNewForm',
        meta: { loginCheck: dtvsAdminCheck, layout: layout }
      },
      {
        path: "confirm",
        component: SuPersonInChargeNewConfirm,
        name: 'AdminSuPersonInChargeNewConfirm',
        meta: { loginCheck: dtvsAdminCheck, layout: layout }
      },
    ]
  },
  {
    path: prefix + 'su_person_in_charges/:id',
    component: SuPersonInChargeEdit,
    children: [
      {
        path: "edit",
        component: SuPersonInChargeEditForm,
        name: 'AdminSuPersonInChargeEditForm',
        meta: { loginCheck: dtvsAdminCheck, layout: layout }
      },
      {
        path: "confirm",
        component: SuPersonInChargeEditConfirm,
        name: 'AdminSuPersonInChargeEditConfirm',
        meta: { loginCheck: dtvsAdminCheck, layout: layout }
      }
    ]
  },
  {
    path: prefix + 'su_person_in_charges/:id/detail',
    component: SuPersonInChargeShow,
    name: 'AdminSuPersonInChargeShow',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'message_room/:room_id',
    component: MessageRoom,
    meta: {loginCheck: loginCheck, layout: layout},
    props: {accountType: 'admin'}
  },
  {
    path: prefix + 'users/index',
    component: UserIndex,
    name: 'AdminUserIndex',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'users',
    component: UserNew,
    children: [
      {
        path: "new",
        component: UserForm,
        name: 'AdminUserNewForm',
        meta: { loginCheck: loginCheck, layout: layout }
      },
      {
        path: "confirm",
        component: UserConfirm,
        name: 'AdminUserNewConfirm',
        meta: { loginCheck: loginCheck, layout: layout }
      }
  ]},
  {
    path: prefix + 'users/:id/show',
    component: UserShow,
    name: 'AdminUserShow',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'users/:id',
    component: UserEdit,
    children: [
      {
        path: "edit",
        component: UserForm,
        name: 'AdminUserEditForm',
        meta: { loginCheck: loginCheck, layout: layout }
      },
      {
        path: "confirm",
        component: UserConfirm,
        name: 'AdminUserEditConfirm',
        meta: { loginCheck: loginCheck, layout: layout }
      }
  ]},
  {
    path: prefix + "recommends/",
    component: RecommendIndex,
    name: "AdminRecommendIndex",
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + "after_recommends/",
    component: AfterRecommendIndex,
    name: "AdminAfterRecommendIndex",
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'after_recommends/:ep_recommend_id',
    component: AfterRecommendShow,
    name: 'AdminAfterRecommendShow',
    meta: { loginCheck: loginCheck, layout: layout_fixed_bottom }
  },
  {
    path: prefix + 'provisional_ep_challenges/index',
    component: ProvisionalEpChallengeIndex,
    name: 'AdminProvisionalEpChallengeIndex',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'provisional_ep_challenges',
    component: ProvisionalEpChallengeNew,
    children: [
      {
        path: 'new',
        component: ProvisionalEpChallengeForm,
        name: 'AdminProvisionalEpChallengeNew',
        meta: { loginCheck: loginCheck, layout: layout }
      },
      {
        path: 'confirm',
        component: ProvisionalEpChallengeConfirm,
        name: 'AdminProvisionalEpChallengeNewConfirm',
        meta: { loginCheck: loginCheck, layout: layout }
      }
    ]
  },
  {
    path: prefix + 'provisional_ep_challenges/:id',
    component: ProvisionalEpChallengeEdit,
    children: [
      {
        path: 'edit',
        component: ProvisionalEpChallengeForm,
        name: 'AdminProvisionalEpChallengeEdit',
        meta: { loginCheck: loginCheck, layout: layout }
      },
      {
        path: 'confirm',
        component: ProvisionalEpChallengeConfirm,
        name: 'AdminProvisionalEpChallengeEditConfirm',
        meta: { loginCheck: loginCheck, layout: layout }
      }
    ]
  },
  {
    path: prefix + 'provisional_ep_challenges/:id/show',
    component: ProvisionalEpChallengeShow,
    name: 'AdminProvisionalEpChallengeShow',
    meta: {loginCheck: loginCheck, layout: layout_fixed_bottom},
  },
  {
    path: prefix + 'provisional_ep_challenges/:id/diff',
    component: ProvisionalEpChallengeDiff,
    name: 'AdminProvisionalEpChallengeDiff',
    meta: {loginCheck: loginCheck, layout: null},
  },

  {
    path: prefix + 'provisional_su_latest_infos/index',
    component: ProvisionalSuLatestInfoIndex,
    name: 'AdminProvisionalSuLatestInfoIndex',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'provisional_su_latest_infos',
    component: ProvisionalSuLatestInfoNew,
    children: [
      {
        path: 'new',
        component: ProvisionalSuLatestInfoForm,
        name: 'AdminProvisionalSuLatestInfoNew',
        meta: { loginCheck: loginCheck, layout: layout }
      },
      {
        path: 'confirm',
        component: ProvisionalSuLatestInfoConfirm,
        name: 'AdminProvisionalSuLatestInfoNewConfirm',
        meta: { loginCheck: loginCheck, layout: layout }
      }
    ]
  },
  {
    path: prefix + 'provisional_su_latest_infos/:id',
    component: ProvisionalSuLatestInfoEdit,
    children: [
      {
        path: 'edit',
        component: ProvisionalSuLatestInfoForm,
        name: 'AdminProvisionalSuLatestInfoEdit',
        meta: { loginCheck: loginCheck, layout: layout }
      },
      {
        path: 'confirm',
        component: ProvisionalSuLatestInfoConfirm,
        name: 'AdminProvisionalSuLatestInfoEditConfirm',
        meta: { loginCheck: loginCheck, layout: layout }
      }
    ]
  },
  {
    path: prefix + 'provisional_su_latest_infos/:id/show',
    component: ProvisionalSuLatestInfoShow,
    name: 'AdminProvisionalSuLatestInfoShow',
    meta: {loginCheck: loginCheck, layout: layout_fixed_bottom},
  },
  {
    path: prefix + 'provisional_su_latest_infos/:id/diff',
    component: ProvisionalSuLatestInfoDiff,
    name: 'AdminProvisionalSuLatestInfoDiff',
    meta: {loginCheck: loginCheck, layout: null},
  },

  {
    path: prefix + 'su_old_achievements/:id',
    component: SuOldAchievementNew,
    children: [
      {
        path: "new",
        component: SuOldAchievementNewForm,
        name: 'AdminSuOldAchievementNewForm',
        meta: { loginCheck: loginCheck, layout: layout }
      },
      {
        path: "confirm",
        component: SuOldAchievementNewConfirm,
        name: 'AdminSuOldAchievementNewConfirm',
        meta: { loginCheck: loginCheck, layout: layout }
      },
    ]
  },
  {
    path: prefix + 'su_old_achievements/:id',
    component: SuOldAchievementEdit,
    children: [
      {
        path: "edit",
        component: SuOldAchievementEditForm,
        name: 'AdminSuOldAchievementEditForm',
        meta: { loginCheck: loginCheck, layout: layout }
      },
      {
        path: "confirm",
        component: SuOldAchievementEditConfirm,
        name: 'AdminSuOldAchievementEditConfirm',
        meta: { loginCheck: loginCheck, layout: layout }
      }
    ]
  },
  {
    path: prefix + 'su_old_achievements/:id/detail',
    component: SuOldAchievementShow,
    name: 'AdminSuOldAchievementShow',
    meta: { loginCheck: loginCheck, layout: layout }
  },

  {
    path: prefix + 'achievement_csv_import/index',
    component: AchievementCsvImport,
    name: 'AdminAchievementCsvImport',
    meta: { loginCheck: loginCheck, layout: layout }
  },

]
