<script>
import bs from 'binary-search'
import MessageItem from './item'
import SetInterval from '../mixins/set_interval'
// 確認モーダルのコンポーネント読み込み
import ConfirmModal from '../pages/ConfirmModal.vue';

export default {
  mixins: [SetInterval],
  components: {
    MessageItem,
    ConfirmModal,
  },
  props: {
    roomId: {
      type: [Number, String],
      required: true,
    },
    accountType: {
      type: String,
      required: true,
    },
    login:{
      type: Object,
      default: () => ({})
    },
    creatable: {
      type: Boolean,
      default: true
    },
    currentUserId: {
      type: [String, Number]
    },
    isNoFade: {
      type: Boolean,
      default: true,
    }
  },
  data () {
    return {
      room: null,
      message: '',
      errors: [],
      timeline: [],
      pollingTimer: null,
      areaHeight: 120,
      reset_flag: true,
      edit_id: null,
      edit_message: '',
      edit_updated_at: null,
      // 排他処理用更新日時
      message_updated_at: null,
      delete_id: null,
      was_post_valid: false,
      was_patch_valid: false
    }
  },
  methods: {
    initRoom(){
      this.$loading.load(this.$auth.api.get(this.apiBasePath()).then(res => {
        this.room = res.data.message_room;
        this.addMessages(res.data.message_room.messages);
        if(!this.pollingTimer){
          this.pollingTimer = this.setInterval(this.polling, 30000)
        }
      }).catch(e => {
        this.$errorHandlers.initial(e);
      }))
    },
    loadOlder(){
      return this.$auth.api.get(this.messagesApiPath(), {
        params: {
          max_id: this.oldestMessageId,
          count: 3
        }
      }).then(res => {
        this.addMessages(res.data.messages);
        return res;
      })
    },
    loadNewer(){
      return this.$auth.api.get(this.messagesApiPath(), {
        params: {
          since_id: this.newestMessageId,
        }
      }).then(res => {
        this.addMessages(res.data.messages);
        return res;
      })
    },
    infinateOlder($state){
      this.loadOlder().then(res  => {
        if(res.data.messages.length < 20){
          $state.complete();
        }else{
          $state.loaded();
        }
      }).catch(err => {
        $state.loaded();
      });
    },
    polling(){
      this.loadNewer();
    },
    postMessage(){
      const data = new FormData();
      data.append('message[message]', this.message)
      return this.$loading.load(this.$auth.api.post(this.messagesApiPath(), data, {
          headers: {'content-type': 'multipart/form-data'}
        }).then(res => {
          this.message = '';
          this.errors = [];
          this.loadNewer();
          this.addMessages([res.data.message]);
          this.$bvToast.toast("メッセージを登録しました", {
            variant: 'success',
            title: '完了'
          });
          return res;
        }).catch(e => {
          if(e.response.status == 422){
            this.errors = e.response.data.errors;
            this.was_post_valid = true;
            this.was_patch_valid = false;
          } else if (e.response.status == 500) {
            this.$errorHandlers.initial(e);
          }else{
            this.$errorHandlers.ajax(e);
          }
        }))
    },
    apiBasePath(){
      return this.accountType + '/message_rooms/' + this.roomId;
    },
    messagesApiPath(){
      return this.apiBasePath() + '/contact_messages';
    },
    addMessages(messages){
      messages.forEach(m => {
        // ログインユーザーの投稿したメッセージの場合、is_current_userをtrueに
        m.user_id === this.currentUserId ? m.is_current_user = true : m.is_current_user = false
        m.is_edit = false
        m.reset_key = 'flag_'
        const idx = bs(this.timeline, m, (a, b) => {
          return a.id > b.id ? -1 :
            a.id < b.id ? 1 :
            0;
        })
        if(idx < 0){
          this.timeline.splice(~idx, 0, m);
        }
      });
    },
    scale: function () {
      this.$nextTick(function () {
        if(this.$refs['message'].scrollHeight < 120) return
        let messageArea = this.$refs['message'];
        if(messageArea.scrollHeight >= messageArea.offsetHeight){
          this.areaHeight = Math.min(this.$refs['message'].scrollHeight, 380);
        }
        else{
          this.$nextTick(function(){
            this.areaHeight = 120;
            this.$nextTick(function(){
              this.areaHeight = Math.min(this.$refs['message'].scrollHeight, 380);
            })
          });
        }
      });
    },

    // 承認ボタン押下時
    onApproval(message) {
      this.message_id = message.id
      this.approval_target_ep_recommend_id = message.ep_recommend_id;
      this.offer_suggestions_welcome_updated_at = message.offer_suggestions_welcome_updated_at;
      this.$refs.approval_confirm_modal.open();
    },

    // 承認モーダルのOKボタン押下時
    onApprovalConfirmSend() {
      this.$loading.load(
        this.$auth.api.patch('su/message_rooms/contact_messages/approval.json', {
          contact_message:{
            message_room_id: this.roomId,
            message_id: this.message_id,
            ep_recommend_id: this.approval_target_ep_recommend_id,
            offer_suggestions_welcome_updated_at: this.offer_suggestions_welcome_updated_at
          }
        }).then(res => {
          this.$bvToast.toast("更新しました。", {
            variant: 'success',
            title: '完了'
          });
          this.replaceButtonToLabel(res.data.message);
          this.approval_target_ep_recommend_id = '';
          this.$refs.approval_confirm_modal.close();
          return res;
        }).catch(err => {
          this.$refs.approval_confirm_modal.close()
          if (err.response.status == 500) {
            this.$errorHandlers.initial(err);
          } else {
            this.$errorHandlers.ajax(err);
          }
        })
      )
    },

    // 見送りボタン押下時
    onPass(message) {
      this.message_id = message.id
      this.pass_target_ep_recommend_id = message.ep_recommend_id;
      this.offer_suggestions_welcome_updated_at = message.offer_suggestions_welcome_updated_at;
      this.$refs.pass_confirm_modal.open();
    },

    // 見送りモーダルのOKボタン押下時
    onPassConfirmSend() {
      this.$loading.load(
          this.$auth.api.patch('su/message_rooms/contact_messages/pass.json', {
            contact_message:{
              message_room_id: this.roomId,
              message_id: this.message_id,
              ep_recommend_id: this.pass_target_ep_recommend_id,
              offer_suggestions_welcome_updated_at: this.offer_suggestions_welcome_updated_at
            }
          }).then(res => {
            this.$bvToast.toast("更新しました。", {
              variant: 'success',
              title: '完了'
            });
            this.replaceButtonToLabel(res.data.message);
            this.pass_target_ep_recommend_id = '';
            this.$refs.pass_confirm_modal.close();
            return res;
          }).catch(err => {
            this.$refs.pass_confirm_modal.close()
            if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            } else {
              this.$errorHandlers.ajax(err);
            }
          })
      )
    },

    // チャレンジ詳細画面
    onChallenge (message) {
      let resolvedRoute = null;
      if(this.accountType == "admin"){
        // DTVSでの遷移
        resolvedRoute = this.$router.resolve({
          name: 'AdminProvisionalEpChallengeShow',
          params: { id: message.provisional_ep_challenge_id }
        });
      }else{
        // SUの遷移
        resolvedRoute = this.$router.resolve({
          name: 'SuChallengeShow',
          params: { challenge_id: message.ep_challenge_id }
        });
      }
      window.open(resolvedRoute.href, '_blank');
    },

    // 最新情報詳細画面
    onLatestInfo (message) {
      let resolvedRoute = null;
      if(this.accountType == "admin"){
        // DTVSでの遷移
        resolvedRoute = this.$router.resolve({
          name: 'AdminProvisionalSuLatestInfoShow',
          params: { id: message.provisional_su_latest_info_id }
        });
      }else{
        // SUの遷移
        resolvedRoute = this.$router.resolve({
          name: 'SuProvisionalSuLatestInfoShow',
          params: { id: message.provisional_su_latest_info_id }
        });
      }
      window.open(resolvedRoute.href, '_blank');
    },

    // 削除ボタン押下時
    onDeleteMessage (message) {
      // 排他処理ように更新日時を取得
      this.message_updated_at = message.updated_at
      this.delete_id = message.id;
      this.$refs.delete_confirm_modal.open();
    },

    // 削除確認モーダル内削除する押下時
    deleteMessage () {
      return this.$loading.load(this.$auth.api.delete(`${this.messagesApiPath()}/${this.delete_id}`,{params:{updated_at: this.message_updated_at}}
        ).then(res => {
          this.$bvToast.toast("メッセージを削除しました", {
            variant: 'success',
            title: '完了'
          });
          this.deleteRemove(res.data.message);
          this.delete_id = null;
          this.$refs.delete_confirm_modal.close();
          return res;
        }).catch(e => {
          this.$refs.delete_confirm_modal.close();
          if(e.response.status == 500){
            this.$errorHandlers.initial(e);
          }else{
            this.$errorHandlers.ajax(e);
          }
        }))
    },

    // 削除されたメッセージを取り除く
    deleteRemove (deleted_message) {
      this.$delete(this.timeline, this.timeline.findIndex( message => message.id === deleted_message.id))
    },

    // 編集ボタン押下時
    onEditMessage (edit_message) {
      this.edit_id = edit_message.id
      this.edit_message = edit_message.message
      this.edit_updated_at = edit_message.updated_at
      this.timeline = this.timeline.map((message) => {
        // 受け取ったedit_messageのIDと同じなら、is_editにtrue
        if (edit_message.id === message.id) {
          message.is_edit = true;
          return message;
        } else {
          message.is_edit = false;
          return message;
        }
      })
      this.childItemRedraw();
      this.errorsRemove();
    },

    // 編集フォームのキャンセル押下時
    onEditCancel () {
      this.editFormInit();
      this.errorsRemove();
    },

    // keyの更新による子コンポーネントの再描画
    childItemRedraw () {
      if (!!Object.keys(this.timeline).length) {
        // 子コンポーネントの再描画、keyの更新
        this.timeline.forEach((message) => {
          if (message.reset_key === 'flag_') {
            message.reset_key = 'flag_a_'
          } else {
            message.reset_key = 'flag_'
          }
        })
      }
    },

    // 編集用バリデーションメッセージ削除
    errorsRemove () {
      if (this.was_patch_valid) {
        this.errors = [];
      }
    },

    // 編集フォーム保存押下時
    patchMessage(){
      const data = new FormData();
      data.append('message[message]', this.edit_message)
      data.append('message[updated_at]', this.edit_updated_at)
      return this.$loading.load(this.$auth.api.patch(`${this.messagesApiPath()}/${this.edit_id}`, data, {
          headers: {'content-type': 'multipart/form-data'}
        }).then(res => {
          this.updateReplace(res.data.message);
          this.editFormInit();
          this.$bvToast.toast("メッセージを更新しました", {
            variant: 'success',
            title: '完了'
          });
          return res;
        }).catch(e => {
          if(e.response.status == 422){
            this.errors = e.response.data.errors;
            this.was_patch_valid = true;
            this.was_post_valid = false;
          } else if (e.response.status == 500) {
            this.$errorHandlers.initial(e);
          }else{
            this.$errorHandlers.ajax(e);
          }
        }))
    },

    // 編集関係の変数初期化
    editFormInit () {
      this.edit_id = null
      this.edit_message = ''
      this.edit_updated_at = null
      // is_editを全てfalseにする
      this.timeline = this.timeline.map((message) => {
        message.is_edit = false;
        return message;
      })
      this.childItemRedraw();
    },

    // 更新部分を置換え
    updateReplace (updated_message) {
      this.timeline = this.timeline.map((message) => {
        if (message.id === updated_message.id) {
          message.message = updated_message.message;
          message.updated_at = updated_message.updated_at;
          return message;
        } else {
          return message;
        }
      })
    },

    // 承認・見送りボタンを結果ラベルに置き換える
    replaceButtonToLabel (updated_message) {
      this.timeline = this.timeline.map((message) => {
        if (message.id === updated_message.id) {
          message.is_show_button = updated_message.is_show_button;
          message.select_result = updated_message.select_result;
          return message;
        } else {
          return message;
        }
      })
    },

  },
  computed: {
    oldestMessageId(){
      return this.timeline && this.timeline.length > 0 ? this.timeline[this.timeline.length - 1].id : null;
    },
    newestMessageId(){
      return this.timeline && this.timeline.length > 0 ? this.timeline[0].id : null;
    }

  },
  created: function () {
  },
  mounted: function(){
    this.initRoom();
  },
  watch: {
    message(to, from) {
      this.scale()
    },
  }
}
</script>
