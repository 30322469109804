<template>
  <main>
    <section class="main-title">
      <h1>スタートアップ企業プッシュ登録</h1>
    </section>
    <form @submit.prevent="confirmSuCompany" class="needs-validation" novalidate>
        <!-- 企業情報 -->
      <section class="chapter white-block">
        <h2 class="chapter-label">企業情報</h2>
        <div class="form-wrap d-flex border-top">
          <div class="right-col">

            <div class="form-group">
              <label class="required-addon" for="company-name">企業名<span class="badge badge-danger">必須</span></label>
              <b-form-input v-model="su_company.company_name" class="form-control" :state="checkValid('company_name')" type="text" id="company-name" placeholder="例) シックスブレイン株式会社" required></b-form-input>
              <p v-if="!!errors['company_name']" class="invalid-feedback d-block">{{ errors['company_name'][0]}}</p>
            </div>

            <fieldset>
              <legend class="required-addon">所在地<span class="badge badge-secondary">任意</span></legend>
              <div class="form-row">
                <div class="form-group col-4">
                  <b-select v-model="su_company.prefecture_master_id" class="custom-select" :state="checkValid('prefecture_master_id')" id="company-address1">
                    <option value="">選択してください</option>
                    <option v-for="(prefecturs_i18n, prefectures) in prefectures" :key="prefectures" :value="prefectures">
                      {{ prefecturs_i18n }}
                    </option>
                  </b-select>
                  <p v-if="!!errors['prefecture_master_id']" class="invalid-feedback d-block">{{ errors['prefecture_master_id'][0] }}</p>
                </div>
                <div class="form-group col-8">
                  <b-form-input v-model="su_company.address" class="form-control" :state="checkValid('address')" type="text" id="ccompany-address2" placeholder="例) 千代田区丸の内3-2-3 丸の内二重橋ビルディング"></b-form-input>
                  <p v-if="!!errors['address']" class="invalid-feedback d-block">{{ errors['address'][0] }}</p>
                </div>
              </div>
            </fieldset>

            <div class="form-group">
              <label class="required-addon">創業日<span class="badge badge-danger">必須</span></label>
              <div class="datetimepicker-input icon-addon-right">
                <b-form-input
                  v-model="su_company.founding_date"
                  :state="checkValid('founding_date')"
                  class="form-control"
                  id="founding_date"
                  type="text"
                  data-toggle="datetimepicker"
                  data-target="#founding_date"
                  autocomplete="off"
                  required
                ></b-form-input><i class="far fa-calendar-alt"></i>
              </div>
              <p v-if="!!errors['founding_date']" class="invalid-feedback d-block">{{ errors['founding_date'][0] }}</p>
            </div>

            <div class="form-group">
              <label class="required-addon" for="company-url">企業HP<span class="badge badge-secondary">任意</span></label>
              <b-form-input v-model="su_company.company_hp" type="text" class="form-control" :state="checkValid('company_hp')" id="company-url" placeholder="例) https://sixbrain.ai"></b-form-input>
              <p v-if="!!errors['company_hp']" class="invalid-feedback d-block">{{ errors['company_hp'][0] }}</p>
            </div>

            <div class="form-group">
              <label class="required-addon" for="company-ceo">代表者<span class="badge badge-secondary">任意</span></label>
              <b-form-input v-model="su_company.representative" type="text" class="form-control" :state="checkValid('representative')" id="company-ceo" placeholder="例) 代表取締役社長　斎藤 祐馬"></b-form-input>
              <p v-if="!!errors['representative']" class="invalid-feedback d-block">{{ errors['representative'][0] }}</p>
            </div>

            <div class="form-group">
              <label class="required-addon" for="growth-stage">ステージ<span class="badge badge-secondary">任意</span></label>
              <b-form-select v-model="su_company.stage" class="custom-select" :state="checkValid('stage')" id="growth-stage">
                <option value="">選択してください</option>
                <option v-for="(stage_i18n, stage) in stages" :key="stage" :value="stage">
                  {{ stage_i18n }}
                </option>
              </b-form-select>
            </div>

            <fieldset class="form-group">
              <legend class="required-addon mb-0">業界</legend>
              <div class="required-addon"><span class="badge badge-danger">必須</span>
                <div class="col d-flex">
                  <b-form-select v-model="su_company.industry_master_id" class="custom-select col-8" :state="checkValid('industry_master_id')" id="business-type">
                    <option selected disabled value>選択してください</option>
                    <option v-for="(industry_i18n, industry) in fetchIndustries" :key="industry" :value="industry">
                      {{ industry_i18n }}
                    </option>
                  </b-form-select>
                  <p v-if="!!errors['industry_master_id']" class="invalid-feedback d-block m-2">{{ errors['industry_master_id'][0] }}</p>
                </div>
              </div>
              <div class="required-addon mt-2"><span class="badge badge-secondary">任意</span>
                <div class="col d-flex">
                  <b-form-select v-model="su_company.sub_industry_master_id" class="custom-select col-8" :state="checkValid('sub_industry_master_id')" id="sub-business-type">
                    <option value="">選択してください</option>
                    <option v-for="(sub_industry_i18n, sub_industry) in fetchSubIndustries" :key="sub_industry" :value="sub_industry">
                      {{ sub_industry_i18n }}
                    </option>
                  </b-form-select>
                  <p v-if="!!errors['sub_industry_master_id']" class="invalid-feedback d-block m-2">{{ errors['sub_industry_master_id'][0] }}</p>
                </div>
              </div>
              <div class="required-addon mt-2"><span class="badge badge-secondary">任意</span>
                <div class="col d-flex">
                  <b-form-select v-model="su_company.sub2_industry_master_id" class="custom-select col-8" :state="checkValid('sub2_industry_master_id')" id="sub2-business-type">
                    <option value="">選択してください</option>
                    <option v-for="(sub2_industry_i18n, sub2_industry) in fetchSub2Industries" :key="sub2_industry" :value="sub2_industry">
                      {{ sub2_industry_i18n }}
                    </option>
                  </b-form-select>
                  <p v-if="!!errors['sub2_industry_master_id']" class="invalid-feedback d-block m-2">{{ errors['sub2_industry_master_id'][0] }}</p>
                </div>
              </div>
            </fieldset>

            <div class="form-group">
              <label class="required-addon" for="business-overview">私たちについて<span class="badge badge-secondary">任意</span></label>
              <b-form-textarea v-model="su_company.business_summary" type="text" class="form-control auto-resize" :state="checkValid('business_summary')" id="business-overview"
                placeholder="例) デロイト トーマツ ベンチャーサポート株式会社は、「挑戦する人とともに未来をひらく」をミッションに、
                ベンチャー企業・大手 企業・官公庁／地方自治体等が協働し、数多くのイノベーションを生み出す世界を目指し活動しています。"
               ></b-form-textarea>
              <p v-if="!!errors['business_summary']" class="invalid-feedback d-block">{{ errors['business_summary'][0] }}</p>
            </div>

            <fieldset>
              <legend class="required-addon">有限責任監査法人トーマツ及びDeloitteのメンバーファームからの監査<span class="badge badge-secondary">任意</span></legend>
              <div class="form-group custom-control custom-checkbox vertical-checkbox">
                <input v-model="su_company.is_audit" true-value='audit' false-value='unaudit' class="custom-control-input" type="checkbox" id="auditor">
                <label class="required-addon custom-control-label" for="auditor">受けている</label>
                <p v-if="!!errors['is_audit']" class="invalid-feedback d-block">{{ errors['is_audit'][0] }}</p>
              </div>
            </fieldset>

            <fieldset>
              <legend class="required-addon">情報公開許諾の有無<span class="badge badge-secondary">任意</span></legend>
              <div class="form-group custom-control custom-checkbox vertical-checkbox">
                <input v-model="su_company.is_publish_info" true-value='publish' false-value='unpublish' class="custom-control-input" type="checkbox" id="publisher">
                <label class="required-addon custom-control-label" for="publisher">公開する</label>
                <p v-if="!!errors['is_publish_info']" class="invalid-feedback d-block">{{ errors['is_publish_info'][0] }}</p>
              </div>
            </fieldset>

            <div class="form-group">
              <label class="required-addon" for="capital">現在時価総額（単位：百万円）<span class="badge badge-secondary">任意</span></label>
              <b-form-input v-model="su_company.current_market_capital" class="form-control" :state="checkValid('current_market_capital')" type="text" id="capital" placeholder="例) 10,000"></b-form-input>
              <p v-if="!!errors['current_market_capital']" class="invalid-feedback d-block">{{ errors['current_market_capital'][0] }}</p>
            </div>

            <div class="form-group">
              <label class="required-addon">直近ファイナンス日付<span class="badge badge-secondary">任意</span></label>
              <div class="datetimepicker-input icon-addon-right">
                <b-form-input
                  v-model="su_company.last_finance_date"
                  :state="checkValid('last_finance_date')"
                  class="form-control"
                  id="last_finance_date"
                  type="text"
                  data-toggle="datetimepicker"
                  data-target="#last_finance_date"
                  autocomplete="off"
                  required
                ></b-form-input><i class="far fa-calendar-alt"></i>
              </div>
              <p v-if="!!errors['last_finance_date']" class="invalid-feedback d-block">{{ errors['last_finance_date'][0] }}</p>
            </div>

          </div>
        </div>
      </section>
      <!-- 企業情報 -->

      <!-- 管理者情報 -->
      <section class="chapter white-block">
        <h2 class="chapter-label">管理者情報</h2>
        <div class="form-wrap d-flex border-top">

          <div class="left-col drop_area" @dragover.prevent="onArea" @drop.prevent="dropUserFile" @dragleave.prevent="offArea" @dragend.prevent="offArea">
            <!-- 既に画像が登録されている -->
            <img v-if="su_company.user_attributes.image_attributes.filename"
              class="thumbnail-frame img180 mx-auto mb-3"
              :src="su_company.user_attributes.presigned_url"
              :alt="su_company.user_attributes.image_attributes.filename"
            />
            <!-- 新しく画像が選択された -->
            <img v-else-if="su_company.user_attributes.uploaded_image"
              class="thumbnail-frame img180 mx-auto mb-3"
              :src="su_company.user_attributes.uploaded_image"
              :alt="su_company.user_attributes.new_image_name"
            />
            <!-- 画像が登録されていない -->
            <img v-else
              class="thumbnail-frame img180 mx-auto mb-3"
              src="~images/su_user_default.png"
              alt="株式会社シックスブレイン logo"
            />
            <p v-if="!!errors['user.image']" class="invalid-feedback d-block">{{ errors['user.image'][0]}}</p>
            <p v-if="!!errors['user.image_size']" class="invalid-feedback d-block">{{ errors['user.image_size'][0]}}</p>
            <div class="btn-group row" role="group" aria-label="画像アップロード">
              <button @click="removeUser" class="btn btn-cancel icon-addon-left col-4" type="button" data-toggle="modal" data-target="#delete"><i class="fas fa-times"></i>削除</button>
              <label class="btn btn-file icon-addon-left col-8" for="user-file-upload"><i class="fas fa-arrow-up"></i>アップロード
                <input type="file" id="user-file-upload" :accept="accept_extensions" @change="onUserFileChange" ref="userImageUploader">
              </label>
            </div>
          </div>

          <div class="right-col">
            <fieldset>
              <div class="form-row">
                <div class="form-group col-6">
                  <label class="required-addon" for="pic-name1">姓<span class="badge badge-danger">必須</span></label>
                  <b-form-input class="form-control" :state="checkValid('user.last_name')" v-model="su_company.user_attributes.last_name" type="text" id="pic-name1" placeholder="例) デロイト" required></b-form-input>
                  <p v-if="!!errors['user.last_name']" class="invalid-feedback d-block">{{ errors['user.last_name'][0] }}</p>
                </div>
                <div class="form-group col-6">
                  <label class="required-addon" for="pic-name2">名<span class="badge badge-danger">必須</span></label>
                  <b-form-input class="form-control" :state="checkValid('user.first_name')" v-model="su_company.user_attributes.first_name" type="text" id="pic-name2" placeholder="例) 太郎" required></b-form-input>
                  <p v-if="!!errors['user.first_name']" class="invalid-feedback d-block">{{ errors['user.first_name'][0] }}</p>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <div class="form-row">
                <div class="form-group col-6">
                  <label class="required-addon" for="administrator-name1-kana">セイ<span class="badge badge-secondary">任意</span></label>
                  <b-form-input v-model="su_company.user_attributes.kana_last_name" :state="checkValid('user.kana_last_name')" type="text" class="form-control" id="administrator-name1-kana" placeholder="例) デロイト"></b-form-input>
                  <p v-if="!!errors['user.kana_last_name']" class="invalid-feedback d-block">{{ errors['user.kana_last_name'][0] }}</p>
                </div>
                <div class="form-group col-6">
                  <label class="required-addon" for="administrator-name2-kana">メイ<span class="badge badge-secondary">任意</span></label>
                  <b-form-input v-model="su_company.user_attributes.kana_first_name" :state="checkValid('user.kana_first_name')" type="text" class="form-control" id="administrator-name2-kana" placeholder="例) タロウ"></b-form-input>
                  <p v-if="!!errors['user.kana_first_name']" class="invalid-feedback d-block">{{ errors['user.kana_first_name'][0] }}</p>
                </div>
              </div>
            </fieldset>
            <div class="form-group">
              <label class="required-addon" for="administrator-email">メールアドレス（ログインID）<span class="badge badge-danger">必須</span></label>
              <b-form-input v-model="su_company.user_attributes.email" type="email" :state="checkValid('user.email')" class="form-control" placeholder="例) t-deloitte@sixbrain.co.jp"></b-form-input>
              <p v-if="!!errors['user.email']" class="invalid-feedback d-block">{{ errors['user.email'][0] }}</p>
            </div>
            <div class="form-group">
              <label class="required-addon" for="pic-title">役職名<span class="badge badge-secondary">任意</span></label>
              <b-form-input v-model="su_company.user_attributes.position" class="form-control" :state="checkValid('user.position')" type="text" id="pic-title" placeholder="例) CTO"></b-form-input>
              <p v-if="!!errors['user.position']" class="invalid-feedback d-block">{{ errors['user.position'][0] }}</p>
            </div>
            <div class="form-group">
              <label class="required-addon" for="department-name">部署名<span class="badge badge-secondary">任意</span></label>
              <b-form-input v-model="su_company.user_attributes.department" class="form-control" :state="checkValid('user.department')" type="text" id="department-name" placeholder="例) ライフケア事業部"></b-form-input>
              <p v-if="!!errors['user.department']" class="invalid-feedback d-block">{{ errors['user.department'][0] }}</p>
            </div>
            <div class="form-group">
              <label class="required-addon" for="profile">プロフィール<span class="badge badge-secondary">任意</span></label>
              <b-form-textarea v-model="su_company.user_attributes.profile" :state="checkValid('user.profile')" type="text" class="form-control auto-resize"></b-form-textarea>
              <p v-if="!!errors['user.profile']" class="invalid-feedback d-block">{{ errors['user.profile'][0] }}</p>
            </div>
          </div>
        </div>
      </section>
      <!-- 管理者情報-->
      <div class="btn-container d-flex justify-content-center">
        <b-button @click="show=true" class="button-square btn-cancel" type="button" data-toggle="modal" data-target="#cancel">もどる</b-button>
        <button class="button-square btn-send" type="submit">確認する</button>
      </div>
      <!-- 戻るモーダル-->
      <b-modal v-model="show" title="" centered size="sm" hide-header :no-fade="isNoFade">
        <div class="modal-body">
          <p class="mt-3 text-center">編集内容を破棄して一覧画面に戻ります。<br>よろしいですか？</p>
        </div>
        <template v-slot:modal-footer>
          <button class="btn btn-cancel" type="button" @click="backToIndex()">一覧にもどる</button>
          <button class="btn btn-send" type="button" data-dismiss="modal" @click="show=false">編集を続ける</button>
        </template>
      </b-modal>
      <!-- / 戻るモーダル-->
    </form>
  </main>
</template>


<script>
  import Form from '../../../common/mixins/form'
  // アップロード画像チェック
  import UploadFileCheck  from "../../../common/mixins/upload_file_check"
  export default {
    metaInfo: {
      title: 'スタートアップ企業プッシュ登録'
    },
    mixins: [Form, UploadFileCheck],
    props: {
      su_company: Object | String,
      stages: Object,
      prefectures: Object,
      industries: Object,
      accept_extensions: Array | String,
      is_audits: Object,
      is_publish_infos: Object,
      isNoFade: Boolean,
    },
    data() {
      return {
        days_max: '',
        show: false,
        was_valid: false,
        errors: '',
        previous_route: null,
      }
    },
    methods: {
      // 確認ボタン押下時
      confirmSuCompany () {
        this.$loading.load(this.$auth.api.post('admin/su_companies/new_confirm', {
          su_company: this.su_company,
        })
        .then(response => {
          this.$router.push({ name: 'AdminSuCompanyConfirm' });
        })
        .catch(error => {
          if(error.response.status == 422) {
            if(error.response.data && error.response.data.errors) {
              this.errors = error.response.data.errors;
              this.was_valid = true
            }
            // エラー時ページトップにスクロール
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
          }else if(error.response.status == 500) {
            this.$errorHandlers.initial(error);
          }else{
            this.$errorHandlers.ajax(error);
          }
        }));
      },

      // 戻るボタン押下時
      backToIndex () {
        if (!!this.previous_route && this.previous_route.name === 'AdminSuIndex') {
          this.$router.push({path: this.previous_route.fullPath});
        } else {
          this.$router.push({name: 'AdminSuIndex'});
        }
      },

      // ユーザー画像ーーーーーーーーーーーーーー
      onUserFileChange(e) {
        const files = e.target.files || e.dataTransfer.files;

        // ファイルチェック(/common/mixins/upload_file_check.jsを参照)
        let image_file_error = this.checkUploadImage(files[0]);
        if (image_file_error != ''){
          // エラーの場合はトースト表示
          this.$bvToast.toast(image_file_error, { variant: 'danger', title: 'エラー'});
          return;
        }

        this.su_company.user_attributes.image_attributes.filename = ''
        this.createUserImage(files[0]);
        this.su_company.user_attributes.new_image_name = files[0].name;
        this.su_company.user_attributes.image_size = files[0].size;
      },
      createUserImage(file) {
        const reader = new FileReader();
        reader.onload = e => {
        this.su_company.user_attributes.uploaded_image = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeUser() {
        this.su_company.user_attributes.uploaded_image = false;
        this.su_company.user_attributes.image_attributes.filename = '';
        this.su_company.user_attributes.uploaded_image = '';
        this.su_company.user_attributes.new_image_name = '';
        this.su_company.user_attributes.image_size = '';
        // 一度アップした画像を取り消した後再度同じ画像をアップすると@changeが発火しないため、refでDOMを直接引っ張ってvalueを消す
        this.$refs.userImageUploader.value = '';
      },
      dropUserFile(e) {
          this.onUserFileChange(e)
          this.offArea()
      },

      // 画像共通ーーーーーーーーーーーーー
      onArea  () { this.inArea = true  },
      offArea () { this.inArea = false },

    },
    computed: {
      // 業界プルダウン(選択中の業界と被らないように選択肢を絞る)
      // メイン業界
      fetchIndustries () {
        let entries = Object.entries(this.industries);
        entries = entries.filter(([id]) => id !== this.su_company.sub_industry_master_id.toString());
        entries = entries.filter(([id]) => id !== this.su_company.sub_industry_master_id);
        entries = entries.filter(([id]) => id !== this.su_company.sub2_industry_master_id.toString());
        entries = entries.filter(([id]) => id !== this.su_company.sub2_industry_master_id);
        return Object.fromEntries(entries);
      },
      // サブ業界1
      fetchSubIndustries () {
        let entries = Object.entries(this.industries);
        entries = entries.filter(([id]) => id !== this.su_company.industry_master_id.toString());
        entries = entries.filter(([id]) => id !== this.su_company.industry_master_id);
        entries = entries.filter(([id]) => id !== this.su_company.sub2_industry_master_id.toString());
        entries = entries.filter(([id]) => id !== this.su_company.sub2_industry_master_id);
        return Object.fromEntries(entries);
      },
      // サブ業界2
      fetchSub2Industries () {
        let entries = Object.entries(this.industries);
        entries = entries.filter(([id]) => id !== this.su_company.industry_master_id.toString());
        entries = entries.filter(([id]) => id !== this.su_company.industry_master_id);
        entries = entries.filter(([id]) => id !== this.su_company.sub_industry_master_id.toString());
        entries = entries.filter(([id]) => id !== this.su_company.sub_industry_master_id);
        return Object.fromEntries(entries);
      }
    },
    updated(){
      // テキストエリア 改行時高さ自動調整
      this.setTextAreaAutoSize();
    },
    // コンポーネントを描画するルートが確立する前に呼ばれる
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.previous_route = from;
      });
    },
    mounted() {
      const self = this;
      // 直近ファイナンス日 のカレンダー設定
      this.setDatePicker(
        '#last_finance_date',
        function(selected_date) {
          self.su_company.last_finance_date = selected_date;
        },
        'top'
      );
      // 創業日 のカレンダー設定
      this.setDatePicker(
        '#founding_date',
        function(selected_date) {
          self.su_company.founding_date = selected_date;
        },
        'top'
      );
    }
  }
</script>
