<template>
  <div>
    <main>
      <form @submit.prevent>
        <section class="chapter white-block pb-2">
          <h2 class="chapter-label">アセット名</h2>
          <div class="form-group px-3">
            <input
              v-model="form.asset_master.name"
              class="form-control"
              type="text"
              :disabled="false"
            >
            <div v-if="!!validation_errors.name" v-for="error_message in validation_errors.name" class="invalid-feedback d-block" style="display: block">
              {{ error_message }}
            </div>
          </div>
        </section>

        <section class="chapter white-block pb-2">
          <h2 class="chapter-label">大手業界</h2>
          <div class="btn-container edit-btn-position">
            <button
              class="button-square btn-sm btn-send"
              type="button"
              @click="$refs.industry_select_modal.open()"
            >
              {{ !edit_asset_master_id? '登録する' : '編集する' }}
            <i class="fas fa-clone pl-2"></i></button>
          </div>
          <template>
            <div class="asset-block border-top p-3">
              <ul class="task-labels-wrap">
                <li v-for="(industry_asset_relation_master, index) in form.asset_master.industry_asset_relation_masters_attributes" class="industry-labels">
                  {{ industryIdIs(industry_asset_relation_master.industry_master_id).name }}
                </li>
              </ul>
            </div>
          </template>
        </section>

        <section class="chapter white-block pb-2">
          <h2 class="chapter-label">スタートアップ業界</h2>
          <div class="btn-container edit-btn-position">
            <button
              class="button-square btn-sm btn-send"
              type="button"
              @click="$refs.su_industry_select_modal.open()"
            >
              {{ !edit_asset_master_id? '登録する' : '編集する' }}
              <i class="fas fa-clone pl-2"></i></button>
          </div>
          <template>
            <div class="asset-block border-top p-3">
              <ul class="task-labels-wrap">
                <li v-for="(industry_asset_relation_master, index) in form.asset_master.su_industry_asset_relation_masters_attributes" class="industry-labels">
                  {{ suIndustryIdIs(industry_asset_relation_master.su_industry_master_id).name }}
                </li>
              </ul>
            </div>
          </template>
        </section>

        <div v-if="!!edit_asset_master_id">
          <section :class="[{ 'disabled' : is_dtvscm || !form.asset_master.was_undecided },'chapter white-block pb-2']">
            <h2 class="chapter-label">ステータス</h2>
            <div class="form-group px-3">
              <select v-model="form.asset_master.exam_status" class="form-control custom-select" id="">
                <option value="">選択してください</option>
                <option
                  v-for="(exam_statuses_i18n, exam_status_key) in exam_statuses"
                  v-model="form.asset_master.exam_status"
                  :key="exam_status_key"
                  :value="exam_status_key">
                  {{ exam_statuses_i18n }}
                </option>
              </select>
              <div v-if="!!validation_errors.exam_status" v-for="error_message in validation_errors.exam_status" class="invalid-feedback d-block" style="display: block">
                {{ error_message }}
              </div>
            </div>
          </section>
        </div>

        <div class="btn-container d-flex justify-content-center">
          <button @click="onClickBack()" class="button-square btn-cancel" type="button">一覧へもどる</button>
          <button class="button-square btn-send" @click="onClickSubmit" type="button">{{ !edit_asset_master_id? '登録する' : '更新する' }}</button>
        </div>

      </form>

      <!-- EP業界のモーダル-->
      <IndustrySelectModal
        ref="industry_select_modal"
        :onClickSend="onSelectedEpIndustries"
        :header_name="form.asset_master.name"
        :industries="ep_industries"
        :select_industry_ids="this.form.asset_master.industry_asset_relation_masters_attributes.map(function(v){ return v.industry_master_id })"
        :isNoFade="isNoFade"
      />

      <!-- SU業界のモーダル-->
      <IndustrySelectModal
        ref="su_industry_select_modal"
        :onClickSend="onSelectedSuIndustries"
        :header_name="form.asset_master.name"
        :industries="su_industries"
        :select_industry_ids="this.form.asset_master.su_industry_asset_relation_masters_attributes.map(function(v){ return v.su_industry_master_id })"
        :isNoFade="isNoFade"
      />

      <ConfirmBackToIndexModal
        ref="back_to_index_confirm_modal"
        :body_text="'入力内容を破棄して一覧画面に戻ります。<br>よろしいですか？'"
        :index_page_name="'AdminAssetMasterIndex'"
        :from_page_info="from_page_info"
        :isNoFade="isNoFade"
      />

    </main>
  </div>
</template>

<script>

  // 業界選択モーダル読み込み
  import IndustrySelectModal     from '../../../common/pages/IndustrySelectModal';
  // 一覧戻る確認モーダル
  import ConfirmBackToIndexModal from '../../../common/pages/ConfirmBackToIndexModal';
  export default {

    props: {
      // 編集対象のアセットID
      edit_asset_master_id: {
        type: [String, Number],
      },
      // 保存ボタン押下時のコールバックメソッド
      onFormPost: {
        type: Function,
        required: true,
      },
      // 削除ボタン押下時のコールバックメソッド
      onDelete: {
        type: Function,
      },
      // バリデーションエラー
      validation_errors: {
        type: Object,
        default: () => ({})
      },
      from_page_info: {
        type: Object,
      },
      isNoFade: {
        type: Boolean,
      }
    },

    components: {
      IndustrySelectModal,
      ConfirmBackToIndexModal,
    },

    data() {
      return {
        edit_asset_master: [],
        // 全ての業界
        ep_industries: [],
        su_industries: [],
        exam_statuses: [],
        is_dtvscm: true,
        is_form_changed: false,
        form: {
          asset_master: {
            name: '',
            updated_at: '',
            exam_status: '',
            industry_asset_relation_masters_attributes: [
              // {
              //   industry_master_id: '',
              // }
            ],
            su_industry_asset_relation_masters_attributes: [
              // {
              //   su_industry_master_id: '',
              // }
            ],
            current_relation_industry_master_ids: [
              //
              // 配列
              //
            ],
            current_relation_su_industry_master_ids: [
              //
              // 配列
              //
            ],
          },
        },
      }
    },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.init();
      if( !!this.edit_asset_master_id ) {
        this.getEditAssetMaster();
      }
      else{
        // フォームの変更監視を開始
        this.startWatchForm();
      }
    },

    // メソッド定義
    methods: {
      // 初期化
      init() {
        this.$loading.load(
          this.$auth.api.get(
            'admin/asset_masters/init_form', {
              params: {
              }
            }
          ).then(res => {
            this.ep_industries = res.data.ep_industries;
            this.su_industries = res.data.su_industries;
            this.exam_statuses = res.data.exam_statuses;
          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        )
      },

      // 編集対象のアセット取得
      getEditAssetMaster() {
        this.$loading.load(
          this.$auth.api.get(
            'admin/asset_masters/' + this.edit_asset_master_id + '/edit/', {
              params: {
              }
            }
          ).then(res => {
            this.is_dtvscm = res.data.is_dtvscm
            this.edit_asset_master = res.data.asset_master;
            this.form.asset_master.name = this.edit_asset_master.name;
            this.form.asset_master.updated_at = this.edit_asset_master.updated_at;
            this.form.asset_master.exam_status = this.edit_asset_master.exam_status;
            this.form.asset_master.was_undecided = this.edit_asset_master.was_undecided;
            this.form.asset_master.industry_asset_relation_masters_attributes = Object.values(res.data.asset_master.industry_masters).map(function (industry_master) {
              return { industry_master_id: industry_master.id }
            });
            this.form.asset_master.su_industry_asset_relation_masters_attributes = Object.values(res.data.asset_master.su_industry_masters).map(function (industry_master) {
              return { su_industry_master_id: industry_master.id }
            });
            // 排他処理用にページ遷移時の業界IDを配列で持っておく
            this.form.asset_master.current_relation_industry_master_ids = this.form.asset_master.industry_asset_relation_masters_attributes.map(industry_master => industry_master.industry_master_id);
            this.form.asset_master.current_relation_su_industry_master_ids = this.form.asset_master.su_industry_asset_relation_masters_attributes.map(su_industry_master => su_industry_master.su_industry_master_id)

            // フォームの変更監視を開始
            this.startWatchForm();

          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        )
      },

      // 入力項目変更の監視を開始する
      startWatchForm(){
        this.$watch('form', function(){ this.is_form_changed = true }, { deep: true });
      },

      // 一覧へもどるボタン押下時処理
      onClickBack(){
        this.is_form_changed ? this.$refs.back_to_index_confirm_modal.open() : this.$refs.back_to_index_confirm_modal.onSend();
      },

      // 保存ボタン押下時処理
      onClickSubmit() {
        this.onFormPost(this.form);
      },

      // 業界選択モーダルの適用するボタン押下時処理
      onSelectedEpIndustries(selected_industry_ids) {
        this.form.asset_master.industry_asset_relation_masters_attributes = Object.values(selected_industry_ids).map(function (selected_industry_id) {
          return { industry_master_id: selected_industry_id }
        });
        this.$refs.industry_select_modal.close();
      },

      // 業界選択モーダルの適用するボタン押下時処理
      onSelectedSuIndustries(selected_industry_ids) {
        this.form.asset_master.su_industry_asset_relation_masters_attributes = Object.values(selected_industry_ids).map(function (selected_industry_id) {
          return { su_industry_master_id: selected_industry_id }
        });
        this.$refs.su_industry_select_modal.close();
      },

      // 業界をIDで検索
      industryIdIs(id) {
        var industry = this.ep_industries.find((industry) => industry.id === id);
        return !!industry ? industry : { id: null, name: '' };
      },
      // 業界をIDで検索
      suIndustryIdIs(id) {
        var industry = this.su_industries.find((industry) => industry.id === id);
        return !!industry ? industry : { id: null, name: '' };
      },
    },
  }
</script>
