<template>
  <div>
    <section id="pic_list" class="chapter white-block mt-5">
      <h2 class="chapter-label">子アカウント一覧</h2>

      <template v-if="current_api_v1_user.account_type == 'ep_admin' || baseApiUrl == 'admin'">
        <div class="btn-container edit-btn-position">
          <button v-if="current_api_v1_user.account_type == 'ep_admin'" @click="$router.push({ name: 'EpPersonInChargeNewForm' })" class="btn btn-green rounded-pill" type="button">新規作成</button>
          <button v-if="accountType == 'dtvs_admin'" @click="$router.push({ name: 'AdminEpPersonInChargeNewForm', params: {id: ep_company_id}})" class="btn btn-green rounded-pill" type="button">新規作成</button>
        </div>
      </template>
      <div class="table-responsive">
        <table class="table table-striped table-hover" v-if="sorted_ep_users">
          <thead class="thead-light">
            <tr>
              <th class="w-5">氏名</th>
              <th class="w-10"></th>
              <th class="w-10">部署名</th>
              <th class="w-15">メールアドレス</th>
              <th class="w-5"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="sorted_ep_user in sorted_ep_users" :key="sorted_ep_user.id">
              <td>
                <img v-if="!!sorted_ep_user.image_attributes && sorted_ep_user.image_attributes.filename" class="thumbnail-frame img60"
                  :src="sorted_ep_user.presigned_url"
                  :alt="sorted_ep_user.image_attributes.filename"
                />
                <img v-else
                  class="thumbnail-frame img60"
                  src="~images/ep_user_default.png"
                  alt="画像が選択されていません"
                />
              </td>
              <td>{{fullName(sorted_ep_user)}}</td>
              <td><template v-if="!!sorted_ep_user.department">{{sorted_ep_user.department}}</template></td>
              <td>{{sorted_ep_user.email}}</td>
              <td class="text-right" v-if="current_api_v1_user.account_type === 'ep_person_in_charge'">
                <button v-if="current_api_v1_user.id == sorted_ep_user.id" class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'詳細'" @click="$router.push({name: 'EpPersonInChargeShow', params: {id: sorted_ep_user.id}})"><i class="fas fa-ellipsis-h"></i></button>
                <button v-if="current_api_v1_user.id == sorted_ep_user.id" class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'編集'" @click="$router.push({name: 'EpPersonInChargeEditForm', params: {id: sorted_ep_user.id}})"><i class="fas fa-pen"></i></button>
              </td>
              <td class="text-right" v-else>
                <button v-if="baseApiUrl === 'admin'" class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'詳細'" @click="$router.push({name: 'AdminEpPersonInChargeShow', params: {id: sorted_ep_user.id}})"><i class="fas fa-ellipsis-h"></i></button>
                <button v-if="baseApiUrl === 'ep'" class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'詳細'" @click="$router.push({name: 'EpPersonInChargeShow', params: {id: sorted_ep_user.id}})"><i class="fas fa-ellipsis-h"></i></button>

                <button v-if="accountType == 'dtvs_admin'" class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'編集'" @click="$router.push({name: 'AdminEpPersonInChargeEditForm', params: {id: sorted_ep_user.id}})"><i class="fas fa-pen"></i></button>
                <button v-if="baseApiUrl === 'ep'" class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'編集'" @click="$router.push({name: 'EpPersonInChargeEditForm', params: {id: sorted_ep_user.id}})"><i class="fas fa-pen"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <!--子アカウント一覧-->
  </div>

</template>

<script>
  import VueScrollTo from 'vue-scrollto'

  export default {

    props: {
      baseApiUrl: {
        type: String
      },
      ep_company_id: {
        type: [Number,String]
      },
      registration_request_id: {
        type: [Number,String]
      },
      accountType: {
        type: String
      },
    },

    // propsを多重に経由してるせいかcreatedのタイミングではep_company_idがundifindになのでwatchで起動
    watch: {
      ep_company_id(new_ep_company_id,old_ep_company_id){
        if (!!new_ep_company_id) {
          this.getDepartmentsAndUsers();
        }
      },
    },

    data(){
      return {
        sorted_ep_users: [],
        current_api_v1_user: {},
      }
    },
    methods: {
      fullName: function (user) {
        return `${user.last_name} ${user.first_name}`
      },
      getDepartmentsAndUsers(){
        this.$loading.load(this.$auth.api.get(`${this.baseApiUrl}/ep_companies/${!!this.registration_request_id ? this.registration_request_id : this.ep_company_id}`, {
        })
        .then(res => {
          this.sorted_ep_users = res.data.sorted_ep_users
          this.current_api_v1_user = res.data.current_api_v1_user
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
      },
    },
    created: function(){
      if (!!this.ep_company_id) {
        this.getDepartmentsAndUsers();
      }
    },
    beforUpdate: function(){
      this.getDepartmentsAndUsers();
    },
  }

</script>
