<template>
  <div>
    <main>
      <section class="main-title">
        <h1>アセットマスタ一覧</h1>
      </section>
      <!-- 検索-->
      <section class="chapter white-block">
        <form @submit.prevent>
          <div class="accordion" id="serach">
            <div class="form-group card">
              <div class="card-header" id="searach-parent"><a class="collapsed" data-toggle="collapse" href="#" @click.prevent.stop="" v-b-toggle.searach-toggle-child>検索<i class="fas"></i></a></div>
              <b-collapse id="searach-toggle-child" visible accordion="my-accordion" role="tabpanel">
                <div class="card-body collapse show" id="searach-child">

                  <div class="form-group row">
                    <label class="col-2 col-form-label" for="asset">アセット名</label>
                    <div class="col-10">
                      <input v-model="form.name" class="form-control" type="text" id="asset">
                    </div>
                  </div>

                  <fieldset class="form-group">
                    <div class="row">
                      <legend class="col-2 col-form-label">大手業界</legend>
                      <div class="col-4">
                        <select class="form-control custom-select" id="" v-model="form.ep_industry_id">
                          <option value="">選択してください</option>
                          <option v-for="ep_industry_master in ep_industry_masters" :key="ep_industry_master.id" :value="ep_industry_master.id">
                            {{ ep_industry_master.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </fieldset>

                  <fieldset class="form-group">
                    <div class="row">
                      <legend class="col-2 col-form-label">スタートアップ業界</legend>
                      <div class="col-4">
                        <select class="form-control custom-select" id="" v-model="form.su_industry_id">
                          <option value="">選択してください</option>
                          <option v-for="su_industry_master in su_industry_masters" :key="su_industry_master.id" :value="su_industry_master.id">
                            {{ su_industry_master.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </fieldset>

                  <fieldset class="form-group">
                    <div class="row">
                      <legend
                          id="model-type-info"
                          class="col-2 col-form-label">
                        ステータス
                      </legend>
                      <div class="col-10 align-self-center">
                        <div v-for="(exam_statuses_i18n, exam_status_key) in exam_statuses" :key="exam_status_key" class="custom-control custom-checkbox custom-control-inline">
                          <input
                              v-model="form.exam_statuses"
                              type="checkbox"
                              class="custom-control-input"
                              :id="'status_' + exam_status_key"
                              :value="exam_status_key"
                          >
                          <label :for="'status_' + exam_status_key" class="custom-control-label" >{{ exam_statuses_i18n }}</label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <p class="search-no-data" v-if="!asset_masters">検索条件に一致するものはありません。</p>
                  <div class="btn-container d-flex justify-content-end">
                    <button v-on:click="onSearch" class="button-square btn-send" type="button">検索する</button>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </form>
      </section>
      <!-- 検索-->

      <!-- リスト-->
      <section class="chapter white-block challenge">
        <div class="btn-container d-flex justify-content-between mb-3">
          <button @click="$router.push({ name: 'AdminAssetMasterNew' })" class="btn btn-green rounded-pill" type="button">新規作成</button>
        </div>
        <div v-if="!!asset_masters" class="table-responsive">
          <table class="table table-striped table-hover">
            <thead class="thead-light">
              <tr>
                <th class="w-15em">アセット名</th>
                <th class="w-10em">ステータス</th>
                <th class="miw-15em">業界
                  <i class="fas fa-info-circle icon-gray ml-1" id="ep-industry-info-tooltip"></i>
                  <b-tooltip target="ep-industry-info-tooltip" triggers="hover">
                    業界をマウスオーバーすると<br>業界名が確認できます。
                  </b-tooltip>
                </th>
                <th class="w-15">更新者</th>
                <!-- 承認却下ボタン」を固定させるために最右列の一つ前に置く -->
                <th hidden></th>
                <th class="w-5"></th>
                <th class="w-15"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="asset_master in asset_masters" :key="asset_master.id">
                <td>{{ asset_master.name }}</td>
                <td>{{ asset_master.exam_status_i18n }}</td>
                <td class="asset-td">
                  <!-- EP業界 -->
                  <template v-for="ep_industry_master in asset_master.ep_industry_masters">
                    <!-- 短いアセット名を持っている -->
                    <template v-if="!!ep_industry_master.short_name">
                      <label class="popover-trigger text-ellipsis" :id="'ep-industry-master-name-' + asset_master.id + '-' + ep_industry_master.id" tabindex="0" data-content="" :key="ep_industry_master.id">
                        {{ ep_industry_master.short_name }}
                      </label>
                      <b-popover
                        :target="'ep-industry-master-name-' + asset_master.id + '-' + ep_industry_master.id"
                        triggers="hover"
                        placement="auto"
                        :key="ep_industry_master.id"
                      >
                        <span>{{ ep_industry_master.name | htmlEscape | nl2br }}</span>
                      </b-popover>
                    </template>
                    <!-- 短いアセット名を持っていない -->
                    <template v-else>
                      <label :key="ep_industry_master.id">{{ ep_industry_master.name }}</label>
                    </template>
                  </template>

                  <!-- SU業界 -->
                  <template v-for="su_industry_master in asset_master.su_industry_masters">
                    <!-- 短いアセット名を持っている -->
                    <template v-if="!!su_industry_master.short_name">
                      <label class="popover-trigger text-ellipsis" :id="'su-industry-master-name-' + asset_master.id + '-' + su_industry_master.id" tabindex="0" data-content="" :key="su_industry_master.id">
                        {{ su_industry_master.short_name }}
                      </label>
                      <b-popover
                        :target="'su-industry-master-name-' + asset_master.id + '-' + su_industry_master.id"
                        triggers="hover"
                        placement="auto"
                        :key="su_industry_master.id"
                      >
                        <span>{{ su_industry_master.name | htmlEscape | nl2br }}</span>
                      </b-popover>
                    </template>
                    <!-- 短いアセット名を持っていない -->
                    <template v-else>
                      <label  :key="su_industry_master.id">{{ su_industry_master.name }}</label>
                    </template>
                  </template>
                </td>
                <td>
                  <template v-if="!!asset_master.short_updater_name">
                    <a
                      :id="'updaters_' + asset_master.id"
                      class="popover-trigger text-ellipsis"
                      tabindex="0">
                      {{ asset_master.short_updater_name }}
                      <br>{{ dispDeletedCompany(asset_master.company) }}{{ dispDeletedUser(asset_master.user) }}
                    </a>
                    <b-popover
                      :target="'updaters_' + asset_master.id"
                      triggers="hover"
                      placement="auto">
                      <div
                        style="margin: -1rem -1.5em; padding: 1rem 1.5em; position: relative; z-index: 1;"
                        @click="$root.$emit('bv::hide::popover')">
                        {{ asset_master.updater_name }}
                      </div>
                    </b-popover>
                  </template>
                  <template v-else>
                    <a>{{ asset_master.updater_name }}<br>{{ dispDeletedCompany(asset_master.company) }}{{ dispDeletedUser(asset_master.user) }}</a>
                  </template>
                </td>
                <!-- 承認却下ボタン」を固定させるために最右列の一つ前に置く -->
                <td hidden>
                  {{asset_master.updated_at | format.date}}
                </td>
                <td>
                  <button v-if="is_admin" @click="onDelete(asset_master.id, asset_master.updated_at)"
                    class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'削除'"><i class="fas fa-trash"></i>
                  </button>
                  <button v-if="is_admin || asset_master.is_undecided"
                    @click="$router.push({name: 'AdminAssetMasterEdit', params: { asset_master_id: asset_master.id }})"
                    class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'編集'"><i class="fas fa-pen"></i>
                  </button>
                </td>
                <td>
                  <!-- 管理者でログイン中 かつ 未対応のアセット のみ承認却下ボタン表示 -->
                  <template v-if="is_admin && asset_master.is_undecided">
                    <button @click="onApproval(asset_master.id, asset_master.updated_at)" type="button" class="btn btn-sm btn-green rounded-pill">承認</button>
                    <button @click="onRejection(asset_master.id, asset_master.updated_at)" type="button" class="btn btn-sm btn-green rounded-pill">却下</button>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <Pagination :total_page="total_page" :total_count="total_count" :current_page="current_page" :current_per="current_per" :page_link_num="page_link_num" :onChangePage="onChangePage" v-if="!!asset_masters" />

      </section>
      <!-- リスト-->
      <ConfirmModal
          ref="delete_confirm_modal"
          :onClickSend="onDeleteConfirmSend"
          :body_text="'既に使用されている場合も削除します。<br>よろしいですか？'"
          :send_button_text="'削除する'"
          :isNoFade="isNoFade"
      />

    </main>
  </div>
</template>

<script>
  // ページネーションのコンポーネント読み込み
  import Pagination from '../../../common/pages/Pagination.vue';
  // 削除データ表示用コンポーネント
  import WithDeleted  from "../../../common/mixins/with_deleted"
  // 確認モーダルのコンポーネント読み込み
  import ConfirmModal from '../../../common/pages/ConfirmModal.vue';

  export default {

    metaInfo: {
      title: 'アセットマスタ一覧',
    },

    components: {
      Pagination,
      ConfirmModal
    },

    mixins: [WithDeleted],

    data() {
      return {
        // 現在ページ数
        current_page: Number(this.$route.query.page) || 1,
        // 現在の表示件数
        current_per:  0,
        // 総ページ数
        total_page: 0,
        // 総レコード数
        total_count: 0,
        // 表示するリンクの数
        page_link_num: 5,
        asset_masters: [],
        exam_statuses: {},
        ep_industry_masters: [],
        su_industry_masters: [],
        industry_name_conversion: '',
        updater_conversion: '',
        is_admin: false,
        form: {
          ep_industry_id: this.$route.query.ep_industry_id || '',
          su_industry_id: this.$route.query.su_industry_id || '',
          name: this.$route.query.name || '',
          exam_statuses: this.$route.query.exam_statuses || ['undecided'],
          // 検索処理で使用する現在のページ位置
          page: this.$route.query.page || '',
        },
      }
    },

    props: { isNoFade: Boolean },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.init();
      // 審査ステータス 1つだけの場合、textになるのを防ぐ処理
      this.form.exam_statuses = this.statusesCheck(this.form.exam_statuses);
      this.$route.query.exam_statuses = this.statusesCheck(this.$route.query.exam_statuses)

      this.getAssetMasters(this.$route.query);
      // 初期表示からのページ遷移時,undecidedを保持させるためformを$route.queryに入れる
      this.$route.query.exam_statuses = this.form.exam_statuses;
    },

    // route.push時に実行
    beforeRouteUpdate (to, from, next) {
      this.getAssetMasters(to.query);
      next();
    },

    // メソッド定義
    methods: {
      // 初期化
      init() {
        this.$loading.load(
          this.$auth.api.get(
            'admin/asset_masters/init_index'
          ).then(res => {
            this.ep_industry_masters = res.data.ep_industry_masters;
            this.su_industry_masters = res.data.su_industry_masters;
            this.exam_statuses = res.data.exam_statuses;
          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        )
      },

      // データ取得処理
      getAssetMasters(query) {
        this.$loading.load(
          this.$auth.api.get(
            'admin/asset_masters', {
              params: query
            }
          ).then(res => {
            this.asset_masters = res.data.asset_masters;
            this.current_per  = Number(res.data.per);
            this.current_page = Number(res.data.page);
            this.total_page   = res.data.total_page;
            this.total_count  = res.data.total_count;
            this.is_admin = res.data.is_admin
          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        )
      },

      // 検索ボタン押下時
      onSearch(query = []) {
        this.form.page = this.current_page = 1;
        this.form.per  = this.current_per;
        // 画面更新
        this.reload(this.form);
      },

      // ページ切り替え時
      onChangePage(page, per) {
        let query = Object.assign({}, this.$route.query);
        query['page'] = this.current_page = page;
        query['per'] = this.current_per = per;
        // 画面更新
        this.reload(query);
      },

      // 更新処理
      reload(query = []) {
        this.$router.push({ name: 'AdminAssetMasterIndex', query: query }).catch(err => {});
      },

      // 削除ボタン押下時
      onDelete(asset_id, updated_at) {
        this.delete_target_id = asset_id;
        this.delete_target_updated_at = updated_at
        this.$refs.delete_confirm_modal.open();
      },
      // 削除確認モーダル内の削除するボタン押下時
      onDeleteConfirmSend() {
        this.$loading.load(
            this.$auth.api.delete('admin/asset_masters/' + this.delete_target_id,{ params: { updated_at: this.delete_target_updated_at } }
            ).then(res => {
              this.$refs.delete_confirm_modal.close();
              this.delete_target_id = '';
              this.$bvToast.toast("削除しました。", {
                variant: 'success',
                title: '完了'
              });
              this.getAssetMasters(this.$route.query);
            }).catch(err => {
              this.$refs.delete_confirm_modal.close()
              if(err.response.status == 500) {
                this.$errorHandlers.initial(err);
              } else {
                this.$errorHandlers.ajax(err);
              }
            })
        )
      },
      // 承認ボタン押下時
      onApproval(approval_target_asset_id, updated_at) {
        this.$loading.load(
            this.$auth.api.patch(`/admin/asset_masters/approval.json`, {
              asset_master:{id: approval_target_asset_id, updated_at: updated_at}
            }
            ).then(res => {
              approval_target_asset_id = '';
              this.$bvToast.toast("更新しました。", {
                variant: 'success',
                title: '完了'
              });
              this.getAssetMasters(this.$route.query);
            }).catch(err => {
              if(err.response.status == 500) {
                this.$errorHandlers.initial(err);
              } else {
                this.$errorHandlers.ajax(err);
              }
            })
        )
      },
      // 却下ボタン押下時
      onRejection(rejection_target_asset_id, updated_at) {
        this.$loading.load(
            this.$auth.api.patch(`/admin/asset_masters/rejection.json`, {
                  asset_master:{id: rejection_target_asset_id, updated_at: updated_at}
                }
            ).then(res => {
              rejection_target_asset_id = '';
              this.$bvToast.toast("更新しました。", {
                variant: 'success',
                title: '完了'
              });
              this.getAssetMasters(this.$route.query);
            }).catch(err => {
              if(err.response.status == 500) {
                this.$errorHandlers.initial(err);
              } else {
                this.$errorHandlers.ajax(err);
              }
            })
        )
      },
      statusesCheck(statuses) {
        // statusesが文字列の場合、配列に詰める
        // 配列の形でない場合、検索フォームで不具合が起きる為
        if (!Array.isArray(statuses) && !!statuses) {
          return [statuses];
        } else {
          return statuses;
        }
      },
    },
  }
</script>