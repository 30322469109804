<template>
  <main>
    <section class="main-title">
      <h1>ユーザー一覧</h1>
    </section>

    <!-- リスト-->
    <section class="chapter white-block">
      <div class="btn-container mb-3">
        <button class="btn btn-green rounded-pill" type="button" @click="$router.push({name: 'AdminUserNewForm'}, () => {}, () => {})">新規作成</button>
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead class="thead-light">
            <tr>
              <th class="w-5"></th>
              <th class="w-15">氏名</th>
              <th class="w-25">部署名</th>
              <th class="w-25">メールアドレス</th>
              <th class="w-15">アカウント種別</th>
              <th></th>
            </tr>
          </thead>
          <tbody>

          <row v-for="user in users" v-bind:key="user.id" :user="user" @unlock="openConfirmModal(user.id)"></row>

          </tbody>
        </table>
      </div>
      <Pagination :total_page="total_page" :total_count="total_count" :current_page="current_page" :current_per="current_per" :page_link_num="page_link_num" :onChangePage="onChangePage" v-if="!!users.length" />
    </section>
    <!-- リスト-->
    <confirm-modal
      ref="confirm_unlock_modal"
      :body_text="'アカウントのロックを解除します。<br>よろしいですか？'"
      :onClickSend="unlock"
      :send_button_text="'解除する'"
      :isNoFade="isNoFade"
    ></confirm-modal>
  </main>
</template>

<script>
// ページネーションのコンポーネント読み込み
import Pagination from '../../../common/pages/Pagination.vue';
// 確認モーダルのコンポーネント読み込み
import ConfirmModal from '../../../common/pages/ConfirmModal.vue';
import Row from './Row.vue';

export default {

  components: {
    Pagination,
    ConfirmModal,
    Row
  },

  metaInfo: {
    title: 'ユーザー一覧',
  },
  name: 'UserIndex',

  props: { isNoFade: Boolean },

  data(){
    return {
      users: {},
      total_page: 0,
      current_page: Number(this.$route.query.page) || 1,
      page_link_num: 10,
      unlock_user_id: -1,
      // 現在ページ数
      current_page: Number(this.$route.query.page) || 1,
      // 現在の表示件数
      current_per:  0,
      // 総ページ数
      total_page: 0,
      // 総レコード数
      total_count: 0,
      // 表示するリンクの数
      page_link_num: 5
    }
  },
  methods: {
    setData (query) {
      this.$loading.load(this.$auth.api.get(`admin/users.json`, {params: query})
        .then(response => {
          this.users = response.data.users
          this.current_per  = Number(response.data.per);
          this.current_page = Number(response.data.page);
          this.total_page   = response.data.total_page;
          this.total_count  = response.data.total_count;
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
    },
    onChangePage(page, per) {
      let query = Object.assign({}, this.$route.query);
      query['page'] = this.current_page = page;
      query['per'] = this.current_per = per;
      // 画面更新
      this.reload(query);
    },
    reload(query = []) {
      this.$router.push({ name: 'AdminUserIndex', query: query }, () => {}, () => {});
    },
    openConfirmModal(id){
      this.unlock_user_id = id;
      this.$refs.confirm_unlock_modal.open();
    },
    unlock(){
      this.$loading.load(this.$auth.api.patch(`admin/users/unlock`, {user_id: this.unlock_user_id})
      .then(response => {
        let updated_user = this.users.filter(user => user.id == this.unlock_user_id)[0];
        this.$set(updated_user, 'locked_at', null);
        this.$refs.confirm_unlock_modal.close();
        this.$bvToast.toast("アカウントのロックを解除しました。", {
          variant: 'success',
          title: '完了'
        });
      }).catch(error =>{
        this.$errorHandlers.initial(error);
      }));
    }
  },
  mounted(){
    this.setData(this.$route.query);
  },
  beforeRouteUpdate (to, from, next) {
    this.setData(to.query);
    next();
  }
}
</script>
