<template>
  <div id="app">
    <main>
      <section class="main-title">
        <h1>{{title()}}-確認</h1>
      </section>

      <form @submit.prevent>
        <!-- 最新情報-->
        <section class="chapter white-block">
          <div class="overview-list overview-list-dt15 my-3 p-3">
            <dl class="form-confirm-group">
              <dt>企業</dt>
              <dd>{{form.provisional_su_latest_info.su_company_name}}</dd>
            </dl>

            <dl class="form-confirm-group">
              <dt>タイトル</dt>
              <dd>{{form.provisional_su_latest_info.title}}</dd>
            </dl>

            <dl class="form-confirm-group">
              <dt>本文</dt>
              <dd>{{form.provisional_su_latest_info.detail}}</dd>
            </dl>

            <dl class="form-confirm-group">
              <dt>アセット</dt>
              <dd class="task-labels-wrap">
                <template v-for="asset_master in form.provisional_su_latest_info.provisional_su_latest_info_asset_masters_attributes">
                  <div class="task-labels" :key="asset_master.asset_master_id">
                    <p class="task-labels-parent">{{assetMasterIdIs(asset_master.asset_master_id).name}}</p>
                  </div>
                </template>
              </dd>
            </dl>

            <dl class="form-confirm-group">
              <dt>登録予定のアセット</dt>
              <dd class="task-labels-wrap">
                <template v-for="(asset_master, index) in form.provisional_su_latest_info.asset_masters_attributes" >
                  <div class="task-labels" v-if="!asset_master._destroy || asset_master._destroy != 1" :key="asset_master.asset_master_id">
                    <p class="task-labels-parent">{{ asset_master.name }}</p>
                    <p
                      v-for="error_message in assetMasterErrorMessagesIndexIs(index).error_messages"
                      :key="error_message"
                      class="invalid-feedback"
                      style="display:block"
                      >
                      {{ error_message }}
                    </p>
                  </div>
                </template>
              </dd>
            </dl>

            <dl class="form-confirm-group">
              <dt>メインビジュアル</dt>
              <dd>
                <!-- 新規画像 か 保存されている画像 -->
                <img v-if="!!form.provisional_su_latest_info.main_image_attributes.upload_image"
                  class="img320"
                  :src="form.provisional_su_latest_info.main_image_attributes.upload_image"
                  :alt="form.provisional_su_latest_info.main_image_attributes.filename"
                />
                <!-- デフォルト画像 -->
                <img v-else
                  class="img320"
                  src="~images/image_default.jpg"
                  alt=""
                />
              </dd>
            </dl>

            <dl class="form-confirm-group">
              <dt>メインビジュアル タイトル</dt>
              <dd>{{form.provisional_su_latest_info.main_image_title}}</dd>
            </dl>

            <dl class="form-confirm-group">
              <dt>イメージ 1</dt>
              <dd>
                <!-- 新規画像 か 保存されている画像 -->
                <img v-if="!!form.provisional_su_latest_info.sub1_image_attributes.upload_image"
                  class="img320"
                  :src="form.provisional_su_latest_info.sub1_image_attributes.upload_image"
                  :alt="form.provisional_su_latest_info.sub1_image_attributes.filename"
                />
                <!-- デフォルト画像 -->
                <img v-else
                  class="img320"
                  src="~images/image_default.jpg"
                  alt=""
                />
              </dd>
            </dl>

            <dl class="form-confirm-group">
              <dt> イメージ 1 タイトル</dt>
              <dd>{{form.provisional_su_latest_info.sub1_image_title}}</dd>
            </dl>

            <dl class="form-confirm-group">
              <dt>イメージ 2</dt>
              <dd>
                <!-- 新規画像 か 保存されている画像 -->
                <img v-if="!!form.provisional_su_latest_info.sub2_image_attributes.upload_image"
                  class="img320"
                  :src="form.provisional_su_latest_info.sub2_image_attributes.upload_image"
                  :alt="form.provisional_su_latest_info.sub2_image_attributes.filename"
                />
                <!-- デフォルト画像 -->
                <img v-else
                  class="img320"
                  src="~images/image_default.jpg"
                  alt=""
                />
              </dd>
            </dl>

            <dl class="form-confirm-group">
              <dt> イメージ 2 タイトル</dt>
              <dd>{{form.provisional_su_latest_info.sub2_image_title}}</dd>
            </dl>

            <dl class="form-group form-confirm-group">
              <dt>ピッチ資料</dt>
              <dd v-if="form.provisional_su_latest_info.pitch_file_attributes.filename">{{ form.provisional_su_latest_info.pitch_file_attributes.filename }}</dd>
              <dd v-else-if="form.provisional_su_latest_info.uploaded_pitch_file">{{ form.provisional_su_latest_info.new_pitch_file_name }}</dd>
              <dd v-else >なし</dd>
            </dl>
          </div>

        </section>
        <!-- 最新情報-->

        <!-- 企業情報-->
        <section class="chapter white-block">
          <p v-if="form.provisional_su_latest_info.exam_status == 'draft'" class="invalid-feedback" style="display:block">
            一時保存のため、企業情報は更新されません。
          </p>

          <dl class="form-confirm-group">
            <dt>企業情報（私たちについて）</dt>
            <dd v-if="form.provisional_su_latest_info.exam_status == 'draft'">{{ provisional_su_latest_info.su_company.business_summary }}</dd>
            <dd v-else>{{ form.provisional_su_latest_info.su_company_attributes.business_summary }}</dd>
          </dl>
        </section>
        <!-- 企業情報-->

        <!-- 審査詳細 -->
        <section class="chapter white-block">
          <h2 class="chapter-label mb-3">審査詳細</h2>
          <div class="overview-list overview-list-dt15 my-3 p-3">

            <dl class="form-confirm-group">
              <dt>審査ステータス</dt>
              <dd>{{exam_statuses[form.provisional_su_latest_info.exam_status]}}</dd>
            </dl>

            <template v-if="isDispNoReason()">
              <dl class="form-confirm-group">
                <dt>要修正理由</dt>
                <dd>{{ form.provisional_su_latest_info.no_reason }}</dd>
              </dl>
            </template>

          </div>
        </section>
        <!-- / 審査詳細-->

        <div class="btn-container d-flex justify-content-center">
          <button class="button-square btn-cancel" type="button" @click="onClickFix">修正する</button>
          <button class="button-square btn-send" @click="onClickSubmit" type="button">{{ saveButtonName() }}</button>
        </div>

      </form>

    </main>
  </div>
</template>

<script>
  // 一覧戻る確認モーダル
  import ConfirmBackToIndexModal from '../../../common/pages/ConfirmBackToIndexModal';
  export default {
    data: function () {
      return {
      }
    },

    props: {
      // 登録編集対象の仮登録SU最新情報
      provisional_su_latest_info: Object,

      // 審査ステータス一覧
      exam_statuses: {
        type: Object,
        default: () => ({})
      },

      // アセットマスタ
      asset_masters: {
        type: Array,
        default: () => ([])
      },

      // バリデーションエラー
      validation_errors: {
        type: Object,
        default: () => ({})
      },

      // 登録するボタン押下時のコールバックメソッド
      onSave: {
        type: Function,
      },

      // 修正するボタン押下時のコールバックメソッド
      onFix: {
        type: Function,
      },

      // フォームの内容
      form: {
        type: Object,
        default: () => ({})
      },
    },

    components: {
      ConfirmBackToIndexModal,
    },

    data() {
      return {
        edit_asset_master: [],
        asset_master_error_messages: [],
      }
    },

    watch: {
      validation_errors(new_validation_errors, old_validation_errors) {
        // 値をpostする形にするため編集をコピー
        let post_form = JSON.parse(JSON.stringify(this.form));

        // 登録するアセットと削除するアセットのパラメータを結合
        let provisional_su_latest_info_asset_masters_attributes = post_form.provisional_su_latest_info.provisional_su_latest_info_asset_masters_attributes
        let destroy_provisional_su_latest_info_asset_masters_attributes = post_form.provisional_su_latest_info.destroy_provisional_su_latest_info_asset_masters_attributes
        let concat_provisional_su_latest_info_asset_masters_attributes = provisional_su_latest_info_asset_masters_attributes.concat(destroy_provisional_su_latest_info_asset_masters_attributes);
        // 選択されたアセットの件数を取得
        // 新規で選択されたアセットは除外
        let provisional_su_latest_info_asset_masters_length = concat_provisional_su_latest_info_asset_masters_attributes.filter(
            concat_provisional_su_latest_info_asset_masters_attribute => !!concat_provisional_su_latest_info_asset_masters_attribute.id
        ).length;

        this.asset_master_error_messages = [];

        this.form.provisional_su_latest_info.asset_masters_attributes.forEach((asset_masters_attributes, index) => {
          // 新規登録
          if(!this.provisional_su_latest_info.id) {
            var error_messages = new_validation_errors[`asset_masters.${index}.name`];
            this.asset_master_error_messages.push({
              error_message_index: index,
              error_messages: error_messages
            });
          }
          // 編集
          else {
            var error_messages = new_validation_errors[`asset_masters.${provisional_su_latest_info_asset_masters_length + index}.name`]
            this.asset_master_error_messages.push({
              error_message_index: index,
              error_messages: error_messages
            });
          }
        });
      },
    },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.init();
    },

    // メソッド定義
    methods: {
      // 初期化
      init() {
      },

      // 修正するボタン押下時処理
      onClickFix() {
        // 審査ステータスが下書きの場合、初期値として未承認を設定
        if(this.form.provisional_su_latest_info.exam_status == 'draft') {
          this.form.provisional_su_latest_info.exam_status = 'undecided';
        }
        this.onFix();
      },

      // 保存ボタン押下時処理
      onClickSubmit() {
        // this.onFormPost(this.form);
        this.onSave();
      },

      // アセットをIDで検索
      assetMasterIdIs(id) {
          let asset_master = this.asset_masters.find((asset_master) => asset_master.id == id);
          return !!asset_master ? asset_master : { id: null, name: '' };
      },

      // 保存ボタンの表示名
      saveButtonName() {
        let button_name = '';
        if(this.form.provisional_su_latest_info.exam_status == 'draft') {
          button_name = '下書き保存';
        }
        else if(this.form.provisional_su_latest_info.registed_type == 'edit') {
          button_name = '更新';
        }
        else {
          button_name = '登録';
        }

        return button_name;
      },

      title() {
        let title = '';
        if(!!this.form.provisional_su_latest_info.id) {
          title = '最新情報編集';
        }
        else {
          title = '最新情報作成';
        }

        return title;
      },

      // アセットのエラーメッセージの配列からIndexで検索
      assetMasterErrorMessagesIndexIs(i) {
        var error_message = this.asset_master_error_messages.find((error_messages) => error_messages.error_message_index === i);
        return !!error_message ? error_message : { error_messages: [] };
      },

      // 要修正理由 表示非表示判定
      isDispNoReason() {
        let result = false;

        // 編集時
        if(!!this.provisional_su_latest_info.id) {
          // 審査ステータスで「要修正」が選択されている
          if(this.form.provisional_su_latest_info.exam_status == 'modify') {
            result = true;
          }
          // 要修正理由が空ではない
          if(!!this.provisional_su_latest_info.no_reason) {
            result = true;
          }
        }

        return result;
      }
    },
  }
</script>
