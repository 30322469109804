<template>
  <div>
    <router-view
      :user.sync="user"
      :accepted_extensions="accepted_extensions"
      :prop_user_uploaded_image.sync="prop_user_uploaded_image"
      :prop_user_img_name.sync="prop_user_img_name">
    </router-view>
  </div>
</template>

<script>
  export default {

    data() {
      return {
        company_id: this.$route.params.id,
        user: {},
        ep_account_types: {},
        accepted_extensions: [],
        prop_user_uploaded_image: '',
        prop_user_img_name: '',
      }
    },

    methods: {
      getUser () {
        this.user = this.$store.getters['ep_person_in_charge/User']
      },
      setEnums () {
        this.$loading.load(this.$auth.api.get('admin/ep_person_in_charges/init_new.json')
          .then(res => {
            this.user.ep_company_id = this.company_id
            this.accepted_extensions = res.data.accepted_extensions;
          })
          .catch(err => {
            this.$errorHandlers.initial(err);
          }));
      }
    },

    created: function(){
      this.getUser();
      this.setEnums();
    },
    beforeMount() {
      if(this.$route.name !== "EpPersonInChargeNewConfirm"){
        this.$store.commit('ep_person_in_charge/initUser');
        this.user = this.$store.getters['ep_person_in_charge/User'];
      }
    },

  }
</script>
