<template>
  <div>
    <main>
      <section class="main-title">
        <h1>企業情報</h1>
      </section>

      <!-- 基本情報-->
      <form>
        <section class="chapter white-block">
          <h2 class="chapter-label">基本情報</h2>
          <div class="alert alert-danger alert-dismissible fade show" role="alert" v-if="registration_request.exam_status == 'undecided'">
            <p>※このアカウントはまだ未承認です。（審査が必要です）</p>
          </div>
          <div class="border-top pt-4 px-3 mb-5">
            <div class="deta-header">
              <h3 class="company-name">{{company.company_name}}</h3>
              <dl>
                <dt><i class="fas fa-desktop" title="URL"></i></dt>
                <dd><a class="btn-text" :href="company.company_hp" target="_blank" rel="noopener">
                  <template v-if="deviceIsPc()">
                    {{ company.company_hp | truncate(90) }}
                  </template>
                  <template v-else>
                    {{ company.company_hp | truncate(50) }}
                  </template>
                </a></dd>
              </dl>
            </div>
            <div class="overview-list overview-list-dt20">
              <dl class="form-confirm-group">
                <dt>本社所在地</dt>
                <dd><span v-if="company.prefecture_master_id!=''">{{headOfficeLocation}}</span></dd>
              </dl>
              <dl class="form-confirm-group">
                <dt>業界</dt>
                <dd>{{ industryName }}&nbsp;&nbsp;&nbsp;&nbsp;{{ subIndustryName }}</dd>
              </dl>
              <dl class="form-confirm-group">
                <dt>私たちについて</dt>
                <dd>{{company.business_summary}}</dd>
              </dl>
              <dl class="form-confirm-group">
                <dt>有限責任監査法人トーマツ及びDeloitteの<br>メンバーファームからの監査</dt>
                <dd>{{auditMessage}}</dd>
              </dl>
              <dl class="form-confirm-group">
                <dt>有料会員機能の利用</dt>
                <dd>{{membershipMessage}}</dd>
              </dl>
            </div>
          </div>

          <!-- 管理者情報-->
          <h2 class="chapter-label">管理者情報</h2>
          <div class="overview-wrap border-top" v-if="!!company.admin_user_attributes">
            <div class="left-col">
              <img v-if="!!company.admin_user_attributes.image_attributes && !!company.admin_user_attributes.image_attributes.filename" class="thumbnail-frame img180"
                :src="company.admin_user_attributes.presigned_url"
                :alt="company.admin_user_attributes.image_attributes.filename"
              />
              <img v-else
                class="thumbnail-frame img180"
                src="~images/ep_user_default.png"
                alt="画像が選択されていません"
              />
              <p class="login-time mt-3">{{company.admin_user_attributes.last_login}}</p>
            </div>
            <div class="right-col">
              <div class="overview-list my-4">
                <dl class="form-confirm-group">
                  <dt>氏名</dt>
                  <dd>{{fullName(company.admin_user_attributes)}}</dd>
                </dl>
                <dl class="form-confirm-group">
                  <dt>氏名（フリガナ）</dt>
                  <dd>{{fullKanaName(company.admin_user_attributes)}}</dd>
                </dl>
                <dl class="form-confirm-group">
                  <dt>メールアドレス<br>（ログインID）</dt>
                  <dd>{{company.admin_user_attributes.email}}</dd>
                </dl>
                <dl class="form-confirm-group">
                  <dt>役職名</dt>
                  <dd>{{company.admin_user_attributes.position}}</dd>
                </dl>
                <dl class="form-confirm-group">
                  <dt>部署名</dt>
                  <dd>{{company.admin_user_attributes.department}}</dd>
                </dl>
                <dl class="form-confirm-group">
                  <dt>プロフィール</dt>
                  <dd>{{company.admin_user_attributes.profile}}</dd>
                </dl>
              </div>
            </div>
          </div>
          <!-- 管理者情報-->

          <div class="btn-container d-flex justify-content-center">
            <button @click="$refs.delete_confirm_modal.open(); setRegistrationRequest(registration_request)" class="btn btn-medium btn-delete rounded-pill" type="button" data-toggle="modal" data-target="#delete" v-if="accountType=='dtvs_admin'">
              削除する
            </button>
            <button :class="'btn btn-medium btn-green rounded-pill'" type="button" @click="$router.push({name: 'AdminEpCompanyEditForm'})" v-if="accountType=='dtvs_admin'">更新する</button>
          </div>
        </section>

        <!-- 2段階認証設定(無効化のみ可) -->
        <TwoFactorAuth
          editUrl="AdminTwoFactorAuthEdit"
          :user_id="company.admin_user_attributes.id"
          :isInActivate="true"
          :otpRequiredForLogin="company.admin_user_attributes.otp_required_for_login"
          @reloadFunc="getEpCompany"
          v-if="registration_request.exam_status == 'accepted'"
        />
        <!-- 2段階認証設定 -->

        <ShowIndex
          baseApiUrl="admin"
          :ep_company_id="ep_company.id"
          :registration_request_id="registration_request.id"
          :accountType="accountType"
          v-if="registration_request.exam_status == 'accepted'"
        />

        <ConfirmModal
          ref="delete_confirm_modal"
          :onClickSend="onDeleteConfirmSend"
          :body_text="'この企業を削除します。<br>よろしいですか？'"
          :send_button_text="'削除する'"
          :isNoFade="isNoFade"
        />

      </form>
      <!-- 基本情報-->

      <div class="btn-container d-flex justify-content-center pt-2">
        <button class="btn btn-big btn-white rounded-pill" type="button" @click="backToPage()">もどる</button>
      </div>
    </main>
  </div>
</template>

<script>

  import ShowIndex from '../../../common/pages/ep_companies/ShowIndex.vue';
  // 端末判別コンポーネント
  import DeviceCheck from '../../../common/mixins/device_check.js';
  // 確認モーダルのコンポーネント読み込み
  import ConfirmModal from '../../../common/pages/ConfirmModal.vue';
  // 2段階認証設定コンポーネント
  import TwoFactorAuth from '../../../common/pages/two_factor_auth/Show.vue';

  export default {

    metaInfo: {
      title: '企業情報',
    },

    components: {
      ShowIndex,
      ConfirmModal,
      TwoFactorAuth
    },

    mixins: [DeviceCheck],

    props: { isNoFade: Boolean, accountType: String},

    data: function () {
      return {
        registration_request: {},
        ep_company: {},
        is_audits: {},
        membership_types: {},
        prefectures: {},
        industries: {},
        before_page_name: null,
        company: {
          company_name: '',
          company_hp: '',
          prefecture_master_id: '',
          address: '',
          industry_master_id: '',
          sub_industry_master_id: '',
          business_summary: '',
          is_audit: '',
          membership_type: '',
          admin_user_attributes: {
            id: 0,
            ep_company_id:'',
            last_login: '',
            last_name: '',
            first_name: '',
            kana_last_name: '',
            kana_first_name: '',
            email: '',
            position: '',
            department: '',
            profile: '',
            password:'',
            otp_required_for_login: '',
            image_attributes: {
              id: '',
              filename: '',
            },
            presigned_url: '',
          }
        },
      }
    },
    mounted(){
      this.getEpCompany();
      this.setEnums();
    },

    methods: {
      getEpCompany: function() {
        this.$loading.load(this.$auth.api.get(`admin/ep_companies/${this.$route.params.id}`)
        .then(response => {
          this.registration_request = response.data.registration_request
          this.ep_company = response.data.registration_request.ep_company
          this.company.company_name = !!this.ep_company ? this.ep_company.company_name : this.registration_request.company_name
          this.company.company_hp = !!this.ep_company ? this.ep_company.company_hp : this.registration_request.company_hp
          this.company.prefecture_master_id = !!this.ep_company ? this.ep_company.prefecture_master_id : ''
          this.company.address = !!this.ep_company ? this.ep_company.address : ''
          this.company.industry_master_id = !!this.ep_company ? this.ep_company.industry_master_id : this.registration_request.industry_master_id
          this.company.sub_industry_master_id = !!this.ep_company ? this.ep_company.sub_industry_master_id : this.registration_request.sub_industry_master_id
          this.company.business_summary = !!this.ep_company ? this.ep_company.business_summary : ''
          this.company.is_audit = !!this.ep_company ? this.ep_company.is_audit : this.registration_request.is_audit
          this.company.membership_type = !!this.ep_company ? this.ep_company.membership_type : this.registration_request.membership_type
          this.company.admin_user_attributes.id = !!this.ep_company ? this.ep_company.ep_admin_user_attributes.id : ''
          this.company.admin_user_attributes.last_login = !!this.ep_company ? this.ep_company.ep_admin_user_attributes.last_login : ''
          this.company.admin_user_attributes.ep_company_id = !!this.ep_company ? this.ep_company.ep_admin_user_attributes.ep_company_id : ''
          this.company.admin_user_attributes.password = !!this.ep_company ? this.ep_company.ep_admin_user_attributes.password : ''
          this.company.admin_user_attributes.last_name = !!this.ep_company ? this.ep_company.ep_admin_user_attributes.last_name : this.registration_request.last_name
          this.company.admin_user_attributes.first_name = !!this.ep_company ? this.ep_company.ep_admin_user_attributes.first_name : this.registration_request.first_name
          this.company.admin_user_attributes.kana_last_name = !!this.ep_company ? this.ep_company.ep_admin_user_attributes.kana_last_name : ''
          this.company.admin_user_attributes.kana_first_name = !!this.ep_company ? this.ep_company.ep_admin_user_attributes.kana_first_name : ''
          this.company.admin_user_attributes.email = !!this.ep_company ? this.ep_company.ep_admin_user_attributes.email : this.registration_request.email
          this.company.admin_user_attributes.position = !!this.ep_company ? this.ep_company.ep_admin_user_attributes.position : ''
          this.company.admin_user_attributes.department = !!this.ep_company ? this.ep_company.ep_admin_user_attributes.department : this.registration_request.department
          this.company.admin_user_attributes.profile = !!this.ep_company ? this.ep_company.ep_admin_user_attributes.profile : ''
          this.company.admin_user_attributes.otp_required_for_login = !!this.ep_company ? this.ep_company.ep_admin_user_attributes.otp_required_for_login : this.registration_request.otp_required_for_login
          this.company.admin_user_attributes.presigned_url = !!this.ep_company ? this.ep_company.ep_admin_user_attributes.presigned_url : ''
          if (!!this.ep_company) {
            this.company.admin_user_attributes.image_attributes = this.ep_company.ep_admin_user_attributes.image_attributes
          }else {
            this.company.admin_user_attributes.image_attributes.id = ''
            this.company.admin_user_attributes.image_attributes.filename = ''
          }

        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
      },
      setEnums () {
        this.$loading.load(this.$auth.api.get('admin/ep_companies/show_init.json')
          .then(response => {
            this.is_audits = response.data.is_audits
            this.membership_types = response.data.membership_types
            this.prefectures = response.data.prefectures
            this.industries = response.data.industries
          })
          .catch(err => {
            this.$errorHandlers.initial(err);
          }));
      },
      fullName: function (user) {
        return `${user.last_name} ${user.first_name}`
      },
      fullKanaName: function (user) {
        return user.kana_last_name == '' || user.kana_last_name == null ? '' : `${user.kana_last_name} ${user.kana_first_name}`
      },
      backToPage(){
        if(!!this.before_page_name && this.before_page_name.name == 'AdminEpCompanyEditForm'){
          this.$router.push({name: 'AdminEpCompanyIndex'})
        }else{
          history.back();
        }
      },
      //  選択した登録申請IDを取得
      setRegistrationRequest(registration_request) {
        this.registration_request_id = registration_request.id,
        this.delete_target_updated_at = this.updatedAt(registration_request)
      },

      // 更新日時を取得
      updatedAt(registration_request) {
        return !!registration_request.ep_company ? registration_request.ep_company.updated_at : registration_request.updated_at
      },

      // 削除確認モーダル内削除するボタン押下時
      onDeleteConfirmSend() {
        this.onEpPersonInChargeDelete();
      },
      // 削除処理
      onEpPersonInChargeDelete() {
        this.$loading.load(
          this.$auth.api.delete(
            `admin/ep_companies/${this.registration_request_id}`, {params: { updated_at: this.delete_target_updated_at }}
          ).then(res => {
            this.$refs.delete_confirm_modal.close();
            this.$router.push({ name: 'AdminEpCompanyIndex' });
          }).catch(err => {
            this.$refs.delete_confirm_modal.close();
            if(err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }else{
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },
    },
    computed: {
      establishmentOn: function () {
        return this.company.establishment_year == '' || this.company.establishment_year == null ? '' :`${this.company.establishment_year}年${this.company.establishment_month}月${this.company.establishment_date}日`
      },
      industryName: function () {
        return this.industries[this.company.industry_master_id];
      },
      subIndustryName: function () {
        return this.industries[this.company.sub_industry_master_id];
      },
      headOfficeLocation: function () {
        return this.company.prefecture_master_id == '' || this.company.prefecture_master_id == null ? '' : `${this.prefectures[this.company.prefecture_master_id]}${this.company.address}`
      },
      auditMessage: function () {
        return this.is_audits[this.company.is_audit]
      },
      membershipMessage: function () {
        return this.membership_types[this.company.membership_type]
      },
    },
    // コンポーネントを描画するルートが確立する前に呼ばれる
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.before_page_name = from;
      });
    },
  }
</script>
