<template>
  <section
    class="thread border-top"
    :class="applyClassBgYellow"
  >
    <div class="post-data d-flex">
      <template v-if="message.user.is_dtvs_user">
        <div class="icon-col">
          <img v-if="message.user.image_url"
            class="thumbnail-frame img60"
            :src="message.user.image_url"
            :alt="message.user.filename"
          />
          <img v-else
            class="thumbnail-frame img60"
            src="~images/dtvs_user_default.png"
            alt="画像が選択されていません"
          />
        </div>
        <div class="data-col">
          <template v-if="!message.is_edit">
            <div v-if="message.is_current_user" class="float-right">
              <button class="btn-action mx-2" type="button" @click="onClickEditMessage(message)">
                <i class="fas fa-pen" v-b-tooltip.hover="'編集'"></i>
              </button>
              <button class="btn-action" type="button" @click="onClickDeleteMessage(message)">
                <i class="fas fa-trash" v-b-tooltip.hover="'削除'"></i>
              </button>
            </div>
            <h3 v-if="!myMessage" class="company-name">デロイト{{ fullName }}{{ dispDeletedUser(message.user) }}</h3>
            <div class="daytime">投稿日時：{{ message.created_at | format.datetime }}</div>
            <p class="text-box"
              :ref="'message'"
              :inner-html.prop="message.message | htmlEscape | nl2br"><b-tooltip v-if="Edited()" :target="'edit_time' + message.id" triggers="hover">{{ message.updated_at | format.datetime }}</b-tooltip></p>
          </template>
          <template v-else>
            <b-textarea
              class="white-block form-control auto-resize"
              name="plan" id="plan"
              :state="null"
              v-model="innerEditMessage"
            >
            </b-textarea>
            <div class="invalid-feedback d-block">
              <span v-if="wasPatchValid && Object.keys(errors).length > 0" v-for="(error, key) in errors" :key="key">{{error[0]}}</span>
            </div>
            <div class="btn-container d-flex justify-content-end">
              <button class="button-square btn-cancel btn-sm" type="button" @click="onClickEditCancel()">キャンセル</button>
              <button class="button-square btn-send btn-sm" type="button" @click="onClickPatchMessage()">保存</button>
            </div>
          </template>
        </div>
      </template>

      <template v-if="message.user.is_ep_user">
        <div class="icon-col">
          <img v-if="message.user.image_url"
            class="thumbnail-frame img60"
            :src="message.user.image_url"
            :alt="message.user.filename"
          />
          <img v-else
            class="thumbnail-frame img60"
            src="~images/ep_user_default.png"
            alt="画像が選択されていません"
          />
        </div>
        <div class="data-col">
          <template v-if="!message.is_edit">
            <div v-if="message.is_current_user" class="float-right">
              <button class="btn-action mx-2" type="button" @click="onClickEditMessage(message)">
                <i class="fas fa-pen" v-b-tooltip.hover="'編集'"></i>
              </button>
              <button class="btn-action" type="button" @click="onClickDeleteMessage(message)">
                <i class="fas fa-trash" v-b-tooltip.hover="'削除'"></i>
              </button>
            </div>
            <h3 v-if="!myMessage" class="company-name">{{ message.user.ep_company.company_name }}{{ fullName }}{{ dispDeletedUser(message.user) }}</h3>
            <div class="daytime">投稿日時：{{ message.created_at | format.datetime }}</div>
            <p class="text-box"
              :ref="'message'"
              :inner-html.prop="message.message | htmlEscape | nl2br"><b-tooltip v-if="Edited()" :target="'edit_time' + message.id" triggers="hover">{{ message.updated_at | format.datetime }}</b-tooltip></p>
          </template>
          <template v-else>
            <b-textarea
              class="white-block form-control auto-resize"
              name="plan" id="plan"
              :state="null"
              v-model="innerEditMessage"
            >
            </b-textarea>
            <div class="invalid-feedback d-block">
              <span v-if="wasPatchValid && Object.keys(errors).length > 0" v-for="(error, key) in errors" :key="key">{{error[0]}}</span>
            </div>
            <div class="btn-container d-flex justify-content-end">
              <button class="button-square btn-cancel btn-sm" type="button" @click="onClickEditCancel()">キャンセル</button>
              <button class="button-square btn-send btn-sm" type="button" @click="onClickPatchMessage()">保存</button>
            </div>
          </template>
        </div>
      </template>

      <template v-if="message.user.is_su_user">
        <template v-if="message.ep_recommend_id">
          <!-- オファー・提案歓迎のマッチング選択画面 -->
          <div class="icon-col">
            <img
              class="thumbnail-frame img60"
              src="~images/dtvs_user_default.png"
              alt="画像が選択されていません"
            />
          </div>
          <div class="data-col">
            <div class="daytime">{{ message.created_at | format.datetime }}</div>
            <div class="info-message">
              <div class="info-message-title">{{ message.offer_suggestions_welcome_company_name }}から{{ changeMessage(message.offer_suggestions_welcome_message_type) }}が届きました。</div>
              <dl class="info-message-deta">
                <dt>{{ message.offer_suggestions_welcome_message_type }}を受けているチャレンジ情報</dt>
                <dd>
                  <a href="#" @click.prevent.stop="onChallenge(message)">{{ message.challenge_title }}</a>
                </dd>
                <dt>{{ message.offer_suggestions_welcome_company_name }}が見た最新情報</dt>
                <dd>
                  <a href="#" @click.prevent.stop="onLatestInfo(message)">{{ message.latest_info_title }}</a>
                </dd>
              </dl>
              <div class="info-message-box white-block" v-if="message.offer_suggestions_welcome_message_type == 'オファー' || message.offer_suggestions_welcome_message_detail">
                <div class="text-title" v-if="message.offer_suggestions_welcome_message_title">{{ message.offer_suggestions_welcome_message_title }}</div>
                <p class="text-box u-pre-wrap" v-text="message.offer_suggestions_welcome_message_detail"></p>
              </div>
              <small class="info-message-notes">オファーを承諾すると、DTVSがオファー企業と連絡を取り、<br class="breakpoint-sm">協業に向けたやり取りを実施させていただきます。</small>
              <template v-if="message.is_su_flg">
                <!-- SU側はボタンをおさせたりする -->
                <div v-if="message.is_show_button" class="btn-container d-flex justify-content-end">
                  <button @click="onApproval(message)" class="btn btn-sm btn-green rounded-pill" type="button">
                    {{ message.offer_suggestions_welcome_message_type }}を承諾する
                  </button>
                  <button @click="onPass(message)" class="btn btn-sm btn-outline-green rounded-pill" type="button">
                    見送る
                  </button>
                </div>
                <small v-else class="info-message-notes">
                  <template v-if="message.select_result">
                    {{ message.offer_suggestions_welcome_message_type }}を承諾しました。<br>DTVSからの連絡をお待ちください。
                  </template>
                  <template v-else>
                    {{ message.offer_suggestions_welcome_message_type }}を見送りました。
                  </template>
                </small>
              </template>
              <small v-else class="info-message-notes">
                <!-- 運営側は状況をテキストで。 -->
                <template v-if="message.select_result === null">
                  {{ message.offer_suggestions_welcome_message_type }}の選択中です。<br>
                  スタートアップ企業が受諾もしくは見送りを行うと、メールにて通知されます。
                </template>
                <template v-else-if="message.select_result">
                  {{ message.offer_suggestions_welcome_message_type }}を承諾しました。<br>DTVSからの連絡をお待ちください。
                </template>
                <template v-else>
                  {{ message.offer_suggestions_welcome_message_type }}を見送りました。
                </template>
              </small>

            </div>
          </div>
        </template>

        <template v-else>
          <div class="icon-col">
            <img v-if="message.user.image_url"
              class="thumbnail-frame img60"
              :src="message.user.image_url"
              :alt="message.user.filename"
            />
            <img v-else
              class="thumbnail-frame img60"
              src="~images/su_user_default.png"
              alt="画像が選択されていません"
            />
          </div>
          <div class="data-col">
            <template v-if="!message.is_edit">
              <div v-if="message.is_current_user" class="float-right">
                <button class="btn-action mx-2" type="button" @click="onClickEditMessage(message)">
                  <i class="fas fa-pen" v-b-tooltip.hover="'編集'"></i>
                </button>
                <button class="btn-action" type="button" @click="onClickDeleteMessage(message)">
                  <i class="fas fa-trash" v-b-tooltip.hover="'削除'"></i>
                </button>
              </div>
              <h3 v-if="!myMessage" class="company-name">{{ message.user.su_company.company_name }}{{ fullName }}{{ dispDeletedUser(message.user) }}</h3>
              <div class="daytime">投稿日時：{{ message.created_at | format.datetime }}</div>
              <p class="text-box"
                :ref="'message'"
                :inner-html.prop="message.message | htmlEscape | nl2br"><b-tooltip v-if="Edited()" :target="'edit_time' + message.id" triggers="hover">{{ message.updated_at | format.datetime }}</b-tooltip></p>
            </template>
            <template v-else>
              <b-textarea
                class="white-block form-control auto-resize"
                name="plan" id="plan"
                :state="null"
                v-model="innerEditMessage"
              >
              </b-textarea>
              <div class="invalid-feedback d-block">
                <span v-if="wasPatchValid && Object.keys(errors).length > 0" v-for="(error, key) in errors" :key="key">{{error[0]}}</span>
              </div>
              <div class="btn-container d-flex justify-content-end">
                <button class="button-square btn-cancel btn-sm" type="button" @click="onClickEditCancel()">キャンセル</button>
                <button class="button-square btn-send btn-sm" type="button" @click="onClickPatchMessage()">保存</button>
              </div>
            </template>
          </div>
        </template>
      </template>

    </div>
  </section>
</template>

<script>
// 削除データ表示用コンポーネント
import WithDeleted from "../mixins/with_deleted"

export default {

  mixins: [WithDeleted],

  props: {
    message: {
      type: Object,
      required: true,
    },
    login:{
      type: Object,
      default: () => ({})
    },
    editMessage: {
      type: String,
      default: '',
    },
    errors: {
      type: [Object,Array],
      default: () => ({})
    },
    wasPatchValid: {
      type: Boolean,
      default: false,
    }
  },

  mounted(){
    this.$nextTick(function () {
      // エスケープされてしまうのでinsertAdjacentHTMLで（編集済み）をインサート
      if(this.Edited() && !this.message.is_edit){
        this.$refs.message.insertAdjacentHTML(
          "beforeend",
          "<span class='text-secondary' id='edit_time" + this.message.id + "' data-toggle='tooltip'>（編集済み）</span>"
          );
      }
    })
  },

  methods: {

    onClickEditMessage (message) {
      this.$emit('onEditMessage', message)
    },

    onClickDeleteMessage (message) {
      this.$emit('onDeleteMessage', message)
    },

    onClickEditCancel () {
      this.$emit('onEditCancel')
    },

    onClickPatchMessage (){
      this.$emit('patchMessage')
    },

    onApproval (message) {
      this.$emit('onApproval', message)
    },

    onPass (message) {
      this.$emit('onPass', message)
    },

    onChallenge (message) {
      this.$emit('onChallenge', message)
    },

    onLatestInfo (message) {
      this.$emit('onLatestInfo', message)
    },

    Edited (){
      return this.message.created_at != this.message.updated_at;
    },

    changeMessage (message) {
      return message == 'オファー' ? message : message + 'の意思表示'
    },

  },

  computed: {
    /**
     * 自分自身の投稿であるかを判定.
     * @returns {boolean}
     */
    myMessage: function(){
      if(Object.keys(this.login).length > 0
        && this.message.user.id === this.login.id){
        return true;
      }
    },

    /**
     * フルネーム(姓と名)を連結して返却.
     *
     * @returns string
     */
    fullName: function(){
      if(this.myMessage) {
        return ''
      }
      return ' ' + this.message.user.last_name + ' ' + this.message.user.first_name
    },

    /**
     *　自分自身の投稿で背景を黄色にするクラスを適用.
     * @returns {{yellow: boolean}}
     */
    applyClassBgYellow: function(){
      return {
        'bg-yellow': this.myMessage
      }
    },

    // 親コンポーネントのedit_messageを更新
    innerEditMessage: {
      get () {
        return this.editMessage
      },
      set (edit_message) {
        this.$emit('update:editMessage', edit_message)
      }
    },

  }
}
</script>
