<template>
  <div>
    <main>
      <section class="main-title d-flex justify-content-between">
        <h1>スタートアップ最新情報</h1>
        <button @click="onLatestInfos()" class="btn btn-outline-green rounded-pill" type="button">最新情報一覧</button>
      </section>
      <section class="new-scroll-container mb-5">
        <div class="new-block-container">
          <div class="new-block" v-for="su_latest_info in su_latest_infos" :key="su_latest_info.id">

            <div class="new-visual">
              <a
                class="img-box"
                @click.prevent.stop="$router.push({name: 'EpSuLatestInfoShow', params: { id: su_latest_info.id }})"
                href="#"
              >
                <img v-if="!!su_latest_info.latest_info_image_url"
                  :src="su_latest_info.latest_info_image_url"
                  :alt="su_latest_info.main_image_title"
                  class="img320"
                />
                <img v-else-if="!!su_latest_info.pitch_file_thumbnail"
                  :src="su_latest_info.pitch_file_thumbnail"
                  alt="ピッチ資料"
                  class="img320"
                />
                <img v-else
                  src="~images/image_default.jpg"
                  alt="デフォルト画像"
                  class="img320"
                />
              </a>
            </div>
            <div class="new-body">
              <div class="new-bar"><small class="new-date text-secondary">{{ su_latest_info.updated_at | format.datetime }}</small></div>
              <h2 class="new-title">
                <a href="#" @click.prevent.stop="$router.push({name: 'EpSuLatestInfoShow', params: { id: su_latest_info.id }})">
                  {{ su_latest_info.title | omittedText(40)}}
                </a>
              </h2>
              <p class="company-name mt-2">{{ su_latest_info.su_company_name }}</p>
              <div class="task-labels-wrap mt-1">
                <div class="industry-labels">{{ su_latest_info.main_industry }}</div>
                <div class="industry-labels" v-if="!!su_latest_info.sub_industry">{{ su_latest_info.sub_industry }}</div>
                <div class="industry-labels" v-if="!!su_latest_info.sub2_industry">{{ su_latest_info.sub2_industry }}</div>
              </div>
              <p class="new-price my-2" v-if="!!su_latest_info.current_market_capital">想定時価総額：{{ su_latest_info.current_market_capital | format.number }}百万円</p>
            </div>
          </div>
        </div>
      </section>

      <section class="main-title d-flex justify-content-between">
        <h1>リコメンド一覧</h1>
        <button @click="onChallengeNew()" class="btn btn-green rounded-pill" type="button">チャレンジ登録</button>
      </section>

      <!-- フィルタ -->
      <form>
        <div class="filter-block mb-3">
          <label class="filter-label"><i class="fas fa-filter mr-1"></i>ステータス：</label>
          <div class="btn-group-toggle" data-toggle="button">
            <label v-for="(recommend_status_i18n, recommend_status_key) in recommend_statuses"
                  :key="recommend_status_key"
                  class="btn button-square btn-check btn-sm"
                  :class="{active: form.recommend_statuses != null && form.recommend_statuses.includes(recommend_status_key) }"
                  >
              {{ recommend_status_i18n }}
              <input
                v-model="form.recommend_statuses"
                type="checkbox"
                class="custom-control-input"
                :id="'status_' + recommend_status_key"
                :value="recommend_status_key"
                @change="onSearchFromChild(form)"
                >
            </label>
          </div>
        </div>
      </form>

      <!-- リスト -->
      <section class="reading-block-container">
        <p v-show="form.recommend_statuses != null && form.recommend_statuses.length == 0" class="search-no-data">ステータスは1つ以上選択してください。</p>
        <p v-if="recommends.length == 0" v-show="loaded" class="search-no-data">条件に一致するリコメンドは現在ありません。</p>
        <div v-for="recommend in recommends" :key="recommend.id" class="white-block reading-block" v-show="form.recommend_statuses != null && form.recommend_statuses.length > 0">
          <div class="reading-visual">
            <a
              class="img-box"
              @click.prevent.stop="$router.push({name: 'EpRecommendShow', params: { id: recommend.id }})"
              href="#"
            >
              <!-- メインビジュアル画像 -->
              <img v-if="!!recommend.su_latest_info.main_image_id"
                :src="recommend.presigned_latest_info_image_url"
                :alt="recommend.su_latest_info.main_image_title"
                class="img320"
              />
              <img v-else-if="!!recommend.pitch_file_thumbnail"
                :src="recommend.pitch_file_thumbnail"
                alt="ピッチ資料"
                class="img320"
              />
              <img v-else
                src="~images/image_default.jpg"
                alt="デフォルト画像"
                class="img320"
              />
            </a>

            <!-- ステータスのラベル -->
            <label class="status-1" v-if="recommend.ep_action_status == 'none'">未確認</label>
            <label class="status-4" v-else-if="recommend.ep_action_status == 'see_off'">見送り</label>
            <label class="status-2" v-else-if="['offer_awaiting_review', 'offer_returned', 'offer_sent', 'offer_matched', 'offer_closed'].includes(recommend.ep_offer_suggestions_welcome.recommend_answer_status)">オファー</label>
            <label class="status-3" v-else>提案歓迎</label>
          </div>
          <div class="reading-bar">
            <small class="reading-date text-secondary">{{ recommend.recommend_send_at | format.datetime }}</small>
          </div>
          <!-- 最新情報タイトル -->
          <h2 class="reading-title">
            <a
              :id="'recommend_' + recommend.id"
              class="popover-trigger text-ellipsis"
              @click.prevent.stop="$router.push({name: 'EpRecommendShow', params: { id: recommend.id }})"
              href="#"
            >
              {{ recommend.su_latest_info.title | omittedText(40) }}
            </a>
            <b-popover
              :target="'recommend_' + recommend.id"
              triggers="hover"
              placement="auto">
              {{ recommend.su_latest_info.title  | htmlEscape | nl2br }}
            </b-popover>
          </h2>
          <dl class="reading-body mt-3">
            <dt>
              <img v-if="recommend.latest_info_manager.image_id"
                :src="recommend.presigned_latest_info_manager_image_url"
                :alt="recommend.presigned_latest_info_manager_image_filename"
                class="thumbnail-frame img40"
              />
              <img v-else
                src="~images/su_user_default.png"
                alt="デフォルト画像"
                class="thumbnail-frame img40"
              />
            </dt>
            <dd>
              <p class="company-name">{{ recommend.su_company_name_department_name }}</p>
            </dd>
          </dl>
          <div class="task-labels-wrap mt-1">
            <div class="industry-labels" v-if="!!recommend.main_industry">{{ recommend.main_industry }}</div>
            <div class="industry-labels" v-if="!!recommend.sub_industry">{{ recommend.sub_industry }}</div>
            <div class="industry-labels" v-if="!!recommend.sub2_industry">{{ recommend.sub2_industry }}</div>
          </div>
          <p class="new-price my-2" ><span v-if="!!recommend.current_market_capital">想定時価総額：{{ recommend.current_market_capital | format.number }} 百万円</span></p>
          <div class="challenge-detail" v-if="!!recommend.ep_challenge">
            <span class="challenge-detail-label">チャレンジ</span>
            <a class="popover-trigger challenge-title"
              :id="'challenge_' + recommend.id"
              @click.prevent.stop="onChallengeShow(recommend.provisional_ep_challenge_id)"
              href="#">
              {{ recommend.ep_challenge.title | omittedText(35) }}
            </a>
            <b-popover
              :target="'challenge_' + recommend.id"
              triggers="hover"
              placement="auto">
              {{ recommend.ep_challenge.title  | htmlEscape | nl2br }}
            </b-popover>
          </div>
        </div>
      </section>
      <!-- / リスト-->
      <Pagination :total_page="total_page"
                  :total_count="total_count"
                  :current_page="current_page"
                  :current_per="current_per"
                  :page_link_num="page_link_num"
                  :onChangePage="onChangePage"
                  v-if="hasRecord"
                  v-show="form.recommend_statuses != null && form.recommend_statuses.length > 0" />
    </main>
  </div>
</template>

<script>
// ページネーションのコンポーネント読み込み
import Pagination from '../../../common/pages/Pagination.vue';
// PDFピッチ資料
import VuePdf from 'vue-pdf';

export default {
  metaInfo: {
    title: 'リコメンド一覧',
  },

  components: {
    Pagination,
    VuePdf,
  },

  data() {
    return {
      // リコメンドステータス（検索用）
      recommend_statuses: [],
      // データ
      recommends: [],
      // 最新情報
      su_latest_infos: [],
      // 現在ページ数
      current_page: Number(this.$route.query.page) || 1,
      // 現在の表示件数
      current_per:  0,
      // 総ページ数
      total_page: 0,
      // 総レコード数
      total_count: 0,
      // 表示するリンクの数
      page_link_num: 5,
      // 読み込み完了フラグ
      loaded: false,
      // pdfjs
      cmaps_path: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.5.141/cmaps/',
      // ステータスの絞り込み
      form: {
        recommend_statuses: this.$route.query.recommend_statuses || ["none", "pass", "offer", "welcome"],
        // 検索処理で使用する現在の1ページあたりの表示件数
        per:  this.$route.query.per  || '',
        // 検索処理で使用する現在のページ位置
        page: this.$route.query.page || '',
      },
    }
  },

  computed: {
    /**
     * 検索結果が存在するか
     */
    hasRecord: function(){
      return Object.keys(this.recommends).length > 0
    }
  },

  filters: {
    // 文字列を省略して表示
    omittedText(text, len) {
      return text.length > len ? text.slice(0, len) + "…" : text;
    },
  },

  methods: {
    // データ取得処理
    getRecommends(query) {
      this.$loading.load(
        this.$auth.api.get(
          'ep/recommends', {
            params: query
          }
        ).then(res => {
          this.su_latest_infos = res.data.su_latest_infos || [];
          this.recommends = res.data.recommends || [];
          this.recommend_statuses = res.data.recommend_statuses;
          this.current_per  = Number(res.data.per);
          this.current_page = Number(res.data.page);
          this.total_page   = res.data.total_page;
          this.total_count  = res.data.total_count;
          this.loaded = true;
        }).catch(err => {
          this.$errorHandlers.initial(err);
        })
      )
    },

    // 最新情報一覧
    onLatestInfos() {
      this.$router.push({ name: 'EpSuLatestInfoIndex' }).catch(err => {});
    },

    // チャレンジ投稿ボタン押下
    onChallengeNew() {
      let resolvedRoute = this.$router.resolve({
        name: 'EpProvisionalEpChallengeNew',
      });
      window.open(resolvedRoute.href, '_blank');
    },

    // チャレンジ投稿ボタン押下
    onChallengeShow(provisional_ep_challenge_id) {
      let resolvedRoute = this.$router.resolve({
        name: 'EpProvisionalEpChallengeShow',
        params: { id: provisional_ep_challenge_id }
      });
      window.open(resolvedRoute.href, '_blank');
    },

    // ページ切り替え時
    onChangePage(page, per) {
      let query = Object.assign({}, this.$route.query);
      query['page'] = this.current_page = page;
      query['per'] = this.current_per = per;
      // 画面更新
      this.reload(query);
    },

    /**
     * 検索ボタンが押下された時の処理.
     * (子Componentから$emitされる)
     */
    onSearchFromChild: function(form = {}){
      if (form.recommend_statuses != null && form.recommend_statuses.length > 0) {
        // 画面更新
        this.reload(form);
      }
    },

    // 更新処理
    reload(query = []) {
      this.$router.push({ name: 'EpRecommendIndex', query: query }).catch(err => {});
    },

    statusesCheck(statuses) {
      // statusesが文字列の場合、配列に詰める
      // 配列の形でない場合、検索フォームで不具合が起きる為
      if (!Array.isArray(statuses) && !!statuses) {
        return [statuses];
      } else {
        return statuses;
      }
    },

  },

  // インスタンス生成後、リアクティブデータ初期化後
  created() {
    if(Object.keys(this.$route.query).length == 0){
      this.$route.query.recommend_statuses = ["none", "pass", "offer", "welcome"];
    }

    // 審査ステータス 1つだけの場合、textになるのを防ぐ処理
    this.form.recommend_statuses = this.statusesCheck(this.form.recommend_statuses);
    this.$route.query.recommend_statuses = this.statusesCheck(this.$route.query.recommend_statuses)

    this.getRecommends(this.$route.query);
  },

  // route.push時に実行
  beforeRouteUpdate (to, from, next) {
    this.getRecommends(to.query);
    next();
  },


}
</script>
