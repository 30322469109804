<template>
  <div class="wrapper">
    <header class="top-header">
      <div class="d-logo"><a :href="'/' + urlType() + '/login'"><img src="~images/header_logo.svg" alt="six brain" /></a></div>
    </header>
    <router-view />
    <footer-component />
  </div>
</template>

<script>
import footerComponent from './footer';

export default {
  components: {
    footerComponent
  },

  methods: {
    urlType () {
      if(this.$route.fullPath.includes('/admin/')) {
        return 'admin'
      }else if(this.$route.fullPath.includes('/ep/')) {
        return 'ep'
      }else if(this.$route.fullPath.includes('/su/')) {
        return 'su'
      }else{
        ;
      }
    }
  }
}
</script>