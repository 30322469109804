<template>
  <main>
    <div class="request-inner mt-6">
      <form @submit.prevent>
        <!-- 2段階認証設定-->
        <section id="pic_list" class="chapter white-block mt-5">
          <h2 class="chapter-label">2段階認証設定
            <p class="sub-label text-black-50">
              携帯端末のGoogle AuthenticatorでQRコードを読み込み、表示された認証コードを入力して【認証する】ボタンを押してください。<br>
              ※ 携帯端末にGoogle Authenticatorがインストールされていない場合は、アプリストアから入手してください。
            </p>
          </h2>
          <div class="form-wrap border-top">
            <dl class="form-group form-confirm-group">
              <dt>1. google-authenticatorをインストールしてください。</dt>
              <dd style="display: flex; align-items: center;">
                <a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ja&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                  <img width="153" alt='Google Play で手に入れよう' src='https://play.google.com/intl/ja/badges/static/images/badges/ja_badge_web_generic.png'/>
                </a>
                <a href="https://apps.apple.com/jp/app/google-authenticator/id388497605?itsct=apps_box_badge&amp;itscg=30200">
                  <img src="https://linkmaker.itunes.apple.com/assets/shared/badges/ja-jp/appstore-lrg.svg" alt="App Store からダウンロード">
                </a>
              </dd>
              <dt>2. インストールしたアプリを開いてください。</dt>
              <dt>3. 右下の＋をタップし、ＱＲコードをスキャンをタップしてください。</dt>
              <dt>4. 次のQRコードを読み込んでください。</dt>
              <dd>
                <img :src="qr_code">
              </dd>
              <dt>5. 認証アプリに表示された認証コードを入力して【認証する】ボタンを押してください。</dt>
            </dl>
            <div class="form-group" >
              <b-form-input v-model="otp_attempt" type="text" class="form-control" placeholder="認証コードを入力"></b-form-input>
              <div v-if="!!error" class="invalid-feedback d-block">{{ error }}</div>
            </div>
            <button class="btn btn-medium btn-send" type="button" @click="onVerify()">認証する</button>
          </div>
        </section>
        <!-- 2段階認証設定-->

      </form>
    </div>
  </main>
</template>

<script>
  export default {
    metaInfo: {
      title: '2段階認証設定確認'
    },

    data() {
      return {
        otp_attempt: '',
        qr_code: '',
        error: ''
      }
    },

    // コンポーネントを描画するルートが確立する前に呼ばれる
    beforeRouteEnter(to, from, next) {
      // 意図しない画面遷移による2段階認証キーの更新を防ぐため、
      // 2段階認証設定画面以外から遷移してきた場合は2段階認証設定画面へ移動する
      if (!!from.name && from.name.match(/TwoFactorAuthEdit/)){
        next();
      }
      else{
        next({path: to.path.split('/').slice(0, -1).join('/') + '/edit'});
      }
    },

    created() {
      this.onInit();
    },

    methods: {
      // 初期処理
      onInit() {
        this.$loading.load(
          this.$auth.api.patch(`two_factor_auth_setting/confirm`)
          .then(res => {
            this.qr_code = res.data.qr_code;
          })
        );
      },

      // 認証ボタン押下時
      onVerify() {
        this.$loading.load(
          this.$auth.api.patch(`two_factor_auth_setting/activate`, {params:{otp_attempt: this.otp_attempt}})
          .then(res => {
            // 完了画面へ（ブラウザバック対策のためreplaceで遷移）
            this.$router.replace('complete');
          })
          .catch(err => {
            if (err.response.status == 422) {
              this.error = err.response.data.error;
            } else {
              this.$errorHandlers.initial(err);
            }
          })
        );
      },
    },
  }
</script>
