<template>
  <div>
    <section class="chapter white-block step-data">
      <div class="step-graph border-bottom">
        <StockValueIllustrated
          :presentValue="presentValue"
          :interestBearingDebtNet="interestBearingDebtNet"
          :stockValue="stockValue"
        />
      </div>
      <div class="step-table">
        <div class="btn-container">
          <div />
          <EditButtons
            :inEdit="inEdit"
            @editClick="$emit('update:inEdit', true)"
            @onSave="$emit('updateJsonScenario')"
          />
        </div>
        <div
          v-if="errors.length"
          class="mx-4 mb-4"
        >
          <p
            v-for="(error, i) in errors"
            :key="`scenario_errors_${i}`"
            class="invalid-feedback d-block"
          >
            {{ error }}
          </p>
        </div>
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr class="divide-x divide-gray-200">
                  <th
                    class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                    scope="col"
                  >
                    {{ displayCurrencyUnit(scenario) }}
                  </th>
                  <th
                    class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0"
                    scope="col"
                  />
                  <th
                    class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0"
                    scope="col"
                  />
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr class="divide-x divide-gray-200">
                  <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900">
                    事業価値
                    <div
                      class="fas fa-info-circle icon-gray ml-1"
                      v-b-tooltip.hover="'企業が将来獲得するFCFの現在価値を合計し、事業価値を計算します。'"
                    />
                  </td>
                  <td class="whitespace-nowrap p-4 text-sm font-medium text-gray-900 sm:pl-0" />
                  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                    <div class="w-[27rem]">
                      {{ presentValue }}
                    </div>
                  </td>
                </tr>
                <tr class="divide-x divide-gray-200">
                  <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900">
                    有利子負債（純額）
                    <div
                      class="fas fa-info-circle icon-gray ml-1"
                      v-b-tooltip.hover="'有利子負債-現預金または現金同等物'"
                    />
                  </td>
                  <td class="whitespace-nowrap p-4 text-sm font-medium text-gray-900 sm:pl-0" />
                  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                    <div class="w-[27rem]">
                      {{ interestBearingDebtNet }}
                    </div>
                  </td>
                </tr>
                <tr class="divide-x divide-gray-200">
                  <td class="whitespace-nowrap p-4 text-sm font-medium text-gray-900 sm:pl-0" />
                  <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900">
                    有利子負債
                    <div
                      class="fas fa-info-circle icon-gray ml-1"
                      v-b-tooltip.hover="'評価基準日時点のB/Sに計上されている短期借入金・長期借入金・退職給付引当金・社債の金額を合計して入力してください。'"
                    />
                  </td>
                  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                    <template v-if="inEdit">
                      <input
                        class="w-full"
                        type="text"
                        :value="scenario.interest_bearing_debt['last']"
                        @input="setField(scenario, 'interest_bearing_debt', { last: $event.target.value })"
                      />
                    </template>
                    <template v-else>
                      <div class="w-[27rem]">
                        {{ digitSeparator(scenario.interest_bearing_debt['last']) }}
                      </div>
                    </template>
                  </td>
                </tr>
                <tr class="divide-x divide-gray-200">
                  <td class="whitespace-nowrap p-4 text-sm font-medium text-gray-900 sm:pl-0" />
                  <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900">
                    現預金または現金同等物
                    <div
                      class="fas fa-info-circle icon-gray ml-1"
                      v-b-tooltip.hover="'評価基準日時点のB/Sに計上されている現預金・土地・有価証券の金額を合計して入力してください。'"
                    />
                  </td>
                  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                    <template v-if="inEdit">
                      <input
                        class="w-full"
                        type="text"
                        :value="scenario.c_and_ce['last']"
                        @input="setField(scenario, 'c_and_ce', { last: $event.target.value })"
                      />
                    </template>
                    <template v-else>
                      <div class="w-[27rem]">
                        {{ digitSeparator(scenario.c_and_ce['last']) }}
                      </div>
                    </template>
                  </td>
                </tr>
                <tr class="divide-x divide-gray-200">
                  <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900">
                    株式価値
                    <div
                      class="fas fa-info-circle icon-gray ml-1"
                      v-b-tooltip.hover="'企業価値から債権者に帰属する負債価値を控除し、株主に帰属する価値(株主価値)を計算します。'"
                    />
                  </td>
                  <td class="whitespace-nowrap p-4 text-sm font-medium text-gray-900 sm:pl-0" />
                  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                    <div class="w-[27rem]">
                      {{ stockValue }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <div class="btn-container d-flex justify-content-center">
      <router-link
        :to="{ name: 'EpScenariosShowStep4' }"
        class="btn btn-big rounded-pill btn-prev"
      >
        前のステップに戻る
      </router-link>
      <router-link
        :to="{ name: 'EpScenariosShowStep6' }"
        class="btn btn-big rounded-pill btn-next"
      >
        次のステップに進む
      </router-link>
    </div>
  </div>
</template>

<script>
  import EditButtons from './parts/EditButtons';
  import StockValueIllustrated from './parts/StockValueIllustrated';
  import ScenariosShowMixins from './scenarios_show_mixins';

  export default {
    mixins: [ScenariosShowMixins, ],
    components: {
      EditButtons,
      StockValueIllustrated,
    },
    props: {
      scenario: {
        type: Object,
        required: true,
      },
      errors: {
        type: Array,
        required: true,
      },
      inEdit: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      presentValue() {
        return this.digitSeparator(this.calcPresentValues(this.scenario)[this.scenario.years_custom]);
      },
      interestBearingDebtNet() {
        return this.digitSeparator(this.calcInterestBearingDebtNet(this.scenario));
      },
      stockValue() {
        return this.digitSeparator(this.calcStockValue(this.scenario));
      },
    },
  };
</script>
<style src="./tailwind.css" scoped />
