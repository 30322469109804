const initialState = () => {
  return {
    access_token: null,
    client: null,
    uid: null,
    account_type: null,
    plan: null,
    userId: null,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    reset(state){
      Object.assign(state, initialState())
    },
    login: function(state, data){
      state.access_token = data.access_token;
      state.client = data.client;
      state.uid = data.uid;
      state.account_type = data.user.account_type;
      state.plan = data.plan;
      state.userId = data.user.id;
    },
    logout: function(state){
      state.access_token = null;
      state.client = null;
      state.uid = null;
      state.account_type = null;
      state.plan = null;
      state.userId = null
    },
    refreshAccessToken: function(state, data){
      state.access_token = data.access_token;
    }
  },

}