<template>
  <div>
    <router-view
      :provisional_su_latest_info="provisional_su_latest_info"
      :asset_masters="asset_masters"
      :industry_asset_masters="industry_asset_masters"
      :accept_extensions="accept_extensions"
      :form.sync="form"
      :main_image.sync="main_image"
      :sub1_image.sync="sub1_image"
      :sub2_image.sync="sub2_image"
      :pitch_file_accept_extensions="pitch_file_accept_extensions"
      :validation_errors="validation_errors"
      :onTemporarily="onTemporarily"
      :onConfirm="onConfirm"
      :onSave="onSave"
      :onFix="onFix"
      :onBack="onBack"
      :is_form_changed="is_form_changed"
    >
    </router-view>
    <CompletedModal
      ref="completed_modal"
      :onModalClose="moveAfterSave"
      :action_text="'更新'"
      :additional_text="'<br>DTVSが内容について確認を行い、<br>大企業へ公開されます。<br>審査完了までお待ちください。'"
      :isNoFade="isNoFade"
    />
  </div>
</template>

<script>
  import CompletedModal from '../../../common/pages/CompletedModal.vue';
  export default {
    metaInfo: {
      title: '最新情報更新',
    },

    components: {
      CompletedModal,
    },

    data() {
      return {
        provisional_su_latest_info: {},
        // 承認済みアセット一覧
        asset_masters: [],
        // 業界に紐づく承認済みアセット一覧
        industry_asset_masters: [],
        // 許容拡張子
        accept_extensions: [],
        // 許容するピッチファイルの拡張子
        pitch_file_accept_extensions: [],

        was_valid: false,
        validation_errors: {},
        previous_route: null,
        is_form_changed: false,

        form: {
          provisional_su_latest_info: {
            su_company_id: '',
            title:  '',
            detail: '',
            registed_type: '',
            exam_status: '',
            // メイン画像
            main_image_title: '',
            // サブ1画像
            sub1_image_title: '',
            // サブ2画像
            sub2_image_title: '',
            // メイン画像
            main_image_attributes: {
              // 画像新規アップロード情報
              // filename: '',
              // size: '',
              // upload_image: '',
            },
            // サブ画像1
            sub1_image_attributes: {
              // 画像新規アップロード情報
              // filename: '',
              // size: '',
              // upload_image: '',
            },
            // サブ画像2
            sub2_image_attributes: {
              // 画像新規アップロード情報
              // filename: '',
              // size: '',
              // upload_image: '',
            },
            // ピッチ資料
            uploaded_pitch_file: '',
            new_pitch_file_name: '',
            pitch_file_attributes: {
              filename: '',
            },
            // 選択アセット
            provisional_su_latest_info_asset_masters_attributes: [
              // {
              //   asset_master_id: '',
              // }
            ],
            // 削除アセット
            destroy_provisional_su_latest_info_asset_masters_attributes: [
              // {
              //   id: '',
              //   asset_master_id: '',
              // }
            ],
            // 登録予定のアセット
            asset_masters_attributes: [
              // {
              //   id: ''
              //   name: ''
              //   // 編集処理用
              //   is_edit: false
              // }
            ],
            // SU企業
            su_company_attributes: {
              id: '',
              // 事業概要
              business_summary: '',
              updated_at: '',
            },
          }
        },
        // メイン画像(表示用)
        main_image: {
          src: '',
          alt: '',
        },
        // サブ1画像(表示用)
        sub1_image: {
          src: '',
          alt: '',
        },
        // サブ2画像(表示用)
        sub2_image: {
          src: '',
          alt: '',
        },
      }
    },

    props: { isNoFade: Boolean },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.init();
    },

    // route.push時に実行
    beforeRouteUpdate(to, from, next) {
      next();
    },

    // コンポーネントを描画するルートが確立する前に呼ばれる
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.previous_route = from;
      });
    },

    // メソッド定義
    methods: {
      // 初期化
      init() {
        this.getProvisionalSuLatestInfo();
      },

      getProvisionalSuLatestInfo() {
        this.$loading.load(
          this.$auth.api.get(
            `su/provisional_su_latest_infos/form_init?provisional_su_latest_info_id=${this.$route.params.id}`, {params:{path: this.$route.name}}
          ).then(res => {
            // SU最新情報
            this.provisional_su_latest_info = res.data.provisional_su_latest_info;
            // 承認済みアセットマスタ一覧
            this.asset_masters = res.data.approval_asset_masters;
            // 許容拡張子
            this.accept_extensions = res.data.accept_extensions;
            // 許容するピッチファイルの拡張子
            this.pitch_file_accept_extensions = res.data.pitch_file_accept_extensions;

            // SU企業ID
            this.form.provisional_su_latest_info.su_company_id = this.provisional_su_latest_info.su_company.id;

            // 仮登録SU最新情報
            this.form.provisional_su_latest_info.id     = this.provisional_su_latest_info.id;
            this.form.provisional_su_latest_info.title  = this.provisional_su_latest_info.title;
            this.form.provisional_su_latest_info.detail = this.provisional_su_latest_info.detail || '';
            this.form.provisional_su_latest_info.updated_at = this.provisional_su_latest_info.updated_at;

            // ピッチ資料
            this.form.provisional_su_latest_info.uploaded_pitch_file            = this.provisional_su_latest_info.uploaded_pitch_file || '';
            this.form.provisional_su_latest_info.new_pitch_file_name            = this.provisional_su_latest_info.new_pitch_file_name || '';
            this.form.provisional_su_latest_info.pitch_file_attributes.id       = this.provisional_su_latest_info.pitch_file.id       || null;
            this.form.provisional_su_latest_info.pitch_file_attributes.filename = this.provisional_su_latest_info.pitch_file.filename || '';

            // 仮登録SU最新情報 アセット
            Object.entries(this.provisional_su_latest_info.asset_masters || {}).forEach(([key, asset_master]) => {
              // 承認済みか？
              if(asset_master.is_accepted) {
                // 紐づくアセット
                this.form.provisional_su_latest_info.provisional_su_latest_info_asset_masters_attributes.push(
                  {
                    'id': asset_master.relation_id,
                    'asset_master_id': asset_master.id,
                  }
                );
              }
              // 未承認か？
              else if(asset_master.is_undecided) {
                // 登録予定アセット
                this.form.provisional_su_latest_info.asset_masters_attributes.push(
                  {
                    'id': asset_master.id,
                    'name': asset_master.name,
                    'is_edit': false,
                    'relation_id': asset_master.relation_id,
                    'updated_at': asset_master.updated_at,
                  }
                );
              }
            });

            // 仮登録SU最新情報 画像
            this.form.provisional_su_latest_info.main_image_title                   = this.provisional_su_latest_info.main_image_title    || '';
            this.form.provisional_su_latest_info.main_image_attributes.id           = this.provisional_su_latest_info.main_image.id       || null;
            this.form.provisional_su_latest_info.main_image_attributes.filename     = this.provisional_su_latest_info.main_image.filename || '';
            this.form.provisional_su_latest_info.main_image_attributes.upload_image = this.provisional_su_latest_info.main_image.presigned_url || '';

            this.form.provisional_su_latest_info.sub1_image_title                   = this.provisional_su_latest_info.sub1_image_title    || '';
            this.form.provisional_su_latest_info.sub1_image_attributes.id           = this.provisional_su_latest_info.sub1_image.id       || null;
            this.form.provisional_su_latest_info.sub1_image_attributes.filename     = this.provisional_su_latest_info.sub1_image.filename || '';
            this.form.provisional_su_latest_info.sub1_image_attributes.upload_image = this.provisional_su_latest_info.sub1_image.presigned_url || '';

            this.form.provisional_su_latest_info.sub2_image_title                   = this.provisional_su_latest_info.sub2_image_title    || '';
            this.form.provisional_su_latest_info.sub2_image_attributes.id           = this.provisional_su_latest_info.sub2_image.id       || null;
            this.form.provisional_su_latest_info.sub2_image_attributes.filename     = this.provisional_su_latest_info.sub2_image.filename || '';
            this.form.provisional_su_latest_info.sub2_image_attributes.upload_image = this.provisional_su_latest_info.sub2_image.presigned_url || '';

            // SU企業 私たちについて
            this.form.provisional_su_latest_info.su_company_attributes.id = this.provisional_su_latest_info.su_company.id;
            this.form.provisional_su_latest_info.su_company_attributes.business_summary = this.provisional_su_latest_info.su_company.business_summary;
            // SU企業 更新日時
            this.form.provisional_su_latest_info.su_company_attributes.updated_at = this.provisional_su_latest_info.su_company.updated_at;

            // メイン画像 初期化
            this.main_image.src = this.provisional_su_latest_info.main_image.presigned_url || ''
            this.main_image.alt = this.form.provisional_su_latest_info.main_image_attributes.filename;

            // サブ1画像 初期化
            this.sub1_image.src = this.provisional_su_latest_info.sub1_image.presigned_url || ''
            this.sub1_image.alt = this.form.provisional_su_latest_info.sub1_image_attributes.filename;

            // // サブ2画像 初期化
            this.sub2_image.src = this.provisional_su_latest_info.sub2_image.presigned_url || ''
            this.sub2_image.alt = this.form.provisional_su_latest_info.sub2_image_attributes.filename;

            // 入力項目の監視を開始（非同期処理でフォーム変更と監視開始のタイミングが前後するためここで実施する）
            this.$watch('form', function(){ this.is_form_changed = true }, { deep: true });
            this.$watch('main_image', function(){ this.is_form_changed = true }, { deep: true });
            this.$watch('sub1_image', function(){ this.is_form_changed = true }, { deep: true });
            this.$watch('sub2_image', function(){ this.is_form_changed = true }, { deep: true });

          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        )
      },

      onTemporarily() {
        // 下書きボタン経由のためtrueをonConfirmに渡す
        this.onConfirm(true);
      },

      onConfirm(is_draft = false) {
        // 登録種別 編集
        this.form.provisional_su_latest_info.registed_type = 'edit'

        // 下書き
        if(is_draft) {
          // 審査ステータス 下書き
          this.form.provisional_su_latest_info.exam_status = 'draft';
          // 登録種別 登録
          this.form.provisional_su_latest_info.registed_type = 'registration'
        }
        // 下書き
        else if(this.provisional_su_latest_info.is_draft) {
          // 審査ステータス 未承認
          this.form.provisional_su_latest_info.exam_status = 'undecided';
          // 登録種別 登録
          this.form.provisional_su_latest_info.registed_type = 'registration'
        }
        // 承認
        else if(this.provisional_su_latest_info.is_accepted) {
          // 審査ステータス 未承認
          this.form.provisional_su_latest_info.exam_status = 'undecided';
        }
        // 要修正
        else if(this.provisional_su_latest_info.is_modify) {
          // 審査ステータス 要登録
          this.form.provisional_su_latest_info.exam_status = 'reregister';
        }
        // 未承認
        else if(this.provisional_su_latest_info.is_undecided){
          // 審査ステータス 未承認
          this.form.provisional_su_latest_info.exam_status = 'undecided';
        }
        // 再登録
        else if(this.provisional_su_latest_info.is_reregister){
          // 審査ステータス 要申請
          this.form.provisional_su_latest_info.exam_status = 'reregister';
        }

        // 値をpostする形にするため編集をコピー
        let post_form = JSON.parse(JSON.stringify(this.form));

        // 登録するアセットと削除するアセットのパラメータを結合
        let provisional_su_latest_info_asset_masters_attributes = post_form.provisional_su_latest_info.provisional_su_latest_info_asset_masters_attributes
        let destroy_provisional_su_latest_info_asset_masters_attributes = post_form.provisional_su_latest_info.destroy_provisional_su_latest_info_asset_masters_attributes
        let concat_provisional_su_latest_info_asset_masters_attributes = provisional_su_latest_info_asset_masters_attributes.concat(destroy_provisional_su_latest_info_asset_masters_attributes);

        // 削除するアセットのパラメータを削除(結合したので不要)
        this.$delete(post_form.provisional_su_latest_info, 'destroy_provisional_su_latest_info_asset_masters_attributes');
        // postする値に結合したパラメータを設定
        post_form.provisional_su_latest_info.provisional_su_latest_info_asset_masters_attributes = concat_provisional_su_latest_info_asset_masters_attributes

        this.$loading.load(
          this.$auth.api.post(
            'su/provisional_su_latest_infos/confirm?', post_form
          ).then(res => {
            // 確認画面を表示
            this.$router.push({name: 'SuProvisionalSuLatestInfoEditConfirm'});
          }).catch(err => {
            // バリデーションエラー
            if (err.response.status == 422) {
              this.validation_errors = err.response.data.errors;
            }
            else if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else {
              this.$errorHandlers.ajax(err);
            }
          })
        );
      },

      // 保存ボタン押下
      onSave() {
        // 値をpostする形にするため編集をコピー
        let post_form = JSON.parse(JSON.stringify(this.form));

        // 登録する業界と削除するアセットのパラメータを結合
        let provisional_su_latest_info_asset_masters_attributes = post_form.provisional_su_latest_info.provisional_su_latest_info_asset_masters_attributes
        let destroy_provisional_su_latest_info_asset_masters_attributes = post_form.provisional_su_latest_info.destroy_provisional_su_latest_info_asset_masters_attributes
        let concat_provisional_su_latest_info_asset_masters_attributes = provisional_su_latest_info_asset_masters_attributes.concat(destroy_provisional_su_latest_info_asset_masters_attributes);

        // 削除するアセットのパラメータを削除(結合したので不要)
        this.$delete(post_form.provisional_su_latest_info, 'destroy_provisional_su_latest_info_asset_masters_attributes');
        // postする値に結合したパラメータを設定
        post_form.provisional_su_latest_info.provisional_su_latest_info_asset_masters_attributes = concat_provisional_su_latest_info_asset_masters_attributes

        this.$loading.load(
          this.$auth.api.patch(
            `su/provisional_su_latest_infos/${this.$route.params.id}`, post_form
          ).then(res => {
            // 下書き保存以外で最新情報を更新した場合は更新完了モーダル表示
            if (this.form.provisional_su_latest_info.exam_status != 'draft' && res.data.is_edit_latest_info){
              this.$refs.completed_modal.open();
            }
            else{
              this.moveAfterSave();
            }
          }).catch(err => {
            // バリデーションエラー
            if (err.response.status == 422) {
              this.validation_errors = err.response.data.errors;
            }
            else if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else {
              this.$errorHandlers.ajax(err);
            }
          })
        );
      },

      // 保存後の画面遷移処理
      moveAfterSave(){
        if (!!this.previous_route && this.previous_route.name === 'SuProvisionalSuLatestInfoIndex') {
          this.$router.push({path: this.previous_route.fullPath, query: {toast_type: 'updated'}});
        }
        else if (this.previous_route.name === 'SuProvisionalSuLatestInfoShow') {
          this.$router.push({name: 'SuProvisionalSuLatestInfoShow',  query: {toast_type: 'updated'}});
        }
        else {
          this.$router.push({name: 'SuProvisionalSuLatestInfoIndex', query: {toast_type: 'updated'}});
        }
      },

      // 修正ボタン押下時処理
      onFix() {
        this.$router.push({name: 'SuProvisionalSuLatestInfoEdit'});
      },

      // 戻るボタン押下時処理
      onBack() {
        if (!!this.previous_route && this.previous_route.name === 'SuProvisionalSuLatestInfoIndex') {
          this.$router.push({path: this.previous_route.fullPath});
        }
        else if (!!this.previous_route && this.previous_route.name === 'SuProvisionalSuLatestInfoShow') {
          this.$router.push({path: this.previous_route.fullPath});
        }
        else {
          this.$router.push({name: 'SuProvisionalSuLatestInfoIndex'});
        }
      },
    },


  }

</script>