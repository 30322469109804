const initialState = () => {
  return {
    user: {
      id: "",
      email: "",
      last_name: "",
      first_name: "",
      kana_last_name: "",
      kana_first_name: "",
      account_type: "",
      position: "",
      department: "",
      password: "",
      password_confirmation: "",
      uploaded_image: "",
      new_image_name: "",
      image_size: "",
      updated_at: "",
      image_attributes: {id: "", filename: ""},
      presigned_url: ""
    }
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    reset(state){
      Object.assign(state, initialState())
    },
    setUser (state, data) {
      state.user.id = data.user.id
      state.user.email = data.user.email
      state.user.last_name = data.user.last_name
      state.user.first_name = data.user.first_name
      state.user.kana_last_name = data.user.kana_last_name
      state.user.kana_first_name = data.user.kana_first_name
      state.user.account_type = data.user.account_type
      state.user.position = data.user.position
      state.user.department = data.user.department
      state.user.password = data.user.password
      state.user.password_confirmation = data.user.password_confirmation
      state.user.uploaded_image = data.user.uploaded_image
      state.user.new_image_name = data.user.new_image_name
      state.user.image_size = data.user.image_size
      state.user.updated_at = data.user.updated_at
      state.user.image_attributes = data.user.image_attributes
      state.user.presigned_url = data.user.presigned_url
    },
    initUser (state) {
      state.user.id = ''
      state.user.email = ''
      state.user.last_name = ''
      state.user.first_name = ''
      state.user.kana_last_name = ''
      state.user.kana_first_name = ''
      state.user.account_type = ''
      state.user.position = ''
      state.user.department = ''
      state.user.password = ''
      state.user.password_confirmation = ''
      state.user.uploaded_image = ""
      state.user.new_image_name = ""
      state.user.image_size = ""
      state.user.updated_at = ""
      state.user.image_attributes = {id: "", filename: ""}
      state.user.presigned_url = ""
    }
  },
  getters: {
    user: state => {
      return state.user
    }
  }
}