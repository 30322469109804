<template>
  <main>
    <Header :accountType="accountType" />
    <MiniHeader :accountType="accountType" />
    <div class="request-flow-image">
      <ul>
        <li class="flow-title"><img src="~images/request_flow_title.svg" alt="Step"></li>
        <li class="flow-01"><img src="~images/request_flow1_on.svg" alt="Step1 メールアドレス入力"></li>
        <li class="flow-02"><img src="~images/request_flow2_on.svg" alt="Step2 メール送信"></li>
        <li class="flow-03"><img src="~images/request_flow3_on.svg" alt="Step3 申請情報入力"></li>
        <li class="flow-04"><img src="~images/request_flow4_on.svg" alt="Step4 申請情報確認"></li>
        <li class="flow-05"><img src="~images/request_flow5_on.svg" alt="Complete 申請完了"></li>
      </ul>
    </div>

    <div class="overview-text-area text-center mt-5">
      <h2>登録申請を受け付けました。</h2>
      <!-- TODO: 営業日 -->
      <p class="mt-4">ご登録いただいた情報を運営事務局で確認いたしますので登録完了までしばらくお待ち下さい。<br>（審査に1～2週間ほどいただく場合があります）</p>
      <p class="mt-3">結果につきましては、ご入力いただいたメールアドレス宛にお送りさせていただきます。</p>
    </div>

    <div class="bg-useflow-tp"><img src="~images/bg_useflow.svg" alt=""></div>

    <div class="bg-useflow-bm"><img src="~images/bg_useflow2.svg" alt=""></div>
  </main>
</template>

<script>
  import Header from "../registration_auths/Header";
  import MiniHeader from "../registration_auths/MiniHeader";

  export default {
    metaInfo: {
      title: 'six brainアカウント登録申請'
    },
    name: "Complete",
    components: {
      Header,
      MiniHeader,
    },
    props: {
      accountType: String
    },
  }
</script>

<style scoped>

</style>