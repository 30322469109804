<template>
  <header class="top-header">
    <div class="d-logo"><a :href="'/' + accountType + '/login'"><img src="~images/header_logo.svg" alt="six brain" /></a></div>
    <div class="btn-container">
      <button class="btn btn-white rounded-pill" type="button" @click="onClickLogin">ログイン</button>
      <button class="btn btn-green rounded-pill" type="button" @click="onClickRegister">アカウント作成</button>
    </div>
  </header>
</template>

<script>
  export default {
    props: {
      accountType: String
    },
    methods: {
      onClickLogin() {
        if (this.accountType === 'ep') {
          this.$router.push({name: 'EpLogin'});
        } else {
          this.$router.push({name: 'SuLogin'});
        }
      },
      onClickRegister() {
        if (this.accountType === 'ep') {
          if(this.$route.name !== 'EpRegistrationAuthInfo') {
            this.$router.push({name: 'EpRegistrationAuthInfo'});
          }else{
            ;
          }
        } else {
          if(this.$route.name !== 'SuRegistrationAuthNew') {
            this.$router.push({name: 'SuRegistrationAuthNew'});
          }else{
            ;
          }
        }
      },
    },
  }
</script>

<style scoped>

</style>