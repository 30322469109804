<template>
  <main>
    <header class="mini-header">
      <p class="page-title">大企業登録完了</p>
    </header>
    <div class="overview-text-area text-center mt-5">
      <h2>会員登録が完了しました</h2>
      <p class="mt-4">続けてチャレンジ情報を入力する場合は下記ボタンより登録してください。</p>
    </div>

    <div class="btn-container d-flex justify-content-center mt-5">
      <button class="button-square btn-send" type="button" @click="onClick">チャレンジを新規作成</button>
    </div>
  </main>
</template>

<script>

  export default {
    metaInfo: {
      title: '大企業登録完了'
    },

    methods: {
      onClick() {
        this.$router.push({name: 'AdminProvisionalEpChallengeNew', params: { init_select_id: this.$route.params.id }})
      }
    }
  }
</script>

<style scoped>

</style>