<template>
  <div class="side-scroll-container">
    <nav>
      <ul class="list-unstyled">
        <li @click="routerPush('EpSuLatestInfoIndex')" :class="currentPage.includes('latest_infos') ? currentClass : ''">
          <a><i></i><span>スタートアップ情報一覧</span></a>
        </li>
        <li @click="routerPush('EpRecommendIndex')" :class="currentPage.includes('recommends') ? currentClass : ''">
          <a><i></i><span>リコメンド一覧</span></a>
        </li>
        <li @click="routerPush('EpOfferIndex')" :class="[currentPage.includes('offers')?currentClass:'']">
          <a><i></i><span>オファー一覧</span></a>
        </li>
        <li @click="routerPush('EpProvisionalEpChallengeIndex')" :class="[currentPage.includes('provisional_ep_challenges')?currentClass:'']">
          <a><i></i><span>チャレンジ一覧</span></a>
        </li>
        <li @click="routerPush('EpMessageRoom', {room_id: message_room_id})" :class="[currentPage.includes('message') ? currentClass:'']">
          <a><i></i><span>メッセージ</span></a>
        </li>
        <li v-if="is_paid_member" @click="routerPush('EpPortfolioIndex')" :class="[currentPage.includes('portfolios') ? currentClass:'']">
          <a><i></i><span>Portfolio Manager</span></a>
        </li>
        <li @click="routerPush('EpEpCompanyShow')" :class="[currentPage.includes('companies')?currentClass:'']">
          <a><i></i><span>アカウント情報</span></a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    departmentId: Number,
    currentUserId: Number,
    message_room_id: Number,
    is_paid_member: Boolean,
  },
  data() {
    return {
      currentClass: 'current',
      activeClass: 'active',
    };
  },
  computed: {
    currentPage() {
      return this.$route.fullPath
    },
  },
  methods: {
    // 画面遷移処理
    routerPush(route_name, params = {}){
      // 遷移元と遷移先のルート名が同じ場合は何もしない
      if (this.$route.name != route_name){
        this.$router.push({name: route_name, params: params} , () => {}, () => {});
      }
    }
  },
}
</script>
