<template>
  <div class="all-wrapper">
    <HeaderComponent
      :companyname="companyname"
      :message="message"
      :icon="icon"
      :notification-count="notification_count"
     />

    <div class="drawer">
      <input class="drawer-toggle" id="drawer-checkbox" type="checkbox" v-model="isOpen">
      <label class="drawer-btn" for="drawer-checkbox"><span></span><span></span><span></span></label>
    </div>

    <div class="side-area">
      <SidemenuComponent :message_room_id="message_room_id"/>
    </div>

    <div class="main-scroll-container">
      <!-- main-area-->
      <div class="main-area">
        <router-view :currentUserId="current_user_id" :isNoFade="is_no_fade" />
        <FooterComponent />
      </div>
    </div>

  </div>
</template>

<script>
import HeaderComponent from '../../common/layouts/header';
import FooterComponent from '../../common/layouts/footer';
import SidemenuComponent from './Sidemenu.vue';
// 端末判別コンポーネント
import DeviceCheck from '../../common/mixins/device_check.js';

export default {
  components: {
    HeaderComponent,
    SidemenuComponent,
    FooterComponent
  },
  mixins: [DeviceCheck],

  metaInfo() {
    return {
      bodyAttrs: {
        class: this.isOpen ? ['drawer-open'] : []
      }
    }
  },
  data() {
    return {
      companyname: null,
      message: null,
      icon: null,
      message_room_id: null,
      notification_count: null,
      is_no_fade: false,
      current_user_id: null,
      isOpen: false,
    }
  },
  mounted () {
    this.getCompanyInfo();
    this.browserIECheck();
  },
  methods: {
    getCompanyInfo () {
      this.$auth.api.get('user_sessions')
      .then(res => {
        this.current_user_id = res.data.current_user_id;
        this.companyname = res.data.companyname;
        this.message = res.data.message;
        this.icon = res.data.icon;
        this.message_room_id = res.data.message_room_id;
        this.notification_count = res.data.notification_count;
      }).catch(err => {
        this.$errorHandlers.initial(err);
        if(err.response.status === 401) {
          this.$router.push({ name: 'SuLogin', query: {error_status: 401}});
        }
      })
    },
    browserIECheck(){
      var ua = navigator.userAgent;
      if(ua.indexOf('Trident') !== -1) {
        this.is_no_fade = true;
      }
    },
  },
  created: function () {
  },
  watch: {
    '$route' (to, from) {
      if(from.name === "SuSuEditConfirm" && to.name === "SuSuCompanyDetail") {
        this.getCompanyInfo();
      }
      // 前画面情報を取得
      this.$store.commit('back_to_index_modal/setFromPage', {
        full_path: from.fullPath,
        name: from.name,
        path: from.path
      });
      this.isOpen = false;
    }
  }
}
</script>
