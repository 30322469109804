<template>
  <main v-if="scenario">
    <section class="main-title scenario-details-header">
      <h1>シナリオ詳細</h1>
      <div class="btn-container">
        <router-link
          :to="{ name: 'EpPortfolioShow', params: { id: scenario.portfolio_id }}"
          class="btn btn-md btn-outline-green rounded-pill"
        >
          ポートフォリオ詳細に戻る
        </router-link>
        <router-link
          :to="{ name: 'EpScenarioEdit' }"
          class="btn btn-md btn-outline-green rounded-pill"
        >
          シナリオ編集に戻る
        </router-link>
        <button
          v-if="$route.name === 'EpScenariosShowStep1'"
          class="button-square btn-send icon-addon-left"
          type="button"
          @click="$refs.step_child.csvUploadModalOpen()"
        >
          <i class="fas fa-upload" />CSVアップロード
        </button>
      </div>
    </section>
    <section class="chapter white-block scenario">
      <h2 class="chapter-title">
        {{ scenario.name }}
        <span
          class="badge badge-md"
          :class="statusClass"
        >
          {{ scenario.scenario_status_i18n }}
        </span>
      </h2>
      <div class="p-3 border-top">
        <p class="text-box">PL</p>
      </div>
    </section>
    <StepList />
    <router-view
      ref="step_child"
      :inEdit.sync="inEdit"
      :scenario="scenario"
      :errors="errors"
      :uploadErrors="uploadErrors"
      @replaceScenario="replaceScenario"
      @updateJsonScenario="updateJsonScenario"
      @removePer="deletePer"
      @uploadCsv="uploadCsv"
      @uploadErrorsClear="uploadErrors = []"
    />
  </main>
</template>

<script>
  import StepList from './parts/StepList';

  export default {
    metaInfo: {
      title: 'シナリオ詳細',
    },
    components: { StepList },
    data() {
      return {
        inEdit: false,
        scenario: null,
        errors: [],
        uploadErrors: [],
      };
    },
    computed: {
      statusClass() {
        if (!this.scenario) return '';

        switch (this.scenario.scenario_status) {
          case 'considering_investment':
            return 'status-1';
          case 'considered_investment':
            return 'status-2';
          case 'exited':
            return 'status-3';
        }
      },
    },
    mounted() {
      this.getScenario();
    },
    methods: {
      getScenario() {
        const proc = this.$auth.api.get(`ep/scenarios/${this.$route.params.id}`)
          .then(res => {
            this.setScenario(res.data.scenario);
          })
          .catch(err => {
            this.$errorHandlers.initial(err);
          });

        this.$loading.load(proc);
      },
      updateJsonScenario() {
        const proc = this.$auth.api.patch(`ep/scenarios/${this.$route.params.id}/json_update`, {
          scenario: this.scenario,
        })
          .then(res => {
            this.errors = [];
            this.uploadErrors = [];
            this.setScenario(res.data.scenario);
            this.inEdit = false;
          })
          .catch(err => {
            if (err.response.status == 422) {
              this.errors = Object.values(err.response.data.errors).flat();
            } else if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            } else {
              this.$errorHandlers.ajax(err);
            }
          });

        this.$loading.load(proc);
      },
      deletePer(uuid) {
        const proc = this.$auth.api.delete(`ep/scenarios/${this.$route.params.id}/pers/${uuid}`, {
          params: { updated_at: this.scenario.updated_at, },
        })
          .then(res => {
            this.scenario = {
              ...this.scenario,
              pers: this.scenario.pers.filter(v => v.uuid !== uuid),
              updated_at: res.data.scenario.updated_at,
            };
          })
          .catch(err => {
            if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            } else {
              this.$errorHandlers.ajax(err);
            }
          });

        this.$loading.load(proc);
      },
      uploadCsv(file, sucessCallback) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('updated_at', this.scenario.updated_at);
        const proc = this.$auth.api.patch(`ep/scenarios/${this.$route.params.id}/json_update_with_csv`, formData)
          .then(res => {
            this.errors = [];
            this.uploadErrors = [];
            this.setScenario(res.data.scenario);
            sucessCallback();
          })
          .catch(err => {
            if (err.response.status == 400) {
              this.uploadErrors = [err.response.data.error];
            } else if (err.response.status == 422) {
              this.uploadErrors = Object.values(err.response.data.errors).flat();
            } else if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            } else {
              this.$errorHandlers.ajax(err);
            }
          });

        this.$loading.load(proc);
      },
      setScenario(scenario) {
        if (scenario.is_temporary) {
          this.$router.push({ name: 'EpScenarioEdit' });
        } else {
          this.scenario = scenario;
        }
      },
      replaceScenario(scenario) {
        this.scenario = scenario;
      },
    },
  };
</script>