import Store from './store'
import Axios from 'axios'
import Http from 'http'
import Https from 'https'

const API_URL = '/api'

export default class Auth{
	login(email, password, loginType, userId=null){
		Store.dispatch('resetAll');
		return this.axios.post(API_URL + '/v1/auth/sign_in', {
			email: email,
			password: password,
			login_type: loginType,
			id: userId
		}).then(res => {
			if(res.data.status == "OK"){
				const data = {
					access_token: res.headers['access-token'],
					client: res.headers['client'],
					uid: res.headers['uid'],
					user: res.data.data,
					plan: res.data.plan,
				}
				Store.commit('auth/login', data);
			}
			return res;
		})
	}

	logout(){
		const proc = this.api.delete('auth/sign_out'
			).then(res => {
			}).catch(err => {
				if(err.response.status == 404 && !err.response.data.success){
					return Promise.resolve(err.response);
				}else{
					return Promise.reject(err);
				}
			});
		Store.commit('auth/logout');
		return proc;
	}

	verifyOtp(code, encdata){
		return this.axios.post(API_URL + '/v1/auth/verify', {
			code: code,
			encdata: encdata
		}).then(res => {
			if(res.data.status == "OK"){
				const data = {
					access_token: res.headers['access-token'],
					client: res.headers['client'],
					uid: res.headers['uid'],
					user: res.data.data,
					plan: res.data.plan,
				}
				Store.commit('auth/login', data);
			}
			return res;
		})
	}

	resendOtp(encdata){
		return this.axios.post(API_URL + '/v1/auth/resend', {
			encdata: encdata
		});
	}

	editableAccount(email){
		if(this.accountType == "dtvs_admin"){
			return true;
		}
		if(this.accountType == "dtvs_cm" && Store.state.auth.uid == email){
			return true;
		}
		return false;
	}

	// 2段階認証設定可能か判定
	twoFactorAuthEnableAccount(email, id) {
		return Store.state.auth.uid == email + '/' + id;
	}

	get isLoggedIn(){
		return Store.state.auth.access_token != null && Store.state.auth.uid != null && Store.state.auth.client != null;
	}

	get userId(){
		return Store.state.auth.userId;
	}

	// アカウント種別
	get accountType(){
		return Store.state.auth.account_type;
	}

	get urlPrefix(){
		switch(this.accountType){
			case 'dtvs_admin':
			case 'dtvs_cm':
				return 'admin'
			case 'ep_admin':
			case 'ep_person_in_charge':
				return 'ep';
			case 'su_user':
			case 'su_person_in_charge':
				return 'su';
			default:
				return null;
		}
	}

	// 契約プランマスター
	get plan(){
		return Store.state.auth.plan;
	}

	// 契約プランがフリーか？
	get isFree(){
		return Store.state.auth.plan.plan_name === 'Free'
	}

	get axios(){
		const csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
		const headers = {
			"X-Requested-With": "XMLHttpRequest",
			"X-CSRF-Token": csrf_token
		};

		if (this.isLoggedIn) {
			console.log(Store.state.auth);
			// トークン追加
			headers['access-token'] = Store.state.auth.access_token;
			headers['client'] = Store.state.auth.client;
			headers['uid'] = Store.state.auth.uid;
			headers['id'] = Store.state.user.id;
		}

		const httpAgent = new Http.Agent({ keepAlive: true });
		const httpsAgent = new Https.Agent({ keepAlive: true });

		const axios = Axios.create({
			headers: headers,
			httpAgent: httpAgent,
			httpsAgent: httpsAgent,
		});

		const handleResponse = (res) => {
			var token = res.headers['x-csrf-token'];
			if (token) {
				document.querySelector('meta[name="csrf-token"]').setAttribute('content', token)
			}

			if(res.headers['access-token']){
				Store.commit('auth/refreshAccessToken', {
					access_token: res.headers['access-token'],
				})
			}

			return res;
		};

		axios.interceptors.response.use(
			handleResponse,
			err => {
				if(err.response){
					handleResponse(err.response);
				}

				return Promise.reject(err);
			}
		);

		return axios;
	}

	get api(){
		const axios = this.axios;
		axios.defaults.baseURL = API_URL + "/v1/";

		return axios;
	}
}
