<template>
  <div>
    <main>
      <section class="main-title d-flex">
        <h1>オファー一覧</h1>
      </section>

      <!-- 検索-->
      <IndexConditionSection
        @search-button-click="onSearchFromChild"
        :offer-statuses="offer_statuses"
        :has-record="hasRecord"
        :loaded = "loaded"
      />

      <!-- リスト-->
      <section class="reading-block-container">
        <div class="white-block reading-block" v-for="offer in offers" :key="offer.id">
          <div class="reading-visual">
            <a class="img-box" href="#" @click.prevent.stop="onClickShow(offer)">
              <img class="img320" v-if="offer.presigned_latest_info_main_image_url"
                  :src="offer.presigned_latest_info_main_image_url"
                  :alt="offer.presigned_latest_info_main_image_filename">
              <img class="img320" v-else
                  src="~images/image_default.jpg"
                  alt="デフォルト画像">
            </a>
            <label v-if="offer.offer_detail_status == 'レビュー待ち'" class="status-1">レビュー待ち</label>
            <label v-else-if="offer.offer_detail_status == '送付中'" class="status-2">送付中</label>
            <label v-else-if="offer.offer_detail_status == 'マッチング'" class="status-3">マッチング</label>
            <label v-else-if="offer.offer_detail_status == '差し戻し中'" class="status-4">差し戻し中</label>
            <label v-else-if="offer.offer_detail_status == 'クローズ'" class="status-5">クローズ</label>
          </div>
          <div class="reading-bar">
            <small class="reading-date text-secondary">{{ offer.ep_checked_at | format.datetime }}</small>
            <span v-if="offer.recommend_type == 'オファー'" class="offer-tag type-1">オファー</span>
            <span v-else-if="offer.recommend_type == '提案歓迎'" class="offer-tag type-2">提案歓迎</span>
          </div>
          <h2 class="reading-title">
            <a class="popover-trigger text-ellipsis" :id="'offer-' + offer.id" tabindex="0" data-content="" href="#" @click.prevent.stop="onClickShow(offer)">
              {{ offer.su_latest_info.title | omittedText(35) }}
            </a>
            <b-popover
              :target="'offer-' + offer.id"
              triggers="hover"
              placement="auto"
            >
              <span :inner-html.prop="offer.su_latest_info.title | htmlEscape | nl2br"></span>
            </b-popover>
          </h2>
          <dl class="reading-body mt-3">
            <dt>
              <img v-if="offer.presigned_latest_info_manager_image_url"
                  class="thumbnail-frame img40"
                  :src="offer.presigned_latest_info_manager_image_url"
                  :alt="offer.presigned_latest_info_manager_image_filename">
              <img v-else class="thumbnail-frame img40" src="~images/su_user_default.png" alt="">
            </dt>
            <dd>
              <p class="company-name">{{ offer.su_company.company_name }}</p>
            </dd>
          </dl>
          <div class="challenge-detail">
            <span class="challenge-detail-label">チャレンジ</span>
            <p v-if="offer.ep_challenge" class="challenge-title">{{ offer.ep_challenge.title | omittedText(20) }}</p>
          </div>
        </div>
      </section>
      <!-- / リスト-->
      <Pagination :total_page="total_page" :total_count="total_count" :current_page="current_page" :current_per="current_per" :page_link_num="page_link_num" :onChangePage="onChangePage" v-if="hasRecord" />
    </main>
  </div>
</template>

<script>
// ページネーションのコンポーネント読み込み
import Pagination from '../../../common/pages/Pagination.vue';
// 検索エリアのコンポーネント読み込み
import IndexConditionSection from "./IndexConditionSection";

export default {
  metaInfo: {
    title: 'オファー一覧',
  },

  components: {
    IndexConditionSection,
    Pagination,
  },

  data() {
    return {
      // リコメンドステータス（検索用）
      offer_statuses: [],
      // データ
      offers: [],
      // 現在ページ数
      current_page: Number(this.$route.query.page) || 1,
      // 現在の表示件数
      current_per:  0,
      // 総ページ数
      total_page: 0,
      // 総レコード数
      total_count: 0,
      // 表示するリンクの数
      page_link_num: 5,
      // 読み込み完了フラグ
      loaded: false,
    }
  },

  computed: {
    /**
     * 検索結果が存在するか
     */
    hasRecord: function(){
      return Object.keys(this.offers).length > 0
    }
  },

  methods: {
    // データ取得処理
    getOffers(query) {
      this.$loading.load(
        this.$auth.api.get(
          'ep/offers', {
            params: query
          }
        ).then(res => {
          this.offers = res.data.offers || [];
          this.offer_statuses = res.data.offer_statuses;
          this.current_per  = Number(res.data.per);
          this.current_page = Number(res.data.page);
          this.total_page   = res.data.total_page;
          this.total_count  = res.data.total_count;
          this.loaded = true;
        }).catch(err => {
          this.$errorHandlers.initial(err);
        })
      )
    },

    // ページ切り替え時
    onChangePage(page, per) {
      let query = Object.assign({}, this.$route.query);
      query['page'] = this.current_page = page;
      query['per'] = this.current_per = per;
      // 画面更新
      this.reload(query);
    },

    /**
     * 検索ボタンが押下された時の処理.
     * (子Componentから$emitされる)
     */
    onSearchFromChild: function(form = {}){
      // 画面更新
      this.reload(form);
    },


    // オファー詳細画面へ遷移
    onClickShow(offer) {
      this.$router.push({name: 'EpOfferShow', params: {id: offer.id}})
    },

    // 更新処理
    reload(query = []) {
      this.$router.push({ name: 'EpOfferIndex', query: query }).catch(err => {});
    },

  },

  filters: {
    // 文字列を省略して表示
    omittedText(text, len) {
      return text.length > len ? text.slice(0, len) + "…" : text;
    },
  },

  // インスタンス生成後、リアクティブデータ初期化後
  created() {
    if(Object.keys(this.$route.query).length == 0){
      this.$route.query.offer_statuses = ['wait', 'returned'];
    }
    this.getOffers(this.$route.query);
  },

  // route.push時に実行
  beforeRouteUpdate (to, from, next) {
    this.getOffers(to.query);
    next();
  },


}
</script>
