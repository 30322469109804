export default {
  install: function (Vue, options) {
	Vue.mixin({
		beforeCreate: function(){
			this.$errorHandlers = {
				common: (err) => {
					console.log(err);

					if(!err.response){
						return true;
					}

					return false;
				},

				// 初期表示エラー
				// returns: 処理されたらture
				initial: (err) => {
					if(this.$errorHandlers.common(err)){
						return true;
					}else
					// 認証エラー
					if(err.response.status == 401){
						const path = this.$router.currentRoute.path
						if(path.indexOf("/admin") === 0){
							this.$router.push({ path:"/admin/login", query: {error_status: 401}});
						}else if(path.indexOf("/ep") === 0){
							this.$router.push({ path:"/ep/login", query: {error_status: 401}});
						}else if(path.indexOf("/su") === 0){
							this.$router.push({ path:"/su/login", query: {error_status: 401}});
						}else{
							this.$router.push("/");
						}
						return true;
					}else
					// Validationエラー
					if(err.response.status == 400){
				
					}else
					// not found
					if(err.response.status == 404){
					// 404ページに遷移
						const path = this.$router.currentRoute.path
						if(path.indexOf("/admin") === 0){
							this.$router.push({ path:"/admin/404"});
						}else if(path.indexOf("/ep") === 0){
							this.$router.push({ path:"/ep/404"});
						}else if(path.indexOf("/su") === 0){
							this.$router.push({ path:"/su/404"});
						}else{
							this.$router.push("/404")
						}
						return true;
					// その他エラー
					}else{
						// システムエラーページに遷移
						this.$router.push("/error")
						return true;
					}
				},

				// 動的エラー
				// returns: 処理されたらture
				ajax: (err) => {
					if(this.$errorHandlers.common(err)){
						return true;
					}else
					// 認証エラー
					if(err.response.status == 401){
						const path = this.$router.currentRoute.path
						var link = null;
						if(path.indexOf("/admin") === 0){
							link = "/admin/login";
						}else if(path.indexOf("/ep") === 0){
							link = "/ep/login";
						}else if(path.indexOf("/su") === 0){
							link = "/su/login";
						}else{
							link = "/";
						}
						this.$bvToast.toast(err.response.data.error || window.defaultMessages.sessionError, {
							title: window.defaultMessages.errorTitle,
							href: link,
							variant: 'danger'
						})
						return true;
					}else
					// Validationエラー
					if(err.response.status == 400){
					}else
					// not found
					if(err.response.status == 404){
						this.$bvToast.toast(err.response.data.error || window.defaultMessages.error, {
							title: window.defaultMessages.errorTitle,
							variant: 'danger'
						})
						return true;
					}else
					// 保存失敗
					if(err.response.status == 409){
						this.$bvToast.toast(err.response.data.error || window.defaultMessages.error, {
							title: window.defaultMessages.errorTitle,
							variant: 'danger'
						})
						return true;
					// その他エラー
					}else{
						this.$bvToast.toast(err.response.data.error || window.defaultMessages.error, {
							title: window.defaultMessages.errorTitle,
							variant: 'danger'
						})
						return true;
					}
				}
			}
		}
	})
  }
}
