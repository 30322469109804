<template>
  <div>
    <main>
      <section class="main-title">
        <h1>チャレンジ一覧</h1>
      </section>

      <!-- 検索-->
      <section class="search-block">
        <form @submit.prevent>
          <div class="accordion" id="serach">
            <div class="form-group card">
              <div class="card-header" id="searach-parent"><a class="collapsed" data-toggle="collapse" href="#" @click.prevent.stop="" v-b-toggle.searach-toggle-child>検索<i class="fas"></i></a></div>
              <b-collapse id="searach-toggle-child" :visible="InitialSearchSectionOpening" accordion="my-accordion" role="tabpanel">
                <div class="card-body collapse show" id="searach-child">
                  <div class="form-group row">
                    <label class="col-2 col-form-label" for="ep">企業名</label>
                    <div class="col-10 incremental-wrap">
                      <input v-model="form.company_name" class="form-control" type="text" id="asset" maxlength="100">
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-2 col-form-label" for="name">部署名</label>
                    <div class="col-10 incremental-wrap">
                      <input v-model="form.department_name" class="form-control" type="text" id="asset" maxlength="40">
                    </div>
                  </div>

                  <fieldset class="form-group">
                    <div class="row">
                      <legend class="col-2 col-form-label">審査ステータス</legend>
                      <div class="col-10 align-self-center single-check">
                        <div class="main-checkbox d-inline-block">
                          <div v-for="(exam_statuses_i18n, exam_status_key) in exam_statuses" :key="exam_status_key" class="custom-control custom-checkbox custom-control-inline">
                            <input
                              v-model="form.exam_statuses"
                              type="checkbox"
                              class="custom-control-input"
                              :id="'status_' + exam_status_key"
                              :value="exam_status_key"
                            >
                            <label :for="'status_' + exam_status_key" class="custom-control-label" >{{ exam_statuses_i18n }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <fieldset class="form-group">
                    <div class="row">
                      <legend class="col-2 col-form-label">公開ステータス</legend>
                      <div class="col-10 align-self-center single-check">
                        <div class="main-checkbox d-inline-block">
                          <div v-for="(public_statuses_i18n, public_status_key) in public_statuses" :key="public_status_key" class="custom-control custom-checkbox custom-control-inline">
                            <input
                              v-model="form.public_statuses"
                              type="checkbox"
                              class="custom-control-input"
                              :id="'status_' + public_status_key"
                              :value="public_status_key"
                            >
                            <label :for="'status_' + public_status_key" class="custom-control-label" >{{ public_statuses_i18n }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <div class="row">
                    <label class="col-2 col-form-label" for="">更新日</label>
                    <div class="col-10">
                      <div class="form-group">
                        <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                          <input v-model="form.updated_at_from" class="form-control" id="start-date" type="text" data-toggle="datetimepicker" data-target="#start-date" maxlength="10" autocomplete="off"><i class="far fa-calendar-alt"></i>
                        </div><span class="unit"> ~</span>
                        <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                          <input v-model="form.updated_at_to" class="form-control" id="end-date" type="text" data-toggle="datetimepicker" data-target="#end-date" maxlength="10" autocomplete="off"><i class="far fa-calendar-alt"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <p class="search-no-data" v-if="!provisional_ep_challenges">検索条件に一致するものはありません。</p>

                  <div class="btn-container d-flex justify-content-end">
                    <button v-on:click="onSearch" class="button-square btn-send" type="button">検索する</button>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </form>
      </section>
      <!-- 検索-->

      <!-- リスト-->
      <section class="chapter white-block challenge">

        <div class="btn-container d-flex justify-content-between mb-3">
          <button @click="$router.push({ name: 'AdminProvisionalEpChallengeNew' })" class="btn btn-sm btn-green rounded-pill px-4" type="button">新規作成</button>
          <button @click="onCSVDownload()" class="button-square btn-sm btn-send" type="button">CSV ダウンロード<i class="fas fa-file-download pl-2"></i></button v-if="!!provisional_ep_challenges">
        </div>

        <div class="table-scroll-container horizontal-scroll" v-if="!!provisional_ep_challenges">
          <table class="table">
            <thead class="thead-light">
              <tr>
                <th>企業名</th>
                <th class="miw-15em">タイトル</th>
                <th class="miw-15em">アセット</th>
                <th class="miw-6em">ステータス</th>
                <th class="miw-6em">興味あり</th>
                <th>更新日</th>
                <th>承認日</th>
                <th>投稿完了日</th>
                <th>再公開日</th>
                <th class="horizontal-fixed"></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="provisional_ep_challenge in provisional_ep_challenges" :key="provisional_ep_challenge.id" :class="provisional_ep_challenge.is_undecided ? 'table-danger' : ''">
                <!-- 企業名 -->
                <td>
                  {{ provisional_ep_challenge.ep_company.company_name }}{{ provisional_ep_challenge.managed_user.department }}
                </td>

                <!-- タイトル -->
                <td>
                  <p v-if="provisional_ep_challenge.is_deleted"><span class="badge badge-sm badge-danger">削除</span></p>
                  <template v-if="!!provisional_ep_challenge.short_title">
                    <a
                      :id="'provisional_ep_challenge-detail_' + provisional_ep_challenge.id"
                      class="popover-trigger text-ellipsis"
                      tabindex="0"
                      data-content=""
                      data-original-title=""
                      title=""
                      @click.prevent.stop="onTitle(provisional_ep_challenge)"
                      >
                      {{ provisional_ep_challenge.short_title }}
                    </a>
                    <b-popover
                      :target="'provisional_ep_challenge-detail_' + provisional_ep_challenge.id"
                      triggers="hover"
                      placement="auto"
                    >
                      <div
                        style="margin: -1rem -1.5em; padding: 1rem 1.5em; position: relative; z-index: 1;"
                        @click="$root.$emit('bv::hide::popover')"
                        >
                        <span :inner-html.prop="provisional_ep_challenge.title | htmlEscape | nl2br" ></span>
                      </div>
                    </b-popover>
                  </template>
                  <template v-else>
                    <a
                      class="popover-trigger text-ellipsis"
                      href="#"
                      @click.prevent.stop="onTitle(provisional_ep_challenge)"
                      >
                      {{ provisional_ep_challenge.title }}
                    </a>
                  </template>
                </td>

                <!-- アセット -->
                <td class="asset-td">
                  <template v-for="(asset_master, index) in provisional_ep_challenge.asset_masters">
                    <!-- 短いアセット名を持っている -->
                    <template v-if="!!asset_master.short_name">
                      <!-- 未承認 -->
                      <template v-if="!!asset_master.is_undecided">
                        <a
                          :id="'asset-name-' + provisional_ep_challenge.id + '-' + asset_master.id"
                          class="popover-trigger"
                          href=""
                          tabindex="0"
                          data-toggle="popover"
                          data-content=""
                          @click.prevent.stop="onAsset(asset_master)"
                        >
                          {{ asset_master.short_name }}
                        </a>
                      </template>
                      <!-- 承認済み、却下 -->
                      <template v-else-if="!!asset_master.is_accepted">
                        <label class="popover-trigger text-ellipsis" :id="'asset-name-' + provisional_ep_challenge.id + '-' + asset_master.id" tabindex="0" data-content="">
                          {{ asset_master.short_name }}
                        </label>
                      </template>
                      <b-popover
                        :target="'asset-name-' + provisional_ep_challenge.id + '-' + asset_master.id"
                        triggers="hover"
                        placement="auto"
                      >
                        <span>{{ asset_master.name | htmlEscape | nl2br }}</span>
                      </b-popover>
                    </template>
                    <!-- 短いアセット名を持っていない -->
                    <template v-else>
                      <!-- 未承認 -->
                      <template v-if="!!asset_master.is_undecided">
                        <a
                          :id="'asset-name-' + provisional_ep_challenge.id + '-' + asset_master.id"
                          href="#"
                          @click.prevent.stop="onAsset(asset_master)"
                        >
                          {{ asset_master.name }}
                        </a>
                      </template>
                      <!-- 承認済み、却下 -->
                      <template v-else-if="!!asset_master.is_accepted">
                        <label>{{ asset_master.name }}</label>
                      </template>
                    </template>
                  </template>
                </td>

                <!-- 審査ステータス -->
                <td>{{ provisional_ep_challenge.exam_status_i18n }}</td>

                <!-- 興味あり -->
                <td>{{ Number(provisional_ep_challenge.interests_count) > 0 ? Number(provisional_ep_challenge.interests_count).toLocaleString() : '' }}</td>

                <!-- 更新日 -->
                <template v-if="!!provisional_ep_challenge.updated_at">
                  <td>{{ provisional_ep_challenge.updated_at | format.datetime_padding }}</td>
                </template>
                <template v-else>
                  <td></td>
                </template>

                <!-- 承認日 -->
                <template v-if="!!provisional_ep_challenge.examination_at && provisional_ep_challenge.is_accepted">
                  <td>{{ provisional_ep_challenge.examination_at | format.datetime_padding }}</td>
                </template>
                <template v-else>
                  <td></td>
                </template>

                <!-- 投稿完了日 -->
                <template v-if="!!provisional_ep_challenge.release_at">
                  <td>{{ provisional_ep_challenge.release_at | format.datetime_padding }}</td>
                </template>
                <template v-else>
                  <td></td>
                </template>

                <!-- 再公開日 -->
                <template v-if="!!provisional_ep_challenge.top_display_at">
                  <td>{{ provisional_ep_challenge.top_display_at | format.datetime_padding }}</td>
                </template>
                <template v-else>
                  <td></td>
                </template>

                <!-- ボタン -->
                <td class="permission-sub-title horizontal-fixed text-nowrap">
                  <button
                    class="btn btn-sm btn-icon mx-1"
                    :class="provisional_ep_challenge.is_deleted ? 'disabled' : ''"
                    type="button"
                    v-b-tooltip.hover="'編集'"
                    @click="onEdit(provisional_ep_challenge)">
                    <i class="fas fa-pen"></i>
                  </button>

                  <button
                    class="btn btn-sm btn-icon mx-1"
                    :class="provisional_ep_challenge.is_deleted ? 'disabled' : ''"
                    type="button"
                    v-b-tooltip.hover="'削除'"
                    @click="onDelete(provisional_ep_challenge)">
                    <i class="fas fa-trash"></i>
                  </button>

                  <button
                    class="btn btn-sm btn-icon mx-1"
                    :class="provisional_ep_challenge.is_open && !provisional_ep_challenge.is_deleted ? '' : 'disabled'"
                    type="button"
                    v-b-tooltip.hover="'目に触れる最新位置に表示'"
                    @click="onSort(provisional_ep_challenge)">
                    <i class="fas fa-sort-amount-up"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <!-- リスト-->

      <Pagination :total_page="total_page" :total_count="total_count" :current_page="current_page" :current_per="current_per" :page_link_num="page_link_num" :onChangePage="onChangePage" v-if="!!provisional_ep_challenges"/>

      <ConfirmModal
        ref="delete_confirm_modal"
        :onClickSend="onDeleteComfirmSend"
        :body_text="'チャレンジを削除します。<br>よろしいですか？'"
        :send_button_text="'削除する'"
        :isNoFade="isNoFade"
      />

      <ConfirmModal
        ref="sort_confirm_modal"
        :onClickSend="onSortComfirmSend"
        :body_text="'指定したチャレンジを最上位位置に表示します。<br>よろしいですか？'"
        :send_button_text="'実行する'"
        :isNoFade="isNoFade"
      />

    </main>
  </div>
</template>

<script>

  import form from '../../../common/mixins/form.js'
   // 初期表示時、検索モーダル開閉の判定
  import InitialSearchSectionOpening  from '../../../common/mixins/initial_serch_section_opening'
  // ページネーションのコンポーネント読み込み
  import Pagination from '../../../common/pages/Pagination.vue';
  // 確認モーダルのコンポーネント読み込み
  import ConfirmModal from '../../../common/pages/ConfirmModal.vue';

  export default {
    mixins: [form, InitialSearchSectionOpening],

    metaInfo: {
      title: 'チャレンジ一覧',
    },

    components: {
      Pagination,
      ConfirmModal
    },

    props: {
      isNoFade: Boolean
    },

    data() {
      return {
        // 現在ページ数
        current_page: Number(this.$route.query.page) || 1,
        // 現在の表示件数
        current_per: 0,
        // 総ページ数
        total_page: 0,
        // 総レコード数
        total_count: 0,
        // 表示するリンクの数
        page_link_num: 5,
        // 審査ステータス一覧
        exam_statuses: {},
        // 公開ステータス
        public_statuses: {},
        provisional_ep_challenges: [],
        // 削除対象ID
        delete_target_id: '',
        // ソート対象ID
        sort_target_id: '',
        provisional_ep_challenge_updated_at: '',
        ep_challenge_updated_at: '',
        form: {
          company_name:    this.$route.query.company_name    || '',
          department_name: this.$route.query.department_name || '',
          exam_statuses:   this.$route.query.exam_statuses   || ['draft', 'undecided'],
          public_statuses: this.$route.query.public_statuses || ['pending', 'open', 'close'],
          updated_at_from: this.$route.query.updated_at_from || '',
          updated_at_to:   this.$route.query.updated_at_to   || '',

          per:  this.$route.query.per  || '',
          // 検索処理で使用する現在のページ位置
          page: this.$route.query.page || '',
        }
      }
    },


    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      // 審査ステータス 1つだけの場合、textになるのを防ぐ処理
      this.form.exam_statuses = this.statusesCheck(this.form.exam_statuses);
      this.$route.query.exam_statuses = this.statusesCheck(this.$route.query.exam_statuses);
      // 公開ステータス 1つだけの場合、textになるのを防ぐ処理
      this.form.public_statuses = this.statusesCheck(this.form.public_statuses);
      this.$route.query.public_statuses = this.statusesCheck(this.$route.query.public_statuses);

      this.getProvisionalEpChallenges(this.form);
    },

    mounted() {
      const self = this;
      // プラン公開日_from のカレンダー設定
      this.setDatePicker(
        '#start-date',
        function(selected_date) {
          self.form.updated_at_from = selected_date;
        },
        'bottom'
      );
      // プラン公開日_to のカレンダー設定
      this.setDatePicker(
        '#end-date',
        function(selected_date) {
          self.form.updated_at_to = selected_date;
        },
        'bottom'
      );
    },

    // route.push時に実行
    beforeRouteUpdate (to, from, next) {
      this.getProvisionalEpChallenges(to.query);
      next();
    },

    // メソッド定義
    methods: {
      statusesCheck(statuses){
        // statusesが文字列の場合、配列に詰める
        // 配列の形でない場合、検索フォームで不具合が起きる為
        if(!Array.isArray(statuses) && !!statuses) {
          return [statuses]
        } else {
          return statuses
        }
      },

      getProvisionalEpChallenges(query) {
        this.$loading.load(
          this.$auth.api.get(
            'admin/provisional_ep_challenges', {
              params: query
            }
          ).then(res => {
            this.provisional_ep_challenges = res.data.provisional_ep_challenges
            this.current_per     = Number(res.data.per);
            this.current_page    = Number(res.data.page);
            this.total_page      = res.data.total_page;
            this.total_count     = res.data.total_count;
            this.exam_statuses   = res.data.exam_statuses;
            this.public_statuses = res.data.public_statuses;
          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        )
      },

      // 検索ボタン押下時
      onSearch(query = []) {
        this.form.page = this.current_page = 1;
        this.form.per  = this.current_per;
        this.form.exam_statuses   = this.form.exam_statuses.length   == 0 ? ['draft', 'undecided', 'modify', 'reregister', 'accepted'] : this.form.exam_statuses;
        this.form.public_statuses = this.form.public_statuses.length == 0 ? ['pending', 'open', 'close'] : this.form.public_statuses;

        // 画面更新
        this.reload(this.form);
      },

      // ページ切り替え時
      onChangePage(page, per) {
        let query = Object.assign({}, this.$route.query);
        query['page'] = this.current_page = page;
        query['per']  = this.current_per  = per;
        query['exam_statuses']   = this.$route.query.exam_statuses   || ['draft', 'undecided'];
        query['public_statuses'] = this.$route.query.public_statuses || ['pending', 'open', 'close'];

        // 画面更新
        this.reload(query);
      },

      // タイトル押下時
      onTitle(provisional_ep_challenge) {
        // 下書き
        if(provisional_ep_challenge.is_draft) {
          // 編集画面に遷移
          this.$router.push({ name: 'AdminProvisionalEpChallengeEdit', params: { id: provisional_ep_challenge.id } });
        }
        // それ以外
        else {
          // 詳細画面に遷移
          this.$router.push({ name: 'AdminProvisionalEpChallengeShow', params: { id: provisional_ep_challenge.id } });
        }
      },

      // アセット名押下時
      onAsset(asset_master) {
        // アセット詳細画面に遷移
        this.$router.push({ name: 'AdminAssetMasterEdit', params: { asset_master_id: asset_master.id } });
      },

      // 編集ボタン押下時
      onEdit(provisional_ep_challenge) {
        // 編集画面に遷移
        this.$router.push({ name: 'AdminProvisionalEpChallengeEdit', params: { id: provisional_ep_challenge.id } });
      },

      // 削除ボタン押下時
      onDelete(provisional_ep_challenge) {
        this.delete_target_id = provisional_ep_challenge.id;
        this.provisional_ep_challenge_updated_at = provisional_ep_challenge.updated_at;
        if(!!provisional_ep_challenge.ep_challenge) {
          this.ep_challenge_updated_at = provisional_ep_challenge.ep_challenge.updated_at;
        }
        this.$refs.delete_confirm_modal.open();
      },

      // 削除確認モーダル内の削除するボタン押下時
      onDeleteComfirmSend() {
        this.$loading.load(
          this.$auth.api.delete(
            'admin/provisional_ep_challenges/' + this.delete_target_id, {
              params: {
                provisional_ep_challenge_updated_at: this.provisional_ep_challenge_updated_at,
                ep_challenge_updated_at: this.ep_challenge_updated_at
              }
            }
          ).then(res => {
            this.$refs.delete_confirm_modal.close();
            this.delete_target_id = '';
            this.$bvToast.toast('削除しました。', {
              variant: 'success',
              title: '完了'
            });
            this.getProvisionalEpChallenges(this.$route.query);
          }).catch(err => {
            this.$refs.delete_confirm_modal.close();
            if(err.response.status == 500) {
              this.$errorHandlers.initial(err);
            } else {
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },

      // 最上位位置並び替えボタン押下時
      onSort(provisional_ep_challenge) {
        this.sort_target_id = provisional_ep_challenge.id;
        this.provisional_ep_challenge_updated_at = provisional_ep_challenge.updated_at;
        if(!!provisional_ep_challenge.ep_challenge) {
          this.ep_challenge_updated_at = provisional_ep_challenge.ep_challenge.updated_at;
        }
        this.$refs.sort_confirm_modal.open();
      },

      // 最上位位置並び替えモーダル内の実行するボタン押下時
      onSortComfirmSend() {
        this.$loading.load(
          this.$auth.api.post(
            'admin/provisional_ep_challenges/' + this.sort_target_id + '/sort', {
              provisional_ep_challenge_updated_at: this.provisional_ep_challenge_updated_at,
              ep_challenge_updated_at: this.ep_challenge_updated_at
            }
          ).then(res => {
            this.$refs.sort_confirm_modal.close();
            this.sort_target_id = '';
            this.$bvToast.toast('表示位置を変更しました。', {
              variant: 'success',
              title: '完了'
            });
            this.getProvisionalEpChallenges(this.$route.query);
          }).catch(err => {
            this.$refs.sort_confirm_modal.close();
            if(err.response.status == 500) {
              this.$errorHandlers.initial(err);
            } else {
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },

      // CSVダウンロードボタン押下時処理
      onCSVDownload() {
        let query = Object.assign({}, this.$route.query);
        query['exam_statuses']   = this.$route.query.exam_statuses   || ['draft', 'undecided'];
        query['public_statuses'] = this.$route.query.public_statuses || ['pending', 'open', 'close'];

        this.$loading.load(
          this.$auth.api.get(
            'admin/provisional_ep_challenges.csv', {
              params: query
            }
          ).then(res => {
            // BOM
            const bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
            // CSVに変換
            let blob     = new Blob([res.data], {type: 'text/csv'});
            let blob_bom = new Blob([bom, blob], {type: 'text/csv'});

            // CSVファイル名
            let current_date = new Date();
            let yyyy = current_date.getFullYear();
            let mm   = ('0' + current_date.getMonth()).slice(-2);
            let dd   = ('0' + current_date.getDate()).slice(-2);
            let hh   = ('0' + current_date.getHours()).slice(-2);
            let min  = ('0' + current_date.getMinutes()).slice(-2);
            let ss   = ('0' + current_date.getSeconds()).slice(-2);
            name = `チャレンジ一覧_${yyyy + mm + dd + hh + min + ss}.csv`;

            // IE
            if (window.navigator.msSaveBlob) {
              window.navigator.msSaveBlob(blob_bom, name);
            }
            // その他ブラウザ
            else {
              // BOMとCSVを結合してダウンロードURL作成
              const url = URL.createObjectURL(blob_bom);
              const link_tag = document.createElement('a');
              link_tag.href = url;
              link_tag.download = name;
              document.body.appendChild(link_tag);

              // ダウンロード
              link_tag.click();

              // ダウンロード用タグ削除
              URL.revokeObjectURL(url);
              link_tag.parentNode.removeChild(link_tag);
            }

          }).catch(err => {
            this.$refs.sort_confirm_modal.close();
            if(err.response.status == 500) {
              this.$errorHandlers.initial(err);
            } else {
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },

      // 更新処理
      reload(query = []) {
        this.$router.push({ name: 'AdminProvisionalEpChallengeIndex', query: query }).catch(err => {});
      },
    },

    // ウォッチャ定義
    watch: {
    },

  }
</script>
