<template>
	<main>
		<section class="main-title">
		<h1>フィードバック</h1>
		</section>

		<section class="chapter white-block">
		<!--.overview-text-->
		<div class="overview-text-area text-center">
			<h2>今回の{{target}}をフィードバックしてください。</h2>
			<p>いただいたフィードバックはお相手の企業側に共有されます。</p>
		</div>
		<!--.overview-text-->

		<form @submit.prevent="post">
			<!-- review-->
			<section class="review mt-4">
			<div class="star-group d-flex justify-content-center">
				<label>
				<input type="checkbox" :checked="satisficationLevel > 0" value="1" @change="checked($event)"><i class="fas fa-star"></i>
				</label>
				<label>
				<input type="checkbox" :checked="satisficationLevel > 1" value="2" @change="checked($event)"><i class="fas fa-star"></i>
				</label>
				<label>
				<input type="checkbox" :checked="satisficationLevel > 2" value="3" @change="checked($event)"><i class="fas fa-star"></i>
				</label>
				<label>
				<input type="checkbox" :checked="satisficationLevel > 3" value="4" @change="checked($event)"><i class="fas fa-star"></i>
				</label>
				<label>
				<input type="checkbox" :checked="satisficationLevel > 4" value="5" @change="checked($event)"><i class="fas fa-star"></i>
				</label>
			</div>
			</section>
			<!-- review-->

			<div class="textarea-container my-5">
				<b-form-textarea class="white-block form-control h-min235" placeholder="メッセージを書く..." v-model="comment" :state="!errors['comment']"></b-form-textarea>
				<p v-if="!!errors['comment']" class="invalid-feedback">{{ errors['comment'][0]}}</p>
				<small class="form-text text-right text-secondary">500文字以内</small>
			</div>

			<div class="btn-container d-flex justify-content-center">
				<button class="button-square btn-cancel" type="button" v-b-modal.cancel>もどる</button>
				<button class="button-square btn-send" type="submit">送信する</button>
			</div>
		</form>
		</section>

		<b-modal id="cancel" centered header-class="d-none" :no-fade="isNoFade">
			<p class="text-center">入力内容を破棄して詳細画面にもどります。<br>よろしいですか？</p>

			<template v-slot:modal-footer="{ ok, cancel, hide }">
				<button class="btn btn-cancel" type="button" @click="back">詳細にもどる</button>
				<button class="btn btn-send" type="button" @click="cancel">編集を続ける</button>
			</template>
		</b-modal>
	</main>
</template>

<script>
export default {
	metaInfo: {
		title: 'フィードバック'
	},
	data() {
		return {
			comment: "",
			satisficationLevel: 5,
			errors: [],
		}
	},
	props: ['feedbackType', 'accountType', 'isNoFade'],
	methods: {
		baseApiUrl(){
			return this.accountType + "/" + this.feedbackType + "/"
		},
		show(){
			if(this.$route.params.feedback_id){
				// フィードバックデータ取得
				this.$loading.load(this.$auth.api.get(this.baseApiUrl() + "feedbacks/" + this.$route.params.feedback_id))
			}else{
				this.$loading.load(this.$auth.api.get(this.baseApiUrl() +  this.$route.params.target_id + "/feedbacks/new")
					.then(res => {

					})
					.catch(err => {
						this.$errorHandlers.initial(err);
					}));
			}
		},
		post(){
			if(this.$route.params.feedback_id){
				// 更新モード
			}else{
				// 作成モード
				this.$loading.load(this.$auth.api.post(this.baseApiUrl() + this.$route.params.target_id + "/feedbacks",
				{
					comment: this.comment,
					satisfaction_level: this.satisficationLevel
				}).then(res => {
					// 遷移
					this.back();
				}).catch(e => {
					if(e.response.status == 422){
						// Validation Error
						this.errors = e.response.data.errors;
					}else{
						this.$errorHandlers.ajax(e);
					}
				}))
			}
		},
		checked($e){
			$e.target.checked = !$e.target.checked
			this.satisficationLevel = parseInt($e.target.value);
		},
		back(){
			var p = this.$route.path.split('/');
			this.$router.push('/' + p[1] + '/' + this.feedbackType + "/" + this.$route.params.target_id);
		}
	},
	computed: {
		target: function(){
			if(this.feedbackType === "plans"){
				return "プラン";
			}else if(this.feedbackType === "projects"){
				return "プロジェクト";
			}
			return ""
		}
	},
	created: function () {
	},
	mounted: function() {
		this.show();
	},
	watch: {
	}
}
</script>

<style scoped>
</style>
