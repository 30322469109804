const initialState = () => {
  return {
    su_old_achievement: {
      id: '',
      su_company_id: '',
      supply_date: '',
      amount: '',
      unit: '',
      invest_persons: [],
      investor_finance_vc: '',
      investor_cvc_company: '',
      investor_independent: '',
      investor_government: '',
      investor_international_vc: '',
      updated_at: '',
    },
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    reset(state){
      Object.assign(state, initialState())
    },
    setSuOldAchievement: function(state, data){
      state.su_old_achievement = data.su_old_achievement
    },
    initSuOldAchievement: function(state){
      state.su_old_achievement.id = ''
      state.su_old_achievement.su_company_id = ''
      state.su_old_achievement.supply_date = ''
      state.su_old_achievement.amount = ''
      state.su_old_achievement.unit = ''
      state.su_old_achievement.invest_persons = []
      state.su_old_achievement.investor_finance_vc = ''
      state.su_old_achievement.investor_cvc_company = ''
      state.su_old_achievement.investor_independent = ''
      state.su_old_achievement.investor_government = ''
      state.su_old_achievement.investor_international_vc = ''
      state.su_old_achievement.updated_at = ''
    },
  },
  getters: {
    SuOldAchievement: state => {
      return state.su_old_achievement
    },
  }
}
