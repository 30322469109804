<template>
  <main>
    <div class="text-center mt-7"><img class="error_image" src="~images/sorry.svg" alt="sorry">
        <h2>サーバーエラーが発生しました。</h2>
        <p class="mt-4">管理者にお問い合わせください。</p>

        <div class="btn-container d-flex justify-content-center mt-5">
          <button class="btn btn-big btn-white rounded-pill" type="button" onclick="history.back()">もどる</button>
        </div>
      </div>
    </main>
</template>

<script>
export default {
  data() {
	return {
	}
  },
  methods: {
  },
  computed: {
  },
  created: function () {
  },
  mounted: function() {
  },
  watch: {
  }
}
</script>

<style scoped>
</style>
