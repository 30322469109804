<template>
  <div>
    <main>
      <section class="main-title">
        <h1>企業情報</h1>
      </section>

      <!-- 基本情報-->
      <form>
        <section class="chapter white-block">
          <h2 class="chapter-label">基本情報</h2>
          <div class="border-top pt-4 px-3 mb-5">
            <div class="deta-header">
              <h3 class="company-name">{{ep_company.company_name}}</h3>
              <dl>
                <dt><i class="fas fa-desktop" title="URL"></i></dt>
                <dd><a class="btn-text" :href="ep_company.company_hp" target="_blank" rel="noopener">
                  <template v-if="deviceIsPc()">
                    {{ ep_company.company_hp | truncate(90) }}
                  </template>
                  <template v-else>
                    {{ ep_company.company_hp | truncate(50) }}
                  </template>
                </a></dd>
              </dl>
            </div>
            <div class="overview-list overview-list-dt20">
              <dl class="form-confirm-group">
                <dt>本社所在地</dt>
                <dd><span v-if="ep_company.prefecture_master_id!=''">{{headOfficeLocation}}</span></dd>
              </dl>
              <dl class="form-confirm-group">
                <dt>業界</dt>
                <dd>{{ industryName }}&nbsp;&nbsp;&nbsp;&nbsp;{{ subIndustryName }}</dd>
              </dl>
              <dl class="form-confirm-group">
                <dt>私たちについて</dt>
                <dd>{{ep_company.business_summary}}</dd>
              </dl>
              <dl class="form-confirm-group">
                <dt>有限責任監査法人トーマツ及びDeloitteの<br>メンバーファームからの監査</dt>
                <dd>{{auditMessage}}</dd>
              </dl>
            </div>
          </div>

          <!-- 管理者情報-->
          <h2 class="chapter-label">管理者情報</h2>
          <div class="overview-wrap border-top" v-if="!!ep_company.ep_admin_user_attributes">
            <div class="left-col">
              <img v-if="!!ep_company.ep_admin_user_attributes.image_attributes && ep_company.ep_admin_user_attributes.image_attributes.filename" class="thumbnail-frame img180 mx-auto mb-3"
                :src="ep_company.ep_admin_user_attributes.presigned_url"
                :alt="ep_company.ep_admin_user_attributes.image_attributes.filename"
              />
              <img v-else
                class="thumbnail-frame img180 mx-auto mb-3"
                src="~images/ep_user_default.png"
                alt="画像が選択されていません"
              />
              <p v-if="$auth.accountType != 'ep_admin'" class="login-time mt-3">{{ep_company.ep_admin_user_attributes.last_login}}</p>
            </div>
            <div class="right-col">
              <div class="overview-list my-4">
                <dl class="form-confirm-group">
                  <dt>氏名</dt>
                  <dd wovn-ignore>{{fullName(ep_company.ep_admin_user_attributes)}}</dd>
                </dl>
                <dl class="form-confirm-group">
                  <dt>氏名（フリガナ）</dt>
                  <dd wovn-ignore>{{fullKanaName(ep_company.ep_admin_user_attributes)}}</dd>
                </dl>
                <dl class="form-confirm-group">
                  <dt>メールアドレス<br>（ログインID）</dt>
                  <dd wovn-ignore>{{ep_company.ep_admin_user_attributes.email}}</dd>
                </dl>
                <dl class="form-confirm-group">
                  <dt>役職名</dt>
                  <dd>{{ep_company.ep_admin_user_attributes.position}}</dd>
                </dl>
                <dl class="form-confirm-group">
                  <dt>部署名</dt>
                  <dd>{{ep_company.ep_admin_user_attributes.department}}</dd>
                </dl>
                <dl class="form-confirm-group">
                  <dt>プロフィール</dt>
                  <dd>{{ep_company.ep_admin_user_attributes.profile}}</dd>
                </dl>
              </div>
            </div>
          </div>
          <!-- 管理者情報-->
          <div class="btn-container d-flex justify-content-center">
            <template v-if="current_api_v1_user.account_type == 'ep_admin'">
              <button class="btn btn-medium btn-outline-green rounded-pill" type="button" @click="$router.push({name: 'EpEpCompanyEditForm'})">編集する</button>
            </template>
          </div>
        </section>

        <!-- 2段階認証設定(親アカウントのみ設定可) -->
        <TwoFactorAuth
          editUrl="EpTwoFactorAuthEdit"
          :isSetting="current_api_v1_user.account_type == 'ep_admin'"
          :otpRequiredForLogin="otp_required_for_login"
        />
        <!-- 2段階認証設定 -->

        <ShowIndex
          baseApiUrl="ep"
          :ep_company_id="ep_company.id"
        />

      </form>
      <!-- 基本情報-->
    </main>
  </div>
</template>

<script>

  import ShowIndex from '../../../common/pages/ep_companies/ShowIndex.vue';
  import TwoFactorAuth from '../../../common/pages/two_factor_auth/Show.vue';
  // 端末判別コンポーネント
  import DeviceCheck from '../../../common/mixins/device_check.js';

  export default {

    metaInfo: {
      title: '企業情報',
    },

    components: {
      ShowIndex,
      TwoFactorAuth,
    },

    mixins: [DeviceCheck],

    data: function () {
      return {
        ep_company: {},
        is_audits: {},
        prefectures: {},
        industries: {},
        prop_admin_user_uploaded_image: '',
        prop_admin_user_img_name: '',
        current_api_v1_user: {
          id: 0,
          account_type: '',
        },
        otp_required_for_login: false,
      }
    },
    mounted(){
      this.getEpCompany();
      this.setEnums();
    },

    methods: {
      getEpCompany () {
        this.$loading.load(this.$auth.api.get(`ep/ep_companies/show`)
        .then(response => {
          this.ep_company = response.data.ep_company
          this.current_api_v1_user = response.data.current_api_v1_user
          this.otp_required_for_login = response.data.ep_company.ep_admin_user_attributes.otp_required_for_login
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
      },
      setEnums () {
        this.$loading.load(this.$auth.api.get('ep/ep_companies/show_init')
          .then(response => {
            this.is_audits = response.data.is_audits
            this.prefectures = response.data.prefectures
            this.industries = response.data.industries
          })
          .catch(err => {
            this.$errorHandlers.initial(err);
          }));
      },
      fullName: function (user) {
        return `${user.last_name} ${user.first_name}`
      },
      fullKanaName: function (user) {
        return user.kana_last_name == '' || user.kana_last_name == null ? '' : `${user.kana_last_name} ${user.kana_first_name}`
      },
    },
    computed: {
      industryName: function () {
        return this.industries[this.ep_company.industry_master_id];
      },
      subIndustryName: function () {
        return this.industries[this.ep_company.sub_industry_master_id];
      },
      headOfficeLocation: function () {
        return this.ep_company.prefecture_master_id == '' || this.ep_company.prefecture_master_id == null ? '' : `${this.prefectures[this.ep_company.prefecture_master_id]}${this.ep_company.address}`
      },
      auditMessage: function () {
        return this.is_audits[this.ep_company.is_audit]
      },
    },
  }
</script>
