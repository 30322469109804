<template>
  <main>
    <div class="one-col-box white-block">
      <div class="header">
        <h1 class="logo"><img v-bind:src="require('../../../../images/logo.svg')" alt="six brain"></h1>
        <h2 class="mb-4">2段階認証</h2>
      </div>
      <div class="body">
        <p class="lead">連携済のGoogle Authenticatorより認証コードが表示されます。認証コードを入力してください。</p>
        <form @submit.prevent="verify">
          <div class="form-group">
            <div class="input-group">
              <input class="form-control" type="text" name="auth" v-model="code" placeholder="認証コードを入力">
            </div>
          </div>
          <div class="btn-container py-3">
            <button class="button-square btn-send btn-block" type="submit">認証</button>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  metaInfo: {
    title: '二段階認証',
    bodyAttrs: {
      class: ['two-step-auth']
    }
  },
  data () {
    return {
    user: {},
      code: '',
    }
  },
  methods: {
    show() {
      this.$auth.api.get('auth/verify', {
        params: {
          encdata: this.$route.query.token
        }
      })
        .then(res => {
          if(res.data.code_available){
            this.user = res.data.user;
          }else{
            // URL期限切れ
            this.$root.$bvToast.toast("URL有効期限切れです", {
              title: '二段階認証',
              variant: 'danger'
            })
            this.$router.push('./login')
          }
        })
        .catch(err => {
          // URL不正など
          this.$errorHandlers.initial(err);
        })
    },
    verify () {
      this.$loading.load(this.$auth.verifyOtp(this.code, this.$route.query.token)
        .then(res => {
          if(res.data.status == "OK"){
            if(res.data.login_type == "ep"){
              this.$router.push('./recommends/');
            }else if(res.data.login_type == "su"){
              this.$router.push('./challenges/');
            }else{
              this.$router.push('./');
            }
          }
        }).catch(e => {
          if(e.response.status == 401){
            // Verifyコード期限切れ
            this.$bvToast.toast("認証コードが違います", {
              title: '二段階認証',
              variant: 'danger'
            })
          }else if(e.response.status == 500){
            this.$errorHandlers.initial(e);
          }else{
            this.$errorHandlers.ajax(e);
          }
        }))
    },
  },
  computed: {
  },
  created: function () {
    // ログインしてる場合はログイン画面へ
    if(this.$auth.isLoggedIn){
      var p = this.$route.path.split('/');
      this.$router.push('/' + p[1] + '/login');
    }else{
      this.show();
    }
  },
  mounted: function(){
  },
  watch: {
  }
}
</script>

<style scoped>
</style>