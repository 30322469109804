const initialState = () => {
  return {
    ep_company: {
      company_name: '',
      prefecture_master_id: '',
      company_hp: '',
      is_audit: '',
      address: '',
      industry_master_id: '',
      sub_industry_master_id: '',
      business_summary: '',
      updated_at: '',
      ep_admin_user_attributes: {
        first_name: '',
        last_name: '',
        kana_first_name: '',
        kana_last_name: '',
        email: '',
        position: '',
        department: '',
        profile: '',
        account_type: 'ep_admin',
        password: '',
        password_confirmation: '',
        uploaded_image: '',
        new_image_name: '',
        image_attributes: {
          filename: ''
        }
      },
    },
    password: '',
    password_confirmation: '',
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    reset(state){
      Object.assign(state, initialState())
    },
    setEpCompany: function(state, data){
      state.ep_company = data
    },
    initEpCompany: function(state){
      state.ep_company.company_name = ''
      state.ep_company.prefecture_master_id = ''
      state.ep_company.is_audit = '',
      state.ep_company.company_hp = ''
      state.ep_company.address = ''
      state.ep_company.industry_master_id = ''
      state.ep_company.sub_industry_master_id = '',
      state.ep_company.business_summary = ''

      state.ep_company.ep_admin_user_attributes.first_name = ''
      state.ep_company.ep_admin_user_attributes.last_name = ''
      state.ep_company.ep_admin_user_attributes.kana_first_name = ''
      state.ep_company.ep_admin_user_attributes.kana_last_name = ''
      state.ep_company.ep_admin_user_attributes.email = ''
      state.ep_company.ep_admin_user_attributes.position = ''
      state.ep_company.ep_admin_user_attributes.department = ''
      state.ep_company.ep_admin_user_attributes.profile = ''
      state.ep_company.ep_admin_user_attributes.account_type = 'ep_admin'
      state.ep_company.ep_admin_user_attributes.password = ''
      state.ep_company.ep_admin_user_attributes.uploaded_image = ''
      state.ep_company.ep_admin_user_attributes.new_image_name = ''
      state.ep_company.ep_admin_user_attributes.image_attributes = { filename: '' }
    },
    initImgData: function(state){
      state.admin_user_img_name = '';
      state.admin_user_uploaded_image = '';
    }
  },
  getters: {
    epCompany: state => {
      return state.ep_company
    },
    uploadedAdminUserImage: state => {
      return state.admin_user_uploaded_image
    },
    imgAdminUserName: state => {
      return state.admin_user_img_name
    },
    password: state => {
      return state.password
    },
    passwordConfirm: state => {
      return state.password_confirmation
    }
  }
}
