<template>
  <div>
    <main>
      <section class="main-title">
        <h1>大手企業一覧</h1>
      </section>

      <!-- 検索-->
      <section class="chapter white-block">
        <form @submit.prevent>
          <div class="accordion" id="serach">
            <div class="form-group card">
              <div class="card-header" id="searach-parent"><a data-toggle="collapse" href="#" @click.prevent.stop="" v-b-toggle.searach-toggle-child>検索<i class="fas"></i></a></div>
              <b-collapse id="searach-toggle-child" visible accordion="my-accordion" role="tabpanel">
                <div class="card-body collapse show" id="searach-child">

                  <div class="form-group row">
                    <label class="col-2 col-form-label" for="input1">企業名</label>
                    <div class="col-10">
                      <input v-model="form.company_name" class="form-control" type="text" id="input1" placeholder="企業名">
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-2 col-form-label" for="input2">部署名</label>
                    <div class="col-10 incremental-wrap">
                      <input v-model="form.department_name"  class="form-control" type="text" id="input2" placeholder="部署名">
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-2 col-form-label" for="input2">担当者名</label>
                    <div class="col-10 incremental-wrap">
                      <input v-model="form.ep_person_in_charge_name"  class="form-control" type="text" id="input2" placeholder="担当者名">
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="row">
                      <legend
                          id="model-type-info"
                          class="col-2 col-form-label">
                        ステータス
                      </legend>
                      <div class="col-10 align-self-center">
                        <div v-for="(exam_statuses_i18n, exam_status_key) in exam_statuses" :key="exam_status_key" class="custom-control custom-checkbox custom-control-inline">
                          <input
                              v-model="form.exam_statuses"
                              type="checkbox"
                              class="custom-control-input"
                              :id="'status_' + exam_status_key"
                              :value="exam_status_key"
                          >
                          <label :for="'status_' + exam_status_key" class="custom-control-label" >{{ exam_statuses_i18n }}</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-2 col-form-label" for="">申し込み日</label>
                    <div class="col-10">
                      <div class="form-group">
                        <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                          <input v-model="form.apply_at_from" class="form-control" id="start-date" type="text" data-toggle="datetimepicker" data-target="#start-date"><i class="far fa-calendar-alt"></i>
                        </div><span class="unit"> ~</span>
                        <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                          <div class="input-group icon-addon-right">
                            <input v-model="form.apply_at_to" class="form-control" id="end-date" type="text" data-toggle="datetimepicker" data-target="#end-date"><i class="far fa-calendar-alt"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <p v-if="!registration_requests.length && is_fetch" class="search-no-data">検索条件に一致するものはありません。</p>
                  <div class="btn-container d-flex justify-content-end">
                    <button @click="onSearch" class="button-square btn-send" type="button">検索する</button>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </form>
      </section>
      <!-- 検索-->

      <!-- リスト -->
      <section class="chapter white-block" >
        <div class="btn-container mb-3" >
          <button @click="$router.push({ name: 'AdminEpCompanyForm' })" class="btn btn-green rounded-pill" type="button" v-if="accountType=='dtvs_admin'">新規作成</button>
        </div>
        <div class="table-responsive" v-if="!!registration_requests.length">
          <table class="table table-striped table-hover">
            <thead class="thead-light">
              <tr>
                <th class="w-15">企業名</th>
                <th class="w-15">部署名</th>
                <th class="w-15">担当者名</th>
                <th class="w-15">ステータス</th>
                <th class="w-15">申し込み日</th>
                <th class="w-15">チャレンジ投稿数</th>
                <th class="w-5"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="registration_request in registration_requests" :key="registration_request.id">
                <td>
                  <router-link :to="{ name: 'AdminEpCompanyShow', params: { id: registration_request.id }}">
                    {{ companyName(registration_request) }}
                  </router-link>
                </td>
                <td>
                  <router-link :to="{ name: 'AdminEpCompanyShow', params: { id: registration_request.id }}">
                    {{ departmentName(registration_request) }}
                  </router-link>
                </td>
                <td>{{ fullName(registration_request) }}</td>
                <td>{{ registration_request.exam_status_i18n }}</td>
                <td>{{ applyDate(registration_request) }}</td>
                <td v-if="registration_request.ep_company !== undefined && registration_request.exam_status == 'accepted'" class="align-self-center">
                  {{ registration_request.ep_company.challenges_counts }}
                </td>
                <td v-else></td>
                <td class="text-right text-nowrap">
                  <button @click="$refs.delete_confirm_modal.open(); setRegistrationRequest(registration_request)" class="btn-action btn btn-sm btn-icon mx-1" type="button" data-toggle="modal" data-target="#delete" v-if="accountType=='dtvs_admin'">
                    <i class="fas fa-trash" v-b-tooltip.hover="'削除'"></i>
                  </button>
                  <button @click="$router.push({name: 'AdminEpCompanyEditForm', params: {id: registration_request.id}})" class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'編集'" v-if="accountType=='dtvs_admin'"><i class="fas fa-pen"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination :total_page="total_page" :total_count="total_count" :current_page="current_page" :current_per="current_per" :page_link_num="page_link_num" :onChangePage="onChangePage" v-if="!!registration_requests.length" />
        <ConfirmModal
          ref="delete_confirm_modal"
          :onClickSend="onDeleteConfirmSend"
          :body_text="'この企業を削除します。<br>よろしいですか？'"
          :send_button_text="'削除する'"
          :isNoFade="isNoFade"
        />
      </section>
      <!-- リスト-->
    </main>
  </div>
</template>

<script>
  // ページネーションのコンポーネント読み込み
  import Pagination from '../../../common/pages/Pagination.vue';
  import form from "../../../common/mixins/form.js"
  // 確認モーダルのコンポーネント読み込み
  import ConfirmModal from '../../../common/pages/ConfirmModal.vue';

  export default {
    mixins: [form],

    components: {
      Pagination,
      ConfirmModal
    },

    metaInfo: {
      title: '大手企業 一覧',
    },

    props: { isNoFade: Boolean, accountType: String },

    data() {
      return {
        // 現在ページ数
        current_page: Number(this.$route.query.page) || 1,
        // 現在の表示件数
        current_per:  0,
        // 総ページ数
        total_page: 0,
        // 総レコード数
        total_count: 0,
        // 表示するリンクの数
        page_link_num: 5,
        is_fetch: false,
        registration_requests: [],
        ep_person_in_charges: {},
        exam_statuses: {},
        pick_registration_requests: [],
        registration_request_id: "",
        form: {
          company_id: this.$route.query.id || '',
          company_name: this.$route.query.company_name || '',
          department_name: this.$route.query.department_name || '',
          ep_person_in_charge_name: this.$route.query.ep_person_in_charge_name || '',
          exam_statuses: this.$route.query.exam_statuses || ['undecided'],
          apply_at_from: this.$route.query.apply_at_from || '',
          apply_at_to: this.$route.query.apply_at_to || '',
          // 検索処理で使用する現在のページ位置
          page: this.$route.query.page || ''
        },
      }
    },

    mounted() {
      const self = this;
      // 申し込み日_from のカレンダー設定
      this.setDatePicker(
        '#start-date',
        function(selected_date) {
          self.form.apply_at_from = selected_date;
        },
        'top'
      );
      // 申し込み日_to のカレンダー設定
      this.setDatePicker(
        '#end-date',
        function(selected_date) {
          self.form.apply_at_to = selected_date;
        },
        'top'
      );
    },

    methods: {
      init () {
        this.$loading.load(this.$auth.api.get('admin/ep_companies/index_init.json')
          .then(response => {
            this.exam_statuses = response.data.exam_statuses
            this.ep_person_in_charges = response.data.ep_person_in_charges
          })
          .catch(err => {
            this.$errorHandlers.initial(err);
          }));
      },

      fetchEpCompanies: function(query) {
        this.$loading.load(this.$auth.api.get('admin/ep_companies.json',{
            params: query
          })
        .then(response => {
          this.registration_requests = response.data.registration_requests
          this.current_per  = Number(response.data.per);
          this.current_page = Number(response.data.page);
          this.total_page   = response.data.total_page;
          this.total_count  = response.data.total_count;
          this.is_fetch = true
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
      },

      // ページ切り替え時
      onChangePage(page, per) {
        let query = Object.assign({}, this.$route.query);
        query['page'] = this.current_page = page;
        query['per'] = this.current_per = per;
        query['exam_statuses'] = this.form.exam_statuses;
        // 画面更新
        this.reload(query);
      },

      // 更新処理
      reload(query = []) {
        this.$router.push({ name: 'AdminEpCompanyIndex', query: query }).catch(err => {});
      },

      // 企業名の一覧から選択時
      onClickEpCompany(registration_request) {
        this.pick_registration_requests = []
        this.form.id = registration_request.id
        this.form.company_name = registration_request.company_name
      },

      // インクリメンタルサーチの候補をリセット
      resetPickList () {
        setTimeout(() => {
          this.pick_assets = [];
        }, 200);
      },

      // 検索ボタン押下時
      onSearch(query = []) {
        this.form.page = this.current_page = 1;
        this.form.per  = this.current_per;
        // 画面更新
        this.reload(this.form);
      },

      // 会社名
      companyName(registration_request) {
        return registration_request.ep_company == undefined ? registration_request.company_name : registration_request.ep_company.company_name
      },

      // 部署名
      departmentName(registration_request) {
        return registration_request.ep_company == undefined ? registration_request.department : registration_request.ep_company.ep_admin_user.department
      },

      // 担当者名
      fullName(registration_request) {
        return registration_request.ep_company == undefined ? `${registration_request.last_name} ${registration_request.first_name}` :`${registration_request.ep_company.ep_admin_user.last_name} ${registration_request.ep_company.ep_admin_user.first_name}`
      },

      // 申し込み日
      applyDate(registration_request) {
        return registration_request.ep_company == undefined ? registration_request.created_at : registration_request.ep_company.apply_date
      },

      //  選択した登録申請IDを取得
      setRegistrationRequest(registration_request) {
        this.registration_request_id = registration_request.id,
        this.delete_target_updated_at = this.updatedAt(registration_request)
      },

      // 更新日時を取得
      updatedAt(registration_request) {
        return !!registration_request.ep_company ? registration_request.ep_company.updated_at : registration_request.updated_at
      },

      examStatusesCheck(exam_statuses){
        // exam_statusesが文字列の場合、配列に詰める
        if(!Array.isArray(exam_statuses) && !!exam_statuses) {
          return [exam_statuses]
        } else {
          return exam_statuses
        }
      },

      // 削除確認モーダル内削除するボタン押下時
      onDeleteConfirmSend() {
        this.onEpPersonInChargeDelete();
      },
      // 削除処理
      onEpPersonInChargeDelete() {
        this.$loading.load(
          this.$auth.api.delete(
            `admin/ep_companies/${this.registration_request_id}`, {params: { updated_at: this.delete_target_updated_at }}
          ).then(res => {
            this.$refs.delete_confirm_modal.close();
            this.fetchEpCompanies(this.$route.query);
          }).catch(err => {
            this.$refs.delete_confirm_modal.close();
            if(err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }else{
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },

    },

    created: function(){
      this.form.exam_statuses = this.examStatusesCheck(this.form.exam_statuses);
      this.$route.query.exam_statuses = this.examStatusesCheck(this.$route.query.exam_statuses);
      this.fetchEpCompanies(this.$route.query);;
      // 初期表示からのページ遷移時,undecidedを保持させるためformを$route.queryに入れる
      this.$route.query.exam_statuses = this.form.exam_statuses;
      this.init();
    },

    // route.push時に実行
    beforeRouteUpdate (to, from, next) {
      this.fetchEpCompanies(to.query);
      next();
    }
  }
</script>
