<template>
  <main>
    <Header :accountType="accountType" />
    <MiniHeader :accountType="accountType" />
    <div class="request-flow-image">
      <ul>
        <li class="flow-title"><img src="~/images/request_flow_title.svg" alt="Step"></li>
        <li class="flow-01"><img src="~/images/request_flow1_on.svg" alt="Step1 メールアドレス入力"></li>
        <li class="flow-02"><img src="~/images/request_flow2_on.svg" alt="Step2 メール送信"></li>
        <li class="flow-03"><img src="~/images/request_flow3.svg" alt="Step3 申請情報入力"></li>
        <li class="flow-04"><img src="~/images/request_flow4.svg" alt="Step4 申請情報確認"></li>
        <li class="flow-05"><img src="~/images/request_flow5.svg" alt="Complete 申請完了"></li>
      </ul>
    </div>
    <div class="overview-text-area text-center mt-5">
      <h2>メールを送信しました。</h2>
      <p class="mt-4">ご入力いただいた {{registration_auth.email}} 宛てに登録申請用のご案内をお送りいたしました。<br>メールの内容を確認して登録申請を完了してください。</p>
    </div>
  </main>
</template>

<script>
  import Header from "./Header";
  import MiniHeader from "./MiniHeader";

  export default {
    name: "Show",
    components: {
      Header,
      MiniHeader,
    },
    props: {
      accountType: String,
    },
    data() {
      return {
        login_path: '/' + this.accountType + '/login',
        id: this.$route.params.id,
        registration_auth: {},
      }
    },
    methods: {
      init() {
        this.fetchRegistrationAuth();
      },
      fetchRegistrationAuth() {
        this.$loading.load(
          this.$auth.api.get('registration_auths/' + this.id ).then(res => {
            this.registration_auth = res.data.registration_auth;
          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        );
      },
    },
    created() {
      this.init();
    }
  }
</script>

<style scoped>

</style>