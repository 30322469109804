<template>
  <div>
    <section class="chapter white-block step-data">
      <div class="step-graph border-bottom">
        <BarChart
          :options="presentValuesBarOptions(scenario)"
          :chartData="presentValuesBarChartData(scenario)"
        />
      </div>
      <div class="step-table">
        <div class="btn-container">
          <div />
          <EditButtons
            :inEdit="inEdit"
            @editClick="$emit('update:inEdit', true)"
            @onSave="$emit('updateJsonScenario')"
          />
        </div>
        <div
          v-if="errors.length"
          class="mx-4 mb-4"
        >
          <p
            v-for="(error, i) in errors"
            :key="`scenario_errors_${i}`"
            class="invalid-feedback d-block"
          >
            {{ error }}
          </p>
        </div>
        <div class="mr-n3 -my-2 overflow-x-auto">
          <div class="pr-3 inline-block min-w-full py-2 align-middle">
            <table class="min-w-full divide-y divide-gray-300">
              <YearsThead
                :columns="yearColumns(scenario.years_custom)"
                :displayCurrencyUnit="displayCurrencyUnit(scenario)"
              />
              <tbody class="divide-y divide-gray-200 bg-white">
                <TbodyRow
                  :columns="yearColumns(scenario.years_custom)"
                  :fieldValues="calcFreeCashFlows(scenario)"
                  fieldName="Free Cash Flow"
                />
                <TbodyRow
                  :inEdit="inEdit"
                  :columns="yearColumns(scenario.years_custom, 'last')"
                  :fieldValues="scenario.net_income_after_tax"
                  fieldName="税引後当期純利益"
                  tooltipContent="最終年度における予想税引後当期純利益を入力してください。最終年度が5年後である場合は、6年目の予想税引後当期純利益を入力します。"
                  @fieldValuesReplace="setField(scenario, 'net_income_after_tax', $event)"
                />
                <TbodyRow
                  :columns="yearColumns(scenario.years_custom, 'last')"
                  :fieldValues="{ last: calcPersMedian(scenario) }"
                  fieldName="PER"
                  tooltipContent="クリックすると詳細な値を入力することができます。"
                  :onLink="goPerComparison"
                />
                <TbodyRow
                  :columns="yearColumns(scenario.years_custom, 'last')"
                  :fieldValues="{ last: calcExitValue(scenario) }"
                  tooltipContent="Exit時点における時価総額を「Exitマルチプル」(事業計画の最終年度をExitタイミングと仮定した場合における、当該時点の対象企業の永続価値を類似企業のマルチプルを用いて算出する計算方法。)で計算します。"
                  fieldName="Exit Value"
                />
                <TbodyRow
                  :columns="yearColumns(scenario.years_custom)"
                  :fieldValues="calcSubtotals(scenario)"
                  fieldName="小計"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <div class="btn-container d-flex justify-content-center">
      <router-link
        :to="{ name: 'EpScenariosShowStep2' }"
        class="btn btn-big rounded-pill btn-prev"
      >
        前のステップに戻る
      </router-link>
      <router-link
        :to="{ name: 'EpScenariosShowStep4' }"
        class="btn btn-big rounded-pill btn-next"
      >
        次のステップに進む
      </router-link>
    </div>
  </div>
</template>

<script>
  import YearsThead from './parts/YearsThead';
  import TbodyRow from './parts/TbodyRow';
  import EditButtons from './parts/EditButtons';
  import BarChart from './parts/BarChart';
  import ScenariosShowMixins from './scenarios_show_mixins';

  export default {
    mixins: [ScenariosShowMixins, ],
    components: {
      YearsThead,
      TbodyRow,
      EditButtons,
      BarChart,
    },
    props: {
      scenario: {
        type: Object,
        required: true,
      },
      errors: {
        type: Array,
        required: true,
      },
      inEdit: {
        type: Boolean,
        required: true,
      },
    },
    methods: {
      goPerComparison() {
        this.$router.push({ name: 'EpScenariosShowPerComparison', });
      },
    },
  };
</script>
<style src="./tailwind.css" scoped />
