import moment from 'moment'

export default {
  date:{
    default: function(value){
      if (!value) return ''
      return moment(value).format('YYYY/M/D')
    },
    ja: function(value) {
      if (!value) return ''
      return moment(value).format('YYYY年M月D日')
    }
  },
  datetime: {
    default: function(value){
      if (!value) return ''
      return moment(value).format('YYYY/M/D HH:mm')
    }
  },
  datetime_padding: {
    default: function(value){
      if (!value) return ''
      return moment(value).format('YYYY/MM/DD HH:mm')
    }
  },
  time: {
    default: function(value){
      if (!value) return ''
      return moment(value).format('HH:mm')
    }
  },
  trimFractionDigits: {
    default: function (value) {
      if (!value) return ''
      var s = String(value).split('.');
      var ret = String(s[0]).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      if (s.length > 1 && s[1] !== "0") {
          ret += '.' + s[1];
      }
      return ret;
    }
  },
  number: function(value){
    return Number(value).toLocaleString();
  }
}