<template>
  <div>
    <router-view
      :ep_company.sync="ep_company"
      :collaboration_request_masters="collaboration_request_masters"
      :industries.sync="industries"
      :accept_extensions="accept_extensions"
      :prefectures.sync="prefectures"
      :is_audits.sync="is_audits"
      :membership_types.sync="membership_types"
      :prop_admin_user_uploaded_image.sync="prop_admin_user_uploaded_image"
      :prop_admin_user_img_name.sync="prop_admin_user_img_name"
      :prop_department_admin_user_uploaded_image.sync="prop_department_admin_user_uploaded_image"
      :prop_department_admin_user_img_name.sync="prop_department_admin_user_img_name">
    </router-view>
  </div>
</template>

<script>
  export default {
    data: function () {
      return {
        ep_company: {},
        is_audits: {},
        membership_types: {},
        collaboration_request_masters: {},
        prefectures: {},
        industries: {},
        accept_extensions: [],
        prop_admin_user_uploaded_image: '',
        prop_admin_user_img_name: '',
        prop_department_admin_user_uploaded_image: '',
        prop_department_admin_user_img_name: ''
      }
    },
    methods: {
      getEpCompany () {
        this.ep_company = this.$store.getters['ep_company/epCompany']
      },
      setEnums () {
        this.$loading.load(this.$auth.api.get('admin/ep_companies/new_init.json', {params: {path: this.$route.name}})
          .then(response => {
            this.is_audits = response.data.is_audits
            this.membership_types = response.data.membership_types
            this.collaboration_request_masters = response.data.collaboration_request_masters
            this.prefectures = response.data.prefectures
            this.industries = response.data.industries
            this.accept_extensions = response.data.accept_extensions.join();
          })
          .catch(err => {
            this.$errorHandlers.initial(err);
          }));
      }
    },
    created: function(){
      this.getEpCompany()
      this.setEnums()
    },
    beforeMount() {
      if(this.$route.name !== "AdminEpCompanyConfirm"){
        this.$store.commit('ep_company/initEpCompany');
        this.ep_company = this.$store.getters['ep_company/epCompany']
      }
    }
  }
</script>