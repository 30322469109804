<template>
  <div>
    <b-modal
      v-model="is_show"
      id="recommend_new"
      title=""
      centered
      size="xl"
      aria-labelledby="recommend_new_Label"
      hide-header
      scrollable
      :no-fade="isNoFade"
    >
      <!-- 追加する対象のチャレンジ名称を表示 -->
      <div class="have-selected-deta mb-3">
        <div class="selected-company">{{ ep_challenge_company_name }}</div>
        <div class="selected-title">{{ ep_challenge_title }}</div>
      </div>
      <!-- /追加する対象のチャレンジ名称を表示 -->

      <form>
        <!-- 検索エリア -->
        <div class="searach-data-wrap accordion">
          <div class="searach-data-header">
            <p>リコメンドする最新情報を検索</p>
            <a class="accordion-trigger" data-toggle="collapse" href="#serach-modal-area" @click.prevent.stop="" v-b-toggle.serach-modal-area><i class="fas"></i></a>
          </div>
          <b-collapse id="serach-modal-area" visible>
            <div class="collapse p-2 show" id="serach-modal-area">
              <div class="form-group row">
                <label class="col-2 col-form-label" for="ep">企業名</label>
                <div class="col-10 incremental-wrap">
                  <input class="form-control" type="text" v-model="modal.company_name">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-2 col-form-label" for="name">担当者名</label>
                <div class="col-10 incremental-wrap">
                  <input class="form-control" type="text" v-model="modal.person_name">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-2 col-form-label">業界</label>
                <div class="col-4">
                  <select class="custom-select" v-model="modal.industry">
                    <option value="">選択してください</option>
                    <option v-for="(industry_i18n, industry) in industries" :key="industry" :value="industry">
                      {{ industry_i18n }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-2 col-form-label" for="free_word">フリーワード</label>
                <div class="col-10 incremental-wrap">
                  <input class="form-control" type="text" v-model="modal.free_word">
                </div>
              </div>
              <div class="btn-container d-flex justify-content-end mt-3">
                <button class="button-square btn-send btn-sm" type="button" @click="onClickSearch()">検索する</button>
              </div>
            </div>
          </b-collapse>
        </div>
        <!-- /検索エリア -->

        <!-- リスト -->
        <template v-if="Object.keys(provisional_su_latest_infos).length > 0">
          <table class="table">
            <thead class="thead-light">
              <tr>
                <th class="w-15">企業名</th>
                <th class="w-25">タイトル</th>
                <th class="w-10">アセット</th>
                <th class="w-10">ステータス</th>
                <th class="w-10">作成日</th>
                <th class="w-10">リコメンド</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(provisional_su_latest_info, index) in provisional_su_latest_infos" :key="provisional_su_latest_info.id">
                <td>
                  <a href="#"
                      :target="'modal-su-company-name-' + provisional_su_latest_info.id"
                      @click.prevent.stop="onSuCompanyName(provisional_su_latest_info.su_company_id)">
                    {{provisional_su_latest_info.su_company.company_name | omittedText(25)}}
                  </a>
                  <b-popover
                    :target="'modal-su-company-name-' + provisional_su_latest_info.id"
                    triggers="hover"
                    placement="auto"
                  >
                    <span>{{ provisional_su_latest_info.su_company.company_name | htmlEscape | nl2br }}</span>
                  </b-popover>
                </td>
                <td>
                  <a href="#"
                      :target="'modal-su-latest-info-' + provisional_su_latest_info.id"
                      @click.prevent.stop="onClickSuLatestInfoTitle(provisional_su_latest_info.id)">
                    {{provisional_su_latest_info.title | omittedText(30)}}
                  </a>
                  <b-popover
                    :target="'modal-su-latest-info-' + provisional_su_latest_info.id"
                    triggers="hover"
                    placement="auto"
                  >
                    <span>{{ provisional_su_latest_info.title | htmlEscape | nl2br }}</span>
                  </b-popover>
                </td>
                <td>
                  <span :id="'modal-asset-name-' + provisional_su_latest_info.id" tabindex="0" data-content="">
                    {{ provisional_su_latest_info.asset_name | omittedText(15) }}
                  </span>
                  <b-popover
                    :target="'modal-asset-name-' + provisional_su_latest_info.id"
                    triggers="hover"
                    placement="auto"
                  >
                    <span>{{ provisional_su_latest_info.asset_name | htmlEscape | nl2br }}</span>
                  </b-popover>
                </td>
                <td>
                  <template v-if="!!provisional_su_latest_info.su_latest_info_id">
                    承認
                  </template>
                  <template v-else>
                    {{provisional_su_latest_info.exam_status_i18n}}
                  </template>
                </td>
                <td>
                  {{provisional_su_latest_info.created_at | format.date}}<br>
                  {{provisional_su_latest_info.created_at | format.time}}
                </td>
                <td>
                  <template v-if="!!provisional_su_latest_info.su_latest_info_id">
                    <template v-if="!!provisional_su_latest_info.ep_recommend_admin_recommend_status">
                      {{provisional_su_latest_info.ep_recommend_admin_recommend_status}}
                    </template>
                    <button class="button-square btn-sm btn-send icon-addon-left"
                            type="button"
                            :class="{disabled: check_recommend_su_latest_info_ids.includes(provisional_su_latest_info.su_latest_info_id)}"
                            @click="addRecommend(index)" v-else>
                      <i class="fas fa-plus"></i>追加
                    </button>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- /リスト -->

          <!-- ページネーション -->
          <Pagination :total_page="total_page"
                      :total_count="total_count"
                      :current_page="current_page"
                      :current_per="current_per"
                      :page_link_num="page_link_num"
                      :onChangePage="onChangePage"
                      v-if="!!provisional_su_latest_infos"/>
          <!-- /ページネーション -->
        </template>
        <template v-else>
          <p class="search-no-data">検索条件に一致するものはありません。</p>
        </template>
      </form>

      <!-- フッター -->
      <template v-slot:modal-footer>
        <!-- 選択された最新情報を表示 -->
        <div class="have-selected-block" v-if="add_recommends.length > 0">
          <h3 class="chapter-label mb-4">最新情報をチャレンジに追加する</h3>
          <div class="recommend-labels-container">
            <div class="recommend-labels assets-labels" v-for="(add_recommend, index) in add_recommends" :key="add_recommend.su_latest_info_id">
              <p class="text-wrap">
                <span class="recommend-campany">{{add_recommend.su_company_name | omittedText(40)}}</span><br>
                {{add_recommend.su_latest_info_title | omittedText(45)}}
              </p>
              <button class="btn-action ml-1" type="button" data-toggle="tooltip" title="" data-original-title="削除" @click="deleteRecommend(index)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- /選択された最新情報を表示 -->

        <!-- ボタン -->
        <div class="button-wrap">
          <button class="btn btn-cancel" type="button" @click="onCancel">閉じる</button>
          <button class="btn btn-send" :class="{disabled: add_recommends.length == 0}" type="button" @click="onSend">
            リコメンド対象追加
          </button>
        </div>
        <!-- /ボタン -->
      </template>
      <!-- /フッター -->
    </b-modal>
  </div>
</template>

<script>
  // ページネーションのコンポーネント読み込み
  import Pagination from '../../../common/pages/Pagination.vue';

  export default {
    components: {
      Pagination,
    },

    props: {
      // モーダルが開いた時のコールバックメソッド
      onModalOpen: {
        type: Function,
      },
      // モーダルが閉じた時のコールバックメソッド
      onModalClose: {
        type: Function,
      },
      // リコメンド対象追加ボタン押下時のコールバックメソッド
      onClickSuccess: {
        type: Function,
      },
      // 最新情報タイトル押下時のコールバックメソッド
      onClickSuLatestInfoTitle: {
        type: Function,
      },
      // 業界
      industries: {
        type: Object | Array,
      },
      isNoFade: {
        type: Boolean,
      },
    },

    data() {
      return {
        // 現在ページ数
        current_page: Number(this.$route.query.page) || 1,
        // 現在の表示件数
        current_per: 0,
        // 総ページ数
        total_page: 0,
        // 総レコード数
        total_count: 0,
        // 表示するリンクの数
        page_link_num: 5,
        is_show: false,
        ep_challenge_id: null,
        ep_challenge_company_name: '',
        ep_challenge_title: '',
        provisional_su_latest_infos: [],
        add_recommends: [],
        check_recommend_su_latest_info_ids: [],
        modal: {
          company_name: '',
          person_name: '',
          industry: '',
          free_word: '',

          per:  this.$route.query.per  || '',
          // 検索処理で使用する現在のページ位置
          page: this.$route.query.page || '',
        }
      }
    },

    watch: {
      is_show(new_is_show, old_is_show) {
        if(new_is_show) {
          if(typeof this.onModalOpen == 'function') {
            this.onModalOpen();
          }
        } else {
          if(typeof this.onModalClose == 'function') {
            this.onModalClose();
          }
        }
      }
    },

    filters: {

      // 文字列を省略して表示
      omittedText(text, len) {
        return text.length > len ? text.slice(0, len) + "…" : text;
      },

    },

    methods: {
      // モーダルオープン
      open(ep_challenge_id, ep_challenge_company_name, ep_challenge_title) {
        this.is_show = true;
        this.ep_challenge_id = ep_challenge_id;
        this.ep_challenge_company_name = ep_challenge_company_name;
        this.ep_challenge_title = ep_challenge_title;

        // 各種データ初期化
        this.current_page = 1;
        this.current_per = 0;
        this.total_page = 0;
        this.total_count = 0;
        this.provisional_su_latest_infos = [];
        this.add_recommends = [];
        this.check_recommend_su_latest_info_ids = [];
        this.modal.company_name = '';
        this.modal.person_name = '';
        this.modal.industry = '';
        this.modal.free_word = '';
        this.modal.per = '';
        this.modal.page = '';
      },

      // モーダルクローズ
      close() {
        this.is_show = false;
      },

      // 検索ボタン押下時
      onClickSearch(){
        this.$loading.load(
          this.$auth.api.post(
            'admin/recommends/search_su_latest_infos', {
              ep_challenge_id: this.ep_challenge_id,
              company_name: this.modal.company_name,
              person_name: this.modal.person_name,
              industry: this.modal.industry,
              free_word: this.modal.free_word,
              per: this.current_per,
              page: this.current_page,
          }).then(res => {
            this.provisional_su_latest_infos = res.data.provisional_su_latest_infos || {};
            this.current_per                 = Number(res.data.per);
            this.current_page                = Number(res.data.page);
            this.total_page                  = res.data.total_page;
            this.total_count                 = res.data.total_count;
          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        )
      },

      // SU企業名押下時
      onSuCompanyName: function(id){
        // 新しいタブでEpチャレンジの詳細画面に遷移
        let routeData = this.$router.resolve({name: 'AdminSuCompanyDetail', params: { id: id } });
        window.open(routeData.href, '_new');
      },

      // ページ切り替え時
      onChangePage(page, per) {
        this.current_page = page;
        this.current_per = per;

        // 画面更新
        this.onClickSearch();
      },

      // 追加ボタン押下時
      addRecommend(index) {
        this.add_recommends.push({
          su_latest_info_id:             this.provisional_su_latest_infos[index].su_latest_info_id,
          provisional_su_latest_info_id: this.provisional_su_latest_infos[index].id,
          su_company_name:               this.provisional_su_latest_infos[index].su_company.company_name,
          su_latest_info_title:          this.provisional_su_latest_infos[index].su_latest_info.title,
          admin_hide_flg:                0,
          admin_recommend_status:        null,
          clicked_at:                    null,
          ep_recommend_id:               null,
          ep_recommend_updated_at:       null,
          manual_addition_flg:           1,
          su_latest_info_updated_at:     this.provisional_su_latest_infos[index].su_latest_info.updated_at,
        });
        this.check_recommend_su_latest_info_ids.push(this.provisional_su_latest_infos[index].su_latest_info_id);
      },

      // 削除ボタン押下時
      deleteRecommend(index) {
        this.check_recommend_su_latest_info_ids = this.check_recommend_su_latest_info_ids.filter(item => item != this.add_recommends[index].su_latest_info_id);
        this.add_recommends.splice(index, 1);
      },

      // OKボタン押下時
      onSend() {
        this.onClickSuccess(this.add_recommends);
        this.close();
      },

      // キャンセルボタン押下時
      onCancel() {
        this.close();
      },

    },
  }
</script>
