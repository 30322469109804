<template>
  <main>
    <section class="main-title">
      <h1>過去の調達実績作成</h1>
    </section>

    <form @submit.prevent class="needs-validation" novalidate>
      <!-- 過去の調達実績登録-->
      <section class="chapter white-block">
        <div class="form-wrap d-flex">
          <div class="right-col">
            <div class="form-group">
              <label class="required-addon">調達日付<span class="badge badge-danger">必須</span></label>
              <div class="datetimepicker-input icon-addon-right">
                <b-form-input
                  v-model="su_old_achievement.supply_date"
                  :state="checkValid('supply_date')"
                  class="form-control"
                  id="supply_date"
                  type="text"
                  data-toggle="datetimepicker"
                  data-target="#supply_date"
                  autocomplete="off"
                  required
                ></b-form-input
                ><i class="far fa-calendar-alt"></i>
              </div>
              <p v-if="!!errors['supply_date']" class="invalid-feedback d-block">{{ errors["supply_date"][0] }}</p>
            </div>
            <div class="form-group">
              <label class="required-addon" for="amount">金額<span class="badge badge-danger">必須</span></label>
              <b-form-input v-model="su_old_achievement.amount" type="text" class="form-control" :state="checkValid('amount')" id="amount" placeholder="例) 10,000,000"></b-form-input>
              <p v-if="!!errors['amount']" class="invalid-feedback d-block">{{ errors["amount"][0] }}</p>
            </div>

            <div class="form-group">
              <label class="required-addon" for="unit">単位<span class="badge badge-danger">必須</span></label>
              <b-form-input v-model="su_old_achievement.unit" type="text" class="form-control" :state="checkValid('unit')" id="unit" placeholder="例) JPY"></b-form-input>
              <p v-if="!!errors['unit']" class="invalid-feedback d-block">{{ errors["unit"][0] }}</p>
            </div>

            <div class="form-group">
              <label class="required-addon" for="invest_persons">出資者<span class="badge badge-secondary">任意</span></label>
              <div class="card">
                <!-- 検索・新規作成-->
                <div class="card-header py-3">
                  <label>新規作成</label>
                  <div class="new-assets">
                    <div class="incremental-wrap">
                      <input v-model="new_invest_person" class="form-control" type="text" placeholder="" value="" />
                    </div>
                    <button @click="onNewInvest" class="button-square btn-send" :class="{ disabled: !new_invest_person }" type="button" data-toggle="modal" data-target="#market-setting">
                      作成
                    </button>
                  </div>
                </div>
                <!-- /検索・新規作成-->
                <div class="card-body">
                  <label>登録予定の出資者</label>
                  <div>
                    <template v-for="(invest_person, index) in su_old_achievement.invest_persons">
                      <div class="assets-labels mt-0" :key="invest_person">
                        <!-- 表示 -->
                        <div class="d-flex" :class="edit_index != index ? 'd-show' : 'd-none'">
                          <p class="task-labels-parent pl-0">{{ invest_person }}</p>
                          <button @click="onEditInvest(index)" class="btn-action mx-2 js-switch-trigger" type="button">
                            <i class="fas fa-pen" v-b-tooltip.hover="'編集'"></i>
                          </button>
                          <button @click="onDeleteInvest(index)" class="btn-action" type="button">
                            <i class="fas fa-trash" v-b-tooltip.hover="'削除'"></i>
                          </button>
                        </div>
                        <!-- 編集 -->
                        <div class="assets-edit-wrap" :class="edit_index == index ? 'd-show' : 'd-none'">
                          <div class="incremental-wrap">
                            <input v-model="edit_invest_person" class="form-control" type="text" />
                          </div>
                          <div class="btn-container d-flex justify-content-end mt-2">
                            <button @click="onCancelInvest(index)" class="button-square btn-cancel btn-sm" type="button">キャンセル</button>
                            <button @click="onSaveInvest(index)" :class="{ disabled: edit_invest_person == invest_person }" class="button-square btn-send btn-sm" type="button">保存</button>
                          </div>
                        </div>
                      </div>
                    </template>
                    <!-- エラーメッセージ -->
                    <p v-if="!!errors['invest_persons']" class="invalid-feedback d-block">{{ errors["invest_persons"][0] }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label class="required-addon" for="investor_finance_vc">投資家の数-金融系VC<span class="badge badge-secondary">任意</span></label>
              <b-form-input
                v-model="su_old_achievement.investor_finance_vc"
                type="text"
                class="form-control"
                :state="checkValid('investor_finance_vc')"
                id="investor_finance_vc"
                placeholder="例) 1"
              ></b-form-input>
              <p v-if="!!errors['investor_finance_vc']" class="invalid-feedback d-block">{{ errors["investor_finance_vc"][0] }}</p>
            </div>

            <div class="form-group">
              <label class="required-addon" for="investor_cvc_company">投資家の数-CVC・事業会社<span class="badge badge-secondary">任意</span></label>
              <b-form-input
                v-model="su_old_achievement.investor_cvc_company"
                type="text"
                class="form-control"
                :state="checkValid('investor_cvc_company')"
                id="investor_cvc_company"
                placeholder="例) 1"
              ></b-form-input>
              <p v-if="!!errors['investor_cvc_company']" class="invalid-feedback d-block">{{ errors["investor_cvc_company"][0] }}</p>
            </div>

            <div class="form-group">
              <label class="required-addon" for="investor_independent">投資家の数-独立系VC<span class="badge badge-secondary">任意</span></label>
              <b-form-input
                v-model="su_old_achievement.investor_independent"
                type="text"
                class="form-control"
                :state="checkValid('investor_independent')"
                id="investor_independent"
                placeholder="例) 1"
              ></b-form-input>
              <p v-if="!!errors['investor_independent']" class="invalid-feedback d-block">{{ errors["investor_independent"][0] }}</p>
            </div>

            <div class="form-group">
              <label class="required-addon" for="investor_government">投資家の数-政府系<span class="badge badge-secondary">任意</span></label>
              <b-form-input
                v-model="su_old_achievement.investor_government"
                type="text"
                class="form-control"
                :state="checkValid('investor_government')"
                id="investor_government"
                placeholder="例) 1"
              ></b-form-input>
              <p v-if="!!errors['investor_government']" class="invalid-feedback d-block">{{ errors["investor_government"][0] }}</p>
            </div>

            <div class="form-group">
              <label class="required-addon" for="investor_international_vc">投資家の数-海外VC<span class="badge badge-secondary">任意</span></label>
              <b-form-input
                v-model="su_old_achievement.investor_international_vc"
                type="text"
                class="form-control"
                :state="checkValid('investor_international_vc')"
                id="investor_international_vc"
                placeholder="例) 1"
              ></b-form-input>
              <p v-if="!!errors['investor_international_vc']" class="invalid-feedback d-block">{{ errors["investor_international_vc"][0] }}</p>
            </div>

          </div>
        </div>
      </section>
      <!-- 過去の調達実績登録-->

      <div class="btn-container d-flex justify-content-center">
        <button class="button-square btn-send" type="button" @click="confirmAdminSuOldAchievement">確認する</button>
      </div>
    </form>
  </main>
</template>

<script>
import Form from "../../../common/mixins/form";

export default {
  metaInfo: {
    title: "過去の調達実績作成",
  },

  mixins: [Form],

  data: function() {
    return {
      errors: "",
      was_valid: false,
      new_invest_person: "",
      edit_invest_person: "",
      edit_index: -1,
    };
  },
  props: {
    su_old_achievement: Object,
  },

  methods: {
    confirmAdminSuOldAchievement: function () {
      this.su_old_achievement.su_company_id = this.$route.params.id;
      this.$loading.load(
        this.$auth.api
          .patch("admin/su_old_achievements/confirm", {
            su_old_achievement: this.su_old_achievement,
          })
          .then((response) => {
            this.$store.commit("su_old_achievement/setSuOldAchievement", {
              su_old_achievement: this.su_old_achievement,
            });
            this.$router.push({ name: "AdminSuOldAchievementNewConfirm" });
          })
          .catch((error) => {
            if (error.response.status == 422) {
              if (error.response.data && error.response.data.errors) {
                this.errors = error.response.data.errors;
                // 確認ボタン押下の確認、falseの時はis-invalidが付与されない
                this.was_valid = true;
              }
              // エラー時ページトップにスクロール
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            } else if (error.response.status == 500) {
              this.$errorHandlers.initial(error);
            } else {
              this.$errorHandlers.ajax(error);
            }
          })
      );
    },

    // 出資者 新規作成ボタン押下時
    onNewInvest() {
      // 入力値が空文字の場合、スルー
      if (this.new_invest_person == "") {
        return;
      } else if (this.su_old_achievement.invest_persons.includes(this.new_invest_person)) {
        this.$bvToast.toast('同名の出資者が既に存在します。', {
          variant: 'danger',
          title: 'エラー'
        });
        return false;
      }
      // 登録予定に追加
      this.su_old_achievement.invest_persons.push(this.new_invest_person);
      // 出資者の入力欄を空にする
      this.new_invest_person = "";
    },

    // 出資者 編集ボタン押下時処理
    onEditInvest(index) {
      // 対象の出資者を編集状態にする
      this.edit_invest_person = this.su_old_achievement.invest_persons[index];
      this.edit_index = index;
    },

    // 出資者 削除ボタン押下時処理
    onDeleteInvest(index) {
      // 配列から削除
      this.su_old_achievement.invest_persons.splice(index, 1);
    },

    // 出資者編集 保存ボタン押下時処理
    onSaveInvest(index) {
      // 入力値が空文字の場合、スルー
      if (this.edit_invest_person == "") {
        return;
      } else if (this.su_old_achievement.invest_persons.includes(this.edit_invest_person)) {
        this.$bvToast.toast('同名の出資者がすでに存在します。', {
          variant: 'danger',
          title: 'エラー'
        });
        return false;
      }

      // 出資者を変更
      this.su_old_achievement.invest_persons.splice(index, 1, this.edit_invest_person);
      this.edit_invest_person = "";
      this.edit_index = -1;
    },

    // 出資者編集 キャンセルボタン押下時処理
    onCancelInvest(asset_master) {
      this.edit_invest_person = "";
      this.edit_index = -1;
    },
  },
  mounted() {
    const self = this;
    // 調達日付のカレンダー設定
    this.setDatePicker(
      "#supply_date",
      function (selected_date) {
        self.su_old_achievement.supply_date = selected_date;
      },
      "bottom"
    );
  },
};
</script>
