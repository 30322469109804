<template>
  <div class="wrapper">
    <header class="top-header">
      <router-link class="d-logo" :to="'/ep/login'"><img src="~images/header_logo.svg" alt="Deloitte. デロイト トーマツ | six brain"></router-link>

      <div class="btn-container">
          <button class="btn btn-white rounded-pill" type="button" @click="login">ログイン</button>
          <button class="btn btn-green rounded-pill" type="button" @click="register">アカウント作成</button>
      </div>
    </header>

    <router-view />

    <FooterComponent />
  </div>
</template>

<script>
import FooterComponent from '../../common/layouts/footer'

export default {
  components:{
    FooterComponent,
  },
  data () {
    return {
      
    }
  },
  methods: {
    login () {
      this.$router.push('/ep/login');
    },
    register() {
      this.$router.push('/ep/request_info');
    }
  },
  computed: {
  },
  created: function () {
    
  },
  mounted: function() {
  },
  watch: {
  }
}
</script>

<style scoped>
</style>