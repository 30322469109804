<template>
  <main>
    <section class="main-title">
      <h1>ユーザー{{viewName}}<span class="small"> - 確認</span></h1>
    </section>

    <form>
      <section class="chapter white-block">
        <div class="form-wrap d-flex">
          <div class="left-col">
            <img class="thumbnail-frame img180" v-if="!!user.uploaded_image" :src="user.uploaded_image" alt="プロフィール">
            <img class="thumbnail-frame img180" v-else-if="!user.uploaded_image && !!image_attributes.filename" :src="user.presigned_url" alt="プロフィール">
            <img class="thumbnail-frame img180" v-else src="~images/dtvs_user_default.png" alt="プロフィール">
          </div>
          <div class="right-col">
            <dl class="form-group form-confirm-group">
              <dt>氏名</dt>
              <dd>{{user.last_name}} {{user.first_name}}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>氏名（フリガナ）</dt>
              <dd>{{user.kana_last_name}} {{user.kana_first_name}}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>メールアドレス（ログインID）</dt>
              <dd>{{user.email}}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>役職名</dt>
              <dd>{{user.position}}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>部署名</dt>
              <dd>{{user.department}}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>アカウント種別</dt>
              <dd>{{account_type_ja}}</dd>
            </dl>
          </div>
        </div>
      </section>

      <div class="btn-container d-flex justify-content-center">
        <button class="button-square btn-cancel" type="button" v-if="!!user.id" @click="$router.push({name: 'AdminUserEditForm', id: user.id})">修正する</button>
        <button class="button-square btn-cancel" type="button" v-else="!!user.id" @click="$router.push({name: 'AdminUserNewForm'})">修正する</button>
        <button class="button-square btn-send" type="button" @click="saveUser">{{sendButtonName}}</button>
      </div>
    </form>
  </main>
</template>

<script>
import ProfileImage from './ProfileImage'
export default {
  name: 'UserConfirm',
  components: {
    ProfileImage
  },
  data(){
    return {
      user: {
        type: Object,
        default(){
          return {
            id: "",
            email: "",
            last_name: "",
            first_name: "",
            kana_last_name: "",
            kana_first_name: "",
            account_type: "",
            position: "",
            department: "",
            password: "",
            password_confirmation: "",
            uploaded_image: "",
            new_image_name: "",
            image_size: "",
            updated_at: "",
            image_attributes: {id: "", filename: ""}
          }
        }
      },
      image_attributes: {id: "", filename: ""},
      account_type_ja: ""
    }
  },
  computed: {
    viewName() {
      if (this.$route.name.includes('Edit')){
        return '編集';
      } else {
        return '登録';
      }
    },
    sendButtonName(){
      if (this.$route.name.includes('Edit')){
        return '更新する';
      } else {
        return '登録する';
      }
    }
  },
  methods: {
    initForm () {
      this.$loading.load(this.$auth.api.get('admin/users/init_form.json')
        .then(response => {
          this.user = this.$store.getters['user/user'];
          this.image_attributes = this.user.image_attributes
          if(this.user.first_name == ""){
            // エラー。ブラウザバックとかしてるとここに入ってくる
            this.$router.push({name: 'AdminUserIndex', query: {toast_type: 'invalid_transitioned'}})
          }
          let account_type = response.data.account_types.filter(t => t.value == this.user.account_type )
          this.account_type_ja = account_type[0]['text']
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
    },
    saveUser(){
      if (this.$route.name.includes('Edit')){
        this.submitData(this.update, 'updated');
      } else {
        this.submitData(this.create, 'created');
      }
    },
    create(){
      return this.$auth.api.post('admin/users/', {
        user: this.user
      });
    },
    update(){
      return this.$auth.api.patch(`admin/users/${this.user.id}`, {
        user: this.user
      });
    },
    submitData (req, toast_type) {
      req().then(response => {
        this.$router.push({name: 'AdminUserShow', params: {id: response.data.id}, query: {toast_type: toast_type}});
      })
      .catch(error => {
        if(error.response.status == 500) {
          this.$errorHandlers.initial(error);
        } else {
          this.$errorHandlers.ajax(error);
        }
      });
    }
  },
  mounted(){
    this.initForm();
  }
}
</script>