<template>
  <section class="chapter white-block step-nav">
    <nav aria-label="Progress">
      <ol role="list">
        <li v-for="step in steps" :key="step.linkName">
          <router-link
            :to="{ name: step.linkName }"
            :class="{ 'current': activeStep(step.linkName) }"
            aria-current="step"
          >
            <span class="step-num">{{ step.name }}</span>
            <span class="label">{{ step.subName }}</span>
          </router-link>
        </li>
      </ol>
    </nav>
    <div class="p-3 m-3 border border-success rounded">
      <p class="text-box" v-html="currentStepDescription" />
    </div>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        steps: [
          {
            name: 'Step 1',
            subName: 'PL項目の集計',
            description: '対象企業が事業成長のために自由に使うことができるキャッシュフロー（Free Cash Flow）を計算するために、その計算の基礎となる税引後営業利益を算出します。',
            linkName: 'EpScenariosShowStep1',
            children: [],
          },
          {
            name: 'Step 2',
            subName: 'Free Cash Flow（FCF）の計算',
            description: `各年度において、対象企業が事業成長のために自由に使うことができるキャッシュフロー（FCF）を以下の計算式により見積もります。<br>
              <b style="color: #77A620;">Free Cash Flow = 税引後営業利益 ± 運転資本の増減額 + 減価償却費等の非資金決済項目 - 設備投資</b><br>
              設備投資が大きいDeep Tech系の会社や、在庫の変動が大きいリテール系の会社を評価する際は設備投資や運転資本の増減がFCFに大きな影響を及ぼすことがあるので注意しましょう。`,
            linkName: 'EpScenariosShowStep2',
            children: ['EpScenariosShowWorkingCapital', ],
          },
          {
            name: 'Step 3',
            subName: 'Exit マルチプルによる永続価値を合算したFCFの計算',
            description: `事業計画上見積もった最終年度において、対象企業がEXITした場合における時価総額を見積りましょう。<br>
              時価総額を見積もる場合は、対象企業と類似する企業のPER（株価利益倍率）などを参照するマルチプル法を利用するケースが多いです。<br>
              <b style="color: #77A620;">EXIT時の時価総額 = 計画最終年度に見込まれる予想税引後当期純利益 × 類似企業のマルチプル</b>`,
            linkName: 'EpScenariosShowStep3',
            children: ['EpScenariosShowPerComparison', ],
          },
          {
            name: 'Step 4',
            subName: '現在価値の計算',
            description: '各計画期間で算出したFree Cash FlowやExitマルチプルで計算したExit時点における時価総額（永続価値）を現在の価値に割引計算しましょう。',
            linkName: 'EpScenariosShowStep4',
            children: [],
          },
          {
            name: 'Step 5',
            subName: '有利子負債等の調整',
            description: `STEP1〜4で算出した現在価値は対象企業の事業が稼ぎ出したキャッシュフローをもとに見積もった「事業価値」です。<br>
              この数値から負債価値や非事業価値を加減算し、株主に帰属する価値（株主価値）を算出しましょう。`,
            linkName: 'EpScenariosShowStep5',
            children: [],
          },
          {
            name: 'Step 6',
            subName: '非流動性ディスカウント等の調整',
            description: `非流動性ディスカウントなどの調整を加え、貴社が想定する株主価値やシェアを見積りましょう。<br>
              見積もった結果を対象企業からのファイナンス条件と見比べて、その妥当性を議論してください。`,
            linkName: 'EpScenariosShowStep6',
            children: [],
          },
        ],
        perComparisonDescription: '類似企業を数社選定しPERを入力してください。PERは株価収益率のことで株価÷1株あたりの純利益で求められます。',
      };
    },
    computed: {
      currentStepDescription() {
        let routeName = this.$route.name;
        if (routeName === 'EpScenariosShowWorkingCapital') {
          routeName = 'EpScenariosShowStep2';
        }
        if (routeName === 'EpScenariosShowPerComparison') {
          return this.perComparisonDescription;
        }
        return this.steps.find(v => v.linkName === routeName).description;
      },
    },
    methods: {
      activeStep(linkName) {
        const targetIdx = this.steps.findIndex((step) => {
          if (step.children.length && step.children.includes(linkName)) return true;
          return step.linkName === linkName;
        });
        const currentLinkName = this.$route.name;
        const currentIdx = this.steps.findIndex((step) => {
          if (step.children.length && step.children.includes(currentLinkName)) return true;
          return step.linkName === currentLinkName;
        });

        return targetIdx <= currentIdx;
      },
    },
  };
</script>
