<template>
  <main>
    <section class="main-title">
      <h1>担当者詳細</h1>
    </section>

    <!-- 担当者詳細-->
    <section class="chapter white-block">
      <div class="overview-wrap pt-3">
        <div class="left-col">
          <img v-if="!!user.image_attributes && user.image_attributes.filename"
            class="thumbnail-frame img180"
            :src="user.presigned_url"
            :alt="user.image_attributes.filename"
          >
          <img v-else
            class="thumbnail-frame img180"
            src="~images/su_user_default.png"
            alt="画像が選択されていません"
          />
          <p class="login-time mt-3">{{user.last_login}}</p>
        </div>
        <div class="right-col">
          <div class="overview-list my-4">
            <dl class="form-confirm-group">
              <dt>氏名</dt>
              <dd>{{fullName(user)}}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>氏名（フリガナ）</dt>
              <dd>{{fullKanaName(user)}}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>メールアドレス<br>（ログインID）</dt>
              <dd>{{user.email}}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>役職名</dt>
              <dd>{{user.position}}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>部署名</dt>
              <dd>{{user.department}}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>プロフィール</dt>
              <dd>{{user.profile}}</dd>
            </dl>
          </div>
        </div>
      </div>
      <!-- 担当者詳細-->
      <div class="btn-container d-flex justify-content-center">
        <button class="btn btn-medium btn-outline-green rounded-pill" type="button" @click="$router.push({path: './edit'})">編集する</button>
      </div>
    </section>

    <!-- 2段階認証設定(無効化のみ可) -->
    <TwoFactorAuth
      editUrl="AdminTwoFactorAuthEdit"
      :user_id="Number(user_id)"
      :isInActivate="true"
      :otpRequiredForLogin="user.otp_required_for_login"
      @reloadFunc="getSuPersonInCharge"
    />
    <!-- 2段階認証設定 -->

    <div class="btn-container d-flex justify-content-center pt-2">
      <button class="btn btn-big btn-white rounded-pill" type="button" @click="backToPage()">もどる</button>

      <button
        class="btn btn-big btn-delete rounded-pill"
        type="button"
        data-toggle="modal"
        data-target="#delete"
        @click="$refs.delete_confirm_modal.open()"
        >削除する</button>
    </div>

    <ConfirmModal
      ref="delete_confirm_modal"
      :onClickSend="onDeleteConfirmSend"
      :body_text="'この担当者を削除します。<br>よろしいですか？'"
      :send_button_text="'削除する'"
      :isNoFade="isNoFade"
    />

  </main>
</template>

<script>
  // 確認モーダルのコンポーネント読み込み
  import ConfirmModal from '../../../common/pages/ConfirmModal.vue';
  // 2段階認証設定コンポーネント
  import TwoFactorAuth from '../../../common/pages/two_factor_auth/Show.vue';

  export default {

    metaInfo: {
      title: '担当者詳細'
    },

    components: {
      ConfirmModal,
      TwoFactorAuth
    },

    props: {
      currentUserId: Number,
      isNoFade: Boolean,
    },

    data: function () {
      return {
        user_id: this.$route.params.id,
        user: {},
        display_order: 'posted_time_desc',
        is_company_id_illegal: false,
        from_page: {},
        is_actor_illegal: false,
        is_su_actor_illegal: false
      }
    },
    created: function(){
      this.getSuPersonInCharge();
      this.from_page = this.$store.getters['back_to_index_modal/fromPage'];
    },

    watch: {
      display_order(){
        this.getSuPersonInCharge();
      }
    },
    methods: {
      getSuPersonInCharge: function() {
        this.$loading.load(this.$auth.api.get(`admin/su_person_in_charges/${this.user_id}`, {
          params: {
            display_order: this.display_order
          }
        })
        .then(res => {
          this.user = res.data.user;
          this.is_company_id_illegal = res.data.is_company_id_illegal;
          this.is_actor_illegal = res.data.is_actor_illegal;
          this.is_su_actor_illegal = res.data.is_su_actor_illegal;
          this.checkIllegalId();
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
      },
      checkIllegalId(){
        if (this.is_actor_illegal) {
          this.$router.push({path: '/admin/' , query: { toast_type: 'invalid_transitioned' } });
        } else if (this.is_su_actor_illegal){
          this.$router.push({name: 'AdminSuCompanyDetail', params: {id: this.user.registration_requests_id}, query: {toast_type: 'invalid_transitioned'}});
        }
      },
      fullName: function (user) {
        return `${user.last_name} ${user.first_name}`
      },
      fullKanaName: function (user) {
        return user.kana_last_name == '' || user.kana_last_name == null ? '' : `${user.kana_last_name} ${user.kana_first_name}`
      },
      // 戻るボタン押下
      backToPage(){
        this.$router.push({name: 'AdminSuCompanyDetail', params: { id: this.user.registration_requests_id }})
      },
      // 削除確認モーダル内削除するボタン押下時
      onDeleteConfirmSend() {
        this.onSuPersonInChargeDelete();
      },
      // 削除処理
      onSuPersonInChargeDelete() {
        this.$loading.load(
          this.$auth.api.delete(
            'admin/su_person_in_charges/' + this.user.id
          ).then(res => {
            this.$refs.delete_confirm_modal.close();
            this.$router.push({name: 'AdminSuCompanyDetail',
              params: { id: this.user.registration_requests_id },
              query: { toast_type: 'deleted' }
            });
          }).catch(err => {
            this.$refs.delete_confirm_modal.close();
            if(err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }else{
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },
    },
  }
</script>
