<template>
  <div>
    <section id="pic_list" class="chapter white-block mt-5">
      <h2 class="chapter-label">子アカウント一覧</h2>

      <template>
        <div class="btn-container edit-btn-position">
          <button @click="$router.push({ name: 'AdminSuPersonInChargeNewForm', params: {id: su_company_id}})" class="btn btn-green rounded-pill" type="button">新規作成</button>
        </div>
      </template>
      <div class="table-responsive">
        <table class="table table-striped table-hover" v-if="su_company.sorted_su_person_in_charges_users">
          <thead class="thead-light">
            <tr>
              <th class="w-25">氏名</th>
              <th class="w-25">部署名</th>
              <th class="w-25">メールアドレス</th>
              <th class="w-25"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="sorted_su_user in su_company.sorted_su_person_in_charges_users" :key="sorted_su_user.id">
              <td>{{fullName(sorted_su_user)}}</td>
              <td><template v-if="!!sorted_su_user.department">{{sorted_su_user.department}}</template></td>
              <td>{{sorted_su_user.email}}</td>
              <td class="text-right">
                <button class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'詳細'" @click="$router.push({name: 'AdminSuPersonInChargeShow', params: {id: sorted_su_user.id}})"><i class="fas fa-ellipsis-h"></i></button>
                <template>
                  <button class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'編集'" @click="$router.push({name: 'AdminSuPersonInChargeEditForm', params: {id: sorted_su_user.id}})"><i class="fas fa-pen"></i></button>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>

</template>

<script>

  export default {

    props: {
      su_company: {
        type: Object
      },
      su_company_id: {
        type: [Number,String]
      },
    },

    data(){
      return {
      }
    },
    methods: {
      fullName: function (user) {
        return `${user.last_name} ${user.first_name}`
      },
    },
  }

</script>
