<template>
  <main>
    <Header :accountType="accountType" />
    <MiniHeader :accountType="accountType" />
    <div class="request-flow-image">
      <ul>
        <li class="flow-title"><img src="~images/request_flow_title.svg" alt="Step"></li>
        <li class="flow-01"><img src="~images/request_flow1_on.svg" alt="Step1 メールアドレス入力"></li>
        <li class="flow-02"><img src="~images/request_flow2_on.svg" alt="Step2 メール送信"></li>
        <li class="flow-03"><img src="~images/request_flow3_on.svg" alt="Step3 申請情報入力"></li>
        <li class="flow-04"><img src="~images/request_flow4.svg" alt="Step4 申請情報確認"></li>
        <li class="flow-05"><img src="~images/request_flow5.svg" alt="Complete 申請完了"></li>
      </ul>
    </div>
    <div class="request-inner mt-6">
      <form @submit.prevent="onConfirm" class="needs-validation" novalidate>
        <!-- 企業情報-->
        <section class="chapter white-block">
          <h2 class="chapter-label">企業情報
            <p class="sub-label text-black-50">企業情報を入力してください</p>
          </h2>

          <div class="form-wrap border-top">
            <div class="form-group">
              <label class="required-addon" for="company_name">企業名<span class="badge badge-danger">必須</span></label>
              <input class="form-control" type="text" id="company_name" v-model="form.registration_request.company_name" placeholder="例) シックスブレイン株式会社" required>
              <div v-if="!!errors['company_name']" class="invalid-feedback d-block">{{ errors['company_name'][0] }}</div>
            </div>

            <div class="form-group">
              <label class="required-addon" for="company_url">企業HP<span class="badge badge-danger">必須</span></label>
              <input class="form-control" type="text" id="company_url" v-model="form.registration_request.company_hp" placeholder="例) https://sixbrain.ai" required>
              <div v-if="!!errors['company_hp']" class="invalid-feedback d-block">{{ errors['company_hp'][0] }}</div>
            </div>

            <div class="form-group" v-if="accountType == 'su'">
              <label class="required-addon">創業日<span class="badge badge-danger">必須</span></label>
              <div class="datetimepicker-input icon-addon-right">
                <b-form-input
                  v-model="form.registration_request.founding_date"
                  class="form-control"
                  id="founding_date"
                  type="text"
                  data-toggle="datetimepicker"
                  data-target="#founding_date"
                  autocomplete="off"
                  required
                ></b-form-input><i class="far fa-calendar-alt"></i>
              </div>
              <p v-if="!!errors['founding_date']" class="invalid-feedback d-block">{{ errors['founding_date'][0] }}</p>
            </div>

            <fieldset class="form-group">
              <legend class="required-addon mb-0">業界</legend>
              <div class="required-addon"><span class="badge badge-danger">必須</span>
                <div class="col d-flex">
                  <select class="custom-select col-5" id="business_type" v-model="form.registration_request.industry_master_id" required>
                    <option selected disabled value>選択してください</option>
                    <option v-for="(industry_i18n, industry) in industries" :key="industry" :value="industry">
                      {{ industry_i18n }}
                    </option>
                  </select>
                  <div v-if="!!errors['industry_master']" class="invalid-feedback m-2 d-block">{{ errors['industry_master'][0] }}</div>
                </div>
              </div>
              <div class="required-addon mt-2"><span class="badge badge-secondary">任意</span>
                <div class="col d-flex">
                  <select class="custom-select col-5" id="business_type2" v-model="form.registration_request.sub_industry_master_id">
                    <option selected>選択してください</option>
                    <option v-for="(industry_i18n, industry) in industries" :key="industry" :value="industry">
                      {{ industry_i18n }}
                    </option>
                  </select>
                  <div v-if="!!errors['sub_industry_master']" class="invalid-feedback m-2 d-block">{{ errors['sub_industry_master'][0] }}</div>
                </div>
              </div>
              <div  v-if="accountType == 'su'" class="required-addon mt-2"><span class="badge badge-secondary">任意</span>
                <div class="col d-flex">
                  <select class="custom-select col-5" id="business_type3" v-model="form.registration_request.sub2_industry_master_id">
                    <option selected>選択してください</option>
                    <option v-for="(industry_i18n, industry) in industries" :key="industry" :value="industry">
                      {{ industry_i18n }}
                    </option>
                  </select>
                  <div v-if="!!errors['sub2_industry_master']" class="invalid-feedback m-2 d-block">{{ errors['sub2_industry_master'][0] }}</div>
                </div>
              </div>
            </fieldset>

            <fieldset>
              <legend class="required-addon">有限責任監査法人トーマツ及びDeloitteのメンバーファームからの監査<span class="badge badge-secondary">任意</span></legend>
              <div class="form-group custom-control custom-checkbox vertical-checkbox">
                <input class="custom-control-input" type="checkbox" id="auditor" v-model="form.registration_request.is_audit" true-value='audit' false-value='unaudit'>
                <label class="required-addon custom-control-label" for="auditor">受けている</label>
                <div v-if="!!errors['is_audit']" class="invalid-feedback d-block">{{ errors['is_audit'][0] }}</div>
              </div>
            </fieldset>

            <fieldset v-if="accountType == 'su'">
              <legend class="required-addon">情報公開許諾の有無<span class="badge badge-secondary">任意</span></legend>
              <div class="form-group custom-control custom-checkbox vertical-checkbox">
                <input class="custom-control-input" type="checkbox" id="publisher" v-model="form.registration_request.is_publish_info" true-value='publish' false-value='unpublish'>
                <label class="required-addon custom-control-label" for="publisher">公開する</label>
                <p v-if="!!errors['is_publish_info']" class="invalid-feedback d-block">{{ errors['is_publish_info'][0] }}</p>
              </div>
            </fieldset>

          </div>
        </section>
        <!-- 企業情報-->

        <!-- 申請者情報-->
        <section class="chapter white-block">
          <h2 class="chapter-label">申請者情報
            <p class="sub-label text-black-50">あなたの情報を入力してください。審査結果をご連絡させていただきます。</p>
          </h2>

          <div class="form-wrap border-top">
            <fieldset>
              <div class="form-row">
                <div class="form-group col-6">
                  <label class="required-addon" for="administrator_name1">姓<span class="badge badge-danger">必須</span></label>
                  <input class="form-control" type="text" id="administrator_name1" v-model="form.registration_request.last_name" placeholder="例) デロイト" required wovn-ignore>
                  <div v-if="!!errors['last_name']" class="invalid-feedback d-block">{{ errors['last_name'][0] }}</div>
                </div>
                <div class="form-group col-6">
                  <label class="required-addon" for="administrator_name2">名<span class="badge badge-danger">必須</span></label>
                  <input class="form-control" type="text" id="administrator_name2" v-model="form.registration_request.first_name" placeholder="例) 太郎" required wovn-ignore>
                  <div v-if="!!errors['first_name']" class="invalid-feedback d-block">{{ errors['first_name'][0] }}</div>
                </div>
              </div>
            </fieldset>

            <div class="form-group">
              <label class="required-addon" for="administrator_department">部署名<span class="badge badge-secondary">任意</span></label>
              <input class="form-control" type="text" id="administrator_department" v-model="form.registration_request.department" placeholder="例) 経営戦略部">
              <div v-if="!!errors['department']" class="invalid-feedback d-block">{{ errors['department'][0] }}</div>
            </div>
          </div>
        </section>
        <!-- 申請者情報-->

        <!-- マイページログイン情報-->
        <section class="chapter white-block">
          <h2 class="chapter-label mt-0 mb-3">マイページログイン情報
            <p class="sub-label text-black-50">最後にマイページにログインするためのパスワードを入力してください。<br>ログインIDはご登録いただいたメールアドレスです。</p>
          </h2>

          <div class="form-wrap border-top">
            <div class="form-group">
              <label>ログインID</label>
              <div class="input-group icon-addon-right" wovn-ignore>{{ registration_auth.email }}</div>
            </div>
            <div class="form-group">
              <label class="required-addon" for="administrator_password">パスワード<span class="badge badge-danger">必須</span></label>
              <div class="input-group icon-addon-right">
                <input class="form-control" id="administrator_password" v-model="form.registration_request.encrypted_password" placeholder="" aria-describedby="password-help" required autocomplete="new-password" :type="password_type" wovn-ignore>
                <div v-if="!!errors['encrypted_password']" class="invalid-feedback d-block">{{ errors['encrypted_password'][0] }}</div>
              </div><small class="form-text text-secondary" id="password-help">・半角英数字・記号、10文字以上<br>・英小文字、英大文字、数字、記号の４つの文字種のうち、３つ以上の文字種を含む</small>
            </div>
            <div class="form-group">
              <label class="required-addon" for="administrator_password2">パスワード（確認用）<span class="badge badge-danger">必須</span></label>
              <div class="input-group icon-addon-right">
                <input class="form-control" id="administrator_password2" v-model="form.registration_request.password_confirmation" placeholder="" required :type="password_confirmation_type" wovn-ignore>
                <div v-if="!!errors['password_confirmation']" class="invalid-feedback d-block">{{ errors['password_confirmation'][0] }}</div>
              </div>
            </div>
          </div>
        </section>

        <div class="btn-container d-flex justify-content-center mt-5">
          <button class="button-square btn-send" type="submit">確認する</button>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
  import Header from "../registration_auths/Header";
  import MiniHeader from "../registration_auths/MiniHeader";
  import form from "../../../common/mixins/form.js"

  export default {
    mixins: [form],
    metaInfo: {
      title: 'six brainアカウント登録申請'
    },
    name: "New",
    components: {
      Header,
      MiniHeader,
    },
    props: {
      accountType: String,
      registration_auth: Object,
      registration_request: Object,
      industries: Object | Array,
      form: Object,
    },
    data() {
      return {
        password_type: 'password',
        password_confirmation_type: 'password',
        confirm_enabled: false,
        errors: {},
      }
    },
    methods: {
      onClickTogglePassword() {
        this.password_type = this.togglePasswordType(this.password_type);
      },
      onClickTogglePasswordConfirmation() {
        this.password_confirmation_type = this.togglePasswordType(this.password_confirmation_type);
      },
      togglePasswordType(value) {
        if (value === 'password') {
          return 'text';
        } else {
          return 'password';
        }
      },
      onConfirm() {
        this.$auth.api.patch('registration_requests/confirm', this.form ).then(res => {
          this.$router.push({path: './confirm'});
        }).catch(err => {
          if (err.response.status == 422) {
            this.errors = err.response.data.errors;
            console.log(this.errors);
            // エラー時ページトップにスクロール
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
          } else {
            this.$errorHandlers.ajax(err);
          }
        })
      },
    },
    watch: {
      'form.registration_request.industry_master_id'(new_value, old_value) {
        this.form.registration_request.industry = this.industries[new_value];
      },
      'form.registration_request.sub_industry_master_id'(new_value, old_value) {
        this.form.registration_request.sub_industry = this.industries[new_value];
      },
      'form.registration_request.sub2_industry_master_id'(new_value, old_value) {
        this.form.registration_request.sub2_industry = this.industries[new_value];
      },
    },
    mounted() {
      const self = this;
      // 創業日 のカレンダー設定
      this.setDatePicker(
        '#founding_date',
        function(selected_date) {
          self.form.registration_request.founding_date = selected_date;
        },
        'top'
      );
    },
  }
</script>

<style scoped>

</style>
