<template>
  <div class="side-scroll-container">
    <nav>
      <ul class="list-unstyled">
        <li @click="$router.push('/admin/', () => {}, () => {})" :class="[currentPage.indexOf('/admin/') === 0 && currentPage.length==7 ? currentClass : '']">
          <a><i class="fas fa-tachometer-alt"></i><span>ダッシュボード</span></a>
        </li>
        <li @click="routerPush('AdminRecommendIndex')" :class="[currentPage.includes('/recommends') ? currentClass : '']">
          <a><i></i><span>リコメンド一覧</span></a>
        </li>
        <li @click="routerPush('AdminAfterRecommendIndex')" :class="[currentPage.includes('after_recommends') ? currentClass : '']">
          <a><i></i><span>リコメンド後データ一覧</span></a>
        </li>
        <li @click="routerPush('AdminProvisionalEpChallengeIndex')" :class="[currentPage.includes('provisional_ep_challenges/index') ? currentClass : '']">
          <a><i></i><span>チャレンジ一覧</span></a>
        </li>
        <li @click="routerPush('AdminProvisionalSuLatestInfoIndex')" :class="[currentPage.includes('provisional_su_latest_infos/index') ? currentClass : '']">
          <a><i></i><span>最新情報一覧</span></a>
        </li>
        <li @click="routerPush('AdminEpCompanyIndex')" :class="[currentPage.includes('ep_companies/index') || currentPage.includes('ep_companies/') && currentPage.includes('show') || currentPage.includes('ep_companies/') && currentPage.includes('edit') || currentPage.includes('ep_companies/') && currentPage.includes('edit_confirm') ? currentClass : '']">
          <a><i></i><span>大手企業一覧</span></a>
        </li>
        <li @click="routerPush('AdminSuIndex')" :class="[currentPage.includes('su_companies/index') || currentPage.includes('su_companies/') && currentPage.includes('detail') ? currentClass : '']">
          <a><i></i><span>スタートアップ企業一覧</span></a>
        </li>
        <li @click="routerPush('AdminMessageIndex')" :class="[currentPage.includes('message_rooms/index') ? currentClass : '']">
          <a><i></i><span>メッセージルーム一覧</span></a>
        </li>
        <li @click="routerPush('AdminAssetMasterIndex')" :class="[currentPage.includes('asset_masters') && !currentPage.includes('asset_masters/new') ? activeClass : '']">
          <a><i></i><span>アセットマスタ一覧</span></a>
        </li>
        <li @click="routerPush('AdminUserShow', {id: currentUserId})" :class="[currentPage.includes('users/' + currentUserId + '/show') ? currentClass : '']">
          <a><i></i><span>アカウント情報</span></a>
        </li>
        <li :class="[currentPage.includes('user') && !currentPage.includes('users/' + currentUserId + '/show') ? currentClass : '']">
          <a v-b-toggle.sub-menu8 aria-expanded="true" aria-controls="sub-menu8"><i class="fas fa-chevron-down"></i><span>ユーザー管理</span></a>
          <b-collapse id="sub-menu8">
            <ul class="sub-menu" id="sub-menu8">
              <li @click="routerPush('AdminUserIndex')" :class="[currentPage.includes('users/index') ? activeClass : '']">
                <a><span>ユーザー一覧</span></a>
              </li>
              <li @click="routerPush('AdminUserNewForm')" :class="[currentPage.includes('users/new') || currentPage.includes('users') && currentPage.includes('edit') ? activeClass:'']">
                <a><span>ユーザー作成</span></a>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li @click="routerPush('AdminAchievementCsvImport')" :class="[currentPage.includes('achievement_csv_import/index') ? currentClass : '']">
          <a><i></i><span>調達実績取込</span></a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: { currentUserId: Number, accountType: String },

  data() {
    return {
      currentClass: 'current',
      activeClass: 'active',
    };
  },
  computed: {
    currentPage() {
      return this.$route.path
    },
  },
  methods: {
    // 画面遷移処理
    routerPush(route_name, params = {}){
      // 遷移元と遷移先のルート名が同じ場合は何もしない
      if (this.$route.name != route_name){
        this.$router.push({name: route_name, params: params} , () => {}, () => {});
      }
    }
  },
}
</script>
