<template>
  <main>
    <section class="main-title">
      <h1>企業情報</h1>
    </section>

    <!-- 基本情報-->
    <section class="chapter white-block">
      <h2 class="chapter-label">基本情報</h2>
      <div v-if="su_company.is_undecided && is_fetch" class="alert alert-danger alert-dismissible fade show" role="alert">
        <p>※このアカウントはまだ未承認です。（審査が必要です）</p>
      </div>
      <div class="overview-wrap border-top">
        <div class="right-col">
          <div class="data-header">
            <h3 class="company-name">{{ su_company.company_name }}</h3>
            <dl>
              <dt><i class="fas fa-desktop" title="URL"></i></dt>
              <dd><a class="btn-text" :href="su_company.company_hp" target="_blank" rel="noopener">
                <template v-if="deviceIsPc()">
                  {{ su_company.company_hp | truncate(70) }}
                </template>
                <template v-else>
                  {{ su_company.company_hp | truncate(30) }}
                </template>
              </a></dd>
            </dl>
          </div>
          <div class="overview-list my-4">
            <dl class="form-confirm-group">
              <dt>業界</dt>
              <dd>{{ industry }}&nbsp;&nbsp;&nbsp;&nbsp;{{ sub_industry }}&nbsp;&nbsp;&nbsp;&nbsp;{{ sub2_industry }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>代表者</dt>
              <dd>{{ su_company.representative }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>所在地</dt>
              <dd><span v-if="su_company.prefecture_master_id!=''">{{officeLocation}}</span></dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>創業日</dt>
              <dd>{{ su_company.founding_date | format.date }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>私たちについて</dt>
              <dd>{{ su_company.business_summary }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>ステージ</dt>
              <dd>{{ stage }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>デロイト監査監査有無</dt>
              <dd>{{ su_company.is_audit_i18n }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>情報公開許諾の有無</dt>
              <dd>{{ su_company.is_publish_info_i18n }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>現在時価総額</dt>
              <dd>{{ su_company.current_market_capital | format.trimFractionDigits }}<span v-if="!!su_company.current_market_capital">&nbsp;&nbsp;百万円</span></dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>直近ファイナンス日</dt>
              <dd>{{ su_company.last_finance_date | format.date }}</dd>
            </dl>
          </div>
        </div>
      </div>

      <h2 class="chapter-label">担当者情報</h2>
      <div class="form-wrap d-flex border-top">
        <div class="left-col">
          <!-- 画像が登録されている -->
          <img v-if="su_company.user_attributes.image_attributes && su_company.user_attributes.image_attributes.filename"
            class="thumbnail-frame img180"
            :src="su_company.user_attributes.presigned_url"
            :alt="su_company.user_attributes.image_attributes.filename"
          />
          <!-- 画像が登録されていない -->
          <img v-else
            class="thumbnail-frame img180"
            src="~images/su_user_default.png"
            alt="株式会社シックスブレイン logo"
          />
          <p class="login-time mt-3">{{su_company.user_attributes.last_login}}</p>
        </div>
        <div class="right-col">
          <div class="overview-list my-4">
            <dl class="form-confirm-group">
              <dt>氏名</dt>
              <dd>{{ fullName }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>氏名（フリガナ）</dt>
              <dd>{{ fullKanaName }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>メールアドレス</dt>
              <dd>{{ su_company.user_attributes.email }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>役職名</dt>
              <dd>{{ su_company.user_attributes.position }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>部署名</dt>
              <dd>{{ su_company.user_attributes.department }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>プロフィール</dt>
              <dd>{{ su_company.user_attributes.profile }}</dd>
            </dl>
          </div>
        </div>
      </div>

    <!--更新する・削除するボタン-->
      <div class="btn-container d-flex justify-content-center">
        <button @click="onDeleteCompany(su_company.registration_requests_id, su_company.updated_at)" class="btn btn-medium btn-delete rounded-pill" type="button">削除する</button>
        <button @click="$router.push({name: 'AdminSuEditForm', params: { id: su_company.registration_requests_id }})" class="btn btn-medium btn-green rounded-pill" type="button">更新する</button>
      </div>
    <!--更新する・削除するボタン-->

    </section>
    <!-- 基本情報-->

    <!-- 2段階認証設定(無効化のみ可) -->
    <TwoFactorAuth
      editUrl="AdminTwoFactorAuthEdit"
      :user_id="su_company.user_attributes.id"
      :isInActivate="true"
      :otpRequiredForLogin="su_company.user_attributes.otp_required_for_login"
      @reloadFunc="$emit('fetchSuCompany')"
      v-if="su_company.is_accepted"
    />
    <!-- 2段階認証設定 -->

    <!-- 過去の調達実績一覧 -->
    <OldAchievementIndex
      :su_company="su_company"
      :su_company_id="su_company.id"
      v-if="su_company.is_accepted"
    />
    <!-- 過去の調達実績一覧 -->

    <!-- 子アカウント一覧-->
    <AdminShowIndex
      :su_company="su_company"
      :su_company_id="su_company.id"
      v-if="su_company.is_accepted"
    />
    <!-- 子アカウント一覧-->

    <div class="btn-container d-flex justify-content-center pt-2">
      <button class="btn btn-big btn-white rounded-pill" type="button" @click="backToPage()">もどる</button>
    </div>
    <ConfirmModal
      ref="delete_confirm_modal"
      :onClickSend="onDeleteCompanyConfirmSend"
      :body_text="'既に使用されている場合も削除します。<br>よろしいですか？'"
      :send_button_text="'削除する'"
      :isNoFade="isNoFade"
    />
  </main>
</template>

<script>

// コンポーネント読み込み
// 端末判別コンポーネント
import DeviceCheck from '../../../common/mixins/device_check.js';
// 子アカウント一覧
import  AdminShowIndex from '../../../admin/pages/su_companies/AdminShowIndex'
// 過去の調達実績一覧
import  OldAchievementIndex from '../../../admin/pages/su_companies/OldAchievementIndex'
// 削除ボタン押下時のモーダル
import ConfirmModal from '../../../common/pages/ConfirmModal.vue';
// 2段階認証設定コンポーネント
import TwoFactorAuth from '../../../common/pages/two_factor_auth/Show.vue';


export default {
  metaInfo: {
    title: '企業情報'
  },
  components: {
    AdminShowIndex,
    OldAchievementIndex,
    ConfirmModal,
    TwoFactorAuth
  },
  mixins: [DeviceCheck],

  props: {
    su_company: Object | String,
    stages: Object,
    prefectures: Object,
    industries: Object,
    before_page_name: String,
    isNoFade: Boolean,
    is_fetch: Boolean
  },

  methods: {
    // 削除ボタン押下時
    onDeleteCompany(su_company_id, updated_at) {
      this.delete_target_id = su_company_id;
      this.delete_target_updated_at = updated_at
      this.$refs.delete_confirm_modal.open();
    },

    // 削除確認モーダル内の削除するボタン押下時
    onDeleteCompanyConfirmSend() {
      this.$loading.load(
        this.$auth.api.delete('admin/su_companies/' + this.delete_target_id,
          { params: { updated_at: this.delete_target_updated_at } }
        ).then(res => {
          this.$refs.delete_confirm_modal.close();
          this.delete_target_id = '';
          this.$router.push({ name: 'AdminSuIndex' });
          this.$bvToast.toast("削除しました。", {
            variant: 'success',
            title: '完了'
          });
        }).catch(err => {
          this.$refs.delete_confirm_modal.close()
          if(err.response.status == 500) {
            this.$errorHandlers.initial(err);
          } else {
            this.$errorHandlers.ajax(err);
          }
        })
      )
    },

    // 戻るボタン押下時
    backToPage(){
      if(this.before_page_name == 'AdminSuEditForm'){
        this.$router.push({name: 'AdminSuIndex'})
      }else{
        history.back();
      }
    },
  },
  computed: {
    stage () {
      return this.stages[this.su_company.stage]
    },
    officeLocation: function () {
      return this.su_company.prefecture_master_id == '' || this.su_company.prefecture_master_id == null ? '' : `${this.prefectures[this.su_company.prefecture_master_id]}${this.su_company.address}`
    },
    industry () {
      return this.industries[this.su_company.industry_master_id]
    },
    sub_industry () {
      return `${this.industries[this.su_company.sub_industry_master_id] || ''}`
    },
    sub2_industry () {
      return `${this.industries[this.su_company.sub2_industry_master_id] || ''}`
    },
    fullName () {
      return `${this.su_company.user_attributes.last_name} ${this.su_company.user_attributes.first_name}`;
    },
    fullKanaName () {
      return this.su_company.user_attributes.kana_last_name == '' || this.su_company.user_attributes.kana_last_name == null ? '' : `${this.su_company.user_attributes.kana_last_name} ${this.su_company.user_attributes.kana_first_name}`
    },
  },
  filters: {
    numberWithDelimiter (value) {
      if (!value) {
        return ''
      }
      return value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,')
    }


  },
}
</script>
