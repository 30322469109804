<template>
  <div>
    <router-view
      :scenario="scenario"
      :unit_iso_code="unit_iso_code"
      :scenario_statuses="scenario_statuses"
      :years_customs="years_customs"
      :valuation_calculation_types="valuation_calculation_types"
      :form.sync="form"
      :validation_errors="validation_errors"
      :onTemporarily="onTemporarily"
      :onSave="onSave"
    >
    </router-view>
    <CompletedModal
      ref="completed_modal"
      :onModalClose="moveAfterSave"
      :action_text="'更新'"
      :isNoFade="isNoFade"
    />
  </div>
</template>

<script>
  import CompletedModal from '../../../common/pages/CompletedModal.vue';
  export default {
    metaInfo: {
      title: 'シナリオ編集',
    },

    components: {
      CompletedModal,
    },

    data() {
      return {
        scenario: {},
        // 通貨ISOコード
        unit_iso_code: '',
        // ステータス一覧
        scenario_statuses: {},
        // 表示年数
        years_customs: [],
        // バリュエーション計算の仕方
        valuation_calculation_types: {},
        validation_errors: {},
        form: {
          scenario: {
            portfolio_id: '',
            name: '',
            scenario_status:  '',
            capital_raised_count: '',
            round: '',
            investment_amount: '',
            expected_return: '',
            valuation_date: '',
            liquidity_discount: '',
            years_custom: '',
            valuation_calculation_type: '',
          },
        },
      }
    },

    props: { isNoFade: Boolean },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.init();
    },

    // route.push時に実行
    beforeRouteUpdate (to, from, next) {
      next();
    },

    // コンポーネントを描画するルートが確立する前に呼ばれる
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.previous_route = from;
      });
    },

    // メソッド定義
    methods: {
      // 初期化
      init() {
        this.getScenario();
      },

      getScenario() {
        this.$loading.load(
          this.$auth.api.get(
            `ep/scenarios/form_init?id=${this.$route.params.id}`
          ).then(res => {
            this.form.scenario = res.data.scenario;
            // 通貨ISO_コード
            this.unit_iso_code = res.data.unit_iso_code;
            // ステータス一覧
            this.scenario_statuses = res.data.scenario_statuses;
            // 表示年数一覧
            this.years_customs = res.data.years_customs;
            // バリュエーション計算の仕方
            this.valuation_calculation_types = res.data.valuation_calculation_types;

          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        );
      },

      onTemporarily() {
        this.onSave(true)
      },

      // 保存ボタン押下
      onSave(is_temporary = false) {
        // 一時保存
        this.form.scenario.is_temporary = is_temporary ? true : false

        this.$loading.load(
          this.$auth.api.patch(
            `ep/scenarios/${this.$route.params.id}`, this.form
          ).then(res => {
            // 更新完了モーダルを表示
            this.$refs.completed_modal.open();
          }).catch(err => {
            // バリデーションエラー
            if (err.response.status == 422) {
              this.validation_errors = err.response.data.errors;
            }
            else if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else {
              this.$errorHandlers.ajax(err);
            }
          })
        );
      },

      // 保存後の画面遷移処理
      moveAfterSave() {
        if (this.form.scenario.is_temporary) {
          this.$router.push({ name: 'EpPortfolioShow', params: {id: this.form.scenario.portfolio_id }});
        } else {
          this.$router.push({ name: 'EpScenariosShowStep1', params: {id: this.$route.params.id }});
        }
      },
    },
  }

</script>
