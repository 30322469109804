<template>
  <main>
    <section class="main-title">
      <h1>企業情報</h1>
    </section>

    <!-- 基本情報-->
    <section class="chapter white-block">
      <h2 class="chapter-label">基本情報</h2>
      <div class="overview-wrap border-top">
        <div class="right-col">
          <div class="deta-header">
            <h3 class="company-name">{{ su_company.company_name }}</h3>
            <dl>
              <dt><i class="fas fa-desktop" title="URL"></i></dt>
              <dd><a class="btn-text" :href="su_company.company_hp" target="_blank" rel="noopener">
                <template v-if="deviceIsPc()">
                  {{ su_company.company_hp | truncate(70) }}
                </template>
                <template v-else>
                  {{ su_company.company_hp | truncate(30) }}
                </template>
              </a></dd>
            </dl>
          </div>
          <div class="overview-list my-4">
            <dl class="form-confirm-group">
              <dt>業界</dt>
              <dd>{{ industry }}&nbsp;&nbsp;&nbsp;&nbsp;{{ sub_industry }}&nbsp;&nbsp;&nbsp;&nbsp;{{ sub2_industry }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>代表者</dt>
              <dd>{{ su_company.representative }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>所在地</dt>
              <dd><span v-if="su_company.prefecture_master_id!=''">{{officeLocation}}</span></dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>創業日</dt>
              <dd>{{ su_company.founding_date | format.date }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>私たちについて</dt>
              <dd>{{ su_company.business_summary }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>ステージ</dt>
              <dd>{{ stage }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>有限責任監査法人トーマツ及びDeloitteの<br>メンバーファームからの監査</dt>
              <dd>{{ su_company.is_audit_i18n }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>情報公開許諾の有無</dt>
              <dd>{{ su_company.is_publish_info_i18n }}</dd>
            </dl>
          </div>
        </div>
      </div>

      <h2 class="chapter-label">担当者情報</h2>
      <div class="form-wrap d-flex border-top">
        <div class="left-col">
          <!-- 画像が登録されている -->
          <img v-if="su_company.user_attributes.image_attributes && su_company.user_attributes.image_attributes.filename"
            class="thumbnail-frame img180"
            :src="su_company.user_attributes.presigned_url"
            :alt="su_company.user_attributes.image_attributes.filename"
          />
          <!-- 画像が登録されていない -->
          <img
            v-else
            class="thumbnail-frame img180"
            src="~images/su_user_default.png"
            alt="株式会社シックスブレイン logo"
          />
        </div>
        <div class="right-col">
          <div class="overview-list my-4">
            <dl class="form-confirm-group">
              <dt>氏名</dt>
              <dd wovn-ignore>{{ fullName }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>氏名（フリガナ）</dt>
              <dd wovn-ignore>{{ fullKanaName }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>メールアドレス</dt>
              <dd wovn-ignore>{{ su_company.user_attributes.email }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>役職名</dt>
              <dd>{{ su_company.user_attributes.position }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>部署名</dt>
              <dd>{{ su_company.user_attributes.department }}</dd>
            </dl>
            <dl class="form-confirm-group">
              <dt>プロフィール</dt>
              <dd>{{ su_company.user_attributes.profile }}</dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="btn-container d-flex justify-content-center" v-if="current_api_v1_user.account_type == 'su_user'">
        <button class="btn btn-medium btn-outline-green rounded-pill" type="button" @click="$router.push({name: 'SuSuEditForm'})">編集する</button>
      </div>
    </section>
    <!-- 基本情報-->

    <!-- 2段階認証設定(親アカウントのみ設定可) -->
    <TwoFactorAuth
      editUrl="SuTwoFactorAuthEdit"
      :isSetting="current_api_v1_user.account_type == 'su_user'"
      :otpRequiredForLogin="su_company.user_attributes.otp_required_for_login"
    />
    <!-- 2段階認証設定 -->

    <ShowIndex
      baseApiUrl="su"
      :su_company_id="su_company.id"
    />

  </main>
</template>

<script>
// コンポーネント読み込み
import ShowIndex from '../../../common/pages/su_companies/ShowIndex.vue';
import TwoFactorAuth from '../../../common/pages/two_factor_auth/Show.vue';
// 端末判別コンポーネント
import DeviceCheck from '../../../common/mixins/device_check.js';

export default {
  metaInfo: {
    title: '企業情報'
  },
  components: {
    ShowIndex,
    TwoFactorAuth
  },
  mixins: [DeviceCheck],

  props: {
    su_company: Object | String,
    stages: Object,
    prefectures: Object,
    industries: Object,
    contract_name: String,
    suBusinessInfos: Array,
    provisionalSuBusinessInfos: Array,
    hasProvisionalAsset: Boolean,
    current_api_v1_user: Object,
  },
  computed: {
    stage () {
      return this.stages[this.su_company.stage]
    },
    officeLocation: function () {
      return this.su_company.prefecture_master_id == '' || this.su_company.prefecture_master_id == null ? '' : `${this.prefectures[this.su_company.prefecture_master_id]}${this.su_company.address}`
    },
    industry () {
      return this.industries[this.su_company.industry_master_id]
    },
    sub_industry () {
      return `${this.industries[this.su_company.sub_industry_master_id] || ''}`
    },
    sub2_industry () {
      return `${this.industries[this.su_company.sub2_industry_master_id] || ''}`
    },
    fullName () {
      return `${this.su_company.user_attributes.last_name} ${this.su_company.user_attributes.first_name}`;
    },
    fullKanaName () {
      return this.su_company.user_attributes.kana_last_name == '' || this.su_company.user_attributes.kana_last_name == null ? '' : `${this.su_company.user_attributes.kana_last_name} ${this.su_company.user_attributes.kana_first_name}`
    },
  },
  filters: {
    numberWithDelimiter (value) {
      if (!value) {
        return '0'
      }
      return value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,')
      }
  }
}
</script>
