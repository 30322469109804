<template>
  <!-- 検索 -->
  <section class="search-block">
    <form @submit.prevent>
      <div class="accordion" id="serach">
        <div class="form-group card">
          <div class="card-header" id="searach-parent">
            <a class="collapse"
               data-toggle="collapse"
               href="javascript:void(0);" @click.prevent.stop=""
               v-b-toggle.searach-toggle-child>検索<i class="fas"></i></a>
          </div>
          <b-collapse id="searach-toggle-child" accordion="my-accordion" role="tabpanel">
            <div class="card-body collapse show" id="searach-child">
              <div class="form-group row">
                <label class="col-2 col-form-label" for="ep">大企業</label>
                <div class="col-10" :class="pick_ep_companies.length > 0 ? 'incremental-wrap' : ''">
                  <input v-model="form.ep_company_name"
                         @input="onInputEpCompany"
                         @blur="resetPickList"
                         class="form-control" type="text" id="ep" value="" maxlength="100">
                  <input v-model="form.ep_company_id" type="hidden">
                  <div class="list-group keyword-list" v-if="pick_ep_companies.length > 0">
                    <a v-for="pick_ep_company in pick_ep_companies"
                      :key="pick_ep_company.id"
                      class="list-group-item list-group-item-action"
                      @click.prevent.stop="onClickEpCompany(pick_ep_company)"
                      href="#">
                      {{ pick_ep_company.company_name }}
                    </a>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-2 col-form-label" for="challenge">大企業 部署名</label>
                <div class="col-10">
                  <input v-model="form.ep_company_department" class="form-control" type="text" id="ep_company_department">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-2 col-form-label" for="challenge">チャレンジタイトル</label>
                <div class="col-10" :class="pick_ep_challenges.length > 0 ? 'incremental-wrap' : ''">
                  <input v-model="form.ep_challenge_title"
                         @input="onInputEpChallenge"
                         @blur="resetPickList"
                         class="form-control" type="text" id="challenge" value="" maxlength="200">
                  <input v-model="form.ep_challenge_id" type="hidden">
                  <div class="list-group keyword-list" v-if="pick_ep_challenges.length > 0">
                    <a v-for="pick_ep_challenge in pick_ep_challenges"
                      :key="pick_ep_challenge.id"
                      class="list-group-item list-group-item-action"
                      @click.prevent.stop="onClickEpChallenge(pick_ep_challenge)"
                      href="#">
                      {{ pick_ep_challenge.title }}
                    </a>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-2 col-form-label" for="su">スタートアップ企業</label>
                <div class="col-10" :class="pick_su_companies.length > 0 ? 'incremental-wrap' : ''">
                  <input v-model="form.su_company_name"
                         @input="onInputSuCompany"
                         @blur="resetPickList"
                         class="form-control" type="text" id="su" value="" maxlength="100">
                  <input v-model="form.su_company_id" type="hidden">
                  <div class="list-group keyword-list" v-if="pick_su_companies.length > 0">
                    <a
                      v-for="pick_su_company in pick_su_companies"
                      :key="pick_su_company.id"
                      class="list-group-item list-group-item-action"
                      @click.prevent.stop="onClickSuCompany(pick_su_company)"
                      href="#">
                      {{ pick_su_company.company_name }}
                    </a>
                  </div>
                </div>
              </div>

              <div class="row">
                <label class="col-2 col-form-label">作成日</label>
                <div class="col-10">
                  <div class="form-group">
                    <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                      <input v-model="form.created_at_from" class="form-control" id="start-date" type="text" data-toggle="datetimepicker" data-target="#start-date"><i class="far fa-calendar-alt"></i>
                    </div><span class="unit"> ~</span>
                    <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                      <div class="input-group icon-addon-right">
                        <input v-model="form.created_at_to" class="form-control" id="end-date" type="text" data-toggle="datetimepicker" data-target="#end-date"><i class="far fa-calendar-alt"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <fieldset class="form-group">
                <div class="row">
                  <legend class="col-2 col-form-label">ステータス</legend>
                  <div class="col-10 align-self-center single-check">
                    <div class="main-checkbox d-inline-block">
                      <div v-for="(recommend_status_i18n, recommend_status_key) in recommendStatuses" :key="recommend_status_key" class="custom-control custom-checkbox custom-control-inline">
                        <input
                              v-model="form.recommend_statuses"
                              type="checkbox"
                              class="custom-control-input"
                              :id="'status_' + recommend_status_key"
                              :value="recommend_status_key"
                            >
                        <label :for="'status_' + recommend_status_key" class="custom-control-label">{{ recommend_status_i18n }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>

              <div class="row">
                <label class="col-2 col-form-label">リコメンド送付日</label>
                <div class="col-10">
                  <div class="form-group">
                    <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                      <input v-model="form.recommend_at_from"
                        class="form-control" id="r-start-date"
                        type="text" data-toggle="datetimepicker" data-target="#r-start-date">
                      <i class="far fa-calendar-alt"></i>
                    </div>
                    <span class="unit"> ~</span>
                    <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                      <div class="input-group icon-addon-right">
                        <input v-model="form.recommend_at_to"
                          class="form-control" id="r-end-date"
                          type="text" data-toggle="datetimepicker" data-target="#r-end-date">
                        <i class="far fa-calendar-alt"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <fieldset class="form-group">
                <div class="row">
                  <legend class="col-2 col-form-label">リコメンド種類</legend>
                  <div class="col-10 align-self-center single-check">
                    <div class="main-checkbox d-inline-block">
                      <div v-for="(recommend_type_i18n, recommend_type_key) in recommendTypes" :key="recommend_type_key"
                        class="custom-control custom-checkbox custom-control-inline">
                        <input
                              v-model="form.recommend_types"
                              type="checkbox"
                              class="custom-control-input"
                              :id="'status_' + recommend_type_key"
                              :value="recommend_type_key"
                            >
                        <label :for="'status_' + recommend_type_key" class="custom-control-label">{{ recommend_type_i18n }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>

              <div class="row">
                <label class="col-2 col-form-label">興味あり押下日</label>
                <div class="col-10">
                  <div class="form-group">
                    <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                      <input v-model="form.interested_at_from"
                        class="form-control" id="i-start-date"
                        type="text" data-toggle="datetimepicker" data-target="#i-start-date">
                      <i class="far fa-calendar-alt"></i>
                    </div>
                    <span class="unit"> ~</span>
                    <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                      <div class="input-group icon-addon-right">
                        <input v-model="form.interested_at_to"
                          class="form-control" id="i-end-date"
                          type="text" data-toggle="datetimepicker" data-target="#i-end-date">
                        <i class="far fa-calendar-alt"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p class="search-no-data" v-if="loaded && !hasRecord">検索条件に一致するものはありません。</p>
              <div class="btn-container d-flex justify-content-end">
                <button @click="search" class="button-square btn-send" type="button">検索する</button>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </form>
  </section>
  <!-- 検索-->
</template>

<script>
  import form from "../../../common/mixins/form.js"

  export default {

    mixins: [form],

    props: {
      // EP企業の候補(インクリメンタルサーチの候補データ)
      conditionEpCompanies: {
        type: Array,
        default: () => ([])
      },

      // challengeの候補(インクリメンタルサーチの候補データ)
      conditionEpChallenges: {
        type: Array,
        default: () => ([])
      },

      // SU企業の候補(インクリメンタルサーチの候補データ)
      conditionSuCompanies: {
        type: Array,
        default: () => ([])
      },

      // ステータスの選択肢
      recommendStatuses: {
        type: Object | Array,
        default: () => ([])
      },

      // リコメンド種類の選択肢
      recommendTypes: {
        type: Object | Array,
        default: () => ([])
      },

      // 検索時：SU企業ID
      searchedSuCompanyId: {
        type: String,
        default: () => ("")
      },

      // 検索時：SU企業名
      searchedSuCompanyName: {
        type: String,
        default: () => ("")
      },

      // 検索時：リコメンドステータス
      searchedRecommendStatuses: {
        type: Object | Array,
        default: () => ([])
      },

      // 検索時：リコメンド送付日From
      searchedRecommendAtFrom: {
        type: String,
        default: () => ("")
      },

      // 検索時：リコメンド送付日To
      searchedRecommendAtTo: {
        type: String,
        default: () => ("")
      },

      // 検索時：リコメンド種類
      searchedRecommendTypes: {
        type: Object | Array,
        default: () => ([])
      },

      // 検索時：興味あり押下日From
      searchedInterestedAtFrom: {
        type: String,
        default: () => ("")
      },

      // 検索時：興味あり押下日To
      searchedInterestedAtTo: {
        type: String,
        default: () => ("")
      },

      // 検索結果が存在するか
      hasRecord: {
        type: Boolean,
        default: true
      },

      // ロード完了したか
      loaded: {
        type: Boolean,
        default: false
      }
    },

    data: function () {
      return {
        form: {
          ep_company_id: this.$route.query.ep_company_id || '',
          ep_company_name: this.$route.query.ep_company_name || '',
          ep_company_department: this.$route.query.ep_company_department || '',
          ep_challenge_id: this.$route.query.ep_challenge_id || '',
          ep_challenge_title: this.$route.query.ep_challenge_title || '',
          created_at_from: this.$route.query.created_at_from || '',
          created_at_to:   this.$route.query.created_at_to   || '',
          su_company_id: this.searchedSuCompanyId,
          su_company_name: this.searchedSuCompanyName,
          recommend_statuses: this.searchedRecommendStatuses,
          recommend_at_from: this.searchedRecommendAtFrom,
          recommend_at_to:   this.searchedRecommendAtTo,
          recommend_types: this.searchedRecommendTypes,
          interested_at_from: this.searchedInterestedAtFrom,
          interested_at_to:   this.searchedInterestedAtTo,
        },
        pick_ep_companies: [],
        pick_ep_challenges: [],
        pick_su_companies: [],
      }
    },

    mounted() {
      const self = this;
      // 作成日_from のカレンダー設定
      this.setDatePicker(
        '#start-date',
        function(selected_date) {
          self.form.created_at_from = selected_date;
        },
        'top'
      );
      // 作成日_to のカレンダー設定
      this.setDatePicker(
        '#end-date',
        function(selected_date) {
          self.form.created_at_to = selected_date;
        },
        'top'
      );

      // リコメンド送付日_from のカレンダー設定
      this.setDatePicker(
        '#r-start-date',
        function(selected_date) {
          self.form.recommend_at_from = selected_date;
        },
        'top'
      );
      // リコメンド送付日_to のカレンダー設定
      this.setDatePicker(
        '#r-end-date',
        function(selected_date) {
          self.form.recommend_at_to = selected_date;
        },
        'top'
      );

      // 興味あり押下日_from のカレンダー設定
      this.setDatePicker(
        '#i-start-date',
        function(selected_date) {
          self.form.interested_at_from = selected_date;
        },
        'top'
      );
      // 興味あり押下日_to のカレンダー設定
      this.setDatePicker(
        '#i-end-date',
        function(selected_date) {
          self.form.interested_at_to = selected_date;
        },
        'top'
      );
    },

    methods: {

      /**
       * EP企業入力時のアクション.
       * (インクリメンタルサーチ)
       */
      onInputEpCompany: function () {
        const search_ep_company_name = this.form.ep_company_name;

        if (search_ep_company_name === '') {
          // 初期化
          this.pick_ep_companies = [];
        } else {
          this.pick_ep_companies = Object.values(this.conditionEpCompanies).filter(function (ep_company) {
            // 入力されたEP企業名で部分一致
            return ep_company.company_name.includes(search_ep_company_name)
          })
        }
        // 入力を検知した場合、EP企業IDの値を空にする
        this.form.ep_company_id = '';
      },

      /**
       * EP企業インクリメンタルサーチの候補選択時のアクション.
       */
      onClickEpCompany: function (ep_company) {
        this.pick_ep_companies = [];
        this.form.ep_company_name = ep_company.company_name;
        this.form.ep_company_id = ep_company.id;
      },

      /**
       * チャレンジ入力時のアクション.
       * (インクリメンタルサーチ)
       */
      onInputEpChallenge: function () {
        const search_ep_challenge_title = this.form.ep_challenge_title;

        if (search_ep_challenge_title === '') {
          // 初期化
          this.pick_ep_challenges = [];
        } else {
          this.pick_ep_challenges = Object.values(this.conditionEpChallenges).filter(function (ep_challenge) {
            // 入力されたタイトルで部分一致
            return ep_challenge.title.includes(search_ep_challenge_title)
          })
        }
        // 入力を検知した場合、EPチャレンジIDの値を空にする
        this.form.ep_challenge_id = '';
      },

      /**
       * チャレンジインクリメンタルサーチの候補選択時のアクション.
       */
      onClickEpChallenge: function (ep_challenge) {
        this.pick_ep_challenges = [];
        this.form.ep_challenge_title = ep_challenge.title;
        this.form.ep_challenge_id = ep_challenge.id;
      },

      /**
       * SU企業入力時のアクション.
       * (インクリメンタルサーチ)
       */
      onInputSuCompany: function () {
        const search_su_company_name = this.form.su_company_name;

        if (search_su_company_name === '') {
          // 初期化
          this.pick_su_companies = [];
        } else {
          this.pick_su_companies = Object.values(this.conditionSuCompanies).filter(function (su_company) {
            // 入力されたSU企業名で部分一致
            return su_company.company_name.includes(search_su_company_name)
          })
        }
        // 入力を検知した場合、SU企業IDの値を空にする
        this.form.su_company_id = '';
      },

      /**
       * SU企業インクリメンタルサーチの候補選択時のアクション.
       */
      onClickSuCompany: function (su_company) {
        this.pick_su_companies = [];
        this.form.su_company_name = su_company.company_name;
        this.form.su_company_id = su_company.id;
      },

      /**
       * 検索ボタン押下時の処理.
       */
      search: function () {
        this.pick_ep_companies = [];
        this.pick_ep_challenges = [];
        this.pick_su_companies = [];
        this.$emit('search-button-click', this.form);
      },

      /**
       * インクリメンタルサーチ中のフォーカスアウト.
       * (インクリメンタルサーチの候補をリセット)
       */
      resetPickList: function () {
        setTimeout(() => {
          this.pick_ep_companies = [];
          this.pick_ep_challenges = [];
          this.pick_su_companies = [];
        }, 200);
      }
    }
  }
</script>