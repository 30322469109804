<template>
  <div class="left-col drop_area" @dragover.prevent="onArea" @drop.prevent="dropUserFile" @dragleave.prevent="offArea" @dragend.prevent="offArea">
    <!-- 既に保存されている画像 -->
    <img v-if="status==1"
      class="thumbnail-frame img180 mx-auto mb-3"
      :src="presigned_url"
      :alt="filename"
    />
    <!-- 新しく画像が選択された -->
    <img v-else-if="status==2" class="thumbnail-frame img180 mx-auto mb-3"
      :src="uploaded_image"
      :alt="new_image_name"
    />
    <img v-else
      class="thumbnail-frame img180 mx-auto mb-3"
      src="~images/dtvs_user_default.png"
      alt="株式会社シックスブレイン logo"
    />
    <p v-if="!!errors['image']" class="invalid-feedback d-block">{{ errors['image'][0] }}</p>
    <p v-if="!!errors['filename']" class="invalid-feedback d-block">{{ errors['filename'][0]}}</p>
    <p v-if="!!errors['image_size']" class="invalid-feedback d-block">{{ errors['image_size'][0]}}</p>
    <div class="btn-group row" role="group" aria-label="画像アップロード">
      <button @click="removeImage" class="btn btn-cancel icon-addon-left col-4" type="button"><i class="fas fa-times"></i>削除</button>
      <label class="btn btn-file icon-addon-left col-8" for="user-file-upload"><i class="fas fa-arrow-up"></i>アップロード
        <input type="file" id="user-file-upload" @change="onUserFileChange" :accept="accepted_extensions" ref="pitchFileUploader">
      </label>
    </div>
  </div>
</template>

<script>
// アップロード画像チェック
import UploadFileCheck from "../../../common/mixins/upload_file_check";

export default {
  name: 'ProfileImage',
  mixins: [UploadFileCheck],
  props: [
    'id',
    'filename',
    'presigned_url',
    'errors',
    'accepted_extensions'
  ],
  data(){
    return {
      uploaded_image: '',
      new_image_name: '',
      deleted: false
    }
  },
  computed:{
    status(){
      if(this.deleted || (!this.filename && !this.uploaded_image)){
        return 0
      }
      if(!!this.filename && !this.uploaded_image){
        return 1
      }
      return 2
    }
  },
  methods: {
    // ユーザー画像
    onUserFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;

      // ファイルチェック(/common/mixins/upload_file_check.jsを参照)
      let image_file_error = this.checkUploadImage(files[0]);
      if (image_file_error != ''){
        // エラーの場合はトースト表示
        this.$bvToast.toast(image_file_error, { variant: 'danger', title: 'エラー'});
        return;
      }

      this.deleted = false;
      this.createUserImage(files[0]);
      this.new_image_name = files[0].name;
      this.image_size = files[0].size;
    },
    createUserImage(file) {
      const reader = new FileReader();
      reader.onload = e => {
        this.uploaded_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage() {
      this.deleted = true;
      this.uploaded_image = '';
      this.new_image_name = '';
      this.image_size = '';
      // 一度アップしたものを取り消した後再度同じものをアップすると@changeが発火しないため、refでDOMを直接引っ張ってvalueを消す
      this.$refs.pitchFileUploader.value = '';
    },
    getData(){
      let filename = this.deleted ? '' : this.filename;
      return {
        uploaded_image: this.uploaded_image,
        new_image_name: this.new_image_name,
        image_size: this.image_size,
        image_attributes: {
          id: this.id,
          filename: filename
        }
      }
    },
    dropUserFile(e) {
        this.onUserFileChange(e)
        this.offArea()
    },
    onArea  () { this.inArea = true  },
    offArea () { this.inArea = false },
  }
}
</script>