export default {
  data() {
    return {
      // vue-chart関連
      presentValueColors: [],
      presentValueColorsDefault: {
        bgColor: '#3b82f6',
        datalabelColor: '#FFF',
      },
      presentValueColorsHover: {
        bgColor: '#bfdbfe',
        datalabelColor: '#1e3a8a',
      },
    };
  },
  methods: {
    displayCurrencyUnit(scenario) {
      const isoCode = scenario.currency_unit_master.iso_code;
      if (isoCode === 'JPY') {
        return '単位：千円';
      }
      return `単位：K ${isoCode}`;
    },
    yearColumns(yearsCustom, withColumn = '') {
      const columns = [...Array(yearsCustom)].map((_, i) => i + 1);
      switch (withColumn) {
        case 'current':
          return ['current', ...columns, ];
        case 'last':
          return columns.map((_, i) => columns.length === i + 1 ? 'last' : 'notApplicable');
        default:
          return columns;
      }
    },
    setField(scenario, fieldName, value) {
      this.$emit('replaceScenario', { ...scenario, [fieldName]: value });
    },
    numberConvert(value) {
      const num = Number(value);
      return Number.isNaN(num) ? 0 : num;
    },
    digitSeparator(value) {
      return this.numberConvert(value).toLocaleString();
    },
    // 出資金額(investment_amount)は百万円単位なので、シナリオ詳細表用の単位に揃える
    digitCorrectionInvestmentAmount(scenario) {
      return this.numberConvert(scenario.investment_amount) * 1000;
    },
    // 営業利益の算出
    calcOperatingIncome(scenario) {
      const columns = this.yearColumns(scenario.years_custom);
      const grossProfit = scenario.gross_profit;
      const sgAndA = scenario.sg_and_a;
      const result = {};
      columns.forEach((key) => {
        result[key] = this.numberConvert(grossProfit[key]) - this.numberConvert(sgAndA[key]);
      });
      return result;
    },
    // 税引後営業利益の算出
    calcOperatingProfitAfterTaxes(scenario) {
      const columns = this.yearColumns(scenario.years_custom);
      const operatingIncome = this.calcOperatingIncome(scenario);
      const tax = scenario.tax;
      const result = {};
      columns.forEach((key) => {
        result[key] = this.numberConvert(operatingIncome[key]) - this.numberConvert(tax[key]);
      });
      return result;
    },
    // 運転資本の増減の算出
    calcChangeInWorkingCapitals(scenario) {
      const columns = this.yearColumns(scenario.years_custom);
      const accountsReceivables = scenario.accounts_receivable;
      const inventories = scenario.inventory;
      const accountsPayables = scenario.accounts_payable;
      const advancePayments = scenario.advance_payment;
      const result = {
        assets: {},
        liabilities: {},
      };
      columns.forEach((key) => {
        let beforeKey = key - 1;
        if (beforeKey === 0) beforeKey = 'current';
        // 運転資本の増減（資産項目）
        const beforeAccountsReceivable = this.numberConvert(accountsReceivables[beforeKey]);
        const beforeInventory = this.numberConvert(inventories[beforeKey]);
        const accountsReceivable = this.numberConvert(accountsReceivables[key]);
        const inventory = this.numberConvert(inventories[key]);
        result['assets'][key] = (beforeAccountsReceivable + beforeInventory) - (accountsReceivable + inventory);
        // 運転資本の増減（負債項目）
        const beforeAccountsPayable = this.numberConvert(accountsPayables[beforeKey]);
        const beforeAdvancePayment = this.numberConvert(advancePayments[beforeKey]);
        const accountsPayable = this.numberConvert(accountsPayables[key]);
        const advancePayment = this.numberConvert(advancePayments[key]);
        // -0と表示されるのを回避する為に0を足す
        result['liabilities'][key] = -((beforeAccountsPayable + beforeAdvancePayment) - (accountsPayable + advancePayment)) + 0;
      });
      return result;
    },
    // FreeCashFlowの算出
    calcFreeCashFlows(scenario) {
      const columns = this.yearColumns(scenario.years_custom);
      const operatingProfitAfterTaxes = this.calcOperatingProfitAfterTaxes(scenario);
      const capitalInvestments = scenario.capital_investment;
      const { assets, liabilities } = this.calcChangeInWorkingCapitals(scenario);
      const depreciations = scenario.depreciation;
      const otherNonCashProfitAndLossItems = scenario.other_non_cash_profit_and_loss_items;
      const result = {};
      columns.forEach((key) => {
        const operatingProfitAfterTax = this.numberConvert(operatingProfitAfterTaxes[key]);
        const capitalInvestment = this.numberConvert(capitalInvestments[key]);
        const asset = this.numberConvert(assets[key]);
        const liability = this.numberConvert(liabilities[key]);
        const depreciation = this.numberConvert(depreciations[key]);
        const otherNonCashProfitAndLossItem = this.numberConvert(otherNonCashProfitAndLossItems[key]);
        result[key] = operatingProfitAfterTax + capitalInvestment + asset + liability + depreciation + otherNonCashProfitAndLossItem;
      });
      return result;
    },
    // PERの中央値を算出
    calcPersMedian(scenario) {
      const pers = scenario.pers.map(v => this.numberConvert(v.per)).sort((a, b) => a - b);
      const m = (pers.length / 2) | 0;
      if (pers.length % 2) {
        return pers[m];
      }
      return (pers[m - 1] + pers[m]) / 2;
    },
    // ExitValueの算出
    calcExitValue(scenario) {
      const netIncomeAfterTax = this.numberConvert(scenario.net_income_after_tax['last']);
      const persMedian = this.calcPersMedian(scenario);

      return netIncomeAfterTax * persMedian;
    },
    // 小計の算出
    calcSubtotals(scenario) {
      const columns = this.yearColumns(scenario.years_custom);
      const freeCashFlows = this.calcFreeCashFlows(scenario);
      const result = {};
      columns.forEach((key) => {
        result[key] = scenario.years_custom === key ? this.calcExitValue(scenario) + freeCashFlows[key] : freeCashFlows[key];
      });
      return result;
    },
    // 現価係数の算出
    calcPresentValueFactors(scenario) {
      const columns = this.yearColumns(scenario.years_custom);
      const expectedReturn = this.numberConvert(scenario.expected_return) / 100;
      const result = {};
      const digitRound = (numValue) => Math.round(numValue * 100) / 100;
      columns.forEach((key) => {
        if (key === 1) {
          const fiscalMonth = scenario.fiscal_month;
          const valuationMonth = new Date(scenario.valuation_date).getMonth() + 1;
          let untilsFiscalMonth = fiscalMonth - valuationMonth;
          if (untilsFiscalMonth <= 0) untilsFiscalMonth += 12;
          result[key] = digitRound(1 / (1 + expectedReturn) ** (untilsFiscalMonth / 12));
        } else {
          result[key] = digitRound(result[key - 1] / (1 + expectedReturn));
        }
      });
      return result;
    },
    // PresentValueの算出
    calcPresentValues(scenario) {
      const columns = this.yearColumns(scenario.years_custom);
      const subtotals = this.calcSubtotals(scenario);
      const presentValueFactors = this.calcPresentValueFactors(scenario);
      const result = {};
      columns.forEach((key) => {
        result[key] = Math.round(subtotals[key] * presentValueFactors[key]);
      });
      return result;
    },
    // 有利子負債（純額）の算出
    calcInterestBearingDebtNet(scenario) {
      return this.numberConvert(scenario.interest_bearing_debt['last']) - this.numberConvert(scenario.c_and_ce['last']);
    },
    // 株式価値の算出
    calcStockValue(scenario) {
      return this.calcPresentValues(scenario)[scenario.years_custom] - this.calcInterestBearingDebtNet(scenario);
    },
    // Valuationの算出
    calcValuation(scenario) {
      const presentValue = this.calcPresentValues(scenario)[scenario.years_custom];
      const liquidityDiscount = this.numberConvert(scenario.liquidity_discount);
      return presentValue * ((100 - liquidityDiscount) / 100);
    },
    // シェアの算出
    calcShare(scenario) {
      const investmentAmount = this.digitCorrectionInvestmentAmount(scenario);
      const valuation = this.calcValuation(scenario);
      // バリュエーション計算の仕方によって算出方法変える
      switch (scenario.valuation_calculation_type) {
        case 'pre_calc':
          return investmentAmount / (valuation + investmentAmount);
        case 'post_calc':
        default:
          return investmentAmount / valuation;
      }
    },
    // Valuationを百万円表示にして算出
    calcValuationWithMillion(scenario) {
      const valuation = this.calcValuation(scenario);
      // *　1000で円に戻し、/　1000000で百万円にし、小数点第2位で四捨五入
      // Math.round(valuation * 1000 / 1000000 * 10) / 10 の省略
      return (Math.round(valuation / 100) / 10);
    },

    // vue-chart関連
    presentValuesBarOptions(scenario) {
      return {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            display: true,
            gridLines: {
              display: false,
            },
            stacked: true,
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.displayCurrencyUnit(scenario),
            },
            display: true,
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 10,
              callback: (value) => this.digitSeparator(value),
            },
            stacked: true,
          }],
        },
        onHover: (_event, elements) => {
          const someChanged = this.presentValueColors.some(v => v.bgColor === this.presentValueColorsHover.bgColor);
          if (!elements.length && someChanged) {
            this.presentValueColors = this.yearColumns(scenario.years_custom).map(() => this.presentValueColorsDefault);
          }
        },
        legend: {
          position: 'right',
          onClick: () => {},
          labels: {
            filter: v => v.datasetIndex <= 2,
          },
        },
        tooltips: {
          position: 'custom',
          callbacks: {
            label: (item, object) => {
              const colorIndex = item.index > 0 ? item.index - 1 : item.datasetIndex - 3;
              const pvc = this.presentValueColors[colorIndex];
              const unchanged = !pvc || pvc.bgColor === this.presentValueColorsDefault.bgColor;
              if (unchanged) {
                this.presentValueColors = this.yearColumns(object.labels.length - 1).map((_, i) => {
                  return i === colorIndex ? this.presentValueColorsHover : this.presentValueColorsDefault;
                });
              }
              return `${object.datasets[item.datasetIndex].label}: ${this.numberConvert(item.value).toLocaleString()}`;
            },
          },
        },
      };
    },
    presentValuesBarChartData(scenario) {
      const columns = this.yearColumns(scenario.years_custom, 'current');
      const labels = columns.map(key => key === 'current' ? '現在' : `${key}年後`);
      const freeCashFlows = [0, ...Object.values(this.calcFreeCashFlows(scenario))];
      const exitValues = columns.map(key => key == scenario.years_custom ? this.calcExitValue(scenario) : 0);
      const getColor = (index) => this.presentValueColors[index] || this.presentValueColorsDefault;
      const presentValues = Object.values(this.calcPresentValues(scenario)).map((v, i) => ({
        label: `Present Value ${i + 1}年後`,
        data: [v],
        backgroundColor: getColor(i).bgColor,
        borderColor: '#FFF',
        borderWidth: 2,
        datalabels: {
          color: getColor(i).datalabelColor,
          formatter: v => v ? this.digitSeparator(v) : '',
        },
      }));

      return {
        labels,
        datasets: [
          {
            label: 'Free Cash Flow',
            data: freeCashFlows,
            backgroundColor: '#22c55e',
            borderColor: '#FFF',
            borderWidth: 2,
            datalabels: {
              color: '#FFF',
              formatter: v => v ? this.digitSeparator(v) : '',
            },
          },
          {
            label: 'Exit Value',
            data: exitValues,
            backgroundColor: '#f97316',
            borderColor: '#FFF',
            borderWidth: 2,
            datalabels: {
              color: '#FFF',
              formatter: v => v ? this.digitSeparator(v) : '',
            },
          },
          {
            label: 'Present Value',
            data: [0],
            backgroundColor: '#3b82f6',
            borderColor: '#FFF',
            borderWidth: 2,
            datalabels: {
              formatter: () => '',
            },
          },
          ...presentValues,
        ],
      };
    },

    workingCapitalsBarOptions(scenario) {
      return {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            display: true,
            gridLines: {
              display: false,
            },
            stacked: true,
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.displayCurrencyUnit(scenario),
            },
            display: true,
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 10,
              callback: (value) => this.digitSeparator(value),
            },
            stacked: true,
          }],
        },
        legend: {
          position: 'right',
          onClick: () => {},
          labels: {
            filter: v => v.text !== 'empty',
          },
        },
        tooltips: {
          position: 'custom',
          filter: (item) => item.datasetIndex !== 1,
          callbacks: {
            label: (item, object) => {
              return `${object.datasets[item.datasetIndex].label}: ${this.numberConvert(item.value).toLocaleString()}`;
            },
          },
        },
      };
    },
    workingCapitalsBarChartData(scenario, mode) {
      const columns = this.yearColumns(scenario.years_custom, 'current');
      const labels = columns.map(key => key === 'current' ? '現在' : [`増減${key}年目`, `${key}年後`]).flat();
      const plusMinusItems = {
        resourceLabel: '',
        plusLabel: '',
        minusLabel: '',
        resourceColor: '',
        plusColor: '',
        minusColor: '',
      };
      let firstResources;
      let secondResources;
      switch (mode) {
        case 'assets':
          plusMinusItems.resourceLabel = '資産';
          plusMinusItems.plusLabel = '資産増';
          plusMinusItems.minusLabel = '資産減';
          plusMinusItems.resourceColor = '#047857';
          plusMinusItems.plusColor = '#34d399';
          plusMinusItems.minusColor = '#fb7185';

          firstResources = scenario.accounts_receivable;
          secondResources = scenario.inventory;
          break;
        case 'liabilities':
          plusMinusItems.resourceLabel = '負債';
          plusMinusItems.plusLabel = '負債増';
          plusMinusItems.minusLabel = '負債減';
          plusMinusItems.resourceColor = '#b91c1c';
          plusMinusItems.plusColor = '#fb7185';
          plusMinusItems.minusColor = '#34d399';

          firstResources = scenario.accounts_payable;
          secondResources = scenario.advance_payment;
          break;
        default:
          return;
      };

      const resources = columns.map(key => this.numberConvert(firstResources[key]) + this.numberConvert(secondResources[key]));
      let showResources = [];
      let emptyItems = [0];
      let increaseItems = [0];
      let decreaseItems = [0];
      const pushItems = (empty, inc, dec) => {
        emptyItems.push(empty);
        increaseItems.push(inc);
        decreaseItems.push(dec);
      };
      for (let i = 0; i < resources.length; ++i) {
        const before = resources[i];
        const after = resources[i + 1];
        showResources.push(i === 0 ? before : [0, before]);
        if (after == null) break;

        const plusMinus = after - before;
        switch (Math.sign(plusMinus)) {
          case 0:
            pushItems(0, 0, 0);
            break;
          case 1:
            pushItems(before, plusMinus, 0);
            break;
          case -1:
            pushItems(after, 0, Math.abs(plusMinus));
            break;
        }
        pushItems(0, 0, 0);
      }
      showResources = showResources.flat();

      return {
        labels: labels,
        datasets: [
          {
            label: plusMinusItems.resourceLabel,
            data: showResources,
            backgroundColor: plusMinusItems.resourceColor,
            datalabels: {
              color: '#FFF',
              formatter: v => v ? this.digitSeparator(v) : '',
            },
          },
          {
            label: 'empty',
            data: emptyItems,
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            datalabels: {
              color: '#FFF',
              formatter: () => '',
            },
          },
          {
            label: plusMinusItems.plusLabel,
            data: increaseItems,
            backgroundColor: plusMinusItems.plusColor,
            datalabels: {
              color: '#FFF',
              formatter: v => v ? this.digitSeparator(v) : '',
            },
          },
          {
            label: plusMinusItems.minusLabel,
            data: decreaseItems,
            backgroundColor: plusMinusItems.minusColor,
            datalabels: {
              color: '#FFF',
              formatter: v => v ? `-${this.digitSeparator(v)}` : '',
            },
          },
        ],
      };
    },
  },
};
