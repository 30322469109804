<template>
  <fragment>
    <div v-if="timeline.length > 0" class="message-thread-wrap">
      <message-item v-for="message in timeline"
        @onEditMessage="onEditMessage"
        @onDeleteMessage="onDeleteMessage"
        @onEditCancel="onEditCancel"
        @onApproval="onApproval"
        @onPass="onPass"
        @onChallenge="onChallenge"
        @onLatestInfo="onLatestInfo"
        @patchMessage="patchMessage"
        @update:editMessage="edit_message=$event"
        :key="message.reset_key + message.id"
        :message.sync="message"
        :login="login"
        :edit-message.sync="edit_message"
        :errors="errors"
        :was-patch-valid="was_patch_valid"
      />
    </div>
    <!-- メッセージ-->
    <div v-if="creatable" class="textarea-container-wrap">
      <div class="textarea-container mb-2">
        <form @submit.prevent="postMessage">
          <textarea class="white-block form-control auto-resize" placeholder="メッセージを書く..." v-model="message" ref="message"></textarea>
          <button class="button-square btn-send" type="submit">送信</button>
        </form>
        <div class="invalid-feedback d-block" v-if="was_post_valid && Object.keys(errors).length > 0">
          <span v-for="(error, key) in errors" :key="key">{{error[0]}}</span>
        </div>
      </div>
    </div>
    <!-- メッセージ-->

    <ConfirmModal
      ref="delete_confirm_modal"
      :onClickSend="deleteMessage"
      :body_text="'このメッセージを削除します。<br>よろしいですか？'"
      :send_button_text="'削除する'"
      :isNoFade="isNoFade"
    />
    <ConfirmModal
      ref="approval_confirm_modal"
      :onClickSend="onApprovalConfirmSend"
      :body_text="'オファーを承認します。<br>決定後は変更できません。<br>本当によろしいですか？'"
      :send_button_text="'OK'"
      :isNoFade="isNoFade"
    />
    <ConfirmModal
      ref="pass_confirm_modal"
      :onClickSend="onPassConfirmSend"
      :body_text="'オファーを見送ります。<br>決定後は変更できません。<br>本当によろしいですか？'"
      :send_button_text="'OK'"
      :isNoFade="isNoFade"
    />
  </fragment>
</template>

<script>
import impl from './room_impl'

export default impl
</script>
