<template>
  <b-modal
    v-model="is_show"
    class="modal fade"
    id="scenario-upload"
    tabindex="-1"
    role="dialog"
    aria-labelledby="scenario-upload-Label"
    hide-header
    centered
    size="sm"
  >
    <div class="mt-3 text-center sm:mt-5">
      <h3 class="text-base font-semibold leading-6 text-gray-900" id="headlessui-dialog-title-:rm:" data-headlessui-state="open">CSV アップロード</h3>
      <div class="mt-2">
        <p class="text-sm text-gray-500">CSVアップロードしてデータを上書きします。</p>
      </div>
    </div>
    <div class="m-3">
      <div
        class="file-up-block"
        role="presentation"
        tabindex="0"
        style="cursor: pointer;"
        @dragover.prevent
        @drop="handleDrop"
        @click="$refs.fileInput.click()"
      >
        <input
          ref="fileInput"
          type="file"
          class="file-input"
          style="display: none;"
          accept="text/csv"
          @change="handleFileChange"
        >
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"></path>
          </svg>
          <button>ファイルを選択</button>
        </div>
      </div>
      <div v-if="uploadErrors.length">
        <p
          v-for="(error, i) in uploadErrors"
          :key="`scenario_upload_errors_${i}`"
          class="invalid-feedback d-block"
        >
          {{ error }}
        </p>
      </div>
    </div>
    <template v-slot:modal-footer>
      <button
        class="btn btn-cancel btn-cancel-bind"
        type="button"
        @click="close"
      >キャンセル</button>
    </template>
  </b-modal>
</template>

<script>
  export default {
    props: {
      uploadErrors: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        is_show: false,
      }
    },
    methods: {
      open() {
        this.$emit('uploadErrorsClear');
        this.is_show = true;
      },
      close() {
        this.is_show = false;
      },
      handleFileChange(event) {
        const selectedFile = event.target.files[0];
        this.$emit('onUploadFile', selectedFile);
      },
      handleDrop(event) {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files[0];
        this.$emit('onUploadFile', droppedFile);
      },
    },
  }
</script>
<style src="../tailwind.css" scoped />
<style lang="scss">
  .btn-cancel-bind {
    background: #a0a0a0 !important;
    color: #fff !important;
    padding: 6px 24px !important;
    &:hover {
      background: #878787 !important;
    }
  }
</style>