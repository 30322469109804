<template>
  <main>
    <div class="one-col-box white-block">
      <div class="header">
        <h1 class="logo"><img v-bind:src="require('../../../images/logo.svg')" alt="six brain"></h1>
        <h2 class="mb-4">ログイン</h2>
      </div>
      <div class="body">
        <div class="alert alert-danger" role="alert" v-if="isLocked">アカウントがロックされました。時間をおいて再度お試しください。</div>
        <div class="alert alert-danger" role="alert" v-if="error">メールアドレスまたはパスワードが間違っています。<br><router-link to="password/reset" class="alert-link">パスワードをお忘れですか？</router-link></div>
        <div class="alert alert-danger" role="alert" v-if="noDepartment">所属部署が設定されていないためログインできません。</div>
        <form @submit.prevent="onLogin">
          <div class="form-group">
            <div class="input-group icon-addon-right">
              <input class="form-control" v-model="email" name="email" type="text" placeholder="メールアドレス" wovn-ignore />
              <i class="far fa-envelope"></i>
            </div>
          </div>
          <div class="form-group">
            <div class="input-group icon-addon-right">
              <input class="form-control" id="input-password" v-model="password" name="password" type="password" placeholder="パスワードを入力" wovn-ignore/>
            </div>
            <p class="text-right mt-2"><router-link class="text-secondary" to="password/reset">パスワードを忘れました</router-link></p>
          </div>
          <div class="btn-container py-3">
            <button class="button-square btn-send btn-block" type="submit">ログイン</button>
          </div>
        </form>
      </div>
      <div class="footer" v-if="accountType == 'ep'">
        <p class="text-center mt-3"><router-link :to="{ path: '/ep/request_info'}">アカウントを作成しますか？</router-link></p>
      </div>
      <div class="footer" v-if="accountType == 'su'">
        <p class="text-center mt-3"><router-link :to="{ path: '/su/request_flow1_edit'}">アカウントを作成しますか？</router-link></p>
      </div>
    </div>

  </main>
</template>

<script>
export default {
  metaInfo: {
    title: 'ログイン',
    bodyAttrs: {
      class: ['login']
    }
  },
  data () {
    return {
      email: '',
      password: '',
      error: false,
      isLocked: false,
      noDepartment: false,
      selectAccount: false,
      selectUsers: [],
      selectUserId: null,
    }
  },
  props: {
    accountType: {
      type: String,
      required: true,
    }
  },
  methods: {
    onLogin(event){
      this.login();
    },
    login (userId=null) {
      this.error = false;
      this.isLocked = false;
      this.selectAccount = false;

      this.$loading.load(this.$auth.login(this.email, this.password, this.accountType, userId)
        .then(res => {
        console.log(res.data.status);
        if(res.data.status == "OK"){
          if(res.data.login_type == "ep"){
            this.$router.push('./recommends/');
          }else if(res.data.login_type == "su"){
            this.$router.push('./challenges/');
          }else{
            this.$router.push('./');
          }
        }else if(res.data.status == "OTP"){
          this.$router.push({
            path: './verify',
            query: {
              token: res.data.token
            }
          })
        }else if(res.data.status == "reset"){
          this.$router.push({
            path: './password/reset/new_password/' + res.data.token,
            query: {
              admin_reset: "1",
              login_type: res.data.login_type
            }
          })
        }else if(res.data.status == "no_department"){
          this.noDepartment = true;
        }else if(res.data.status == "select"){
          this.selectAccount = true;
          this.selectUsers = res.data.users;
        }
      }).catch(err => {
        if(err.response.status == 401){
          if(err.response.data.status == "locked"){
            this.isLocked = true;
          }else{
            // ログイン失敗
            this.error = true;
          }
        }else{
          this.$errorHandlers.ajax(err)
        }
      }));
    },
    logout () {
  		this.$loading.load(this.$auth.logout()
  			.then(res => {
  			})
	  		.catch(err => {
		  		this.$errorHandlers.initial(err);
			  }));
    },
    onSelectUser(event, user){
      this.login(user.id);
    },
    resetEmail(value, account_type){
      this.$loading.load(this.$auth.api.get('reset_emails', {
        params: {
          value: value,
          account_type: account_type
        }
      }).then(res => {
        if(res.data.status == 'ok'){
          this.$bvToast.toast("メール認証が完了しました。", {
            variant: 'success',
            title: 'メール認証'
          });
        }
      }).catch(err => {
        console.log(err);
        if(err.response.status == 404){
          // ユーザー not found
          this.$errorHandlers.initial(err)
        }else if(err.response.status == 500) {
          // メール重複エラー
          this.$bvToast.toast(err.response.data.error, {
            variant: 'danger',
            title: 'エラー'
          });
        } else {
          this.$errorHandlers.ajax(err);
        }
      }));
    }
  },
  computed: {
  },
  created: function () {
    // ログインしてる場合はログアウト
    if(this.$auth.isLoggedIn){
      this.logout();
    }

    if(this.$route.query.unlocked){
      this.$bvToast.toast("アカウントロックを解除しました。", {
        variant: 'success',
        title: 'アンロック'
      });
    }

    // メール認証
    if(!!this.$route.query.value) {
      this.resetEmail(this.$route.query.value, this.accountType);
    }
  },
  mounted: function(){
  },
  watch: {
  }
}
</script>

<style scoped>
</style>
