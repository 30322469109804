<template>
  <div>
    <button
      class="button-square btn-send btn-sm"
      :class="{ disabled: inEdit }"
      :disabled="inEdit"
      @click="$emit('editClick')"
    >
      編集する
    </button>
    <button
      class="button-square btn-send btn-sm"
      :class="{ disabled: !inEdit }"
      :disabled="!inEdit"
      @click="$emit('onSave')"
    >
      保存する
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      inEdit: {
        type: Boolean,
        required: true,
      },
    },
  };
</script>
