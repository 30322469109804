<template>
  <div>
    <main>
      <section class="main-title d-flex">
        <h1>リコメンド後データ 一覧</h1>
      </section>

      <!-- 検索 -->
      <section class="search-block">
        <form @submit.prevent>
          <div class="accordion" id="serach">
            <div class="form-group card">
              <div class="card-header" id="searach-parent">
                <a data-toggle="collapse" href="#" @click.prevent.stop="" v-b-toggle.searach-toggle-child>検索<i class="fas"></i></a>
              </div>
              <b-collapse id="searach-toggle-child" :visible="InitialSearchSectionOpening" accordion="my-accordion" role="tabpanel">
                <div class="card-body collapse show" id="searach-child">
                  <!-- ステータス -->
                  <fieldset class="form-group">
                    <div class="row">
                      <legend id="model-type-info" class="col-2 col-form-label">ステータス</legend>
                      <div class="col-10 align-self-center">
                        <div
                          v-for="(recommend_answer_statuses_i18n, recommend_answer_status_key) in recommend_answer_statuses"
                          :key="recommend_answer_status_key"
                          class="custom-control custom-checkbox custom-control-inline"
                        >
                          <input
                            v-model="form.recommend_answer_statuses"
                            type="checkbox"
                            class="custom-control-input"
                            :id="'status_' + recommend_answer_status_key"
                            :value="recommend_answer_status_key"
                          />
                          <label :for="'status_' + recommend_answer_status_key" class="custom-control-label">{{
                            recommend_answer_statuses_i18n
                          }}</label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <!-- フィードバック日 -->
                  <div class="form-group row">
                    <label class="col-2 col-form-label" for="">フィードバック日</label>
                    <div class="col-10">
                      <div class="form-group">
                        <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                          <input
                            v-model="form.offer_at_from"
                            class="form-control"
                            id="offer-at-start-date"
                            type="text"
                            data-toggle="datetimepicker"
                            data-target="#offer-at-start-date"
                          /><i class="far fa-calendar-alt"></i>
                        </div>
                        <span class="unit"> ~</span>
                        <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                          <div class="input-group icon-addon-right">
                            <input
                              v-model="form.offer_at_to"
                              class="form-control"
                              id="offer-at-end-date"
                              type="text"
                              data-toggle="datetimepicker"
                              data-target="#offer-at-end-date"
                            /><i class="far fa-calendar-alt"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 大企業 -->
                  <div class="form-group row">
                    <label class="col-2 col-form-label" for="search_ep">大企業</label>
                    <div class="col-10" :class="pick_ep_company_names.length > 0 ? 'incremental-wrap' : ''">
                      <input
                        v-model="form.ep_company_name"
                        @input="onInputEpCompany"
                        @blur="resetPickList"
                        id="search_ep"
                        class="form-control"
                        type="text"
                        placeholder="企業名"
                      />
                      <div class="list-group keyword-list" v-if="pick_ep_company_names.length > 0">
                        <a
                          v-for="pick_ep_company_name in pick_ep_company_names"
                          :key="pick_ep_company_name"
                          class="list-group-item list-group-item-action"
                          @click.prevent.stop="onClickEpCompany(pick_ep_company_name)"
                          href="#"
                        >
                          {{ pick_ep_company_name }}
                        </a>
                      </div>
                    </div>
                  </div>
                  <!-- 部署名 -->
                  <div class="form-group row">
                    <label class="col-2 col-form-label" for="search_department">部署名</label>
                    <div class="col-10" :class="pick_ep_departments.length > 0 ? 'incremental-wrap' : ''">
                      <input
                        v-model="form.ep_department_name"
                        @input="onInputEpDepartment"
                        @blur="resetPickList"
                        id="search_department"
                        class="form-control"
                        type="text"
                        placeholder="部署名"
                      />
                      <div class="list-group keyword-list" v-if="pick_ep_departments.length > 0">
                        <a
                          v-for="pick_ep_department in pick_ep_departments"
                          :key="pick_ep_department"
                          class="list-group-item list-group-item-action"
                          @click.prevent.stop="onClickEpDepartment(pick_ep_department)"
                          href="#"
                        >
                          {{ pick_ep_department }}
                        </a>
                      </div>
                    </div>
                  </div>
                  <!-- チャレンジタイトル -->
                  <div class="form-group row">
                    <label class="col-2 col-form-label" for="search_challenge">チャレンジタイトル</label>
                    <div class="col-10" :class="pick_challenge_titles.length > 0 ? 'incremental-wrap' : ''">
                      <input
                        v-model="form.challenge_title"
                        @input="onInputEpChallengeTitle"
                        @blur="resetPickList"
                        id="search_challenge"
                        class="form-control"
                        type="text"
                        placeholder="チャレンジタイトル"
                      />
                      <div class="list-group keyword-list" v-if="pick_challenge_titles.length > 0">
                        <a
                          v-for="pick_challenge_title in pick_challenge_titles"
                          :key="pick_challenge_title"
                          class="list-group-item list-group-item-action"
                          @click.prevent.stop="onClickChallengeTitle(pick_challenge_title)"
                          href="#"
                        >
                          {{ pick_challenge_title }}
                        </a>
                      </div>
                    </div>
                  </div>
                  <!-- スタートアップ -->
                  <div class="form-group row">
                    <label class="col-2 col-form-label" for="search_su">スタートアップ</label>
                    <div class="col-10" :class="pick_su_company_names.length > 0 ? 'incremental-wrap' : ''">
                      <input
                        v-model="form.su_company_name"
                        @input="onInputSuCompany"
                        @blur="resetPickList"
                        id="search_su"
                        class="form-control"
                        type="text"
                        placeholder="スタートアップ"
                      />
                      <div class="list-group keyword-list" v-if="pick_su_company_names.length > 0">
                        <a
                          v-for="pick_su_company_name in pick_su_company_names"
                          :key="pick_su_company_name"
                          class="list-group-item list-group-item-action"
                          @click.prevent.stop="onClickSuCompany(pick_su_company_name)"
                          href="#"
                        >
                          {{ pick_su_company_name }}
                        </a>
                      </div>
                    </div>
                  </div>
                  <!-- リコメンド種類 -->
                  <fieldset class="form-group">
                    <div class="row">
                      <legend class="col-2 col-form-label">リコメンド種類</legend>
                      <div class="col-10 align-self-center single-check">
                        <div class="main-checkbox d-inline-block">
                          <div
                            v-for="(manual_addition_flgs_i18n, manual_addition_flg_key) in manual_addition_flgs"
                            :key="manual_addition_flg_key"
                            class="custom-control custom-checkbox custom-control-inline"
                          >
                            <input
                              v-model="form.manual_addition_flgs"
                              type="checkbox"
                              class="custom-control-input"
                              :id="'recommend_type_' + manual_addition_flg_key"
                              :value="manual_addition_flg_key"
                            />
                            <label :for="'recommend_type_' + manual_addition_flg_key" class="custom-control-label">
                              {{ manual_addition_flgs_i18n }}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <!-- リコメンド送付日 -->
                  <div class="form-group row">
                    <label class="col-2 col-form-label" for="">リコメンド送付日</label>
                    <div class="col-10">
                      <div class="form-group">
                        <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                          <input
                            v-model="form.recommend_send_at_from"
                            class="form-control"
                            id="recommend-send-at-start-date"
                            type="text"
                            data-toggle="datetimepicker"
                            data-target="#recommend-send-at-start-date"
                          /><i class="far fa-calendar-alt"></i>
                        </div>
                        <span class="unit"> ~</span>
                        <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                          <div class="input-group icon-addon-right">
                            <input
                              v-model="form.recommend_send_at_to"
                              class="form-control"
                              id="recommend-send-at-end-date"
                              type="text"
                              data-toggle="datetimepicker"
                              data-target="#recommend-send-at-end-date"
                            /><i class="far fa-calendar-alt"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <p v-if="acc_headers.length == 0" class="search-no-data">検索条件に一致するものはありません。</p>
                  <div class="btn-container d-flex justify-content-end">
                    <button @click="onSearch" class="button-square btn-send" type="button">検索する</button>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </form>
      </section>

      <!-- リスト-->
      <section class="chapter white-block" v-if="acc_headers.length > 0">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              href="#"
              :class="['nav-link', form.display_switch == 'ep' ? 'active show' : '']"
              @click.prevent.stop="form.display_switch != 'ep' ? onChangeTab('ep') : ''"
              data-toggle="tab"
            >
              チャレンジごとに表示
            </a>
          </li>
          <li class="nav-item">
            <a
              href="#"
              :class="['nav-link', form.display_switch == 'su' ? 'active show' : '']"
              @click.prevent.stop="form.display_switch != 'su' ? onChangeTab('su') : ''"
              data-toggle="tab"
            >
              スタートアップごとに表示
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <!-- チャレンジごとに表示 -->
          <div v-if="form.display_switch == 'ep'" class="tab-pane active" id="challenge">
            <div class="table-fixed-container recommend">
              <table class="table mb-0 table-fixed-head">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%"></th>
                    <th style="width: 17%">大企業名</th>
                    <th style="width: 40%">チャレンジタイトル</th>
                    <th style="width: 18%">チャレンジ作成日</th>
                    <th style="width: 22%">オファー等送付率</th>
                  </tr>
                </thead>
              </table>

              <table class="table table-bordered accordion-wrap recommend-list"
                v-for="(challenge, index) in acc_headers" :key="`${challenge.ep_company_id}-${challenge.id}`"
              >
                <!-- アコーディオンヘッダ部 -->
                <thead
                  :class="['js-accordion-header', 'accordion-header', challenge.is_active ? 'active' : '']"
                  @click="accordionToggle(index)"
                >
                  <tr class="recommend-list-header table-success">
                    <th style="width: 3%"></th>
                    <!-- 大企業名 -->
                    <th style="width: 17%">{{ challenge.company_name }} {{ challenge.department_name }}</th>
                    <!-- チャレンジタイトル -->
                    <th colspan="2" style="width: 40%">
                      <a
                        :id="'challenge_title_' + challenge.id"
                        class="popover-trigger text-ellipsis"
                        tabindex="0"
                        data-content=""
                        data-original-title=""
                        title=""
                        @click.prevent.stop="onShowEpChallenge(challenge.provisional_ep_challenge_id)"
                        href="#"
                      >
                        {{ challenge.title | omittedText(25) }}
                      </a>
                      <b-popover
                        v-if="!!challenge.title && challenge.title.length > 25"
                        :target="'challenge_title_' + challenge.id"
                        triggers="hover"
                        placement="auto"
                      >
                        <div
                          style="margin: -1rem -1.5em; padding: 1rem 1.5em; position: relative; z-index: 1;"
                          @click="$root.$emit('bv::hide::popover')"
                          >
                          <span :inner-html.prop="challenge.title | htmlEscape | nl2br" ></span>
                        </div>
                      </b-popover>
                    </th>
                    <!-- チャレンジ作成日 -->
                    <th style="width: 18%">{{ challenge.challenge_created_at | format.date }}</th>
                    <!-- オファー等送付率 -->
                    <th style="width: 11%">{{ challenge.offer_numerator }}/{{ challenge.offer_denominator }}</th>
                    <th style="width: 11%"></th>
                  </tr>
                </thead>

                <tbody class="accordion-body" v-if="challenge.is_active">
                  <tr class="table-warning">
                    <td class="p-0"><img class="m-auto" src="~/images/icon_right.svg" /></td>
                    <td>ステータス</td>
                    <td>フィードバックコメント</td>
                    <td>スタートアップ<br />企業名</td>
                    <td>最新情報</td>
                    <td>フィード<br>バック日</td>
                    <td>リコメンド<br>送付日</td>
                  </tr>

                  <!-- アコーディオン要素部 -->
                  <tr v-for="after_recommend in challenge.after_recommends" :key="after_recommend.ep_recommend_id">
                    <td></td>
                    <!-- ステータス -->
                    <td>
                      <a @click.prevent.stop="onShowOffer(after_recommend.ep_recommend_id)"
                        href="#"
                      >
                        {{ after_recommend.recommend_answer_status }}
                      </a>
                    </td>
                    <!-- オファーコメント -->
                    <td>
                      <span :id="'offer_title_' + after_recommend.ep_recommend_id">
                        {{ after_recommend.feedback_comment | omittedText(25) }}
                      </span>
                      <b-popover
                        v-if="!!after_recommend.feedback_comment && after_recommend.feedback_comment.length > 25"
                        :target="'offer_title_' + after_recommend.ep_recommend_id"
                        triggers="hover"
                        placement="auto"
                      >
                        <div
                          style="margin: -1rem -1.5em; padding: 1rem 1.5em; position: relative; z-index: 1;"
                          @click="$root.$emit('bv::hide::popover')"
                        >
                          <span :inner-html.prop="after_recommend.feedback_comment | htmlEscape | nl2br" ></span>
                        </div>
                      </b-popover>
                    </td>
                    <!-- スタートアップ企業名 -->
                    <td>
                      <span class="badge badge-sm badge-info" v-if="after_recommend.is_manual_add">マニュアル追加</span>
                      <p>{{ after_recommend.su_company_name}}</p>
                    </td>
                    <!-- 最新情報 -->
                    <td>
                      <a
                        :id="'latest_info_title_' + after_recommend.ep_recommend_id"
                        class="popover-trigger text-ellipsis"
                        tabindex="0"
                        data-content=""
                        data-original-title=""
                        title=""
                        @click.prevent.stop="onShowSuLatestInfo(after_recommend.provisional_su_latest_info_id)"
                        href="#"
                      >
                        {{ after_recommend.latest_info_title | omittedText(25) }}
                      </a>
                      <b-popover
                        v-if="!!after_recommend.latest_info_title && after_recommend.latest_info_title.length > 25"
                        :target="'latest_info_title_' + after_recommend.ep_recommend_id"
                        triggers="hover"
                        placement="auto"
                      >
                        <div
                          style="margin: -1rem -1.5em; padding: 1rem 1.5em; position: relative; z-index: 1;"
                          @click="$root.$emit('bv::hide::popover')"
                          >
                          <span :inner-html.prop="after_recommend.latest_info_title | htmlEscape | nl2br" ></span>
                        </div>
                      </b-popover>
                    </td>
                    <!-- フィードバック日 -->
                    <td>{{ after_recommend.offer_created_at | format.date }}</td>
                    <!-- リコメンド日 -->
                    <td>{{ after_recommend.recommended_at | format.date }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--/pager-->
            <Pagination
              :total_page="total_page"
              :total_count="total_count"
              :current_page="current_page"
              :current_per="current_per"
              :page_link_num="page_link_num"
              :onChangePage="onChangePage"
            />
          </div>

          <!--スタートアップ単位-->
          <div v-if="form.display_switch == 'su'" class="tab-pane active" id="startup">
            <div class="table-fixed-container recommend">
              <table class="table mb-0 table-fixed-head">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%"></th>
                    <th style="width: 75%">スタートアップ企業名</th>
                    <th style="width: 11%">オファー受取率</th>
                    <th style="width: 11%"></th>
                  </tr>
                </thead>
              </table>

              <table class="table table-bordered accordion-wrap recommend-list"
                v-for="(su_company, index) in acc_headers" :key="su_company.id"
              >

                <!-- アコーディオンヘッダ部 -->
                <thead
                  :class="['js-accordion-header', 'accordion-header', su_company.is_active ? 'active' : '']"
                  @click="accordionToggle(index)"
                >
                  <tr class="recommend-list-header table-success">
                    <th style="width: 3%"></th>
                    <!-- スタートアップ企業名 -->
                    <th colspan="5" style="width: 75%">{{ su_company.company_name }}</th>
                    <!-- オファー受取率 -->
                    <th style="width: 11%">{{ su_company.offer_numerator }}/{{ su_company.offer_denominator }}</th>
                    <th style="width: 11%"></th>
                  </tr>
                </thead>

                <tbody class="accordion-body" v-if="su_company.is_active">
                  <tr class="table-warning">
                    <td class="p-0"><img class="m-auto" src="~/images/icon_right.svg" /></td>
                    <td>ステータス</td>
                    <td>最新情報</td>
                    <td>フィードバック<br>コメント</td>
                    <td>大企業名</td>
                    <td>チャレンジ</td>
                    <td>フィード<br>バック日</td>
                    <td>リコメンド<br>送付日</td>
                  </tr>

                  <!-- アコーディオン要素部 -->
                  <tr v-for="after_recommend in su_company.after_recommends" :key="after_recommend.ep_recommend_id">
                    <td></td>
                    <!-- ステータス -->
                    <td>
                      <a @click.prevent.stop="onShowOffer(after_recommend.ep_recommend_id)"
                        href="#"
                      >
                        {{ after_recommend.recommend_answer_status }}
                      </a>
                    </td>
                    <!-- 最新情報 -->
                    <td>
                      <a
                        :id="'latest_info_title_' + after_recommend.ep_recommend_id"
                        class="popover-trigger text-ellipsis"
                        tabindex="0"
                        data-content=""
                        data-original-title=""
                        title=""
                        @click.prevent.stop="onShowSuLatestInfo(after_recommend.provisional_su_latest_info_id)"
                        href="#"
                      >
                        {{ after_recommend.latest_info_title | omittedText(25) }}
                      </a>
                      <b-popover
                        v-if="!!after_recommend.latest_info_title && after_recommend.latest_info_title.length > 25"
                        :target="'latest_info_title_' + after_recommend.ep_recommend_id"
                        triggers="hover"
                        placement="auto"
                      >
                        <div
                          style="margin: -1rem -1.5em; padding: 1rem 1.5em; position: relative; z-index: 1;"
                          @click="$root.$emit('bv::hide::popover')"
                          >
                          <span :inner-html.prop="after_recommend.latest_info_title | htmlEscape | nl2br" ></span>
                        </div>
                      </b-popover>
                    </td>
                    <!-- フィードバックコメント -->
                    <td>
                      <span :id="'offer_title_' + after_recommend.ep_recommend_id">
                        <!-- 提案歓迎の場合は提案歓迎を表示 -->
                        {{ after_recommend.feedback_comment | omittedText(25) }}
                      </span>
                      <b-popover
                        v-if="!!after_recommend.feedback_comment && after_recommend.feedback_comment.length > 25"
                        :target="'offer_title_' + after_recommend.ep_recommend_id"
                        triggers="hover"
                        placement="auto"
                      >
                        <div
                          style="margin: -1rem -1.5em; padding: 1rem 1.5em; position: relative; z-index: 1;"
                          @click="$root.$emit('bv::hide::popover')"
                        >
                          <span :inner-html.prop="after_recommend.feedback_comment | htmlEscape | nl2br" ></span>
                        </div>
                      </b-popover>
                    </td>
                    <!-- 大企業名 -->
                    <td>
                      <span class="badge badge-sm badge-info" v-if="after_recommend.is_manual_add">マニュアル追加</span>
                      <p>{{ after_recommend.ep_company_name }}<br />{{ after_recommend.ep_department_name }}</p>
                    </td>
                    <!-- チャレンジ -->
                    <td>
                      <a
                        :id="'challenge_title_' + after_recommend.ep_recommend_id"
                        class="popover-trigger text-ellipsis"
                        tabindex="0"
                        data-content=""
                        data-original-title=""
                        title=""
                        @click.prevent.stop="onShowEpChallenge(after_recommend.provisional_ep_challenge_id)"
                        href="#"
                      >
                        {{ after_recommend.challenge_title | omittedText(25) }}
                      </a>
                      <b-popover
                        v-if="!!after_recommend.challenge_title && after_recommend.challenge_title.length > 25"
                        :target="'challenge_title_' + after_recommend.ep_recommend_id"
                        triggers="hover"
                        placement="auto"
                      >
                        <div
                          style="margin: -1rem -1.5em; padding: 1rem 1.5em; position: relative; z-index: 1;"
                          @click="$root.$emit('bv::hide::popover')"
                          >
                          <span :inner-html.prop="after_recommend.challenge_title | htmlEscape | nl2br" ></span>
                        </div>
                      </b-popover>
                    </td>
                    <!-- フィードバック日 -->
                    <td>{{ after_recommend.offer_created_at | format.date }}</td>
                    <!-- リコメンド日 -->
                    <td>{{ after_recommend.recommended_at | format.date}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--pager-->
            <Pagination
              :total_page="total_page"
              :total_count="total_count"
              :current_page="current_page"
              :current_per="current_per"
              :page_link_num="page_link_num"
              :onChangePage="onChangePage"
            />
          </div>
        </div>
        <!-- tab-content-->
      </section>
      <!-- リスト-->
    </main>
  </div>
</template>

<script>
  import form from '../../../common/mixins/form.js'
  // 初期表示時、検索モーダル開閉の判定
  import InitialSearchSectionOpening  from '../../../common/mixins/initial_serch_section_opening'
  // ページネーションのコンポーネント読み込み
  import Pagination from '../../../common/pages/Pagination.vue';

  export default {

    mixins: [form, InitialSearchSectionOpening],

    components: {
      Pagination,
    },

    metaInfo: {
      title: "リコメンド後データ 一覧",
    },

    data() {
      return {
        // 現在ページ数
        current_page: Number(this.$route.query.page) || 1,
        // 現在の表示件数
        current_per: 0,
        // 総ページ数
        total_page: 0,
        // 総レコード数
        total_count: 0,
        // 表示するリンクの数
        page_link_num: 5,
        // ステータス
        recommend_answer_statuses: [],
        // マニュアル追加フラグ
        manual_addition_flgs: [],
        // 検索条件のサジェスト用データ
        suggest_ep_company_names: [],
        suggest_ep_departments: [],
        suggest_challenge_titles: [],
        suggest_su_company_names: [],
        // サジェストで表示する候補一覧
        pick_ep_company_names: [],
        pick_ep_departments: [],
        pick_challenge_titles: [],
        pick_su_company_names: [],
        // アコーディオンヘッダ情報
        acc_headers: [],
        form: {
          // 表示単位スイッチ（'ep' or 'su'）
          display_switch: this.$route.query.display_switch || 'ep',
          // 検索条件
          recommend_answer_statuses: this.$route.query.recommend_answer_statuses || [],
          offer_at_from: this.$route.query.offer_at_from || '',
          offer_at_to: this.$route.query.offer_at_to || '',
          ep_company_id: this.$route.query.ep_company_id || '',
          ep_company_name: this.$route.query.ep_company_name || '',
          ep_department_name: this.$route.query.ep_department_name || '',
          su_company_name: this.$route.query.su_company_name || '',
          challenge_title: this.$route.query.challenge_title || '',
          manual_addition_flgs: this.$route.query.manual_addition_flgs || [],
          recommend_send_at_to: this.$route.query.recommend_send_at_to || '',
          recommend_send_at_from: this.$route.query.recommend_send_at_from || '',
          // 表示件数
          per: this.$route.query.per || '',
          // ページ番号
          page: this.$route.query.page || '',
          // アコーディオンヘッダキー
          acc_header_id: this.$route.query.acc_header_id || ''
        },
      };
    },

    filters: {

      // 文字列を省略して表示
      omittedText(text, len) {
        if(text === undefined || text === null){
          return '';
        }else{
          return text.length > len ? text.slice(0, len) + "…" : text;
        }
      },

    },

    methods: {
      // 初期化
      init() {
        this.$loading.load(
          this.$auth.api
            .get('admin/after_recommends/init_index')
            .then((response) => {
              // 検索条件のステータスチェックボックス一覧と各サジェストデータを取得
              this.recommend_answer_statuses = response.data.recommend_answer_statuses;
              this.suggest_ep_company_names = response.data.suggest_ep_company_names;
              this.suggest_ep_departments = response.data.suggest_ep_departments;
              this.suggest_challenge_titles = response.data.suggest_challenge_titles;
              this.suggest_su_company_names = response.data.suggest_su_company_names;
              this.manual_addition_flgs = response.data.manual_addition_flgs;
            })
            .catch((err) => {
              this.$errorHandlers.initial(err);
            })
        );
      },

      // アコーディオンヘッダ部の情報を取得
      fetchAccordionHeaders(query) {
        this.$loading.load(
          this.$auth.api
            .get('admin/after_recommends', {
              params: query,
            })
            .then((res) => {
              this.acc_headers  = res.data.acc_headers;
              this.current_per  = Number(res.data.per);
              this.current_page = Number(res.data.page);
              this.total_page = res.data.total_page;
              this.total_count = res.data.total_count;
            })
            .catch((err) => {
              this.$errorHandlers.initial(err);
            })
        );
      },

      // ページ切り替え時
      onChangePage(page, per) {
        let query = Object.assign({}, this.$route.query);
        query.acc_header_id = '';
        query.ep_company_id = '';
        query.page = this.current_page = page;
        query.per = this.current_per = per;
        // 画面更新
        this.reload(query);
      },

      // アコーディオンヘッダ部押下時
      // @param 押下したアコーディオンヘッダ情報のインデックス
      accordionToggle(index){
        let acc_header = this.acc_headers[index];

        if (!!acc_header.after_recommends && acc_header.after_recommends.length != 0){
          // データ取得済みの場合、アコーディオンの開閉のみ実施
          acc_header.is_active = !acc_header.is_active;
          // 配列内のデータを更新した場合はVueが変更を検知せず再描画されないので$setを使う
          this.$set(this.acc_headers, index, acc_header);
        }
        else {
          this.getIndexAccordionBody(acc_header, index);
        }
      },

      getIndexAccordionBody(acc_header, index) {
        let query = this.$route.query;
        query.acc_header_id = acc_header.id;
        if (this.form.display_switch === 'ep') {
          query.ep_company_id = acc_header.ep_company_id;
        }
        // アコーディオン要素部の情報を取得
        this.$loading.load(
          this.$auth.api
            .get("admin/after_recommends/index_accordion_body", {
              // パラメータとしてヘッダ部のキーIDと入力フォーム情報を渡す
              params: query,
            })
            .then((res) => {
              // 取得した情報を格納
              acc_header.after_recommends = res.data.after_recommends;
              // アコーディオンを開く
              acc_header.is_active = true
              // 配列内のデータを更新した場合はVueが変更を検知せず再描画されないので$setを使う
              this.$set(this.acc_headers, index, acc_header)
            })
            .catch((err) => {
              this.$errorHandlers.initial(err);
            })
        );
      },

      // 検索ボタン押下時
      onSearch() {
        this.form.acc_header_id = '';
        this.form.ep_company_id = '';
        this.form.page = this.current_page = 1;
        this.form.per = this.current_per;
        // 画面更新
        this.reload(this.form);
      },

      // 表示単位タブ押下時
      onChangeTab(val = 'ep') {
        this.form.acc_header_id = '';
        this.form.ep_company_id = '';
        this.form.page = this.current_page = 1;
        this.form.per = this.current_per;
        this.form.display_switch = val;
        // 画面更新
        this.reload(this.form);
      },

      // 更新処理
      reload(query = []) {
        this.$router.push({ name: 'AdminAfterRecommendIndex', query: query }).catch((err) => {});
      },

      // チャレンジタイトル押下時
      onShowEpChallenge(provisional_ep_challenge_id){
        // 新しいタブでEpチャレンジの詳細画面に遷移
        let routeData = this.$router.resolve({name: 'AdminProvisionalEpChallengeShow', params: { id: provisional_ep_challenge_id } });
        window.open(routeData.href, '_new');
      },

      // 最新情報タイトル押下時
      onShowSuLatestInfo(provisional_su_latest_info_id){
        let routeData = this.$router.resolve({name: 'AdminProvisionalSuLatestInfoShow', params: { id: provisional_su_latest_info_id } });
        window.open(routeData.href, '_new');
      },

      // オファーコメント押下時
      onShowOffer(ep_recommend_id){
        // リコメンド後データ詳細画面へ遷移
        this.$router.push({name: 'AdminAfterRecommendShow', params: {ep_recommend_id: ep_recommend_id}})
      },

      // 大企業サジェスト選択時
      onClickEpCompany(ep_company) {
        // 候補一覧をリセット
        this.pick_ep_company_names = [];
        // 選択した企業名を入力フォームにセット
        this.form.ep_company_name = ep_company;
      },

      // 部署サジェスト選択時
      onClickEpDepartment(ep_department) {
        // 候補一覧をリセット
        this.pick_ep_departments = [];
        // 選択した部署名を入力フォームにセット
        this.form.ep_department_name = ep_department;
      },

      // チャレンジサジェスト選択時
      onClickChallengeTitle(challenge_title) {
        // 候補一覧をリセット
        this.pick_challenge_titles = [];
        // 選択したチャレンジタイトルを入力フォームにセット
        this.form.challenge_title = challenge_title;
      },

      // スタートアップサジェスト選択時
      onClickSuCompany(su_company) {
        // 候補一覧をリセット
        this.pick_su_company_names = [];
        // 選択したスタートアップを入力フォームにセット
        this.form.su_company_name = su_company;
      },

      // 大手企業入力時
      onInputEpCompany() {
        const search_ep_company_name = this.form.ep_company_name;

        if (search_ep_company_name == '') {
          this.pick_ep_company_names = [];
        } else {
          // 入力された大手企業名で部分一致したものを候補一覧にセット
          this.pick_ep_company_names = this.suggest_ep_company_names.filter((el) => el.includes(search_ep_company_name));
        }
      },

      // 部署入力時
      onInputEpDepartment() {
        const search_ep_department_name = this.form.ep_department_name;

        if (search_ep_department_name == '') {
          this.pick_ep_departments = [];
        } else {
          // 入力された部署名で部分一致したものを候補一覧にセット
          this.pick_ep_departments = this.suggest_ep_departments.filter((el) => el.includes(search_ep_department_name));
        }
      },

      // チャレンジ入力時
      onInputEpChallengeTitle() {
        const search_challenge_title = this.form.challenge_title;

        if (search_challenge_title == '') {
          this.pick_challenge_titles = [];
        } else {
          // 入力されたチャレンジタイトルで部分一致したものを候補一覧にセット
          this.pick_challenge_titles = this.suggest_challenge_titles.filter((el) => el.includes(search_challenge_title));
        }
      },

      // スタートアップ入力時
      onInputSuCompany() {
        const search_su_company_name = this.form.su_company_name;

        if (search_su_company_name == '') {
          this.pick_su_company_names = [];
        } else {
          // 入力されたスタートアップで部分一致したものを候補一覧にセット
          this.pick_su_company_names = this.suggest_su_company_names.filter((el) => el.includes(search_su_company_name));
        }
      },

      // インクリメンタルサーチの候補一覧をリセット
      resetPickList() {
        setTimeout(() => {
          this.pick_ep_company_names = [];
          this.pick_ep_departments = [];
          this.pick_challenge_titles = [];
          this.pick_su_company_names = [];
        }, 200);
      },

      statusesCheck(statuses) {
        // statusesが文字列の場合、配列に詰める
        // 配列の形でない場合、検索フォームで不具合が起きる為
        if (!Array.isArray(statuses) && !!statuses) {
          return [statuses];
        } else {
          return statuses;
        }
      },
    },

    mounted() {
      const self = this;
      // フィードバック日_from のカレンダー設定
      this.setDatePicker(
        '#offer-at-start-date',
        function(selected_date) {
          self.form.offer_at_from = selected_date;
        },
        'top'
      );
      // フィードバック日_to のカレンダー設定
      this.setDatePicker(
        '#offer-at-end-date',
        function(selected_date) {
          self.form.offer_at_to = selected_date;
        },
        'top'
      );
      // リコメンド送付日_from のカレンダー設定
      this.setDatePicker(
        '#recommend-send-at-start-date',
        function(selected_date) {
          self.form.recommend_send_at_from = selected_date;
        },
        'top'
      );
      // フィードバック日_to のカレンダー設定
      this.setDatePicker(
        '#recommend-send-at-end-date',
        function(selected_date) {
          self.form.recommend_send_at_to = selected_date;
        },
        'top'
      );

    },

    created: function () {
      // リコメンド対応ステータスが1つだけ選択されている場合、textになるのを防ぐ処理
      this.form.recommend_answer_statuses = this.statusesCheck(this.form.recommend_answer_statuses);
      this.$route.query.recommend_answer_statuses = this.statusesCheck(this.$route.query.recommend_answer_statuses)

      this.fetchAccordionHeaders(this.$route.query);
      this.init();
    },

    // route.push時に実行
    beforeRouteUpdate(to, from, next) {
      this.fetchAccordionHeaders(to.query);
      next();
    },
  };
</script>
