<template>
  <main>
    <div class="one-col-box white-block">
      <div class="header">
      <h1 class="logo"><img v-bind:src="require('../../../../images/logo.svg')" alt="six brain"></h1>
      <h2 class="mb-4">新しいパスワードを設定してください</h2>
      </div>
      <div class="body">
      <div class="alert alert-primary" role="alert" v-if="$route.query.admin_reset && ($attrs.accountType || $route.query.login_type) == 'admin'">
        <p>パスワードの変更から84日が経過しました。定期的なパスワードの更新をお願いします。</p>
      </div>
      <div class="alert alert-danger" role="alert" v-if="errors.length">
        <p v-for="error in errors">{{error}}</p>
      </div>
      <form @submit.prevent="update">
        <div class="form-group">
        <div class="input-group icon-addon-right">
          <input class="form-control" type="password" name="password" v-model="password" placeholder="パスワードを入力">
        </div>
        </div>
        <div class="form-group">
        <div class="input-group icon-addon-right">
          <input class="form-control" type="password" name="password2" v-model="password_confirmation" placeholder="パスワードを入力（確認用）">
        </div>
        </div>
        <div class="btn-container py-3">
        <button class="button-square btn-send btn-block" type="submit">設定</button>
        </div>
      </form>
      </div>
    </div>
    <p class="text-center"><a class="text-secondary" href="mailto:sixbrain-support@tohmatsu.co.jp">サポートに問い合わせる</a></p>
  </main>
</template>

<script>
export default {
  metaInfo: {
    title: 'パスワード再設定',
    bodyAttrs: {
      class: ['password_setting']
    }
  },
  data() {
    return {
      password: '',
      password_confirmation: '',
      errors: []
    }
  },
  methods: {
    show: function(){
      this.$loading.load(this.$auth.api.get('auth/password/edit', {
        params: {
        reset_password_token: this.$route.params.reset_password_token,
        redirect_url: 'http://localhost/reset',
        },
        maxRedirects: 0
      }).then(res => {
        if(!res.data.success){
        this.$root.$bvToast.toast("パスワード再設定リンクの有効期限切れです", {
          title: 'パスワード再設定',
          variant: 'danger'
        })
          var p = this.$route.path.split('/');
          this.$router.push('/' + p[1] + '/password/reset');
        }
      }).catch(err => {
        if(err.response.status == 500 && err.response.data.error == 'Not Found'){
          this.$root.$bvToast.toast("パスワード再設定リンクの有効期限切れです", {
            title: 'パスワード再設定',
            variant: 'danger'
          })
          var p = this.$route.path.split('/');
          this.$router.push('/' + p[1] + '/password/reset');
        }else{
          this.$errorHandlers.initial(err);
        }
      }))
    },
    update: function(){
      this.$loading.load(this.$auth.api.patch('auth/password/', {
        reset_password_token: this.$route.params.reset_password_token,
        password: this.password,
        password_confirmation: this.password_confirmation,
      }).then(res => {
        this.$root.$bvToast.toast("パスワードを再設定しました。", {
          title: 'パスワード再設定',
          variant: 'success'
        })
        var p = this.$route.path.split('/');
        this.$router.push('/' + p[1] + '/login');
      }).catch(err => {
        if(err.response.status == 422){
          // Validation Error
          var full_messages = err.response.data.errors.full_messages;
          var index = full_messages.indexOf('パスワード（確認用）が一致していません')
          if(index > -1){
            full_messages.splice(index, 1);
          }
          this.errors = full_messages
        }
      }))
    }
  },
  computed: {
  },
  created: function () {
  },
  mounted: function() {
    this.show();
  },
  watch: {
  }
}
</script>

<style scoped>
</style>