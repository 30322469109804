<template>
  <!-- アカウント情報に表示する2段階認証設定エリア -->
  <div>
    <section id="pic_list" class="chapter white-block mt-5">
      <h2 class="chapter-label">2段階認証設定</h2>
        <dl class="form-group form-confirm-group">
            <dd>{{verification()}}</dd>
        </dl>
      <div class="btn-container edit-btn-position">
        <template v-if="isSetting">
          <button class="btn btn-green rounded-pill" type="button" @click="$router.push({name: editUrl})">設定する</button>
        </template>
        <template v-if="isInActivate && !isSetting && otpRequiredForLogin">
          <button class="btn btn-cancel rounded-pill" type="button" @click="$refs.inactivate_confirm_modal.open()">無効化する</button>
        </template>
      </div>
    </section>
    <!--子アカウント一覧-->

  <InActivateConfirmModal
    ref="inactivate_confirm_modal"
    :onClickSend="twoFactorAuthInActivate"
    :body_text="'無効化すると本人アカウントでなければ有効に戻すことはできません。<br>無効化してよろしいですか？'"
    :send_button_text="'はい'"
    :cancel_button_text="'いいえ'"
    :isNoFade="isNoFade"
  />
  </div>
  <!-- アカウント情報に表示する2段階認証設定エリア -->
</template>

<script>
  // 確認モーダルのコンポーネント読み込み
  import InActivateConfirmModal from '../ConfirmModal.vue';

  export default {
    components: {
      InActivateConfirmModal,
    },

    props: {
      // 2段階認証設定画面のルート名
      editUrl: {
        type: String
      },
      otpRequiredForLogin: {
        type: Boolean
      },
      // 設定するボタン表示するか
      isSetting: {
        type: Boolean,
        default: false
      },
      // 無効化するボタン表示するか
      isInActivate: {
        type: Boolean,
        default: false
      },
      // 対象のユーザーID（無効化ボタン押下時用）
      user_id: {
        type: Number
      },
      isNoFade: {
        type: Boolean,
      }
    },

    methods: {
      // 無効化ボタン押下時
      twoFactorAuthInActivate() {
          this.$loading.load(
            this.$auth.api.patch(`two_factor_auth_setting/inactivate`, {
              user_id: this.user_id
            }).then(res => {
              this.$refs.inactivate_confirm_modal.close();
              // アカウント詳細の情報を再表示
              this.$emit('reloadFunc');
              this.$bvToast.toast("更新しました", { variant: 'success', title: '完了'});
            }).catch(error => {
              this.$errorHandlers.initial(error);
            })
          );
      },
      verification() {
        if (this.otpRequiredForLogin == true) {
          return '有効';
        } else if (this.otpRequiredForLogin == false) {
          return '無効';
        }
      }
    },
  }
</script>
