<template>
  <main>
    <section class="main-title">
      <h1>メッセージルーム一覧</h1>
    </section>

    <!-- 検索-->
    <section class="chapter white-block">
      <form @submit.prevent>
        <div class="accordion" id="serach">
          <div class="form-group card">
            <div class="card-header" id="searach-parent"><a href="#" @click.prevent.stop="" v-b-toggle.searach-toggle-child>検索<i class="fas"></i></a></div>
            <b-collapse id="searach-toggle-child" visible accordion="my-accordion" role="tabpanel">
              <div class="card-body collapse show" id="searach-child">

                <div class="form-group row">
                  <label class="col-2 col-form-label" for="ep">大企業名</label>
                  <div class="col-10" :class="pick_ep_companies.length > 0 ? 'incremental-wrap' : ''">
                    <input v-model="form.ep_company_name" @input="onInputEpCompany" @blur="resetPickList" class="form-control" type="text" placeholder="大企業名">
                    <div class="list-group keyword-list" v-if="pick_ep_companies.length > 0">
                      <a v-for="pick_ep_company in pick_ep_companies" :key="pick_ep_company.id"
                        class="list-group-item list-group-item-action"
                        @click.prevent.stop="onClickEpCompany(pick_ep_company)"
                        href="#">
                        {{ pick_ep_company.company_name }}
                      </a>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-2 col-form-label" for="department">部署名</label>
                  <div class="col-10" :class="pick_departments.length > 0 ? 'incremental-wrap' : ''">
                    <input v-model="form.department_name" @input="onInputDepartment" @blur="resetPickList" class="form-control" type="text" placeholder="部署名">
                    <div class="list-group keyword-list" v-if="pick_departments.length > 0">
                      <a v-for="pick_department in pick_departments" :key="pick_department.id"
                        class="list-group-item list-group-item-action"
                        @click.prevent.stop="onClickDepartment(pick_department)"
                        href="#">
                        {{ pick_department }}
                      </a>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-2 col-form-label" for="su">スタートアップ企業</label>
                  <div class="col-10" :class="pick_su_companies.length > 0 ? 'incremental-wrap' : ''">
                    <input v-model="form.su_company_name" @input="onInputSuCompany" @blur="resetPickList" class="form-control" type="text" placeholder="スタートアップ企業">
                    <div class="list-group keyword-list" v-if="pick_su_companies.length > 0">
                      <a v-for="pick_su_company in pick_su_companies" :key="pick_su_company.id"
                        class="list-group-item list-group-item-action"
                        @click.prevent.stop="onClickSuCompany(pick_su_company)"
                        href="#">
                        {{ pick_su_company.company_name }}
                      </a>
                    </div>
                  </div>
                </div>

                <p class="search-no-data" v-if="!message_rooms">検索条件に一致するものはありません。</p>
                <div class="btn-container d-flex justify-content-end">
                  <button v-on:click="onSearch" class="button-square btn-send" type="button">検索する</button>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </form>
    </section>
    <!-- 検索-->

    <!-- リスト-->
    <section class="chapter white-block" v-if="!!message_rooms">
      <div class="table-responsive static-last-td">
        <table class="table table-striped table-hover">
          <thead class="thead-light">
            <tr>
              <th class="w-20">企業名</th>
              <th class="w-25">
                最新メッセージ
                <i class="fas fa-info-circle icon-gray ml-1" id="message-info-tooltip"></i>
                <b-tooltip target="message-info-tooltip" triggers="hover">最新メッセージを<br>マウスオーバーすると<br>全文を確認できます</b-tooltip>
              </th>
              <th class="w-25">送信者</th>
              <th class="w-10">送信日時</th>
              <th class="w-5"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="message_room in message_rooms" :key="message_room.id" >
              <td>
                <template>
                  {{ message_room.room_manager_company_department_name}}
                </template>
              </td>
              <td>
                <template v-if="message_room.is_suggestions_welcome_message">
                  提案歓迎の意思表示が届きました。
                </template>
                <template v-else-if="message_room.latest_message.ep_recommend_id">
                  オファーが届きました。
                </template>
                <template v-else-if="!!message_room.short_latest_message">
                  <p class="popover-trigger text-ellipsis" :id="'message-' + message_room.id" tabindex="0"
                    data-content="">{{ message_room.short_latest_message }}</p>
                  <b-popover
                    :target="'message-' + message_room.id"
                    triggers="hover"
                    placement="auto"
                  >
                    <span :inner-html.prop="message_room.latest_message.message | htmlEscape | nl2br"></span>
                  </b-popover>
                </template>
                <template v-else>
                  <p class="text-ellipsis" tabindex="0"
                    :inner-html.prop="message_room.latest_message.message | htmlEscape"></p>
                </template>
              </td>
              <td>{{ message_room.latest_sender }}</td>
              <td>
                <template>
                  {{ message_room.latest_message.created_at | format.datetime }}
                </template>
              </td>
              <td class="text-right">
                <button class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'詳細'"
                  @click="onShow(message_room)"><i class="fas fa-ellipsis-h"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <Pagination :total_page="total_page" :total_count="total_count" :current_page="current_page" :current_per="current_per" :page_link_num="page_link_num" :onChangePage="onChangePage" v-if="!!message_rooms" />

    </section>
    <!-- リスト-->

  </main>
</template>

<script>
  // ページネーションのコンポーネント読み込み
  import Pagination from '../../../common/pages/Pagination.vue';
  // 確認モーダルのコンポーネント読み込み
  import ConfirmModal from '../../../common/pages/ConfirmModal.vue';
  // メッセージルームのコンポーネント読み込み
  import MessageRoom from '../../../common/message/room_section.vue';
  // 削除データ表示用コンポーネント
  import WithDeleted  from "../../../common/mixins/with_deleted";

  export default {

    mixins: [WithDeleted],

    metaInfo: {
      title: 'メッセージルーム一覧',
    },

    components: {
      Pagination,
      ConfirmModal,
      MessageRoom
    },

    data() {
      return {
        // 現在ページ数
        current_page: Number(this.$route.query.page) || 1,
        // 現在の表示件数
        current_per:  0,
        // 総ページ数
        total_page: 0,
        // 総レコード数
        total_count: 0,
        // 表示するリンクの数
        page_link_num: 5,
        message_rooms: [],
        ep_companies: [],
        departments: [],
        su_companies: [],
        pick_ep_companies: [],
        pick_su_companies: [],
        pick_departments: [],
        form: {
          su_company_name: this.$route.query.su_company_name || '',
          ep_company_name: this.$route.query.ep_company_name || '',
          department_name: this.$route.query.department_name || '',
          // 検索処理で使用する現在のページ位置
          page: this.$route.query.page || '',
        },
      }
    },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.init();
      this.getMessageRooms(this.$route.query);
    },

    // route.push時に実行
    beforeRouteUpdate (to, from, next) {
      this.getMessageRooms(to.query);
      next();
    },

    // メソッド定義
    methods: {
      // 初期化
      init() {
        this.$loading.load(
          this.$auth.api.get(
            'admin/message_rooms/init_index', {
              params: {
              }
            }
          ).then(res => {
            this.ep_companies = res.data.ep_companies;
            this.su_companies = res.data.su_companies;
            this.departments = res.data.departments;
          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        )
      },

      // データ取得処理
      getMessageRooms(query) {
        this.$loading.load(
          this.$auth.api.get(
            'admin/message_rooms', {
              params: query
            }
          ).then(res => {
            this.message_rooms = res.data.message_rooms
            this.current_per  = Number(res.data.per);
            this.current_page = Number(res.data.page);
            this.total_page   = res.data.total_page;
            this.total_count  = res.data.total_count;
          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        )
      },

      // 大手企業入力時
      onInputEpCompany() {
        const search_ep_company_name = this.form.ep_company_name;

        if(search_ep_company_name == '') {
          this.pick_ep_companies = [];
        } else {
          this.pick_ep_companies = Object.values(this.ep_companies).filter(function (ep_company) {
            // 入力された大手企業名で部分一致
            return ep_company.company_name.includes(search_ep_company_name)
          })
        }
      },

      // 部署入力時
      onInputDepartment() {
        const search_department_name = this.form.department_name;

        if(search_department_name == '') {
          this.pick_departments = [];
        } else {
          this.pick_departments = Object.values(this.departments).filter(function (department_name) {
            // 入力された部署名で部分一致
            return department_name.includes(search_department_name)
          })
        }
      },

      // SU企業入力時
      onInputSuCompany() {
        const search_su_company_name = this.form.su_company_name;

        if(search_su_company_name == '') {
          // 初期化
          this.pick_su_companies = [];
        } else {
          this.pick_su_companies = Object.values(this.su_companies).filter(function (su_company) {
            // 入力されたSU企業名で部分一致
            return su_company.company_name.includes(search_su_company_name)
          })
        }
      },

      // 大手企業一覧から選択時
      onClickEpCompany(ep_company) {
        this.pick_ep_companies = []
        this.form.ep_company_name = ep_company.company_name
      },

      // 部署一覧から選択時
      onClickDepartment(department) {
        this.pick_departments = []
        this.form.department_name = department
      },

      // SU企業一覧から選択時
      onClickSuCompany(su_company) {
        this.pick_su_companies = []
        this.form.su_company_name = su_company.company_name
      },

      // インクリメンタルサーチの候補をリセット
      resetPickList() {
        setTimeout(() => {
          this.pick_ep_companies = [];
          this.pick_departments = [];
          this.pick_su_companies = [];
        }, 200);
      },

      // 検索ボタン押下時
      onSearch(query = []) {
        this.form.page = this.current_page = 1;
        // 画面更新
        this.reload(this.form);
      },

      // ページ切り替え時
      onChangePage(page, per) {
        let query = Object.assign({}, this.$route.query);
        query['page'] = this.current_page = page;
        query['per'] = this.current_per = per;
        // 画面更新
        this.reload(query);
      },

      // 更新処理
      reload(query = []) {
        this.$router.push({ name: 'AdminMessageIndex', query: query }).catch(err => {});
      },

      //詳細ボタン押下時
      onShow(room) {
        // 詳細画面に遷移
        this.$router.push({ name: 'AdminMessageShow', params: { room_id: room.id } });
      },

    },
  }

</script>
