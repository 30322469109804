import Login from '../common/pages/login'
import Logout from './pages/logout'
import Verify from '../common/pages/verify'
import PasswordReset from '../common/pages/password_reset'
import PasswordResetSent from '../common/pages/password_reset/sent'
import PasswordResetNewPassword from '../common/pages/password_reset/new_password'
import Unlock from '../common/pages/unlock'

import SuEdit from './pages/su_companies/SuEdit.vue'
import SuEditForm from './pages/su_companies/SuEditForm.vue'
import SuEditConfirm from './pages/su_companies/SuEditConfirm.vue'
import SuCompanyDetail from './pages/su_companies/SuCompanyDetail.vue'

import SuPersonInChargeNew from './pages/su_person_in_charges/New.vue'
import SuPersonInChargeNewForm from './pages/su_person_in_charges/Form.vue'
import SuPersonInChargeNewConfirm from './pages/su_person_in_charges/Confirm.vue'
import SuPersonInChargeEdit from './pages/su_person_in_charges/Edit.vue'
import SuPersonInChargeEditForm from './pages/su_person_in_charges/EditForm.vue'
import SuPersonInChargeEditConfirm from './pages/su_person_in_charges/EditConfirm.vue'
import SuPersonInChargeShow from './pages/su_person_in_charges/Show.vue'

import Feedback from '../common/pages/feedback'

import RegistrationAuthNew from '../common/pages/registration_auths/New.vue'
import RegistrationAuthShow from '../common/pages/registration_auths/Show.vue'

import RegistrationRequestNew from '../common/pages/registration_requests/New.vue'
import RegistrationRequestForm from "../common/pages/registration_requests/Form.vue";
import RegistrationRequestConfirm from '../common/pages/registration_requests/Confirm.vue'
import RegistrationRequestComplete from '../common/pages/registration_requests/Complete.vue'

import TwoFactorAuthEdit from '../common/pages/two_factor_auth/Edit.vue'
import TwoFactorAuthConfirm from '../common/pages/two_factor_auth/Confirm.vue'
import TwoFactorAuthComplete from '../common/pages/two_factor_auth/Complete.vue'

import MessageRoom from '../common/pages/message_room'

import SuChallengeIndex from './pages/challenges/Index.vue'
import SuChallengeShow from './pages/challenges/Show.vue'

import ProvisionalSuLatestInfoIndex   from './pages/provisional_su_latest_infos/Index'
import ProvisionalSuLatestInfoNew     from './pages/provisional_su_latest_infos/New'
import ProvisionalSuLatestInfoEdit    from './pages/provisional_su_latest_infos/Edit'
import ProvisionalSuLatestInfoForm    from './pages/provisional_su_latest_infos/Form'
import ProvisionalSuLatestInfoConfirm from './pages/provisional_su_latest_infos/Confirm'
import ProvisionalSuLatestInfoShow    from './pages/provisional_su_latest_infos/Show'


const prefix = '/su/';

const loginCheck = (to, from, next, auth) => {
  return auth.accountType == "su_user" || auth.accountType == "su_person_in_charge"
}

// 管理者ユーザーかチェック
const SuAdminCheck = (to, from, next, auth) => {
  return auth.accountType == "su_user";
}

const single = 'su-single'
const layout = 'su-main'
const layout_fixed_bottom = 'su-main-fixed-bottom'

export default [
  { path: prefix + 'login', component: Login, name: 'SuLogin', meta: { isPublic: true }, props: {accountType: 'su'}},
  { path: prefix + 'logout', component: Logout, meta: { isPublic: true, layout: single }},
  { path: prefix + 'verify', component: Verify, meta: { isPublic: true }},
  { path: prefix + 'password/reset', component: PasswordReset, meta: { isPublic: true }, props: {accountType: "su"} },
  { path: prefix + 'password/reset/sent', component: PasswordResetSent, meta: { isPublic: true }, props: {accountType: "su"} },
  { path: prefix + 'password/reset/new_password/:reset_password_token', component: PasswordResetNewPassword, meta: { isPublic: true }, props: {accountType: "su"} },
  { path: prefix + 'password/unlock/:unlock_token', component: Unlock, meta: { isPublic: true }, props: {accountType: 'su'}},

  { path: prefix + 'su_company', component: SuEdit,
    children: [
      { path: 'edit', component: SuEditForm, name: 'SuSuEditForm', meta: { loginCheck: SuAdminCheck, layout: layout } },
      { path: 'confirm', component: SuEditConfirm, name: 'SuSuEditConfirm', meta: { loginCheck: SuAdminCheck, layout: layout } },
      { path: 'detail', component: SuCompanyDetail, name: 'SuSuCompanyDetail', meta: { loginCheck: loginCheck, layout: layout } }
    ]
  },

  {
    path: prefix + 'su_person_in_charges',
    component: SuPersonInChargeNew,
    children: [
      {
        path: "new",
        component: SuPersonInChargeNewForm,
        name: 'SuPersonInChargeNewForm',
        meta: { loginCheck: SuAdminCheck, layout: layout }
      },
      {
        path: "confirm",
        component: SuPersonInChargeNewConfirm,
        name: 'SuPersonInChargeNewConfirm',
        meta: { loginCheck: SuAdminCheck, layout: layout }
      },
    ]
  },
  {
    path: prefix + 'su_person_in_charges/:id',
    component: SuPersonInChargeEdit,
    children: [
      {
        path: "edit",
        component: SuPersonInChargeEditForm,
        name: 'SuPersonInChargeEditForm',
        meta: { loginCheck: loginCheck, layout: layout }
      },
      {
        path: "confirm",
        component: SuPersonInChargeEditConfirm,
        name: 'SuPersonInChargeEditConfirm',
        meta: { loginCheck: loginCheck, layout: layout }
      }
    ]
  },
  {
    path: prefix + 'su_person_in_charges/:id/detail',
    component: SuPersonInChargeShow,
    name: 'SuPersonInChargeShow',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'request_flow1_edit',
    component: RegistrationAuthNew,
    name: 'SuRegistrationAuthNew',
    meta: { isPublic: true },
    props: { accountType: "su" }
  },
  {
    path: prefix + 'request_flow2_send/:id',
    component: RegistrationAuthShow,
    name: 'SuRegistrationAuthShow',
    meta: { isPublic: true },
    props: { accountType: "su" }
  },
  {
    path: prefix + 'request_flow',
    component: RegistrationRequestNew,
    meta: { isPublic: true },
    children: [
      {
        path: "new",
        component: RegistrationRequestForm,
        name: 'SuRegistrationRequestForm',
        props: { accountType: "su" },
      },
      {
        path: "confirm",
        component: RegistrationRequestConfirm,
        name: 'SuRegistrationRequestConfirm',
        props: { accountType: "su" },
      },
      {
        path: "complete",
        component: RegistrationRequestComplete,
        name: 'SuRegistrationRequestComplete',
        props: { accountType: "su" },
      }
    ]
  },

  {
    path: prefix + "two_factor_auth/edit",
    component: TwoFactorAuthEdit,
    name: 'SuTwoFactorAuthEdit',
    meta: { loginCheck: loginCheck, layout: layout },
    props: { accountType: "su" },
  },
  {
    path: prefix + "two_factor_auth/confirm",
    component: TwoFactorAuthConfirm,
    name: 'SuTwoFactorAuthConfirm',
    meta: { loginCheck: loginCheck, layout: layout },
    props: { accountType: "su" },
  },
  {
    path: prefix + "two_factor_auth/complete",
    component: TwoFactorAuthComplete,
    name: 'SuTwoFactorAuthComplete',
    meta: { loginCheck: loginCheck, layout: layout },
    props: { accountType: "su" },
  },

  {
    path: prefix + 'message_room/:room_id',
    component: MessageRoom,
    name: 'SuMessageRoom',
    meta: {loginCheck: loginCheck, layout: layout},
    props: {accountType: 'su'}
  },
  {
    path: prefix + 'challenges/',
    component: SuChallengeIndex,
    name: 'SuChallengeIndex',
    meta: {loginCheck: loginCheck, layout: layout},
  },
  {
    path: prefix + 'challenges/:challenge_id',
    component: SuChallengeShow,
    name: 'SuChallengeShow',
    meta: {loginCheck: loginCheck, layout: layout_fixed_bottom},
  },
  {
    path: prefix + 'provisional_su_latest_infos/index',
    component: ProvisionalSuLatestInfoIndex,
    name: 'SuProvisionalSuLatestInfoIndex',
    meta: { loginCheck: loginCheck, layout: layout }
  },
  {
    path: prefix + 'provisional_su_latest_infos',
    component: ProvisionalSuLatestInfoNew,
    children: [
      {
        path: 'new',
        component: ProvisionalSuLatestInfoForm,
        name: 'SuProvisionalSuLatestInfoNew',
        meta: { loginCheck: loginCheck, layout: layout }
      },
      {
        path: 'confirm',
        component: ProvisionalSuLatestInfoConfirm,
        name: 'SuProvisionalSuLatestInfoNewConfirm',
        meta: { loginCheck: loginCheck, layout: layout }
      }
    ]
  },
  {
    path: prefix + 'provisional_su_latest_infos/:id',
    component: ProvisionalSuLatestInfoEdit,
    children: [
      {
        path: 'edit',
        component: ProvisionalSuLatestInfoForm,
        name: 'SuProvisionalSuLatestInfoEdit',
        meta: { loginCheck: loginCheck, layout: layout }
      },
      {
        path: 'confirm',
        component: ProvisionalSuLatestInfoConfirm,
        name: 'SuProvisionalSuLatestInfoEditConfirm',
        meta: { loginCheck: loginCheck, layout: layout }
      }
    ]
  },
  {
    path: prefix + 'provisional_su_latest_infos/:id/show',
    component: ProvisionalSuLatestInfoShow,
    name: 'SuProvisionalSuLatestInfoShow',
    meta: {loginCheck: loginCheck, layout: layout},
  },
]