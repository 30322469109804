<template>
  <main>
    <section class="main-title">
      <h1>スタートアップ企業一覧</h1>
    </section>

    <!-- 検索-->
    <section class="chapter white-block">
      <form @submit.prevent>
        <div class="accordion" id="serach">
          <div class="form-group card">
            <div class="card-header" id="searach-parent"><a class="collapsed" data-toggle="collapse" href="#" @click.prevent.stop="" v-b-toggle.searach-toggle-child>検索<i class="fas"></i></a></div>
            <b-collapse id="searach-toggle-child" visible accordion="my-accordion" role="tabpanel">
              <div class="card-body collapse show" id="searach-child">

                <div class="form-group row">
                  <label class="col-2 col-form-label">企業名</label>
                  <div class="col-10">
                    <input v-model="form.company_name" class="form-control" type="text" placeholder="企業名">
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-2 col-form-label">担当者</label>
                  <div class="col-10">
                    <input v-model="form.person_in_charge" class="form-control" type="text" placeholder="担当者">
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-2 col-form-label">ステータス</label>
                  <div class="col-10">
                    <div v-for="(exam_statuses_i18n, exam_status_key) in exam_statuses" :key="exam_status_key" class="custom-control custom-checkbox custom-control-inline">
                      <input
                        v-model="form.exam_statuses"
                        type="checkbox"
                        class="custom-control-input"
                        :id="'status_' + exam_status_key"
                        :value="exam_status_key"
                      >
                      <label :for="'status_' + exam_status_key" class="custom-control-label" >{{ exam_statuses_i18n }}</label>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-2 col-form-label">申し込み日</label>
                  <div class="col-10">
                    <div class="form-group">
                      <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                        <input v-model="form.apply_at_from" class="form-control" id="start-date" type="text" data-toggle="datetimepicker" data-target="#start-date"><i class="far fa-calendar-alt"></i>
                      </div><span class="unit"> ~</span>
                      <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                        <div class="input-group icon-addon-right">
                          <input v-model="form.apply_at_to" class="form-control" id="end-date" type="text" data-toggle="datetimepicker" data-target="#end-date"><i class="far fa-calendar-alt"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p v-if="!su_company_registration_infos.length && is_fetch" class="search-no-data">検索条件に一致するものはありません。</p>
                <div class="btn-container d-flex justify-content-end">
                  <button v-on:click="onSearch" class="button-square btn-send" type="button">検索する</button>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </form>
    </section>
    <!-- 検索-->

    <!-- リスト-->
    <section class="chapter white-block">
      <div class="btn-container d-flex justify-content-between mb-3">
        <button @click="$router.push({ name: 'AdminSuCompanyForm' })" class="btn btn-green rounded-pill" type="button">
          新規作成
        </button>
      </div>
      <div v-if="su_company_registration_infos.length" class="table-responsive">
        <table class="table table-striped table-hover">
          <thead class="thead-light">
            <tr>
              <th class="w-20">企業名</th>
              <th class="w-25">業界</th>
              <th class="w-15">担当者名</th>
              <th class="w-10">メールアドレス</th>
              <th class="w-5">ステータス</th>
              <th class="w-10">申込日</th>
              <th class="w-5">最新情報投稿</th>
              <th hidden></th>
              <th class="w-10"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="su_company_registration_info in su_company_registration_infos" :key="su_company_registration_info.id">
              <td class="text-wrap">
                <a @click.prevent.stop="$router.push({name: 'AdminSuCompanyDetail', params: { id: su_company_registration_info.id }})" class="reading-title" href="#">
                  {{ su_company_registration_info.company_name }}
                </a>
              </td>
              <td>{{ su_company_registration_info.industry_names }}</td>
              <td>{{ su_company_registration_info.su_user }}</td>
              <td>{{ su_company_registration_info.mail_address }}</td>
              <td>{{ su_company_registration_info.exam_status_i18n }}</td>
              <td>{{ su_company_registration_info.apply_date | format.date }}</td>
              <td v-if="su_company_registration_info.exam_status == 'accepted'">{{ su_company_registration_info.number_su_infos }}</td>
              <td v-else></td>
              <td hidden>{{ su_company_registration_info.updated_at | format.date }}</td>
              <td>
                <div>
                  <button @click="onDelete(su_company_registration_info.id, su_company_registration_info.updated_at)"
                    class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'削除'">
                    <i class="fas fa-trash"></i>
                  </button>
                  <button @click="$router.push({name: 'AdminSuEditForm', params: { id: su_company_registration_info.id }})"
                    class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'編集'"><i class="fas fa-pen"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination :total_page="total_page" :total_count="total_count" :current_page="current_page" :current_per="current_per" :page_link_num="page_link_num" :onChangePage="onChangePage" v-if="!!su_company_registration_infos.length"/>
      <ConfirmModal
        ref="delete_confirm_modal"
        :onClickSend="onDeleteConfirmSend"
        :body_text="'既に使用されている場合も削除します。<br>よろしいですか？'"
        :send_button_text="'削除する'"
        :isNoFade="isNoFade"
      />
    </section>
    <!-- リスト-->
  </main>
</template>

<script>
  import Form from '../../../common/mixins/form';
  // ページネーションのコンポーネント読み込み
  import Pagination from '../../../common/pages/Pagination.vue';
  // 確認モーダルのコンポーネント読み込み
  import ConfirmModal from '../../../common/pages/ConfirmModal.vue';

  export default {
    mixins: [Form],
    metaInfo: {
      title: 'スタートアップ企業一覧'
    },
    components: {
      Pagination,
      ConfirmModal
    },
    data () {
      return {
        // 現在ページ数
        current_page: Number(this.$route.query.page) || 1,
        // 現在の表示件数
        current_per:  0,
        // 総ページ数
        total_page: 0,
        // 総レコード数
        total_count: 0,
        // 表示するリンクの数
        page_link_num: 5,
        su_company_registration_infos: [],
        exam_statuses: [],
        // 画面遷移直後に検索結果なしの文言を表示させないために定義
        is_fetch: false,
        form:{
          exam_statuses: this.$route.query.exam_statuses || ['undecided'],
          company_name: this.$route.query.company_name || '',
          person_in_charge: this.$route.query.person_in_charge || '',
          apply_at_from: this.$route.query.apply_at_from || '',
          apply_at_to: this.$route.query.apply_at_to || '',
          // 検索処理で使用する現在のページ位置
          page: this.$route.query.page || ''
        }
      }
    },

    props: { isNoFade: Boolean },

    methods: {
      // 初期化
      init () {
        this.$loading.load(this.$auth.api.get('/admin/su_companies/index_init')
          .then(response => {
            this.exam_statuses = response.data.exam_statuses;
          })
          .catch(err => {
            this.$errorHandlers.initial(err);
          })
        );
      },

      // SU企業登録情報取得
      fetchRegistrationInfos (query) {
        this.$loading.load(this.$auth.api.get(`/admin/su_companies`, {
          params: query
        })
        .then(response => {
          this.su_company_registration_infos = response.data.su_company_registration_infos
          this.current_per  = Number(response.data.per);
          this.current_page = Number(response.data.page);
          this.total_page   = response.data.total_page;
          this.total_count  = response.data.total_count;
          this.is_fetch = true
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
      },

      // 検索ボタン押下時
      onSearch(query = []) {
        this.form.page = this.current_page = 1;
        this.form.per  = this.current_per;
        // 画面更新
        this.reload(this.form);
      },

      // 削除ボタン押下時
      onDelete(registration_info_id, updated_at) {
        this.delete_target_id = registration_info_id;
        this.delete_target_updated_at = updated_at
        this.$refs.delete_confirm_modal.open();
      },

      // 削除確認モーダル内の削除するボタン押下時
      onDeleteConfirmSend() {
        this.$loading.load(
          this.$auth.api.delete('admin/su_companies/' + this.delete_target_id,
            { params: { updated_at: this.delete_target_updated_at } })
            .then(res => {
              this.$refs.delete_confirm_modal.close();
              this.delete_target_id = '';
              this.$bvToast.toast("削除しました。", {
                variant: 'success',
                title: '完了'
              });
              this.fetchRegistrationInfos(this.$route.query);
            }).catch(err => {
              this.$refs.delete_confirm_modal.close()
              if(err.response.status == 500) {
                this.$errorHandlers.initial(err);
              } else {
                this.$errorHandlers.ajax(err);
              }
            }
          )
        )
      },

      // ページ切り替え時
      onChangePage(page, per) {
        let query = Object.assign({}, this.$route.query);
        query['page'] = this.current_page = page;
        query['per'] = this.current_per = per;
        // 画面更新
        this.reload(query);
      },

      // 更新処理
      reload(query = []) {
        this.$router.push({ name: 'AdminSuIndex', query: query }).catch(err => {});
      },

      statusesCheck(statuses) {
        // statusesが文字列の場合、配列に詰める
        // 配列の形でない場合、検索フォームで不具合が起きる為
        if (!Array.isArray(statuses) && !!statuses) {
          return [statuses];
        } else {
          return statuses;
        }
      },

    },

    // インスタンス生成後
    created () {
      this.init();
      // 審査ステータス 1つだけの場合、textになるのを防ぐ処理
      this.form.exam_statuses = this.statusesCheck(this.form.exam_statuses);
      this.$route.query.exam_statuses = this.statusesCheck(this.$route.query.exam_statuses)

      this.fetchRegistrationInfos(this.$route.query);
      // 初期表示からのページ遷移時,undecidedを保持させるためformを$route.queryに入れる
      this.$route.query.exam_statuses = this.form.exam_statuses;
    },

    // elementへのマウントが行われたあと
    mounted() {
      const self = this;
      // 申し込み日_from のカレンダー設定
      this.setDatePicker(
          '#start-date',
          function(selected_date) {
            self.form.apply_at_from = selected_date;
          },
          'top'
      );
      // 申し込み日_to のカレンダー設定
      this.setDatePicker(
          '#end-date',
          function(selected_date) {
            self.form.apply_at_to = selected_date;
          },
          'top'
      );
    },

    // route.push時に実行
    beforeRouteUpdate (to, from, next) {
      this.fetchRegistrationInfos(to.query);
      next();
    }

  }
</script>
