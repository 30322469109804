<template>
  <div>
    <section class="chapter white-block step-data">
      <div class="step-table">
        <h5>バリュエーション試算</h5>
        <div class="-mx-4 -my-2 mt-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr class="divide-x divide-gray-200">
                  <th
                    class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                    scope="col"
                  >
                    {{ displayCurrencyUnit(scenario) }}
                  </th>
                  <th
                    class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                    scope="col"
                  />
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr class="divide-x divide-gray-200">
                  <td class="whitespace-nowrap p-4 text-sm font-medium text-gray-900 sm:pl-0">
                    株主価値
                  </td>
                  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                    <div class="w-[27rem]">
                      {{ digitSeparator(calcStockValue(scenario)) }}
                    </div>
                  </td>
                </tr>
                <tr class="divide-x divide-gray-200">
                  <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900">
                    非流動性ディスカウント
                    <div
                      class="fas fa-info-circle icon-gray ml-1"
                      v-b-tooltip.hover="'上場企業の株式に比べて流動性が低く、換金しようとすると追加コストがかかることが想定されるため一般的に10~30%ディスカウントして評価されます。'"
                    />
                  </td>
                  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                    <div class="w-[27rem]">
                      {{ `${digitSeparator(scenario.liquidity_discount)}%` }}
                    </div>
                  </td>
                </tr>
                <tr class="divide-x divide-gray-200">
                  <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900">
                    株主価値試算値
                    <div
                      class="fas fa-info-circle icon-gray ml-1"
                      v-b-tooltip.hover="'対象企業から提出された事業計画をもとに試算した株主価値の参考値です。'"
                    />
                  </td>
                  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                    <div class="w-[27rem]">
                      {{ digitSeparator(calcValuation(scenario)) }}
                    </div>
                  </td>
                </tr>
                <tr class="divide-x divide-gray-200">
                  <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900">
                    参考値　他社株主価値試算値
                  </td>
                  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                    <a
                      href="https://c2p2-poc.vercel.app/dashboard"
                      target="_blank"
                      class="btn btn-md btn-outline-green rounded-pill"
                    >
                      他社のバリュエーションを見る
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <section class="chapter white-block step-data">
      <div class="step-table">
        <h5>想定シェア試算</h5>
        <div class="-mx-4 -my-2 mt-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr class="divide-x divide-gray-200">
                  <th
                    class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                    scope="col"
                  >
                    {{ displayCurrencyUnit(scenario) }}
                  </th>
                  <th
                    class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                    scope="col"
                  />
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr class="divide-x divide-gray-200">
                  <td class="whitespace-nowrap p-4 text-sm font-medium text-gray-900 sm:pl-0">
                    自社の持分
                  </td>
                  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                    <div class="w-[27rem]">
                      {{ digitSeparator(digitCorrectionInvestmentAmount(scenario)) }}
                    </div>
                  </td>
                </tr>
                <tr class="divide-x divide-gray-200">
                  <td class="whitespace-nowrap p-4 text-sm font-medium text-gray-900 sm:pl-0">
                    自社の持分割合
                  </td>
                  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                    <div class="w-[27rem]">
                      {{ digitSeparator(calcShare(scenario)) }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <div class="btn-container d-flex justify-content-center">
      <router-link
        :to="{ name: 'EpScenariosShowStep5' }"
        class="btn btn-big rounded-pill btn-prev"
      >
        前のステップに戻る
      </router-link>
    </div>
  </div>
</template>

<script>
  import ScenariosShowMixins from './scenarios_show_mixins';

  export default {
    mixins: [ScenariosShowMixins, ],
    props: {
      scenario: {
        type: Object,
        required: true,
      },
    },
  };
</script>
<style src="./tailwind.css" scoped />
