var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"margin":"0 3rem"}},[_c('div',{style:({
        'width': '8rem',
        'text-align': 'center',
        'background-color': 'rgb(16 185 129)',
        'font-weight': 600,
        'color': 'rgb(255 255 255)',
        'border': '1px solid rgb(16 185 129)',
      })},[_vm._v("\n      企業価値\n    ")]),_vm._v(" "),_c('div',{style:({
        'text-align': 'center',
        'font-weight': 600,
        'border': '1px solid rgb(16 185 129)',
      })},[_vm._v("\n      "+_vm._s(_vm.presentValue)+"\n    ")])]),_vm._v(" "),_c('div',{staticStyle:{"display":"flex"}},[_c('svg',{attrs:{"width":"550","height":"350"}},[_c('rect',{attrs:{"x":"0","y":"0","width":"275","height":"350","fill":"#34d399"}}),_vm._v(" "),_c('rect',{attrs:{"x":"275","y":"0","width":"275","height":"175","fill":"#d1d5db"}}),_vm._v(" "),_c('rect',{attrs:{"x":"275","y":"175","width":"275","height":"175","fill":"#38bdf8"}}),_vm._v(" "),_c('line',{attrs:{"x1":"275","y1":"0","x2":"275","y2":"350","stroke":"#FFFFFF","stroke-width":"2"}}),_vm._v(" "),_c('line',{attrs:{"x1":"275","y1":"175","x2":"550","y2":"175","stroke":"#FFFFFF","stroke-width":"2"}})])]),_vm._v(" "),_c('div',{staticStyle:{"margin":"0 3rem"}},[_c('div',[_c('div',{style:({
          'width': '8rem',
          'text-align': 'center',
          'background-color': 'rgb(107 114 128)',
          'font-weight': 600,
          'color': 'rgb(255 255 255)',
          'border': '1px solid rgb(107 114 128)',
        })},[_vm._v("\n        負債価値\n      ")]),_vm._v(" "),_c('div',{style:({
          'text-align': 'center',
          'font-weight': 600,
          'border': '1px solid rgb(107 114 128)',
        })},[_vm._v("\n        "+_vm._s(_vm.interestBearingDebtNet)+"\n      ")])]),_vm._v(" "),_c('div',{staticStyle:{"margin-top":"8rem"}},[_c('div',{style:({
          'width': '8rem',
          'text-align': 'center',
          'background-color': 'rgb(14 165 233)',
          'font-weight': 600,
          'color': 'rgb(255 255 255)',
          'border': '1px solid rgb(14 165 233)',
        })},[_vm._v("\n        株主価値\n      ")]),_vm._v(" "),_c('div',{style:({
          'text-align': 'center',
          'font-weight': 600,
          'border': '1px solid rgb(14 165 233)',
        })},[_vm._v("\n        "+_vm._s(_vm.stockValue)+"\n      ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }