<template>
  <div>
    <router-view
      :su_company.sync="su_company"
      :stages.sync="stages"
      :prefectures.sync="prefectures"
      :industries.sync="industries"
      :accept_extensions="accept_extensions"
      :su-business-infos="su_business_infos"
      :provisional-su-business-infos="provisional_su_business_infos"
      :has-provisional-asset="has_provisional_asset"
      :is_audits.sync="is_audits"
      :is_publish_infos.sync="is_publish_infos"
      :current_api_v1_user.sync="current_api_v1_user"
      >
    </router-view>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        su_company: {
          company_name: '',
          company_hp: '',
          representative: '',
          prefecture_master_id: '',
          address: '',
          stage: '',
          industry_master_id: '',
          sub_industry_master_id: '',
          sub2_industry_master_id: '',
          business_summary: '',
          is_audit: '',
          is_publish_info:'',
          founding_date: '',
          user_attributes: {
            first_name: '',
            last_name: '',
            kana_first_name: '',
            kana_last_name: '',
            email: '',
            department: '',
            profile: '',
            otp_required_for_login: '',
            account_type: '',
            position: '',
            password: '',
            password_confirmation: '',
            uploaded_image: '',
            new_image_name: '',
            image_attributes: {
              filename: ''
            }
          }
        },
        stages:{},
        prefectures: {},
        industries: {},
        is_audits: {},
        is_publish_infos: {},
        accept_extensions: [],
        su_business_infos: [],
        provisional_su_business_infos: [],
        has_provisional_asset: true,
        current_api_v1_user: {}
      }
    },
    beforeMount() {
      if(this.$route.name !== "SuSuEditConfirm"){
        this.fetchSuCompany();
      }
      this.fetchRelatedParts();
    },
    beforeRouteUpdate (to, from, next) {
      if(to.name === "SuSuCompanyDetail"){
        this.fetchSuCompany();
      }
      next();
    },
    methods: {
      fetchSuCompany () {
        this.$loading.load(this.$auth.api.get(`/su/su_companies/edit`)
        .then(response => {
          this.su_company = response.data.su_company;
          this.su_business_infos = response.data.su_business_infos;
          this.provisional_su_business_infos = response.data.provisional_su_business_infos;
          this.has_provisional_asset = response.data.has_provisional_asset;
          this.current_api_v1_user = response.data.current_api_v1_user;
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
      },
      fetchRelatedParts () {
        this.$loading.load(this.$auth.api.get(`/su/su_companies/related_parts`)
        .then(response => {
          this.stages = response.data.stages;
          this.prefectures = response.data.prefectures;
          this.industries = response.data.industries;
          this.accept_extensions = response.data.accept_extensions.join();
          this.is_audits = response.data.is_audits;
          this.is_publish_infos = response.data.is_publish_infos;
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
      },
    },
    created () {
    }
  }
</script>