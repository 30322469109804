<template>
  <tr
    class="divide-x divide-gray-200"
    :class="{ link_tr: onLink }"
    @click="onRow"
  >
    <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900">
      {{ fieldName }}
      <div
        v-if="tooltipContent"
        class="fas fa-info-circle icon-gray ml-1"
        v-b-tooltip.hover="tooltipContent"
      />
    </td>
    <td
      v-for="(columnKey, i) in columns"
      :key="`tbody_edit_${fieldName}_${i}`"
      class="whitespace-nowrap p-4 text-sm text-gray-500"
      :class="{ 'not-applicable': columnKey === 'notApplicable' }"
    >
      <template v-if="columnKey === 'notApplicable'">
        N/A
      </template>
      <template v-else-if="inEdit">
        <input
          class="w-28"
          type="text"
          :value="fieldValues[columnKey]"
          @input="fieldValuesReplace($event.target.value, columnKey)"
        />
      </template>
      <template v-else>
        {{ digitSeparator(fieldValues[columnKey]) }}
      </template>
    </td>
  </tr>
</template>

<script>
  import ScenariosShowMixins from '../scenarios_show_mixins';

  export default {
    mixins: [ScenariosShowMixins, ],
    props: {
      inEdit: {
        type: Boolean,
        default: false,
      },
      columns: {
        type: Array,
        required: true,
      },
      fieldValues: {
        type: Object,
        required: true,
      },
      fieldName: {
        type: String,
        required: true,
      },
      tooltipContent: {
        type: String,
        default: '',
      },
      onLink: {
        type: Function,
        default: null,
      },
    },
    methods: {
      fieldValuesReplace(value, columnKey) {
        this.$emit('fieldValuesReplace', { ...this.fieldValues, [columnKey]: value });
      },
      onRow() {
        if (!this.onLink) return;

        this.onLink();
      },
    },
  };
</script>
<style src="../tailwind.css" scoped />
<style lang="scss" scoped>
  .link_tr {
    background-color: #f0f4bc;
    td.text-gray-500 {
      color: #4b5563;
    }
    td.not-applicable {
      background-color: #e6e8cc;
      color: #A8A8A8;
    }
    &:hover {
      cursor: pointer;
      td {
        border: none;
        color: #FFFFFF;
        background-color: #86bc25;
        div {
          color: #FFFFFF;
        }
      }
    }
  }
  .not-applicable {
    background-color: #dcdcdc;
    color: #A8A8A8;
  }
</style>