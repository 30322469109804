<template>
  <div>
    <b-modal
      v-model="is_show"
      id="portfolio-setting"
      aria-labelledby="portfolio-setting-Label"
      title=""
      hide-header
      centered
      scrollable
      size="xl"
      :no-fade="isNoFade"
    >
      <form>
        <div class="collapse p-2 show" id="search-data">
          <div class="form-group row">
            <label class="col-2 col-form-label" for="company-name">企業名</label>
            <div class="col-10 incremental-wrap">
              <b-form-input v-model="forms.portfolio.company_name" :state="checkValid('company_name')" type="text" placeholder="例) シックスブレイン株式会社" class="form-control"></b-form-input>
              <p v-if="!!errors['company_name']" class="invalid-feedback d-block">{{ errors['company_name'][0]}}</p>
            </div>
          </div>

          <fieldset class="form-group row">
            <label class="col-2 col-form-label">決算期</label>
            <div class="col-4">
              <b-select id="fiscal_month" v-model="forms.portfolio.fiscal_month" :state="checkValid('fiscal_month')">
                <option v-for="month in 12" :key="month" :value="month" :selected="month === 1">
                  {{ month }}月
                </option>
              </b-select>
            </div>
            <p v-if="!!errors['fiscal_month']" class="invalid-feedback d-block">{{ errors['fiscal_month'][0] }}</p>
          </fieldset>

          <fieldset class="form-group row">
            <label class="col-2 col-form-label">貨幣単位</label>
            <div class="col-4">
              <b-select id="currency_unit_master_id" v-model="forms.portfolio.currency_unit_master_id" :state="checkValid('currency_unit_master_id')">
                <option v-for="(currency_unit_i18n, unit_id) in currency_units" :key="unit_id" :value="unit_id">
                  {{ currency_unit_i18n }}
                </option>
              </b-select>
            </div>
            <p v-if="!!errors['currency_unit_master_id']" class="invalid-feedback d-block">{{ errors['currency_unit_master_id'][0] }}</p>
          </fieldset>
        </div>
      </form>
      <template v-slot:modal-footer>
        <button class="btn btn-cancel" type="button" @click="onCancel">キャンセル</button>
        <button class="btn btn-send" type="button" @click="onSave">保存</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Form from '../../../common/mixins/form'
export default {
  mixins: [Form],
  props: {
    // 保存ボタン押下時のコールバックメソッド
    onClickSave: {
      type: Function,
    },
    // ポートフォリオ情報
    portfolio: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    currency_units: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    isNoFade: {
      type: Boolean,
    },
  },
  data() {
    return {
      is_show: false,
      forms: {
        portfolio: {},
      },
    };
  },
  methods: {
    // 初期化
    init() {
      this.forms.portfolio = { ...this.portfolio };
    },
    // モーダルオープン
    open() {
      this.init();
      this.is_show = true;
    },
    // モーダルクローズ
    close() {
      this.is_show = false;
    },
    // 保存ボタン押下時
    onSave() {
      this.$loading.load(
        this.$auth.api.patch(
          'ep/portfolios/' + this.$route.params.id, this.forms
        ).then(res => {
          this.onClickSave();
          this.close();
        }).catch(error => {
          if (error.response.status == 422) {
            if (error.response.data && error.response.data.errors) {
              this.errors = error.response.data.errors;
            }
          } else if (error.response.status == 500) {
            this.$errorHandlers.initial(error);
          } else {
            this.$errorHandlers.ajax(error);
          }
        })
      );
    },
    // キャンセルボタン押下時
    onCancel() {
      this.close();
    },
  },
};
</script>
