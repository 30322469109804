<template>
  <div>
    <section id="pic_list" class="chapter white-block mt-5">
      <h2 class="chapter-label">過去の調達実績一覧</h2>

      <template>
        <div class="btn-container edit-btn-position">
          <button @click="$router.push({ name: 'AdminSuOldAchievementNewForm', params: { id: su_company_id } })" class="btn btn-green rounded-pill" type="button">新規作成</button>
        </div>
      </template>
      <div class="table-responsive">
        <table class="table table-striped table-hover" v-if="su_company.su_old_achievements">
          <thead class="thead-light">
            <tr>
              <th class="w-25">調達日付</th>
              <th class="w-25">金額</th>
              <th class="w-25">出資者</th>
              <th class="w-25"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="su_old_achievement in su_company.su_old_achievements" :key="su_old_achievement.id">
              <td>{{ su_old_achievement.supply_date | format.date }}</td>
              <td>{{ su_old_achievement.amount | format.trimFractionDigits }}</td>
              <td>{{ su_old_achievement.invest_persons.join(", ") | omittedText(25)}}</td>
              <td class="text-right">
                <button class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'詳細'" @click="$router.push({ name: 'AdminSuOldAchievementShow', params: { id: su_old_achievement.id } })">
                  <i class="fas fa-ellipsis-h"></i>
                </button>
                <template>
                  <button
                    class="btn btn-sm btn-icon mx-1"
                    type="button"
                    v-b-tooltip.hover="'編集'"
                    @click="$router.push({ name: 'AdminSuOldAchievementEditForm', params: { id: su_old_achievement.id } })"
                  >
                    <i class="fas fa-pen"></i>
                  </button>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    su_company: {
      type: Object,
    },
    su_company_id: {
      type: [Number, String],
    },
  },

  data() {
    return {};
  },
  filters: {
    // 文字列を省略して表示
    omittedText(text, len) {
      if (text === undefined || text === null) {
        return "";
      } else {
        return text.length > len ? text.slice(0, len) + "…" : text;
      }
    },
  },

  methods: {},
};
</script>
