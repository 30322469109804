<template>
  <vue-editor
    ref="editor"
    id="editor"
    :value="value"
    :editor-toolbar="customToolbar"
    :editorOptions="editorSettings"
    @input="updateValue"
  ></vue-editor>
</template>
<style>
.ql-editor {
  line-height: 1.8;
}
</style>
<script>
import { VueEditor, Quill } from 'vue2-editor';
import ImageResize from 'quill-image-resize-vue';
import { ImageDrop } from 'quill-image-drop-module';

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

export default {
  components: {
    VueEditor,
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["link", "image", "video"],
        [{ direction: "rtl" }],
        ["clean"],
      ],

      // Quillのオプション
      editorSettings: {
        theme: 'snow',
        modules: {
          imageDrop: true,
          imageResize: {},
        }
      },
    };
  },
  methods: {
    updateValue(val) {
      this.$emit('input', val)
    },
  },
};
</script>
