<template>
  <div style="display: flex;">
    <div style="margin: 0 3rem;">
      <div
        :style="{
          'width': '8rem',
          'text-align': 'center',
          'background-color': 'rgb(16 185 129)',
          'font-weight': 600,
          'color': 'rgb(255 255 255)',
          'border': '1px solid rgb(16 185 129)',
        }"
      >
        企業価値
      </div>
      <div
        :style="{
          'text-align': 'center',
          'font-weight': 600,
          'border': '1px solid rgb(16 185 129)',
        }"
      >
        {{ presentValue }}
      </div>
    </div>
    <div style="display: flex;">
      <svg width="550" height="350">
        <rect x="0" y="0" width="275" height="350" fill="#34d399" />
        <rect x="275" y="0" width="275" height="175" fill="#d1d5db" />
        <rect x="275" y="175" width="275" height="175" fill="#38bdf8" />
        <line x1="275" y1="0" x2="275" y2="350" stroke="#FFFFFF" stroke-width="2" />
        <line x1="275" y1="175" x2="550" y2="175" stroke="#FFFFFF" stroke-width="2" />
      </svg>
    </div>
    <div style="margin: 0 3rem;">
      <div>
        <div
          :style="{
            'width': '8rem',
            'text-align': 'center',
            'background-color': 'rgb(107 114 128)',
            'font-weight': 600,
            'color': 'rgb(255 255 255)',
            'border': '1px solid rgb(107 114 128)',
          }"
        >
          負債価値
        </div>
        <div
          :style="{
            'text-align': 'center',
            'font-weight': 600,
            'border': '1px solid rgb(107 114 128)',
          }"
        >
          {{ interestBearingDebtNet }}
        </div>
      </div>
      <div style="margin-top: 8rem;">
        <div
          :style="{
            'width': '8rem',
            'text-align': 'center',
            'background-color': 'rgb(14 165 233)',
            'font-weight': 600,
            'color': 'rgb(255 255 255)',
            'border': '1px solid rgb(14 165 233)',
          }"
        >
          株主価値
        </div>
        <div
          :style="{
            'text-align': 'center',
            'font-weight': 600,
            'border': '1px solid rgb(14 165 233)',
          }"
        >
          {{ stockValue }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      presentValue: {
        type: String,
        required: true,
      },
      interestBearingDebtNet: {
        type: String,
        required: true,
      },
      stockValue: {
        type: String,
        required: true,
      },
    },
  };
</script>
