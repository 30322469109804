<template>
  <fragment>
    <message-item v-for="message in timeline"
      @onEditMessage="onEditMessage"
      @onDeleteMessage="onDeleteMessage"
      @onEditCancel="onEditCancel"
      @patchMessage="patchMessage"
      @update:editMessage="edit_message=$event"
      :key="message.reset_key + message.id"
      :message.sync="message"
      :login="login"
      :edit-message.sync="edit_message"
      :errors="errors"
      :was-patch-valid="was_patch_valid"
    />

    <!-- メッセージ-->
    <div v-if="creatable" class="textarea-container-wrap">
      <div class="textarea-container">
        <form @submit.prevent="postMessage">
          <textarea class="white-block form-control auto-resize" placeholder="メッセージを書く..." v-model="message"></textarea>
          <button class="button-square btn-send" type="submit">送信</button>
        </form>
        <div class="invalid-feedback d-block" v-if="was_post_valid && Object.keys(errors).length > 0">
          <span v-for="(error, key) in errors" :key="key">{{error[0]}}</span>
        </div>
      </div>
    </div>
    <!-- メッセージ-->

    <ConfirmModal
      ref="delete_confirm_modal"
      :onClickSend="deleteMessage"
      :body_text="'このメッセージを削除します。<br>よろしいですか？'"
      :send_button_text="'削除する'"
      :isNoFade="isNoFade"
    />
  </fragment>
</template>

<script>
import impl from './room_impl'

export default impl
</script>
