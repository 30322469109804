import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistedstate from "vuex-persistedstate";
import auth from './auth';
import SuCompany from './su_company';
import SuCompanyEdit from './su_company_edit';
import EpCompany from './ep_company';
import EpEditCompanyEdit from './ep_company_edit';
import BackToIndexModal from './back_to_index_modal';
import BackToProjectIndex from './back_to_project_index';
import EpPersonInCharge from './ep_person_in_charge';
import SuPersonInCharge from './su_person_in_charge';
import SuOldAchievement from './su_old_achievement';
import User from './user';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: auth,
    ep_company: EpCompany,
    ep_company_edit: EpEditCompanyEdit,
    su_company: SuCompany,
    su_company_edit: SuCompanyEdit,
    back_to_index_modal: BackToIndexModal,
    back_to_project_index: BackToProjectIndex,
    ep_person_in_charge: EpPersonInCharge,
    su_person_in_charge: SuPersonInCharge,
    su_old_achievement: SuOldAchievement,
    user: User
  },
  plugins: [
    VuexPersistedstate({
        key: 'SixbrainApp',
        paths: ['ep_company', 'ep_company_edit', 'su_company', 'su_company_edit', 'back_to_index_modal','back_to_project_index', 'ep_person_in_charge','su_person_in_charge', 'su_old_achievement'],
        storage: window.sessionStorage
    }),
    VuexPersistedstate({
      key: 'SixbrainApp',
      paths: ['auth'],
      storage: window.localStorage
    }),
  ],
  actions: {
    resetAll({commit, state}){
      Object.keys(state).forEach(key => {
        commit(key + "/reset");
      });
    }
  }
})
