<template>
  <main>
    <section class="main-title">
      <h1>スタートアップ企業プッシュ登録<span class="small"> - 確認</span></h1>
    </section>

    <form @submit.prevent="createSuCompany">
      <!-- 企業情報 -->
      <section class="chapter white-block">
        <h2 class="chapter-label">企業情報</h2>
        <div class="form-wrap d-flex border-top">
          <div class="right-col">
            <dl class="form-group form-confirm-group">
              <dt>企業名</dt>
              <dd>{{ su_company.company_name }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>所在地</dt>
              <dd><span v-if="su_company.prefecture_master_id!=''">{{officeLocation}}</span></dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>創業日</dt>
              <dd>{{ su_company.founding_date }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>企業HP</dt>
              <dd>{{ su_company.company_hp }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>代表者</dt>
              <dd>{{ su_company.representative }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>ステージ</dt>
              <dd>{{ stage }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>業界</dt>
              <dd>{{ industry }}&nbsp;&nbsp;&nbsp;&nbsp;{{ sub_industry }}&nbsp;&nbsp;&nbsp;&nbsp;{{ sub2_industry }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>私たちについて</dt>
              <dd>{{ su_company.business_summary }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>有限責任監査法人トーマツ及びDeloitteのメンバーファームからの監査</dt>
              <dd>{{ auditMessage }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>情報公開許諾の有無</dt>
              <dd>{{ publishMessage }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>現在時価総額</dt>
              <dd>{{ su_company.current_market_capital | format.trimFractionDigits }}&nbsp;&nbsp;百万円</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>直近ファイナンス日</dt>
              <dd>{{ su_company.last_finance_date }}</dd>
            </dl>
          </div>
        </div>
      </section>
      <!-- 企業情報 -->

      <!-- 担当者情報 -->
      <section class="chapter white-block">
        <h2 class="chapter-label">担当者情報</h2>
        <div class="form-wrap d-flex border-top">
          <div class="left-col">
            <img v-if="su_company.user_attributes.image_attributes.filename"
              class="thumbnail-frame img180 mx-auto mb-3"
              :src="su_company.user_attributes.presigned_url"
              :alt="su_company.user_attributes.image_attributes.filename"
            />
            <!-- 画像が選択された -->
            <img v-else-if="su_company.user_attributes.uploaded_image"
              class="thumbnail-frame img180 preview-item-file"
              :src="su_company.user_attributes.uploaded_image"
              :alt="su_company.user_attributes.new_image_name"
            />
            <!-- 画像が選択されていない -->
            <img v-else
              class="thumbnail-frame img180 mx-auto mb-3"
              src="~images/su_user_default.png"
              alt="株式会社シックスブレイン logo"
            />
          </div>
          <div class="right-col">
            <dl class="form-group form-confirm-group">
              <dt>氏名</dt>
              <dd>{{ fullName }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>氏名（フリガナ）</dt>
              <dd>{{ fullKanaName }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>メールアドレス（ログインID）</dt>
              <dd>{{ su_company.user_attributes.email }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>役職名</dt>
              <dd>{{ su_company.user_attributes.position }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>部署名</dt>
              <dd>{{ su_company.user_attributes.department }}</dd>
            </dl>
            <dl class="form-group form-confirm-group">
              <dt>プロフィール</dt>
              <dd>{{ su_company.user_attributes.profile }}</dd>
            </dl>
          </div>
        </div>
      </section>
      <!-- 担当者情報 -->

      <div class="btn-container d-flex justify-content-center">
        <button class="button-square btn-cancel" type="button" @click="$router.push({name: 'AdminSuCompanyForm'})">修正する</button>
        <button class="button-square btn-send" type="submit">登録する</button>
      </div>
    </form>
  </main>
</template>

<script>
  export default {
    metaInfo: {
      title: 'スタートアップ企業プッシュ登録確認'
    },
    props: {
      su_company: Object | String,
      stages: Object,
      industries: Object,
      is_audits: Object,
      is_publish_infos: Object,
      prefectures: Object,
    },
    methods: {
      // SU企業作成
      createSuCompany () {
        this.$loading.load(this.$auth.api.post(`/admin/su_companies.json`, {
          su_company: this.su_company
        })
        .then(response => {
          this.$router.push({name: 'AdminSuCompanyComplete', params: { id: response.data.id }});
        })
        .catch(error => {
          if(error.response.status == 500) {
            this.$errorHandlers.initial(error);
          }else{
            this.$errorHandlers.ajax(error);
          }
        }));
      }
    },
    computed: {
      officeLocation: function () {
        return this.su_company.prefecture_master_id == '' || this.su_company.prefecture_master_id == null ? '' : `${this.prefectures[this.su_company.prefecture_master_id]}${this.su_company.address}`
      },
      stage () {
        return this.stages[this.su_company.stage]
      },
      industry () {
        return `${this.industries[this.su_company.industry_master_id]}`
      },
      sub_industry () {
        return `${this.industries[this.su_company.sub_industry_master_id] || ''}`
      },
      sub2_industry () {
        return `${this.industries[this.su_company.sub2_industry_master_id] || ''}`
      },
      fullName () {
        return `${this.su_company.user_attributes.last_name} ${this.su_company.user_attributes.first_name}`;
      },
      fullKanaName () {
        return this.su_company.user_attributes.kana_last_name == '' || this.su_company.user_attributes.kana_last_name == null ? '' : `${this.su_company.user_attributes.kana_last_name} ${this.su_company.user_attributes.kana_first_name}`
      },
      toBoolean () {
        return function (value) {
          value = typeof(value) == 'string' ? value.toLowerCase() : value
          return (value == true || value == 'true') ? true : false;
        };
      },
      auditMessage: function () {
        return this.is_audits[this.su_company.is_audit]
      },
      publishMessage: function () {
        return this.is_publish_infos[this.su_company.is_publish_info]
      },
    },
    filters: {
      numberWithDelimiter (value) {
        if (!value) {
          return '0'
        }
        return value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,')
      }
    }
  }
</script>
