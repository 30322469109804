<template>
  <div>
    <router-view
      :provisional_ep_challenge="provisional_ep_challenge"
      :ep_companies="ep_companies"
      :asset_masters="asset_masters"
      :exam_statuses="exam_statuses"
      :industry_masters="industry_masters"
      :challenge_stages="challenge_stages"
      :accept_extensions="accept_extensions"
      :form.sync="form"
      :main_image.sync="main_image"
      :sub1_image.sync="sub1_image"
      :sub2_image.sync="sub2_image"
      :validation_errors.sync="validation_errors"
      :onTemporarily="onTemporarily"
      :onConfirm="onConfirm"
      :onSave="onSave"
      :onFix="onFix"
      :onBack="onBack"
      :is_form_changed="is_form_changed"
    >
    </router-view>
  </div>
</template>

<script>
  export default {
    metaInfo: {
      title: 'チャレンジ作成',
    },

    data() {
      return {
        provisional_ep_challenge: {},
        // 大手企業一覧
        ep_companies: [],
        // 承認済みアセット一覧
        asset_masters: [],
        // 審査ステータス一覧
        exam_statuses: {},
        // 業界一覧
        industry_masters: [],
        // ステージ一覧
        challenge_stages: {},
        // 許容拡張子
        accept_extensions: [],

        was_valid: false,
        validation_errors: {},
        previous_route: null,
        is_form_changed: false,
        form: {
          provisional_ep_challenge: {
            ep_company_id: '',
            ep_company_name: '',
            title:  '',
            detail: '',
            registed_type: '',
            exam_status: '',
            public_status: '',
            no_reason: '',
            challenge_stage: '',
            // メイン画像
            main_image_title: '',
            // サブ1画像
            sub1_image_title: '',
            // サブ2画像
            sub2_image_title: '',
            // メイン画像
            main_image_attributes: {
              // 画像新規アップロード情報
              // filename: '',
              // size: '',
              // upload_image: '',
            },
            // サブ画像1
            sub1_image_attributes: {
              // 画像新規アップロード情報
              // filename: '',
              // size: '',
              // upload_image: '',
            },
            // サブ画像2
            sub2_image_attributes: {
              // 画像新規アップロード情報
              // filename: '',
              // size: '',
              // upload_image: '',
            },
            // 選択アセット
            provisional_ep_challenge_asset_masters_attributes: [
              // {
              //   asset_master_id: '',
              // }
            ],
            // 削除アセット
            destroy_provisional_ep_challenge_asset_masters_attributes: [
              // {
              //   id: '',
              //   asset_master_id: '',
              // }
            ],
            // 登録予定のアセット
            asset_masters_attributes: [
              // {
              //   id: ''
              //   name: ''
              //   // 編集処理用
              //   is_edit: false
              // }
            ],
            // 業種
            provisional_ep_challenge_industry_masters_attributes: [
              // {
              //   industry_master_id: '',
              // }
            ],
            // 削除業界
            destroy_provisional_ep_challenge_industry_masters_attributes: [
              // {
              //   id: '',
              //   industry_master_id: '',
              // }
            ],
            // 大手企業
            ep_company_attributes: {
              id: '',
              // 私たちについて
              business_summary: '',
              updated_at: '',
            },
            // EPチャレンジ
            ep_challenge_attributes: {
              // release_date: '',
              // close_date: '',
              // release_time: '',
              // close_time: '',
              // release_at: '',
              // close_at: '',
            }
          },
        },
        // メイン画像(表示用)
        main_image: {
          src: '',
          alt: '',
        },
        // サブ1画像(表示用)
        sub1_image: {
          src: '',
          alt: '',
        },
        // サブ2画像(表示用)
        sub2_image: {
          src: '',
          alt: '',
        },
      }
    },

    props: { isNoFade: Boolean },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.init();
    },

    // route.push時に実行
    beforeRouteUpdate(to, from, next) {
      next();
    },

    // コンポーネントを描画するルートが確立する前に呼ばれる
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.previous_route = from;
      });
    },

    // メソッド定義
    methods: {
      // 初期化
      init() {
        this.getProvisionalEpChallenge();
      },

      getProvisionalEpChallenge() {
        this.$loading.load(
          this.$auth.api.get(
            'admin/provisional_ep_challenges/form_init'
          ).then(res => {
            this.provisional_ep_challenge = res.data.provisional_ep_challenge;
            // 大手企業一覧
            this.ep_companies = res.data.ep_companies;
            // 承認済みアセットマスタ一覧
            this.asset_masters = res.data.approval_asset_masters;
            // 審査ステータス一覧
            this.exam_statuses = res.data.exam_statuses;
            // 業界マスタ
            this.industry_masters = res.data.industry_masters;
            // ステージ
            this.challenge_stages = res.data.challenge_stages;
            // 許容拡張子
            this.accept_extensions = res.data.accept_extensions;

            // 大手企業ID
            this.form.provisional_ep_challenge.ep_company_id = this.$route.params.init_select_id || '';

            // 仮登録EPチャレンジ
            this.form.provisional_ep_challenge.title  = this.provisional_ep_challenge.title  || '';
            this.form.provisional_ep_challenge.detail = this.provisional_ep_challenge.detail || '';
            this.form.provisional_ep_challenge.challenge_stage = this.provisional_ep_challenge.challenge_stage || '';

            // 大手企業 私たちについて
            this.form.provisional_ep_challenge.ep_company_attributes.id = this.form.provisional_ep_challenge.ep_company_attributes.id || '';
            this.form.provisional_ep_challenge.ep_company_attributes.business_summary = this.form.provisional_ep_challenge.ep_company_attributes.business_summary || '';
            // 大手企業 更新日時
            this.form.provisional_ep_challenge.ep_company_attributes.updated_at = this.form.provisional_ep_challenge.ep_company_attributes.updated_at || '';

            // メイン画像 初期化
            if(!!this.form.provisional_ep_challenge.main_image_attributes.upload_image) {
              this.main_image.src = this.form.provisional_ep_challenge.main_image_attributes.upload_image;
            }
            else if(!!this.form.provisional_ep_challenge.main_image_attributes.filename) {
              this.main_image.src = this.form.provisional_ep_challenge.main_image_attributes.filename;
            }
            this.main_image.alt = this.form.provisional_ep_challenge.main_image_attributes.filename;

            // サブ1画像 初期化
            if(!!this.form.provisional_ep_challenge.sub1_image_attributes.upload_image) {
              this.sub1_image.src = this.form.provisional_ep_challenge.sub1_image_attributes.upload_image;
            }
            else if(!!this.form.provisional_ep_challenge.sub1_image_attributes.filename) {
              this.sub1_image.src = this.form.provisional_ep_challenge.sub1_image_attributes.filename;
            }
            this.sub1_image.alt = this.form.provisional_ep_challenge.sub1_image_attributes.filename;

            // サブ2画像 初期化
            if(!!this.form.provisional_ep_challenge.sub2_image_attributes.upload_image) {
              this.sub2_image.src = this.form.provisional_ep_challenge.sub2_image_attributes.upload_image;
            }
            else if(!!this.form.provisional_ep_challenge.sub2_image_attributes.filename) {
              this.sub2_image.src = this.form.provisional_ep_challenge.sub2_image_attributes.filename;
            }
            this.sub2_image.alt = this.form.provisional_ep_challenge.sub2_image_attributes.filename;

            // 審査ステータス
            this.form.provisional_ep_challenge.exam_status = 'undecided';

            // 入力項目の監視を開始（非同期処理でフォーム変更と監視開始のタイミングが前後するためここで実施する）
            this.$watch('form', function(){ this.is_form_changed = true }, { deep: true });
            this.$watch('main_image', function(){ this.is_form_changed = true }, { deep: true });
            this.$watch('sub1_image', function(){ this.is_form_changed = true }, { deep: true });
            this.$watch('sub2_image', function(){ this.is_form_changed = true }, { deep: true });

          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        )
      },

      onTemporarily() {
        // 下書きボタン経由のためtrueをonConfirmに渡す
        this.onConfirm(true);
      },

      // 確認ボタン押下
      onConfirm(is_draft = false) {
        if(is_draft) {
          // 登録種別 新規登録
          this.form.provisional_ep_challenge.registed_type = 'registration'
          // 審査ステータス 下書き
          this.form.provisional_ep_challenge.exam_status = 'draft'
        }
        else {
          // 登録種別 新規登録
          this.form.provisional_ep_challenge.registed_type = 'registration'
        }

        this.$loading.load(
          this.$auth.api.post(
            'admin/provisional_ep_challenges/confirm', this.form
          ).then(res => {
            // 確認画面を表示
            this.$router.push({name: 'AdminProvisionalEpChallengeNewConfirm'});
          }).catch(err => {
            if(is_draft) {
              // 審査ステータスを初期化
              this.form.provisional_ep_challenge.exam_status = 'undecided'
            }
            // バリデーションエラー
            if (err.response.status == 422) {
              this.validation_errors = err.response.data.errors;
            }
            else if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else {
              this.$errorHandlers.ajax(err);
            }
          })
        );
      },

      // 保存ボタンo押下時処理
      onSave() {
        this.$loading.load(
          this.$auth.api.post(
            'admin/provisional_ep_challenges', this.form
          ).then(res => {
            if (!!this.previous_route && this.previous_route.name === 'AdminProvisionalEpChallengeIndex') {
              this.$router.push({path: this.previous_route.fullPath, query: {toast_type: 'created'}});
            }
            else {
              this.$router.push({name: 'AdminProvisionalEpChallengeIndex', query: {toast_type: 'created'}});
            }
          }).catch(err => {
            // バリデーションエラ
            if (err.response.status == 422) {
              this.validation_errors = err.response.data.errors;
            }
            else if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else {
              this.$errorHandlers.ajax(err);
            }
          })
        );
      },

      // 修正ボタン押下時処理
      onFix() {
        this.$router.push({name: 'AdminProvisionalEpChallengeNew'});
      },

      // 戻るボタン押下時処理
      onBack() {
        if (!!this.previous_route && this.previous_route.name === 'AdminProvisionalEpChallengeIndex') {
          this.$router.push({path: this.previous_route.fullPath});
        }
        else if (!!this.previous_route && this.previous_route.name === 'AdminProvisionalEpChallengeShow') {
          this.$router.push({path: this.previous_route.fullPath});
        }
        else {
          this.$router.push({name: 'AdminProvisionalEpChallengeIndex'});
        }
      },
    },
  }

</script>
