<template>
  <div id="app">
    <main>
      <section class="main-title">
        <h1>{{title()}}</h1>
      </section>

      <form class="needs-validation" autocomplete="off" @submit.prevent>
        <!-- チャレンジ情報-->
        <section class="chapter white-block">
          <div class="form-wrap">
            <!-- シナリオ名 -->
            <div class="form-group">
              <label for="name">シナリオ名</label>
              <b-form-input
                v-model="form.scenario.name"
                :state="checkValid('name')"
                type="text"
                class="form-control">
              </b-form-input>
              <div v-for="(error_message, key) in validation_errors.name" :key="key" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- ステータス -->
            <div class="form-row">
              <div class="form-group col-4">
                <label for="scenario_status">ステータス</label>
                <b-select v-model="form.scenario.scenario_status" :state="checkValid('scenario_status')" class="custom-select">
                  <option value="" disabled>選択してください</option>
                  <option v-for="(scenario_statuses_i18n, scenario_status) in scenario_statuses" :key="scenario_status" :value="scenario_status">
                    {{ scenario_statuses_i18n }}
                  </option>
                </b-select>
                <div v-for="(error_message, key) in validation_errors.scenario_status" :key="key" class="invalid-feedback" style="display: block">
                  {{ error_message }}
                </div>
              </div>
            </div>

            <!-- 調達回数 -->
            <div class="form-row">
              <div class="form-group col-4">
                <label for="capital_raised_count">調達回数</label>
                <b-select v-model="form.scenario.capital_raised_count" :state="checkValid('capital_raised_count')" class="custom-select">
                  <option value="" disabled>選択してください</option>
                  <option v-for="number in 10" :key="number" :value="number">
                    {{ number }}
                  </option>
                </b-select>
                <div v-if="!!validation_errors['capital_raised_count']" class="invalid-feedback" style="display: block">
                  {{ validation_errors['capital_raised_count'][0] }}
                </div>
              </div>
            </div>

            <!-- ラウンド -->
            <div class="form-row">
              <div class="form-group col-4">
                <label for="round">ラウンド</label>
                <b-form-input
                  v-model="form.scenario.round"
                  :state="checkValid('round')"
                  type="text"
                  class="form-control">
                </b-form-input>
                <div v-for="(error_message, key) in validation_errors.round" :key="key" class="invalid-feedback" style="display: block">
                  {{ error_message }}
                </div>
              </div>
            </div>

            <!-- 出資金額 -->
            <div class="form-group">
              <label for="investment_amount">出資金額</label>
              <div class="form-row">
                <div class="col-4">
                  <b-form-input
                    v-model="form.scenario.investment_amount"
                    :state="checkValid('investment_amount')"
                    type="text"
                    placeholder="0"
                    class="form-control">
                  </b-form-input>
                </div><span class="unit">{{ unit_iso_code === 'JPY' ? '百万円' : `M ${unit_iso_code}` }}</span>
              </div>
              <div v-for="(error_message, key) in validation_errors.investment_amount" :key="key" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- 期待利回り(IRR等) -->
            <div class="form-group">
              <label for="expected_return">期待利回り(IRR等)<i class="fas fa-info-circle icon-gray mx-1" v-b-tooltip="'10％など、パーセンテージの値でご入力ください'"></i></label>
              <div class="form-row">
                <div class="col-4">
                  <b-form-input
                    v-model="form.scenario.expected_return"
                    :state="checkValid('expected_return')"
                    type="text"
                    placeholder="0"
                    class="form-control">
                  </b-form-input>
                </div><span class="unit">%</span>
              </div>
              <div v-for="(error_message, key) in validation_errors.expected_return" :key="key" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- 評価基準日 -->
            <div class="form-group">
              <label class="required-addon">評価基準日</label>
              <div class="datetimepicker-input icon-addon-right">
                <b-form-input
                  v-model="form.scenario.valuation_date"
                  :state="checkValid('valuation_date')"
                  class="form-control"
                  id="valuation_date"
                  type="text"
                  data-toggle="datetimepicker"
                  data-target="#valuation_date"
                  autocomplete="off"
                  required
                ></b-form-input
                ><i class="far fa-calendar-alt"></i>
              </div>
              <div v-for="(error_message, key) in validation_errors.valuation_date" :key="key" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>
            <!-- 非流動性ディスカウント -->
            <div class="form-group">
              <label for="liquidity_discount">
                非流動性ディスカウント
                <i
                  class="fas fa-info-circle icon-gray mx-1"
                  v-b-tooltip="'上場企業の株式に比べて流動性が低く、換金しようとすると追加コストがかかることが想定されるため一般的に10~30%ディスカウントして評価されます。'"
                />
              </label>
              <div class="form-row">
                <div class="col-4">
                  <b-form-input
                    v-model="form.scenario.liquidity_discount"
                    :state="checkValid('liquidity_discount')"
                    placeholder="0"
                    type="text"
                    class="form-control">
                  </b-form-input>
                </div><span class="unit">%</span>
              </div>
              <div v-for="(error_message, key) in validation_errors.liquidity_discount" :key="key" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>

            <!-- 表示年数 -->
            <div class="form-row">
              <div class="form-group col-4">
                <label for="years_custom">表示年数</label>
                <b-select v-model="form.scenario.years_custom" :state="checkValid('years_custom')" class="custom-select">
                  <option value="" disabled>選択してください</option>
                  <option v-for="years_custom in years_customs" :key="years_custom" :value="years_custom">
                    {{ years_custom }}
                  </option>
                </b-select>
                <div v-for="(error_message, key) in validation_errors.years_custom" :key="key" class="invalid-feedback" style="display: block">
                  {{ error_message }}
                </div>
              </div>
            </div>

            <!-- バリュエーション計算の仕方 -->
            <div class="form-row">
              <div class="form-group col-4">
                <label for="scenario_status">バリュエーション計算の仕方</label>
                <b-select v-model="form.scenario.valuation_calculation_type" :state="checkValid('valuation_calculation_type')" class="custom-select">
                  <option v-for="(valuation_calculation_type_i18n, valuation_calculation_type) in valuation_calculation_types" :key="valuation_calculation_type" :value="valuation_calculation_type">
                    {{ valuation_calculation_type_i18n }}
                  </option>
                </b-select>
                <div v-for="(error_message, key) in validation_errors.valuation_calculation_type" :key="key" class="invalid-feedback" style="display: block">
                  {{ error_message }}
                </div>
              </div>
            </div>

          </div>
        </section>

        <div class="btn-container d-flex justify-content-center">
          <button class="button-square btn-save" @click="onClickTemporarily" type="button" v-if="this.$route.path.includes('new') || !!form.scenario.is_temporary">一時保存する</button>
          <button class="button-square btn-send" @click="onClickConfirm" type="button">次に進む</button>
        </div>

      </form>

    </main>
  </div>
</template>

<script>
  import Form from '../../../common/mixins/form';

  export default {
    mixins: [Form],

    props: {
      // 登録編集対象のシナリオ
      scenario: Object,
      // フォームの内容
      form: {
        type: Object,
        default: () => ({})
      },
      // ポートフォリオ 通貨ISOコード
      unit_iso_code: {
        type: String,
        default: '',
      },
      // シナリオ ステータス一覧
      scenario_statuses: {
        type: Object,
        default: () => ({})
      },
      // シナリオ 表示年数一覧
      years_customs: {
        type: Array,
      },
      // シナリオ バリュエーション計算の仕方
      valuation_calculation_types: {
        type: Object,
        default: () => ({})
      },
      // 確認ボタン押下時のコールバックメソッド
      onSave: {
        type: Function,
      },
      // 一時保存ボタン押下時のコールバックメソッド
      onTemporarily: {
        type: Function,
      },
      // バリデーションエラー
      validation_errors: {
        type: Object,
        default: () => ({})
      },
      isNoFade: {
        type: Boolean,
      }
    },

    watch: {
      validation_errors(new_validation_errors) {
        // エラー時ページトップにスクロール
        if (Object.keys(new_validation_errors).length > 0) {
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        };
      },
    },

    mounted() {
      const self = this;
      // 調達日付のカレンダー設定
      this.setDatePicker(
        "#valuation_date",
        function (selected_date) {
          self.form.scenario.valuation_date = selected_date;
        },
        "bottom"
      );
    },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
    },

    // メソッド定義
    methods: {
      // 初期化
      init() {
      },

      // 一時保存ボタン押下時処理
      onClickTemporarily() {
        this.onTemporarily();
      },

      // 次へ進むボタン押下時処理
      onClickConfirm() {
        this.onSave();
      },

      title() {
        let title = '';
        if(!!this.form.scenario.id) {
          title = 'シナリオ編集';
        }
        else {
          title = 'シナリオ作成';
        }

        return title;
      },
    },
  }
</script>
