<template>
  <main>
    <div class="text-center mt-7"><img class="error_image" src="~images/404.svg" alt="404">
      <h2>お探しのページはみつかりませんでした</h2>
      <p class="mt-4">リクエストされたページのURLは変更されたか、有効ではありません</p>
    </div>
  </main>
</template>

<script>
export default {
  metaInfo: {
    title: 'エラー',
    bodyAttrs: {
      class: ['login_error']
    }
  },
  data() {
    return {
      dashboardLink: null,
    }
  },
  methods: {
  },
  computed: {
  },
  created: function () {
  },
  mounted: function() {
    const p = this.$route.path.split('/');
    if(p[1] == 'ep' || p[1] == 'su' || p[1] == 'admin'){
      this.dashboardLink = '/' + p[1] + '/'
    }
  },
  watch: {
  }
}
</script>

<style scoped>
</style>
