<template>
  <div>
    <main>
      <section class="main-title">
        <h1>最新情報一覧</h1>
      </section>

      <!-- 検索-->
      <section class="search-block">
        <form @submit.prevent>
          <div class="accordion" id="serach">
            <div class="form-group card">
              <div class="card-header" id="searach-parent"><a class="collapsed" data-toggle="collapse" href="#" @click.prevent.stop="" v-b-toggle.searach-toggle-child>検索<i class="fas"></i></a></div>
              <b-collapse id="searach-toggle-child" :visible="InitialSearchSectionOpening" accordion="my-accordion" role="tabpanel">
                <div class="card-body collapse show" id="searach-child">
                  <div class="row">
                    <label class="col-2 col-form-label" for="">更新日</label>
                    <div class="col-10">
                      <div class="form-group">
                        <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                          <input v-model="form.updated_at_from" class="form-control" id="start-date" type="text" data-toggle="datetimepicker" data-target="#start-date" autocomplete="off"><i class="far fa-calendar-alt"></i>
                        </div><span class="unit"> ~</span>
                        <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                          <input v-model="form.updated_at_to" class="form-control" id="end-date" type="text" data-toggle="datetimepicker" data-target="#end-date" autocomplete="off"><i class="far fa-calendar-alt"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <p class="search-no-data" v-if="!provisional_su_latest_infos">検索条件に一致するものはありません。</p>

                  <div class="btn-container d-flex justify-content-end">
                    <button v-on:click="onSearch" class="button-square btn-send" type="button">検索する</button>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </form>
      </section>
      <!-- 検索-->

      <!-- リスト-->
      <section class="chapter white-block challenge">
        <div class="btn-container d-flex justify-content-between mb-3">
          <button @click="$router.push({ name: 'SuProvisionalSuLatestInfoNew' })" class="btn btn-sm btn-green rounded-pill px-4" type="button">新規作成</button>
        </div>

        <div class="table-scroll-container horizontal-scroll">
          <table class="table">
            <thead class="thead-light">
              <tr>
                <th>審査ステータス</th>
                <th class="miw-15em">タイトル</th>
                <th class="miw-15em">アセット</th>
                <th>更新日</th>
                <th class="horizontal-fixed"></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="provisional_su_latest_info in provisional_su_latest_infos" :key="provisional_su_latest_info.id">
                <!-- 審査ステータス -->
                <td>{{ provisional_su_latest_info.exam_status_i18n }}</td>

                <!-- タイトル -->
                <td>
                  <template v-if="!!provisional_su_latest_info.short_title">
                    <a
                      :id="'provisional_su_latest_info-detail_' + provisional_su_latest_info.id"
                      class="popover-trigger text-ellipsis"
                      tabindex="0"
                      data-content=""
                      data-original-title=""
                      title=""
                      @click.prevent.stop="onTitle(provisional_su_latest_info)"
                    >
                      {{ provisional_su_latest_info.short_title }}
                    </a>
                    <b-popover
                      :target="'provisional_su_latest_info-detail_' + provisional_su_latest_info.id"
                      triggers="hover"
                      placement="auto"
                    >
                      <div
                        style="margin: -1rem -1.5em; padding: 1rem 1.5em; position: relative; z-index: 1;"
                        @click="$root.$emit('bv::hide::popover')"
                      >
                        <span :inner-html.prop="provisional_su_latest_info.title | htmlEscape | nl2br" ></span>
                      </div>
                    </b-popover>
                  </template>
                  <template v-else>
                    <a
                      class="popover-trigger text-ellipsis"
                      href="#"
                      @click.prevent.stop="onTitle(provisional_su_latest_info)"
                    >
                      {{ provisional_su_latest_info.title }}
                    </a>
                  </template>
                </td>

                <!-- アセット -->
                <td class="asset-td">
                  <template v-for="(asset_master, index) in provisional_su_latest_info.asset_masters">
                    <!-- 短いアセット名を持っている -->
                    <template v-if="!!asset_master.short_name">
                      <label class="popover-trigger text-ellipsis" :id="'asset-name-' + provisional_su_latest_info.id + '-' + asset_master.id" tabindex="0" data-content="">
                        {{ asset_master.short_name }}
                      </label>
                      <b-popover
                        :target="'asset-name-' + provisional_su_latest_info.id + '-' + asset_master.id"
                        triggers="hover"
                        placement="auto"
                      >
                        <span>{{ asset_master.name | htmlEscape | nl2br }}</span>
                      </b-popover>
                    </template>
                    <!-- 短いアセット名を持っていない -->
                    <template v-else>
                      <label>{{ asset_master.name }}</label>
                    </template>
                  </template>
                </td>

                <!-- 更新 -->
                <template v-if="!!provisional_su_latest_info.updated_at">
                  <td class="text-nowrap">{{ provisional_su_latest_info.updated_at | format.datetime_padding }}</td>
                </template>
                <template v-else>
                  <td class="text-nowrap"></td>
                </template>

                <!-- ボタン -->
                <td>
                  <button class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'編集'" @click="onEdit(provisional_su_latest_info)">
                    <i class="fas fa-pen"></i>
                  </button>
                  <button class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover="'削除'" @click="onDelete(provisional_su_latest_info)">
                    <i class="fas fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <!-- / リスト-->

      <Pagination :total_page="total_page" :total_count="total_count" :current_page="current_page" :current_per="current_per" :page_link_num="page_link_num" :onChangePage="onChangePage" v-if="!!provisional_su_latest_infos"/>

      <ConfirmModal
        ref="delete_confirm_modal"
        :onClickSend="onDeleteComfirmSend"
        :body_text="'最新情報を削除します。<br>よろしいですか？'"
        :send_button_text="'削除する'"
        :isNoFade="isNoFade"
      />
    </main>
  </div>
</template>

<script>

  import form from '../../../common/mixins/form.js'
   // 初期表示時、検索モーダル開閉の判定
  import InitialSearchSectionOpening  from '../../../common/mixins/initial_serch_section_opening'
  // ページネーションのコンポーネント読み込み
  import Pagination from '../../../common/pages/Pagination.vue';
  // 確認モーダルのコンポーネント読み込み
  import ConfirmModal from '../../../common/pages/ConfirmModal.vue';

  export default {
    mixins: [form, InitialSearchSectionOpening],

    metaInfo: {
      title: '最新情報一覧',
    },

    components: {
      Pagination,
      ConfirmModal
    },

    props: {
      isNoFade: Boolean
    },

    data() {
      return {
        // 現在ページ数
        current_page: Number(this.$route.query.page) || 1,
        // 現在の表示件数
        current_per: 0,
        // 総ページ数
        total_page: 0,
        // 総レコード数
        total_count: 0,
        // 表示するリンクの数
        page_link_num: 5,
        provisional_su_latest_infos: [],
        delete_target_id: '',
        provisional_su_latest_info_updated_at: '',
        su_latest_info_updated_at: '',
        form: {
          updated_at_from: this.$route.query.updated_at_from || '',
          updated_at_to:   this.$route.query.updated_at_to   || '',

          per:  this.$route.query.per  || '',
          // 検索処理で使用する現在のページ位置
          page: this.$route.query.page || '',
        }
      }
    },


    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.getProvisionalSuLatestInfos(this.form);
    },

    mounted() {
      const self = this;
      // 更新日_from のカレンダー設定
      this.setDatePicker(
        '#start-date',
        function(selected_date) {
          self.form.updated_at_from = selected_date;
        },
        'bottom'
      );
      // 更新日_to のカレンダー設定
      this.setDatePicker(
        '#end-date',
        function(selected_date) {
          self.form.updated_at_to = selected_date;
        },
        'bottom'
      );
    },

    // route.push時に実行
    beforeRouteUpdate (to, from, next) {
      this.getProvisionalSuLatestInfos(to.query);
      next();
    },

    // メソッド定義
    methods: {
      getProvisionalSuLatestInfos(query) {
        this.$loading.load(
          this.$auth.api.get(
            'su/provisional_su_latest_infos', {
              params: query
            }
          ).then(res => {
            this.provisional_su_latest_infos = res.data.provisional_su_latest_infos
            this.current_per  = Number(res.data.per);
            this.current_page = Number(res.data.page);
            this.total_page   = res.data.total_page;
            this.total_count  = res.data.total_count;
          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        )
      },

      // 検索ボタン押下時
      onSearch(query = []) {
        this.form.page = this.current_page = 1;
        this.form.per  = this.current_per;
        // 画面更新
        this.reload(this.form);
      },

      // ページ切り替え時
      onChangePage(page, per) {
        let query = Object.assign({}, this.$route.query);
        query['page'] = this.current_page = page;
        query['per']  = this.current_per  = per;
        // 画面更新
        this.reload(query);
      },

      // タイトル押下時
      onTitle(provisional_su_latest_info) {
        // 下書き
        if(provisional_su_latest_info.is_draft) {
          // 編集画面に遷移
          this.$router.push({ name: 'SuProvisionalSuLatestInfoEdit', params: { id: provisional_su_latest_info.id } });
        }
        // それ以外
        else {
          // 詳細画面に遷移
          this.$router.push({ name: 'SuProvisionalSuLatestInfoShow', params: { id: provisional_su_latest_info.id } });
        }
      },

      // 編集ボタン押下時
      onEdit(provisional_su_latest_info) {
        // 編集画面に遷移
        this.$router.push({ name: 'SuProvisionalSuLatestInfoEdit', params: { id: provisional_su_latest_info.id } });
      },

      // 削除ボタン押下時
      onDelete(provisional_su_latest_info) {
        this.delete_target_id = provisional_su_latest_info.id;
        this.provisional_su_latest_info_updated_at = provisional_su_latest_info.updated_at;
        if(!!provisional_su_latest_info.su_latest_info) {
          this.su_latest_info_updated_at = provisional_su_latest_info.su_latest_info.updated_at;
        }
        this.$refs.delete_confirm_modal.open();
      },

      // 削除確認モーダル内の削除するボタン押下時
      onDeleteComfirmSend() {
        this.$loading.load(
          this.$auth.api.delete(
            'su/provisional_su_latest_infos/' + this.delete_target_id, {
              params: {
                provisional_su_latest_info_updated_at: this.provisional_su_latest_info_updated_at,
                su_latest_info_updated_at: this.su_latest_info_updated_at
              }
            }
          ).then(res => {
            this.$refs.delete_confirm_modal.close();
            this.delete_target_id = '';
            this.$bvToast.toast('削除しました。', {
              variant: 'success',
              title: '完了'
            });
            this.getProvisionalSuLatestInfos(this.$route.query);
          }).catch(err => {
            this.$refs.delete_confirm_modal.close()
            if(err.response.status == 500) {
              this.$errorHandlers.initial(err);
            } else {
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },

      // 更新処理
      reload(query = []) {
        this.$router.push({ name: 'SuProvisionalSuLatestInfoIndex', query: query }).catch(err => {});
      },
    },

    // ウォッチャ定義
    watch: {
    },

  }
</script>
