export default {
  methods: {

    dispDeletedCompany(company){
      if(this.isDeleted(company)){
        return "(削除済企業)"
      }
    },
    dispDeletedUser(user){
      if(this.isDeleted(user)){
        return "(削除済みユーザー)"
      }
    },

    // 削除されてたらtrue
    isDeleted(val) {
      return !!val && !!val.deleted_at
    },

  }
}
