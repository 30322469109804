<template>
  <main>
    <Header :accountType="accountType" />
    <MiniHeader :accountType="accountType" />
    <div class="overview-text-area text-center mt-5">
      <h2>お申し込み前にご確認ください</h2>
      <p class="mt-4">ただいまsix brainでは以下の項目いずれかに該当する企業を対象とさせていただいております。</p>
    </div>

    <div class="chapter white-block request-info-target">上場会社及びその子会社・法定監査を受けている会社</div>

    <div class="btn-container d-flex justify-content-center mt-5">
      <button class="button-square btn-send" type="button" @click="onClickRegister">了承のうえ、登録申請を行う</button>
    </div>
  </main>
</template>

<script>
  import Header from "./Header";
  import MiniHeader from "./MiniHeader";

  export default {
    metaInfo: {
      title: 'six brainアカウント登録申請'
    },
    props: {
      accountType: String
    },
    components: {
      Header,
      MiniHeader,
    },
    methods: {
      onClickRegister() {
        if (this.accountType === 'ep') {
          this.$router.push({name: 'EpRegistrationAuthNew'});
        } else if (this.accountType === 'su') {
          this.$router.push({name: 'SuRegistrationAuthNew'});
        }
      }
    }
  }
</script>

<style scoped>

</style>