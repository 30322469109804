<template>
  <router-view
    :registration_auth.sync="registration_auth"
    :registration_request.sync="registration_request"
    :industries.sync="industries"
    :is_audits.sync="is_audits"
    :is_publish_infos.sync="is_publish_infos"
    :form.sync="form">
  </router-view>
</template>

<script>
  export default {
    props: {
      accountType: String
    },
    data: function () {
      return {
        registration_auth: {},
        registration_request: {},
        industries: [],
        is_audits: [],
        is_publish_infos: {},
        form: {
          registration_auth: {},
          registration_request: {
            company_name: '',
            company_hp: '',
            industry_master_id: null,
            sub_industry_master_id: null,
            is_audit: 'unaudit',
            is_publish_info: 'publish',
            founding_date: '',
            last_name: '',
            first_name: '',
            department: '',
            encrypted_password: '',
            password_confirmation: '',
            industry: '',
            sub_industry: '',
          },
        },
      }
    },
    methods: {
      fetchRegistrationAuth(query) {
        this.$loading.load(
          this.$auth.api.get('registration_requests/new', {params: query} ).then(res => {
            this.registration_auth = res.data.registration_auth;
            this.form.registration_auth = this.registration_auth;
            this.form.registration_request = res.data.registration_request;
            this.form.registration_request.email = this.registration_auth.email;
            this.is_audits = res.data.is_audits;
            this.is_publish_infos = res.data.is_publish_infos;
            if (this.$route.path === '/su/request_flow/new') {
              this.industries = res.data.industries;
            } else {
              this.industries = res.data.ep_industries;
            }
          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        );
      },
    },
    created() {
      this.fetchRegistrationAuth(this.$route.query);
    },
  }
</script>

<style scoped>

</style>
