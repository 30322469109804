<template>
  <div>
    <section class="chapter white-block step-data">
      <div class="step-graph border-bottom">
        <BarChart
          :options="presentValuesBarOptions(scenario)"
          :chartData="presentValuesBarChartData(scenario)"
        />
      </div>
      <div class="step-table">
        <div class="btn-container">
          <div />
          <EditButtons
            :inEdit="inEdit"
            @editClick="$emit('update:inEdit', true)"
            @onSave="$emit('updateJsonScenario')"
          />
        </div>
        <div
          v-if="errors.length"
          class="mx-4 mb-4"
        >
          <p
            v-for="(error, i) in errors"
            :key="`scenario_errors_${i}`"
            class="invalid-feedback d-block"
          >
            {{ error }}
          </p>
        </div>
        <div class="mr-n3 -my-2 overflow-x-auto">
          <div class="pr-3 inline-block min-w-full py-2 align-middle">
            <table class="min-w-full divide-y divide-gray-300">
              <YearsThead
                :columns="yearColumns(scenario.years_custom)"
                :displayCurrencyUnit="displayCurrencyUnit(scenario)"
              />
              <tbody class="divide-y divide-gray-200 bg-white">
                <TbodyRow
                  :inEdit="inEdit"
                  :columns="yearColumns(scenario.years_custom)"
                  :fieldValues="scenario.sales"
                  fieldName="売上高"
                  @fieldValuesReplace="setField(scenario, 'sales', $event)"
                />
                <TbodyRow
                  :inEdit="inEdit"
                  :columns="yearColumns(scenario.years_custom)"
                  :fieldValues="scenario.gross_profit"
                  fieldName="売上総利益"
                  @fieldValuesReplace="setField(scenario, 'gross_profit', $event)"
                />
                <TbodyRow
                  :inEdit="inEdit"
                  :columns="yearColumns(scenario.years_custom)"
                  :fieldValues="scenario.sg_and_a"
                  fieldName="販売費及び一般管理費"
                  @fieldValuesReplace="setField(scenario, 'sg_and_a', $event)"
                />
                <TbodyRow
                  :columns="yearColumns(scenario.years_custom)"
                  :fieldValues="calcOperatingIncome(scenario)"
                  fieldName="営業利益"
                />
                <TbodyRow
                  :inEdit="inEdit"
                  :columns="yearColumns(scenario.years_custom)"
                  :fieldValues="scenario.tax"
                  fieldName="税金"
                  tooltipContent="事業計画上予定されている法人税の金額(繰越欠損金による節税効果考慮後)を入力してください。"
                  @fieldValuesReplace="setField(scenario, 'tax', $event)"
                />
                <TbodyRow
                  :columns="yearColumns(scenario.years_custom)"
                  :fieldValues="calcOperatingProfitAfterTaxes(scenario)"
                  fieldName="税引後営業利益"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <div class="btn-container d-flex justify-content-center">
      <router-link
        :to="{ name: 'EpScenariosShowStep2' }"
        class="btn btn-big rounded-pill btn-next"
      >
        次のステップに進む
      </router-link>
    </div>
    <CsvUploadModal
      ref="csv_upload_modal"
      :uploadErrors="uploadErrors"
      @onUploadFile="onUploadFile"
      @uploadErrorsClear="$emit('uploadErrorsClear')"
    />
  </div>
</template>

<script>
  import YearsThead from './parts/YearsThead';
  import TbodyRow from './parts/TbodyRow';
  import EditButtons from './parts/EditButtons';
  import BarChart from './parts/BarChart';
  import CsvUploadModal from './parts/CsvUploadModal';
  import ScenariosShowMixins from './scenarios_show_mixins';

  export default {
    mixins: [ScenariosShowMixins, ],
    components: {
      YearsThead,
      TbodyRow,
      EditButtons,
      BarChart,
      CsvUploadModal,
    },
    props: {
      scenario: {
        type: Object,
        required: true,
      },
      errors: {
        type: Array,
        required: true,
      },
      uploadErrors: {
        type: Array,
        required: true,
      },
      inEdit: {
        type: Boolean,
        required: true,
      },
    },
    methods: {
      onUploadFile(file) {
        this.$emit('uploadCsv', file, () => this.$refs.csv_upload_modal.close());
      },
      csvUploadModalOpen() {
        this.$refs.csv_upload_modal.open();
      },
    },
  };
</script>
<style src="./tailwind.css" scoped />
