<template>
  <fragment>
    <component v-bind:is="layout"/>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :z-index="9999"
      :is-full-page="true"></loading>

  </fragment>
</template>

<script>
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import VueScrollTo from 'vue-scrollto'

  export default {
    components: {
      Loading
    },

    data() {
      return {
        loadingCount: 0,
      }
    },

    metaInfo: {
      title: '',
      titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - six brain` : 'six brain';
      }
    },

    methods: {
      load(promise){
        this.startLoading();
        promise.then(this.endLoading).catch(this.endLoading);
      },

      startLoading(){
        this.loadingCount++;
      },

      endLoading(){
        if(this.loadingCount > 0){
          this.loadingCount--;
        }
      },

      // クエリからtoast_typeを削除してreplace
      replaceDeletedToast (path) {
        let query = Object.assign({}, this.$route.query)
        delete query['toast_type']
        this.$router.replace({path: path, query: query})
      },

      scrollToAnchor (hash) {
        if (hash && hash.match(/^#.+$/)) {
          this.$nextTick(function() {
            VueScrollTo.scrollTo(hash, 500, { offset: -70 });
          });
        }
      }
    },

    computed: {
      layout () {
        return (this.$route.meta.layout || 'default') + "-layout"
      },
      isLoading(){
        return this.loadingCount > 0;
      }
    },

    created: function () {
      this.$loading.startLoading = this.startLoading;
      this.$loading.endLoading = this.endLoading;
    },

    watch: {
      '$route': function (to, from) {
        // 同一ページ内のスクロール(他ページからの遷移はwatch:isLoadingで行う)
        if(to.name === from.name) {
          this.scrollToAnchor(this.$route.hash)
        }

        if(this.$route.query.toast_type === 'updated') {
          this.$bvToast.toast("更新しました", {
            variant: 'success',
            title: '完了'
          });
        } else if(this.$route.query.toast_type === 'created') {
          this.$bvToast.toast("登録しました", {
            variant: 'success',
            title: '完了'
          });
        } else if(this.$route.query.toast_type === 'deleted') {
          this.$bvToast.toast("削除されました", {
            variant: 'success',
            title: '完了'
          });
        }else if(this.$route.query.error_status === 401) {
          this.$bvToast.toast('セッションが切れました。再度ログインしてください。', {
            variant: 'danger',
            title: 'ログアウト'
          });
        }else if(this.$route.query.toast_type === 'rejected') {
          this.$bvToast.toast('仮登録情報を却下しました', {
            variant: 'success',
            title: '却下'
          });
        }else if(this.$route.query.toast_type === 'accepted') {
          this.$bvToast.toast('仮登録情報を承認しました', {
            variant: 'success',
            title: '承認'
          })
        }else if(this.$route.query.toast_type === 'invalid_transitioned') {
          this.$bvToast.toast('不正な遷移です', {
            variant: 'danger',
            title: 'エラー'
          })
        }else if(this.$route.query.toast_type === 'provisional_updated') {
          this.$bvToast.toast('アセットと課題が更新されています。', {
            variant: 'danger',
            title: 'エラー'
          });
        }else if(this.$route.query.toast_type === 'project_flows_updated') {
          this.$bvToast.toast('プロジェクトのフローを更新しました。', {
            variant: 'success',
            title: '完了'
          });
        }else if(this.$route.query.toast_type === 'project_staffs_updated') {
          this.$bvToast.toast('担当者設定を更新しました。', {
            variant: 'success',
            title: '完了'
          });
        }else if(this.$route.query.toast_type === 'request_accepted') {
          this.$bvToast.toast('アカウント登録申請を承認しました', {
            variant: 'success',
            title: '承認'
          });
        }else if(this.$route.query.toast_type === 'request_rejected') {
          this.$bvToast.toast('アカウント登録申請を却下しました', {
            variant: 'success',
            title: '却下'
          });
        }else if(this.$route.query.toast_type === 'change_email') {
          this.$bvToast.toast("認証メールを送信しました。メールを確認して認証を完了してください。", {
            variant: 'success',
            title: '完了'
          });
          this.$bvToast.toast("更新しました。", {
            variant: 'success',
            title: '完了'
          });
        }
        if (!!this.$route.query.toast_type){
          this.replaceDeletedToast(to.path);
        }
      },
      // 読み込み完了後にアンカーへスクロール
      isLoading: function(to, from) {
        if(this.loadingCount === 0){
          this.scrollToAnchor(this.$route.hash)
        }
      }
    }
  }
</script>

<style scoped>
</style>
