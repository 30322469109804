<template>
  <div>
    <router-view
      :su_company.sync="su_company"
      :stages.sync="stages"
      :prefectures.sync="prefectures"
      :industries.sync="industries"
      :accept_extensions="accept_extensions"
      :is_audits.sync="is_audits"
      :is_publish_infos.sync="is_publish_infos"
    >
    </router-view>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // SU企業情報
      su_company: {
        company_name: '',
        company_hp: '',
        representative: '',
        prefecture_master_id: '',
        address: '',
        stage: '',
        industry_master_id: '',
        sub_industry_master_id: '',
        sub2_industry_master_id: '',
        business_summary: '',
        is_audit: 'unaudit',
        is_publish_info: 'publish',
        current_market_capital: '',
        last_finance_date: '',
        founding_date: '',
        user_attributes: {
          first_name: '',
          last_name: '',
          kana_first_name: '',
          kana_last_name: '',
          email: '',
          position: '',
          department: '',
          profile: '',
          account_type: 'su_user',
          position: '',
          password: '',
          uploaded_image: '',
          new_image_name: '',
          image_attributes: {
            filename: ''
          }
        }
      },

      // ステージ
      stages:{},
      // 都道府県
      prefectures: {},
      // 業界
      industries: {},
      // 許容する画像の拡張子
      accept_extensions: [],
      // 監査
      is_audits: {},
      // 情報公開許諾
      is_publish_infos: {},
    }
  },
  methods: {
    // プルダウン選択用の情報取得
    fetchRelatedParts () {
      this.$loading.load(this.$auth.api.get(`/admin/su_companies/related_parts`, {params: {path: this.$route.name}})
        .then(response => {
          this.stages = response.data.stages
          this.prefectures = response.data.prefectures
          this.industries = response.data.industries
          this.accept_extensions = response.data.accept_extensions.join();
          this.is_audits = response.data.is_audits
          this.is_publish_infos = response.data.is_publish_infos
        }).catch(err => {
          this.$errorHandlers.initial(err);
        })
      );
    },

  },
  // インスタンスが生成され､且つデータが初期化された後(beforeMountのまえ)
  created () {
  },
  // インスタンスが DOM 要素にマウントされる前(createdのあと)
  beforeMount () {
    this.fetchRelatedParts();
  },

}
</script>