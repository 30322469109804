<template>
  <div>
    <main>
      <section class="main-title d-flex justify-content-between">
        <h1>チャレンジ情報閲覧</h1>
        <form class="search-box" @submit.prevent>
          <input @keydown.enter="onSearchFreeWord" v-model="form.search_text" class="form-control" type="text" placeholder="企業名、タイトルまたは本文で検索する..." /><i class="fas fa-search"></i>
        </form>
        <button @click="onLatestInfoNew()" class="btn btn-green rounded-pill" type="button">最新情報投稿</button>
      </section>

      <p class="search-no-data" v-if="!challenges">チャレンジ情報が存在しません。</p>
      <!-- リスト-->
      <section class="reading-block-container">
        <div v-for="challenge in challenges" :key="challenge.id" class="white-block reading-block" :class="{ 'recommend-block': challenge.is_sponser == 1 }">
          <div v-if="challenge.is_sponser == 1" class="recommend-label recommend-label-index">運営ピックアップ</div>
          <div class="reading-visual">
            <div class="img-box">
              <img v-if="challenge.main_image_id"
                :src="challenge.presigned_challenge_image_url"
                :alt="challenge.main_image_title"
                class="img320"
                @click.prevent.stop="onTitleImg(challenge)"
              />
              <img v-else
                src="~images/image_default.jpg"
                alt="画像が選択されていません。"
                class="img320"
                @click.prevent.stop="onTitleImg(challenge)"
              />
            </div>
          </div>
          <div class="reading-bar">
            <small class="reading-date text-secondary">{{ challenge.release_at | format.datetime }}{{ challenge.is_sponser }}</small>
          </div>

          <h2 class="reading-title">
            <template v-if="!!challenge.short_challenge_title">
              <span
                :id="'provisional_ep_challenge-detail_' + challenge.id"
                class="popover-trigger text-ellipsis"
                tabindex="0"
                data-content=""
                data-original-title=""
                title=""
                @click.prevent.stop="onTitleImg(challenge)"
                style="text-decoration: none;"
              >
                {{ challenge.short_challenge_title }}
              </span>
              <b-popover
                :target="'provisional_ep_challenge-detail_' + challenge.id"
                triggers="hover"
                placement="top"
              >
                <div
                  style="margin: -1rem -1.5em; padding: 1rem 1.5em; position: relative; z-index: 1;"
                  @click="$root.$emit('bv::hide::popover')"
                >
                  <span :inner-html.prop="challenge.title | htmlEscape | nl2br" ></span>
                </div>
              </b-popover>
            </template>
            <template v-else>
              <span
                class="popover-trigger text-ellipsis"
                href="#"
                @click.prevent.stop="onTitleImg(challenge)"
                style="text-decoration: none;"
              >
                {{ challenge.title }}
              </span>
            </template>
          </h2>

          <dl class="reading-body mt-3">
            <dt>
              <img v-if="challenge.challenge_manager.image_id"
                :src="challenge.presigned_challenge_manager_image_url"
                :alt="challenge.presigned_challenge_manager_image_filename"
                class="thumbnail-frame img40"
              />
              <img v-else
                src="~images/ep_user_default.png"
                alt="画像が選択されていません"
                class="thumbnail-frame img40"
              />
            </dt>
            <dd>
              <p class="company-name">{{ challenge.ep_company_name_department_name }}</p>
            </dd>
          </dl>

          <div class="reading-footer">
            <button v-if="challenge.is_interested" @click="onInterest(challenge)" type="button" class='button-like active button-square btn-block'>
              <div class="icon-wrap"><i class="far fa-thumbs-up"></i></div>取り消し {{ challenge.number_of_interests }}
            </button>
            <button v-else @click="onInterest(challenge)" type="button" class='button-like button-square btn-block'>
              <div class="icon-wrap"><i class="far fa-thumbs-up"></i></div>興味あり {{ challenge.number_of_interests }}
            </button>
          </div>

        </div>

      </section>
      <!-- / リスト-->
      <Pagination :total_page="total_page" :total_count="total_count" :current_page="current_page" :current_per="current_per" :page_link_num="page_link_num" :onChangePage="onChangePage" v-if="!!challenges" />

      <!-- 興味あり-->
      <b-modal
        v-model="show_like_modal"
        id="like"
        aria-labelledby="like-Label"
        title="興味あり"
        centered
        hide-header
        hide-footer
        size="sm"
        @hidden="hiddenShowLikeModal"
        :no-fade="isNoFade"
      >
        <div>
          <p class="mt-3 text-center">興味ありを有難うございます！<br>こちらの企業様へ貴社の情報をお届けします。<br>企業様よりオファーが届いた際は<br>通知が届きますので宜しくお願いします。</p>
          <p class="mt-3 text-center">
            <input type="checkbox" id="like_checkbox" v-model="like_modal_hide"><label for="like_checkbox">&nbsp;次回からは表示しない</label>
          </p>
        </div>
        <div class="text-center">
          <button type="button" data-dismiss="modal" @click="onLikeHide()"><i class="fas fa-times"></i></button>
        </div>
      </b-modal>
      <!-- / 興味あり-->

      <!-- 遷移前確認-->
      <ConfirmModal
        ref="transition_confirm_modal"
        :onClickSend="sendProvisionalSuLatestInfosNew"
        :body_text="'最新情報作成画面へ移動します。<br>よろしいですか？'"
        :send_button_text="'実行する'"
        :isNoFade="isNoFade"
      />
      <!-- / 遷移前確認-->
    </main>
  </div>
</template>

<script>
// ページネーションのコンポーネント読み込み
import Pagination from '../../../common/pages/Pagination.vue';

// 興味ありモーダル非表示フラグ(Cookie管理)
import UseCookie from '../../../common/mixins/use_cookie';

// 確認モーダルのコンポーネント読み込み
import ConfirmModal from '../../../common/pages/ConfirmModal.vue';

export default {
  metaInfo: {
    title: 'チャレンジ情報閲覧',
  },

  mixins: [UseCookie],

  components: {
    Pagination,
  },

  data() {
    return {
      challenges: [],
      // 興味あり済のep_challenge_idが配列で入っている(興味ありボタンの画面挙動に使う)
      interest_list: [],
      // 現在ページ数
      current_page: Number(this.$route.query.page) || 1,
      // 現在の表示件数
      current_per:  0,
      // 総ページ数
      total_page: 0,
      // 総レコード数
      total_count: 0,
      // 表示するリンクの数
      page_link_num: 5,
      // 興味ありモーダル
      show_like_modal: false,
      // 興味ありモーダル：次回から表示しない
      like_modal_hide: false,
      // 最新情報未作成かどうか
      is_exist_su_latest_info: true,

      // 絞り込みフォーム
      form: {
        // フリーワード検索
        search_text: this.$route.query.search_text || "",
      },
    }
  },

  props: { isNoFade: Boolean },

  components: {
    ConfirmModal,
  },

  methods: {
    // データ取得処理
    getChallenges(query) {
      this.$loading.load(
        this.$auth.api.get(
          'su/challenges', {
            params: query
          }
        ).then(res => {
          this.challenges = res.data.challenges;
          this.interest_list = res.data.interest_list;
          this.current_per  = Number(res.data.per);
          this.current_page = Number(res.data.page);
          this.total_page   = res.data.total_page;
          this.total_count  = res.data.total_count;
          this.is_exist_su_latest_info  = res.data.is_exist_su_latest_info;
        }).catch(err => {
          this.$errorHandlers.initial(err);
        })
      )
    },

    // 検索フォームでエンター押下時
    onSearchFreeWord(event) {
      if (event.keyCode !== 13) return;
      // データ取得
      this.form.page = this.current_page = 1;
      this.form.per = this.current_per;
      this.reload(this.form);
    },

    // 興味ありボタン押下
    onInterest(challenge) {
      let target_interest_index = this.interest_list.indexOf(challenge.id);
      // 興味なし→あり
      // interest_listにep_challenge_idが存在しなかった場合
      if (target_interest_index === -1) {
        // 最新情報が未登録の場合モーダルを表示
        if (this.is_exist_su_latest_info == false) {
          this.$refs.transition_confirm_modal.open();
        } else {
          this.insertInterest(challenge);
        }
        // 興味あり→なし
      } else {
        this.deleteInterest(challenge, target_interest_index);
      }
    },

    // タイトル・サムネイル押下
    onTitleImg(challenge) {
      this.$router.push({name: 'SuChallengeShow', params: { challenge_id: challenge.id }});
    },

    // 最新情報投稿ボタン押下
    onLatestInfoNew() {
      let resolvedRoute = this.$router.resolve({
        name: 'SuProvisionalSuLatestInfoNew',
      });
      window.open(resolvedRoute.href, '_blank');
    },

    // レコード追加リクエスト
    insertInterest(challenge){
      this.$loading.load(
        this.$auth.api.post(`/su/challenges/register_interest`, {id: challenge.id}
        ).then(res => {
          challenge.number_of_interests++;
          this.interest_list.push(challenge.id);
          challenge.is_interested = !challenge.is_interested;
          challenge = '';
          if(!this.getCookieLikeModalHide()){
            this.show_like_modal = true;
          }
        }).catch(err => {
          if(err.response.status == 500) {
            this.$errorHandlers.initial(err);
          } else {
            this.$errorHandlers.ajax(err);
          }
        })
      )
    },

    // レコード削除リクエスト
    deleteInterest(challenge, target_interest_index){
      this.$loading.load(
        this.$auth.api.delete(`/su/challenges/${challenge.id}/delete_interest`
        ).then(res => {
          this.interest_list.splice(target_interest_index, 1);
          challenge.number_of_interests--;
          challenge.is_interested = !challenge.is_interested;
          challenge = '';
          this.show_like_modal = false;
        }).catch(err => {
          if(err.response.status == 500) {
            this.$errorHandlers.initial(err);
          } else {
            this.$errorHandlers.ajax(err);
          }
        })
      )
    },

    // ページ切り替え時
    onChangePage(page, per) {
      let query = Object.assign({}, this.$route.query);
      query['page'] = this.current_page = page;
      query['per'] = this.current_per = per;
      // 画面更新
      this.reload(query);
    },

    // 更新処理
    reload(query = []) {
      this.$router.push({ name: 'SuChallengeIndex', query: query }).catch(err => {});
    },

    // 興味ありモーダル閉じる
    onLikeHide() {
      this.show_like_modal = false;
    },

    // 興味ありモーダル閉じられた後にCookieに状態保存
    hiddenShowLikeModal(){
      this.setCookieLikeModalHide(this.like_modal_hide);
    },

    // 遷移確認モーダル 実行
    sendProvisionalSuLatestInfosNew(){
      this.$router.push({name: 'SuProvisionalSuLatestInfoNew'});
    },
  },

  // インスタンス生成後、リアクティブデータ初期化後
  created() {
    this.getChallenges(this.$route.query);
  },

  // route.push時に実行
  beforeRouteUpdate (to, from, next) {
    this.getChallenges(to.query);
    next();
  },


}
</script>
