<template>
  <div>
  </div>
</template>

<script>
export default {
  data() {
  return {
  }
  },
  methods: {
  },
  computed: {
  },
  created: function () {
  },
  mounted: function() {
  },
  watch: {
  }
}
</script>

<style scoped>
</style>