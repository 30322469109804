<template>
  <main>
    <Header :accountType="accountType" />
    <MiniHeader :accountType="accountType" />
    <div class="request-flow-image">
      <ul>
        <li class="flow-title"><img src="~images/request_flow_title.svg" alt="Step"></li>
        <li class="flow-01"><img src="~images/request_flow1_on.svg" alt="Step1 メールアドレス入力"></li>
        <li class="flow-02"><img src="~images/request_flow2_on.svg" alt="Step2 メール送信"></li>
        <li class="flow-03"><img src="~images/request_flow3_on.svg" alt="Step3 申請情報入力"></li>
        <li class="flow-04"><img src="~images/request_flow4_on.svg" alt="Step4 申請情報確認"></li>
        <li class="flow-05"><img src="~images/request_flow5.svg" alt="Complete 申請完了"></li>
      </ul>
    </div>
    <div class="request-inner mt-6">
      <form>
        <!-- 企業情報-->
        <section class="chapter white-block">
          <h2 class="chapter-label">企業情報</h2>

          <div class="form-wrap border-top">

            <dl class="form-group form-confirm-group">
              <dt>企業名</dt>
              <dd>{{ form.registration_request.company_name }}</dd>
            </dl>

            <dl class="form-group form-confirm-group">
              <dt>企業HP</dt>
              <dd>{{ form.registration_request.company_hp }}</dd>
            </dl>

            <dl class="form-group form-confirm-group" v-if="accountType == 'su'">
              <dt>創業日</dt>
              <dd>{{ form.registration_request.founding_date }}</dd>
            </dl>

            <dl class="form-group form-confirm-group">
              <dt>業界</dt>
              <dd>{{ form.registration_request.industry }}　{{ form.registration_request.sub_industry }}　{{ form.registration_request.sub2_industry }}</dd>
            </dl>

            <dl class="form-group form-confirm-group">
              <dt>有限責任監査法人トーマツ及びDeloitteのメンバーファームからの監査</dt>
              <dd>{{ is_audits[form.registration_request.is_audit] }}</dd>
            </dl>

            <dl class="form-group form-confirm-group" v-if="accountType == 'su'">
              <dt>情報公開許諾の有無</dt>
              <dd>{{ is_publish_infos[form.registration_request.is_publish_info] }}</dd>
            </dl>
          </div>
        </section>
        <!-- 企業情報-->

        <!-- 申請者情報-->
        <section class="chapter white-block">
          <h2 class="chapter-label">申請者情報</h2>

          <div class="form-wrap border-top">
            <dl class="form-group form-confirm-group">
              <dt>氏名</dt>
              <dd wovn-ignore>{{ form.registration_request.last_name + '　' + form.registration_request.first_name }}</dd>
            </dl>

            <dl class="form-group form-confirm-group">
              <dt>部署名</dt>
              <dd>{{ form.registration_request.department }}</dd>
            </dl>
          </div>
        </section>
        <!-- 申請者情報-->

        <!-- マイページログイン情報-->
        <section class="chapter white-block">
          <h2 class="chapter-label mt-0 mb-3">マイページログイン情報</h2>

          <div class="form-wrap border-top">
            <dl class="form-group form-confirm-group">
              <dt>ログインID</dt>
              <dd wovn-ignore>{{ form.registration_auth.email }}</dd>
            </dl>
          </div>
        </section>

        <div class="btn-container d-flex justify-content-center mt-5">
          <button class="button-square btn-cancel" type="button" @click="onModify">修正する</button>
          <button class="button-square btn-send" type="button" @click="onRegister">登録する</button>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
  import Header from "../registration_auths/Header";
  import MiniHeader from "../registration_auths/MiniHeader";

  export default {
    metaInfo: {
      title: 'six brainアカウント登録申請'
    },
    name: "Confirm",
    components: {
      Header,
      MiniHeader,
    },
    props: {
      accountType: String,
      is_audits: Object | Array,
      is_publish_infos: Object | Array,
      form: Object,
    },
    data() {
      return {
      }
    },
    methods: {
      onModify() {
        this.$router.back();
      },
      onRegister() {
        this.$loading.load(
          this.$auth.api.post('registration_requests', {
            registration_auth: this.form.registration_auth,
            registration_request: this.form.registration_request,
          }).then(res => {
            this.$router.push({path: './complete'})
          }).catch(err => {
            if(err.response.data.error.indexOf('メールアドレス') !== -1){
              this.$errorHandlers.ajax(err);
            }else{
              this.$errorHandlers.initial(err);
            }
          })
        );
      },
    },
  }
</script>

<style scoped>

</style>