<template>
  <thead>
    <tr class="divide-x divide-gray-200">
      <th
        class="whitespace-nowrap px-4 text-left text-sm font-semibold text-gray-900 py-3.5 sm:pl-0"
        scope="col"
      />
      <th
        v-for="header in firstHeaders"
        :key="`${header}_year_later`"
        class="whitespace-nowrap px-4 text-left text-sm font-semibold text-gray-900 py-3.5 sm:pr-0"
        scope="col"
      >
        {{ header }}
      </th>
    </tr>
    <tr class="divide-x divide-gray-200">
      <th
        class="whitespace-nowrap px-4 text-left text-sm font-semibold text-gray-900 py-3.5 sm:pl-0"
        scope="col"
      >
        {{ displayCurrencyUnit }}
      </th>
      <th
        v-for="header in secondHeaders"
        :key="`calendar_${header}`"
        class="whitespace-nowrap px-4 text-left text-sm font-semibold text-gray-900 py-3.5 sm:pl-0"
        scope="col"
      >
        {{ header }}
      </th>
    </tr>
  </thead>
</template>

<script>
  export default {
    props: {
      columns: {
        type: Array,
        required: true,
      },
      displayCurrencyUnit: {
        type: String,
        required: true,
      },
    },
    computed: {
      thisYear() {
        return Number(new Date().getFullYear());
      },
      firstHeaders() {
        return this.columns.map(v => v === 'current' ? '現時点' : `${v}年後`);
      },
      secondHeaders() {
        return this.columns.map(v => v === 'current' ? '' : `${this.thisYear + v}年1月期`);
      },
    },
  };
</script>
<style src="../tailwind.css" scoped>
</style>
