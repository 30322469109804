<template>
  <main>
    <section class="main-title">
      <h1>子アカウント作成</h1>
    </section>

    <form @submit.prevent="confirmEpPersonInCharge" class="needs-validation" novalidate>
      <!-- 子アカウント登録-->
      <section class="chapter white-block">
        <div class="form-wrap d-flex">
          <div class="left-col drop_area" @dragover.prevent="onArea" @drop.prevent="dropFile" @dragleave.prevent="offArea" @dragend.prevent="offArea">
            <!-- 既に保存されている画像 -->
            <img v-if="user.image_attributes.filename"
              class="thumbnail-frame img180 mx-auto mb-3"
              :src="user.presigned_url"
              :alt="user.image_attributes.filename"
            />
            <!-- 新しく画像が選択された -->
            <img v-else-if="!!(user.uploaded_image)" class="thumbnail-frame img180 mx-auto mb-3"
              :src="user.uploaded_image"
              :alt="user.new_image_name"
            />
            <img v-else
              class="thumbnail-frame img180 mx-auto mb-3"
              src="~images/ep_user_default.png"
              alt="株式会社シックスブレイン logo"
            />
            <p v-if="!!errors['image']" class="invalid-feedback d-block">{{ errors['image'][0] }}</p>
            <p v-if="!!errors['filename']" class="invalid-feedback d-block">{{ errors['filename'][0]}}</p>
            <p v-if="!!errors['image_size']" class="invalid-feedback d-block">{{ errors['image_size'][0]}}</p>
            <div class="btn-group row" role="group" aria-label="画像アップロード">
              <button @click="removeUser" class="btn btn-cancel icon-addon-left col-4" type="button" ><i class="fas fa-times"></i>削除</button>
              <label class="btn btn-file icon-addon-left col-8" for="user-file-upload"><i class="fas fa-arrow-up"></i>アップロード
                <input type="file" id="user-file-upload" @change="onUserFileChange" :accept="accepted_extensions">
              </label>
            </div>
          </div>
          <div class="right-col">
            <fieldset>
              <div class="form-row">
                <div class="form-group col-6">
                  <label class="required-addon" for="administrator-name1">姓<span class="badge badge-danger">必須</span></label>
                  <b-form-input v-model="user.last_name" :state="checkValid('last_name')" type="text" class="form-control" id="administrator-name1" placeholder="例) デロイト" wovn-ignore></b-form-input>
                  <p v-if="!!errors['last_name']" class="invalid-feedback d-block">{{ errors['last_name'][0] }}</p>
                </div>
                <div class="form-group col-6">
                  <label class="required-addon" for="administrator-name2">名<span class="badge badge-danger">必須</span></label>
                  <b-form-input v-model="user.first_name" :state="checkValid('first_name')" type="text" class="form-control" id="administrator-name2" placeholder="例) 太郎" wovn-ignore></b-form-input>
                  <p v-if="!!errors['first_name']" class="invalid-feedback d-block">{{ errors['first_name'][0] }}</p>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <div class="form-row">
                <div class="form-group col-6">
                  <label class="required-addon" for="administrator-name1-kana">セイ<span class="badge badge-secondary">任意</span></label>
                  <b-form-input v-model="user.kana_last_name" :state="checkValid('kana_last_name')" type="text" class="form-control" id="administrator-name1-kana" placeholder="例) デロイト" wovn-ignore></b-form-input>
                  <p v-if="!!errors['kana_last_name']" class="invalid-feedback d-block">{{ errors['kana_last_name'][0] }}</p>
                </div>
                <div class="form-group col-6">
                  <label class="required-addon" for="administrator-name2-kana">メイ<span class="badge badge-secondary">任意</span></label>
                  <b-form-input v-model="user.kana_first_name" :state="checkValid('kana_first_name')" type="text" class="form-control" id="administrator-name2-kana" placeholder="例) タロウ" wovn-ignore></b-form-input>
                  <p v-if="!!errors['kana_first_name']" class="invalid-feedback d-block">{{ errors['kana_first_name'][0] }}</p>
                </div>
              </div>
            </fieldset>
            <div class="form-group">
              <label class="required-addon" for="administrator-email">メールアドレス（ログインID）<span class="badge badge-danger">必須</span></label>
              <b-form-input v-model="user.email" type="email" :state="checkValid('email')" class="form-control" placeholder="例) t-deloitte@sixbrain.co.jp" wovn-ignore></b-form-input>
              <p v-if="!!errors['email']" class="invalid-feedback d-block">{{ errors['email'][0] }}</p>
            </div>

            <div class="form-group">
              <label class="required-addon" for="administrator-title">役職名<span class="badge badge-secondary">任意</span></label>
              <b-form-input v-model="user.position" :state="checkValid('position')" class="form-control" type="text" id="administrator-title" placeholder="例) 課長"></b-form-input>
              <p v-if="!!errors['position']" class="invalid-feedback d-block">{{ errors['position'][0] }}</p>
            </div>

            <div class="form-group">
              <label class="required-addon" for="administrator-department">部署名<span class="badge badge-secondary">任意</span></label>
              <b-form-input v-model="user.department" :state="checkValid('department')" class="form-control" type="text" id="administrator-department" placeholder="例) 経営戦略部"></b-form-input>
              <p v-if="!!errors['department']" class="invalid-feedback d-block">{{ errors['department'][0] }}</p>
            </div>

            <div class="form-group">
              <label class="required-addon" for="administrator-password">パスワード<span class="badge badge-danger">必須</span></label>
              <div class="input-group icon-addon-right">
                <b-form-input v-model="user.password" :state="checkValid('password')" class="form-control" :type="toggle_password" autocomplete="new-password" id="administrator-password" placeholder="" aria-describedby="password-help" wovn-ignore></b-form-input>
              </div><small class="form-text text-secondary" id="password-help">・半角英数字・記号、10文字以上<br>・英小文字、英大文字、数字、記号の４つの文字種のうち、３つ以上の文字種を含む</small>
              <p v-if="!!errors['password']" class="invalid-feedback d-block">{{ errors['password'][0] }}</p>
            </div>
            <div class="form-group">
              <label class="required-addon" for="administrator-password2">パスワード（確認用）<span class="badge badge-danger">必須</span></label>
              <div class="input-group icon-addon-right">
                <b-form-input v-model="user.password_confirmation" :state="checkValid('password_confirmation')" class="form-control" :type="toggle_password_confirm" autocomplete="new-password" id="administrator-password2" placeholder="" wovn-ignore></b-form-input>
              </div>
              <p v-if="!!errors['password_confirmation']" class="invalid-feedback d-block">{{ errors['password_confirmation'][0] }}</p>
            </div>
            <div class="form-group">
              <label class="required-addon" for="administrator-profile">プロフィール<span class="badge badge-secondary">任意</span></label>
              <b-form-textarea v-model="user.profile" :state="checkValid('profile')" type="text" class="form-control"></b-form-textarea>
              <p v-if="!!errors['profile']" class="invalid-feedback d-block">{{ errors['profile'][0] }}</p>
            </div>
          </div>
        </div>
      </section>
      <!-- 子アカウント登録-->

      <div class="btn-container d-flex justify-content-center">
        <button class="button-square btn-send" type="submit">確認する</button>
      </div>
    </form>
  </main>
</template>

<script>
  import Form from '../../../common/mixins/form'
  // アップロード画像チェック
  import UploadFileCheck  from "../../../common/mixins/upload_file_check"
  export default {

    metaInfo: {
      title: '子アカウント作成'
    },

    mixins: [Form, UploadFileCheck],

    data: function () {
      return {
        errors: '',
        was_valid: false,
        uploaded_image: this.prop_user_uploaded_image,
        img_name: this.prop_user_img_name,
        toggle_password: 'password',
        toggle_password_confirm: 'password',
      }
    },
    props: {
      user: Object,
      accepted_extensions: Array,
      prop_user_uploaded_image: String,
      prop_user_img_name: String,
    },

    methods: {
      confirmEpPersonInCharge: function(){
        this.$loading.load(this.$auth.api.patch('ep/ep_person_in_charges/confirm', {
          user: this.user
        })
        .then(response => {
          this.$store.commit('ep_person_in_charge/setUser', {
            user: this.user,
          });
          this.$router.push({name: 'EpPersonInChargeNewConfirm'});
        })
        .catch(error => {
          if(error.response.status == 422) {
            if (error.response.data && error.response.data.errors) {
              this.errors = error.response.data.errors;
              // 確認ボタン押下の確認、falseの時はis-invalidが付与されない
              this.was_valid = true
            }
            // エラー時ページトップにスクロール
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
          }else if(error.response.status == 500) {
            this.$errorHandlers.initial(error);
          }else{
            this.$errorHandlers.ajax(error);
          }
        }));
      },

      // 部署管理者ユーザー画像
      onUserFileChange(e) {
        const files = e.target.files || e.dataTransfer.files;

        // ファイルチェック(/common/mixins/upload_file_check.jsを参照)
        let image_file_error = this.checkUploadImage(files[0]);
        if (image_file_error != ''){
          // エラーの場合はトースト表示
          this.$bvToast.toast(image_file_error, { variant: 'danger', title: 'エラー'});
          return;
        }

        this.user.filename = ''
        this.createUserImage(files[0]);
        this.user.new_image_name = files[0].name;
        this.user.image_size = files[0].size;
      },
      createUserImage(file) {
        const reader = new FileReader();
        reader.onload = e => {
          this.user.uploaded_image = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeUser() {
        this.user.uploaded_image = false;
        this.user.image_attributes.filename = '';
        this.user.uploaded_image = '';
        this.user.new_image_name = '';
        this.user.image_size = '';
      },
      dropFile(e) {
          this.onUserFileChange(e)
          this.offArea()
      },
      onArea  () { this.inArea = true  },
      offArea () { this.inArea = false },

      changePasswordStatus() {
        if(this.toggle_password === 'password'){
          this.toggle_password = 'text';
        }else{
          this.toggle_password = 'password';
        }
      },
      changePasswordConfirmStatus() {
        if(this.toggle_password_confirm === 'password'){
          this.toggle_password_confirm = 'text';
        }else{
          this.toggle_password_confirm = 'password';
        }
      }
    },
  }
</script>
