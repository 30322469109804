export default {
  install: function (Vue, options) {
	  const Loading = {
		load: (promise) => {
			Loading.startLoading();
			return promise.then(Loading.endLoading).catch(Loading.endLoading);
		},
		startLoading: null,
		endLoading: null,
	  }
	Vue.mixin({
		beforeCreate: function(){
			this.$loading = Loading;
		}
	})
  }
}
