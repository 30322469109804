<template>
  <main>
    <section class="main-title">
      <h1>運営へのお問い合わせ</h1>
    </section>

    <section class="chapter white-block message message-list">
      <message-room
        ref="message_room"
        :room-id="this.$route.params.room_id"
        :account-type="this.accountType"
        :current-user-id="currentUserId"
        :isNoFade="isNoFade"
      />
    </section>

    <div class="btn-container d-flex justify-content-center pt-2">
      <button class="btn btn-big btn-white rounded-pill" @click="onClickBack()" type="button" data-toggle="modal" data-target="#back">もどる</button>
    </div>

    <!-- 一覧へ戻るモーダル -->
    <confirm-history-back-modal
      ref="confirm_history_back_modal"
      :body_text="'入力内容を破棄して戻ります。<br>よろしいですか？'"
      :index_page_name="'HistoryBack'"
      :send_button_text="'もどる'"
      :cancel_button_text="'編集を続ける'"
      :isNoFade="isNoFade"
    />
    <!-- /一覧へ戻るモーダル -->
  </main>
</template>

<script>
import MessageRoom from '../message/room_scroll'
import ConfirmHistoryBackModal from './ConfirmBackToIndexModal';

export default{
  metaInfo: {
    title: '運営へのお問い合わせ',
    bodyAttrs: {
      class: ['']
    }
  },
  components:{
    MessageRoom,
    ConfirmHistoryBackModal
  },
  props: ['accountType', 'isNoFade', 'currentUserId'],
  data(){
    return {
      roomId: this.$route.params.room_id,
    }
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.$loading.load(
        this.$auth.api.get(
          `${this.accountType}/message_rooms/${this.roomId}`
        ).catch(err => {
          this.$errorHandlers.initial(err);
        })
      )
    },

    // もどるボタン押下時処理
    onClickBack(){
      // 既存メッセージ編集中でない かつ メッセージ入力欄が空の場合は確認モーダル表示しない
      if(this.$refs.message_room.edit_id == null && this.$refs.message_room.message == ''){
        this.$refs.confirm_history_back_modal.onSend();
      }else{
        this.$refs.confirm_history_back_modal.open()
      }
    },
  },

}
</script>
