<template>
  <div>
    <main>
      <section class="main-title">
        <h1>大手企業プッシュ登録<span class="small"> - 確認</span></h1>
      </section>
      <form @submit.prevent="createEpCompany">
        <section class="chapter white-block">
          <h2 class="chapter-label">企業情報</h2>
          <div class="form-wrap border-top">
            <dl class="form-group form-confirm-group">
              <dt>企業名</dt>
              <dd>{{ep_company.company_name}}</dd>
            </dl>

            <dl class="form-group form-confirm-group">
              <dt>本社所在地</dt>
              <dd><span v-if="ep_company.prefecture_master_id!=''">{{headOfficeLocation}}</span></dd>
            </dl>

            <dl class="form-group form-confirm-group">
              <dt>企業HP</dt>
              <dd>{{ep_company.company_hp}}</dd>
            </dl>

            <dl class="form-group form-confirm-group">
              <dt>業界</dt>
              <dd>{{ industryName }}&nbsp;&nbsp;&nbsp;&nbsp;{{ subIndustryName }}</dd>
            </dl>

            <dl class="form-group form-confirm-group">
              <dt>私たちについて</dt>
              <dd>{{ep_company.business_summary}}</dd>
            </dl>

            <dl class="form-group form-confirm-group">
              <dt>有限責任監査法人トーマツ及びDeloitteのメンバーファームからの監査</dt>
              <dd>{{auditMessage}}</dd>
            </dl>

            <dl class="form-group form-confirm-group">
              <dt>有料会員機能の利用</dt>
              <dd>{{membershipMessage}}</dd>
            </dl>
          </div>
        </section>

        <!-- 管理者情報-->
        <section class="chapter white-block">
          <h2 class="chapter-label">管理者情報</h2>
          <div class="form-wrap d-flex border-top">
            <div class="left-col">
              <img
                v-if="ep_company.ep_admin_user_attributes.uploaded_image"
                class="thumbnail-frame img180 preview-item-file"
                :src="ep_company.ep_admin_user_attributes.uploaded_image"
                :alt="ep_company.ep_admin_user_attributes.new_image_name"
              />
              <img v-else
                class="thumbnail-frame img180 mx-auto mb-3"
                src="~images/ep_user_default.png"
                alt="画像が選択されていません"
              />
            </div>
            <div class="right-col">
              <dl class="form-group form-confirm-group">
                <dt>氏名</dt>
                <dd>{{fullName(ep_company.ep_admin_user_attributes)}}</dd>
              </dl>

              <dl class="form-group form-confirm-group">
                <dt>氏名（フリガナ）</dt>
                <dd>{{fullKanaName(ep_company.ep_admin_user_attributes)}}</dd>
              </dl>

              <dl class="form-group form-confirm-group">
                <dt>メールアドレス（ログインID）</dt>
                <dd>{{ep_company.ep_admin_user_attributes.email}}</dd>
              </dl>

              <dl class="form-group form-confirm-group">
                <dt>役職名</dt>
                <dd>{{ep_company.ep_admin_user_attributes.position}}</dd>
              </dl>

              <dl class="form-group form-confirm-group">
                <dt>部署名</dt>
                <dd>{{ep_company.ep_admin_user_attributes.department}}</dd>
              </dl>

              <dl class="form-group form-confirm-group">
                <dt>プロフィール</dt>
                <dd>{{ep_company.ep_admin_user_attributes.profile}}</dd>
              </dl>

            </div>
          </div>
        </section>
        <!-- 管理者情報-->

        <div class="btn-container d-flex justify-content-center">
          <button class="button-square btn-cancel" type="button" @click="onClickBackPage()">修正する</button>
          <button type="submit" class="button-square btn-send">登録する</button>
        </div>
      </form>
    </main>
  </div>
</template>

<script>
  export default {
    metaInfo: {
      title: '大手企業 プッシュ登録',
    },
    props: {
      ep_company: Object,
      is_audits: Object,
      membership_types: Object,
      industries: Object,
      prefectures: Object
    },
    methods: {
      createEpCompany: function(){
        this.$loading.load(this.$auth.api.post('admin/ep_companies', {
          ep_company: this.ep_company,
          users_attributes: this.ep_company.users_attributes
        })
        .then(response => {
          this.$store.commit('ep_company/initEpCompany');
          this.$router.push({name: 'AdminEpCompanyComplete', params: { id: response.data.id }});
        })
        .catch(error => {
          if(error.response.status == 500) {
            this.$errorHandlers.initial(error);
          }else{
            this.$errorHandlers.ajax(error);
          }
        }));
      },
      fullName: function (user) {
        return `${user.last_name} ${user.first_name}`
      },
      fullKanaName: function (user) {
        return user.kana_last_name == '' || user.kana_last_name == null ? '' : `${user.kana_last_name} ${user.kana_first_name}`
      },
      toBoolean: function(value) {
        value = typeof(value) == 'string' ? value.toLowerCase() : value
        return (value == true || value == 'true') ? true : false
      },
      onClickBackPage: function () {
        this.ep_company.ep_admin_user_attributes.password = ''
        this.ep_company.ep_admin_user_attributes.password_confirmation = ''
        this.$router.push('new');
      },
    },
    computed: {
      industryName: function () {
        return this.industries[this.ep_company.industry_master_id];
      },
      subIndustryName: function () {
        return this.industries[this.ep_company.sub_industry_master_id];
      },
      headOfficeLocation: function () {
        return this.ep_company.prefecture_master_id == '' || this.ep_company.prefecture_master_id == null ? '' : `${this.prefectures[this.ep_company.prefecture_master_id]}${this.ep_company.address}`
      },
      auditMessage: function () {
        return this.is_audits[this.ep_company.is_audit]
      },
      membershipMessage: function () {
        return this.membership_types[this.ep_company.membership_type]
      },

    },
    filters: {
      numberWithDelimiter: function (value) {
        if (!value) {
          return '0'
        }
        return value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,')
      }
    }
  }
</script>
