<template>
  <div>
    <main>
      <section class="main-title">
        <h1>ポートフォリオ一覧</h1>
      </section>

      <!-- リスト-->
      <section class="chapter white-block">
        <div class="btn-container d-flex justify-content-between mb-3">
          <button @click="$router.push({ name: 'EpPortfolioNew' })" class="btn btn-green rounded-pill" type="button">ポートフォリオ追加</button>
        </div>

        <div class="table-responsive">
          <table class="table table-striped table-hover">
            <thead class="thead-light">
              <tr>
                <th class="w-50">企業名</th>
                <th>決算期</th>
                <th>貨幣単位</th>
                <th class="w-5"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="portfolio in portfolios" :key="portfolio.id">
                <td>
                  <router-link :to="{ name: 'EpPortfolioShow', params: { id: portfolio.id }}">
                    {{ portfolio.company_name }}
                  </router-link>
                </td>
                <td>{{ portfolio.fiscal_month }}月</td>
                <td>{{ portfolio.unit_name }}</td>
                <td class="text-right">
                  <button @click="onDelete(portfolio)" class="btn-action btn btn-sm btn-icon mx-1" type="button" data-toggle="modal" data-target="#delete">
                    <i class="fas fa-trash" v-b-tooltip.hover="'削除'"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <!-- リスト-->

      <ConfirmModal
        ref="delete_confirm_modal"
        :onClickSend="onDeleteConfirmSend"
        :body_text="'ポートフォリオを削除します。<br>よろしいですか？'"
        :send_button_text="'削除する'"
        :isNoFade="isNoFade"
      />
    </main>
  </div>
</template>

<script>

  import form from '../../../common/mixins/form.js'
  // 確認モーダルのコンポーネント読み込み
  import ConfirmModal from '../../../common/pages/ConfirmModal.vue';

  export default {
    mixins: [form],

    metaInfo: {
      title: 'ポートフォリオ',
    },

    components: {
      ConfirmModal
    },

    props: {
      isNoFade: Boolean
    },

    data() {
      return {
        // ポートフォリオ情報
        portfolios: [],
        // 削除対象ID
        delete_target_id: '',
        delete_target_updated_at: '',
      }
    },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.getPortfolios(this.form);
    },

    mounted() {
    },

    // route.push時に実行
    beforeRouteUpdate (to, from, next) {
      this.getPortfolios(to.query);
      next();
    },

    // メソッド定義
  methods: {
      // ポートフォリオ情報取得
      getPortfolios(query) {
        this.$loading.load(
          this.$auth.api.get(
            'ep/portfolios', {
              params: query
            }
          ).then(res => {
            this.portfolios = res.data.portfolios
          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        )
      },

      // 削除ボタン押下時
      onDelete(portfolio) {
        this.delete_target_id = portfolio.id;
        this.delete_target_updated_at = portfolio.updated_at;
        this.$refs.delete_confirm_modal.open();
      },

      // 削除確認モーダル内の削除するボタン押下時
      onDeleteConfirmSend() {
        this.$loading.load(
          this.$auth.api.delete(
            'ep/portfolios/' + this.delete_target_id, {
              params: { updated_at: this.delete_target_updated_at }
            }
          ).then(res => {
            this.$refs.delete_confirm_modal.close();
            this.delete_target_id = '';
            this.$bvToast.toast('削除しました。', {
              variant: 'success',
              title: '完了'
            });
            this.getPortfolios(this.$route.query);
          }).catch(err => {
            this.$refs.delete_confirm_modal.close();
            if(err.response.status == 500) {
              this.$errorHandlers.initial(err);
            } else {
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },
    },
  }
</script>
