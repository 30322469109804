<template>
  <main>
    <section class="main-title">
      <h1>
        {{ ep_company }}{{ su_company }}
      </h1>
    </section>
    <div v-if="message_room && !!message_room.id">
      <section class="chapter white-block message message-list">
        <message-room
          ref="message_room"
          :room-id="message_room.id"
          :account-type="'admin'"
          :current-user-id="$attrs.currentUserId"
          :isNoFade="isNoFade"
        />
      </section>
    </div>

    <div class="btn-container d-flex justify-content-center pt-2">
      <button @click="onClickBack()" class="btn btn-big btn-white rounded-pill" type="button">もどる</button>
    </div>

    <confirm-back-to-index-modal
      ref="back_to_index_confirm_modal"
      :body_text="'一覧画面に戻ります。<br>よろしいですか？'"
      :index_page_name="'AdminMessageIndex'"
      :isNoFade="isNoFade"
    />

  </main>
</template>

<script>

  // メッセージルームのコンポーネント読み込み
  import MessageRoom from '../../../common/message/room'
  // 一覧戻る確認モーダル
  import ConfirmBackToIndexModal from '../../../common/pages/ConfirmBackToIndexModal';
  // 削除データ表示用コンポーネント
  import WithDeleted  from "../../../common/mixins/with_deleted"

  export default {
    mixins: [WithDeleted],

    metaInfo: {
      title: 'メッセージ詳細',
    },

    components: {
      MessageRoom,
      ConfirmBackToIndexModal,
    },

    props: { isNoFade: Boolean },

    data() {
      return {
        room_id: this.$route.params.room_id,
        message_room: {},
        ep_company: '',
        su_company: '',
      }
    },

    // インスタンス生成後
    created() {
      this.init();
    },

    // メソッド定義
    methods: {
      init() {
        this.$loading.load(
          this.$auth.api.get(
            'admin/message_rooms/' + this.room_id, {
              params: {
              }
            }
          ).then(res => {
            this.message_room = res.data.message_room;
            if (res.data.message_room.company.ep_company_name) {this.ep_company = res.data.message_room.company.ep_company_name;}
            if (res.data.message_room.company.su_company_name) {this.su_company = res.data.message_room.company.su_company_name;}
          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        )
      },

      // もどるボタン押下時処理
      onClickBack(){
        // 既存メッセージ編集中でない かつ メッセージ入力欄が空の場合は確認モーダル表示しない
        if(this.$refs.message_room.edit_id == null && this.$refs.message_room.message == ''){
          this.$refs.back_to_index_confirm_modal.onSend();
        }else{
          this.$refs.back_to_index_confirm_modal.open();
        }
      }
    }
  }

</script>
