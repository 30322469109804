<template>
  <div>
    <main>
      <section class="main-title">
        <h1>ポートフォリオ詳細</h1>
      </section>

      <!-- 基本情報-->
      <section class="chapter white-block mb-5">
        <h2 class="chapter-label mb-3">詳細情報</h2>
        <div class="btn-container edit-btn-position">
          <button class="button-square btn-sm btn-send" type="button" @click="$refs.edit_modal.open();">編集する<i class="fas fa-clone pl-2"></i></button>
        </div>
        <div class="show" id="screen-data">
          <div class="overview-list overview-list-dt15 my-3 p-3">
            <dl class="form-confirm-group">
              <dt>企業名</dt>
              <dd>{{ portfolio.company_name }}</dd>
            </dl>

            <dl class="form-confirm-group">
              <dt>決算期</dt>
              <dd>{{ portfolio.fiscal_month }}月</dd>
            </dl>

            <dl class="form-confirm-group">
              <dt>通貨</dt>
              <dd>{{ portfolio.unit_name }}</dd>
            </dl>
          </div>
        </div>
      </section>

      <!-- シナリオ一覧 -->
      <section class="main-title d-flex justify-content-between align-items-center">
        <h1 class="mb-0">シナリオ一覧</h1>
        <div>
          <a
            href="https://c2p2-poc.vercel.app/dashboard"
            target="_blank"
            class="btn btn-md btn-outline-green rounded-pill mr-2"
          >
            他社のバリュエーションを見る
          </a>
          <button class="btn btn-green rounded-pill" type="button" @click="$router.push({ name: 'EpScenarioNew', params: {id: portfolio.id }})">シナリオ追加</button>
        </div>
      </section>

      <!-- リスト -->
      <section class="scenario-block-container">
        <!--ステータスラベルのクラス分け
        status-1: 投資検討中
        status-2: 投資実行済
        status-3: EXIT済
        -->
        <div class="white-block scenario-block" v-for="scenario in scenarios" :key="scenario.id">
          <a v-if="!scenario.is_temporary" @click="$router.push({ name: 'EpScenariosShowStep1', params: {id: scenario.id }})" data-value="true"></a>
          <a v-else @click="$router.push({ name: 'EpScenarioEdit', params: {id: scenario.id }})" data-value="false"></a>
          <div class="btn-container">
            <button @click="onCopy(scenario)" class="btn btn-sm btn-icon" type="button" >
              <i class="far fa-copy" v-b-tooltip.hover="'複製'"></i>
            </button>
            <button @click="onDelete(scenario)" class="btn btn-sm btn-icon" type="button" >
              <i class="fas fa-trash" v-b-tooltip.hover="'削除'"></i>
            </button>
          </div>

          <h2 class="chapter-label">{{ scenario.name || "未設定" }}</h2>

          <div class="scenario-block-body border-top">
            <dl class="scenario-block-list name">
              <dt>{{ portfolio.company_name }}</dt>
              <dd><span class="badge badge-md" :class="`status-${scenario.scenario_status}`">{{ scenario.scenario_status_i18n }}</span></dd>
            </dl>
            <dl class="scenario-block-list valuation">
              <dt>株式評価資産額<span>Post-Valuation</span></dt>
              <dd>{{ digitSeparator(calcValuationWithMillion(scenario)) }}<span>{{ portfolio.unit_iso_code ==='JPY' ? '百万円' : `M ${portfolio.unit_iso_code}` }}</span></dd>
            </dl>
            <dl class="scenario-block-list date">
              <dt>最終更新日付</dt>
              <dd>{{ scenario.updated_at | format.datetime_padding }}</dd>
            </dl>
          </div>
        </div>
      </section>
      <!-- ボタンエリア-->
      <div class="btn-container space-lg d-flex justify-content-center">
        <a class="btn btn-big btn-white rounded-pill miw-15em" type="button" @click="$router.push({ name: 'EpPortfolioIndex'})">ポートフォリオ一覧にもどる</a>
      </div>

      <EditModal
        ref="edit_modal"
        :onClickSave="onClickSave"
        :portfolio="portfolio"
        :currency_units="currency_units"
        :isNoFade="isNoFade"
      />

      <ConfirmModal
        ref="copy_confirm_modal"
        :onClickSend="onCopyConfirmSend"
        :body_text="'シナリオを複製します。<br>よろしいですか？'"
        :send_button_text="'複製する'"
        :isNoFade="isNoFade"
      />

      <ConfirmModal
        ref="delete_confirm_modal"
        :onClickSend="onDeleteConfirmSend"
        :body_text="'シナリオを削除します。<br>よろしいですか？'"
        :send_button_text="'削除する'"
        :isNoFade="isNoFade"
      />
    </main>
  </div>
</template>

<script>
import form from '../../../common/mixins/form.js'
// 確認モーダル
import ConfirmModal from '../../../common/pages/ConfirmModal.vue';
// 編集モーダル
import EditModal from './EditModal.vue';
// シナリオ詳細mixins
import ScenariosShowMixins from '../scenarios/show/scenarios_show_mixins.js';

export default {
  mixins: [form, ScenariosShowMixins],

  metaInfo: {
    title: 'シナリオ一覧',
  },

  components: {
    ConfirmModal,
    EditModal,
  },

  props: {
    isNoFade: Boolean
  },

  data() {
    return {
      // ポートフォリオ情報
      portfolio: {},
      // シナリオ一覧
      scenarios: [],
      // 削除対象ID
      target_scenario: {},
      // ポートフォリオ編集の貨幣単位一覧
      currency_units: {},
    }
  },

  // インスタンス生成後、リアクティブデータ初期化後
  created() {
    this.getScenarios(this.form);
  },

  mounted() {
  },

  // route.push時に実行
  beforeRouteUpdate (to, from, next) {
    this.getScenarios(to.query);
    next();
  },

  // メソッド定義
  methods: {
    // シナリオ一覧取得
    getScenarios(query) {
      this.$loading.load(
        this.$auth.api.get(
          `ep/portfolios/${this.$route.params.id}`, {
          params: query
        }
        ).then(res => {
          this.portfolio = res.data.portfolio;
          this.scenarios = res.data.scenarios;
          this.currency_units = res.data.currency_units;
        }).catch(err => {
          this.$errorHandlers.initial(err);
        })
      );
    },

    // ポートフォリオ編集モーダルの保存ボタン押下時
    onClickSave() {
      this.$bvToast.toast("更新しました。", {
        variant: 'success',
        title: '完了'
      });
      this.getScenarios(this.$route.query);
    },

    // 複製ボタン押下時
    onCopy(scenario) {
      this.target_scenario = { ...scenario };
      this.$refs.copy_confirm_modal.open();
    },

    // 削除ボタン押下時
    onDelete(scenario) {
      this.target_scenario = scenario;
      this.$refs.delete_confirm_modal.open();
    },

    // 複製確認モーダルの複製するボタン押下時
    onCopyConfirmSend() {
      this.target_scenario.id = null;
      this.$loading.load(
        this.$auth.api.post(
          'ep/scenarios', this.target_scenario
        ).then(res => {
          this.$refs.copy_confirm_modal.close();
          this.target_scenario = {};
          this.$bvToast.toast('複製しました。', {
            variant: 'success',
            title: '完了'
          });
          this.getScenarios(this.$route.query);
        }).catch(err => {
          this.$refs.delete_confirm_modal.close();
          if(err.response.status == 500) {
            this.$errorHandlers.initial(err);
          } else {
            this.$errorHandlers.ajax(err);
          }
        })
      )
    },

    // 削除確認モーダルの削除するボタン押下時
    onDeleteConfirmSend() {
      this.$loading.load(
        this.$auth.api.delete(
          'ep/scenarios/' + this.target_scenario.id, {
            params: { updated_at: this.target_scenario.updated_at }
          }
        ).then(res => {
          this.$refs.delete_confirm_modal.close();
          this.target_scenario = {};
          this.$bvToast.toast('削除しました。', {
            variant: 'success',
            title: '完了'
          });
          this.getScenarios(this.$route.query);
        }).catch(err => {
          this.$refs.delete_confirm_modal.close();
          if(err.response.status == 500) {
            this.$errorHandlers.initial(err);
          } else {
            this.$errorHandlers.ajax(err);
          }
        })
      )
    },

    // 更新処理
    reload(query = []) {
      this.$router.push({ name: 'EpPortfolioShow', query: query }).catch(err => {});
    },
  },
}
</script>
