<template>
<main>
  <section class="main-title">
    <h1>ユーザー詳細</h1>
  </section>

  <section class="chapter white-block">
    <div class="form-wrap d-flex">
      <div class="left-col">
        <img class="thumbnail-frame img180" v-if="!!image_attributes.filename" :src="user.presigned_url" alt="プロフィール">
        <img class="thumbnail-frame img180" v-else src="~images/dtvs_user_default.png" alt="プロフィール">
        <p class="login-time mt-3">{{user.last_login}}</p>
      </div>
      <div class="right-col">
        <dl class="form-group form-confirm-group">
          <dt>氏名</dt>
          <dd>{{user.last_name}} {{user.first_name}}</dd>
        </dl>
        <dl class="form-group form-confirm-group">
          <dt>氏名（フリガナ）</dt>
          <dd>{{user.kana_last_name}} {{user.kana_first_name}}</dd>
        </dl>
        <dl class="form-group form-confirm-group">
          <dt>メールアドレス（ログインID）</dt>
          <dd>{{user.email}}</dd>
        </dl>
        <dl class="form-group form-confirm-group">
          <dt>役職名</dt>
          <dd>{{user.position}}</dd>
        </dl>
        <dl class="form-group form-confirm-group">
          <dt>部署名</dt>
          <dd>{{user.department}}</dd>
        </dl>
        <dl class="form-group form-confirm-group">
          <dt>アカウント種別</dt>
          <dd>{{user.account_type_i18n}}</dd>
        </dl>
      </div>
    </div>
    <div class="btn-container d-flex justify-content-center">
      <button class="btn btn-medium btn-outline-green rounded-pill" type="button" v-if="$auth.editableAccount(user.email)" @click="$router.push({name: 'AdminUserEditForm', id: user.id}, () => {})">編集する</button>
    </div>
  </section>

  <!-- 2段階認証設定(本人なら設定可、管理者は本人以外の無効化可) -->
  <TwoFactorAuth
    editUrl="AdminTwoFactorAuthEdit"
    :user_id="user.id"
    :isSetting="$auth.twoFactorAuthEnableAccount(user.email, user.id)"
    :isInActivate="accountType == 'dtvs_admin'"
    :otpRequiredForLogin="user.otp_required_for_login"
    @reloadFunc="setData"
  />
  <!-- 2段階認証設定 -->

  <div class="btn-container d-flex justify-content-center pt-2">
    <button class="btn btn-big btn-white rounded-pill" type="button" @click="$router.push({ name: 'AdminUserIndex' })">ユーザー一覧へ</button>
  </div>

</main>
</template>

<script>
// 2段階認証設定コンポーネント
import TwoFactorAuth from '../../../common/pages/two_factor_auth/Show.vue';

export default {
  name: 'UserShow',
  metaInfo: {
    title: 'ユーザー詳細',
  },

  components: {
    TwoFactorAuth
  },
  watch:{
    '$route'(to, from){
      this.setData();
    }
  },

  props: { isNoFade: Boolean, accountType: String },

  data(){
    return {
      user: {},
      image_attributes: {id: "", filename: ""}
    }
  },
  methods: {
    setData () {
      this.$loading.load(this.$auth.api.get(`admin/users/${this.$route.params.id}.json`)
        .then(response => {
          this.user = response.data.user
          this.image_attributes = response.data.user.image_attributes
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
    },
  },
  mounted(){
    this.setData();
  }
}
</script>
