<template>
  <div class="main-area">
    <main>
      <div>
        <section class="main-title">
          <h1>リコメンド後データ詳細</h1>
        </section>

        <!-- flow-->
        <section class="chapter white-block flow-image">
          <!-- .status-processed 	済み（薄い緑）-->
          <!-- .status-processing   現在（緑）-->
          <!-- .sub-processing  現在（緑）-->
          <!-- [.sub]（却下系）が現在になる場合は[.sub-processing]を[.main]につけてください-->

          <!-- 提案歓迎フロー -->
          <div class="flow-image-wrap" v-if="ep_offer_suggestions_welcome.is_suggestions_welcomed">
            <div class="d-flex">
              <div class="flow-item-box main " :class="status_flow.recommended">
                <div class="flow-title">リコメンド中</div>
                <div class="flow-point"><i></i><span class="line-right"></span></div>
              </div>

              <div class="flow-item-box main" :class="status_flow.suggestions_welcome_sent">
                <div class="flow-title">提案歓迎送付中</div>
                <div class="flow-point"><i></i><span class="line-left"></span><span class="line-right"></span>

                  <!-- サブ-->
                  <div class="flow-item-box sub" :class="status_flow.suggestions_welcome_closed">
                    <div class="flow-title">クローズ（提案歓迎）</div>
                    <div class="flow-point"><i></i></div>
                  </div>
                </div>
              </div>

              <div class="flow-item-box main" :class="status_flow.suggestions_welcome_matched">
                <div class="flow-title">マッチング（提案歓迎）</div>
                <div class="flow-point"><i></i><span class="line-left"></span></div>
              </div>
            </div>
          </div>

          <!-- オファーフロー -->
          <div class="flow-image-wrap" v-else>
            <div class="d-flex">
              <div class="flow-item-box main" :class="status_flow.recommended">
                <div class="flow-title">リコメンド中</div>
                <div class="flow-point"><i></i><span class="line-right"></span>

                  <!-- サブ-->
                  <div class="flow-item-box sub" :class="status_flow.pass">
                    <div class="flow-title">見送り</div>
                    <div class="flow-point"><i></i></div>
                  </div>
                </div>
              </div>

              <div class="flow-item-box main " :class="status_flow.offer_awaiting_review">
                <div class="flow-title">オファーレビュー待ち</div>
                <div class="flow-point"><i></i><span class="line-left"></span><span class="line-right"></span>

                  <!-- サブ-->
                  <div class="flow-item-box sub" :class="status_flow.offer_returned">
                    <div class="flow-title">差し戻し</div>
                    <div class="flow-point"><i></i></div>
                  </div>
                </div>
              </div>

              <div class="flow-item-box main" :class="status_flow.offer_sent">
                <div class="flow-title">オファー送付中</div>
                <div class="flow-point"><i></i><span class="line-left"></span><span class="line-right"></span>

                  <!-- サブ-->
                  <div class="flow-item-box sub" :class="status_flow.offer_closed">
                    <div class="flow-title">クローズ（オファー）</div>
                    <div class="flow-point"><i></i></div>
                  </div>
                </div>
              </div>

              <div class="flow-item-box main" :class="status_flow.offer_matched">
                <div class="flow-title">マッチング（オファー）</div>
                <div class="flow-point"><i></i><span class="line-left"></span></div>
              </div>
            </div>
          </div>

        </section>
        <!-- / flow-->

        <!-- リコメンド詳細-->
        <section class="chapter white-block">
          <h2 class="chapter-label mb-3">リコメンド詳細</h2>
          <div class="overview-list overview-list-dt15 my-3 p-3">
            <dl class="form-confirm-group">
              <dt>リコメンド日</dt>
              <dd>{{ ep_recommend.created_at | format.datetime }}</dd>
            </dl>

            <dl class="form-confirm-group">
              <dt>ステータス</dt>
              <dd>{{ ep_offer_suggestions_welcome.recommend_detail_status_i18n }}</dd>
            </dl>
          </div>
        </section>
        <!-- / リコメンド詳細-->

        <!-- オファー詳細-->
        <section class="chapter white-block offer-deta">
          <h2 class="chapter-label mb-3">オファー詳細</h2>
          <div class="active-trigger js-switch-trigger">
            <button
              class="btn btn-sm btn-icon mx-1"
              type="button"
              data-toggle="tooltip" title="オファー詳細編集"
              :class="{disabled: is_offer_detail_edit || is_exam_edit}"
              @click="is_offer_detail_edit = true"
            >
              <i class="fas fa-pen"></i>
            </button>
          </div>
          <div class="overview-list overview-list-dt15 my-3 p-3" v-show="!is_offer_detail_edit">
            <dl class="form-confirm-group" v-if="ep_offer_suggestions_welcome.is_offered">
              <dt>オファータイトル</dt>
              <dd>{{ ep_offer_suggestions_welcome.ep_offer_title }}</dd>
            </dl>

            <dl class="form-confirm-group">
              <dt>ステータス</dt>
              <dd>{{ ep_offer_suggestions_welcome.recommend_answer_status_i18n }}</dd>
            </dl>

            <dl class="form-confirm-group" v-if="ep_offer_suggestions_welcome.is_offered">
              <dt>オファー本文</dt>
              <dd>{{ ep_offer_suggestions_welcome.ep_offer_detail }}</dd>
            </dl>

            <dl class="form-confirm-group" v-if="ep_offer_suggestions_welcome.is_suggestions_welcomed">
              <dt>提案歓迎コメント</dt>
              <dd>{{ ep_offer_suggestions_welcome.ep_welcome_detail }}</dd>
            </dl>

            <dl class="form-confirm-group" v-if="ep_offer_suggestions_welcome.is_offer_pass">
              <dt>見送り理由</dt>
              <dd>{{ ep_recommend.ep_no_reason }}</dd>
            </dl>

            <dl class="form-confirm-group">
              <dt>大企業</dt>
              <dd>{{ ep_company.company_name }}</dd>
            </dl>

            <dl class="form-confirm-group">
              <dt>スタートアップ</dt>
              <dd>{{ su_company.company_name }}</dd>
            </dl>

            <dl class="form-confirm-group" v-if="ep_offer_suggestions_welcome.is_offered || ep_offer_suggestions_welcome.is_suggestions_welcomed || ep_offer_suggestions_welcome.is_offer_pass">
              <dt>フィードバック日</dt>
              <dd>{{ ep_offer_suggestions_welcome.updated_at | format.datetime }}</dd>
            </dl>

          </div>

          <form class="offer-edit-wrap form-wrap" :style=" is_offer_detail_edit ? 'display:block;' : ''">

            <div class="form-group" v-if="is_select_offered">
              <label for="ep_offer_title">オファータイトル</label>
              <b-form-input
                class="form-control"
                type="text"
                id="ep_offer_title"
                :state="was_valid ? !validation_errors.ep_offer_title : null"
                v-model="form.ep_offer_suggestions_welcome.ep_offer_title"
              />
              <small class="form-text text-right text-secondary">100文字以内</small>
              <div v-if="!!validation_errors.ep_offer_title">
                <p  v-for="error_message in validation_errors.ep_offer_title" :key="error_message" class="invalid-feedback d-block">
                  {{ error_message }}
                </p>
              </div>
            </div>

            <fieldset>
              <legend>ステータス</legend>
              <div class="form-row">
                <div class="form-group col-4">
                  <select
                    v-model="form.ep_offer_suggestions_welcome.recommend_answer_status"
                    class="form-control custom-select"
                    id="status"
                    @change="onChangeOfferEditStatus($event)"
                  >
                    <option
                      v-for="(recommend_answer_statuses_i18n, recommend_answer_status_key) in recommend_answer_statuses"
                      :key="recommend_answer_status_key"
                      :value="recommend_answer_status_key"
                    >
                      {{ recommend_answer_statuses_i18n }}
                    </option>
                  </select>

                </div>
              </div>
            </fieldset>

            <div class="form-group" v-if="is_select_offered">
              <label for="ep_offer_detail">オファー本文</label>
              <b-form-textarea
                class="form-control auto-resize"
                id="ep_offer_detail"
                :state="was_valid ? !validation_errors.ep_offer_detail : null"
                v-model="form.ep_offer_suggestions_welcome.ep_offer_detail"
              >
              </b-form-textarea>
              <small class="form-text text-right text-secondary">500文字以内</small>
              <div v-if="!!validation_errors.ep_offer_detail">
                <p  v-for="error_message in validation_errors.ep_offer_detail" :key="error_message" class="invalid-feedback d-block">
                  {{ error_message }}
                </p>
              </div>
            </div>

            <div class="form-group" v-if="is_select_welcomed">
              <label for="ep_welcome_detail">提案歓迎コメント</label>
              <b-form-textarea
                class="form-control auto-resize"
                id="ep_welcome_detail"
                :state="was_valid ? !validation_errors.ep_welcome_detail : null"
                v-model="form.ep_offer_suggestions_welcome.ep_welcome_detail"
              >
              </b-form-textarea>
              <small class="form-text text-right text-secondary">500文字以内</small>
              <div v-if="!!validation_errors.ep_welcome_detail">
                <p  v-for="error_message in validation_errors.ep_welcome_detail" :key="error_message" class="invalid-feedback d-block">
                  {{ error_message }}
                </p>
              </div>
            </div>

            <div class="form-group" v-if="is_select_pass">
              <label for="ep_no_reason">見送り理由</label>
              <b-form-textarea
                class="form-control auto-resize"
                id="ep_no_reason"
                :state="was_valid ? !validation_errors.ep_no_reason : null"
                v-model="form.ep_recommend.ep_no_reason"
              >
              </b-form-textarea>
              <small class="form-text text-right text-secondary">500文字以内</small>
              <div v-if="!!validation_errors.ep_no_reason">
                <p  v-for="error_message in validation_errors.ep_no_reason" :key="error_message" class="invalid-feedback d-block">
                  {{ error_message }}
                </p>
              </div>
            </div>

            <div class="form-group" v-if="is_select_offer_returned">
              <label for="no_reason">差し戻し理由</label>
              <b-form-textarea
                class="form-control auto-resize"
                id="no_reason"
                :state="was_valid ? !validation_errors.no_reason : null"
                v-model="form.ep_offer_suggestions_welcome.no_reason"
              >
              </b-form-textarea>
              <small class="form-text text-right text-secondary">500文字以内</small>
              <div v-if="!!validation_errors.no_reason">
                <p  v-for="error_message in validation_errors.no_reason" :key="error_message" class="invalid-feedback d-block">
                  {{ error_message }}
                </p>
              </div>
            </div>

            <dl class="form-group form-confirm-group">
              <dt>大企業</dt>
              <dd>{{ ep_company.company_name }}</dd>
            </dl>

            <dl class="form-group form-confirm-group">
              <dt>スタートアップ</dt>
              <dd>{{ su_company.company_name }}</dd>
            </dl>

            <div class="btn-container d-flex justify-content-end">
              <button class="button-square btn-cancel" type="button" @click="initEditForm()">閉じる</button>
              <button class="button-square btn-send" type="button" @click="onOfferEditSave()">保存する</button>
            </div>
          </form>
        </section>
        <!-- / オファー詳細-->

        <!-- 大企業企業情報-->
        <section class="chapter white-block">
          <h2 class="chapter-label mb-3">大企業企業情報</h2>
          <a class="accordion-trigger" data-toggle="collapse" href="#" @click.prevent.stop="" v-b-toggle.ep-company-toggle><i class="fas"></i></a>
          <b-collapse id="ep-company-toggle" role="tabpanel">
            <div class="border-top p-3 my-4">
              <div class="deta-header">
                <h3 class="company-name">{{ ep_company.company_name }}</h3>
                <dl>
                  <dt><i class="fas fa-desktop" title="URL"></i></dt>
                  <dd><a class="btn-text" :href="ep_company.company_hp" target="_blank" rel="noopener">{{ep_company.company_hp}}</a></dd>
                </dl>
              </div>
              <div class="overview-list overview-list-dt20">
                <dl class="form-confirm-group">
                  <dt>本社所在地</dt>
                  <dd>{{ ep_company.address }}</dd>
                </dl>

                <dl class="form-confirm-group">
                  <dt>業界</dt>
                  <dd>{{ ep_company.industry_name }}</dd>
                </dl>

                <dl class="form-confirm-group">
                  <dt>私たちについて</dt>
                  <dd>{{ ep_company.business_summary }}</dd>
                </dl>

                <dl class="form-confirm-group">
                  <dt>デロイト監査有無</dt>
                  <dd>{{ ep_company.is_audit_i18n }}</dd>
                </dl>
              </div>
            </div>
          </b-collapse>
        </section>
        <!-- / 大企業企業情報-->

        <!-- チャレンジ情報-->
        <section class="chapter accordion-block white-block" v-if="ep_challenge.id">
          <h2 class="chapter-label mb-3">チャレンジ情報</h2>
          <a class="accordion-trigger" data-toggle="collapse" href="#" @click.prevent.stop="" v-b-toggle.ep-challenge-toggle><i class="fas"></i></a>
          <b-collapse class="article-container" id="ep-challenge-toggle" role="tabpanel">
            <article class="reading-block pb-0">
              <!-- メインビジュアル画像 -->
              <div class="reading-visual">
                <template v-if="ep_challenge.main_image_url">
                  <a class="img-box" :href="ep_challenge.main_image_url"
                    data-lightbox="challenge_image" :data-title="ep_challenge.main_image_title">
                    <img
                      :src="ep_challenge.main_image_url"
                      :alt="ep_challenge.main_image_title">
                  </a>
                  <div class="reading-visual-caption">{{ ep_challenge.main_image_title }}</div>
                </template>
                <template v-else>
                  <a class="img-box" href="#">
                    <img
                      src="~images/image_default.jpg"
                      alt="デフォルト画像"
                    />
                  </a>
                </template>
              </div>
              <!-- 投稿日時 -->
              <div class="reading-bar">
                <small class="reading-date text-secondary">{{ ep_challenge.created_at | format.datetime }}</small>
              </div>
              <!-- タイトル -->
              <h2 class="reading-title">{{ ep_challenge.title }}</h2>

              <!-- チャレンジ企業 -->
              <dl class="reading-body border-bottom">
                <dt>
                  <img v-if="ep_challenge.is_manager_image"
                  :src="ep_challenge.manager_image_url"
                  :alt="ep_challenge.manager_image_filename"
                    class="thumbnail-frame img40"
                  />
                  <img v-else
                    src="~images/ep_user_default.png"
                    alt="画像が選択されていません"
                    class="thumbnail-frame img40"
                  />
                </dt>
                <dd>
                  <p class="company-name">{{ ep_challenge.ep_company_name }}</p>
                </dd>
              </dl>

              <dl class="reading-item">
                <dt>私たちのチャレンジ</dt>
                <dd class="u-pre-wrap" v-html="ep_challenge.detail"></dd>
                <dd class="u-pre-wrap ql-snow">
                  <div class="ql-editor" v-html="ep_challenge.detail"></div>
                </dd>
              </dl>

              <dl class="reading-item" v-if="ep_challenge.is_sub1_image || ep_challenge.is_sub1_image">
                <dt>イメージ</dt>
                <dd class="d-flex">
                  <!-- 画像（サブビジュアル1） -->
                  <figure v-if="ep_challenge.is_sub1_image">
                    <a class="img-box" :href="ep_challenge.sub1_image_url"
                      data-lightbox="challenge_image" :data-title="ep_challenge.sub1_image_title">
                      <img
                        :src="ep_challenge.sub1_image_url"
                        :alt="ep_challenge.sub1_image_title"
                      >
                    </a>
                    <figcaption class="reading-visual-caption">{{ ep_challenge.sub1_image_title }}</figcaption>
                  </figure>
                  <!-- 画像（サブビジュアル2） -->
                  <figure class="mr-3" v-if="ep_challenge.is_sub1_image">
                    <a class="img-box" :href="ep_challenge.sub2_image_url"
                      data-lightbox="challenge_image" :data-title="ep_challenge.sub2_image_title">
                      <img
                        :src="ep_challenge.sub2_image_url"
                        :alt="ep_challenge.sub2_image_title"
                      >
                    </a>
                    <figcaption class="reading-visual-caption">{{ ep_challenge.sub2_image_title }}</figcaption>
                  </figure>
                </dd>
              </dl>

              <!-- チャレンジのアセット -->
              <dl class="reading-item">
                <dt>パートナー企業様にご提供できる価値</dt>
                <dd>
                  <template v-if="ep_challenge.is_show_more_assets">
                    <div v-for="asset in ep_challenge.asset_masters.slice(0, 10)" :key="asset.asset_master_id" class="task-labels">
                      <p class="task-labels-parent">
                        {{ asset.name }}
                      </p>
                    </div>
                    <div class="btn-container text-right">
                      <button class="js-view-trigger btn-text d-show" type="button" @click="onClickShowMoreAssetsChallenge()">
                        もっと見る
                      </button>
                    </div>
                  </template>
                  <template v-else>
                    <div v-for="asset in ep_challenge.asset_masters" :key="asset.asset_master_id" class="task-labels">
                      <p class="task-labels-parent">
                        {{ asset.name }}
                      </p>
                    </div>
                  </template>
                </dd>
              </dl>

              <!-- チャレンジのステージ -->
              <dl class="reading-item">
                <dt>このチャレンジのステータス</dt>
                <dd>
                  <div class="status-list">

                    <!-- アイデア段階 -->
                    <div class="status-item">
                      <div v-if="ep_challenge.is_idea_stage">
                        <p class="status-label active">アイデア段階</p>
                        <div class="img-box"><img src="~images/status_img1_on.svg" alt=""></div>
                      </div>
                      <div v-else>
                        <p class="status-label">アイデア段階</p>
                        <div class="img-box"><img src="~images/status_img1.svg" alt=""></div>
                      </div>
                    </div>

                    <!-- PoC段階 -->
                    <div class="status-item">
                      <div v-if="ep_challenge.is_poc_stage">
                        <p class="status-label active">PoC段階</p>
                        <div class="img-box"><img src="~images/status_img2_on.svg" alt=""></div>
                      </div>
                      <div v-else>
                        <p class="status-label">PoC段階</p>
                        <div class="img-box"><img src="~images/status_img2.svg" alt=""></div>
                      </div>
                    </div>

                    <!-- 事業化段階 -->
                    <div class="status-item">
                      <div v-if="ep_challenge.is_commercial_stage">
                        <p class="status-label active">事業化段階</p>
                        <div class="img-box"><img src="~images/status_img3_on.svg" alt=""></div>
                      </div>
                      <div v-else>
                        <p class="status-label">事業化段階</p>
                        <div class="img-box"><img src="~images/status_img3.svg" alt=""></div>
                      </div>
                    </div>

                    <!-- その他 -->
                    <div class="status-item">
                      <div v-if="ep_challenge.is_other_stage">
                        <p class="status-label active">その他</p>
                        <div class="img-box"><img src="~images/status_img4_on.svg" alt=""></div>
                      </div>
                      <div v-else>
                        <p class="status-label">その他</p>
                        <div class="img-box"><img src="~images/status_img4.svg" alt=""></div>
                      </div>
                    </div>

                  </div>
                </dd>
              </dl>

            </article>
          </b-collapse>
        </section>
        <!-- / チャレンジ情報-->

        <!-- スタートアップ企業情報-->
        <section class="chapter white-block">
          <h2 class="chapter-label mb-3">スタートアップ企業情報</h2>
          <a class="accordion-trigger" data-toggle="collapse" href="#" @click.prevent.stop="" v-b-toggle.su-company-toggle><i class="fas"></i></a>
          <b-collapse id="su-company-toggle" role="tabpanel">
            <div class="border-top p-3 my-4">
              <div class="deta-header">
                <h3 class="company-name">{{ su_company.company_name }}</h3>
                <dl>
                  <dt><i class="fas fa-desktop" title="URL"></i></dt>
                  <dd><a class="btn-text" :href="su_company.company_hp" target="_blank" rel="noopener">{{ su_company.company_hp }}</a></dd>
                </dl>
              </div>
              <div class="overview-list overview-list-dt20">
                <dl class="form-confirm-group">
                  <dt>業界</dt>
                  <dd>{{ su_company.industry_name }}</dd>
                </dl>

                <dl class="form-confirm-group">
                  <dt>代表者</dt>
                  <dd>{{ su_company.representative }}</dd>
                </dl>

                <dl class="form-confirm-group">
                  <dt>所在地</dt>
                  <dd>{{ su_company.address }}</dd>
                </dl>

                <dl class="form-confirm-group">
                  <dt>私たちについて</dt>
                  <dd>{{ su_company.business_summary }}</dd>
                </dl>

                <dl class="form-confirm-group">
                  <dt>ステージ</dt>
                  <dd>{{ su_company.stage_i18n }}</dd>
                </dl>
              </div>
            </div>
          </b-collapse>
        </section>
        <!-- / スタートアップ企業情報-->

        <!-- 最新情報-->
        <section class="chapter accordion-block white-block">
          <h2 class="chapter-label mb-3">最新情報</h2>
          <a class="accordion-trigger" data-toggle="collapse" href="#" @click.prevent.stop="" v-b-toggle.su-latest-info-toggle><i class="fas"></i></a>
          <b-collapse class="article-container" id="su-latest-info-toggle" role="tabpanel">
            <article class="reading-block pb-0">
              <!-- メインビジュアル画像 -->
              <div class="reading-visual">
                <template v-if="su_latest_info.main_image_url">
                  <a class="img-box" :href="su_latest_info.main_image_url"
                    data-lightbox="latest_info_image" :data-title="su_latest_info.main_image_title">
                    <img
                      :src="su_latest_info.main_image_url"
                      :alt="su_latest_info.main_image_title">
                  </a>
                  <div class="reading-visual-caption">{{ su_latest_info.main_image_title }}</div>
                </template>
                <template v-else>
                  <a class="img-box" href="#">
                    <img
                      src="~images/image_default.jpg"
                      alt="デフォルト画像"
                    />
                  </a>
                </template>
              </div>
              <!-- 投稿日時 -->
              <div class="reading-bar">
                <small class="reading-date text-secondary">{{ su_latest_info.created_at | format.datetime }}</small>
              </div>
              <!-- タイトル -->
              <h2 class="reading-title">{{ su_latest_info.title }}</h2>
              <!-- 最新情報企業 -->
              <dl class="reading-body border-bottom">
                <dt>
                  <img v-if="su_latest_info.is_manager_image"
                  :src="su_latest_info.manager_image_url"
                  :alt="su_latest_info.manager_image_filename"
                    class="thumbnail-frame img40"
                  />
                  <img v-else
                    src="~images/su_user_default.png"
                    alt="画像が選択されていません"
                    class="thumbnail-frame img40"
                  />
                </dt>
                <dd>
                  <p class="company-name">{{ su_latest_info.su_company_name }}</p>
                </dd>
              </dl>
              <!-- 企業情報（私たちについて） -->
              <dl class="reading-item">
                <dt>企業情報（私たちについて）</dt>
                <dd class="u-pre-wrap" v-text="su_company.business_summary"></dd>
              </dl>
              <!-- 内容 -->
              <dl class="reading-item">
                <dt>最近何やってる？</dt>
                <dd class="u-pre-wrap" v-text="su_latest_info.detail"></dd>
              </dl>
              <dl class="reading-item" v-if="su_latest_info.is_sub1_image || su_latest_info.is_sub2_image">
                <dt>イメージ</dt>
                <dd class="d-flex">
                  <!-- 画像（サブビジュアル1） -->
                  <figure v-if="su_latest_info.is_sub1_image">
                    <a class="img-box" :href="su_latest_info.sub1_image_url"
                      data-lightbox="latest_info_image" :data-title="su_latest_info.sub1_image_title">
                      <img
                        :src="su_latest_info.sub1_image_url"
                        :alt="su_latest_info.sub1_image_title"
                      >
                    </a>
                    <figcaption class="reading-visual-caption">{{ su_latest_info.sub1_image_title }}</figcaption>
                  </figure>
                  <!-- 画像（サブビジュアル2） -->
                  <figure class="mr-3" v-if="su_latest_info.is_sub2_image">
                    <a class="img-box" :href="su_latest_info.sub2_image_url"
                      data-lightbox="latest_info_image" :data-title="su_latest_info.sub2_image_title">
                      <img
                        :src="su_latest_info.sub2_image_url"
                        :alt="su_latest_info.sub2_image_title"
                      >
                    </a>
                    <figcaption class="reading-visual-caption">{{ su_latest_info.sub2_image_title }}</figcaption>
                  </figure>
                </dd>
              </dl>
              <dl class="reading-item">
                <dt>パートナー企業様に求めるアセット</dt>
                <dd>
                  <template v-if="su_latest_info.is_show_more_assets">
                    <div v-for="asset in su_latest_info.asset_masters.slice(0, 10)" :key="asset.asset_master_id" class="task-labels">
                      <p class="task-labels-parent">
                        {{ asset.name }}
                      </p>
                    </div>
                    <div class="btn-container text-right">
                      <button class="js-view-trigger btn-text d-show" type="button" @click="onClickShowMoreAssetsLatestInfo()">
                        もっと見る
                      </button>
                    </div>
                  </template>
                  <template v-else>
                    <div v-for="asset in su_latest_info.asset_masters" :key="asset.asset_master_id" class="task-labels">
                      <p class="task-labels-parent">
                        {{ asset.name }}
                      </p>
                    </div>
                  </template>
                </dd>
              </dl>
            </article>
          </b-collapse>
        </section>
        <!-- / 最新情報-->

        <!-- 審査詳細-->
        <!-- オファー系ステータス時のみ表示 -->
        <section class="chapter white-block screen-deta" v-if="ep_offer_suggestions_welcome.is_offered">
          <h2 class="chapter-label mb-3">審査詳細</h2>
          <div class="active-trigger js-switch-trigger" >
            <button
              class="btn btn-sm btn-icon mx-1"
              type="button"
              data-toggle="tooltip" title="審査詳細編集"
              :class="{disabled: is_offer_detail_edit || is_exam_edit}"
              @click="is_exam_edit = true"
            >
              <i class="fas fa-pen"></i>
            </button>
          </div>
          <div class="overview-list overview-list-dt15 my-3 p-3" v-show="!is_exam_edit">
            <dl class="form-confirm-group">
              <dt>作成日</dt>
              <dd>{{ ep_offer_suggestions_welcome.created_at | format.datetime }}</dd>
            </dl>

            <dl class="form-confirm-group">
              <dt>ステータス</dt>
              <dd>{{ exam_statuses[ep_offer_suggestions_welcome.offer_exam_status] }}</dd>
            </dl>

            <dl v-if="ep_offer_suggestions_welcome.is_offer_returned" class="form-confirm-group">
              <dt>差し戻し理由</dt>
              <dd>{{ ep_offer_suggestions_welcome.no_reason }}</dd>
            </dl>

            <dl class="form-confirm-group">
              <dt>レビュー完了日</dt>
              <dd>{{ ep_offer_suggestions_welcome.review_at | format.datetime }}</dd>
            </dl>

          </div>

          <form class="screen-edit-wrap form-wrap" :style=" is_exam_edit ? 'display:block;' : ''">
            <dl class="form-group form-confirm-group">
              <dt>作成日</dt>
              <dd>{{ ep_offer_suggestions_welcome.created_at | format.datetime }}</dd>
            </dl>

            <fieldset>
              <legend>ステータス</legend>
              <div class="form-row">
                <div class="form-group col-4">
                  <select
                    v-model="form.exam_status"
                    class="form-control custom-select"
                    id="exam_status"
                    @change="onChangeExamStatus($event)"
                  >
                    <option
                      v-for="(exam_statuses_i18n, exam_status_key) in exam_statuses"
                      :key="exam_status_key"
                      :value="exam_status_key">
                      {{ exam_statuses_i18n }}
                    </option>
                  </select>
                </div>
              </div>
            </fieldset>

            <div class="form-group" v-if="is_select_offer_returned">
              <label for="exam_no_reason">差し戻し理由</label>
              <b-form-textarea
                class="form-control auto-resize"
                id="exam_no_reason"
                :state="was_valid ? !validation_errors.no_reason : null"
                v-model="form.ep_offer_suggestions_welcome.no_reason"
              >
              </b-form-textarea>
              <small class="form-text text-right text-secondary">500文字以内</small>
              <div v-if="!!validation_errors.no_reason">
                <p  v-for="error_message in validation_errors.no_reason" :key="error_message" class="invalid-feedback d-block">
                  {{ error_message }}
                </p>
              </div>
            </div>

            <dl class="form-group form-confirm-group">
              <dt>レビュー完了日</dt>
              <dd>{{ ep_offer_suggestions_welcome.review_at | format.datetime }}</dd>
            </dl>

            <div class="btn-container d-flex justify-content-end">
              <button class="button-square btn-cancel" type="button" @click="initEditForm()">閉じる</button>
              <button class="button-square btn-send" type="button" @click="onExamEditSave()">保存する</button>
            </div>
          </form>
        </section>
        <!-- / 審査詳細-->

        <!-- ボタンエリア-->
        <div class="btn-container adjust-lotof-btn d-flex justify-content-center">
          <a class="btn btn-big btn-white rounded-pill" href="#" @click.prevent.stop="onToIndex()">戻る</a>
          <a
            class="btn btn-big btn-delete rounded-pill"
            :class="ep_offer_suggestions_welcome.recommend_answer_status == 'recommended' ? 'disabled' : ''"
            href="#"
            @click.prevent.stop="$refs.delete_confirm_modal.open()"
          >
            削除する
          </a>
        </div>
        <!-- ボタンエリア-->
      </div>

      <ConfirmModal
        ref="delete_confirm_modal"
        :onClickSend="onDeleteComfirmSend"
        :body_text="'既に使用されている場合も削除します。<br>よろしいですか？'"
        :send_button_text="'削除する'"
        :isNoFade="isNoFade"
      />
    </main>

    <div class="fixed-footer-block" v-if="!!ep_action_display">
      <!-- オファー・提案歓迎を行う（デフォルト）-->
      <div class="inner" v-if="ep_action_display == ep_action_display_list.choice">
        <div class="btn-container d-flex justify-content-center align-items-center">
          <div class="chapter-label">オファー・提案歓迎を行う</div>
          <a class="btn btn-medium btn-outline-green rounded-pill" href="#" @click="onChangeEpAction(ep_action_display_list.pass)" >見送る</a>
          <a class="btn btn-medium btn-green rounded-pill" href="#" @click="onChangeEpAction(ep_action_display_list.offer)">オファー送付</a>
          <template v-if="ep_challenge.id">
            <a class="btn btn-medium btn-green rounded-pill" href="#" @click="onChangeEpAction(ep_action_display_list.welcome)">提案歓迎</a>
          </template>
        </div>
      </div>
      <!-- / オファー・提案歓迎を行う（デフォルト）-->

      <!-- オファー・提案歓迎を行う（見送る）-->
      <div class="inner" id="forgo-area" v-if="ep_action_display == ep_action_display_list.pass">
        <form>
          <div class="row">
            <div class="left-col">
              <div class="chapter-label">見送る</div>
              <label for="pass-comment">見送りコメント<span class="badge badge-light ml-1">任意</span></label>
            </div>
            <div class="right-col col-10">
              <b-form-textarea
                class="form-control"
                id="pass-comment"
                :state="was_valid ? !validation_errors.ep_no_reason : null"
                v-model="form.ep_recommend.ep_no_reason"
              >
              </b-form-textarea>
              <small class="form-text text-right">500文字以内</small>
              <div v-if="!!validation_errors.ep_no_reason">
                <p  v-for="error_message in validation_errors.ep_no_reason" :key="error_message" class="invalid-feedback d-block">
                  {{ error_message }}
                </p>
              </div>
            </div>
          </div>
          <div class="btn-container d-flex justify-content-end">
            <div class="btn-container d-flex justify-content-end">
              <a class="button-square btn-sm btn-cancel mr-2" href="#" @click="onChangeEpAction(ep_action_display_list.choice)">戻る</a>
              <button class="button-square btn-sm btn-send" type="button" @click="onEpAction('pass')">見送る</button>
            </div>
          </div>
        </form>
      </div>
      <!-- / オファー・提案歓迎を行う（見送る）-->

      <!-- オファー・提案歓迎を行う（オファー送付）-->
      <div class="inner" id="offer-area" v-if="ep_action_display == ep_action_display_list.offer">
        <form class="form-wrap py-1">
          <div class="chapter-label">オファーを出す</div>
          <div class="form-group row">
            <label class="col-2 col-form-label" for="offer-title">オファータイトル<span class="badge badge-danger ml-2">必須</span></label>
            <div class="col">
              <b-form-input
                v-model="form.ep_offer_suggestions_welcome.ep_offer_title"
                class="form-control"
                type="text"
                id="offer-title"
                :state="was_valid ? !validation_errors.ep_offer_title : null"
              />
              <small class="form-text text-right">100文字以内</small>
              <div v-if="!!validation_errors.ep_offer_title">
                <p  v-for="error_message in validation_errors.ep_offer_title" :key="error_message" class="invalid-feedback d-block">
                  {{ error_message }}
                </p>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-2 col-form-label" for="welcome-comment">オファー本文<span class="badge badge-danger ml-2">必須</span></label>
            <div class="col">
              <b-form-textarea
                class="form-control"
                id="offer_detail"
                :state="was_valid ? !validation_errors.ep_offer_detail : null"
                v-model="form.ep_offer_suggestions_welcome.ep_offer_detail"
              >
              </b-form-textarea>
              <small class="form-text text-right">500文字以内</small>
              <div v-if="!!validation_errors.ep_offer_detail">
                <p  v-for="error_message in validation_errors.ep_offer_detail" :key="error_message" class="invalid-feedback d-block">
                  {{ error_message }}
                </p>
              </div>
            </div>
          </div>

          <div class="btn-container d-flex justify-content-end">
            <a class="button-square btn-sm btn-cancel mr-2" href="#" @click="onChangeEpAction(ep_action_display_list.choice)">戻る</a>
            <button class="button-square btn-sm btn-send" type="button" @click="onEpAction('offer_awaiting_review')">オファー送付</button>
          </div>
        </form>
      </div>
      <!-- / オファー・提案歓迎を行う（オファー送付）-->

      <!-- オファー・提案歓迎を行う（提案歓迎）-->
      <div class="inner" id="welcome-area" v-if="ep_action_display == ep_action_display_list.welcome">
        <form>
          <div class="row">
            <div class="left-col">
              <div class="chapter-label">提案歓迎オファーを出す</div>
              <label for="welcome-comment">提案歓迎コメント<span class="badge badge-light ml-1">任意</span></label>
            </div>
            <div class="right-col col-9">
              <b-form-textarea
                class="form-control"
                id="welcome-comment"
                :state="was_valid ? !validation_errors.ep_welcome_detail : null"
                v-model="form.ep_offer_suggestions_welcome.ep_welcome_detail"
              >
              </b-form-textarea>
              <small class="form-text text-right">500文字以内</small>
              <div v-if="!!validation_errors.ep_welcome_detail">
                <p  v-for="error_message in validation_errors.ep_welcome_detail" :key="error_message" class="invalid-feedback d-block">
                  {{ error_message }}
                </p>
              </div>
            </div>
          </div>
          <div class="btn-container d-flex justify-content-end">
            <a class="button-square btn-sm btn-cancel mr-2" href="#" @click="onChangeEpAction(ep_action_display_list.choice)">戻る</a>
            <button class="button-square btn-sm btn-send" type="button" @click="onEpAction('suggestions_welcome_sent')">提案歓迎</button>
          </div>
        </form>
      </div>
      <!-- / オファー・提案歓迎を行う（提案歓迎）-->
    </div>

    <FooterComponent />
  </div>
</template>

<script>
  // Footer
  import FooterComponent from '../../../common/layouts/footer';

  // 確認モーダルのコンポーネント読み込み
  import ConfirmModal from '../../../common/pages/ConfirmModal.vue';
  // 端末判別コンポーネント
  import DeviceCheck from '../../../common/mixins/device_check.js';

  export default {

    mixins: [DeviceCheck],

    metaInfo: {
      title: 'リコメンド後一覧 詳細',
    },

    components: {
      ConfirmModal,
      FooterComponent
    },

    props: { isNoFade: Boolean },

    data() {
      return {
        // 遷移元画面情報
        previous_route: {},
        // EPリコメンドID
        ep_recommend_id: this.$route.params.ep_recommend_id,
        // リコメンド情報
        ep_recommend: {},
        // 提案歓迎・オファー情報
        ep_offer_suggestions_welcome:{},
        // チャレンジ情報
        ep_challenge: {},
        // 最新情報
        su_latest_info: {},
        // EP企業情報
        ep_company: {},
        // SU企業情報
        su_company: {},

        /**
         * セレクトボックスの選択肢
         */
        // リコメンド対応ステータス
        recommend_answer_statuses: [],
        // 審査ステータス
        exam_statuses: [],

        /**
         * フォーム情報
         */
        form: {
          // EPリコメンド情報
          ep_recommend: {
            // 見送り理由
            ep_no_reason: '',
            // 更新日時（排他チェック用）
            updated_at: ''
          },
          // オファー・提案歓迎情報
          ep_offer_suggestions_welcome: {
            // オファータイトル
            ep_offer_title: '',
            // リコメンド対応ステータス
            recommend_answer_status: '',
            // オファー本文
            ep_offer_detail: '',
            // 提案歓迎コメント
            ep_welcome_detail: '',
            // 差し戻し理由
            no_reason: '',
            // 更新日時（排他チェック用）
            updated_at: ''
          },
          // 審査ステータス
          exam_status: '',
          // 更新後に詳細情報をrenderするかどうか
          // 更新後に画面遷移しない場合はtrue, 画面遷移する場合はfalseを指定する
          is_render: false,
        },

        /**
         * 表示制御用フラグ
         */
        // オファー詳細編集モード
        is_offer_detail_edit: false,
        // 審査情報編集モード
        is_exam_edit: false,
        // オファー系を選択しているか
        is_select_offered: false,
        // 提案歓迎系を選択しているか
        is_select_welcomed: false,
        // 差し戻しを選択しているか
        is_select_offer_returned: false,
        // 見送りを選択しているか
        is_select_pass: false,

        // EP対応フォーム表示切替
        ep_action_display: '',
        // EP対応フォーム表示切替パターン
        ep_action_display_list: {
          // アクション選択
          choice: 'choice',
          // 見送り
          pass: 'pass',
          // オファー
          offer: 'offer',
          // 提案歓迎
          welcome: 'welcome',
        },
        // バリデーションエラーが発生したか
        was_valid: false,
        // バリデーションエラー内容
        validation_errors: [],
        // ステータスフロー図の表示制御クラス群
        status_flow: {
          // リコメンド中
          recommended: [],
          // 見送り
          pass: [],
          // オファーレビュー待ち
          offer_awaiting_review: [],
          // オファー差し戻し中
          offer_returned: [],
          // オファー送付中
          offer_sent: [],
          // マッチング（オファー）
          offer_matched: [],
          // クローズ（オファー）
          offer_closed: [],
          // 提案歓迎送付中
          suggestions_welcome_sent: [],
          // マッチング（提案歓迎）
          suggestions_welcome_matched: [],
          // クローズ（提案歓迎）
          suggestions_welcome_closed: [],
        },
        // 動的に変更するクラスの一覧
        class_list: {
          // 過去のステータス
          processed: 'status-processed',
          // 現在のステータス
          processing: 'status-processing',
          // 現在のステータス(却下系)
          sub_processing: 'sub-processing',
        },
        login: {},
      }
    },

    // コンポーネントを描画するルートが確立する前に呼ばれる
    beforeRouteEnter(to, from, next) {
      next(vm => {
        // 遷移前情報を取得
        vm.previous_route = from;
      });
    },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.init();
    },

    // ウォッチャ定義
    watch: {
      'ep_offer_suggestions_welcome.recommend_answer_status'(new_status, old_status) {
        this.initFlow();
        switch (new_status) {
          // リコメンド中
          case 'recommended':
            this.setFlowRecommended();
            break;
          // 見送り
          case 'pass':
            this.setFlowPass();
            break;
          // オファーレビュー待ち
          case 'offer_awaiting_review':
            this.setFlowOfferAwaitingReview();
            break;
          // オファー差し戻し中
          case 'offer_returned':
            this.setFlowOfferReturned();
            break;
          // オファー送付中
          case 'offer_sent':
            this.setFlowOfferSent();
            break;
          // マッチング（オファー）
          case 'offer_matched':
            this.setFlowOfferMatched();
            break;
          // クローズ（オファー）
          case 'offer_closed':
            this.setFlowOfferClosed();
            break;
          // 提案歓迎送付中
          case 'suggestions_welcome_sent':
            this.setFlowSuggestionsWelcomeSent();
            break;
          // マッチング（提案歓迎）
          case 'suggestions_welcome_matched':
            this.setFlowSuggestionsWelcomeMatched();
            break;
          // クローズ（提案歓迎）
          case 'suggestions_welcome_closed':
            this.setFlowSuggestionsWelcomeClosed();
            break;
          default:
            // 上記以外はリコメンド中
            this.setFlowRecommended();
            break;
        }
      }
    },
    // メソッド定義
    methods: {
      init() {
        this.$loading.load(
          this.$auth.api.get(
            'admin/after_recommends/' + this.ep_recommend_id, {
              params: {
              }
            }
          ).then(res => {
            // 表示データを初期化
            this.initData(res.data);
            // 入力フォームの初期化
            this.initEditForm();

          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        )
      },

      // 表示データを初期化
      initData(data){
        this.ep_company     = data.ep_company;
        this.su_company     = data.su_company;
        this.ep_challenge   = data.ep_challenge;
        this.su_latest_info = data.su_latest_info;
        this.ep_recommend   = data.ep_recommend;
        this.ep_offer_suggestions_welcome = data.ep_offer_suggestions_welcome;
        this.recommend_answer_statuses = data.recommend_answer_statuses;
        this.exam_statuses = data.exam_statuses;
        this.ep_offer_suggestions_welcome.is_offer_returned = this.ep_offer_suggestions_welcome.recommend_answer_status === 'offer_returned';
        this.ep_offer_suggestions_welcome.is_offer_pass = this.ep_offer_suggestions_welcome.recommend_answer_status === 'pass';

        if (!this.ep_challenge.id) {
          // チャレンジ紐付き無しの場合は提案歓迎系のステータスを除外
          delete this.recommend_answer_statuses.suggestions_welcome_sent
          delete this.recommend_answer_statuses.suggestions_welcome_matched
          delete this.recommend_answer_statuses.suggestions_welcome_closed
        }
      },

      // 編集フォームの初期化
      initEditForm() {
        /* 編集モード制御フラグ */
        // オファー詳細編集モード
        this.is_offer_detail_edit = false;
        // 審査情報編集モード
        this.is_exam_edit = false;
        // EP対応モード
        this.ep_action_display = this.ep_offer_suggestions_welcome.recommend_answer_status === 'recommended' ? this.ep_action_display_list.choice : '';
        // セレクトボックスでオファー系ステータスを選択しているか
        this.is_select_offered = this.ep_offer_suggestions_welcome.is_offered;
        // セレクトボックスで提案歓迎系ステータスを選択しているか
        this.is_select_welcomed = this.ep_offer_suggestions_welcome.is_suggestions_welcomed;
        // セレクトボックスで差し戻し中を選択しているか
        this.is_select_offer_returned = this.ep_offer_suggestions_welcome.is_offer_returned;
        // セレクトボックスで見送りを選択しているか
        this.is_select_pass = this.ep_offer_suggestions_welcome.is_offer_pass;

        /* 入力項目の初期化 */
        // 見送り理由
        this.form.ep_recommend.ep_no_reason = this.ep_recommend.ep_no_reason;
        // リコメンド対応ステータス
        this.form.ep_offer_suggestions_welcome.recommend_answer_status = this.ep_offer_suggestions_welcome.recommend_answer_status;
        // オファータイトル
        this.form.ep_offer_suggestions_welcome.ep_offer_title = this.ep_offer_suggestions_welcome.ep_offer_title;
        // オファー本文
        this.form.ep_offer_suggestions_welcome.ep_offer_detail = this.ep_offer_suggestions_welcome.ep_offer_detail;
        // 提案歓迎コメント
        this.form.ep_offer_suggestions_welcome.ep_welcome_detail = this.ep_offer_suggestions_welcome.ep_welcome_detail;
        // 審査ステータス
        this.form.exam_status = this.ep_offer_suggestions_welcome.offer_exam_status;
        // 差し戻し理由
        this.form.ep_offer_suggestions_welcome.no_reason = this.ep_offer_suggestions_welcome.no_reason;
        // EPリコメンド 更新日時
        this.form.ep_recommend.updated_at = this.ep_recommend.updated_at;
        // オファー・提案歓迎 更新日時
        this.form.ep_offer_suggestions_welcome.updated_at = this.ep_offer_suggestions_welcome.updated_at;

        // バリデーションエラー内容
        this.validation_errors = [];
        // バリデーションエラーが発生したか
        this.was_valid = false;
      },

      // オファー詳細編集の保存ボタン押下時
      onOfferEditSave() {
        // 審査ステータスをクリア
        this.form.exam_status = null;
        // 更新後のデータ再取得
        this.form.is_render = true;
        this.updateAfterRecommend();
      },

      // 審査情報編集の保存ボタン押下時
      onExamEditSave() {
        // 更新後のデータ再取得
        this.form.is_render = true;
        this.updateAfterRecommend();
      },

      // リコメンド後データ更新処理
      updateAfterRecommend() {
        // Unpermitted parameter formatエラー対策
        let params = { update_params: this.form };

        // update実施
        this.$loading.load(
          this.$auth.api.patch(
            'admin/after_recommends/' + this.ep_recommend_id,
            params
          ).then(res => {
            // 表示データを初期化
            this.initData(res.data);
            // 入力フォームを初期化
            this.initEditForm();
            this.$bvToast.toast("更新しました。", {
              variant: 'success',
              title: '完了'
            });
          }).catch(err => {
            // バリデーションエラー
            if(err.response.status == 422) {
              this.validation_errors = err.response.data.errors;
              this.was_valid = true;
            } else if(err.response.status == 500) {
              this.$errorHandlers.initial(err);
            } else {
              this.$errorHandlers.ajax(err);
            }
          })
        );
      },

      //EP対応フォームのアクションボタン（見送る、オファー送付、提案歓迎）押下時
      // @param status 押下したボタンに対応するステータス
      onEpAction(status) {
        // リコメンド対応ステータスをセット
        this.form.ep_offer_suggestions_welcome.recommend_answer_status = status;
        // 画面遷移するため更新後のデータ再取得は不要
        this.form.is_render = false;
        this.updateEpAction();
      },

      // EP対応フォームの更新処理
      updateEpAction() {
        // Unpermitted parameter formatエラー対策
        let params = { update_params: this.form };

        // update実施
        this.$loading.load(
          this.$auth.api.patch(
            'admin/after_recommends/' + this.ep_recommend_id,
            params
          ).then(res => {
            // リコメンド後データ一覧画面へ遷移
            this.routerPushIndex({toast_type: 'updated'});
          }).catch(err => {
            // バリデーションエラー
            if(err.response.status == 422) {
              this.validation_errors = err.response.data.errors;
              this.was_valid = true;
            } else if(err.response.status == 500) {
              this.$errorHandlers.initial(err);
            } else {
              this.$errorHandlers.ajax(err);
            }
          })
        );
      },

      // オファー詳細編集のステータス変更時
      onChangeOfferEditStatus(event) {
        let offered_statuses = ['offer_awaiting_review', 'offer_returned','offer_sent','offer_matched','offer_closed'];
        let suggestions_welcome_statuses = ['suggestions_welcome_sent', 'suggestions_welcome_matched', 'suggestions_welcome_closed'];
        let old_data = this.form.ep_offer_suggestions_welcome;
        // 表示制御フラグをクリア
        this.clearSelectFlgs();
        this.was_valid = false;

        if(offered_statuses.includes(event.target.value)){
          // オファー系の入力フォームを表示
          this.is_select_offered = true;

          // 提案歓迎内容が入力されている場合はオファー本文に提案歓迎内容を移す
          if(old_data.ep_welcome_detail){
            this.$set(this.form.ep_offer_suggestions_welcome, 'ep_offer_detail',  old_data.ep_welcome_detail);
            this.$set(this.form.ep_offer_suggestions_welcome, 'ep_welcome_detail', null);
          }

          if(event.target.value === 'offer_returned') {
            // 差し戻し理由を表示
            this.is_select_offer_returned = true;
          }
          else{
            // 差し戻し理由をクリア
            this.$set(this.form.ep_offer_suggestions_welcome, 'no_reason', null);
          }

          // 見送り理由をクリア
          this.$set(this.form.ep_recommend, 'ep_no_reason', null);

        }else if (suggestions_welcome_statuses.includes(event.target.value)){
          // 提案歓迎系の入力フォームを表示
          this.is_select_welcomed = true;

          // オファー本文が入力されてる場合は提案歓迎内容にオファー本文を移す
          if(old_data.ep_offer_detail){
            this.$set(this.form.ep_offer_suggestions_welcome, 'ep_welcome_detail',  old_data.ep_offer_detail);
            this.$set(this.form.ep_offer_suggestions_welcome, 'ep_offer_detail', null);
            this.$set(this.form.ep_offer_suggestions_welcome, 'ep_offer_title', null);
          }

          // 差し戻し理由、見送り理由をクリア
          this.$set(this.form.ep_offer_suggestions_welcome, 'no_reason', null);
          this.$set(this.form.ep_recommend, 'ep_no_reason', null);

        }else if (event.target.value === 'pass'){
          // 見送り理由の入力フォームを表示
          this.is_select_pass = true;

          // オファー、提案歓迎、差し戻しの入力内容をクリア
          this.$set(this.form.ep_offer_suggestions_welcome, 'ep_offer_title',null);
          this.$set(this.form.ep_offer_suggestions_welcome, 'ep_offer_detail', null);
          this.$set(this.form.ep_offer_suggestions_welcome, 'ep_welcome_detail', null);
        }
        else{
          // 全ての入力フォームをクリア
          this.$set(this.form.ep_offer_suggestions_welcome, 'ep_offer_title', null);
          this.$set(this.form.ep_offer_suggestions_welcome, 'ep_offer_detail', null);
          this.$set(this.form.ep_offer_suggestions_welcome, 'ep_welcome_detail', null);
          this.$set(this.form.ep_offer_suggestions_welcome, 'no_reason', null);
        }
        this.validation_errors = [];
      },

      // 表示制御フラグをクリア
      clearSelectFlgs() {
        this.is_select_offered = false;
        this.is_select_welcomed = false;
        this.is_select_offer_returned = false;
        this.is_select_pass = false;
      },

      // 審査情報セレクトボックス変更時
      onChangeExamStatus(event){
        this.clearSelectFlgs();
          if(event.target.value === 'returned') {
            // 差し戻し選択時、差し戻し理由入力フォームを表示
            this.is_select_offer_returned = true;
          }
          else{
            // 上記以外は差し戻し入力内容をクリア
            this.$set(this.form.ep_offer_suggestions_welcome, 'no_reason', null);
          }
      },

      // パラメータで指定されたEP対応フォームに表示を切り替える
      onChangeEpAction(value){
        this.ep_action_display = value;
      },

      // もっと見るボタン押下時(チャレンジ)
      onClickShowMoreAssetsChallenge(){
        this.ep_challenge.is_show_more_assets = false;
      },

      // もっと見るボタン押下時(最新情報)
      onClickShowMoreAssetsLatestInfo(){
        this.su_latest_info.is_show_more_assets = false;
      },

      // 戻るボタン押下時
      onToIndex() {
        this.routerPushIndex();
      },

      // 削除確認モーダル内の削除するボタン押下時
      onDeleteComfirmSend() {
        this.$loading.load(
          this.$auth.api.post(
            'admin/after_recommends/' + this.ep_recommend_id + '/delete_offer',
            { delete_params: { updated_at: this.form.ep_offer_suggestions_welcome.updated_at } }
          ).then(res => {
            this.$refs.delete_confirm_modal.close();
            this.routerPushIndex({toast_type: 'deleted'});
          }).catch(err => {
            this.$refs.delete_confirm_modal.close();
            if(err.response.status == 500) {
              this.$errorHandlers.initial(err);
            } else {
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },

      routerPushIndex(toast_type) {
        // 前画面情報が一覧画面の場合、クエリを保持した一覧画面に遷移
        if(!!this.previous_route && this.previous_route.name === 'AdminAfterRecommendIndex'){
          let query = this.previous_route.query;

          // 一覧画面のアコーディオンヘッダを展開する為、ヘッダのキー情報をクエリに追加
          if (query.display_switch === 'ep'){
            query.acc_header_id = this.ep_challenge.id;
            query.ep_company_id = this.ep_company.id;
          }
          else if( query.display_switch === 'su'){
            query.acc_header_id = this.su_company.id;
          }
          this.$router.push({name: 'AdminAfterRecommendIndex', query: {...query, ...toast_type}});
        }else{
          // 前画面情報がなければクエリなしで遷移
          this.$router.push({ name: 'AdminAfterRecommendIndex', query: toast_type});
        };
      },

      // ステータス:リコメンド中 のフロー設定
      setFlowRecommended() {
        this.status_flow.recommended = [this.class_list.processing];
      },
      // ステータス:見送り のフロー設定
      setFlowPass() {
        this.status_flow.recommended = [this.class_list.processed, this.class_list.sub_processing];
        this.status_flow.pass = [this.class_list.processing];
      },
      // ステータス：オファーレビュー待ち のフロー設定
      setFlowOfferAwaitingReview() {
        this.status_flow.recommended = [this.class_list.processed];
        this.status_flow.offer_awaiting_review = [this.class_list.processing];
      },
      // ステータス:オファー差し戻し中 のフロー設定
      setFlowOfferReturned() {
        this.status_flow.recommended = [this.class_list.processed];
        this.status_flow.offer_awaiting_review = [this.class_list.processed, this.class_list.sub_processing];
        this.status_flow.offer_returned = [this.class_list.processing];
      },
      // ステータス:オファー送付中 のフロー設定
      setFlowOfferSent() {
        this.status_flow.recommended = [this.class_list.processed];
        this.status_flow.offer_awaiting_review = [this.class_list.processed];
        this.status_flow.offer_sent = [this.class_list.processing];
      },
      // ステータス:マッチング（オファー） のフロー設定
      setFlowOfferMatched() {
        this.status_flow.recommended = [this.class_list.processed];
        this.status_flow.offer_awaiting_review = [this.class_list.processed];
        this.status_flow.offer_sent = [this.class_list.processed];
        this.status_flow.offer_matched = [this.class_list.processing];
      },
      // ステータス:クローズ（オファー） のフロー設定
      setFlowOfferClosed() {
        this.status_flow.recommended = [this.class_list.processed];
        this.status_flow.offer_awaiting_review = [this.class_list.processed];
        this.status_flow.offer_sent = [this.class_list.processed, this.class_list.sub_processing];
        this.status_flow.offer_closed = [this.class_list.processing];
      },
      // ステータス:提案歓迎送付中 のフロー設定
      setFlowSuggestionsWelcomeSent() {
        this.status_flow.recommended = [this.class_list.processed];
        this.status_flow.suggestions_welcome_sent = [this.class_list.processing];
      },
      // ステータス:マッチング（提案歓迎） のフロー設定
      setFlowSuggestionsWelcomeMatched() {
        this.status_flow.recommended = [this.class_list.processed];
        this.status_flow.suggestions_welcome_sent = [this.class_list.processed];
        this.status_flow.suggestions_welcome_matched = [this.class_list.processing];
      },
      // ステータス:クローズ（提案歓迎） のフロー設定
      setFlowSuggestionsWelcomeClosed() {
        this.status_flow.recommended = [this.class_list.processed];
        this.status_flow.suggestions_welcome_sent = [this.class_list.processed, this.class_list.sub_processing];
        this.status_flow.suggestions_welcome_closed = [this.class_list.processing];
      },
      // フロー初期化
      initFlow() {
        this.status_flow.recommended = [];
        this.status_flow.pass = [];
        this.status_flow.offer_awaiting_review = [];
        this.status_flow.offer_returned = [];
        this.status_flow.offer_sent = [];
        this.status_flow.offer_matched = [];
        this.status_flow.offer_closed = [];
        this.status_flow.suggestions_welcome_sent = [];
        this.status_flow.suggestions_welcome_matched = [];
        this.status_flow.suggestions_welcome_closed = [];
      },
    },
    filters: {
      numberWithDelimiter (value) {
        if (!value) {
          return '0';
        }
        return value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
    }
  }

</script>
