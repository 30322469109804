<template>
  <div>
    <router-view
      :registration_request.sync="registration_request"
      :ep_company.sync="ep_company"
      :industries.sync="industries"
      :prefectures.sync="prefectures"
      :exam_statuses.sync="exam_statuses"
      :accept_extensions="accept_extensions"
      :is_audits.sync="is_audits"
      :membership_types.sync="membership_types"
      :prop_admin_user_uploaded_image.sync="prop_admin_user_uploaded_image"
      :prop_admin_user_img_name.sync="prop_admin_user_img_name"
      :before_page_name.sync="before_page_name">
    </router-view>
  </div>
</template>

<script>
  export default {
    data: function () {
      return {
        registration_request: {},
        is_audits: {},
        membership_types: {},
        prefectures: {},
        exam_statuses:{},
        industries: {},
        accept_extensions: [],
        prop_admin_user_uploaded_image: '',
        prop_admin_user_img_name: '',
        before_page_name: 'other',
        ep_company: {
          id: '',
          company_name: '',
          prefecture_master_id: '',
          address: '',
          is_audit: '',
          membership_type: '',
          industry_master_id: '',
          sub_industry_master_id: '',
          company_hp: '',
          business_summary: '',
          updated_at: '',
          ep_admin_user_attributes: {
            ep_company_id: '',
            email: '',
            last_name: '',
            first_name: '',
            kana_last_name: '',
            kana_first_name: '',
            position: '',
            department: '',
            profile: '',
            password: '',
            uploaded_image: '',
            new_image_name: '',
            image_size: '',
            presigned_url: '',
            last_login: '',
            image_attributes: {
              id: '',
              filename: ''
            }
          }
        }
      }
    },
    beforeMount() {
      if(this.$route.name !== "AdminEpCompanyEditConfirm"){
        this.$store.commit('ep_company_edit/initEpCompany');
        this.fetchEpCompany();
      }
    },
    // route.push時に実行
    beforeRouteUpdate (to, from, next) {
      if(to.name === "AdminEpCompanyShow"){
        this.fetchEpCompany();
      }
      if(from.name === "AdminEpCompanyEditForm"){
        this.before_page_name = "AdminEpCompanyEditForm"
      }
      next();
    },
    methods: {
      fetchEpCompany: function() {
        this.$loading.load(this.$auth.api.get(`admin/ep_companies/${this.$route.params.id}/edit.json`)
        .then(response => {
          this.registration_request = response.data.registration_request
          if (!!response.data.ep_company) {
            this.ep_company = response.data.ep_company
          }
          if (!this.ep_company.id) {
            this.ep_company.company_name = this.registration_request.company_name
            this.ep_company.prefecture_master_id = ''
            this.ep_company.address = ''
            this.ep_company.is_audit = this.registration_request.is_audit
            this.ep_company.membership_type = this.registration_request.membership_type
            this.ep_company.industry_master_id = this.registration_request.industry_master_id
            this.ep_company.sub_industry_master_id = this.registration_request.sub_industry_master_id
            this.ep_company.company_hp = this.registration_request.company_hp
            this.ep_company.business_summary = ''
            this.ep_company.updated_at = this.registration_request.updated_at
            this.ep_company.ep_admin_user_attributes.email = this.registration_request.email
            this.ep_company.ep_admin_user_attributes.last_name = this.registration_request.last_name
            this.ep_company.ep_admin_user_attributes.first_name = this.registration_request.first_name
            this.ep_company.ep_admin_user_attributes.kana_last_name = ''
            this.ep_company.ep_admin_user_attributes.kana_first_name = ''
            this.ep_company.ep_admin_user_attributes.position = ''
            this.ep_company.ep_admin_user_attributes.department = this.registration_request.department
            this.ep_company.ep_admin_user_attributes.profile = ''
            this.ep_company.ep_admin_user_attributes.password = this.registration_request.encrypted_password
            this.ep_company.ep_admin_user_attributes.uploaded_image = ''
            this.ep_company.ep_admin_user_attributes.new_image_name = ''
            this.ep_company.ep_admin_user_attributes.image_size = ''
            this.ep_company.ep_admin_user_attributes.updated_at = ''
            this.ep_company.ep_admin_user_attributes.image_attributes.filename = ''
            this.ep_company.ep_admin_user_attributes.presigned_url = ''
            this.ep_company.ep_admin_user_attributes.last_login = ''
          }
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
      },
      getEpCompany () {
        this.ep_company = this.$store.getters['ep_company_edit/epCompany']
      },
      setEnums () {
        this.$loading.load(this.$auth.api.get('admin/ep_companies/edit_init.json')
          .then(response => {
            this.is_audits = response.data.is_audits
            this.membership_types = response.data.membership_types
            this.prefectures = response.data.prefectures
            this.industries = response.data.industries
            this.accept_extensions = response.data.accept_extensions.join();
            this.exam_statuses = response.data.exam_statuses
          })
          .catch(err => {
            this.$errorHandlers.initial(err);
          }));
      }
    },
    computed: {
      getAdminUserUploadedImage () {
        this.prop_admin_user_uploaded_image = this.$store.getters['ep_company_edit/uploadedAdminUserImage']
      },
      getAdminUserImgName () {
        this.prop_admin_user_img_name = this.$store.getters['ep_company_edit/imgAdminUserName']
      }
    },
    created: function(){
      this.setEnums();
      this.getEpCompany();
      this.getAdminUserUploadedImage;
      this.getAdminUserImgName;
    },
  }
</script>
