<template>
  <main>
    <div class="request-inner mt-6">
      <form @submit.prevent="onConfirm" class="needs-validation" novalidate>
        <!-- 2段階認証設定 -->
        <section class="chapter white-block">
          <h2 class="chapter-label">2段階認証設定<br>
            <p class="sub-label text-black-50">2段階認証を有効にすると、ログイン時に認証コードの入力が必要となり、アカウントを保護できます。<br>2段階認証には認証アプリが必要となります。</p>
          </h2>

          <div class="form-wrap border-top">
            <div>
              <div class="custom-control custom-radio custom-control-inline">
                <input v-model="otp_required_for_login" class="custom-control-input" type="radio" name="otp_required_for_login" id="otp_required_for_login_available" value="true">
                <label class="custom-control-label" for="otp_required_for_login_available">有効</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input v-model="otp_required_for_login" class="custom-control-input" type="radio" name="otp_required_for_login" id="otp_required_for_login_unavailable" value="false">
                <label class="custom-control-label" for="otp_required_for_login_unavailable">無効</label>
              </div>
            </div>
          </div>
          <div class="btn-container edit-btn-position" style="bottom: 1.5rem; top:initial;">
            <button class="btn btn-green rounded-pill" type="button" @click="onConfirm">設定する</button>
          </div>

        </section>
        <!-- 2段階認証設定-->

        <!-- バックアップコード生成-->
        <section class="chapter white-block" v-if="old_otp_required_for_login">
          <h2 class="chapter-label">バックアップコード
            <p class="sub-label text-black-50">コードの紛失・盗難、または多くのコードが既に使用済みの場合は、再生成するボタンを押して<br>新しいコードを取得してください。再生成すると以前に取得したコードは使用できなくなります。</p>
          </h2>

          <div class="border-top pt-4 px-3 mb-5" v-if="otp_backup_codes.length">
            <dl class="form-confirm-group" v-for="backup_code in otp_backup_codes" :key="backup_code">
              <dd>{{backup_code}}</dd>
            </dl>
          </div>

          <div class="btn-container edit-btn-position">
            <button class="btn btn-green rounded-pill" type="button" @click="onMakeBackupCode" v-if="old_otp_required_for_login">再生成する</button>
          </div>
        </section>
        <!-- バックアップコード生成-->
      </form>
    </div>
  </main>
</template>

<script>

  export default {
    metaInfo: {
      title: '2段階認証設定'
    },

    data() {
      return {
        user_id: 0,
        account_type: '',
        otp_required_for_login: '',
        old_otp_required_for_login: false,
        otp_backup_codes: [],
        errors: {}
      }
    },

    beforeMount() {
    },
    created() {
      this.fetchTwoFactorAuth();
    },

    methods: {
      // 2段階認証設定情報取得
      fetchTwoFactorAuth: function() {
        this.$loading.load(this.$auth.api.get(`two_factor_auth_setting/edit`)
        .then(res => {
          this.user_id = res.data.user_id;
          this.account_type = res.data.account_type;
          this.old_otp_required_for_login = res.data.otp_required_for_login;
          this.otp_required_for_login = res.data.otp_required_for_login.toString();
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
      },

      // 2段階認証設定確認
      onConfirm() {
        if (this.otp_required_for_login === 'true'){
          // 有効なら確認画面へ
          this.$router.push('confirm');
        } else {
          // 無効なら2段階設定を無効化してアカウント情報に戻る
          this.$loading.load(
            this.$auth.api.patch(`two_factor_auth_setting/inactivate`
            ).then(res => {
              this.backAccountDetail();
            }).catch(error => {
              this.$errorHandlers.initial(error);
            })
          );
        }
      },

      // バックアップコード生成
      onMakeBackupCode() {
        this.$loading.load(
          this.$auth.api.patch('two_factor_auth_setting/make_backup_code').then(res => {
            this.otp_backup_codes = res.data.otp_backup_codes;
          }).catch(error => {
            if(error.response.status == 422) {
              this.errors = error.response.data.errors;
            }else if(error.response.status == 500) {
              this.$errorHandlers.initial(error);
            }else{
              this.$errorHandlers.ajax(error);
            }
          })
        );
      },
      // アカウント詳細に戻る
      backAccountDetail() {
        let route_hash = {query: {toast_type: 'updated'}};
        // アカウント種別に応じて遷移先を変更する
        switch (this.account_type) {
          case 'dtvs_admin':
          case 'dtvs_cm':
            route_hash.name = 'AdminUserShow';
            route_hash.params = {id: this.user_id}
            break;
          case 'ep_admin':
            route_hash.name = 'EpEpCompanyShow'
            break;
          case 'ep_person_in_charge':
            route_hash.name = 'EpPersonInChargeShow';
            route_hash.params = {id: this.user_id};
            break;
          case 'su_user':
            route_hash.name = 'SuSuCompanyDetail';
            break;
          case 'su_person_in_charge':
            route_hash.name = 'SuPersonInChargeShow'
            route_hash.params = {id: this.user_id};
            break;
          default:
            break;
        }
        this.$router.push(route_hash);
      }


    },
  }
</script>
