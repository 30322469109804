<template>
  <!-- ロック解除したら[.table-danger]クラスを外し、アイコンと解除ボタンも非表示にする-->
  <tr :class="{'table-danger': user.locked_at}">
    <td><i class="fas fa-lock text-danger" v-b-tooltip.hover title="アカウントロック中" v-if="user.locked_at"></i></td>
    <td>{{user.last_name}} {{user.first_name}}</td>
    <td>{{user.department}}</td>
    <td class="text-break">{{user.email}}</td>
    <td>{{user.account_type_i18n}}</td>
    <td class="text-nowrap text-right">
      <button class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover title="ロック解除" v-if="user.locked_at" @click="$emit('unlock')">
        <i class="fas fa-lock-open"></i>
      </button>
      <button class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover title="詳細" @click="$router.push({name: 'AdminUserShow', params: {id: user.id}}, () => {}, () => {})">
        <i class="fas fa-ellipsis-h"></i>
      </button>
      <button class="btn btn-sm btn-icon mx-1" type="button" v-b-tooltip.hover title="編集" v-if="$auth.editableAccount(user.email)" @click="$router.push({name: 'AdminUserEditForm', params: {id: user.id}}, () => {}, () => {})">
        <i class="fas fa-pen"></i>
      </button>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'Row',
  props:[ 'user' ],
  methods: {},
  mounted(){
  }
}
</script>