<template>
  <div>
    <router-view
      :ep_company.sync="ep_company"
      :industries.sync="industries"
      :prefectures.sync="prefectures"
      :accept_extensions="accept_extensions"
      :is_audits.sync="is_audits"
      :prop_admin_user_uploaded_image.sync="prop_admin_user_uploaded_image"
      :prop_admin_user_img_name.sync="prop_admin_user_img_name">
    </router-view>
  </div>
</template>

<script>
  export default {
    data: function () {
      return {
        ep_company: {},
        is_audits: {},
        prefectures: {},
        industries: {},
        accept_extensions: [],
        prop_admin_user_uploaded_image: '',
        prop_admin_user_img_name: '',
      }
    },
    beforeMount() {
      if(this.$route.name !== "EpEpCompanyEditConfirm"){
        this.fetchEpCompany();
        this.$store.commit('ep_company_edit/initEpCompany');
      }
    },
    // route.push時に実行
    beforeRouteUpdate (to, from, next) {
      if(to.name === "EpEpCompanyShow"){
        this.fetchEpCompany();
      }
      next();
    },
    methods: {
      fetchEpCompany: function() {
        this.$loading.load(this.$auth.api.get(`ep/ep_companies/edit.json`)
        .then(response => {
          this.ep_company = response.data.ep_company
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
      },
      getEpCompany () {
        this.ep_company = this.$store.getters['ep_company_edit/epCompany']
      },
      setEnums () {
        this.$loading.load(this.$auth.api.get('ep/ep_companies/edit_init.json', {params:{path: this.$route.name}})
          .then(response => {
            this.is_audits = response.data.is_audits
            this.prefectures = response.data.prefectures
            this.industries = response.data.industries
            this.accept_extensions = response.data.accept_extensions.join();
          })
          .catch(err => {
            this.$errorHandlers.initial(err);
          }));
      }
    },
    computed: {
      getAdminUserUploadedImage () {
        this.prop_admin_user_uploaded_image = this.$store.getters['ep_company_edit/uploadedAdminUserImage']
      },
      getAdminUserImgName () {
        this.prop_admin_user_img_name = this.$store.getters['ep_company_edit/imgAdminUserName']
      }
    },
    created: function(){
      this.setEnums();
      this.getEpCompany();
      this.getAdminUserUploadedImage;
      this.getAdminUserImgName;
    },
  }
</script>
