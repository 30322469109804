<!--スライダー機能を作るための「vue-slick-carousel」プラグインのmixinです-->
<script>
import SlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {

  components: {SlickCarousel},

  data: function () {
    return {
      /**
       * スライダーの設定
       * このコンポーネントを使うときに 属性にv-bind="slickOptions" を指定すること
       */
      slickOptions: {
        dots: true,
        arrows: true,
        swipe: false,
        slidesToScroll: 3,
        slidesToShow: 3,
        infinite: false,
        speed: 1000,
      },
    }
  }
}

</script>

<style>
/*スライダーのスタイル(注意:グローバルスタイルです)*/
.slick-prev, .slick-next{
  width: 0;
  height: 0;
  position: absolute;
  top: 40%;
}
.slick-prev{
  left: -30px;
}
.slick-next{
  right: 0;
}
.slick-prev:before, .slick-next:before {
  font-family: "Font Awesome 5 Free";
  font-size: 90px;
  color: #c7c7c7;
  line-height: 1;
  text-rendering: auto;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  position: absolute;
}
.slick-prev:before {
  content: "‹";
}
.slick-next:before {
  content: "›";
}
.slick-prev.slick-disabled, .slick-next.slick-disabled {
  pointer-events: none;
  opacity: 0;
}
.slick-dots li.slick-active button:before {
  color: #86bc25;
}
.slick-slide > div {
  margin: 0.5rem;
}
.slick-slide li > a {
  padding: 1.25rem;
}
</style>
