<template>
  <div>
    <main>
      <section class="main-title">
        <h1>子アカウント登録<span class="small"> - 確認</span></h1>
      </section>

      <form @submit.prevent="createUser">
        <!-- 子アカウント-->
        <section class="chapter white-block">
          <div class="form-wrap d-flex">
            <div class="left-col">
              <img
                v-if="!!(user.uploaded_image)"
                class="thumbnail-frame img180 preview-item-file"
                :src="user.uploaded_image"
                :alt="user.new_image_name"
              />
              <img v-else
                class="thumbnail-frame img180 mx-auto mb-3"
                src="~images/su_user_default.png"
                alt="画像が選択されていません"
              />
            </div>
            <div class="right-col">
              <dl class="form-group form-confirm-group">
                <dt>氏名</dt>
                <dd wovn-ignore>{{fullName(user)}}</dd>
              </dl>
              <dl class="form-group form-confirm-group">
                <dt>氏名（フリガナ）</dt>
                <dd wovn-ignore>{{fullKanaName(user)}}</dd>
              </dl>
              <dl class="form-group form-confirm-group">
                <dt>メールアドレス（ログインID）</dt>
                <dd wovn-ignore>{{user.email}}</dd>
              </dl>
              <dl class="form-group form-confirm-group">
                <dt>役職名</dt>
                <dd>{{user.position}}</dd>
              </dl>
              <dl class="form-group form-confirm-group">
                <dt>部署名</dt>
                <dd>{{user.department}}</dd>
              </dl>
              <dl class="form-group form-confirm-group">
                <dt>プロフィール</dt>
                <dd>{{user.profile}}</dd>
              </dl>
            </div>
          </div>
        </section>
        <!-- 子アカウント-->
        <div class="btn-container d-flex justify-content-center">
          <button class="button-square btn-cancel" type="button" @click="onClickBackPage()">修正する</button>
          <button class="button-square btn-send" type="submit">登録する</button>
        </div>
      </form>
    </main>
  </div>
</template>

<script>
  export default {
    metaInfo: {
      title: '子アカウント登録',
    },
    data() {
      return {
        account_type_i18n: '',
      }
    },
    props: {
      user: Object,
    },
    methods: {
      createUser: function(){
        this.$loading.load(this.$auth.api.post('su/su_person_in_charges', {
          user: this.user
        })
        .then(response => {
          this.$store.commit('su_person_in_charge/initUser');
          this.$router.push({path: `/su/su_person_in_charges/${response.data.id}/detail` , query: {toast_type: 'created'}});
        })
        .catch(error => {
          if(error.response.status == 500) {
            this.$errorHandlers.initial(error);
          }else{
            this.$errorHandlers.ajax(error);
          }
        }));
      },
      fullName: function (user) {
        return `${user.last_name} ${user.first_name}`
      },
      fullKanaName: function (user) {
        return user.kana_last_name == '' || user.kana_last_name == null ? '' : `${user.kana_last_name} ${user.kana_first_name}`
      },
      toBoolean: function(value) {
        value = typeof(value) == 'string' ? value.toLowerCase() : value
        return (value == true || value == 'true') ? true : false
      },
      onClickBackPage: function () {
        this.user.password = ''
        this.user.password_confirmation = ''
        this.$router.push('new');
      },
    },
  }
</script>
