<template>
  <main>
    <section class="main-title">
      <h1>調達実績取込</h1>
    </section>

    <section class="chapter white-block">
      <div class="form-wrap d-flex align-items-center mb-3">
        <label class="btn btn-medium btn-outline-green rounded-pill mr-3 mb-0" for="csv-file-upload">ファイルを選択
          <input type="file" id="csv-file-upload" @change="onCsvFileChange" style="display: none;"/>
        </label>
        <p>{{ csv_file_name }}</p>
        <button type="button" class="btn btn-green rounded-pill ml-auto" @click="uploadCsvFile"><i class="fas fa-arrow-up mr-1"></i>アップロード</button>
      </div>
      <dl class="right-col form-group form-confirm-group">
        <dt v-show="result_message">結果</dt>
        <dd v-for="(value, key) in result_message" :key="key">{{ value }}</dd>
      </dl>
    </section>
  </main>
</template>

<script>
// アップロード画像チェック
import UploadFileCheck  from "../../../common/mixins/upload_file_check"

export default {
  mixins: [UploadFileCheck],
  name: "AchievementCsvImport",
  metaInfo: {
    title: "調達実績取込",
  },

  props: { isNoFade: Boolean },

  data() {
    return {
      csv_file_name: "選択されていません。",
      csv: {},
      csv_file_data: null,
      result_message: '',
    };
  },
  methods: {
    onCsvFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;

      // ファイルチェック(/common/mixins/upload_file_check.jsを参照)
      let csv_file_error = this.checkUploadCsv(files[0]);
      if (csv_file_error != "") {
        // エラーの場合はトースト表示
        this.$bvToast.toast(csv_file_error, { variant: "danger", title: "エラー" });
        return;
      }

      this.csv_file_name = files[0].name;
      this.csv_file_data = files[0];
    },

    uploadCsvFile() {
      if (!this.csv_file_data) {
        this.$bvToast.toast("CSVファイルを選択してください。", { variant: "danger", title: "エラー" });
        return;
      }
      const formData = new FormData();
      formData.append('csv_file_data', this.csv_file_data);
      this.$loading.load(this.$auth.api.post('admin/achievement_csv_import/upload', formData
      )
      .then(response => {
        this.result_message = response.data.result_message;
        this.csv_file_name = "選択されていません。";
        this.csv_file_data = null;
      })
      .catch(error => {
        if(error.response.status == 500) {
          this.$errorHandlers.initial(error);
        }else{
          this.$errorHandlers.ajax(error);
        }
      }));
    },
  },
};
</script>
