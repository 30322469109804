<template>
  <main>
    <div class="one-col-box white-block">
      <div class="header">
        <h1 class="logo"><img v-bind:src="require('../../../../images/logo.svg')" alt="six brain"></h1>
        <h2 class="mb-4">ログインできませんか？</h2>
      </div>
      <div class="body">
        <p class="lead">パスワード再設定リンクを<br>次のメールアドレスに送りました。</p>
        <form @submit.prevent="post">
          <div class="form-group">
            <div class="input-group">
              <input class="form-control value-display" type="text" name="email" v-model="email" disabled>
            </div>
          </div>
          <div class="btn-container py-3">
            <button class="button-square btn-send btn-block" type="submit">再設定リンクを再送</button>
          </div>
        </form>
      </div>
      <div class="footer">
        <p class="text-center mt-3"><router-link to="../../login">ログイン画面に戻る</router-link></p>
      </div>
    </div>
    <p class="text-center"><a class="text-secondary" href="mailto:sixbrain-support@tohmatsu.co.jp">サポートに問い合わせる</a></p>
  </main>
</template>

<script>
export default {
	metaInfo: {
		title: 'パスワード再設定',
		bodyAttrs: {
			class: ['password_setting']
		}
  },
  data() {
    return {
      email: '',
    }
  },
  methods: {
	  post: function(){
		  this.$loading.load(this.$auth.api.post('auth/password', {
        email: this.$route.query.email,
        first_name: this.$route.query.first_name,
        last_name: this.$route.query.last_name,
        account_type: this.$attrs.accountType,
			  redirect_url: 'http://localhost/reset'
		  }).then(res => {
			  if(res.data.success){
				  this.$bvToast.toast("リセットメールを送信しました", {
            variant: 'success',
            title: 'パスワード再設定'
          });
			  }
		  }).catch(err => {
        if(err.response.status == 500){
          this.$errorHandlers.initial(err);
        }else{
          this.$errorHandlers.ajax(err);
        }
		  }))
	  }
  },
  computed: {
  },
  created: function () {
    this.email = this.$route.query.email
  },
  mounted: function() {
  },
  watch: {
  }
}
</script>

<style scoped>
</style>