import Auth from '../../auth'

export default {
  install: function (Vue, options) {
    const auth = new Auth();

    Object.defineProperties(Vue.prototype, {
      $auth: {
        get () {
          return auth;
        }
      }
    })
  }
}