<template>
  <div>
    <router-view
      :su_old_achievement.sync="su_old_achievement">
    </router-view>
  </div>
</template>

<script>
  export default {
    data: function () {
      return {
        su_old_achievement: {
          id: '',
          su_company_id: '',
          supply_date: '',
          amount: '',
          unit: '',
          invest_persons: [],
          investor_finance_vc: '',
          investor_cvc_company: '',
          investor_independent: '',
          investor_government: '',
          investor_international_vc: '',
          updated_at: '',
        },
      }
    },

    beforeMount() {
      if(this.$route.name !== "AdminSuOldAchievementEditConfirm"){
        this.fetchSuOldAchievement();
        this.$store.commit('su_old_achievement/initSuOldAchievement');
      }
    },
    methods: {
      fetchSuOldAchievement: function() {
        this.$loading.load(this.$auth.api.get(`admin/su_old_achievements/${this.$route.params.id}/edit`)
        .then(res => {
          this.su_old_achievement = res.data.su_old_achievement
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
      },
    },
    created: function(){
      this.su_old_achievement = this.$store.getters['su_old_achievement/SuOldAchievement']
    },
  }
</script>
