<template>
  <div>
    <router-view
      :su_company.sync="su_company"
      :stages.sync="stages"
      :prefectures.sync="prefectures"
      :exam_statuses.sync="exam_statuses"
      :industries.sync="industries"
      :is_audits.sync="is_audits"
      :is_publish_infos.sync="is_publish_infos"
      :otp_required_for_logins.sync="otp_required_for_logins"
      :accept_extensions="accept_extensions"
      :before_page_name="before_page_name"
      :isNoFade="isNoFade"
      :is_fetch="is_fetch"
      @fetchSuCompany="fetchSuCompany"
    >
    </router-view>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        su_company: {
          company_name: '',
          company_hp: '',
          representative: '',
          prefecture_master_id: '',
          address: '',
          stage: '',
          industry_master_id: '',
          sub_industry_master_id: '',
          sub2_industry_master_id: '',
          business_summary: '',
          is_audit: 'unaudit',
          is_publish_info: 'publish',
          current_market_capital: '',
          last_finance_date: '',
          founding_date: '',
          user_attributes: {
            first_name: '',
            last_name: '',
            kana_first_name: '',
            kana_last_name: '',
            email: '',
            position: '',
            department: '',
            profile: '',
            otp_required_for_login: '',
            account_type: 'su_user',
            position: '',
            password: '',
            password_confirmation: '',
            last_login: '',
            uploaded_image: '',
            new_image_name: '',
            image_attributes: {
              filename: ''
            }
          }
        },
        stages:{},
        prefectures: {},
        exam_statuses: {},
        industries: {},
        is_audits: {},
        is_publish_infos: {},
        otp_required_for_logins: {},
        accept_extensions: [],
        before_page_name: 'other',
        is_fetch: false,
      }
    },

    props: { isNoFade: Boolean },

    methods: {
      // サーバーサイドからSU企業情報を取得
      fetchSuCompany () {
        this.$loading.load(this.$auth.api.get(`/admin/su_companies/${this.$route.params.id}/edit`)
        .then(response => {
          this.su_company = response.data.su_company
          this.is_fetch = true
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
      },

      // プルダウン選択用,バリデーション用情報取得
      fetchRelatedParts () {
        this.$loading.load(this.$auth.api.get(`/admin/su_companies/related_parts`, {params: {path: this.$route.name}})
        .then(response => {
          this.stages = response.data.stages
          this.prefectures = response.data.prefectures
          this.industries = response.data.industries
          this.is_audits = response.data.is_audits
          this.is_publish_infos = response.data.is_publish_infos
          this.exam_statuses = response.data.exam_statuses
          this.otp_required_for_logins = response.data.otp_required_for_logins
          this.accept_extensions = response.data.accept_extensions.join()
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
      },
    },

    // インスタンスが生成され､且つデータが初期化された後(beforeMountのまえ)
    created () {
    },

    // インスタンスが DOM 要素にマウントされる前(createdのあと)
    beforeMount() {
      if (this.$route.name !== "AdminSuEditConfirm") {
        this.fetchSuCompany();
      }
      this.fetchRelatedParts();
    },

    // route.push時に実行
    beforeRouteUpdate (to, from, next) {
      if(to.name === "AdminSuCompanyDetail"){
        this.fetchSuCompany();
      }
      if(from.name === "AdminSuEditForm"){
        this.before_page_name = "AdminSuEditForm"
      }
      next();
    },
  }
</script>
