<template>
</template>

<script>
export default {
  data () {
    return {
      
    }
  },
  methods: {
    logout () {
      this.$loading.load(this.$auth.logout().then(res => {
        this.$router.push('./login');
      }).catch(err => {
        this.$errorHandlers.initial(err);
      }));
    }
  },
  computed: {
  },
  created: function () {
    this.logout();
  },
  watch: {
  }
}
</script>

<style scoped>
</style>