<template>
  <div>
    <b-modal
      v-model="is_show"
      id="industry-setting"
      aria-labelledby="industry-setting_Label"
      title=""
      centered
      scrollable
      size="lg"
      :no-fade="isNoFade"
    >
      <template v-slot:modal-header>
        <h3 class="modal-title mx-auto">{{ header_name }}</h3>
      </template>
      <form onsubmit="return false;">
        <section class="chapter white-block">
          <div class="form-group pb-2">
            <div class="card">

              <!-- アセット表示 -->
              <div class="card-header py-3">
                <label>登録中のアセット</label>
                <div class="btn-group-toggle my-2 overflow-auto h-max400" data-toggle="button">
                  <label
                    v-for="asset_master in asset_masters"
                    v-show="isSelectedAssetMaster(asset_master.id)"
                    class="btn button-square btn-check btn-checkbox btn-sm"
                    :class="isSelectedAssetMaster(asset_master.id) ? 'active' : ''"
                  >
                    {{ asset_master.name }}<i class="fas fa-check-circle"></i>
                    <input
                      type="checkbox"
                      class="form-check-input"
                      :id="'asset_master_' + asset_master.id"
                      :value="{'asset_master_id': asset_master.id}"
                      @click.prevent.stop="onClickAssetMaster(asset_master.id)"
                    >
                  </label>
                </div>
              </div>

              <!-- アセット検索 -->
              <div class="card-body">
                <label>検索</label>
                <div class="new-assets">
                  <div class="incremental-wrap">
                    <input v-model="asset_name" @input="onInputAssetName" @blur="resetAssetList" class="form-control" type="text" placeholder="" value="" >
                    <div class="list-group keyword-list" v-if="!!pick_assets.length">
                      <a
                        v-model="selected_asset_masters"
                        v-for="pick_asset in pick_assets"
                        :key="pick_asset.id"
                        class="list-group-item list-group-item-action"
                        @click.prevent.stop="onClickSearchAsset(pick_asset.id, pick_asset.relation_id)"
                        href="#"
                      >
                        {{ pick_asset.name }}
                      </a>
                    </div>
                  </div>
                </div>
                <div v-if="!pick_assets.length && !!asset_name" class="invalid-feedback" style="display: block">
                  このアセットはシステムに登録されていません。<br>
                  該当するアセットがない場合は「新規作成」よりアセットを作成してください。
                </div>
              </div>

            </div>
          </div>
        </section>
      </form>
      <template v-slot:modal-footer>
        <button class="btn btn-cancel" type="button" @click="onCancel" >{{ cancel_button_text }}</button>
        <button
          class="btn btn-send"
          type="button"
          @click="onSend"
        >
          {{ send_button_text }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  export default {
    props: {
      // モーダルが開いた時のコールバックメソッド
      onModalOpen: {
        type: Function,
      },
      // モーダルが閉じた時のコールバックメソッド
      onModalClose: {
        type: Function,
      },
      // OKボタン押下時のコールバックメソッド
      onClickSend: {
        type: Function,
      },
      // キャンセルボタン押下時のコールバックメソッド
      onClickCancel: {
        type: Function,
      },
       // OKボタンの文字列
      send_button_text: {
        type: String,
        default: '追加する'
      },
      // キャンセルボタンの文字列
      cancel_button_text: {
        type: String,
        default: 'キャンセルする'
      },
      // ヘッダー表示名
      header_name: {
        type: String,
        default: ''
      },
      // アセットマスタ
      asset_masters: {
        type: Array,
        default: () => ([])
      },
      isNoFade: {
        type: Boolean,
      },
    },
    data() {
      return {
        is_show: false,
        // アセット名の入力値
        asset_name: '',
        // アセットの検索候補
        pick_assets: [],
        // 選択されたアセット
        selected_asset_masters: [
          // {
          //   asset_master_id: '',
          // }
        ],
      }
    },
    watch: {
      is_show(new_is_show, old_is_show) {
        if(new_is_show) {
          if(typeof this.onModalOpen == 'function') {
            this.onModalOpen();
          }
        } else {
          if(typeof this.onModalClose == 'function') {
            this.onModalClose();
          }
        }
      }
    },
    methods: {
      // モーダルオープン
      open() {
        this.is_show = true;
      },

      // モーダルクローズ
      close() {
        this.selected_asset_masters = [];
        this.is_show = false;
      },

      // OKボタン押下時
      onSend() {
        if(typeof this.onClickSend == 'function') {
          // OKボタン押下時のコールバックメソッドが存在すれば、呼ぶ
          // ※ モーダルは閉じられないので、コールバックメソッド内で閉じる必要がある
          this.onClickSend(this.selected_asset_masters);
          this.selected_asset_masters = [];
        } else {
          // OKボタン押下時のコールバックメソッドが存在しなければ、モーダルを閉じる
          this.close();
        }
      },

      // キャンセルボタン押下時
      onCancel() {
        if(typeof this.onClickCancel == 'function') {
          // キャンセルボタン押下時のコールバックメソッドが存在すれば、呼ぶ
          // ※ モーダルは閉じられないので、コールバックメソッド内で閉じる必要がある
          this.onClickCancel();
        } else {
          // キャンセルボタン押下時のコールバックメソッドが存在しなければ、モーダルを閉じる
          this.close();
        }
      },

      // アセット 入力フォーム入力時処理
      onInputAssetName() {
        // 検索するアセット名
        let search_asset_name = this.asset_name;

        if(search_asset_name == '') {
          this.pick_assets = [];
        } else {
          let copy_asset_masters = JSON.parse(JSON.stringify(this.asset_masters));

          this.pick_assets = copy_asset_masters.filter(function (asset_master) {
            // 入力されたビジネス名で前方一致
            return asset_master.name.includes(search_asset_name);
          });
        }
      },

      // アセット 入力フォームからフォーカスが外れた時の処理
      resetAssetList() {
        // 検索候補をリセット
        setTimeout(() => {
          this.pick_assets = [];
        }, 200);
      },

      // アセット 検索結果の値を選択時処理
      onClickSearchAsset(asset_master_id, relation_id) {
        // 選択済みのアセットマスタを検索
        let filtered_asset_master = this.selected_asset_masters.find(v => v.asset_master_id == asset_master_id);
        // まだ選択されていない場合、新たに選択済みアセット一覧に追加
        if(!filtered_asset_master) {
          this.selected_asset_masters.push({
            'asset_master_id': asset_master_id,
            'id': relation_id
          });
        }
        // アセットの検索値を空にする
        this.asset_name = ''
      },

      // 選択中のアセットマスタか
      isSelectedAssetMaster(asset_master_id){
        if (this.selected_asset_masters.length == 0) {
          return false;
        }
        return !!this.selected_asset_masters.filter(
          provisional_su_latest_info_asset_masters => provisional_su_latest_info_asset_masters.asset_master_id == asset_master_id
        ).length;
      },

      // アセットの選択肢を選択時処理
      onClickAssetMaster(asset_master_id) {
        this.$delete(
          this.selected_asset_masters,
          this.selected_asset_masters.findIndex(v => v.asset_master_id == asset_master_id)
        );
      },

    }
  }
</script>
