<template>
  <div>
    <main>
      <section class="main-title">
        <h1>アセットマスタ編集</h1>
      </section>
        <Form
          :edit_asset_master_id="asset_master_id"
          :onFormPost="onEditFormPost"
          :validation_errors="validation_errors"
          :isNoFade="isNoFade"
        />
    </main>
  </div>
</template>

<script>

  // フォームのコンポーネント読み込み
  import Form from './Form';

  export default {
    components: {
      Form,
    },

    metaInfo: {
      title: 'アセットマスタ編集',
    },

    data() {
      return {
        asset_master_id: this.$route.params.asset_master_id,
        target_updated_at: '',
        was_valid: false,
        update_form: {},
        validation_errors: {},
        previous_route: null,
      }
    },

    props: { isNoFade: Boolean },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.init();
    },

    // メソッド定義
    methods: {
      // 初期化
      init() {
      },

      // 保存ボタン押下
      onEditFormPost(form) {
        this.update_form = form;
        this.formPost();
      },

      formPost() {
        this.$loading.load(
          this.$auth.api.patch(
            'admin/asset_masters/' + this.asset_master_id, this.update_form
          ).then(res => {
            if (!!this.previous_route && this.previous_route.name === 'AdminAssetMasterIndex') {
              this.$router.push({path: this.previous_route.fullPath, query: {toast_type: 'updated'}});
            } else {
              this.$router.push({name: 'AdminAssetMasterIndex', query: {toast_type: 'updated'}});
            }
          }).catch(err => {
            // バリデーションエラー
            if(err.response.status == 422) {
              this.validation_errors = err.response.data.errors;
              // エラー時ページトップにスクロール
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
            } else if(err.response.status == 500) {
              this.$errorHandlers.initial(err);
            } else {
              this.$errorHandlers.ajax(err);
            }
          })
        )
        this.was_valid = true;
      },
    },
    // コンポーネントを描画するルートが確立する前に呼ばれる
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.previous_route = from;
      });
    },
  }

</script>
