<template>
  <div>
    <section class="chapter white-block step-data">
      <div class="step-graph border-bottom">
        <BarChart
          :options="presentValuesBarOptions(scenario)"
          :chartData="presentValuesBarChartData(scenario)"
        />
      </div>
      <div class="step-table">
        <div class="btn-container">
          <div>
            <router-link
              :to="{ name: 'EpScenariosShowStep3' }"
              class="btn btn-white btn-sm rounded-pill"
            >
              戻る
            </router-link>
          </div>
          <EditButtons
            :inEdit="inEdit"
            @editClick="$emit('update:inEdit', true)"
            @onSave="$emit('updateJsonScenario')"
          />
        </div>
        <div
          v-if="errors.length"
          class="mx-4 mb-4"
        >
          <p
            v-for="(error, i) in errors"
            :key="`scenario_errors_${i}`"
            class="invalid-feedback d-block"
          >
            {{ error }}
          </p>
        </div>
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr class="divide-x divide-gray-200">
                  <th
                    class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                    scope="col"
                  >
                    社名
                  </th>
                  <th
                    class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0"
                    scope="col"
                  >
                    PER
                  </th>
                  <th
                    class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0"
                    scope="col"
                  />
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="(item, i) in scenario.pers"
                  :key="`tbody_per_edit_${i}`"
                  class="divide-x divide-gray-200"
                >
                  <template v-if="inEdit">
                    <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                      <input
                        class="w-[27rem]"
                        type="text"
                        :value="item.company_name"
                        @input="inputPer($event.target.value, i, 'company_name')"
                      />
                    </td>
                    <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                      <input
                        class="w-[27rem]"
                        type="text"
                        :value="item.per"
                        @input="inputPer($event.target.value, i, 'per')"
                      />
                    </td>
                  </template>
                  <template v-else>
                    <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                      <div class="w-[27rem]">
                        {{ item.company_name }}
                      </div>
                    </td>
                    <td class="whitespace-nowrap p-4 text-sm text-gray-500">
                      <div class="w-[27rem]">
                        {{ digitSeparator(item.per) }}
                      </div>
                    </td>
                  </template>
                  <td class="w-20">
                    <button
                      class="button-square btn-send btn-sm ml-4 remove-btn"
                      :class="{ disabled: undeletablePers }"
                      :disabled="undeletablePers"
                      @click="removePer(item, i)"
                    >
                      削除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="inEdit"
              class="flex justify-between mt-3 mb-2 mr-3"
            >
              <div class="flex"/>
              <div class="flex">
                <button
                  class="button-square btn-send btn-sm add-btn"
                  @click="addPer"
                >
                  追加する
                </button>
              </div>
            </div>
            <div class="median-area">
              <div />
              <div>
                <div class="text-sm font-semibold median-key">
                  中央値
                </div>
                <div class="text-sm font-semibold median-value">
                  {{ digitSeparator(calcPersMedian(scenario)) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import EditButtons from './parts/EditButtons';
  import BarChart from './parts/BarChart';
  import ScenariosShowMixins from './scenarios_show_mixins';

  export default {
    mixins: [ScenariosShowMixins, ],
    components: {
      EditButtons,
      BarChart,
    },
    props: {
      scenario: {
        type: Object,
        required: true,
      },
      errors: {
        type: Array,
        required: true,
      },
      inEdit: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      undeletablePers() {
        return this.scenario.pers.length <= this.scenario.pers_size_min;
      },
    },
    methods: {
      addPer() {
        this.setField(
          this.scenario,
          'pers',
          [...this.scenario.pers, { company_neme: '', per: 0, }, ],
        );
      },
      inputPer(value, index, key) {
        this.setField(
          this.scenario,
          'pers',
          this.scenario.pers.map((v, i) => i === index ? { ...v, [key]: value } : v),
        );
      },
      removePer(per, index) {
        if (this.undeletablePers) return;

        // 既存PERの削除
        if (per['uuid']) {
          this.$emit('removePer', per['uuid']);
          return;
        }

        // 未保存PERの削除
        this.setField(
          this.scenario,
          'pers',
          this.scenario.pers.filter((_, i) => i !== index),
        );
      },
    },
  };
</script>
<style src="./tailwind.css" scoped />
<style lang="scss" scoped>
  .add-btn {
    background: #bed202;
    color: #fff;
  }
  .remove-btn {
    background: #dc3545;
    color: #fff;
    &:hover {
      background: #c82333;
    }
    &:disabled {
      color: #fff;
    }
  }
  .median-area {
    display: flex;
    justify-content: space-between;
    margin-top: 45px;
    div {
      display: flex;
      div {
        display: inline-flex;
        align-items: center;
        border: 1px solid #86bc25;
        &.median-key {
          padding: 6px 36px;
          background: #86bc25;
          color: #fff;
        }
        &.median-value {
          padding: 6px 46px;
        }
      }
    }
  }
</style>