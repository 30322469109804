<template>
  <div>
    <main>
      <section class="main-title d-flex justify-content-between">
        <h1>スタートアップ情報一覧</h1>
        <form class="search-box" @submit.prevent>
          <input @keydown.enter="onSearchFreeWord" v-model="form.search_text" class="form-control" type="text" placeholder="企業名またはタイトルで検索する..." /><i class="fas fa-search"></i>
        </form>
      </section>

      <!--絞込・ソート-->
      <div class="filter-wrap">
        <div class="filter-list">
          <div class="filter-item current">
            <div class="filter-button" role="button" tabindex="0" @click="onOrderChange">
              <svg class="arrowDownThick" viewBox="0 0 16 16"
              style="width: 12px; height: 100%; display: block; flex-shrink: 0; backface-visibility: hidden; margin-right: 4px;"
              :style="[form.is_order_desc ? {transform: 'scale(1,-1)'} : '']">
                <path d="M7.99316 1.52246C7.40527 1.52246 7.00195 1.93262 7.00195 2.54785V10.1357L7.07031 11.6191L5.69629 10.0332L4.1582 8.50195C3.98047 8.31738 3.74805 8.19434 3.44727 8.19434C2.91406 8.19434 2.51074 8.59082 2.51074 9.15137C2.51074 9.41113 2.61328 9.65039 2.81836 9.8623L7.26172 14.3125C7.44629 14.5039 7.72656 14.6133 7.99316 14.6133C8.2666 14.6133 8.54004 14.5039 8.72461 14.3125L13.1748 9.8623C13.3799 9.65039 13.4824 9.41113 13.4824 9.15137C13.4824 8.59082 13.0791 8.19434 12.5459 8.19434C12.252 8.19434 12.0195 8.31738 11.835 8.50195L10.2969 10.0332L8.92285 11.6191L8.98438 10.1357V2.54785C8.98438 1.93262 8.58789 1.52246 7.99316 1.52246Z"></path>
              </svg><span>最新情報公開日</span>
            </div>
          </div>

          <!-- メイン業界 -->
          <div class="filter-item" :class="pick_main_industries.length > 0 ? 'incremental-wrap' : ''">
            <div class="filter-button" role="button" tabindex="0" @click="onClickMainIndustry" @blur="resetPickList">
              <svg class="typesSelect" viewBox="0 0 16 16" style="width: 16px; height: 16px; display: block; flex-shrink: 0; backface-visibility: hidden; margin-right: 6px;">
                <path d="M8 15.126C11.8623 15.126 15.0615 11.9336 15.0615 8.06445C15.0615 4.20215 11.8623 1.00293 7.99316 1.00293C4.13086 1.00293 0.938477 4.20215 0.938477 8.06445C0.938477 11.9336 4.1377 15.126 8 15.126ZM8 13.7383C4.85547 13.7383 2.33301 11.209 2.33301 8.06445C2.33301 4.91992 4.84863 2.39746 7.99316 2.39746C11.1377 2.39746 13.6738 4.91992 13.6738 8.06445C13.6738 11.209 11.1445 13.7383 8 13.7383ZM7.62402 10.6348C7.79492 10.915 8.20508 10.9287 8.37598 10.6348L10.666 6.73145C10.8574 6.41016 10.7002 6.04102 10.3652 6.04102H5.62793C5.29297 6.04102 5.14941 6.43066 5.32031 6.73145L7.62402 10.6348Z"></path>
              </svg><span>{{ selected_main_industry_name }}</span>
              <svg class="chevronDown" viewBox="0 0 30 30" style="width: 10px; height: 100%; display: block; flex-shrink: 0; backface-visibility: hidden; margin-left: 4px; margin-top: 1px;">
                <polygon points="15,17.4 4.8,7 2,9.8 15,23 28,9.8 25.2,7 "></polygon>
              </svg>
            </div>
            <div class="list-group keyword-list" v-if="pick_main_industries.length > 0" style="min-width: 215px;">
              <a
                v-for="pick_main_industry in pick_main_industries"
                :key="pick_main_industry.id"
                class="list-group-item list-group-item-action"
                @click.prevent.stop="onSelectMainIndustry(pick_main_industry)"
                href="#"
              >
                {{ pick_main_industry.name }}
              </a>
            </div>
          </div>
          <!-- メイン業界 -->

          <!-- サブ業界1 -->
          <div class="filter-item" :class="pick_sub1_industries.length > 0 ? 'incremental-wrap' : ''">
            <div class="filter-button" role="button" tabindex="0" @click="onClickSub1Industry" @blur="resetPickList">
              <svg class="typesSelect" viewBox="0 0 16 16" style="width: 16px; height: 16px; display: block; flex-shrink: 0; backface-visibility: hidden; margin-right: 6px;">
                <path d="M8 15.126C11.8623 15.126 15.0615 11.9336 15.0615 8.06445C15.0615 4.20215 11.8623 1.00293 7.99316 1.00293C4.13086 1.00293 0.938477 4.20215 0.938477 8.06445C0.938477 11.9336 4.1377 15.126 8 15.126ZM8 13.7383C4.85547 13.7383 2.33301 11.209 2.33301 8.06445C2.33301 4.91992 4.84863 2.39746 7.99316 2.39746C11.1377 2.39746 13.6738 4.91992 13.6738 8.06445C13.6738 11.209 11.1445 13.7383 8 13.7383ZM7.62402 10.6348C7.79492 10.915 8.20508 10.9287 8.37598 10.6348L10.666 6.73145C10.8574 6.41016 10.7002 6.04102 10.3652 6.04102H5.62793C5.29297 6.04102 5.14941 6.43066 5.32031 6.73145L7.62402 10.6348Z"></path>
              </svg><span>{{ selected_sub1_industry_name }}</span>
              <svg class="chevronDown" viewBox="0 0 30 30" style="width: 10px; height: 100%; display: block; flex-shrink: 0; backface-visibility: hidden; margin-left: 4px; margin-top: 1px;">
                <polygon points="15,17.4 4.8,7 2,9.8 15,23 28,9.8 25.2,7 "></polygon>
              </svg>
            </div>

            <div class="list-group keyword-list" v-if="pick_sub1_industries.length > 0" style="min-width: 215px;">
              <a
                v-for="pick_sub1_industry in pick_sub1_industries"
                :key="pick_sub1_industry.id"
                class="list-group-item list-group-item-action"
                @click.prevent.stop="onSelectSub1Industry(pick_sub1_industry)"
                href="#"
              >
                {{ pick_sub1_industry.name }}
              </a>
            </div>
          </div>
          <!-- サブ業界1 -->

          <!-- サブ業界2 -->
          <div class="filter-item" :class="pick_sub2_industries.length > 0 ? 'incremental-wrap' : ''">
            <div class="filter-button" role="button" tabindex="0" @click="onClickSub2Industry" @blur="resetPickList">
              <svg class="typesSelect" viewBox="0 0 16 16" style="width: 16px; height: 16px; display: block; flex-shrink: 0; backface-visibility: hidden; margin-right: 6px;">
                <path d="M8 15.126C11.8623 15.126 15.0615 11.9336 15.0615 8.06445C15.0615 4.20215 11.8623 1.00293 7.99316 1.00293C4.13086 1.00293 0.938477 4.20215 0.938477 8.06445C0.938477 11.9336 4.1377 15.126 8 15.126ZM8 13.7383C4.85547 13.7383 2.33301 11.209 2.33301 8.06445C2.33301 4.91992 4.84863 2.39746 7.99316 2.39746C11.1377 2.39746 13.6738 4.91992 13.6738 8.06445C13.6738 11.209 11.1445 13.7383 8 13.7383ZM7.62402 10.6348C7.79492 10.915 8.20508 10.9287 8.37598 10.6348L10.666 6.73145C10.8574 6.41016 10.7002 6.04102 10.3652 6.04102H5.62793C5.29297 6.04102 5.14941 6.43066 5.32031 6.73145L7.62402 10.6348Z"></path>
              </svg><span>{{ selected_sub2_industry_name }}</span>
              <svg class="chevronDown" viewBox="0 0 30 30" style="width: 10px; height: 100%; display: block; flex-shrink: 0; backface-visibility: hidden; margin-left: 4px; margin-top: 1px;">
                <polygon points="15,17.4 4.8,7 2,9.8 15,23 28,9.8 25.2,7 "></polygon>
              </svg>
            </div>
            <div class="list-group keyword-list" v-if="pick_sub2_industries.length > 0" style="min-width: 215px;">
              <a
                v-for="pick_sub2_industry in pick_sub2_industries"
                :key="pick_sub2_industry.id"
                class="list-group-item list-group-item-action"
                @click.prevent.stop="onSelectSub2Industry(pick_sub2_industry)"
                href="#"
              >
                {{ pick_sub2_industry.name }}
              </a>
            </div>
          </div>
          <!-- サブ業界2 -->

          <!-- 推定時価総額 -->
          <div class="filter-item">
            <div class="filter-button" role="button" tabindex="0" id="capital_button">
              <svg viewBox="0 0 16 16" class="typesNumber" style="width: 16px; height: 16px; display: block; flex-shrink: 0; backface-visibility: hidden; margin-right: 6px;">
                <path d="M2.4834 10.9902H4.33594L3.75488 13.8887C3.74121 13.9639 3.72754 14.0664 3.72754 14.1416C3.72754 14.5381 3.99414 14.7637 4.39746 14.7637C4.81445 14.7637 5.09473 14.5449 5.18359 14.1143L5.80566 10.9902H8.79297L8.21191 13.8887C8.19824 13.9639 8.18457 14.0664 8.18457 14.1416C8.18457 14.5381 8.45117 14.7637 8.85449 14.7637C9.27148 14.7637 9.55176 14.5449 9.63379 14.1143L10.2627 10.9902H12.4502C12.9287 10.9902 13.2432 10.6758 13.2432 10.2109C13.2432 9.8418 12.9902 9.56836 12.6006 9.56836H10.5498L11.2129 6.28711H13.3662C13.8379 6.28711 14.1523 5.96582 14.1523 5.50098C14.1523 5.13184 13.9062 4.8584 13.5098 4.8584H11.5L12.0195 2.27441C12.0264 2.21973 12.0469 2.11035 12.0469 2.01465C12.0469 1.625 11.7666 1.39258 11.3633 1.39258C10.9053 1.39258 10.6797 1.63867 10.5977 2.05566L10.0303 4.8584H7.04297L7.5625 2.27441C7.57617 2.21973 7.58984 2.11035 7.58984 2.01465C7.58984 1.625 7.30957 1.39258 6.91309 1.39258C6.44824 1.39258 6.21582 1.63867 6.13379 2.05566L5.57324 4.8584H3.54297C3.06445 4.8584 2.75 5.18652 2.75 5.65137C2.75 6.03418 3.00293 6.28711 3.39258 6.28711H5.28613L4.62305 9.56836H2.63379C2.15527 9.56836 1.84082 9.89648 1.84082 10.3613C1.84082 10.7373 2.09375 10.9902 2.4834 10.9902ZM6.09277 9.56836L6.75586 6.28711H9.74316L9.08008 9.56836H6.09277Z">
                </path>
              </svg>
              <span style="max-width: 180px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                <span style="font-weight: 500;">{{ search_current_market_capital }}（単位：百万円）
                </span>
              </span>
              <svg class="chevronDown" viewBox="0 0 30 30" style="width: 10px; height: 100%; display: block; flex-shrink: 0; backface-visibility: hidden; margin-left: 4px; margin-top: 1px;">
                <polygon points="15,17.4 4.8,7 2,9.8 15,23 28,9.8 25.2,7 "></polygon>
              </svg>
            </div>
            <div class="filter-child" id="capital_popup" v-show="is_show_capital">
              <div style="display: flex; color: rgba(55, 53, 47, 0.5); font-size: 12px;">
                <span style="flex-shrink: 1; margin-left: 8px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">推定時価総額（単位：百万円）</span>
              </div>
              <div style="display: flex; align-items: center; flex-grow: 1; white-space: nowrap; margin: 0 0.5rem;">
                <input
                  type="text"
                  class="form-control"
                  style="height: 32px; padding: 0.375rem; box-shadow: 0 0 2px #d4d4c2"
                  @input="onChangeCurrentMarketCapital"
                  v-model="form.current_market_capital_from"
                  maxlength="24"
                />
                <span class="unit" style="margin: 0 5px">~</span>
                <input
                  type="text"
                  class="form-control"
                  style="height: 32px; padding: 0.375rem; box-shadow: 0 0 2px #d4d4c2"
                  @input="onChangeCurrentMarketCapital"
                  v-model="form.current_market_capital_to"
                  maxlength="24"
                />
              </div>
            </div>
          </div>
          <!-- 推定時価総額 -->

          <!-- アセット -->
          <div class="filter-item">
            <div class="filter-button" role="button" tabindex="0" id="asset_button">
              <svg viewBox="0 0 16 16" class="typesMultipleSelect" style="width: 16px; height: 16px; display: block; fill: rgba(55, 53, 47, 0.45); flex-shrink: 0; backface-visibility: hidden; margin-right: 6px;">
                <path d="M1.91602 4.83789C2.44238 4.83789 2.87305 4.40723 2.87305 3.87402C2.87305 3.34766 2.44238 2.91699 1.91602 2.91699C1.38281 2.91699 0.952148 3.34766 0.952148 3.87402C0.952148 4.40723 1.38281 4.83789 1.91602 4.83789ZM5.1084 4.52344H14.3984C14.7607 4.52344 15.0479 4.23633 15.0479 3.87402C15.0479 3.51172 14.7607 3.22461 14.3984 3.22461H5.1084C4.74609 3.22461 4.45898 3.51172 4.45898 3.87402C4.45898 4.23633 4.74609 4.52344 5.1084 4.52344ZM1.91602 9.03516C2.44238 9.03516 2.87305 8.60449 2.87305 8.07129C2.87305 7.54492 2.44238 7.11426 1.91602 7.11426C1.38281 7.11426 0.952148 7.54492 0.952148 8.07129C0.952148 8.60449 1.38281 9.03516 1.91602 9.03516ZM5.1084 8.7207H14.3984C14.7607 8.7207 15.0479 8.43359 15.0479 8.07129C15.0479 7.70898 14.7607 7.42188 14.3984 7.42188H5.1084C4.74609 7.42188 4.45898 7.70898 4.45898 8.07129C4.45898 8.43359 4.74609 8.7207 5.1084 8.7207ZM1.91602 13.2324C2.44238 13.2324 2.87305 12.8018 2.87305 12.2686C2.87305 11.7422 2.44238 11.3115 1.91602 11.3115C1.38281 11.3115 0.952148 11.7422 0.952148 12.2686C0.952148 12.8018 1.38281 13.2324 1.91602 13.2324ZM5.1084 12.918H14.3984C14.7607 12.918 15.0479 12.6309 15.0479 12.2686C15.0479 11.9062 14.7607 11.6191 14.3984 11.6191H5.1084C4.74609 11.6191 4.45898 11.9062 4.45898 12.2686C4.45898 12.6309 4.74609 12.918 5.1084 12.918Z"></path>
              </svg><span>協業相手に求めるアセット</span>
              <svg class="chevronDown" viewBox="0 0 30 30" style="width: 10px; height: 100%; display: block; flex-shrink: 0; backface-visibility: hidden; margin-left: 4px; margin-top: 1px;">
                <polygon points="15,17.4 4.8,7 2,9.8 15,23 28,9.8 25.2,7 "></polygon>
              </svg>
            </div>
            <div class="filtering-icon" v-if="form.assets.length > 0"></div>
            <div class="filter-child" id="asset_popup" v-show="is_show_asset">
              <div style="display: flex; color: rgba(55, 53, 47, 0.5); font-size: 12px;">
                <span style="flex-shrink: 1; margin-left: 8px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">協業相手に求めるアセット</span>
              </div>
              <div style="display: flex; flex-direction: column; width: 260px; min-width: 180px; max-width: calc(100vw - 24px); height: 100%; max-height: 50vh;">
                <div style="display: block; flex-wrap: nowrap; align-items: flex-start; cursor: text; overflow: auto; box-shadow: 0 0 2px #d4d4c2; padding: 6px 6px 1px; border: 1px solid lightgray; margin: 0 8px 6px; min-height: 30px; font-size: 12px; border-radius: 3px; z-index: 1;">
                  <div style="display: flex; flex-wrap: wrap; flex-grow: 1; min-width: 0px;">
                    <!-- 選択中のアセット -->
                    <div class="selected-asset-wrap" v-for="selected_asset in form.assets" :key="selected_asset">
                      <div class="selected-asset">
                        {{ selected_asset }}
                      </div>
                      <div role="button" class="selected-asset-del-btn" tabindex="0" @click="onSelectedAssetDelete(selected_asset)">
                        <svg viewBox="0 0 8 8" class="closeThick" style="width: 8px; height: 8px; display: block; fill: inherit; flex-shrink: 0; backface-visibility: hidden; opacity: 0.5;">
                          <polygon points="8 1.01818182 6.98181818 0 4 2.98181818 1.01818182 0 0 1.01818182 2.98181818 4 0 6.98181818 1.01818182 8 4 5.01818182 6.98181818 8 8 6.98181818 5.01818182 4">
                          </polygon>
                        </svg>
                      </div>
                    </div>
                    <!-- 選択中のアセット -->
                  </div>
                  <!-- アセット入力 -->
                  <div style="display: flex; align-items: center; border: none; padding: 0px; width: auto; background: transparent; font-size: inherit; line-height: 20px; flex: 1 1 60px; min-width: 60px; margin: 0px 6px 4px 0px;">
                    <input
                      v-model="input_asset"
                      @input="onInputAsset"
                      class="search-asset-input"
                      type="text"
                      placeholder="アセットを検索する..."
                    />
                  </div>
                  <!-- アセット入力 -->
                </div>
                <div v-if="pick_asset_names.length > 0" class="incremental-wrap" >
                  <div class="list-group keyword-list" style="max-width: fit-content">
                    <a
                      v-for="pick_asset_name in pick_asset_names"
                      :key="pick_asset_name"
                      class="list-group-item list-group-item-action"
                      @click.prevent.stop="onClickPickAsset(pick_asset_name)"
                      href="#"
                    >
                      {{ pick_asset_name }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- アセット -->

          <!-- リセット -->
          <div class="filter-item" style="margin-left: auto;">
            <div class="filter-button" role="button" tabindex="0" @click="onReset">
              <span>リセット</span>
            </div>
          </div>
          <!-- リセット -->

        </div>
      </div>
      <!--絞込・ソート-->

      <!-- リスト-->
      <section class="new-block-container mb-5">
        <p v-if="su_latest_infos.length == 0" v-show="loaded" class="search-no-data">条件に一致する最新情報は現在ありません。</p>
        <div class="new-block" v-for="su_latest_info in su_latest_infos" :key="su_latest_info.id">

          <div class="new-visual">
            <a
              class="img-box"
              @click.prevent.stop="$router.push({name: 'EpSuLatestInfoShow', params: { id: su_latest_info.id }})"
              href="#"
            >
              <img v-if="!!su_latest_info.latest_info_image_url"
                :src="su_latest_info.latest_info_image_url"
                :alt="su_latest_info.main_image_title"
                class="img320"
              />
              <img v-else-if="!!su_latest_info.pitch_file_thumbnail"
                :src="su_latest_info.pitch_file_thumbnail"
                alt="ピッチ資料"
                class="img320"
              />
              <img v-else
                src="~images/image_default.jpg"
                alt="デフォルト画像"
                class="img320"
              />
            </a>
          </div>
          <div class="new-body">
            <div class="new-bar"><small class="new-date text-secondary">{{ su_latest_info.updated_at | format.datetime }}</small></div>
            <h2 class="new-title">
              <a
                href="#"
                @click.prevent.stop="$router.push({name: 'EpSuLatestInfoShow', params: { id: su_latest_info.id }})"
              >
                {{ su_latest_info.title | omittedText(40)}}
              </a>
            </h2>
            <p class="company-name mt-2">{{ su_latest_info.su_company_name }}</p>
            <div class="task-labels-wrap mt-1">
              <div class="industry-labels">{{ su_latest_info.main_industry }}</div>
              <div class="industry-labels" v-if="!!su_latest_info.sub_industry">{{ su_latest_info.sub_industry }}</div>
              <div class="industry-labels" v-if="!!su_latest_info.sub2_industry">{{ su_latest_info.sub2_industry }}</div>
            </div>
            <p class="new-price my-2" v-if="!!su_latest_info.current_market_capital">想定時価総額：{{ su_latest_info.current_market_capital | format.number }}百万円</p>
          </div>
        </div>
      </section>
      <!-- / リスト-->

      <Pagination
        :total_page="total_page"
        :total_count="total_count"
        :current_page="current_page"
        :current_per="current_per"
        :page_link_num="page_link_num"
        :onChangePage="onChangePage"
        v-if="hasRecord"
      />
    </main>
  </div>
</template>

<script>
// ページネーションのコンポーネント読み込み
import Pagination from "../../../common/pages/Pagination.vue";
// PDFピッチ資料
import VuePdf from 'vue-pdf';

export default {
  metaInfo: {
    title: "スタートアップ情報一覧",
  },

  components: {
    Pagination,
    VuePdf,
  },

  data() {
    return {
      // 業界一覧
      industries: [],
      // アセットのサジェスト
      suggest_assets: [],

      // 業界のドロップダウンメニュー
      pick_main_industries: [],
      pick_sub1_industries: [],
      pick_sub2_industries: [],
      // 選択中のメイン業界名
      selected_main_industry_name: "メイン業界",
      selected_sub1_industry_name: "サブ業界１",
      selected_sub2_industry_name: "サブ業界２",
      // 推定時価総額
      search_current_market_capital: "推定時価総額",
      is_show_capital: false,

      // アセット入力
      input_asset: "",
      // アセットの選択候補
      pick_asset_names: [],
      is_show_asset: false,

      // 最新情報
      su_latest_infos: [],

      // 現在ページ数
      current_page: Number(this.$route.query.page) || 1,
      // 現在の表示件数
      current_per: 0,
      // 総ページ数
      total_page: 0,
      // 総レコード数
      total_count: 0,
      // 表示するリンクの数
      page_link_num: 5,
      // 読み込み完了フラグ
      loaded: false,
      // pdfjs
      cmaps_path: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.5.141/cmaps/',
      // 絞り込みフォーム
      form: {
        // フリーワード検索
        search_text: this.$route.query.search_text || "",
        // 最新情報公開日の並び順
        is_order_desc: this.$route.query.is_order_desc || true,
        // 業界フィルタ
        main_industry_id: this.$route.query.main_industry_id || "",
        sub1_industry_id: this.$route.query.sub1_industry_id || "",
        sub2_industry_id: this.$route.query.sub2_industry_id || "",
        // 推定時価総額
        current_market_capital_from: this.$route.query.current_market_capital_from || "",
        current_market_capital_to: this.$route.query.current_market_capital_to || "",
        // アセット
        assets: this.$route.query.assets || [],
        // 1ページあたりの表示件数
        per: this.$route.query.per || "",
        // 現在のページ位置
        page: this.$route.query.page || "",
      },
    };
  },

  computed: {
    /**
     * 検索結果が存在するか
     */
    hasRecord: function() {
      return Object.keys(this.su_latest_infos).length > 0;
    },
  },

  filters: {
    // 文字列を省略して表示
    omittedText(text, len) {
      return text.length > len ? text.slice(0, len) + "…" : text;
    },
  },

  methods: {
    // 初期処理
    init() {
      this.$loading.load(
        this.$auth.api
          .get('ep/su_latest_infos/init_index')
          .then((response) => {
            // SU業界一覧を取得
            this.industries = response.data.industries;
            this.industries.unshift({ id: "", name: "指定なし" });
            // アセット名一覧を取得
            this.suggest_assets = response.data.asset_masters;
          })
          .catch((err) => {
            this.$errorHandlers.initial(err);
          })
      );
    },
    // データ取得処理
    getLatestInfos(query) {
      this.$loading.load(
        this.$auth.api
          .get("ep/su_latest_infos", {
            params: query,
          })
          .then((res) => {
            this.su_latest_infos = res.data.su_latest_infos || [];
            this.current_per = Number(res.data.per);
            this.current_page = Number(res.data.page);
            this.total_page = res.data.total_page;
            this.total_count = res.data.total_count;
            this.loaded = true;
          })
          .catch((err) => {
            this.$errorHandlers.initial(err);
          })
      );
    },

    // 最新情報一覧
    onLatestInfoShow() {
      let resolvedRoute = this.$router.resolve({
        name: "EpLatestInfoShow",
      });
      window.open(resolvedRoute.href, "_blank");
    },

    // 検索フォームでエンター押下時
    onSearchFreeWord(event) {
      if (event.keyCode !== 13) return;
      // データ取得
      this.form.page = this.current_page = 1;
      this.form.per = this.current_per;
      this.reload(this.form);
    },

    // 推定時価総額入力時
    onChangeCurrentMarketCapital(e) {
      // 入力変換中は動かないようにする
      if (!!e.isComposing) return false;

      clearTimeout(this.change_timer);
      this.change_timer = setTimeout(() => {
        // 検索実行
        this.searchCurrentMarketCapital();
      }, 1200);
    },

    searchCurrentMarketCapital() {
      // 入力値の整形（文字列や不正な小数点の除外）
      this.form.current_market_capital_from = this.shapeFloat(this.form.current_market_capital_from)
      this.form.current_market_capital_to = this.shapeFloat(this.form.current_market_capital_to)
      // ボタンの表示変更
      if (!this.form.current_market_capital_from && !this.form.current_market_capital_to) {
        this.search_current_market_capital = "推定時価総額";
      } else {
        this.search_current_market_capital = this.form.current_market_capital_from + " ~ " + this.form.current_market_capital_to;
      }
      // データ取得
      this.form.page = this.current_page = 1;
      this.form.per = this.current_per;
      this.reload(this.form);
    },

    // 推定時価総額の入力値の整形
    shapeFloat(str) {
      // 数字と小数点以外を削除
      str = str.replace(/[^0-9\.]/g, "");
      // 2つ目の小数点以降を削除
      const idx = str.indexOf('.', str.indexOf('.') + 1);
      if (idx !== -1) {
        str = str.substring(0, idx);
      }
      return str;
    },

    // アセットのサジェスト選択時
    onClickPickAsset(asset) {
      // 候補一覧をリセット
      this.pick_asset_names = [];
      this.input_asset = "";
      // 選択済みアセットを選択した場合はスルー
      if (this.form.assets.indexOf(asset) === -1) {
        this.form.assets.push(asset);
        // データ取得
        this.form.page = this.current_page = 1;
        this.form.per = this.current_per;
        this.reload(this.form);
      }
    },

    // アセット入力時
    onInputAsset() {
      if (this.input_asset == '') {
        this.pick_asset_names = [];
      } else {
        // 入力された部署名で部分一致したものを候補一覧にセット(大文字小文字区別しない)
        this.pick_asset_names = this.suggest_assets.filter((el) =>
          el.toLowerCase().includes(this.input_asset.toLowerCase())
        );
      }
    },

    // 選択中のアセット削除ボタン押下時
    onSelectedAssetDelete(selected_asset) {
      const idx = this.form.assets.indexOf(selected_asset);
      if (idx !== -1) {
        this.form.assets.splice(idx, 1);
        // データ取得
        this.form.page = this.current_page = 1;
        this.form.per = this.current_per;
        this.reload(this.form);
      }
    },

    // 並び順ボタン押下時
    onOrderChange() {
      this.form.page = this.current_page = 1;
      this.form.per = this.current_per;

      // 並び順を反転
      this.form.is_order_desc = !this.form.is_order_desc;
      this.reload(this.form);
    },

    // メイン業界ボタン押下時
    onClickMainIndustry() {
      if (this.pick_main_industries.length > 0) {
        this.pick_main_industries = [];
      } else {
        this.pick_main_industries = this.industries;
      }
    },

    // サブ業界1ボタン押下時
    onClickSub1Industry() {
      if (this.pick_sub1_industries.length > 0) {
        this.pick_sub1_industries = [];
      } else {
        // サブ業界1のドロップダウンを表示
        this.pick_sub1_industries = this.industries;
      }
    },

    // サブ業界2ボタン押下時
    onClickSub2Industry() {
      if (this.pick_sub2_industries.length > 0) {
        this.pick_sub2_industries = [];
      } else {
        // サブ業界2のドロップダウンを表示
        this.pick_sub2_industries = this.industries;
      }
    },

    // メイン業界選択時
    onSelectMainIndustry(industry) {
      // 変更なければスルー
      if (this.form.main_industry_id === industry.id) {
        return false;
      }
      // ドロップダウンを非表示
      this.pick_main_industries = [];
      if (industry.id === "") {
        this.selected_main_industry_name = "メイン業界";
      } else {
        this.selected_main_industry_name = industry.name;
      }
      this.form.main_industry_id = industry.id;
      // 1ページ目に移動
      this.form.page = this.current_page = 1;
      this.form.per = this.current_per;
      this.reload(this.form);
    },

    // サブ1業界選択時
    onSelectSub1Industry(industry) {
      // 変更なければスルー
      if (this.form.sub1_industry_id === industry.id) {
        return false;
      }
      this.pick_sub1_industries = [];
      if (industry.id === "") {
        this.selected_sub1_industry_name = "サブ業界１";
      } else {
        this.selected_sub1_industry_name = industry.name;
      }
      this.pick_sub1_industries = [];
      this.form.sub1_industry_id = industry.id;
      // 1ページ目に移動
      this.form.page = this.current_page = 1;
      this.form.per = this.current_per;
      this.reload(this.form);
    },

    // サブ2業界選択時
    onSelectSub2Industry(industry) {
      // 変更なければスルー
      if (this.form.sub2_industry_id === industry.id) {
        return false;
      }
      this.pick_sub2_industries = [];

      if (industry.id === "") {
        this.selected_sub2_industry_name = "サブ業界２";
      } else {
        this.selected_sub2_industry_name = industry.name;
      }

      this.form.sub2_industry_id = industry.id;
      // 1ページ目に移動
      this.form.page = this.current_page = 1;
      this.form.per = this.current_per;
      this.reload(this.form);
    },

    // リセットボタン押下時
    onReset() {
      let query = {}
      query['page'] = this.current_page = 1;
      query['per'] = this.current_per;
      this.reload(query);
    },

    // ドロップダウンメニューをクリア
    resetPickList() {
      setTimeout(() => {
        this.pick_main_industries = [];
        this.pick_sub1_industries = [];
        this.pick_sub2_industries = [];
      }, 200);
    },

    // ページ切り替え時
    onChangePage(page, per) {
      let query = Object.assign({}, this.$route.query);
      query['page'] = this.current_page = page;
      query['per'] = this.current_per = per;
      // 画面更新
      this.reload(query);
    },

    // 更新処理
    reload(query = []) {
      this.$router.push({ name: "EpSuLatestInfoIndex", query: query }).catch((err) => {});
    },

    // 検索ポップアップ制御
    searchPopUpControll(event) {
      if (this.$el.querySelector('#capital_button').contains(event.target)) {
        this.is_show_capital = !this.is_show_capital;
        this.is_show_asset = false;
      }
      else if (this.$el.querySelector('#asset_button').contains(event.target)) {
        this.is_show_asset = !this.is_show_asset;
        this.is_show_capital = false;
      }
      // ポップアップ領域外クリック時にポップアップを閉じる
      else if (this.is_show_capital && !this.$el.querySelector('#capital_popup').contains(event.target)) {
        this.is_show_capital = false;
      }
      else if (this.is_show_asset && !this.$el.querySelector('#asset_popup').contains(event.target)) {
        this.is_show_asset = false;
      }
    },
  },

  // インスタンス生成後、リアクティブデータ初期化後
  created() {
    this.init();
    this.getLatestInfos(this.$route.query);
  },

  // route.push時に実行
  beforeRouteUpdate(to, from, next) {
    this.getLatestInfos(to.query);
    next();
  },

  mounted() {
    window.addEventListener('click', this.searchPopUpControll, true);
  },

  beforeDestroy() {
    window.removeEventListener('click', this.searchPopUpControll);
  }
};
</script>
