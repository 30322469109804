<template>

  <div class="pager-container mt-3">
    <div class="pager-box mr-5"><span>表示中</span>
      <select v-model.number="form.per" class="custom-select" @change="clickedChange()">
        <option v-for="per in [5, 10, 20, 50]" :key="per">
          {{ per }}
        </option>
      </select><span>/ {{ total_count }}</span>
    </div>

    <template v-if="current_page !== 1">
      <a href="#" class="mr-3" @click.prevent.stop="clickedPrev">&lt;&lt; 前へ</a>
    </template>

    <div class="pager-box mr-3"><span>ページ</span>
      <input v-model.number="form.page" @change="clickedChange()" class="form-control" type="text"><span>/ {{ total_page }}</span>
    </div>

    <template v-if="current_page < total_page">
      <a href="#" class="mr-5" @click.prevent.stop="clickedNext">次へ &gt;&gt;</a>
    </template>
  </div>

</template>

<script>

  export default {
    props: {
      // 総ページ数
      total_page: Number,
      // 総レコード数
      total_count: Number,
      // 現在のページ数
      current_page: Number,
      // 現在の表示件数
      current_per: Number,
      // 表示するページのリンク数
      page_link_num: Number,
      // ページ切替時のコールバックメソッド
      onChangePage: Function,
    },

    data() {
      return {
        form: {
          per:  this.current_per,
          page: this.current_page,
        },
      }
    },

    methods: {
      // ページ数押下時
      clickedPage(page, e) {
        if (this.onChangePage) {
          this.onChangePage(page, this.current_per);
        }

        e.preventDefault();
      },
      // 前のページ押下時
      clickedPrev(e) {
        if (this.onChangePage) {
          this.form.per = this.current_per;
          this.onChangePage(this.current_page - 1, this.current_per);
        }

        e.preventDefault();
      },
      // 次のページ押下時
      clickedNext(e) {
        if (this.onChangePage) {
          this.form.per = this.current_per;
          this.onChangePage(this.current_page + 1, this.current_per);
        }

        e.preventDefault();
      },
      // 表示中のページ、件数変更ボタン押下時
      clickedChange() {
        if (this.onChangePage) {
          this.onChangePage(this.form.page, this.form.per);
        }
      }
    },

    computed: {
      page_number_list() {
        const page_list = Array.from(new Array(this.total_page)).map((v,i) => i + 1)
        const offset_left  = Math.floor((this.page_link_num - 1) / 2);
        const offset_right = this.page_link_num - offset_left - 1;

        let start = this.current_page - offset_left;
        let end   = this.current_page + offset_right;

        if (this.current_page - offset_left < 1) {
          start = 1;
          end = start + (this.page_link_num < this.total_page ? this.page_link_num : this.total_page) - 1;
        } else if (this.current_page + offset_right > this.total_page) {
          start = this.total_page - (this.page_link_num < this.total_page ? this.page_link_num : this.total_page) + 1;
          end = this.total_page;
        }

        return page_list.slice(start - 1, end);
      },
      // ページネーションの表示判定
      // 総ページ数が1ページより大きい : true
      // 総ページ数が1ページ未満 : false
      is_display_pagination() {
        return this.total_page > 1
      },
    },

    watch: {
      current_per(new_current_per, old_current_per) {
        this.form.per = Number(new_current_per);
      },
      current_page(new_current_page, old_current_page) {
        this.form.page = Number(new_current_page);
      },
    }

  }

</script>