<template>
  <div id="app">
    <main>
      <section class="main-title">
        <h1>ポートフォリオ作成</h1>
      </section>
      <form @submit.prevent="createPortfolio">
        <section class="chapter white-block">
          <h2 class="chapter-label">基本情報</h2>
          <div class="form-wrap border-top">
            <div class="form-group">
              <label class="required-addon" for="company-name">企業名<span class="badge badge-danger">必須</span></label>
              <b-form-input v-model="forms.portfolio.company_name" :state="checkValid('company_name')" type="text" placeholder="例) シックスブレイン株式会社" class="form-control"></b-form-input>
              <p v-if="!!errors['company_name']" class="invalid-feedback d-block">{{ errors['company_name'][0]}}</p>
            </div>

            <fieldset class="form-group">
              <legend class="required-addon">決算期<span class="badge badge-danger">必須</span></legend>
              <b-select class="custom-select col-4" id="month" v-model="forms.portfolio.fiscal_month" :state="checkValid('fiscal_month')">
                <option v-for="month in 12" :key="month" :value="month" :selected="month === 1">
                  {{ month }}月
                </option>
              </b-select>
              <p v-if="!!errors['fiscal_month']" class="invalid-feedback d-block">{{ errors['fiscal_month'][0] }}</p>
            </fieldset>

            <fieldset class="form-group">
              <legend class="required-addon">貨幣単位<span class="badge badge-danger">必須</span></legend>
              <div class="required-addon">
                <b-select class="custom-select col-4" id="currency_unit" v-model="forms.portfolio.currency_unit_master_id" :state="checkValid('currency_unit_master_id')">
                  <option v-for="(currency_unit_i18n, currency_unit) in currency_units" :key="currency_unit" :value="currency_unit">
                    {{ currency_unit_i18n }}
                  </option>
                </b-select>
              </div>
              <p v-if="!!errors['currency_unit_master_id']" class="invalid-feedback d-block">{{ errors['currency_unit_master_id'][0] }}</p>
            </fieldset>
          </div>
        </section>

        <div class="btn-container d-flex justify-content-center">
          <b-button @click="$router.push({ name: 'EpPortfolioIndex' });" class="button-square btn-cancel" type="button">キャンセル</b-button>
          <button type="submit" class="button-square btn-send">追加する</button>
        </div>
      </form>
    </main>
  </div>
</template>

<script>
import Form from '../../../common/mixins/form'
export default {
  name: 'Form',
  mixins: [Form],

  data() {
    return {
      forms: {
        portfolio: {
          company_name: '',
          fiscal_month: 1,
          currency_unit_master_id: 1,
        },
      },
      errors: '',
      currency_units: {},
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.$loading.load(this.$auth.api.get(
        'ep/portfolios/new_init'
      ).then(response => {
        this.currency_units = response.data.currency_units;
      })
      .catch(err => {
        this.$errorHandlers.initial(err);
      }));
    },

    createPortfolio() {
      this.$loading.load(
        this.$auth.api.post('ep/portfolios', {
          portfolio: this.forms.portfolio
        })
        .then(response => {
          this.$router.push({ name: 'EpPortfolioIndex' });
        })
        .catch(error => {
          if(error.response.status == 422) {
            if(error.response.data && error.response.data.errors) {
              this.errors = error.response.data.errors;
            }
            // エラー時ページトップにスクロール
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
          } else if(error.response.status == 500) {
            this.$errorHandlers.initial(error);
          } else {
            this.$errorHandlers.ajax(error);
          }
        })
      );
    }
  },
}
</script>
