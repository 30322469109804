<template>
  <router-view :user="user" :image_attributes="image_attributes" :newly="false" :currentUserId="currentUserId" ></router-view>
</template>

<script>
export default {
  metaInfo: {
    title: 'ユーザー編集',
  },
  name: 'UserEdit',

  props: { currentUserId: Number },

  data(){
    return {
      user: {},
      image_attributes: {id: "", filename: ""}
    }
  },
  methods: {
    setData () {
      this.$loading.load(this.$auth.api.get(`admin/users/${this.$route.params.id}.json`)
        .then(response => {
          this.user = response.data.user
          this.image_attributes = response.data.user.image_attributes
        })
        .catch(err => {
          this.$errorHandlers.initial(err);
        }));
    }
  },
  mounted(){
    this.setData();
  }
}
</script>
