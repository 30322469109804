<template>
  <div>
    <main>
      <section class="main-title">
        <h1>チャレンジ詳細</h1>
      </section>

      <!-- チャレンジ詳細-->
      <section class="article-container mt-3">
        <article class="white-block reading-block">
          <!-- メインビジュアル画像 -->
          <div class="reading-visual">
            <template v-if="!isObjectEmpty(provisional_ep_challenge.main_image)">
              <a class="img-box" :href="provisional_ep_challenge.main_image.presigned_url"
                data-lightbox="challenge_image" :data-title="provisional_ep_challenge.main_image_title">
                <img
                  :src="provisional_ep_challenge.main_image.presigned_url"
                  :alt="provisional_ep_challenge.main_image.filename">
              </a>
              <div class="reading-visual-caption">{{ provisional_ep_challenge.main_image_title }}</div>
            </template>
            <template v-else>
              <a class="img-box" href="#">
                <img
                  src="~images/image_default.jpg"
                  alt="デフォルト画像"
                />
              </a>
            </template>
          </div>
          <!-- 投稿日時 -->
          <div class="reading-bar">
            <small class="reading-date text-secondary">{{ provisional_ep_challenge.created_at | format.datetime }}</small>
          </div>
          <!-- タイトル -->
          <h2 class="reading-title">{{ provisional_ep_challenge.title }}</h2>
          <dl class="reading-item">
            <dt>私たちのチャレンジ</dt>
            <dd class="u-pre-wrap ql-snow">
              <div class="ql-editor" v-html="provisional_ep_challenge.detail"></div>
            </dd>
          </dl>
          <dl class="reading-item" v-if="provisional_ep_challenge.sub1_image_id || provisional_ep_challenge.sub2_image_id">
            <dt>イメージ</dt>
            <dd class="d-flex">
              <!-- 画像（サブビジュアル1） -->
              <figure v-if="provisional_ep_challenge.sub1_image_id">
                <a class="img-box" :href="provisional_ep_challenge.sub1_image.presigned_url"
                  data-lightbox="challenge_image" :data-title="provisional_ep_challenge.sub1_image_title">
                  <img
                    :src="provisional_ep_challenge.sub1_image.presigned_url"
                    :alt="provisional_ep_challenge.presigned_challenge_sub1_image_filename"
                  >
                </a>
                <figcaption class="reading-visual-caption">{{ provisional_ep_challenge.sub1_image_title }}</figcaption>
              </figure>
              <!-- 画像（サブビジュアル2） -->
              <figure class="mr-3" v-if="provisional_ep_challenge.sub2_image_id">
                <a class="img-box" :href="provisional_ep_challenge.sub2_image.presigned_url"
                  data-lightbox="challenge_image" :data-title="provisional_ep_challenge.sub2_image_title">
                  <img
                    :src="provisional_ep_challenge.sub2_image.presigned_url"
                    :alt="provisional_ep_challenge.sub2_image.filename"
                  >
                </a>
                <figcaption class="reading-visual-caption">{{ provisional_ep_challenge.sub2_image_title }}</figcaption>
              </figure>
            </dd>
          </dl>
          <dl class="reading-item">
            <dt>パートナー企業様にご提供できる価値</dt>
            <dd>
              <template v-if="provisional_ep_challenge.is_show_more_assets">
                <div v-for="(asset, index) in provisional_ep_challenge.asset_masters" :key="asset.asset_master_id" class="task-labels" v-if="index <= 9">
                  <p class="task-labels-parent" >
                    {{ asset.name }}
                  </p>
                </div>
                <div class="btn-container text-right">
                  <button class="js-view-trigger btn-text d-show" type="button" @click="onClickShowMoreAssets()">
                    もっと見る
                  </button>
                </div>
              </template>
              <template v-else>
                <div v-for="asset in provisional_ep_challenge.asset_masters" :key="asset.asset_master_id" class="task-labels">
                  <p class="task-labels-parent">
                    {{ asset.name }}
                  </p>
                </div>
              </template>
            </dd>
          </dl>

          <!-- チャレンジのステージ -->
          <dl class="reading-item">
            <dt>このチャレンジのステータス</dt>
            <dd>
              <div class="status-list">

                <!-- アイデア段階 -->
                <div class="status-item">
                  <div v-if="provisional_ep_challenge.is_idea_stage">
                    <p class="status-label active">アイデア段階</p>
                    <div class="img-box"><img src="~images/status_img1_on.svg" alt=""></div>
                  </div>
                  <div v-else>
                    <p class="status-label">アイデア段階</p>
                    <div class="img-box"><img src="~images/status_img1.svg" alt=""></div>
                  </div>
                </div>

                <!-- PoC段階 -->
                <div class="status-item">
                  <div v-if="provisional_ep_challenge.is_poc_stage">
                    <p class="status-label active">PoC段階</p>
                    <div class="img-box"><img src="~images/status_img2_on.svg" alt=""></div>
                  </div>
                  <div v-else>
                    <p class="status-label">PoC段階</p>
                    <div class="img-box"><img src="~images/status_img2.svg" alt=""></div>
                  </div>
                </div>

                <!-- 事業化段階 -->
                <div class="status-item">
                  <div v-if="provisional_ep_challenge.is_commercial_stage">
                    <p class="status-label active">事業化段階</p>
                    <div class="img-box"><img src="~images/status_img3_on.svg" alt=""></div>
                  </div>
                  <div v-else>
                    <p class="status-label">事業化段階</p>
                    <div class="img-box"><img src="~images/status_img3.svg" alt=""></div>
                  </div>
                </div>

                <!-- その他 -->
                <div class="status-item">
                  <div v-if="provisional_ep_challenge.is_other_stage">
                    <p class="status-label active">その他</p>
                    <div class="img-box"><img src="~images/status_img4_on.svg" alt=""></div>
                  </div>
                  <div v-else>
                    <p class="status-label">その他</p>
                    <div class="img-box"><img src="~images/status_img4.svg" alt=""></div>
                  </div>
                </div>

              </div>
            </dd>
          </dl>

          <dl class="reading-item">
            <dt>企業情報（私たちについて）</dt>
            <dd class="u-pre-wrap" v-if="!!provisional_ep_challenge.ep_company">{{ provisional_ep_challenge.ep_company.business_summary }}</dd>
          </dl>
        </article>
      </section>

      <!-- 審査詳細-->
      <section class="chapter white-block">
        <h2 class="chapter-label mb-3">審査詳細</h2>
        <div class="overview-list overview-list-dt15 my-3 p-3">
          <dl class="form-confirm-group">
            <dt>登録種別</dt>
            <dd>{{ provisional_ep_challenge.registed_type_i18n }}</dd>
          </dl>

          <dl class="form-confirm-group">
            <dt>作成日</dt>
            <dd>{{ provisional_ep_challenge.created_at | format.datetime }}</dd>
          </dl>

          <dl class="form-confirm-group">
            <dt>審査ステータス</dt>
            <dd>{{ provisional_ep_challenge.exam_status_i18n }}</dd>
          </dl>

          <dl class="form-confirm-group"  v-if="provisional_ep_challenge.is_modify">
            <dt>要修正理由</dt>
            <dd>{{ provisional_ep_challenge.no_reason }}</dd>
          </dl>

          <dl class="form-confirm-group">
            <dt>レビュー完了日</dt>
            <dd>{{ provisional_ep_challenge.examination_at | format.datetime }}</dd>
          </dl>

          <dl class="form-confirm-group" v-if="!isObjectEmpty(provisional_ep_challenge.ep_challenge)">
            <dt>公開ステータス</dt>
            <dd>{{ provisional_ep_challenge.public_status_i18n }}</dd>
          </dl>

          <dl class="form-confirm-group" v-if="!isObjectEmpty(provisional_ep_challenge.ep_challenge)">
            <dt>公開期間</dt>
            <dd>{{ provisional_ep_challenge.ep_challenge.release_at | format.datetime }} ~ {{ provisional_ep_challenge.ep_challenge.close_at | format.datetime }}</dd>
          </dl>
        </div>
      </section>
      <!-- / 審査詳細-->

      <!-- ボタンエリア-->
      <div class="btn-container adjust-lotof-btn d-flex justify-content-center">
        <a class="btn btn-big btn-white rounded-pill"  @click.prevent.stop="onClickBack">戻る</a>
        <a class="btn btn-big btn-delete rounded-pill" @click.prevent.stop="onDelete">削除する</a>
        <button
          class="btn btn-big btn-green rounded-pill"
          :class="provisional_ep_challenge.is_close ? 'disable' : ''"
          :disabled="provisional_ep_challenge.is_close"
          @click.prevent.stop="onEdit"
          style="margin-left: 10px;"
        >
          更新する
        </button>
      </div>
      <!-- ボタンエリア-->

      <ConfirmModal
        ref="delete_confirm_modal"
        :onClickSend="onDeleteComfirmSend"
        :body_text="'このチャレンジを削除します。<br>よろしいですか？'"
        :send_button_text="'削除する'"
        :isNoFade="isNoFade"
      />

    </main>
  </div>
</template>

<script>
  // 確認モーダルのコンポーネント読み込み
  import ConfirmModal from '../../../common/pages/ConfirmModal.vue';


  export default {
    metaInfo: {
      title: 'チャレンジ詳細',
    },

    components: {
      ConfirmModal,
    },

    data() {
      return {
        provisional_ep_challenge: {},
        ep_challenge_updated_at: '',
        previous_route: null,
      }
    },

    props: { isNoFade: Boolean },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.init();
    },

    // コンポーネントを描画するルートが確立する前に呼ばれる
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.previous_route = from;
      });
    },

    // メソッド定義
    methods: {
      // 初期化
      init() {
        this.getProvisionalEpChallenge();
      },

      getProvisionalEpChallenge() {
        this.$loading.load(
          this.$auth.api.get(
            `ep/provisional_ep_challenges/${this.$route.params.id}`
          ).then(res => {
            this.provisional_ep_challenge = res.data.provisional_ep_challenge;
          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        );
      },

      // 戻るボタン押下時処理
      onClickBack() {
        if (!!this.previous_route && this.previous_route.name === 'EpProvisionalEpChallengeIndex') {
          this.$router.push({path: this.previous_route.fullPath});
        } else {
          this.$router.push({name: 'EpProvisionalEpChallengeIndex'});
        }
      },

      // 削除ボタン押下時
      onDelete() {
        if (!!this.provisional_ep_challenge.ep_challenge) {
          this.ep_challenge_updated_at = this.provisional_ep_challenge.ep_challenge.updated_at;
        }
        this.$refs.delete_confirm_modal.open();
      },

      // 削除確認モーダル内の削除するボタン押下時
      onDeleteComfirmSend() {
        if (!!this.provisional_ep_challenge.ep_challenge) {
          this.ep_challenge_updated_at = this.provisional_ep_challenge.ep_challenge.updated_at;
        }
        this.$loading.load(
          this.$auth.api.delete(
            'ep/provisional_ep_challenges/' + this.provisional_ep_challenge.id, {
              params: {
                provisional_ep_challenge_updated_at: this.provisional_ep_challenge.updated_at,
                ep_challenge_updated_at: this.ep_challenge_updated_at
              }
            }
          ).then(res => {
            this.$refs.delete_confirm_modal.close();
            // 一覧画面へ戻る
            if (!!this.previous_route && this.previous_route.name === 'EpProvisionalEpChallengeIndex') {
              this.$router.push({path: this.previous_route.fullPath, query: {toast_type: 'deleted'}});
            } else {
              this.$router.push({name: 'EpProvisionalEpChallengeIndex', query: {toast_type: 'deleted'}});
            }
          }).catch(err => {
            this.$refs.delete_confirm_modal.close();

            if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else {
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },

      // 更新するボタン押下時
      onEdit() {
        // 編集画面に遷移
        this.$router.push({ name: 'EpProvisionalEpChallengeEdit', params: { id: this.provisional_ep_challenge.id } });
      },

      isObjectEmpty(object) {
        if(!object) {
          return true;
        }
        return !(Object.keys(object).length)
      },

      // もっと見るボタン押下時
      onClickShowMoreAssets(){
        this.provisional_ep_challenge.is_show_more_assets = false;
      },

    }

  }
</script>