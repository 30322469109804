<template>
  <!-- 検索-->
  <section class="search-block">
    <form @submit.prevent>
      <div class="accordion" id="serach">
        <div class="form-group card">
          <div class="card-header" id="searach-parent">
            <a class="collapsed"
               data-toggle="collapse"
               href="javascript:void(0);" @click.prevent.stop=""
               v-b-toggle.searach-toggle-child>検索<i class="fas"></i></a>
          </div>
          <b-collapse id="searach-toggle-child" visible accordion="my-accordion" role="tabpanel">
            <div class="card-body collapse show" id="searach-child">

              <fieldset class="form-group">
                <div class="row">
                  <legend class="col-2 col-form-label">ステータス</legend>
                  <div class="col-10 align-self-center single-check">
                    <div class="main-checkbox d-inline-block">
                      <div v-for="(offer_status_i18n, offer_status_key) in offerStatuses" :key="offer_status_key" class="custom-control custom-checkbox custom-control-inline">
                        <input
                              v-model="form.offer_statuses"
                              type="checkbox"
                              class="custom-control-input"
                              :id="'status_' + offer_status_key"
                              :value="offer_status_key"
                            >
                        <label :for="'status_' + offer_status_key" class="custom-control-label">{{ offer_status_i18n }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>

              <div class="row">
                <label class="col-2 col-form-label">作成日</label>
                <div class="col-10">
                  <div class="form-group">
                    <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                      <input v-model="form.offer_at_from" class="form-control" id="start-date" type="text" data-toggle="datetimepicker" data-target="#start-date"><i class="far fa-calendar-alt"></i>
                    </div><span class="unit"> ~</span>
                    <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                      <div class="input-group icon-addon-right">
                        <input v-model="form.offer_at_to" class="form-control" id="end-date" type="text" data-toggle="datetimepicker" data-target="#end-date"><i class="far fa-calendar-alt"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p class="search-no-data" v-if="loaded && !hasRecord">検索条件に一致するものはありません。</p>
              <div class="btn-container d-flex justify-content-end">
                <button @click="search" class="button-square btn-send" type="button">検索する</button>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </form>
  </section>
  <!-- 検索-->
</template>

<script>
  import form from "../../../common/mixins/form.js"

  export default {

    mixins: [form],

    props: {
      // ステータスの選択肢
      offerStatuses: {
        type: Object | Array,
        default: () => ([])
      },

      // 検索結果が存在するか
      hasRecord: {
        type: Boolean,
        default: true
      },

      // ロード完了したか
      loaded: {
        type: Boolean,
        default: false
      }
    },

    data: function () {
      return {
        form: {
          offer_at_from: this.$route.query.offer_at_from || '',
          offer_at_to:   this.$route.query.offer_at_to   || '',
          offer_statuses: this.$route.query.offer_statuses || ['wait', 'returned'],
        },
      }
    },

    mounted() {
      const self = this;
      // 作成日_from のカレンダー設定
      this.setDatePicker(
        '#start-date',
        function(selected_date) {
          self.form.offer_at_from = selected_date;
        }
      );
      // 作成日_to のカレンダー設定
      this.setDatePicker(
        '#end-date',
        function(selected_date) {
          self.form.offer_at_to = selected_date;
        }
      );
    },

    methods: {

      /**
       * 検索ボタン押下時の処理.
       */
      search: function () {
        this.$emit('search-button-click', this.form);
      },

    }
  }
</script>