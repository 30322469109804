const initialState = () => {
  return {
    ep_company:{
      company_name: '',
      prefecture_master_id: '',
      company_hp: '',
      is_audit: 'unaudit',
      address: '',
      industry_master_id: '',
      sub_industry_master_id: '',
      business_summary: '',
      updated_at: '',
      ep_admin_user_attributes: {
        email: '',
        last_name: '',
        first_name: '',
        account_type: 'ep_admin',
        password: '',
        image_attributes: {
          filename: ''
        },
        uploaded_image: '',
        new_image_name: ''
      }
    },
    password: '',
    password_confirmation: '',
	}
}

export default {
	namespaced: true,
	state: initialState(),
	mutations: {
    reset(state){
      Object.assign(state, initialState())
    },
		setEpCompany: function(state, data){
      state.ep_company = data
    },
    initEpCompany: function(state){
      state.ep_company.company_name = ''
      state.ep_company.prefecture_master_id = ''
      state.ep_company.is_audit = 'unaudit',
      state.ep_company.company_hp = ''
      state.ep_company.address = ''
      state.ep_company.industry_master_id = ''
      state.ep_company.sub_industry_master_id = '',
      state.ep_company.business_summary = ''
      Object.keys(state.ep_company.ep_admin_user_attributes).map(function (key) {
        state.ep_company.ep_admin_user_attributes[key] = ''
      })

      state.ep_company.ep_admin_user_attributes.image_attributes = { filename: '' }

      state.ep_company.ep_admin_user_attributes.account_type = 'ep_admin'
    }
  },
  getters: {
    epCompany: state => {
      return state.ep_company
    },
    uploadedAdminUserImage: state => {
      return state.admin_user_uploaded_image
    },
    imgAdminUserName: state => {
      return state.admin_user_img_name
    }
  }
}