const initialState = () => {
  return {
    from_page: {
      full_path: '',
      path: '',
      name: ''
    }
  }
}

export default {
  namespaced: true,
  state: initialState(),

  mutations: {
    setFromPage (state, data) {
      state.from_page.full_path = data.full_path;
      state.from_page.path = data.path;
      state.from_page.name = data.name;
    },
    reset(state){
      Object.assign(state, initialState())
    }
  },

  getters: {
    fromPage: state => {
      return state.from_page;
    }
  }
}